import moment from 'moment';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { clearCurrentPlant, setNotificationList } from '../../../actions';
import { useClickOutsideComponentsEffect } from '../../../hooks/useClickOutsideComponentsEffect';
import { ReactComponent as InfoIcon } from '../../../images/optimar_info.svg';
import api from '../../../utility/api';
import { getNotificationLink, getNotificationMessage, getNotificationPath } from '../../../utility/notificationHelper';
import './NotificationBell.scss';
import NotificationButton from './NotificationButton';

const baseUrl = process.env.REACT_APP_API + '/api/userNotifications';

const NotificationBell = ({ notifications = [], setNotificationList, ...props }) => {
    const [state, setState] = useState({
        showNotifications: false,
        redirect: undefined,
        allRead: false,
    });

    const history = useHistory();
    const dropdownMenu = useRef(null);
    useClickOutsideComponentsEffect([dropdownMenu], () =>
        setState((prevState) => ({ ...prevState, showNotifications: false }))
    );

    function toggleNotifications(event) {
        event.preventDefault();
        setState((prevState) => ({ ...prevState, showNotifications: !prevState.showNotifications }));
    }

    function redirect(path) {
        history.push(path);
    }

    const setNotificationRead = async (userNotificationId) => {
        try {
            await api.put(`${baseUrl}/read/${userNotificationId}`);
            await loadNotifications();
        } catch (e) {
            console.error(e);
        }
    };

    const loadNotifications = useCallback(async () => {
        try {
            const response = await api.get(`${baseUrl}`);
            console.log('Response', response);
            setNotificationList(response.data);
        } catch (e) {
            console.error(e);
        }
    }, [setNotificationList]);

    useEffect(() => {
        loadNotifications();
    }, [loadNotifications]);

    async function goToNotificationSource(n) {
        const link = getNotificationLink(n);

        await setNotificationRead(n.id);

        if (link !== '') {
            props.clearCurrentPlant();
            history.push(`${link}?supportId=${n.object}`);
            setState((prevState) => ({ ...prevState, showNotifications: false }));
        }
    }

    function printNotifications(notifications) {
        const res = [];
        if (!notifications) {
            return [];
        }

        for (let i = 0; i < 5 && notifications.length > i; i++) {
            const notification = notifications[i];
            const notificationMessage = getNotificationMessage(notification);
            res.push(
                <div
                    onClick={async () => await goToNotificationSource(notifications[i])}
                    key={'notif-' + i}
                    className="alert-list-item"
                >
                    <div className="alert-severity" />
                    <div className="alert-message">
                        <InfoIcon className="alert-severity-icon alert-severity-icon-info" />
                        {notification.read === true ? notificationMessage : <b>{notificationMessage}</b>}
                    </div>
                    <span className="notification-path">{getNotificationPath(notification)}</span>
                    <br />
                    <span className="notification-timestamp">
                        {moment.utc(notification.createdOn).local().format('DD.MM.YYYY HH:mm')}
                    </span>
                </div>
            );
        }
        console.log('Printing notifications', res);
        return res;
    }
    const count = notifications.filter((x) => x.read === false).length;

    return (
        <div style={{ position: 'relative' }}>
            {state.redirect ? (
                <Redirect
                    to={{
                        pathname: state.redirect,
                        search: state.case ? `?supportId=${state.case.object}` : '',
                    }}
                />
            ) : null}
            <NotificationButton onClick={toggleNotifications} alertCount={state.allRead ? 0 : count} />
            {state.showNotifications ? (
                <div className="alert-list" ref={dropdownMenu}>
                    {printNotifications(notifications)}

                    <div
                        className="alert-list-item"
                        onClick={(event) => {
                            toggleNotifications(event);
                            redirect('/alerts');
                        }}
                    >
                        <div className="alert-header">Show all</div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

const mapDispatchToProps = {
    clearCurrentPlant,
    setNotificationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);
