import React from 'react';

import { confirmAlert } from 'react-confirm-alert';

import PageBreak from './PageBreak';
import Subtitle from './Subtitle';
import DataPointTable from './DataPointTable';
import CommentList from './CommentList';
import LineChart from './LineChart';
import BarChart from './BarChart';
import CustomTable from './CustomTable';

export default {
    get_types_list: get_types_list,
    get_name: get_name,
    is_configurable: is_configurable,
    get_new_item: get_new_item,
};

const items = {
    pagebreak: PageBreak(),
    subtitle: Subtitle(),
    datapointtable: DataPointTable(),
    commentlist: CommentList(),
    linechart: LineChart(),
    barchart: BarChart(),
    customtable: CustomTable(),
};

function get_types_list() {
    const types_list = [];
    Object.keys(items).forEach((item) => types_list.push({ type: item, name: get_name(item) }));
    return types_list;
}

function get_name(type) {
    if (type in items) return items[type].name;

    return 'N/A';
}

function get_new_item(type) {
    if (type in items) return items[type].create_new();

    return '{}';
}

function is_configurable(type) {
    if (type in items) return items[type].configurable;

    return false;
}

export function ItemEdit(props) {
    //props.parentSection = the parent section in the document
    const [data, setData] = React.useState(props.data);
    const [unsavedData, setUnsavedData] = React.useState(false);

    const onInputChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
        setUnsavedData(true);
    };

    const onCancel = () => {
        if (unsavedData)
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body">
                            <h1>Cancel editing items</h1>
                            <p>
                                There are unsaved changes
                                <br />{' '}
                            </p>
                            <p>Are you sure you want to cancel editing?</p>
                            <div className="buttons">
                                <button className="btn btn-yellow" onClick={onClose}>
                                    No
                                </button>
                                <button
                                    className="btn btn-red"
                                    onClick={async () => {
                                        props.closeCallback();
                                        onClose();
                                    }}
                                >
                                    Yes, discard
                                </button>
                            </div>
                        </div>
                    );
                },
            });
        else props.closeCallback();
    };

    const onSave = () => {
        if (unsavedData) props.updateCallback(data);
        props.closeCallback();
    };

    return (
        <>
            <h2>Edit {get_name(data.type)} Item</h2>
            {items[data.type].get_editor(data, onInputChange, {
                datapoints: props.datapoints,
                job_type: props.job_type,
                child_jobs: props.child_jobs,
            })}
            <div className="buttons">
                <button onClick={onCancel} className="btn btn-yellow">
                    Cancel
                </button>
                <button onClick={onSave} className="btn btn-blue">
                    Save
                </button>
            </div>
        </>
    );
}

export function ItemNew(props) {
    const [type, setType] = React.useState(Object.keys(items)[0]);

    const addNewItem = () => {
        props.insertCallback(get_new_item(type));
        props.closeCallback();
    };

    return (
        <>
            <h2>New Item</h2>
            <div className="form-group">
                <label htmlFor="type">Item Type</label>
                <select
                    required
                    name="type"
                    className={'input-control'}
                    onChange={(event) => setType(event.currentTarget.value)}
                    value={type}
                >
                    {Object.keys(items).map((key, index) => (
                        <option key={'new' + index} value={key}>
                            {get_name(key)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="buttons">
                <button onClick={props.closeCallback} className="btn btn-yellow">
                    Cancel
                </button>
                <button onClick={addNewItem} className="btn btn-blue">
                    Add
                </button>
            </div>
        </>
    );
}
