import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import './CustomDatePicker.css';
//import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from 'react-date-range';
import { getRangeInfoFromPeriod } from '../../Widgets/widgetHelper';

class CustomDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            activeMenu: 'day',
            selectionRange: [
                {
                    period: 24,
                    interval: 'day',
                    endDate: moment(),
                },
            ],
            firstRender: true,
        };

        const hours = [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
        ];

        this.theme = {
            Calendar: {
                display: 'none',
                background: 'none',
                color: '#41c9f0',
                //flexDirection: 'column',
                boxSizing: 'inherit',
                //width: '1400px'
            },
            Day: {
                fontSize: '18px',
                fontWeight: '300',
                padding: '10px',
                verticalAlign: 'center',
            },
            Weekday: {
                fontSize: '18px',
                fontWeight: '300',
                padding: '5px',
            },
            DayToday: {
                backgroundColor: 'rgb(61, 145, 255)',
                color: 'white',
            },
        };

        this.hours = hours;

        const selectedDate = null;
        this.selectedDate = selectedDate;

        const selectedTime = 'NOW';
        this.selectedTime = selectedTime;
    }

    componentDidUpdate() {
        if (this.props.selPeriod && this.state.firstRender) {
            this.setState({
                activeMenu: this.props.selPeriod.period + this.props.selPeriod.interval.substring(0, 1),
            });
        }
        if (this.state.firstRender) {
            this.setState({ firstRender: false });
        }
    }

    componentDidMount() {
        if (this.props.selDate) {
            this.setState({
                endDate: moment(this.props.selDate),
                selectedTime:
                    moment(this.props.selDate).hours().toString().length === 1
                        ? '0' + moment(this.props.selDate).hours().toString()
                        : moment(this.props.selDate).hours().toString(),
            });

            this.selectedDate = moment(this.props.selDate);
        }

        // react-date-range version broke, had to update
        // only displays correctly if width is set at start and then removed, no idea why
        // better solution required
        setTimeout(() => {
            const cal = document.getElementsByClassName('rdr-Calendar').item(0);
            if (cal) {
                cal.setAttribute('style', 'color: #41c9f0;display: block');
                this.forceUpdate();
            }
        }, 1);
    }

    handleSelect(date, _push) {
        //console.log("selected!", date);
        this.selectedDate = date;
        this.setState({
            endDate: date,
        });

        // if (push) {
        //     this.props.daterange(moment(date).utc().toISOString(), false, this.props.parentDatepicker);
        // }
    }

    setPeriod(period) {
        const range = getRangeInfoFromPeriod(period);

        this.setState({
            activeMenu: period,
        });

        this.props.daterange(range, true, this.props.parentDatepicker);
    }

    pushSelection(push) {
        if (push) {
            this.props.daterange(
                this.state.endDate ? this.state.endDate.toISOString() : null,
                true,
                this.props.parentDatepicker,
                null,
                false
            );
        } else {
            this.props.daterange(null, true, null, null, true);
        }
    }

    time(val) {
        if (this.selectedDate) {
            this.setState({
                selectedTime: val,
            });

            if (val !== 'NOW') {
                this.selectedDate = moment(this.selectedDate).hour(val).minutes(0).seconds(0);
                this.setState({
                    endDate: this.selectedDate,
                });
            } else {
                this.setState({
                    selectedTime: 'NOW',
                    endDate: null,
                });
            }
        } else {
            if (val !== 'NOW') {
                this.selectedDate = moment().hour(val).minutes(0).seconds(0);
                this.setState({
                    endDate: this.selectedDate,
                });
            } else {
                this.setState({
                    selectedTime: 'NOW',
                    endDate: null,
                });
            }
        }
    }

    render() {
        //console.log("END", this.state.endDate);
        if (this.props.period && !this.props.mobile) {
            return (
                <div className={'optimar-date-picker-period'}>
                    <div
                        onClick={() => this.setPeriod('hour')}
                        className={this.state.activeMenu === 'hour' ? 'active' : ''}
                    >
                        hour
                    </div>
                    <div
                        onClick={() => this.setPeriod('day')}
                        className={this.state.activeMenu === 'day' ? 'active' : ''}
                    >
                        day
                    </div>
                    <div
                        onClick={() => this.setPeriod('week')}
                        className={this.state.activeMenu === 'week' ? 'active' : ''}
                    >
                        week
                    </div>
                    <div
                        onClick={() => this.setPeriod('month')}
                        className={this.state.activeMenu === 'month' ? 'active' : ''}
                    >
                        month
                    </div>
                </div>
            );
        } else if ((!this.props.mobile && !this.props.period) || (this.props.mobile && !this.props.period)) {
            return (
                <div className={'optimar-date-picker'}>
                    <Calendar
                        weekStartsOn={1}
                        date={
                            this.state.endDate
                                ? moment.isMoment(this.state.endDate)
                                    ? this.state.endDate.toDate()
                                    : this.state.endDate
                                : new Date()
                        }
                        onChange={this.handleSelect}
                        theme={this.theme}
                    />
                    <div className={'optimar-time-part'}>
                        <div
                            className={'now ' + (this.state.selectedTime === 'NOW' ? 'active' : '')}
                            onClick={() => this.time('NOW')}
                        >
                            NOW
                        </div>
                        <div className={'times'}>
                            {this.hours.map((v, k) => {
                                return (
                                    <div
                                        key={k}
                                        onClick={() => this.time(v)}
                                        className={'hour ' + (this.state.selectedTime === v ? 'active' : '')}
                                    >
                                        {v}
                                    </div>
                                );
                            })}
                        </div>
                        <div className={'action-btn'} onClick={() => this.pushSelection(true)}>
                            Ok
                        </div>
                        <div className={'action-btn'} onClick={() => this.pushSelection(false)}>
                            Cancel
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={'optimar-date-picker-mobile'}>
                    <div
                        onClick={() => this.setPeriod('hour')}
                        className={this.state.activeMenu === 'hour' ? 'active' : ''}
                    >
                        hour
                    </div>
                    <div
                        onClick={() => this.setPeriod('day')}
                        className={this.state.activeMenu === 'day' ? 'active' : ''}
                    >
                        day
                    </div>
                    <div
                        onClick={() => this.setPeriod('week')}
                        className={this.state.activeMenu === 'week' ? 'active' : ''}
                    >
                        week
                    </div>
                    <div
                        onClick={() => this.setPeriod('month')}
                        className={this.state.activeMenu === 'month' ? 'active' : ''}
                    >
                        month
                    </div>
                </div>
            );
        }
    }
}

CustomDatePicker.propTypes = {
    itemId: PropTypes.string,
};

export default CustomDatePicker;
