import React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import CommentList from '../../components/common/CommentList/CommentList';
import FileList from '../../components/common/FileList/FileList';
import { ReactComponent as EditIcon } from '../../images/new/optimar_edit.svg';
import { NotificationManager } from 'react-notifications';
import api from '../../utility/api';
import * as moment from 'moment';
import { emptyGuid } from '../../utility/guidFunctions';
import { uploadFileUsingFormData } from '../../utility/formFileUploadFunctions';
import Spinner from '../../components/common/Spinner/Spinner';
import SimpleSpinner from '../../components/common/Spinner/SimpleSpinner';
// import FileUpload from '../../components/common/FileUpload/FileUpload';
import { accept } from '../../components/common/FileUpload/FileUpload';
import ItemDropdown from '../../components/common/ItemDropdown/ItemDropdown';
import './CaseView.scss';

const overlayStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '2.5em 0',
    background: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    color: '#fff',
};

const dropzoneStyle = {
    marginTop: '20px',
    border: '1px dashed #919191',
    position: 'relative',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
class CaseView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            statuses: [],
            caseTypes: [],
            optimarUsers: [],
            editFields: [],
            documents: [],
            internalDocuments: [],
            filterString: '',
            expanded: [],
            items: [],
            list: [],
            case: props.case ? Object.assign({}, props.case) : {},
            showCaseInfo: !this.isDialogFrame(),
            crmCaseState: 0, //state =0: N/A, 1: fetching case based on crmid, 2: fetched ok, 3: Not found in Commander.. sync might not have run yet., 4: Some error occurred

            fetchingComments: false,
            fetchingInternalComments: false,
            fetchingFiles: false,
            fetchingInternalFiles: false,
            uploadingFile: false,
            uploadingInternalFile: false,
        };
        this.getComments = this.getComments.bind(this);
        this.getOptimarUsers = this.getOptimarUsers.bind(this);
        this.getSupportTypes = this.getSupportTypes.bind(this);
        this.getSupportStatuses = this.getSupportStatuses.bind(this);
        this.getDocuments = this.getDocuments.bind(this);
        this.fetchSingleDoc = this.fetchSingleDoc.bind(this);
        this.deleteSingleDoc = this.deleteSingleDoc.bind(this);

        this.isDialogFrame = this.isDialogFrame.bind(this);
        this.fetchFrameCase = this.fetchFrameCase.bind(this);
        this.fetchCaseInfo = this.fetchCaseInfo.bind(this);

        this.generateListSection = this.generateListSection.bind(this);
        this.getCaseInfoPane = this.getCaseInfoPane.bind(this);
        this.getDescriptionPane = this.getDescriptionPane.bind(this);
        this.getDropzone = this.getDropzone.bind(this);

        this.onDrop = this.onDrop.bind(this);
        this.onChange = this.onChange.bind(this);
        this.submitChanges = this.submitChanges.bind(this);
        this.textEditField = this.textEditField.bind(this);
        this.itemEditField = this.itemEditField.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.getStructure = this.getStructure.bind(this);
        this.getLinkedNote = this.getLinkedNote.bind(this);
    }

    componentDidMount() {
        this.getStructure(this.props.user, this.props.plant);
        this.getActiveProject(this.props.plant);
        this.getSupportTypes();
        this.getSupportStatuses();
        this.getOptimarUsers();

        if (this.state.case?.id) {
            //console.log("Fetching case base on state");
            this.fetchCaseInfo(this.state.case);
        } else if (this.isDialogFrame()) {
            //console.log("Fetching case from frame info");
            this.fetchFrameCase();
        }

        this.interval = setInterval(() => {
            this.getComments(this.state.case);
        }, 30000);
    }
    isDialogFrame = () => {
        return window.location.pathname === '/frame/support/dialog';
    };
    fetchFrameCase = async () => {
        if (this.isDialogFrame()) {
            try {
                //fetch case based on crm info.
                //https://staging-commander.optimar.io/frame/support/dialog?id=68b40ae2-dbec-4f2c-9e52-7685d685ca9b&amp;typename=incident&amp;type=112&amp;orgname=e47731e3bc194446948c2e0729a283&amp;OrgLCID=1033&amp;UserLCID=1033

                const urlParams = new URLSearchParams(window.location.search);
                const supportId = urlParams.get('id');
                if (!supportId) {
                    // console.log("");
                    throw new Error('Missing support id');
                }
                this.setState({ crmCaseState: 1 });
                const res = await api.get(`api/support/crm/frame/${supportId}`);
                if (!res.data) {
                    //crmCaseState: 3 = case not created in commander yet/not synced..
                    console.log('Unable to get case by id.. it might not have been synced yet. ', supportId);
                    this.setState({ crmCaseState: 3 });
                    return;
                }

                this.setState({ case: res.data, crmCaseState: 2 }, () => this.fetchCaseInfo(this.state.case));
            } catch (err) {
                console.log('Error fetching frame case', err);
                this.setState({ crmCaseState: 4 });
            }
        } else {
            console.log('Skipped fetching case', this.isDialogFrame(), window.location.search);
        }
    };
    fetchCaseInfo = async (theCase) => {
        if (theCase) {
            console.log('Fetching info on case', theCase);
            try {
                const commentPromise = this.getComments(theCase);
                const docsPromise = this.getDocuments(theCase);
                const internalDocsPromise = this.getDocuments(theCase, true);
                const linkedNotePromise = this.getLinkedNote(theCase);
                await Promise.all([commentPromise, docsPromise, internalDocsPromise, linkedNotePromise]);
            } catch (err) {
                console.log('Error fetching case info', err);
            }
        } else {
            console.log('No case to fetch info on..');
        }
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getComments = async (theCase) => {
        if (!theCase?.id) return;
        try {
            this.setState({
                fetchingComments: true,
                fetchingInternalComments: true,
            });
            const res = await api.get(`api/support/${theCase.id}/comments`);

            this.setState({
                comments: res.data,
                fetchingComments: false,
                fetchingInternalComments: false,
            });
        } catch (err) {
            console.log('Error fetching comments', err);
        }
    };

    getLinkedNote = async (theCase) => {
        if (!theCase?.linkedNote) return;
        try {
            const res = await api.get(`api/notes/${theCase.linkedNote}`);
            this.setState({ linkedNote: res.data });
        } catch (err) {
            console.log('Error fetching linked note', err);
        }
    };

    getOptimarUsers = async () => {
        try {
            if (this.props.user.isAdmin || this.props.user.profile.AccountName.includes('Optimar')) {
                const res = await api.get('api/users/optimar');
                this.setState({ optimarUsers: res.data });
            }
        } catch (err) {
            console.log('Error fetching optimar users', err);
        }
    };

    getSupportStatuses = async () => {
        try {
            const res = await api.get('api/support/statuses');
            this.setState({ statuses: res.data });
        } catch (err) {
            console.log('Error fetching support statuses', err);
        }
    };

    getSupportTypes = async () => {
        try {
            const res = await api.get('api/support/types');
            this.setState({ caseTypes: res.data });
        } catch (err) {
            console.log('Error fetching support types', err);
        }
    };

    getDocuments = async (theCase, internal) => {
        //(internal) {
        if (!theCase) return;

        try {
            if (internal) {
                this.setState({ fetchingInternalFiles: true });
            } else {
                this.setState({ fetchingFiles: true });
            }

            const res = await api.get(`api/documents/support/${theCase.id}` + (internal ? '/internal' : ''));

            if (!res.data) return;

            if (internal) {
                this.setState({ internalDocuments: res.data, fetchingInternalFiles: false });
            } else {
                this.setState({ documents: res.data, fetchingFiles: false });
            }
        } catch (err) {
            console.log('Error fetching document', err);
        }
    };

    getActiveProject = async (plant) => {
        const plantId = plant ? plant.id : this.state.case?.plant;
        if (!plantId) return;
        try {
            const res = await api.get(`api/projects/${plantId}/active`);
            if (res.data) this.setState({ project: res.data });
        } catch (err) {
            console.log('Error fetching active project', err);
        }
    };

    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        const tmp = Object.assign({}, this.state.case);
        tmp[name] = value;

        if (name === 'caseTypeId' || name === 'owner' || name === 'assignedTo' || name === 'statusId') {
            const index = e.nativeEvent.target.selectedIndex;
            const optionText = e.nativeEvent.target[index].text;
            tmp[name === 'owner' || name === 'assignedTo' ? name + 'Name' : name.replace('Id', '')] = optionText;
        }

        this.setState({ case: tmp, dirtyForm: true });
    }

    submitChanges() {
        api.put('api/support/update', this.state.case)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    NotificationManager.success('Case updated successfully', 'Support case updated', 5000);
                    this.setState({
                        dirtyForm: false,
                        editFields: [],
                        editItem: false,
                        editStatus: null,
                        editCaseType: null,
                    });
                    return;
                }
                NotificationManager.error('Could not update support case', 'Updated failed', 5000);
            })
            .catch(() => {
                NotificationManager.error('Could not update support case', 'Updated failed', 5000);
            });
    }

    getStructure(user, plant) {
        const plantId = plant ? plant.id : this.props.case?.plant;
        if (!plantId) return;
        const apiUrl = process.env.REACT_APP_API + '/api/plants/' + plantId + '/items/structure';

        api.get(apiUrl)
            .then((res) => {
                this.fetchingStructure = false;
                if (res.status !== 204) {
                    this.unflatten(res.data);
                    this.setState({
                        flatData: res.data,
                    });
                } else if (res.status === 204) {
                    this.unflatten();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    unflatten(data) {
        if (data) {
            const ID_KEY = 'id';
            const PARENT_KEY = 'parentId';
            const CHILDREN_KEY = 'children';

            const tree = [],
                childrenOf = {};
            let item, id, parentId;

            for (let i = 0, length = data.length; i < length; i++) {
                item = data[i];
                id = item[ID_KEY];
                parentId = item[PARENT_KEY] || '00000000-0000-0000-0000-000000000000';
                // every item may have children
                childrenOf[id] = childrenOf[id] || [];
                // init its children
                item[CHILDREN_KEY] = childrenOf[id];
                item.toggled = true;
                if (parentId !== '00000000-0000-0000-0000-000000000000') {
                    // init its parent's children object
                    childrenOf[parentId] = childrenOf[parentId] || [];
                    // push it into its parent's children object
                    childrenOf[parentId].push(item);
                } else {
                    tree.push(item);
                }
            }

            this.setState(
                {
                    data: tree,
                    items: data,
                },
                () => this.generateList()
            );
        }
    }

    findNode(node) {
        const fString = this.state.filterString.toLowerCase();
        if (
            node.name.toLowerCase().includes(fString) ||
            (node.orderPos && node.orderPos.toLowerCase().includes(fString)) ||
            (node.productSheet && node.productSheet.toLowerCase().includes(fString)) ||
            node.children.find((child) => this.findNode(child))
        ) {
            if (!this.state.expanded.includes(node.parentId)) {
                const tmp = this.state.expanded;
                tmp.push(node.parentId);
                this.setState({
                    expanded: tmp,
                });
            }
            return true;
        }
    }

    generateList() {
        const list = [];

        const sortedNodes = this.state.data.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
        );

        sortedNodes.map((node) => {
            return this.generateListSection(node, list, 0);
        });

        this.setState({
            list: list,
            generated: true,
        });
    }
    removePlantKeyIfPresent(topic) {
        const plantKey = this.props.plant?.topicKey;
        console.log(this.props.plant);
        if (topic.indexOf(plantKey) >= 0) {
            return topic.replace(`${plantKey}/`, '');
        }
        return topic;
    }

    setItem(item) {
        //console.log("Setting item..",item)
        if (item) {
            const sCase = Object.assign({}, this.state.case);
            sCase.item = item.id;
            sCase.itemPath = this.removePlantKeyIfPresent(item.topic);
            this.setState({
                selectedItem: item,
                case: sCase,
                filterString: '',
                showItemList: false,
                dirtyForm: true,
            });
        }
    }

    generateListSection(node, list, level) {
        if (node.parentId === emptyGuid() && this.findNode(node)) {
            list.push(
                <span
                    key={node.id}
                    className="search-drodown-element"
                    onMouseDown={() => {
                        this.setItem(node);
                    }}
                >
                    {node.name}
                </span>
            );
        } else {
            const styles = {};
            if (level >= 1) {
                if (this.props.isMobile) {
                    styles.marginLeft = level * 17 + 'px';
                } else {
                    styles.marginLeft = level * 12 + 'px';
                }
            }

            if (this.state.filterString === '' || this.findNode(node)) {
                list.push(
                    <span
                        style={styles}
                        key={node.id}
                        className="search-drodown-element"
                        onMouseDown={() => {
                            this.setItem(node);
                        }}
                    >
                        {node.name}
                    </span>
                );
            }
        }

        if (node.children.length > 0) {
            ++level;

            const sortedChildren = node.children.sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
            );

            sortedChildren.map((n) => {
                return this.generateListSection(n, list, level);
            });
        }

        return;
    }

    // getItemName() {
    //     var selected = this.state.items.find(item => item.id === this.state.selectedItem?.id);

    //     if (this.state.list.length > 0) {
    //         // List is open
    //         return this.escapapeItemName(this.state.itemName);
    //     } else if (selected) {
    //         return this.escapapeItemName(selected.name);
    //     }
    //     return ""
    // }

    // escapapeItemName(itemName) {
    //     if (itemName) {
    //         return itemName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    //     }
    //     return "";
    // }

    statusEditField(component) {
        return component;
    }

    caseTypeEditField(component) {
        return component;
    }

    userEditField(component, _fieldName) {
        return component;
    }

    textEditField(component, _fieldName) {
        return component;
    }

    editDescriptionField(component, _fieldName) {
        return component;
    }

    itemEditField(component, componentId) {
        //return component;
        if (!this.props.user.isAdmin && !this.props.user.profile.AccountName.includes('Optimar')) return component;

        //const sCase = this.state.case;
        if (!this.state.editItem)
            return (
                <div>
                    {component}
                    <EditIcon onClick={() => this.setState({ editItem: true })} className="edit-icon" />
                </div>
            );

        return (
            <ItemDropdown
                modalRef={this.props.modalRef}
                showLabel={false}
                showHiddenItems={false}
                plantId={this.props.plant.id}
                selectItem={(item) => this.setItem(item)}
                selectedItem={{ id: componentId }}
                required={true}
            />
        );
        // return (
        //     <div style={{ position: "relative" }}>
        //         <input onKeyPress={this.handleEnterPress}
        //             autoComplete="off"
        //             ref={"map-search-input"}
        //             type="search" value={this.state.itemName}
        //             pattern={this.getItemName()}
        //             placeholder="Search"
        //             className="search-items"
        //             title="Name does not match selected item"
        //             onFocusCapture={() => this.setState({ showItemList: true })}
        //             onBlur={() => { this.setState({ showItemList: false }) }}
        //             onChange={(e) => { this.setState({ filterString: e.target.value }, this.generateList) }} />
        //         <div style={{ display: this.state.showItemList ? "flex" : "none" }} className="search-items-dropdown">
        //             <span style={{ paddingLeft: '10px', fontSize: '12px' }}>{this.state.list ? `Showing ${this.state.list.length} results` : ''}</span>
        //             {
        //                 this.state.list ? this.state.list.map((m) => {
        //                     return m;
        //                 })
        //                     : null
        //             }
        //         </div>
        //     </div>
        // );
    }

    handleFileChange(e, internal) {
        const file = e.target.files[0];
        if (internal) {
            this.setState({ internalFileToUpload: file });
        } else {
            this.setState({ fileToUpload: file });
        }
    }

    deleteSingleDoc = async (doc) => {
        const sCase = this.state.case;
        const url = `/api/documents/support/${sCase.id}/${doc.id}/single`;
        this.setState({ deleting: true });
        try {
            const response = await api.delete(url, {
                method: 'DELETE',
                mode: 'cors',
                headers: new Headers({
                    Authorization: 'Bearer ' + this.props.user.access_token,
                }),
            });

            if (response.status === 200) {
                NotificationManager.success('Document deleted successfully', 'Delete success');
            } else {
                throw new Error('Response status error.');
            }
        } catch (err) {
            NotificationManager.error('Failed to delete document', 'Delete failed', 5000);
            console.error(err);
        } finally {
            this.setState({ deleting: false });
        }

        try {
            await this.getDocuments(sCase, doc.internal);
        } catch (err) {
            NotificationManager.warning(
                'Failed to refresh documents list, you may have to refresh the page to see your changes.',
                'Refresh failed',
                5000
            );
            console.error(err);
        }
    };
    fetchSingleDoc = async (doc) => {
        const sCase = this.state.case;
        const url = `/api/documents/support/${sCase.id}/${doc.id}/single`;
        this.setState({ fetching: true });
        try {
            const response = await api.get(url, {
                mode: 'cors',
                responseType: 'arraybuffer',
                headers: new Headers({
                    Authorization: 'Bearer ' + this.props.user.access_token,
                }),
            });
            if (response.status === 200) {
                this.createAndDownloadFile(response.data, doc.filename);
                if (this.props.fetchNotesData) this.props.fetchNotesData();
                NotificationManager.success('Document downloaded successfully', 'Download success');
            } else {
                throw new Error('Response status error');
            }
        } catch (err) {
            NotificationManager.error('Failed to download document', 'Download failed', 5000);
            console.error(err);
        }
    };

    createAndDownloadFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = filename;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({ fetching: false });
            }
        }
    }

    onDrop = async (files, internal) => {
        if (files.length < 1) {
            NotificationManager.error('File type not supported', 'File error', 5000);
            return;
        }
        // if(files.length>1){
        //     NotificationManager.error("Only one file can be uploaded at once. Sorry for the inconvenience.", "File error", 5000);
        //     return;
        // }
        // let prevFiles = this.state.files ||[];
        // prevFiles.push(...files);

        const fileUploadingProp = internal ? 'uploadingInternalFile' : 'uploadingFile';
        const stateChange = {};
        stateChange[fileUploadingProp] = true;
        this.setState(stateChange);

        const uploadable = files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })
        );

        for (let i = 0; i < uploadable.length; i++) {
            //uploadable.forEach((file) => {
            const file = uploadable[i];
            await this.uploadFile(internal, file);
        }
        stateChange[fileUploadingProp] = false;
        this.setState(stateChange);
        await this.getDocuments(this.state.case, internal);
    };
    uploadFile = async (internal, file) => {
        try {
            //let file = internal ? this.state.internalFileToUpload : this.state.fileToUpload;
            const props = {
                Internal: internal,
                SupportId: this.state.case.id,
                CreatedAt: moment.utc().format(),
                Date: moment.utc().format(),
            };

            await uploadFileUsingFormData(file, props);
            NotificationManager.success('File uploaded successfully', 'Upload succeeded', 5000);

            // this.setState({
            //     // fileToUpload: null,
            //     // internalFileToUpload: null,
            //     // uploadingInternalFile:false,
            //     // uploadingFile:false
            // })
        } catch (err) {
            NotificationManager.error('Could not upload file, please try again', 'Upload failed', 5000);
        }
    };

    getValidStatuses(sCase) {
        const caseType = this.state.caseTypes?.find((x) => x.id === sCase.caseTypeId)?.name;

        if (['Punch', 'Claim'].includes(caseType)) {
            return this.state.statuses;
        }
        if (['Improvement'].includes(caseType)) {
            return this.state.statuses.filter((x) => x.status !== 'Rejected');
        }

        return this.state.statuses.filter(
            (x) => x.status === 'New' || x.status === 'In progress' || x.status === 'Closed'
        );
    }
    getCaseInfoPane(sCase) {
        return (
            <div className={`info ${this.state.showCaseInfo ? '' : 'hideframe'}`}>
                <table>
                    <tbody>
                        <tr>
                            <td>Status</td>
                            <td>{this.statusEditField(sCase.status)}</td>
                        </tr>
                        <tr>
                            <td>Customer ref</td>
                            <td>{this.textEditField(sCase.customerRef, 'customerRef')}</td>
                        </tr>
                        <tr>
                            <td>Case type</td>
                            <td>{this.caseTypeEditField(sCase.caseType)}</td>
                        </tr>
                        {this.props.accountMode ? (
                            <tr>
                                <td>Plant</td>
                                <td>{sCase.plantName}</td>
                            </tr>
                        ) : null}
                        <tr>
                            <td>Component</td>
                            <td>{this.itemEditField(sCase.itemPath, sCase.item)}</td>
                        </tr>
                        <tr>
                            <td>Reporter</td>
                            <td>{sCase.reporterName}</td>
                        </tr>
                        <tr>
                            <td>Sender</td>
                            <td>{sCase.sender}</td>
                        </tr>
                        <tr>
                            <td>Created</td>
                            <td>{sCase.created && moment.utc(sCase.created).local().format('DD.MM.YYYY HH:mm')}</td>
                        </tr>
                        <tr>
                            <td>Updated</td>
                            <td>{sCase.created && moment.utc(sCase.updated).local().format('DD.MM.YYYY HH:mm')}</td>
                        </tr>
                        <tr>
                            <td>Opened</td>
                            <td>{moment.utc(sCase.created).fromNow()}</td>
                        </tr>
                    </tbody>
                </table>
                {
                    <button onClick={this.submitChanges} disabled={!this.state.dirtyForm} className="btn btn-blue">
                        Save
                    </button>
                }
                <button style={{ marginLeft: '10px' }} onClick={this.props.close} className="btn btn-yellow">
                    Close
                </button>
            </div>
        );
    }
    getDescriptionPane(isClaim, sCase) {
        return !isClaim ? (
            <div className="description">
                <h3>Description</h3>
                <div>{this.editDescriptionField(sCase.description, 'description')}</div>
            </div>
        ) : (
            <>
                <div className="description">
                    <h3>Deficiency description</h3>
                    <div>{this.editDescriptionField(sCase.deficiencyDescription, 'deficiencyDescription')}</div>
                </div>
                <div className="description">
                    <h3>Situation description</h3>
                    <div>{this.editDescriptionField(sCase.situationDescription, 'situationDescription')}</div>
                </div>
                <div className="description">
                    <h3>Required action</h3>
                    <div>{this.editDescriptionField(sCase.requiredAction, 'requiredAction')}</div>
                </div>
                <div className="description">
                    <h3>Occured before</h3>
                    <div>{this.editDescriptionField(sCase.occuredBefore, 'occuredBefore')}</div>
                </div>
            </>
        );
    }

    getDropzone(internal) {
        return (
            <Dropzone onDrop={(files) => this.onDrop(files, internal)} accept={accept}>
                {({ getRootProps, getInputProps, isDragActive, _isDragReject }) => (
                    <div {...getRootProps()} style={dropzoneStyle}>
                        Drop files here or click to upload
                        <input {...getInputProps()} />
                        {isDragActive && <div style={overlayStyle}></div>}
                    </div>
                )}
            </Dropzone>
        );
    }

    render() {
        if (this.isDialogFrame()) {
            if (this.state.crmCaseState === 1) {
                //fetching based on crm id in url
                return (
                    <div className="support view">
                        <div className="content">
                            <Spinner text="support case"></Spinner>
                        </div>
                    </div>
                );
            } else if (this.state.crmCaseState === 2) {
                //fetched ok. show normally
            } else if (this.state.crmCaseState === 3) {
                //Nothing found. most likely not synked yet. or some error has occurred during sync/creation
                return (
                    <div className="support view">
                        <div className="content center">
                            <p>
                                <label>
                                    The support case has not yet been synced with Commander. This process might take a
                                    few minutes.
                                    <br />
                                    If the problem persists, ensure the following:
                                    <br />
                                    <ul>
                                        <li>
                                            that the case is enabled to show in Commander. <br />
                                            (Toggle "Show in Commander" NO, save, and then toggle back to YES again).
                                        </li>
                                        <li>that the Commander Plant is linked to the CRM Plant Account.</li>
                                        <li>that the CRM Account is linked to the Commander Account.</li>
                                    </ul>
                                    Contact the Commander Technical team if the problem still persists.
                                </label>
                            </p>
                        </div>
                    </div>
                );
            } else if (this.state.crmCaseState === 4) {
                //Some error has occurred. Maybe with the loading of the iframe or internal routing.
                return (
                    <div className="support view">
                        <div className="content center">
                            <label>An unknown error has occurred. Refresh the page or try again later.</label>
                        </div>
                    </div>
                );
            }
        }
        const sCase = this.state.case;
        const plantId = this.props.plant ? this.props.plant.id : sCase?.plant;

        const isClaim = this.state.caseTypes.filter((x) => x.id === sCase.caseTypeId)[0]?.name === 'Claim';
        const comments = this.state.comments;
        return (
            <div className="support view">
                <div className={`support-header ${this.state.showCaseInfo ? '' : 'hideframe'}`}>
                    <h3 className="title">{this.textEditField(sCase.title, 'title')}</h3>
                </div>
                <div className="content center">
                    {this.getCaseInfoPane(sCase)}
                    <div className="body">
                        {this.getDescriptionPane(isClaim, sCase)}

                        <div className="discussions">
                            <div className="discussion">
                                <h3>Customer dialogue</h3>
                                <div className="comments">
                                    <CommentList
                                        caseId={sCase?.id}
                                        plantId={plantId}
                                        supportMode={true}
                                        comments={comments && comments.filter((x) => !x.internal)}
                                    />
                                </div>
                                <div className="files">
                                    <h3>Files</h3>
                                    {this.getDropzone(false)}
                                    {this.state.uploadingFile ? <SimpleSpinner /> : null}
                                    {this.state.fetchingFiles ? (
                                        <Spinner text="files" />
                                    ) : (
                                        <FileList
                                            readOnly={false}
                                            files={this.state.documents}
                                            downloadFunc={(file) => this.fetchSingleDoc(file)}
                                            deleteFunc={(file) => this.deleteSingleDoc(file)}
                                        />
                                    )}
                                </div>
                            </div>
                            {this.props.user.profile.AccountName.includes('Optimar') ? (
                                <div className="discussion">
                                    <h3>Internal Optimar Dialogue</h3>
                                    <div className="comments">
                                        <CommentList
                                            caseId={sCase?.id}
                                            plantId={plantId}
                                            supportInternal={true}
                                            supportMode={true}
                                            comments={comments && comments.filter((x) => x.internal)}
                                        />
                                    </div>
                                    <div className="files">
                                        <h3>Internal Files</h3>
                                        {this.getDropzone(true)}
                                        {this.state.uploadingInternalFile ? <SimpleSpinner /> : null}
                                        {this.state.fetchingInternalFiles ? (
                                            <Spinner text="files" />
                                        ) : (
                                            <FileList
                                                readOnly={false}
                                                files={this.state.internalDocuments}
                                                downloadFunc={(file) => this.fetchSingleDoc(file)}
                                                deleteFunc={(file) => this.deleteSingleDoc(file)}
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        {this.state.linkedNote ? (
                            <div className="linkednote">
                                <h3>Linked note - {this.state.linkedNote.title}</h3>
                                <div className="description">{this.state.linkedNote.content}</div>
                                <div className="files-and-comments">
                                    <CommentList readonly={true} noteId={this.state.linkedNote.id} plantId={plantId} />
                                    <div>
                                        <h3>Files</h3>
                                        <FileList
                                            readOnly={true}
                                            noteId={this.state.linkedNote.id}
                                            files={this.state.linkedNote.documents}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        plant: state.plants.currentPlant,
        isMobile: state.utility.isMobile,
    };
};

export default connect(mapStateToProps)(CaseView);
