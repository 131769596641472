import React from 'react';
import { connect } from 'react-redux';
import { validate } from '../../../utility/validationHelper';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-datetime/css/react-datetime.css';
import api from '../../../utility/api';
import ItemDropdown from '../ItemDropdown/ItemDropdown';
import { DataValueSelect } from '../../../utility/datavalueSelect';
import Select from 'react-select';

class AdminPlantEventSubAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lblHeader: props.editType === 'edit' ? 'Edit event subscription' : 'Add event subscription',
            eventSub: null,
            validStart: true,
            validEnd: true,
            dynamicData: {},
            dataValues: [],
            jobtypes: [],
            isSubmitting: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.handleOnFocusOut = this.handleOnFocusOut.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onPropSelectChanged = this.onPropSelectChanged.bind(this);
        this.onActionSelectChanged = this.onActionSelectChanged.bind(this);
        this.fetchingStructure = false;
        this.fetchingTags = false;
        this.fetchDataValues = this.fetchDataValues.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

        this.defaults = {
            notifyMeTriggerAction: 'NotifyMe',
            createOrUpdateJobTriggerAction: 'CreateOrUpdateJob',
        };
    }

    handleOnFocusOut() {
        validate();
    }

    componentDidMount() {
        this.fetchJobTypes();
        if (this.props.editType === 'edit') {
            //console.log("EDIT", this.props.editItem);
            const editItem = this.props.editItem;
            editItem.props = JSON.parse(editItem.props);
            this.setState({
                eventSub: editItem,
            });
            this.fetchDataValues(editItem);
        } else {
            const eventSub = {
                dataValue: null,
                triggerValue: null,
                triggerOperator: null,
                triggerAction: null,
                plantId: this.props.plant.id,
                topic: null,
                itemId: null,
                triggerOnce: false,
                enabled: true,
                props: {
                    //jobTypeId: null
                },
            };

            this.setState({ eventSub });
        }
    }

    componentDidUpdate() {}
    fetchDataValues = async (item) => {
        if (item) {
            try {
                const res = await api.get(
                    process.env.REACT_APP_API + '/api/items/datavalues?itemKeys=' + encodeURIComponent(item.topic)
                );
                this.setState({ datavalues: res.data });
            } catch (err) {
                this.setState({ datavalues: [] });
                console.error('ERROR: ', err);
            }
        } else {
            this.setState({ datavalues: [] });
        }
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = this.state.eventSub;
        tmp[name] = value;

        this.setState({ eventSub: tmp });
    }

    handleDateSelect(date, name, _dynamic) {
        const tmp = this.state.eventSub;
        tmp[name] = date;

        this.setState({
            eventSub: tmp,
            showDatePicker: false,
        });
    }

    selectItem(item) {
        const tmp = this.state.eventSub;
        tmp.topic = item.topic != null ? item.topic.substring(item.topic?.indexOf('/') + 1) : '';
        tmp.itemId = item.id;

        this.fetchDataValues(item);

        this.setState({
            eventSub: tmp,
        });
    }

    onDataValueChanged(selectedOption) {
        const tmp = Object.assign({}, this.state.eventSub);
        tmp.dataValue = selectedOption.value;

        this.setState({
            eventSub: tmp,
        });
    }
    onActionSelectChanged(event) {
        const value = event.target.value;
        const name = event.target.name;

        const tmp = Object.assign({}, this.state.eventSub);
        tmp[name] = value;

        //reset props.
        tmp.props = {};

        // var stateObj = {
        //     eventSub: tmp
        // };
        //Set defaults for the selected action.
        if (value === this.defaults.createOrUpdateJobTriggerAction) {
            //tmp.props["jobTypeId"] = null;
        } else if (value === this.defaults.notifyMeTriggerAction) {
            tmp.props.notifyImportance = 'medium';
        }

        this.setState({
            eventSub: tmp,
        });
    }
    onSelectChanged(event) {
        const value = event.target.value;
        const name = event.target.name;

        const tmp = this.state.eventSub;
        tmp[name] = value;

        this.setState({
            eventSub: tmp,
        });
    }
    onPropSelectChanged(event) {
        const value = event.target.value;
        const name = event.target.name;

        const tmp = this.state.eventSub;
        tmp.props[name] = value;

        this.setState({
            eventSub: tmp,
        });
    }

    handleCheckboxChange(event) {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        const tmp = Object.assign({}, this.state.eventSub);
        tmp[name] = value;

        this.setState({ eventSub: tmp });
    }

    async handleSubmit(event) {
        this.setState({
            isSubmitting: true,
        });

        if (this.props.editType === 'edit') {
            await this.updateSubmit(event);
        } else {
            await this.createSubmit(event);
        }
        this.setState({
            isSubmitting: false,
        });
    }

    fetchJobTypes = () => {
        const url = `${process.env.REACT_APP_API}/api/jobs/types/${this.props.plant.id}`;

        api.get(url).then((res) => {
            const jobtypes = res.data.map((j) => {
                return {
                    value: j.id,
                    label: j.name,
                    configuration: j.configuration,
                };
            });

            this.setState({
                jobtypes,
            });
        });
    };

    async createSubmit(event) {
        event.preventDefault();

        try {
            const eventSub = Object.assign({}, this.state.eventSub);
            eventSub.props = JSON.stringify(eventSub.props);

            await api.post(process.env.REACT_APP_API + '/api/admin/eventsubscriptions', eventSub);
            this.props.closeModal();
            NotificationManager.success('Rule created successfully', 'Rule created', 5000);
        } catch (err) {
            console.log('Error creating rule', err);
            NotificationManager.error('Rule not created successfully', 'Error creating rule', 5000);
        }
    }

    async updateSubmit(event) {
        event.preventDefault();
        try {
            const eventSub = Object.assign({}, this.state.eventSub);
            eventSub.props = JSON.stringify(eventSub.props);

            await api.put(
                process.env.REACT_APP_API + '/api/admin/eventsubscriptions/' + this.state.eventSub.id,
                eventSub
            );

            this.setState({
                editItem: null,
            });

            NotificationManager.success('Rule updated successfully', 'Rule updated', 5000);
            this.props.closeModal();
        } catch (err) {
            console.log('Error updating rule', err);
            NotificationManager.error('Rule not updated successfully', 'Error updating rule', 5000);
        }
        // });
    }

    handleTypeChange = (newValue, _event) => {
        const eventSub = Object.assign({}, this.state.eventSub);
        eventSub.props.jobTypeId = newValue?.value;
        this.setState({ eventSub: eventSub });
    };

    renderButtons() {
        const button = [
            <button
                className="btn btn-yellow btn-grow"
                key={'cancel'}
                onClick={(e) => {
                    e.preventDefault();
                    this.props.closeModal();
                }}
            >
                Cancel
            </button>,
        ];

        if (this.props.editType === 'edit') {
            button.push(
                <button disabled={this.fetching} className="btn btn-blue btn-grow" value="Submit" key={'updating'}>
                    {this.state.isSubmitting ? 'Updating...' : 'Update'}
                </button>
            );
        } else {
            button.push(
                <button disabled={this.fetching} className="btn btn-blue btn-grow" value="Submit" key={'creating'}>
                    {this.state.isSubmitting ? 'Creating...' : 'Create'}
                </button>
            );
        }

        return button;
    }

    render() {
        return this.state.eventSub != null ? (
            <div className="form-container">
                <div className="form-header">
                    <h2>{this.state.lblHeader}</h2>
                </div>

                {this.state.showFormSuccess ? this._renderSuccessMessage() : null}
                <form onSubmit={this.handleSubmit}>
                    <ItemDropdown
                        plantId={this.props.plant.id}
                        selectItem={(item) => this.selectItem(item)}
                        selectedItem={this.state.eventSub?.topic}
                        required={true}
                    />

                    <div className="form-group" style={{ position: 'relative' }}>
                        <label htmlFor="dataValue">Data value</label>
                        <DataValueSelect
                            options={this.state.datavalues || []}
                            onChange={(selected) => this.onDataValueChanged(selected)}
                            widget={this.state.eventSub || {}}
                            required={true}
                        />
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                                opacity: 0,
                                width: '100%',
                                height: 0,
                                position: 'absolute',
                            }}
                            value={this.state.eventSub?.dataValue}
                            required={true}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="triggerOperator">Operator</label>
                        <select
                            required
                            value={this.state.eventSub.triggerOperator || ''}
                            name="triggerOperator"
                            onChange={this.onSelectChanged}
                        >
                            <option key={'op0'} value="">
                                Select operator
                            </option>
                            <option key={'op1'} value="eq">
                                Equals
                            </option>
                            <option key={'op2'} value="gt">
                                Greater than
                            </option>
                            <option key={'op3'} value="lt">
                                Lesser than
                            </option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="triggerValue">Trigger value</label>
                        <input
                            required
                            type="text"
                            className={'input-control'}
                            name="triggerValue"
                            onChange={this.handleChange}
                            value={this.state.eventSub.triggerValue}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="triggerAction">Action</label>
                        <select
                            required
                            value={this.state.eventSub.triggerAction || ''}
                            name="triggerAction"
                            onChange={this.onActionSelectChanged}
                        >
                            <option key={'ac0'} value="">
                                Select action
                            </option>
                            <option key={'ac1'} value="CreateOrUpdateJob">
                                Create or update job
                            </option>
                            <option key={'ac2'} value="NotifyMe">
                                Notify me
                            </option>
                        </select>
                    </div>
                    {this.state.eventSub.triggerAction &&
                    this.state.eventSub.triggerAction === this.defaults.createOrUpdateJobTriggerAction ? (
                        <div className="form-group">
                            <label htmlFor="type">Operation type target</label>
                            <Select
                                isClearable
                                onChange={this.handleTypeChange}
                                options={this.state.jobtypes}
                                value={this.state.jobtypes?.find(
                                    (j) => j.value === this.state.eventSub?.props.jobTypeId
                                )}
                                classNamePrefix={'optimar'}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    ) : null}
                    {this.state.eventSub.triggerAction &&
                    this.state.eventSub.triggerAction === this.defaults.notifyMeTriggerAction ? (
                        <div className="form-group">
                            <label htmlFor="notifyImportance">Importance</label>
                            <select
                                required
                                value={this.state.eventSub.props.notifyImportance}
                                name="notifyImportance"
                                onChange={this.onPropSelectChanged}
                            >
                                <option key={'ac1'} value="High">
                                    High
                                </option>
                                <option key={'ac2'} value="Medium">
                                    Medium
                                </option>
                                <option key={'ac3'} value="Low">
                                    Low
                                </option>
                            </select>
                        </div>
                    ) : null}
                    <div className="form-group">
                        <label htmlFor="triggerOnce">Trigger once</label>
                        <input
                            type="checkbox"
                            name="triggerOnce"
                            checked={this.state.eventSub.triggerOnce ? this.state.eventSub.triggerOnce : false}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="enabled">Enabled</label>
                        <input
                            type="checkbox"
                            name="enabled"
                            checked={this.state.eventSub.enabled ? this.state.eventSub.enabled : false}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>

                    <div className="btn-row-grow">{this.renderButtons()}</div>
                </form>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentAdminPlant,
});

export default connect(mapStateToProps)(AdminPlantEventSubAddEdit);
