import React from 'react';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';
import Dashboard from '../../dashboard/dashboard';
import JobDocuments from '../../joblist/jobdocuments';
import { confirmDelete } from '../../joblist/listdefinition';
import Modal from 'react-modal';
import { modalStyle } from '../../../utility/modalstyles';
import JobAddEdit from '../../../components/common/JobAddEdit/JobAddEdit';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import moment from 'moment';
import { getDataCol } from '../../../components/common/GanttChart/GanttChartConfiguration';
import './jobdetails.scss';

const viewTypeDash = 0;
const viewTypeDocs = 1;

function JobDetails(props) {
    const [sideJobDetailsViewType, setSideJobDetailsViewType] = React.useState(viewTypeDash);
    const [editType, setEditType] = React.useState('edit');
    const [modalOpen, setModalState] = React.useState(false);

    const deleteJob = React.useCallback(
        (item) => {
            const url = `${process.env.REACT_APP_API}/api/jobs/${item.id}`;
            api.delete(url).then((res) => {
                if (res.status === 200) {
                    props.fetchJobs();
                    NotificationManager.success('Job deleted successfully', 'Job deleted', 5000);
                } else {
                    NotificationManager.success('Job not deleted successfully', 'Job not deleted', 5000);
                }
            });
        },
        [props.fetchJobs]
    );

    const generateJobReport = async (item) => {
        NotificationManager.info('Generating and downloading report. Please wait.', 'Generating', 5000);
        const completeCallback = (isError, error) => {
            if (isError) {
                if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
                    NotificationManager.error('User not allowed to generate report', 'Report not generated', 5000);
                    return;
                }
                NotificationManager.error('Report not generated successfully', 'Report not generated', 5000);
            } else {
                NotificationManager.success('Report generated successfully', 'Report generated', 5000);
            }
        };
        try {
            const jobs = getJobChain(item.id, Object.assign([], props.data));
            const reportConfigId = props.jobTypes.find((x) => item.jobTypeId === x.value).reportConfigId;

            const lastTripJob = findLastTrip(item, props.data);

            let lastTrip;
            if (lastTripJob) {
                lastTrip = { trip: lastTripJob, jobs };
                lastTrip.jobs = lastTrip ? getJobChain(lastTripJob.id, props.data) : undefined;
            }

            const fetchedJobData = api.post(`/api/edgeReports/job/${item.id}`);
            const fetchedReportConfig = api.get(`/api/reportconfig/${reportConfigId}`);
            const modulePdfGenerator = import('../../../utility/pdfGenerator');
            const promises = await Promise.all([fetchedJobData, modulePdfGenerator, fetchedReportConfig]);
            const [response, pdfGenerator, reportConfigResponse] = promises;

            const reportData = JSON.parse(response.data.report.report).Data;
            const reportConfig = JSON.parse(reportConfigResponse.data.config);
            const currentTrip = { trip: Object.assign({}, item), jobs };
            const mergedData = {
                reportData,
                currentTrip,
                lastTrip,
                reportConfig,
            };

            pdfGenerator.generatePdfFromType(item.type, mergedData, completeCallback);
        } catch (error) {
            completeCallback(true, error);
            console.error(error);
        }
    };

    const findLastTrip = (currentTrip, allJobs) => {
        const currentTripStartTime = moment.utc(currentTrip.startDate).unix();
        const lastTrip = allJobs
            .filter((job) => {
                const isTypeTrip = job.type.toLowerCase().includes('trip');
                const isNotCurrentJob = currentTrip.id !== job.id;
                const isBeforeThisTrip = moment.utc(job.startDate).unix() < currentTripStartTime;
                return isTypeTrip && isNotCurrentJob && isBeforeThisTrip;
            })
            .sort((trip1, trip2) => {
                // Sort for newest first
                return moment.utc(trip2).unix() - moment.utc(trip1).unix();
            });
        return lastTrip[0];
    };

    const getJobChain = (parentId, chainArray) => {
        function findTheChain(baseId, data = [], acc = {}) {
            for (let index = data.length - 1; index >= 0; index--) {
                const node = data[index];
                if (node && node.parentJobId === baseId) {
                    data.splice(index, 1);
                    acc[node.id] = {
                        node,
                        children: findTheChain(node.id, data, {}),
                    };
                }
            }
            return acc;
        }
        return findTheChain(parentId, chainArray);
    };

    const toggleModal = (type) => {
        setEditType(type);
        setModalState(!modalOpen);
    };

    const toggleJobDetails = (row, type) => {
        setSideJobDetailsViewType(viewTypeDash);
        props.toggleJobDetails(row);
    };

    const getSideJobDetails = () => {
        const data = getDataCol(props.jobDetailsRow);
        return (
            <div>
                <div className="side-panel-header">
                    <nav className="viewslist">
                        <div className="viewlist-content" onClick={() => setSideJobDetailsViewType(viewTypeDash)}>
                            <span className={sideJobDetailsViewType === viewTypeDash ? 'view-active' : 'view-inactive'}>
                                Dashboard
                            </span>
                        </div>

                        <div className="viewlist-content" onClick={() => setSideJobDetailsViewType(viewTypeDocs)}>
                            <span className={sideJobDetailsViewType === viewTypeDocs ? 'view-active' : 'view-inactive'}>
                                Documents
                            </span>
                        </div>
                    </nav>
                    <CloseIcon
                        className="side-panel-close-btn"
                        onClick={() => {
                            toggleJobDetails(null);
                        }}
                    />
                </div>
                <div className="viewslist">
                    <div className="viewlist-title">
                        <span className="title">Type:</span>
                        <span>{props.jobDetailsRow.type}</span>
                    </div>

                    <div className="viewlist-title">
                        <span className="title">Data:</span>
                        <span className="data">{data}</span>
                    </div>
                </div>
                <div className="side-panel-content">
                    {sideJobDetailsViewType === viewTypeDash ? (
                        <Dashboard
                            dashboardType="job"
                            jobTypeId={props.jobDetailsRow.jobTypeId}
                            job={{
                                startDate: props.jobDetailsRow.startDate
                                    ? moment.utc(props.jobDetailsRow.startDate)
                                    : new Date(),
                                endDate: props.jobDetailsRow.endDate
                                    ? moment.utc(props.jobDetailsRow.endDate)
                                    : new Date(),
                            }}
                            match={{ params: { plantId: props.jobDetailsRow.plantId } }}
                            currentItem={{ topic: props.jobDetailsRow.topic }}
                            currentPlant={{
                                id: props.plant.id,
                                name: props.plant.name,
                                topicKey: props.plant.topicKey,
                            }}
                        />
                    ) : null}
                    {sideJobDetailsViewType === viewTypeDocs ? (
                        <div style={{ paddingTop: '20px' }}>
                            <JobDocuments plantId={props.jobDetailsRow.plantId} jobId={props.jobDetailsRow.id} />
                        </div>
                    ) : null}
                </div>
                <div className="side-panel-footer">
                    <div
                        className="btn btn-blue"
                        onClick={() => {
                            toggleModal('child');
                        }}
                    >
                        Add child operation
                    </div>
                    <div
                        className="btn btn-blue"
                        onClick={() => {
                            toggleModal('edit');
                        }}
                    >
                        Edit operation
                    </div>
                    <div
                        className="btn btn-blue"
                        onClick={() => {
                            confirmDelete(props.jobDetailsRow, deleteJob);
                            toggleJobDetails(null);
                        }}
                    >
                        Delete operation
                    </div>
                    {props.jobTypes.find((x) => props.jobDetailsRow.jobTypeId === x.value)?.reportConfigId ? (
                        <div
                            className="btn btn-blue"
                            onClick={() => {
                                generateJobReport(props.jobDetailsRow);
                            }}
                        >
                            Download Report
                        </div>
                    ) : null}
                    <div
                        className="btn btn-blue"
                        onClick={() => {
                            props.onTransferOperation(props.jobDetailsRow);
                            toggleJobDetails(null);
                        }}
                    >
                        Transfer
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Modal
            className="right-panel-modal"
            overlayClassName="right-panel-modal-overlay"
            isOpen={props.jobDetailsOpen}
            onRequestClose={() => {
                toggleJobDetails();
            }}
            ariaHideApp={false}
        >
            {getSideJobDetails()}
            <Modal
                ref={props.modalRef}
                isOpen={modalOpen && props.jobDetailsOpen}
                style={modalStyle}
                onRequestClose={() => {
                    // Fetch jobs when modal is closed to keep in sync
                    props.fetchJobs();
                    toggleModal();
                }}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <JobAddEdit
                    modalRef={props.modalRef}
                    editItem={props.jobDetailsRow}
                    editType={editType}
                    plantId={props.jobDetailsRow.plantId}
                    plant={props.plant}
                    toggleModal={(toggletype) => {
                        if (toggletype !== 'Cancel') {
                            props.fetchJobs();
                        }
                        toggleModal();
                    }}
                />
            </Modal>
        </Modal>
    );
}

export default JobDetails;
