import { useEffect, useCallback } from 'react';

/**
 * Checks if any of the refs have been clicked, if not fires `onClickOutside`
 * @param {React.Ref<import('react').ReactNode>[]} refs Refs for elements to check against
 * @param {(e)=>void} onClickOutside Callback fired if none of the refs have been clicked
 */
export const useClickOutsideComponentsEffect = (refs, onClickOutside) => {
    if (!Array.isArray(refs)) {
        throw new Error('Refs must be an array');
    }
    const handleClickOutside = useCallback(
        (event) => {
            if (!refs.some((ref) => ref.current?.contains(event.target))) {
                onClickOutside(event);
            }
        },
        [onClickOutside, refs]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
};
