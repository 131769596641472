import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoImg } from '../../../images/Optimar_Commander_Negativ.svg';
import classes from './Logo.module.scss';

// eslint-disable-next-line no-unused-vars
const Logo = ({ source, clickable, props }) => {
    //console.log("Clickable?",clickable)
    return (
        <Link to="/" style={clickable ? null : { pointerEvents: 'none' }}>
            {/* <img style={{boxSizing: 'content-box'}} className={classes.logo} alt="Optimar logos" src={source}></img> */}
            <LogoImg style={{ boxSizing: 'content-box' }} className={classes.logo} alt="Optimar logos"></LogoImg>
        </Link>
    );
};

Logo.propTypes = {
    //source: PropTypes.string.isRequired
};

export default Logo;
