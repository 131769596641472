import { useCallback, useState, useEffect } from 'react';
/**
 * @param {() => PromiseLike} fetchFunction
 * @param {{ onError?: (error: unknown) => void }} config `config.onError` is called when the `fetchFunction` fails
 *
 * @returns
 */
export const useDataFetcher = (fetchFunction, { onError }) => {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const refetch = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await fetchFunction();
            setData(data);
            setIsLoading(false);
        } catch (err) {
            setError(err);
            onError?.(err);
            setIsLoading(false);
        }
    }, [fetchFunction, onError]);

    useEffect(() => {
        if (data != null || error != null || isLoading) {
            return;
        }
        refetch();
    }, [data, error, isLoading, refetch]);

    return { error, data, refetch, isLoading };
};
