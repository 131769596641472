import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ReactTable from 'react-table-6';
import * as moment from 'moment';
import 'react-table-6/react-table.css';
import api from '../../../utility/api';
import { confirmAlert } from 'react-confirm-alert';
import AdminPlantItemsFromTopic from '../AdminPlantItemsFromTopic/AdminPlantItemsFromTopic';
import { ReactComponent as EditIcon } from '../../../images/list/optimar_edit.svg';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import Spinner from '../Spinner/Spinner';

import Modal from 'react-modal';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminPlantNewItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
            fetchingItems: false,
        };

        this.visibleColumns = [
            { Header: 'Topic', accessor: 'topic', filterable: false },
            {
                Header: 'Modified',
                accessor: 'modified',
                Cell: (row) => <div>{moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss')}</div>,
                filterable: false,
            },
            {
                Header: '',
                Cell: (item) => {
                    return (
                        <div className="action-container">
                            <div
                                className="action-element padding"
                                alt="Add new item"
                                title="Add item"
                                onClick={() => this.openModal(item.original)}
                            >
                                <EditIcon className="action-icon" />
                                <span className="list-edit-btn">Add new item</span>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: '',
                width: 100,
                Cell: (item) => {
                    return (
                        <div className="action-container">
                            <div
                                className="action-element padding"
                                alt="Delete"
                                title="Add item"
                                onClick={() => this.confirmDelete(item.original)}
                            >
                                <DeleteIcon className="delete-icon" />
                                <span className="list-edit-btn">Delete</span>
                            </div>
                        </div>
                    );
                },
            },
        ];

        this.fetchNewItems = this.fetchNewItems.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        this.fetchNewItems();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) this.fetchNewItems();
    }

    async fetchNewItems() {
        if (this.props.plant) {
            if (this.mounted) {
                this.setState({
                    fetchingItems: true,
                });
            }
            try {
                const res = await api.get(
                    process.env.REACT_APP_API +
                        '/api/admin/plants/' +
                        this.props.plant.id +
                        '/newitems?topicKey=' +
                        this.props.plant.topicKey
                );
                //   .then(res => {
                if (this.mounted) {
                    this.setState({
                        data: res.data,
                        deleting: false,
                        fetchingItems: false,
                    });
                }
                // })
                // .catch(err => {
                //     NotificationManager.error('Failed to fetch', "Could not fetch items")
                // });
            } catch (err) {
                NotificationManager.error('Failed to fetch', 'Could not fetch items');
            }
        }
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete topic</h1>
                        <p>
                            You are about to delete a topic
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.plantName}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteItem(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    confirmDeleteAll() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete all new items on plant</h1>
                        <p>
                            You are about to delete all new items on the plant <br />{' '}
                        </p>
                        <span>
                            <b>{this.props.plant.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteAllItems();
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteItem(item) {
        api.delete(`api/admin/plants/${this.props.plant.id}/newitems?topic=${encodeURIComponent(item.topic)}`)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    NotificationManager.success('Topic deleted', 'Success');
                    this.fetchNewItems();
                }
            })
            .catch(() => {
                NotificationManager.error('Failed to delete topic', 'Failure');
            });
    }

    deleteAllItems() {
        this.setState({
            deleting: true,
        });
        api.delete(`api/admin/plants/${this.props.plant.id}/newitems/all?topicKey=${this.props.plant.topicKey}`)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    NotificationManager.success('All new items deleted', 'Success');
                    this.fetchNewItems();
                }
            })
            .catch(() => {
                NotificationManager.error('Failed to delete new items', 'Failure');
            });
    }

    openModal(item, _mode) {
        this.setState({
            modalIsOpen: true,
            editItem: item.topic,
        });
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
            },
            function () {
                this.fetchNewItems();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase()) : false;
    }

    render() {
        const newItems = this.state.data;
        if (this.state.fetchingItems) return <Spinner text="New Items"></Spinner>;
        if (!newItems || (newItems && newItems.length < 1)) return <div>No new items</div>;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{newItems ? newItems.length : 0} New Items</h2>
                    </span>
                    <span>
                        <button
                            className="btn btn-red"
                            disabled={this.state.deleting}
                            onClick={() => this.confirmDeleteAll()}
                        >
                            {this.state.deleting ? 'Deleting...' : 'Delete all new items'}
                        </button>
                    </span>
                </div>
                <ReactTable
                    defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                    data={newItems}
                    columns={this.visibleColumns}
                    showPagination={true}
                    defaultPageSize={25}
                    minRows={0}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminPlantItemsFromTopic
                        topicString={this.state.editItem}
                        addedCallback={() => this.closeModal()}
                        closeModal={() => this.closeModal()}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        plant: state.plants.currentAdminPlant,
    };
};

export default connect(mapStateToProps)(AdminPlantNewItems);
