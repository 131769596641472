const item_type_name = 'Page Break';
const configurable = false;

export default () => {
    return {
        name: item_type_name,
        configurable: configurable,
        create_new: create_new,
    };
};

function create_new() {
    return {
        type: 'pagebreak',
    };
}
