import React from 'react';
import { connect } from 'react-redux';
import api from '../../utility/api';
import Spinner from '../common/Spinner/Spinner';
import '../../views/list/generic-list.scss';
import FontAwesome from 'react-fontawesome';
import format from 'date-fns/format';
import { Pagination } from 'antd';
class ProjectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            filteredProjects: [],
            currentPage: 1,
            pageSize: 20,
            sortColumn: undefined,
            sortDirection: undefined,
        };
        this.filterString = '';
        this.filter = this.filter.bind(this);
        this.search = this.search.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onShowSizeChange = this.onShowSizeChange.bind(this);
        this.fetching = false;
    }

    componentDidMount() {
        this.fetchProjects();
    }

    fetchProjects() {
        this.fetching = true;
        api.get('/api/projects/account').then((res) => {
            this.fetching = false;
            if (res.status === 200) {
                this.setState({ projects: res.data, filteredProjects: res.data });
            }
        });
    }

    filter() {
        if (!this.state.projects) return [];
        const projects = JSON.parse(JSON.stringify(this.state.projects));

        if (!this.filterString || this.filterString.length < 1) {
            return projects;
        } else {
            const fString = this.filterString.toLowerCase();
            // eslint-disable-next-line
            return projects.filter((o) => {
                return Object.keys(o).some((k) => {
                    if (typeof o[k] === 'string' || typeof o[k] === 'number')
                        return String(o[k]).toLowerCase().includes(fString.toLowerCase());
                });
            });
        }
    }

    search(e) {
        const filter = e.target.value.trimStart();
        this.filterString = filter;
        this.setFilteredProjects();
    }

    sortFunc(ascending, colName) {
        return (a, b) => {
            if (a === b) {
                return 0;
            } else if (a[colName] === null) {
                return 1;
            } else if (b[colName] === null) {
                return -1;
            } else if (ascending) {
                if (colName === 'projectNo') return parseInt(a[colName]) < parseInt(b[colName]) ? -1 : 1;
                return a[colName].toString().toLowerCase() < b[colName].toString().toLowerCase() ? -1 : 1;
            } else {
                return a[colName].toString().toLowerCase() < b[colName].toString().toLowerCase() ? 1 : -1;
            }
        };
    }

    setFilteredProjects(sortByColumn) {
        let projects = this.filter();
        let newDirection,
            newSortColumn = undefined;

        if (projects.length < this.state.currentPage * this.state.pageSize) {
            this.setState({ currentPage: 1 });
        }

        if (this.state.sortColumn && sortByColumn) {
            if (this.state.sortColumn === sortByColumn) {
                if (this.state.sortDirection === 'asc') {
                    newDirection = 'desc';
                    newSortColumn = sortByColumn;
                    projects = projects.sort(this.sortFunc(false, sortByColumn));
                } else if (this.state.sortDirection === 'desc') {
                    newDirection = undefined;
                    newSortColumn = undefined;
                }
            } else {
                newDirection = 'asc';
                newSortColumn = sortByColumn;
                projects = projects.sort(this.sortFunc(true, sortByColumn));
            }
        } else if (sortByColumn && projects) {
            newDirection = 'asc';
            newSortColumn = sortByColumn;
            projects = projects.sort(this.sortFunc(true, sortByColumn));
        }

        this.setState({ filteredProjects: projects, sortDirection: newDirection, sortColumn: newSortColumn });
    }

    onShowSizeChange(current, pageSize) {
        this.setState({ pageSize: pageSize, currentPage: current });
    }

    onChange(pageNumber) {
        this.setState({ currentPage: pageNumber });
    }

    render() {
        const filteredProjects = this.state.filteredProjects
            ? this.state.filteredProjects.slice(
                  (this.state.currentPage - 1) * this.state.pageSize,
                  (this.state.currentPage - 1) * this.state.pageSize + this.state.pageSize
              )
            : [];

        return (
            <>
                <div className="table-tools">
                    <div className="list-search">
                        <input
                            autoComplete="off"
                            type="text"
                            placeholder="Search"
                            className="search-list"
                            onChange={this.search}
                        />
                        <FontAwesome className="search-icon" name="search" />
                    </div>
                </div>
                <div className="list">
                    <div className="list-items-header">
                        <span
                            className={
                                'list-item-date ' +
                                (this.state.sortColumn === 'projectNo'
                                    ? this.state.sortDirection === 'asc'
                                        ? 'ascending-sort'
                                        : 'descending-sort'
                                    : '')
                            }
                            onClick={() => this.setFilteredProjects('projectNo')}
                        >
                            Project No.
                        </span>
                        <span
                            className={
                                'list-item-title ' +
                                (this.state.sortColumn === 'projectName'
                                    ? this.state.sortDirection === 'asc'
                                        ? 'ascending-sort'
                                        : 'descending-sort'
                                    : '')
                            }
                            onClick={() => this.setFilteredProjects('projectName')}
                        >
                            Project name
                        </span>
                        <span
                            className={
                                'list-item-title ' +
                                (this.state.sortColumn === 'plantName'
                                    ? this.state.sortDirection === 'asc'
                                        ? 'ascending-sort'
                                        : 'descending-sort'
                                    : '')
                            }
                            onClick={() => this.setFilteredProjects('plantName')}
                        >
                            Plant
                        </span>
                        <span
                            className={
                                'list-item-title ' +
                                (this.state.sortColumn === 'projectManagerName'
                                    ? this.state.sortDirection === 'asc'
                                        ? 'ascending-sort'
                                        : 'descending-sort'
                                    : '')
                            }
                            onClick={() => this.setFilteredProjects('projectManager')}
                        >
                            Project manager
                        </span>
                        <span
                            className={
                                'list-item-date ' +
                                (this.state.sortColumn === 'factoryAcceptanceTest'
                                    ? this.state.sortDirection === 'asc'
                                        ? 'ascending-sort'
                                        : 'descending-sort'
                                    : '')
                            }
                            onClick={() => this.setFilteredProjects('factoryAcceptanceTest')}
                        >
                            FAT
                        </span>
                        <span
                            className={
                                'list-item-date ' +
                                (this.state.sortColumn === 'customerHandover'
                                    ? this.state.sortDirection === 'asc'
                                        ? 'ascending-sort'
                                        : 'descending-sort'
                                    : '')
                            }
                            onClick={() => this.setFilteredProjects('customerHandover')}
                        >
                            Handover
                        </span>
                        <span
                            className={
                                'list-item-date ' +
                                (this.state.sortColumn === 'endOfWarrantyPeriod'
                                    ? this.state.sortDirection === 'asc'
                                        ? 'ascending-sort'
                                        : 'descending-sort'
                                    : '')
                            }
                            onClick={() => this.setFilteredProjects('endOfWarrantyPeriod')}
                        >
                            End of warranty
                        </span>
                    </div>

                    {this.fetching ? (
                        <Spinner text="projects" />
                    ) : filteredProjects ? (
                        filteredProjects.map((project, i) => {
                            return (
                                <div className="list-item" key={'div-' + i}>
                                    <div className="list-item-date">
                                        <span>{project.projectNo}</span>
                                    </div>

                                    <div className="list-item-title">
                                        <span>{project.projectName}</span>
                                    </div>
                                    <div className="list-item-title">
                                        <span>{project.plantName}</span>
                                    </div>
                                    <div className="list-item-title">
                                        <span>{project.projectManagerName}</span>
                                    </div>
                                    <div className="list-item-date">
                                        <span>
                                            {project.factoryAcceptanceTest
                                                ? format(new Date(project.factoryAcceptanceTest), 'dd.MM.yyyy')
                                                : ''}
                                        </span>
                                    </div>
                                    <div className="list-item-date">
                                        <span>
                                            {project.customerHandover
                                                ? format(new Date(project.customerHandover), 'dd.MM.yyyy')
                                                : ''}
                                        </span>
                                    </div>
                                    <div className="list-item-date">
                                        <span>
                                            {project.endOfWarrantyPeriod
                                                ? format(new Date(project.endOfWarrantyPeriod), 'dd.MM.yyyy')
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                            );
                        })
                    ) : null}

                    {!this.fetching ? (
                        <div className="paginator">
                            <Pagination
                                showSizeChanger
                                defaultPageSize={this.state.pageSize}
                                onShowSizeChange={this.onShowSizeChange}
                                showTotal={(projects, range) => `${range[0]}-${range[1]} of ${projects} projects`}
                                defaultCurrent={1}
                                total={this.state.projects ? this.state.projects.length : 0}
                                current={this.state.currentPage}
                                onChange={this.onChange}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (_state) => {
    return {};
};

export default connect(mapStateToProps)(ProjectList);
