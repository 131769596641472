import React from 'react';
import './AdminUsers.css';
import AdminUserAddEdit from '../../components/common/AdminUserAddEdit/AdminUserAddEdit';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import Modal from 'react-modal';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../utility/api';
import { noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '12vw',
        width: '600px',
    },
};

class AdminUsers extends React.Component {
    constructor(props) {
        super(props);

        const rowContext = [
            {
                content: <span>Edit user</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete user</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];

        this.state = {
            showAddUserSection: false,
            users: [],
            visibleColumns: [
                { Header: 'User', accessor: 'username', filterable: true, width: 100 },
                { Header: 'Account', accessor: 'accountName', filterable: true },
                {
                    Header: 'Roles',
                    accessor: 'roles',
                    filterable: false,
                    type: 'array',
                    width: 300,
                },
                {
                    Header: 'Created',
                    accessor: 'createdOn',
                    Cell: (props) => <span>{moment(props.value).format('DD.MM.YYYY HH:mm')}</span>,
                    filterable: true,
                    width: 50,
                },
                {
                    Header: () => null,
                    id: 'rowcontext',
                    width: 50,
                    Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
                },
            ],
            editMode: false,
            editUser: null,
            modalIsOpen: false,
        };

        this.fetchUsers = this.fetchUsers.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        this.fetchUsers();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.users) {
            //this.fetchUsers();
        }
    }

    fetchUsers = async () => {
        if (!this.props.user) {
            return;
        }
        try {
            const response = await api.get(process.env.REACT_APP_API + '/api/admin/users/');
            if (!this.mounted) {
                return;
            }

            const users = response.data;
            users.map((user) => {
                user.isDisabled = user.applicationUserState === 0;
                user.roles =
                    user.roles?.map((role) => {
                        return { id: role?.id, name: role?.title, permissions: role?.permissions };
                    }) || [];
                return user;
            });
            users.sort((a, b) => +a.isDisabled - +b.isDisabled || a.username.localeCompare(b.username));

            this.setState({
                users: users,
            });
            console.log('state users', this.state.users);
        } catch (err) {
            console.error('ERROR: ', err);
        }
    };

    editUser = (itemFromChild) => {
        this.setState({
            editUser: itemFromChild,
        });
    };

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete user</h1>
                        <p>
                            You are about to delete the user
                            <br />
                        </p>
                        <span>
                            <b>{item.username}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteUser(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteUser = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/users/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('User deleted', 'User successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('User not deleted', 'Error deliting user', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchUsers();
                })
                .catch(noop);
        }
    };

    openModal(item, mode) {
        this.setState({
            modalIsOpen: true,
            editItem: item,
            editType: mode,
        });
        // , function () {
        //     this.fetchUsers();
        // });
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchUsers();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
    }

    render() {
        const users = this.state.users;
        const visibleColumns = this.state.visibleColumns;
        const currentUser = this.props.user;
        if (currentUser && currentUser.profile.role !== 'admin' && currentUser.profile.role !== 'owner') {
            return <Redirect to="/" />;
        }

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{users ? users.length : 0} Users</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New user</span>{' '}
                        </span>
                    </span>
                </div>
                <GenericTable
                    data={users}
                    localPaging={true}
                    columns={visibleColumns}
                    enableGlobalFilter={true}
                    defaultPageSize={50}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminUserAddEdit
                        editUser={this.state.editItem}
                        editedCallback={this.closeModal}
                        cancelCallback={this.closeModal}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminUsers);
