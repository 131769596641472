export const SET_CURRENT_ADMIN_ACCOUNT = 'SET_CURRENT_ADMIN_ACCOUNT';
export const CLEAR_CURRENT_ADMIN_ACCOUNT = 'CLEAR_CURRENT_ADMIN_ACCOUNT';
export const SET_ACCOUNT_NOTELIST = 'SET_ACCOUNT_NOTELIST';
export const CLEAR_ACCOUNT_NOTELIST = 'CLEAR_ACCOUNT_NOTELIST';
export const SET_ACCOUNT_DOCLIST = 'SET_ACCOUNT_DOCLIST';
export const CLEAR_ACCOUNT_DOCLIST = 'CLEAR_ACCOUNT_DOCLIST';

export const setCurrentAdminAccount = (account) => {
    return {
        type: SET_CURRENT_ADMIN_ACCOUNT,
        data: account,
    };
};

export const clearCurrentAdminAccount = () => {
    return {
        type: CLEAR_CURRENT_ADMIN_ACCOUNT,
        data: null,
    };
};

export const setAccountNoteList = (noteList) => {
    return {
        type: SET_ACCOUNT_NOTELIST,
        data: noteList,
    };
};

export const clearAccountNoteList = () => {
    return {
        type: CLEAR_ACCOUNT_NOTELIST,
        data: null,
    };
};

export const setAccountDocList = (docList) => {
    return {
        type: SET_ACCOUNT_DOCLIST,
        data: docList,
    };
};

export const clearAccountDocList = () => {
    return {
        type: CLEAR_ACCOUNT_DOCLIST,
        data: null,
    };
};
