import React from 'react';
import './OptiControl.scss';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import addMinutes from 'date-fns/addMinutes';
import api from '../../../utility/api';
import { getTimeSince } from '../../../utility/timesince';
import { forEach } from 'lodash';

const mockedEvents = [
    {
        date: '2020-09-22T12:16:43.53924+02:00',
        value: '0',
        name: 'mode',
        type: 'var_raw',
        topic: 'OC1/DATA/VAR/EVENT/PZFSDF/Factory/Line1/Machine3/Mode',
    },
    {
        date: '2020-09-22T12:17:43.539158+02:00',
        value: '1',
        name: 'mode',
        type: 'var_raw',
        topic: 'OC1/DATA/VAR/EVENT/PZFSDF/Factory/Line1/Machine3/Mode',
    },
    {
        date: '2020-09-22T12:21:43.53924+02:00',
        value: '1',
        name: 'mode',
        type: 'var_raw',
        topic: 'OC1/DATA/VAR/EVENT/PZFSDF/Factory/Line1/Machine3/Mode',
    },
];

class OptiControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
        this.getChartInfo = this.getChartInfo.bind(this);
        this.mockedEvents = mockedEvents;
    }

    componentDidUpdate(prevProps) {
        if (this.props.shouldFetch && this.props.user && JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });
        }
    }

    componentDidMount() {
        if (this.props.shouldFetch && this.props.user && !this.props.data) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });

            this.interval = setInterval(() => {
                this.getChartInfo((res) => {
                    if (this.mounted) {
                        this.setState({
                            res: res,
                        });
                    }
                });
            }, this.props.refreshInterval || 10000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.mounted = false;
    }

    getChartInfo = (callback) => {
        let apiUrl =
            process.env.REACT_APP_API +
            '/api/kpi?valueFormat=opticontrol&dataType=' +
            this.props.dataType +
            '&dataValue=' +
            this.props.dataValue;
        forEach(this.props.components, (component) => {
            apiUrl += '&componentId=' + component;
        });

        api.get(apiUrl)
            .then((res) => {
                return callback(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    setBarColor() {
        return 'linear-gradient(90deg, rgba(65,200,240,0), rgba(65,200,240,0.6))';
    }

    render() {
        const res = this.state.res || this.props.data?.[0] || { data: {} };

        const weight = false;
        const minValue = 0;
        const idealCycleTime = this.props.configuration.idealCycleTime || res.idealCycleTime;
        const maxValue = (15 * 60) / idealCycleTime;
        const runningModeStatic = this.props.configuration.runningMode;
        const runningMode = res.currentStatus;

        const endOfPeriod = new Date();
        const startOfPeriod = addMinutes(endOfPeriod, -15);
        let totalSecondsOfDowntime = 0;

        console.log('Events', res.events);

        for (const [i, val] of (res.events || []).entries()) {
            if (i === 0) {
                if (res.prevEvent?.mode === '1') {
                    //Previous event was running, no downtime
                } else {
                    //Previous event missing or stopped, downtime
                    totalSecondsOfDowntime += differenceInSeconds(new Date(val.date), startOfPeriod);
                }
            } else if (i === res.events.length - 1 && val.value === '0') {
                // if last event is stopped, start has not occurd yet. Using end date as end of down time.
                totalSecondsOfDowntime += differenceInSeconds(endOfPeriod, new Date(val.date));
            } else {
                // if event is running, get previous event to see when stopped
                if (val.value === '1') {
                    totalSecondsOfDowntime += differenceInSeconds(new Date(val.date), new Date(res.events[i - 1].date));
                }
            }
        }

        const downTime = Math.round(totalSecondsOfDowntime / idealCycleTime); // (downtime in minutes * seconds) / cycletime
        const expectedWeight = (this.props.configuration.expectedWeight || res.expectedWeight) / 1000;

        const decimals = this.props.configuration.decimalPlaces || 0;
        const config = this.props.configuration;

        const current = res.value ? res.value.toFixed(decimals) : res.value;

        const totalSpan = maxValue - minValue;
        let currentCalc = current;
        if (current < 0 || minValue < 0) {
            currentCalc = (minValue - current) * -1;
        }

        let currentPercent = current != null ? (currentCalc / totalSpan) * 100 : 0;
        let currentDowntimePercent = downTime != null ? (downTime / totalSpan) * 100 : 0;
        if (currentPercent > 100) {
            currentPercent = 100;
        } else if (currentPercent < 0) {
            currentPercent = 0;
        }

        if (currentDowntimePercent > 100) {
            currentDowntimePercent = 100;
        } else if (currentDowntimePercent < 0) {
            currentDowntimePercent = 0;
        }

        return (
            <div className={'opti-control'}>
                {runningModeStatic ? null : (
                    <div className={'line'}>
                        <div className={'line-name'}>{this.props.title}</div>

                        <div className={'line-status'}>
                            {runningMode && runningMode === '1' ? (
                                <span className="green">Running</span>
                            ) : (
                                <span className="red">Stopped</span>
                            )}
                        </div>
                    </div>
                )}
                <div className={'bar-gauge ' + (this.props.configuration.size ? this.props.configuration.size : '')}>
                    <span className="time-since">{getTimeSince({ data: { timestamp: res.timestamp } })}</span>
                    <div className="widget-header">
                        {runningModeStatic ? <span className="widget-title">{this.props.title}</span> : <span></span>}
                        <span className="widget-value">
                            {current != null
                                ? (weight ? current * expectedWeight : current) +
                                  (config
                                      ? !config.usePayloadUnit
                                          ? config.unit || ''
                                          : res.data.payloadUnit || ''
                                      : '')
                                : ''}{' '}
                            {downTime > 0 ? (
                                <span className="red">
                                    {(weight ? downTime * expectedWeight : downTime) +
                                        (config
                                            ? !config.usePayloadUnit
                                                ? config.unit || ''
                                                : res.data.payloadUnit || ''
                                            : '')}
                                </span>
                            ) : (
                                ''
                            )}
                        </span>
                    </div>

                    <div className="bar-gauge-bar">
                        <div
                            className="bar-gauge-bar-progress"
                            style={{
                                width: currentPercent + '%',
                                backgroundImage: this.setBarColor(),
                                borderRight: '2px solid white',
                            }}
                        ></div>
                        <div
                            className="bar-gauge-bar-progress downtime"
                            style={{ width: currentDowntimePercent + '%', background: 'rgba(255,0,85,0.6)' }}
                        ></div>
                    </div>

                    <div className="bar-gauge-values">
                        <span>{0}</span> <span>{weight ? maxValue * expectedWeight : maxValue}</span>
                    </div>
                </div>
            </div>
        );
    }
}

OptiControl.propTypes = {};

export default OptiControl;
