import PropTypes from 'prop-types';
import React from 'react';
import { ConfirmationAlert } from './ConfirmationAlert';
/**
 * @type {React.FC<{
 *     onCancel: React.MouseEventHandler<HTMLButtonElement>;
 *     onConfirm: React.MouseEventHandler<HTMLButtonElement>;
 *     revert: Boolean;
 * }>}
 */
export const ConfirmChangeDashboardAlert = ({ revert, ...props }) => (
    <ConfirmationAlert
        title={revert ? 'Revert custom dashboard' : 'Create custom dashboard'}
        description={
            revert
                ? 'This will revert the dashboard and show the default dashboard'
                : 'This will override the default dashboard and create your own custom dashboard'
        }
        {...props}
    />
);
ConfirmChangeDashboardAlert.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    revert: PropTypes.bool.isRequired,
};
