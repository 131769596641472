import React from 'react';
import Header from '../Header/Header.js';
import { useState } from 'react';
import { NotificationContainer } from 'react-notifications';
import { Route, Switch, useLocation } from 'react-router-dom';
import IconBars from '../../../images/icons/optimar_bars_blue.svg';
import AccountDashboard from '../../../views/accountdashboard/index.js';
import Admin from '../../../views/admin/index.js';
import AdminAccounts from '../../../views/AdminAccounts/index.js';
import AdminPlantItems from '../../../views/AdminPlantItems/index.js';
import AdminPlants from '../../../views/AdminPlants/index.js';
import AdminTags from '../../../views/AdminTags/index.js';
import AdminUsers from '../../../views/AdminUsers/index.js';
import Login from '../../Login/Login';
import Account from '../Account/account.js';
import AdminAccountAddEdit from '../AdminAccountAddEdit/AdminAccountAddEdit.js';
import FlatTreeNav from '../TreeNav/FlatTreeNav';
import AlertList from './../../../views/AlertList';
import Map from './../../../views/map';
import PlantList from './../../../views/plantlist';
import CaseView from './../../../views/support/CaseView';
import Tables from './../../../views/tables';
import './grids.css';

const MainGrid = ({ user, signOut, isMobile }) => {
    const location = useLocation();
    let isOnPlant = false;

    let [showSidebar, setShowSidebar] = useState(false);

    const globalState = getShowSidebarGlobalState();
    if (showSidebar !== globalState) {
        showSidebar = globalState;
        setShowSidebar(globalState);
    }
    // eslint-disable-next-line
    if (location.pathname) {
        isOnPlant = location.pathname.match(/\/plant\/(?:[a-z0-9]*-)+[a-z0-9]+\/\w+/);
        if (location.pathname.includes('admin')) isOnPlant = false;
    }

    if (!user) return null;

    const tableSizeCheck = () => {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 1100;
    };
    const setShowSidebarGlobalState = (state) => {
        window.localStorage.setItem('showSidebarState', JSON.stringify(state));
    };

    function getShowSidebarGlobalState() {
        return JSON.parse(window.localStorage.getItem('showSidebarState'));
    }

    const setSidebarState = (state) => {
        setShowSidebar(state);
        setShowSidebarGlobalState(state);
    };

    return (
        <div className="maingrid">
            <div className="header-grid">
                <Switch>
                    <Route path="/login">{null}</Route>
                    <Route path="/plant/:plantId/items/:itemId">
                        <Header signOut={signOut} />
                    </Route>
                    <Route path="/plant/:plantId">
                        <Header signOut={signOut} />
                    </Route>
                    <Route path="/">
                        <Header signOut={signOut} />
                    </Route>
                </Switch>
            </div>
            <div className={'content'}>
                <Switch>
                    <Route exact path="/" name="Map">
                        {check() ? <Map /> : <PlantList />}
                    </Route>
                    <Route exact path="/frame/support/dialog" name="Support">
                        <CaseView />
                    </Route>
                </Switch>
                <div style={{ display: 'flex' }}>
                    {!isMobile && isOnPlant && tableSizeCheck() ? (
                        <FlatTreeNav
                            currentLocation={window.location.pathname.slice(window.location.pathname.lastIndexOf('/'))}
                            toggle={setSidebarState}
                            sidePanelMode={true}
                            className="sidebar-nav"
                            style={{ position: 'relative', overflow: 'auto' }}
                            show={showSidebar}
                        />
                    ) : null}
                    {!isMobile && isOnPlant && !showSidebar && tableSizeCheck() ? (
                        <div onClick={() => setSidebarState(true)} className="sitemap-button">
                            <img src={IconBars} alt="Menu" />
                        </div>
                    ) : null}
                    <div className={'inner-content'}>
                        <Switch>
                            <Route path="/map" name="Map" element={Map} />
                            <Route path="/list" name="List">
                                <PlantList />
                            </Route>
                            <Route path="/account" name="Account overview">
                                <Account />
                            </Route>
                            <Route path="/plant/:plantId/items/:itemId" name="ItemView">
                                <Tables />
                            </Route>
                            <Route path="/plant/:plantId" name="Plant">
                                <Tables />
                            </Route>
                            <Route path="/login" name="Login" element={Login} />
                            <Route path="/admin" name="Admin">
                                <Admin />
                            </Route>
                            <Route path="/admin/users" name="Users">
                                <AdminUsers />
                            </Route>
                            <Route path="/admin/plant" name="Plants">
                                <AdminPlants />
                            </Route>
                            <Route exact path="/admin/account" name="Accounts">
                                <AdminAccounts />
                            </Route>
                            <Route path="/admin/account/edit" name="Account Edit">
                                <AdminAccountAddEdit />
                            </Route>
                            <Route path="/admin/tags" name="Tags">
                                <AdminTags />
                            </Route>
                            <Route path="/admin/plant/items" name="Tags">
                                <AdminPlantItems />
                            </Route>
                            <Route path="/tree" name="Tree Test">
                                <FlatTreeNav />
                            </Route>
                            <Route path="/alerts" name="Alerts">
                                <AlertList />
                            </Route>
                            <Route path="/accountdashboard" name="AccountDashboard">
                                <AccountDashboard />
                            </Route>
                        </Switch>
                        <NotificationContainer />
                    </div>
                </div>
            </div>
        </div>
    );
};

const check = () => {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    const windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

    return windowWidth > 568; //returns true for widths larger than 568 pixels
};

export default MainGrid;
