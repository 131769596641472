import { cloneDeep } from 'lodash';
import moment from 'moment';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import FontAwesome from 'react-fontawesome';
import Modal from 'react-modal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { clearAccountNoteList, clearNoteList, setAccountNoteList, setCurrentPlant, setNoteList } from '../../actions';
import CommentList from '../../components/common/CommentList/CommentList';
import FileList from '../../components/common/FileList/FileList';
import GenericTable from '../../components/common/GenericTable';
import NoteAddEdit from '../../components/common/NoteAddEdit/index.js';
import { withRouteMatch } from '../../HOC';
import api from '../../utility/api';
import { largeModalStyle, modalStyle } from '../../utility/modalstyles';
import SupportAddEdit from '../../views/support/SupportAddEdit';
import '../list/generic-list.scss';
import './notelist.css';

class NoteList extends React.Component {
    /**
     * @param {typeof mapDispatchToProps & typeof mapStateToProps} props
     */
    constructor(props) {
        super(props);
        this.state = {
            notes: undefined,
            isVisible: false,
            downloading: [],
            modalIsOpen: false,
            openNotes: [],
            showSub: true,
            showComments: false,
            showFiles: false,
            sortColumn: undefined,
            sortDirection: undefined,
            currentPage: 1,
            pageSize: 10,
        };
        this.filterString = '';

        this.fetching = false;
        this.mounted = true;
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.filter = this.filter.bind(this);
        this.search = this.search.bind(this);
        this.fetchNotesData = this.fetchNotesData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onShowSizeChange = this.onShowSizeChange.bind(this);
        this.renderName = this.renderName.bind(this);
        this.handleShowOptimarNotesClick = this.handleShowOptimarNotesClick.bind(this);
        this.rowClick = this.rowClick.bind(this);

        this.visibleColumns = [
            {
                Header: 'Date',
                accessor: 'date',
                style: { whiteSpace: 'unset' },
                filterable: true,
                Cell: (row) => <div>{moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss')}</div>,
            },
            { Header: 'Title.', accessor: 'title', filterable: true },
            { id: 'itemId', accessor: 'itemId', show: false },
            {
                Header: 'Component',
                accessor: 'itemTopic',
                filterable: true,
                Cell: (cellInfo) => (
                    <Link
                        to={`/plant/${this.props.plant?.id || cellInfo.data[cellInfo.row.index].plantId}/items/${
                            cellInfo.data[cellInfo.row.index].itemId
                        }/note`}
                    >
                        {cellInfo.value}
                    </Link>
                ),
            },
            {
                Header: 'Reporter',
                accessor: 'reporter',
                filterable: true,
                Cell: (row) => <div>{row.displayname || row.value}</div>,
            },
            { Header: 'AssignedTo', accessor: 'assignedTo', filterable: true },
            { Header: 'Status', accessor: 'statusName', filterable: true },
        ];
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && this.props.plant && JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.fetchNotesData();
            this.fetchBreadcrumb();
        }
    }

    componentDidMount() {
        if (this.props.user && this.props.plant) {
            this.fetchNotesData();
            this.fetchBreadcrumb();
        } else if (this.props.user && !this.props.plant) {
            this.fetchAccountNotes();
        }
    }

    componentWillUnmount() {
        this.props.clearNoteList();
        this.mounted = false;
        clearInterval(this.interval);
    }

    fetchBreadcrumb() {
        const id = this.props.itemId ? this.props.itemId : this.props.plantId;
        const url = `${process.env.REACT_APP_API}/api/breadcrumb?itemId=${id}`;

        api.get(url)
            .then((res) => {
                if (this.mounted) {
                    this.setState({
                        breadcrumb: res.data,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    fetchAccountNotes() {
        const url = `${process.env.REACT_APP_API}/api/notes/account`;
        this.fetching = true;
        api.get(url)
            .then((res) => {
                this.fetching = false;
                if (res.status === 200) {
                    this.props.setAccountNoteList(res.data);
                    this.props.setNoteList(res.data);
                    this.setFilteredNotes();
                } else if (res.status === 204) {
                    this.props.clearAccountNoteList();
                }
            })
            .catch((err) => {
                console.error(err);
                this.fetching = false;
            });
    }

    fetchNotesData() {
        let url = `${process.env.REACT_APP_API}/api/notes/list/${this.props.plantId}/${this.props.itemId}`;
        if (!this.props.itemId || this.props.itemId === '00000000-0000-0000-0000-000000000000') {
            url = `${process.env.REACT_APP_API}/api/notes/list/${this.props.plantId}`;
        }

        if (!this.fetching) {
            this.fetching = true;
            api.get(url)
                .then((res) => {
                    if (res.status === 200) {
                        if (this.mounted) {
                            this.props.setNoteList(res.data);
                            this.setFilteredNotes();
                        }
                    } else if (res.status === 204) {
                        this.props.setNoteList([]);
                        this.setFilteredNotes();
                    }
                    this.fetching = false;
                    if (this.mounted) {
                        this.forceUpdate();
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.fetching = false;
                });
        }
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete note</h1>
                        <p>
                            You are about to delete the note
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.title}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteNote(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteNote = (note) => {
        api.delete(process.env.REACT_APP_API + '/api/notes/' + note.id).then((response) => {
            if (response.status === 200) {
                this.fetchNotesData();
                NotificationManager.success('Note deleted successfully', 'Note deleted', 5000);
            } else {
                NotificationManager.error('Note not deleted', 'Error deleting note', 5000);
            }
        });
    };

    toggleNote(note) {
        const index = this.state.openNotes.indexOf(note);

        this.setState({
            openNotes:
                index === -1
                    ? [...this.state.openNotes, note]
                    : [...this.state.openNotes.slice(0, index), ...this.state.openNotes.slice(index + 1)],
        });
    }

    openModal(mode, note) {
        this.setState({
            editModal: true,
            modalIsOpen: false,
            editItem: note,
            editType: mode,
        });
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchNotesData();
            }
        );
    }

    closeComments() {
        this.setState(
            {
                showComments: false,
            },
            () => this.fetchNotesData()
        );
    }

    closeFiles() {
        this.setState(
            {
                showFiles: false,
            },
            () => this.fetchNotesData()
        );
    }

    filter() {
        if (!this.props.notes) {
            return [];
        }
        const notes = cloneDeep(this.props.notes);
        notes.map((note) => {
            return (note.statusName =
                note.status === 1 || note.status === null
                    ? 'Open'
                    : note.status === 2
                    ? 'Fixed'
                    : note.status === 3
                    ? 'Closed'
                    : '');
        });

        if (!this.filterString || this.filterString.length < 1) {
            return notes;
        } else {
            const fString = this.filterString.toLowerCase();
            // eslint-disable-next-line
            return notes.filter((o) => {
                // eslint-disable-next-line
                return Object.keys(o).some((k) => {
                    if (k === 'createdAt' || k === 'date') {
                        return new Date(o[k]).toString().toLowerCase().includes(fString.toLowerCase());
                    }
                    if (typeof o[k] === 'string' || typeof o[k] === 'number') {
                        return String(o[k]).toLowerCase().includes(fString.toLowerCase());
                    }
                });
            });
        }
    }

    search(e) {
        const filter = e.target.value.trimStart();
        this.filterString = filter;
        this.setFilteredNotes();
    }

    sortFunc(ascending, colName) {
        return (a, b) => {
            if (a === b) {
                return 0;
            } else if (a[colName] === null) {
                return 1;
            } else if (b[colName] === null) {
                return -1;
            } else if (ascending) {
                return a[colName].toString().toLowerCase() < b[colName].toString().toLowerCase() ? -1 : 1;
            } else {
                return a[colName].toString().toLowerCase() < b[colName].toString().toLowerCase() ? 1 : -1;
            }
        };
    }

    /**
     * @param {'showClosedNotesClick' | 'showSubComponentsClick' | 'showOptimarNotesClick' | undefined} sortByColumn
     */
    setFilteredNotes(sortByColumn) {
        let notes = this.filter();
        let newDirection,
            newSortColumn = undefined;

        if (notes.length < this.state.currentPage * this.state.pageSize) {
            this.setState({ currentPage: 1 });
        }

        if (
            this.state.sortColumn &&
            sortByColumn &&
            sortByColumn !== 'showClosedNotesClick' &&
            sortByColumn !== 'showSubComponentsClick' &&
            sortByColumn !== 'showOptimarNotesClick'
        ) {
            if (this.state.sortColumn === sortByColumn) {
                if (this.state.sortDirection === 'asc') {
                    newDirection = 'desc';
                    newSortColumn = sortByColumn;
                    notes = notes.sort(this.sortFunc(false, sortByColumn));
                } else if (this.state.sortDirection === 'desc') {
                    newDirection = undefined;
                    newSortColumn = undefined;
                }
            } else {
                newDirection = 'asc';
                newSortColumn = sortByColumn;
                notes = notes.sort(this.sortFunc(true, sortByColumn));
            }
        } else if (
            sortByColumn &&
            notes &&
            sortByColumn !== 'showClosedNotesClick' &&
            sortByColumn !== 'showSubComponentsClick' &&
            sortByColumn !== 'showOptimarNotesClick'
        ) {
            newDirection = 'asc';
            newSortColumn = sortByColumn;
            notes = notes.sort(this.sortFunc(true, sortByColumn));
        }

        let showClosed = this.state.showClosed;
        let showSub = this.state.showSub;
        let showOptimarNotes = this.state.showOptimarNotes;

        if (sortByColumn && sortByColumn === 'showClosedNotesClick') {
            showClosed = !showClosed;
        }

        if (sortByColumn && sortByColumn === 'showOptimarNotesClick') {
            showOptimarNotes = !showOptimarNotes;
        }

        if (sortByColumn && sortByColumn === 'showSubComponentsClick') {
            showSub = !showSub;
        }

        if (!showClosed && notes) {
            notes = notes.filter((note) => {
                return note.status !== 3;
            });
        }

        if (showOptimarNotes && notes) {
            notes = notes.filter((note) => {
                return note.optimarIssue;
            });
        }

        if (!showSub && notes) {
            notes = notes.filter((note) => {
                return (
                    (note.itemId && note.itemId === this.props.match.params.itemId) ||
                    (this.props.match.params.itemId === undefined && note.itemId === null)
                );
            });
        }

        this.setState({
            notes: notes,
            sortDirection: newDirection,
            sortColumn: newSortColumn,
            showClosed: showClosed,
            showOptimarNotes: showOptimarNotes,
            showSub: showSub,
        });
    }

    showComments(note) {
        this.setState({
            showComments: true,
            comments: note.comments,
            cNoteId: note.id,
        });
    }

    showFiles(note) {
        this.setState({
            showFiles: true,
            files: note.documents,
            fNoteId: note.id,
        });
    }

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    handleShowClosedNotesClick() {
        this.setFilteredNotes('showClosedNotesClick');
    }

    handleShowOptimarNotesClick() {
        this.setFilteredNotes('showOptimarNotesClick');
    }

    handleShowSubComponentsClick() {
        this.setFilteredNotes('showSubComponentsClick');
    }

    onShowSizeChange(current, pageSize) {
        this.setState({ pageSize: pageSize, currentPage: current });
    }

    onChange(pageNumber) {
        this.setState({ currentPage: pageNumber });
    }

    renderName(username, displayname) {
        if (this.props.isTablet) {
            if (displayname) {
                if (displayname.length > 10) {
                    return displayname.substr(0, 10) + '...';
                }
                return displayname;
            }

            if (username && username.length > 10) {
                return username.substr(0, 10) + '...';
            }
        }
        return displayname || username;
    }

    createSupport(note) {
        if (note.supportId) {
            this.setState({
                supportRedirect: true,
                modalIsOpen: false,
                supportNote: note,
            });
        } else {
            this.setState({
                supportModalOpen: true,
                modalIsOpen: false,
                supportNote: note,
            });
        }
    }

    rowClick(row) {
        this.setState({
            selectedNote: this.state.notes.filter((c) => c.id === row.original.id)[0],
            modalIsOpen: true,
        });
    }

    render() {
        if (this.state.supportRedirect) {
            return (
                <Redirect to={`/plant/${this.props.plant.id}/support?supportId=${this.state.supportNote.supportId}`} />
            );
        }
        const notes = this.state.notes || [];
        const sNote = this.state.selectedNote || {};
        return (
            <div className="notelist-grid">
                <NotificationContainer />
                <div className="main-header">
                    <span>
                        {this.check() ? <h2>{this.state.notes ? this.state.notes.length : 0} Notes</h2> : null}
                        {this.props.plant ? (
                            <span className="main-header-action" onClick={() => this.openModal('new')}>
                                {' '}
                                <span className="list-expand-toggle">+ Add note</span>{' '}
                            </span>
                        ) : null}
                    </span>
                </div>
                <div className="table-tools">
                    <div className="list-search">
                        <input
                            autoComplete="off"
                            type="text"
                            placeholder="Search"
                            className="search-list"
                            onChange={this.search}
                        />
                        <FontAwesome className="search-icon" name="search" />
                    </div>
                    <div onClick={() => this.handleShowSubComponentsClick()} className="faux-checkbox">
                        <div className={this.state.showSub ? 'checkbox checked' : 'checkbox'}></div>
                        <span>Show subcomponent notes</span>
                    </div>
                    <div
                        style={{ marginLeft: this.check() ? '10px' : '' }}
                        onClick={() => this.handleShowClosedNotesClick()}
                        className="faux-checkbox"
                    >
                        <div className={this.state.showClosed ? 'checkbox checked' : 'checkbox'}></div>
                        <span>Show closed notes</span>
                    </div>
                </div>

                <GenericTable
                    data={notes}
                    pageCount={this.state.pages}
                    columns={this.visibleColumns}
                    localPaging={true}
                    rowClick={this.rowClick}
                />
                <Modal
                    isOpen={this.state.supportModalOpen}
                    onRequestClose={() => this.setState({ supportModalOpen: false })}
                    style={largeModalStyle}
                    ariaHideApp={false}
                >
                    <SupportAddEdit
                        linkedNote={this.state.supportNote}
                        item={this.props.currentItem || { id: this.state.supportNote && this.state.supportNote.itemId }}
                        close={() => {
                            this.setState({ supportModalOpen: false });
                        }}
                    />
                </Modal>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={() => this.setState({ modalIsOpen: false }, this.fetchNotesData)}
                    style={largeModalStyle}
                    ariaHideApp={false}
                >
                    <div key="veryunique12341" className="note-text">
                        <div className="note-left">
                            <h2 className="comment-title">{sNote.title}</h2>
                            <p className="note-content" style={{ fontSize: '15px' }}>
                                {sNote.content}
                            </p>
                            <FileList
                                readOnly={this.props.plant ? false : true}
                                noteId={sNote.id}
                                files={sNote.documents}
                                fetchNotes={this.fetchNotesData}
                            />
                            {this.props.plant ? (
                                <>
                                    <div className="btn-row-grow">
                                        <button
                                            className="btn btn-red btn-grow"
                                            onClick={() => this.confirmDelete(sNote)}
                                        >
                                            Delete
                                        </button>
                                        <button
                                            className="btn btn-blue btn-grow"
                                            onClick={() => this.openModal('edit', sNote)}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                    <div className="btn-row-grow">
                                        {
                                            <button
                                                disabled={!this.props.plant.activeSLA}
                                                onClick={() => this.createSupport(sNote)}
                                                className="btn btn-yellow btn-grow"
                                            >
                                                {sNote.supportId ? 'Open support case' : 'Create support case'}
                                            </button>
                                        }
                                        <button
                                            style={{ marginLeft: '10px' }}
                                            onClick={() => {
                                                this.setState({ modalIsOpen: false });
                                            }}
                                            className="btn btn-yellow"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div className="note-right">
                            <CommentList noteId={sNote.id} comments={sNote.comments} />
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.editModal}
                    style={modalStyle}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <NoteAddEdit
                        triggerFetch={this.state.triggerFetch}
                        match={this.props.match}
                        editType={this.state.editType}
                        editItem={this.state.editItem}
                        closeModal={() => {
                            this.setState({ editModal: false });
                            this.fetchNotesData();
                        }}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plants.currentPlant,
    user: state.user.currentUser,
    notes: state.notes.noteList,
    accountNotes: state.account.noteList,
    isTablet: state.utility.isTablet,
});

const mapDispatchToProps = {
    setNoteList,
    setCurrentPlant,
    clearNoteList,
    setAccountNoteList,
    clearAccountNoteList,
};

export default withRouteMatch(connect(mapStateToProps, mapDispatchToProps)(NoteList));
