/* eslint-disable react/no-unknown-property */
import React from 'react';

const ListIcon = () => {
    return (
        <svg width="6.4069471mm" height="4.9107998mm" viewBox="0 0 8.4069471 6.6107998" version="1.1" id="svg5349">
            <defs id="defs5343" />
            <g label="Layer 1" groupmode="layer" id="layer1" transform="translate(-84.242955,-137.21246)">
                <path
                    className="nav-icon"
                    fill="#919191"
                    connector-curvature="0"
                    d="m 92.649902,138.11053 c 0,0.16631 -0.141363,0.29935 -0.299357,0.29935 h -7.808232 c -0.16631,0 -0.299358,-0.14136 -0.299358,-0.29935 v -0.59872 c 0,-0.16631 0.141364,-0.29935 0.299358,-0.29935 h 7.808232 c 0.166309,0 0.299357,0.14136 0.299357,0.29935 z m 0,1.80446 c 0,0.1663 -0.141363,0.29935 -0.299357,0.29935 h -7.808232 c -0.16631,0 -0.299358,-0.14136 -0.299358,-0.29935 v -0.59872 c 0,-0.16631 0.141364,-0.29936 0.299358,-0.29936 h 7.808232 c 0.166309,0 0.299357,0.14137 0.299357,0.29936 z m 0,1.80445 c 0,0.16631 -0.141363,0.29936 -0.299357,0.29936 h -7.808232 c -0.16631,0 -0.299358,-0.14136 -0.299358,-0.29936 v -0.59871 c 0,-0.16631 0.141364,-0.29936 0.299358,-0.29936 h 7.808232 c 0.166309,0 0.299357,0.14137 0.299357,0.29936 z m 0,1.80446 c 0,0.16631 -0.141363,0.29936 -0.299357,0.29936 h -7.808232 c -0.16631,0 -0.299358,-0.14136 -0.299358,-0.29936 v -0.59871 c 0,-0.16631 0.141364,-0.29936 0.299358,-0.29936 h 7.808232 c 0.166309,0 0.299357,0.14136 0.299357,0.29936 z"
                    id="path4664"
                />
            </g>
        </svg>
    );
};

export default ListIcon;
