export function meta_get_available_customtable_fields(child_jobs, value) {
    const available_fields = [];
    const job = child_jobs?.find((element) => element.id === value);

    if (job && job.configuration) {
        const job_config = JSON.parse(job.configuration);
        if (job_config?.fields)
            job_config.fields.forEach((element) => {
                const sufix = element.type === 'date' ? '#time#HH:mm' : '';
                available_fields.push({ key: element.name + sufix, label: element.label });
            });
    }

    return available_fields;
}

export function meta_get_available_metas(job_type) {
    if (!job_type?.configuration) {
        console.log('jobtype is empty or missing configuration', job_type);
        return [];
    }
    const job_config = JSON.parse(job_type.configuration);

    return job_config?.fields ? job_config.fields : [];
}
