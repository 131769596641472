import 'moment/locale/en-gb';
import React from 'react';
import Select from 'react-select';
import api from '../../../../utility/api';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';

const selectStyle = { 
    menuPortal: (base) => ({ 
        ...base, 
        zIndex: 9999}),
    control: (css, _) => ({ 
        ...css, 
        backgroundColor: "#0d171e",
        color: "#e6e6f0"
    }),
    input: (css, _) => ({ 
        ...css, 
        color: "#e6e6f0"
    }),
    option: (css, _) => ({ 
        ...css, 
        backgroundColor: "#0d171e",
        color: "#e6e6f0"
    }),
    menu: (css, _) => ({
        ...css,
        border: "1px solid #2d4150",
        marginTop: '0px'
    }),
    menuList: (css, _) => ({
        ...css,
        backgroundColor: "#0d171e"
    })
};

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '5vw',
    },
};

export default function SelectDestinationPlant(props) {    
    const [plantToTransfer, setPlantToTransfer] = React.useState(null);
    const [plantOptions, setPlantOptions] = React.useState([]);
    const [isFetching, setIsFetching] = React.useState(false);
    const [currentPlant, setCurrentPlant] = React.useState(null);

    const fetchAccountPlants = async () => {
        const apiUrl = process.env.REACT_APP_API + '/api/plants/accountplants';       

        api.get(apiUrl)
            .then((res) => {
                const accountPlants = (res.data || []);
                const currentPlant = accountPlants
                    .filter(plant => plant.id === props.jobPlantId)
                    .map((plant) => ({ label : plant.name, value : plant.id }))[0];

                const plants = accountPlants
                    .filter(plant => plant.id !== props.jobPlantId)
                    .map((plant) => ({ label : plant.name, value : plant.id }));
                    
                setPlantOptions(plants);
                setCurrentPlant(currentPlant);
            })
            .catch((err) => {
                NotificationManager.error('Error retrieving account plants', 'Failed to retrieve account plants', 5000);
                console.error(err);
            });
    };        
    
    React.useEffect(() => {
        fetchAccountPlants();     
    }, []);

    const transferOperation = () => {
        setIsFetching(true);
        
        const fromPlant = currentPlant.label;
        const toPlant  = plantToTransfer.label;

        const url = `${process.env.REACT_APP_API}/api/jobs/transferOperation/${props.jobId}/${plantToTransfer.value}`;

        api.post(url)
            .then((res) => {
                NotificationManager.success('Operation(s) transferred', `Operation(s) transferred from '${fromPlant}' to '${toPlant}'`, 5000);

                props.fetchJobs();
                props.onToggleModal(false);
            })
            .catch((err) => {
                NotificationManager.error('Error transferring operation(s)', 'Failed to transfer operation(s)', 5000);

                if (err?.response?.data?.transferOperations) {
                    props.redirect({
                        currentPlant : currentPlant,
                        plantToTransfer : plantToTransfer,
                        transferOperations: err.response.data.transferOperations,
                        rootJobId : props.jobId
                    });
                }                
                console.error(err);
            })
            .finally(() => {
                setIsFetching(false);
            });
    };

    const renderButtons = () => {
        const isValidForm = plantToTransfer !== null && !isFetching;
        
        return [
            <button
                key="btnCancelJobAddEdit"
                className="btn btn-yellow btn-grow"
                style={{ marginLeft: '0px' }}
                onClick={() => {
                    props.onToggleModal(false);
                }}
            >
                Cancel
            </button>,
            <button
                key="btnUpdateJobAddEdit"
                disabled={!isValidForm}
                className="btn btn-blue btn-grow"
                value="Submit"
                onClick={() => {
                    transferOperation();
                    setTimeout(() => {
                        props.onToggleModal(false);
                    }, "500");

                    
                }}                
            >
                Transfer
            </button>
        ];
    };

    return (
        <Modal
            ref={props.modalRef}
            isOpen={props.modalOpen}
            style={modalStyle}
            onRequestClose={() => {
                // Fetch jobs when modal is closed to keep in sync
                props.fetchJobs();
                props.onToggleModal(false);
            }}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className="form-container">
                <div className="form-header">
                    <h2>
                        Select destination plant
                    </h2>
                </div>
                <form>
                    <div className="form-group">
                        <label htmlFor="type">Plant</label>
                        <Select
                            isClearable
                            onChange={setPlantToTransfer}
                            options={plantOptions}
                            classNamePrefix={'optimar'}
                            menuPortalTarget={document.body}
                            styles={selectStyle}
                        />
                    </div>
                    <div className="btn-row-grow">{renderButtons()}</div>
                </form>
            </div>
        </Modal>        
    );
}
