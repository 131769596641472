import React from 'react';
import AdminFormulasAddEdit from './AdminFormulasAddEdit';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../utility/api';
import { noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '12vw',
        width: '600px',
    },
};
class AdminFormulas extends React.Component {
    constructor(props) {
        super(props);
        const rowContext = [
            {
                content: <span>Edit formula</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete formula</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];
        this.state = {
            formulas: [],
            visibleColumns: [
                { Header: 'Name', accessor: 'name', filterable: true },
                { Header: 'Formula', accessor: 'formula', filterable: true },
                {
                    Header: () => null,
                    id: 'rowcontext',
                    Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
                },
            ],
            modalIsOpen: false,
            editMode: false,
            editFormula: null,
        };

        this.fetchFormulas = this.fetchFormulas.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        this.fetchFormulas();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchFormulas() {
        if (this.props.user != null) {
            api.get(process.env.REACT_APP_API + '/api/widgetformulas')
                .then((res) => {
                    res.data.sort((a, b) => a.name.localeCompare(b.name));

                    if (this.mounted) {
                        this.setState({
                            formulas: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    editFormula = (itemFromChild) => {
        this.setState({
            editFormula: itemFromChild,
        });
    };

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete formula</h1>
                        <p>
                            You are about to delete this formula
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteFormula(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteFormula = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/widgetformulas/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Formula deleted', 'Formula successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete formula', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchFormulas();
                })
                .catch(noop);
        }
    };

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                editType: mode,
            },
            function () {
                this.fetchFormulas();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchFormulas();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
    }

    render() {
        const formulas = this.state.formulas;
        const visibleColumns = this.state.visibleColumns;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{formulas ? formulas.length : 0} Formulas</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New formula</span>{' '}
                        </span>
                    </span>
                </div>
                <GenericTable data={formulas} columns={visibleColumns} localPaging={true} enableGlobalFilter={true} />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminFormulasAddEdit
                        allFormulas={this.state.formulas}
                        editFormula={this.state.editItem}
                        editedCallback={this.closeModal}
                        cancelCallback={this.closeModal}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminFormulas);
