import React, { useCallback } from 'react';
import Modal from 'react-modal';

import GenericTable from '../../components/common/GenericTable';
import { SketchPicker } from 'react-color';

import { getDefaultProfileConfig } from '../../utility/pdfGenerator/helpers/profiler';

import 'react-dropdown/style.css';

import { ImagePicker } from './ImagePicker';
import { useUploadImage } from './useUploadImage';
import { NotificationManager } from 'react-notifications';
import { useReportImages } from './useReportImages';
import { useDefaultImages } from './useDefaultImages';

const default_values = getDefaultProfileConfig();
const rows_configuration = [
    { key: 'top', label: 'Top Logo', type: 'logo' },
    { key: 'bottom_left', label: 'Bottom Left Logo', type: 'logo' },
    { key: 'bottom_right', label: 'Bottom Right Logo', type: 'logo' },
    { key: 'head_foot_color', label: 'Header & Footer Background Color', type: 'color' },
    { key: 'seperator_line_color', label: 'Separator Line Color', type: 'color' },
    { key: 'checkbox_background_color', label: 'Checkbox Background Color', type: 'color' },
    { key: 'checkbox_check_color', label: 'Checkbox Check Color', type: 'color' },
    { key: 'data_table_header_color', label: 'Data Table Header Background Color', type: 'color' },
    { key: 'data_table_header_text_color', label: 'Data Table Header Text Color', type: 'color' },
    { key: 'data_table_row_color', label: 'Data Table Row Background Color', type: 'color' },
    { key: 'data_table_row_text_color', label: 'Data Table Row Text Color', type: 'color' },
    { key: 'linechart_colors', label: 'Line Chart Colors', type: 'colorlist' },
    { key: 'linechart_limit_color', label: 'Line Chart Limit Line Color', type: 'color' },
    { key: 'barchart_color', label: 'Bar Chart Color', type: 'color' },
    { key: 'histogram_n1_row_color', label: 'Histogram Table Odd Row Color', type: 'color' },
    { key: 'histogram_n2_row_color', label: 'Histogram Table Even Row Color', type: 'color' },
    { key: 'separators', label: 'Separators Sizes', type: 'separators' },
    { key: 'document_header_font', label: 'Document Title Font', type: 'font' },
    { key: 'header_font', label: 'Section Title Font', type: 'font' },
    { key: 'sub_header_font', label: 'Subheader Font', type: 'font' },
    { key: 'datapoint_header_font', label: 'Datapoint Header Font', type: 'font' },
    { key: 'datapoint_text_font', label: 'Datapoint Value Font', type: 'font' },
    { key: 'datatable_header_font', label: 'Datatable Header Font', type: 'font' },
    { key: 'datatable_text_font', label: 'Datatable Content Font', type: 'font' },
];

export default function StyleDesigner(props) {
    const [data, setData] = React.useState(props.data || { logos: {} });
    const { images, isLoading: isLoadingImages, refetch } = useReportImages();
    const { images: defaultImages, isLoading: isDefaultImagesLoading } = useDefaultImages();
    const [style_rows, setStyleRows] = React.useState([]);
    const uploadImage = useUploadImage();

    const is_default_value = useCallback(
        (style_row) => {
            if (!data) return true;

            switch (style_row.type) {
                case 'color':
                case 'colorlist':
                    if (!data.colors) return true;
                    return !data.colors.hasOwnProperty(style_row.key);
                case 'separators':
                    return !data.hasOwnProperty(style_row.key);
                case 'font':
                    if (!data.fonts) return true;
                    return !data.fonts.hasOwnProperty(style_row.key);
                case 'logo':
                    if (!data.logos) return true;
                    return !data.logos.hasOwnProperty(style_row.key);
                default:
                    console.warn('case not handled', style_row.type);
                    return false;
            }
        },
        [data]
    );

    const toggle_default = useCallback(
        (style_row) => {
            if (is_default_value(style_row)) {
                switch (style_row.type) {
                    case 'color':
                    case 'colorlist':
                        if (!data.colors) data.colors = {};
                        data.colors[style_row.key] = JSON.parse(
                            JSON.stringify(default_values[style_row.key.toUpperCase()])
                        );
                        break;
                    case 'separators':
                        data[style_row.key] = [
                            default_values.CHAPTER_LINE_HEIGHT,
                            default_values.SUB_CHAPTER_LINE_HEIGHT,
                            default_values.DATA_POINT_LINE_HEIGHT,
                        ];
                        break;
                    case 'font':
                        if (!data.fonts) data.fonts = {};
                        data.fonts[style_row.key] = default_values[style_row.key.toUpperCase()];
                        break;
                    case 'logo':
                        if (!data.logos) data.logos = {};
                        data.logos[style_row.key] = '';
                        break;
                    default:
                        console.warn('case not handled', style_row.type);
                }
            } else {
                switch (style_row.type) {
                    case 'color':
                    case 'colorlist':
                        delete data.colors[style_row.key];
                        break;
                    case 'separators':
                        delete data[style_row.key];
                        break;
                    case 'font':
                        delete data.fonts[style_row.key];
                        break;
                    case 'logo':
                        delete data.logos[style_row.key];
                        break;
                    default:
                        console.warn('case not handled', style_row.type);
                }
            }

            setData({ ...data });
            props.onDataChange(data);
        },
        [data, is_default_value, props]
    );

    const on_color_change = useCallback(
        (key, value) => {
            data.colors[key] = value;
            setData({ ...data });
            props.onDataChange(data);
        },
        [data, props]
    );

    const on_separator_change = useCallback(
        (key, index, value) => {
            data[key][index] = value;
            setData({ ...data });
            props.onDataChange(data);
        },
        [data, props]
    );

    const on_font_change = useCallback(
        (key, name, value) => {
            data.fonts[key][name] = value;
            setData({ ...data });
            props.onDataChange(data);
        },
        [data, props]
    );

    const on_color_list_change = useCallback(
        (key, index, value) => {
            data.colors[key][index] = value;

            setData({ ...data });
            props.onDataChange(data);
        },
        [data, props]
    );
    const on_image_change = useCallback(
        (key, value) => {
            data.logos[key] = value;

            setData({ ...data });
            props.onDataChange(data);
        },
        [data, props]
    );

    const get_color_editor = useCallback(
        (style_row) => {
            const is_default = is_default_value(style_row);
            const value = is_default ? default_values[style_row.key.toUpperCase()] : data.colors[style_row.key];

            return (
                <MyColorPicker
                    value={value}
                    editable={!is_default}
                    label={style_row.label}
                    onChange={(value) => on_color_change(style_row.key, value)}
                />
            );
        },
        [data.colors, is_default_value, on_color_change]
    );

    const get_colorlist_editor = useCallback(
        (style_row) => {
            const is_default = is_default_value(style_row);
            const value = is_default ? default_values[style_row.key.toUpperCase()] : data.image[style_row.key];

            return (
                <div>
                    {value.map((elem, index) => {
                        return (
                            <div style={{ float: 'left' }} key={'color_pick_' + index}>
                                <MyColorPicker
                                    value={elem}
                                    editable={!is_default}
                                    label={style_row.label}
                                    onChange={(value) => on_color_list_change(style_row.key, index, value)}
                                />
                            </div>
                        );
                    })}
                </div>
            );
        },
        [data.image, is_default_value, on_color_list_change]
    );

    const get_separators_editor = useCallback(
        (style_row) => {
            const is_default = is_default_value(style_row);
            const value = is_default
                ? [
                      default_values.CHAPTER_LINE_HEIGHT,
                      default_values.SUB_CHAPTER_LINE_HEIGHT,
                      default_values.DATA_POINT_LINE_HEIGHT,
                  ]
                : data[style_row.key];

            return (
                <MySeparatorEditor
                    value={value}
                    editable={!is_default}
                    onChange={(index, value) => on_separator_change(style_row.key, index, value)}
                />
            );
        },
        [data, is_default_value, on_separator_change]
    );

    const get_font_editor = useCallback(
        (style_row) => {
            const is_default = is_default_value(style_row);
            const value = is_default ? default_values[style_row.key.toUpperCase()] : data.fonts[style_row.key];

            return (
                <MyFontEditor
                    value={value}
                    editable={!is_default}
                    onChange={(name, value) => on_font_change(style_row.key, name, value)}
                />
            );
        },
        [data.fonts, is_default_value, on_font_change]
    );

    const get_image_selector = useCallback(
        (style_row) => {
            const is_default = is_default_value(style_row);

            const handleImageSubmit = async (uploadDetails, style_row) => {
                const notificationTitle = 'Image upload';
                try {
                    const blobUrl = await uploadImage(uploadDetails);
                    NotificationManager.success('Image was uploaded successfully', notificationTitle);
                    const { key } = style_row;
                    if (key) {
                        const updatedData = { ...data, logos: { ...data.logos, [key]: blobUrl } };
                        setData(updatedData);
                        props.onDataChange(updatedData);
                    }
                    await refetch();
                } catch (error) {
                    NotificationManager.error('Something went wrong', notificationTitle);
                }
            };

            if (style_row.type !== 'logo') {
                console.warn('Row is not of type image');
                return null;
            }

            const commonImagePickerProps = {
                imageUrl: data.logos[style_row.key],
                label: style_row.label,
                editable: !is_default,
                images,
                defaultImages,
                isLoadingImages,
                isDefaultImagesLoading,
            };

            switch (style_row.key) {
                case 'top':
                    return (
                        <ImagePicker
                            {...commonImagePickerProps}
                            imageSize={{ width: '586px', height: '213px' }}
                            imageType={'LogoTop'}
                            onSubmit={(imageBlobUrl, filename) =>
                                handleImageSubmit({ filename, blobUrl: imageBlobUrl, imageType: 'LogoTop' }, style_row)
                            }
                            onImageChange={(value) => on_image_change(style_row.key, value)}
                        />
                    );
                case 'bottom_left':
                    return (
                        <ImagePicker
                            {...commonImagePickerProps}
                            imageSize={{ width: '345px', height: '130px' }}
                            imageType={'LogoBottomLeft'}
                            onSubmit={(imageBlobUrl, filename) =>
                                handleImageSubmit(
                                    { filename: filename, blobUrl: imageBlobUrl, imageType: 'LogoBottomLeft' },
                                    style_row
                                )
                            }
                            onImageChange={(value) => on_image_change(style_row.key, value)}
                        />
                    );
                case 'bottom_right':
                    return (
                        <ImagePicker
                            {...commonImagePickerProps}
                            imageSize={{ width: '350px', height: '130px' }}
                            imageType={'LogoBottomRight'}
                            onSubmit={(imageBlobUrl, filename) =>
                                handleImageSubmit(
                                    { filename: filename, blobUrl: imageBlobUrl, imageType: 'LogoBottomRight' },
                                    style_row
                                )
                            }
                            onImageChange={(value) => on_image_change(style_row.key, value)}
                        />
                    );
                default:
                    console.warn('No handler for', style_row.key, style_row);
                    return null;
            }
        },
        [
            data,
            images,
            defaultImages,
            isDefaultImagesLoading,
            isLoadingImages,
            is_default_value,
            on_image_change,
            props,
            refetch,
            uploadImage,
        ]
    );

    const get_generic_style_editor = useCallback(
        (style_row) => {
            switch (style_row.type) {
                case 'color':
                    return get_color_editor(style_row);
                case 'colorlist':
                    return get_colorlist_editor(style_row);
                case 'separators':
                    return get_separators_editor(style_row);
                case 'font':
                    return get_font_editor(style_row);
                case 'logo':
                    return get_image_selector(style_row);
                default:
                    return null;
            }
        },
        [get_color_editor, get_colorlist_editor, get_font_editor, get_image_selector, get_separators_editor]
    );

    React.useEffect(() => {
        const rows = JSON.parse(JSON.stringify(rows_configuration));

        rows.forEach((elem, index) => {
            rows[index].default = is_default_value(elem);
            rows[index].editor = get_generic_style_editor(elem);
        });

        setStyleRows(rows);
    }, [get_generic_style_editor, is_default_value]);

    // styles table columns definition
    const style_table_columns = [
        { Header: 'Name', accessor: 'label', disableSortBy: true, disableFilters: true },
        {
            Header: 'Use default value',
            //id: 'rowcontext',
            Cell: (row) => {
                return (
                    <div className="form-group">
                        <input
                            type="checkbox"
                            className={'input-control'}
                            name={'use_default'}
                            onChange={() => toggle_default(row.row.original)}
                            checked={row.row.original.default}
                        />
                    </div>
                );
            },
        },
        { Header: 'Value', accessor: 'editor', disableSortBy: true, disableFilters: true },
    ];

    return <GenericTable data={style_rows} columns={style_table_columns} localPaging={true} />;
}

function MyColorPicker(props) {
    const [value, setValue] = React.useState(props.value);
    const [editable, setEditable] = React.useState(props.editable);
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setValue(props.value);
        setEditable(props.editable);
    }, [props]);

    const show_color_picker = () => {
        if (editable) setDisplay(!display);
    };

    const close_color_picker = () => {
        setDisplay(false);
    };

    const on_color_change = (color) => {
        setValue(color.hex);
        props.onChange(color.hex);
    };

    return (
        <>
            <div
                onClick={show_color_picker}
                // eslint-disable-next-line react/no-unknown-property
                background={value}
                style={{
                    width: '50px',
                    height: '50px',
                    background: `${value}`,
                    cursor: `${editable ? 'pointer' : 'default'}`,
                }}
            />
            <Modal
                isOpen={display}
                onRequestClose={close_color_picker}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                className={'auto_modal_content'}
            >
                <h2>{props.label}</h2>
                <SketchPicker color={value} onChange={on_color_change} />
                <button onClick={close_color_picker} className="btn btn-blue">
                    OK
                </button>
            </Modal>
        </>
    );
}

function MyFontEditor(props) {
    const [value, setValue] = React.useState(props.value);
    const [editable, setEditable] = React.useState(props.editable);

    React.useEffect(() => {
        setValue(props.value);
        setEditable(props.editable);
    }, [props]);

    const toggle_bold = () => {
        if (editable) {
            value.bold = !value.bold;
            setValue({ ...value });

            props.onChange('bold', value.bold);
        }
    };

    const on_size_change = (name, val) => {
        if (editable && val > 0) {
            value.fontSize = val;
            setValue({ ...value });

            props.onChange(name, val);
        }
    };

    return (
        <>
            <div className="form-group">
                Bold:{' '}
                <input
                    type="checkbox"
                    className={'input-control'}
                    name={'bold'}
                    onChange={toggle_bold}
                    checked={value.bold}
                />
            </div>
            <div className="form-group">
                Font Size:{' '}
                <input
                    type="number"
                    className={'input-control'}
                    name={'fontSize'}
                    onChange={(event) => on_size_change(event.currentTarget.name, event.currentTarget.value)}
                    value={value.fontSize}
                />
            </div>
        </>
    );
}

function MySeparatorEditor(props) {
    const [value, setValue] = React.useState(props.value);
    const [editable, setEditable] = React.useState(props.editable);

    React.useEffect(() => {
        setValue(props.value);
        setEditable(props.editable);
    }, [props]);

    const on_size_change = (index, val) => {
        if (editable && val > 0) {
            value[index] = val;
            setValue([...value]);

            props.onChange(index, val);
        }
    };

    return (
        <>
            <div className="form-group">
                Chapter Line Height:{' '}
                <input
                    type="number"
                    className={'input-control'}
                    name={'sep1'}
                    onChange={(event) => on_size_change(0, event.currentTarget.value)}
                    value={value[0]}
                />
            </div>
            <div className="form-group">
                Sub Chapter Line Height:{' '}
                <input
                    type="number"
                    className={'input-control'}
                    name={'sep2'}
                    onChange={(event) => on_size_change(1, event.currentTarget.value)}
                    value={value[1]}
                />
            </div>
            <div className="form-group">
                Datapoint Line Height:{' '}
                <input
                    type="number"
                    className={'input-control'}
                    name={'sep3'}
                    onChange={(event) => on_size_change(2, event.currentTarget.value)}
                    value={value[2]}
                />
            </div>
        </>
    );
}
