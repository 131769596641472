import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import api from '../../../../utility/api';
import './FeedbackModal.scss';

class FeedbackModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: '',
        };
        this.submit = this.submit.bind(this);
    }

    submit(e) {
        e.preventDefault();

        api.post(`/api/feedback`, this.state.feedback)
            .then((res) => {
                if (res.status === 200) {
                    NotificationManager.success('Feedback submitted successfully', 'Success');
                    this.props.closeModal();
                } else {
                    NotificationManager.error(res.data, 'Error');
                }
            })
            .catch((res) => {
                console.log(res);
                NotificationManager.error(res.data, 'Error');
            });
    }

    render() {
        return (
            <div className="profilemodal">
                <h3 className="profile-title">Feedback</h3>
                <form className="profile-form" onSubmit={this.submit}>
                    <label htmlFor="feedback">Feedback:&nbsp;</label>
                    <textarea
                        name="feedback"
                        value={this.state.feedback}
                        minLength={1}
                        onChange={(e) => this.setState({ feedback: e.target.value })}
                        required
                        className="input-control"
                        type="text"
                    />

                    <button type="submit" className="btn btn-blue">
                        Send
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.closeModal();
                        }}
                        type="submit"
                        className="btn btn-yellow btn-grow"
                    >
                        Close
                    </button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default connect(mapStateToProps)(FeedbackModal);
