import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import fileIcons from './filetype-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';

export function getHeader(
    data,
    itemId,
    {
        showSubDocs,
        showProjectDocs,
        showArticleDocs,
        enableFiltering,
        hasItemIdFilter,
        hasProjectDocFilter,
        hasArticleDocFilter,
    }
) {
    const header = {
        left: [
            <div
                key="showSubDocs"
                onClick={() => showSubDocs()}
                className={'list-filter-check faux-checkbox ' + (enableFiltering() ? 'disabled' : '')}
            >
                <div className={hasItemIdFilter() ? 'checkbox checked' : 'checkbox'}></div>
                <span>Show sub docs</span>
            </div>,
        ],
        right: [],
    };
    if (!itemId) {
        //only show these filters if we are not on a item page?
        header.left.push.apply(header.left, [
            <div
                key="showProjDocs"
                onClick={() => showProjectDocs()}
                className={'list-filter-check faux-checkbox ' + (enableFiltering() ? 'disabled' : '')}
            >
                <div className={hasProjectDocFilter() ? 'checkbox checked' : 'checkbox'}></div>
                <span>Show project docs</span>
            </div>,
            <div
                key="showArticleDocs"
                onClick={() => showArticleDocs()}
                className={'list-filter-check faux-checkbox ' + (enableFiltering() ? 'disabled' : '')}
            >
                <div className={hasArticleDocFilter() ? 'checkbox checked' : 'checkbox'}></div>
                <span>Show article docs</span>
            </div>,
        ]);
    }
    return header;
}

export function getColumns(
    props,
    fetchSingleDoc,
    confirmDelete,
    editFile,
    openFile,
    hideDocModifyContextMenuFunctions
) {
    const showPlantField = props.plant === undefined; // typeof(props.plant) === "undefined";

    const rowContext = [
        {
            content: <span>Open</span>,
            action: (row) => openFile(row.original, 'open'),
            // eslint-disable-next-line
            hideContext: () => false,
        },
        {
            content: <span>Edit</span>,
            action: (row) => editFile(row.original, 'edit'),
            hideContext: hideDocModifyContextMenuFunctions,
        },
        {
            content: <span>Delete</span>,
            action: (row) => confirmDelete(row.original),
            hideContext: hideDocModifyContextMenuFunctions,
        },
    ];

    const createLink = (itemId, name, row) => {
        const plantId = row?.original?.plantId || props.plant?.id;
        if (!itemId || !plantId) return <div className="list-item-title">{name}</div>;
        console.log(row);
        return (
            <div className="list-item-title">
                <Link to={`/plant/${plantId}/items/${itemId}/overview`}>{name}</Link>
            </div>
        );
    };

    const createFileLink = (doc) => {
        return doc.link && !doc.external ? (
            <a className="link-link" href={doc.link} target="_blank" rel="noopener noreferrer">
                <h4>
                    <FontAwesomeIcon icon={['fas', 'link']} />
                    {doc.filename}
                </h4>
            </a>
        ) : (
            <h4 className="document-link" onClick={() => fetchSingleDoc(doc)}>
                <FontAwesomeIcon
                    icon={[
                        'far',
                        fileIcons.fileTypes.filter(
                            (f) => f.ext.indexOf(doc.filename.toLowerCase().split('.').pop()) > -1
                        )[0]?.icon,
                    ]}
                />
                {doc.filename}
            </h4>
        );
    };

    const columns = [
        {
            Header: 'Date',
            accessor: 'createdAt',
            Cell: (row) => <div>{row.value ? moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss') : null}</div>,
        },
        {
            Header: 'Filename',
            accessor: 'filename',
            Cell: (cellInfo) => createFileLink(cellInfo.data[cellInfo.row.index]),
        },
        {
            Header: 'Title',
            accessor: 'documentTitle',
        },
        {
            Header: 'Type',
            accessor: 'documentType',
        },
        {
            Header: 'Component',
            accessor: 'itemName',
            Cell: (cellInfo) => {
                if (cellInfo.data[cellInfo.row.index].itemName) {
                    return createLink(
                        cellInfo.data[cellInfo.row.index].itemId,
                        cellInfo.data[cellInfo.row.index].itemName,
                        cellInfo.row
                    );
                } else {
                    return cellInfo.data[cellInfo.row.index].articleItems;
                }
            },
        },
        // {
        //     Header: 'Article',
        //     accessor: 'article'
        // },
        {
            Header: 'Uploaded by',
            accessor: 'createdByName',
        },
        {
            Header: 'Project No',
            accessor: 'projectId',
            Cell: (row) => <div>{row.value && row.value !== 0 ? row.value : null}</div>,
        },
        {
            Header: 'Pos',
            accessor: 'pos',
        },
        {
            // eslint-disable-next-line
            Header: () => null,
            id: 'rowcontext',
            Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
        },
    ];
    if (showPlantField) {
        columns.splice(columns.length - 1, 0, {
            Header: 'Plant',
            accessor: 'plantName',
        });
    }

    return columns;
}
