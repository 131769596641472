export const getValueWithPrecisionOrDefault = (value, precision, defaultVal) => {
    //I have seen code send in strings as default val..
    //So we have to always check if the function toFixed is available..
    //console.log("getValueWithPrecisionOrDefault",value,typeof(value),precision,typeof(precision));
    if (typeof defaultVal === 'undefined' || defaultVal == null) {
        defaultVal = 0;
    }
    if (typeof precision === 'undefined') {
        precision = 0;
    }
    if (typeof precision !== 'number') {
        precision = toNumber(precision, 0);
    }
    if (typeof value === 'undefined' || value == null) {
        value = defaultVal;
    }
    if (!value.toFixed) {
        // console.log("Value missing toFixed function: "+value + " typeof: "+typeof(value));
        return value;
    }

    return value.toFixed(precision);
};
export const toNumber = (value, defaultVal = 0) => {
    if (typeof value === 'undefined' || value == null) return defaultVal;
    if (typeof value == 'number' && !isNaN(value)) return value;
    const numberVal = Number(value);

    return isNaN(numberVal) ? defaultVal : numberVal;
};

export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
};

const localizedIdCache = [];
export const newLocalizedId = () => {
    let i = 0;
    while (i < 100) {
        const rand = getRandomInt(100000);
        if (localizedIdCache.indexOf(rand) >= 0) {
            i++;
            continue;
        }
        localizedIdCache.push(rand);
        return rand;
    }
    return -1;
};
