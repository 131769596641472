import React from 'react';
import './AdminTags.css';
import AdminTagsAddEdit from '../../components/common/AdminTagsAddEdit/AdminTagsAddEdit';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import 'react-table-6/react-table.css';
import Modal from 'react-modal';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../utility/api';
import { noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '12vw',
        width: '600px',
    },
};
class AdminTags extends React.Component {
    constructor(props) {
        super(props);
        const rowContext = [
            {
                content: <span>Edit keyword</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete keyword</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];
        this.state = {
            showAddTagSection: false,
            tags: [],
            visibleColumns: [
                { Header: 'Title', accessor: 'title', filterable: true },
                {
                    Header: 'Plants',
                    accessor: 'plants',
                    filterable: true,
                    Cell: (props) => (
                        <span>{props.value ? props.value?.map((t) => t.label).join(', ') : 'All plants'}</span>
                    ),
                },
                {
                    Header: 'Modified',
                    accessor: 'modifiedOn',
                    Cell: (props) => <span>{moment(props.value).format('DD.MM.YYYY HH:mm')}</span>,
                    filterable: true,
                },
                {
                    Header: 'Document',
                    accessor: 'documentEnabled',
                    width: 100,
                    filterable: true,
                    Cell: (row) => (
                        <div style={{ textAlign: 'left' }}>{!row.value || row.value === 'false' ? '' : '✓'}</div>
                    ),
                },
                {
                    Header: 'Note',
                    accessor: 'noteEnabled',
                    width: 100,
                    filterable: true,
                    Cell: (row) => (
                        <div style={{ textAlign: 'left' }}>{!row.value || row.value === 'false' ? '' : '✓'}</div>
                    ),
                },
                {
                    Header: () => null,
                    id: 'rowcontext',
                    Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
                },
            ],
            modalIsOpen: false,
            editMode: false,
            editTag: null,
        };

        this.fetchTags = this.fetchTags.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        this.fetchTags();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.tags) {
            this.fetchTags();
        }
    }

    fetchTags() {
        if (this.props.user != null) {
            api.get(process.env.REACT_APP_API + '/api/admin/tags')
                .then((res) => {
                    res.data.sort((a, b) => a.title.localeCompare(b.title));

                    if (this.mounted) {
                        this.setState({
                            tags: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    editTag = (itemFromChild) => {
        this.setState({
            editTag: itemFromChild,
        });
    };

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete keyword</h1>
                        <p>
                            You are about to delete the keyword
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.title}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteTag(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteTag = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/tags/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Tag deleted', 'Tag successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete tag', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchTags();
                })
                .catch(noop);
        }
    };

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                editType: mode,
            },
            function () {
                this.fetchTags();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchTags();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]?.toLowerCase()).includes(filter.value?.toLowerCase()) : true;
    }

    render() {
        const tags = this.state.tags;
        const visibleColumns = this.state.visibleColumns;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{tags ? tags.length : 0} Keywords</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New keyword</span>{' '}
                        </span>
                    </span>
                </div>
                <GenericTable enableGlobalFilter={true} data={tags} columns={visibleColumns} localPaging={true} />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminTagsAddEdit
                        editTag={this.state.editItem}
                        editedCallback={this.closeModal}
                        cancelCallback={this.closeModal}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminTags);
