import React from 'react';
import '../widget.css';
import './LineChartDotted.scss';
import { Tooltip, Area, AreaChart, XAxis, YAxis, ReferenceLine, ResponsiveContainer, LineChart, Line } from 'recharts';
import moment from 'moment';
import { connect } from 'react-redux';
import LeftNav from '../../../styles/icons/LeftNav';
import RightNav from '../../../styles/icons/RightNav';
import api from '../../../utility/api';
import { forEach, maxBy } from 'lodash';

class LineChartDotted extends React.Component {
    constructor(props) {
        super(props);
        this.formatXAxis = this.formatXAxis.bind(this);
        this.state = {
            left: 'active',
            right: 'deactivated',
            selectedTab: 0,
            area: null,
        };
        this.mounted = true;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.data && this.props.user && JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        area: res,
                    });
                }
            });
        }
    }

    componentDidMount() {
        if (this.props.user && !this.props.data) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        area: res,
                    });
                }
            });

            this.interval = setInterval(() => {
                this.getChartInfo((res) => {
                    if (this.mounted) {
                        this.setState({
                            area: res,
                        });
                    }
                });
            }, this.props.refreshInterval || 120000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.mounted = false;
    }

    getChartInfo = (callback) => {
        let to =
            this.props.periodParent.ticks === 'day'
                ? moment(this.props.endDateParent)
                : moment(this.props.endDateParent).minute(0).second(0).millisecond(0);
        if (!this.props.endDateParent) {
            to =
                this.props.periodParent.ticks === 'day'
                    ? moment().hour(0).minute(0).second(0).millisecond(0).utc()
                    : moment().minute(0).second(0).millisecond(0).utc();
        }
        const from =
            this.props.periodParent.ticks === 'day'
                ? to
                      .clone()
                      .add(-this.props.periodParent.period, this.props.periodParent.interval)
                      .add(moment().utcOffset(), 'minutes')
                : to
                      .clone()
                      .minute(0)
                      .second(0)
                      .millisecond(0)
                      .add(-this.props.periodParent.period, this.props.periodParent.interval);

        const daterange =
            '&from=' +
            (this.props.job ? this.props.job.startDate.toISOString() : from.toISOString()) +
            '&to=' +
            (this.props.job ? this.props.job.endDate.toISOString() : to.toISOString());

        let dataType;
        let dataValue;
        let dataValueFilter = '';
        let timeInterval;
        let tabComponents;

        if (this.props.configuration && this.props.configuration.tabs) {
            dataType = this.props.configuration.tabs[this.state.selectedTab].dataType;
            dataValue = this.props.configuration.tabs[this.state.selectedTab].dataValue;

            tabComponents = this.props.configuration.tabs[this.state.selectedTab].components;

            if (this.props.configuration.tabs[this.state.selectedTab].dataValueFilter)
                dataValueFilter = this.props.configuration.tabs[this.state.selectedTab].dataValueFilter;

            if (this.props.configuration.tabs[this.state.selectedTab].timeInterval)
                timeInterval = this.props.configuration.tabs[this.state.selectedTab].timeInterval;
        } else {
            dataType = this.props.dataType;
            dataValue = this.props.dataValue;
        }

        const components = this.props.components[0] !== '' ? this.props.components : tabComponents;

        let apiUrl =
            process.env.REACT_APP_API +
            '/api/kpi/range?machinenumber=' +
            this.props.machineNumber +
            '&valueFormat=range' +
            daterange +
            '&rangeformat=' +
            (timeInterval || this.props.periodParent.ticks) +
            '&dataType=' +
            dataType +
            '&dataValue=' +
            dataValue +
            '&dataValueFilter=' +
            dataValueFilter;
        forEach(components, (component) => {
            apiUrl += '&componentId=' + component;
            // apiUrl += "&componentId=PUPTXQ/Factory/RSWTank/213PS"
        });

        if (this.mounted) this.setState({ loading: true });

        api.get(apiUrl)
            .then((res) => {
                const json = res.data;
                if (timeInterval && timeInterval === 'week') {
                    json.data.map((p) => {
                        p.weekNumber = moment(p.date).isoWeek();
                        p.value = dataValueFilter === 'dead' ? p.value * -1 : p.value;
                        return p;
                    });

                    if (this.mounted) {
                        this.setState({ loading: false });
                    }

                    return callback(json.data);
                } else {
                    //FIX NULL VALUES
                    const range =
                        this.props.periodParent.ticks === 'day'
                            ? this.enumerateDaysBetweenDates(from, to)
                            : this.enumerateHoursBetweenDates(from, to);

                    const fixedArr = json.data.map((item) => {
                        item.value = item.value ? item.value.toFixed(2) : item.value;
                        return item;
                    });

                    const byDate = fixedArr.reduce((res, item) => {
                        res[new Date(item.date).toISOString()] = item;
                        return res;
                    }, {});
                    // eslint-disable-next-line
                    for (const r of range) {
                        if (!byDate[r]) {
                            byDate[r] = { date: r, value: null };
                        }
                    }

                    if (this.mounted) {
                        this.setState({ loading: false });
                    }

                    return callback(Object.values(byDate).sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date))));
                }
            })
            .catch((err) => {
                console.error(err);
                this.setState({ loading: false });
            });
    };

    enumerateDaysBetweenDates(startDate, endDate) {
        const now = startDate.clone(),
            dates = [];

        while (now.isBefore(endDate)) {
            dates.push(now.format('YYYY-MM-DDT00:00:00.000') + 'Z');
            now.add(1, 'days');
        }
        return dates;
    }

    enumerateHoursBetweenDates(startDate, endDate) {
        const now = startDate.clone(),
            dates = [];

        while (now.isBefore(endDate)) {
            dates.push(now.minute(0).seconds(0).milliseconds(0).toISOString());
            now.add(1, 'hours');
        }
        return dates;
    }

    scrollChart(direction, e) {
        const chart = e.currentTarget.parentNode.parentNode;

        if (direction === 'left') {
            chart.scrollLeft = chart.scrollLeft - 50;
        } else {
            chart.scrollLeft = chart.scrollLeft + 50;
        }

        if (chart.scrollLeft === 0) {
            this.setState({
                left: 'deactivated',
                right: 'active',
            });
        } else {
            if (chart.scrollLeft === chart.scrollWidth - chart.clientWidth) {
                this.setState({
                    left: 'active',
                    right: 'deactivated',
                });
            } else {
                this.setState({
                    left: 'active',
                    right: 'active',
                });
            }
        }
    }

    formatXAxis(tickItem) {
        if (
            this.props.configuration &&
            this.props.configuration.tabs &&
            this.props.configuration.tabs[this.state.selectedTab].timeInterval
        ) {
            return tickItem;
        } else {
            if (this.props.periodParent) {
                if (this.props.periodParent.ticks === 'hour') {
                    return moment(tickItem).format('HH:mm');
                } else {
                    return moment(tickItem).format('DD.MMM');
                }
            }
        }

        return moment(tickItem).format('DD.MMM HH:mm');
    }

    formatYAxis(tickItem) {
        return tickItem.toFixed(0);
    }

    render() {
        const res = this.state.area;

        if (!res) {
            return <div></div>;
        }

        function CustomTooltip(props) {
            if (!props.payload || props.payload.length === 0) {
                return '';
            } else {
                return (
                    <div>
                        {moment(props.payload[0].payload.date).format('HH:mm')} - {props.payload[0].payload.value}
                    </div>
                );
            }
        }

        function SpreadTooltip(props) {
            if (!props.payload || props.payload.length === 0) {
                return '';
            } else {
                return (
                    <div className="tooltip-box">
                        <div className="tooltip-date">{moment(props.payload[0].payload.date).format('ddd MMM Do')}</div>

                        {/* <div className="tooltip-label spread">High</div>
                        <div className="tooltip-value">{props.payload[0].payload.value}</div> */}
                        <div className="tooltip-label">Average</div>
                        <div className="tooltip-value">{props.payload[0].payload.value.toFixed(2)}</div>
                        {/* <div className="tooltip-label spread">Low</div>
                        <div className="tooltip-value">{props.payload[0].payload.value}</div> */}
                    </div>
                );
            }
        }

        return this.createChart(res, SpreadTooltip, CustomTooltip);
    }

    setStateSync(tab) {
        return new Promise((resolve) => {
            this.setState({ selectedTab: tab }, () => resolve());
        });
    }

    async setSelectedTab(tab) {
        await this.setStateSync(tab);

        this.getChartInfo((res) => {
            if (this.mounted) {
                this.setState({
                    area: res,
                });
            }
        });
    }

    createChart(res, SpreadTooltip, CustomTooltip) {
        return (
            <div className="widget">
                {this.props.configuration.tabs ? (
                    <div className="tabs">
                        {this.props.configuration.tabs.map((tab, i) => {
                            return (
                                <div
                                    onClick={() => {
                                        this.setState({ area: null });
                                        this.setSelectedTab(i);
                                    }}
                                    className={this.state.selectedTab === i ? 'selected tab' : 'tab'}
                                    key={'tab' + tab.name}
                                >
                                    {tab.name}
                                </div>
                            );
                        })}
                        {this.state.loading ? (
                            <div className="tab">
                                <span className="status-info">Fetching data</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {!this.state.loading && (res && res.length) === 0 ? (
                            <div className="tab">
                                <span className="status-info">No data</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <div></div>
                )}

                {this.props.spreadChart ? (
                    <div className="widget-content line-chart">
                        <ResponsiveContainer height={400} width="100%">
                            <LineChart className={'inner-line'} width={1600} height={300} data={res}>
                                <YAxis
                                    orientation="left"
                                    padding={{ top: 20 }}
                                    strokeWidth={0}
                                    dx={10}
                                    stroke="#e6e6f0"
                                    tickFormatter={this.formatYAxis}
                                    domain={[
                                        0,
                                        res
                                            ? maxBy(res, function (o) {
                                                  return o.value;
                                              })
                                                ? (
                                                      maxBy(res, function (o) {
                                                          return o.value;
                                                      }).value * 1.1
                                                  ).toFixed(2)
                                                : 0
                                            : 0,
                                    ]}
                                />
                                <XAxis
                                    stroke="#e6e6f0"
                                    padding={{ left: 20 }}
                                    strokeWidth={0}
                                    dy={10}
                                    dataKey="weekNumber"
                                    tickFormatter={this.formatXAxis}
                                />
                                )
                                <Tooltip content={<SpreadTooltip />} />
                                <Line
                                    isAnimationActive={false}
                                    type="monotone"
                                    dataKey="value"
                                    stroke="#fff"
                                    activeDot={{ r: 2 }}
                                />
                                {/* <Line type="monotone" dataKey="value" stroke="#c9b54e" dot={{ fill: '#35C4EC', r: 0 }} activeDot={{ r: 2 }} />
                                    <Line type="monotone" dataKey="value" stroke="#c9b54e" dot={{ fill: '#35C4EC', r: 0 }} activeDot={{ r: 2 }} /> */}
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                ) : this.props.periodParent && ![6, 7, 12].includes(this.props.periodParent.period) ? (
                    <div className="widget-content line-chart">
                        <AreaChart
                            className={'inner-line'}
                            width={1600}
                            height={300}
                            data={res}
                            margin={{ top: 60, right: 70, bottom: 5 }}
                        >
                            <defs>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="8%" stopColor="#0B2731" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#071B23" stopOpacity={1} />
                                </linearGradient>
                            </defs>
                            <Tooltip content={<CustomTooltip />} />
                            <YAxis
                                orientation="right"
                                strokeWidth={0}
                                dx={10}
                                stroke="#2F515E"
                                domain={[
                                    0,
                                    res
                                        ? maxBy(res, function (o) {
                                              return o.value;
                                          })
                                            ? maxBy(res, function (o) {
                                                  return o.value;
                                              }).value
                                            : 0
                                        : 0,
                                ]}
                            />
                            <XAxis
                                stroke="#2F515E"
                                strokeWidth={0}
                                dy={10}
                                dataKey="date"
                                tickFormatter={this.formatXAxis}
                            />
                            )
                            {res.map((d) => {
                                return <ReferenceLine key={d.date} x={d.date} stroke="#325663" strokeDasharray="2 2" />;
                            })}
                            <Area
                                fillOpacity="0.7"
                                isAnimationActive={false}
                                type="monotone"
                                dataKey="value"
                                stroke="#35C4EC"
                                fill="url(#colorPv)"
                                dot={{ fill: '#35C4EC', r: 4 }}
                                activeDot={{ r: 2 }}
                            />
                        </AreaChart>
                        <div className="line-nav">
                            <div
                                className={'left-nav ' + (this.state.left === 'active' ? 'active' : '')}
                                onClick={(e) => this.scrollChart('left', e)}
                            >
                                <LeftNav />
                            </div>
                            <div
                                className={'right-nav ' + (this.state.right === 'active' ? 'active' : '')}
                                onClick={(e) => this.scrollChart('right', e)}
                            >
                                <RightNav />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="widget-content line-chart">
                        <ResponsiveContainer height={200}>
                            <AreaChart
                                className={'inner-line'}
                                width={1600}
                                height={300}
                                data={res}
                                margin={{ top: 60, right: 70, left: 70, bottom: 5 }}
                            >
                                <defs>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="8%" stopColor="#0B2731" stopOpacity={1} />
                                        <stop offset="95%" stopColor="#071B23" stopOpacity={1} />
                                    </linearGradient>
                                </defs>
                                <Tooltip content={<CustomTooltip />} />
                                <YAxis
                                    orientation="right"
                                    strokeWidth={0}
                                    dx={10}
                                    stroke="#2F515E"
                                    domain={[
                                        0,
                                        res
                                            ? maxBy(res, function (o) {
                                                  return o.value;
                                              })
                                                ? maxBy(res, function (o) {
                                                      return o.value;
                                                  }).value
                                                : 0
                                            : 0,
                                    ]}
                                />
                                <XAxis
                                    stroke="#2F515E"
                                    strokeWidth={0}
                                    dy={10}
                                    dataKey="date"
                                    tickFormatter={this.formatXAxis}
                                />
                                )
                                {res.map((d) => {
                                    return (
                                        <ReferenceLine key={d.date} x={d.date} stroke="#325663" strokeDasharray="2 2" />
                                    );
                                })}
                                <Area
                                    fillOpacity="0.7"
                                    isAnimationActive={false}
                                    type="monotone"
                                    dataKey="value"
                                    stroke="#35C4EC"
                                    fill="url(#colorPv)"
                                    dot={{ fill: '#35C4EC', r: 4 }}
                                    activeDot={{ r: 2 }}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    endDate: state.itemrow.endDate,
    endDateParent: state.itemrow.endDateParent,
    period: state.itemrow.period || { period: 12, interval: 'hours', ticks: 'hour' },
    periodParent: state.itemrow.periodParent || { period: 12, interval: 'hours', ticks: 'hour' },
});

export default connect(mapStateToProps)(LineChartDotted);
