import React from 'react';
import Modal from 'react-modal';

import { confirmAlert } from 'react-confirm-alert';

import ItemsFactory from './items/ItemsFactory';
import { ItemEdit, ItemNew } from './items/ItemsFactory';
import { isRepeaterSection, getSectionTypeLabel } from '../../utility/pdfGenerator/helpers/section';

export default function SectionDesigner(props) {
    const data = props.section;
    const [is_new_item_modal_open, setNewItemModalOpen] = React.useState(false);
    const [new_item_index, setNewItemIndex] = React.useState(null);
    const [is_edit_item_modal_open, setEditItemModalOpen] = React.useState(false);
    const [edit_item_index, setEditItemIndex] = React.useState(null);

    const onSectionTitleChange = (event) => {
        data.title = event.target.value;
        props.onSectionChange(props.index, data);
    };

    const openNewItemModal = (index) => {
        setNewItemModalOpen(true);
        setNewItemIndex(index);
    };

    const closeNewItemModal = () => {
        setNewItemModalOpen(false);
        setNewItemIndex(null);
    };

    const openEditItemModal = (index) => {
        setEditItemModalOpen(true);
        setEditItemIndex(index);
    };

    const closeEditItemModal = () => {
        setEditItemModalOpen(false);
        setEditItemIndex(null);
    };

    const saveItem = (item) => {
        if (JSON.stringify(data.items[edit_item_index]) !== JSON.stringify(item)) {
            data.items[edit_item_index] = item;
            props.onSectionChange(props.index, data);
        }
    };

    const addNewItem = (item) => {
        const new_items = data.items;
        new_items.splice(new_item_index, 0, item);

        props.onSectionChange(props.index, { ...data, items: new_items });
    };

    const deleteItem = (index) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete item</h1>
                        <p>
                            You are deleting a <b>{data.items[index].type}</b> item from
                            <br />{' '}
                        </p>
                        <span>
                            <b>{data.title}</b> section
                        </span>
                        <p>Do you want to delete item?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    const new_items = data.items;
                                    new_items.splice(index, 1);

                                    props.onSectionChange(props.index, { ...data, items: new_items });
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    const switchItems = (index1, index2) => {
        const new_items = data.items;
        const item = Object.assign({}, new_items[index1]);
        new_items[index1] = Object.assign({}, new_items[index2]);
        new_items[index2] = item;

        props.onSectionChange(props.index, { ...data, items: new_items });
    };
    const getOperationTypeName = (operationId) => {
        const operation = operationId && props.child_jobs ? props.child_jobs.find((cj) => cj.id === operationId) : null;
        //console.log("operationname",operationId,operation,props.child_jobs);
        return operation?.name ? operation.name : operationId;
    };
    const getItemEditJobTypeBasedOnSectionType = () => {
        if (isRepeaterSection(data.sectionType))
            return props.child_jobs ? props.child_jobs.find((cj) => cj.id === data.jobTypeRef) : null;
        return props.job_type;
    };

    return (
        <>
            <div className="section-designer">
                <div className="title-form">
                    <label className="title">Section Title</label>
                    <input
                        autoComplete="off"
                        type="text"
                        className={'input-control'}
                        name="section_title"
                        value={data.title}
                        onChange={onSectionTitleChange}
                    />
                    <div className="section-info">
                        <label>Section type</label>
                        <p>{getSectionTypeLabel(data.sectionType)}</p>
                    </div>
                    {isRepeaterSection(data.sectionType) ? (
                        <div className="section-info">
                            <label>Repeating over</label>
                            <p>{getOperationTypeName(data.jobTypeRef)}</p>
                        </div>
                    ) : null}
                </div>
                <div className="section-items">
                    {data.items.map((item, item_index) => {
                        return (
                            <React.Fragment key={'section_item_' + item_index}>
                                <div className="section-item-add" onClick={() => openNewItemModal(item_index)}>
                                    +
                                </div>
                                <div className="section-item-content">
                                    <div
                                        className={
                                            'section-item' +
                                            (ItemsFactory.is_configurable(item.type) ? ' configurable-item' : '')
                                        }
                                        onClick={() =>
                                            ItemsFactory.is_configurable(item.type)
                                                ? openEditItemModal(item_index)
                                                : null
                                        }
                                    >
                                        {ItemsFactory.get_name(item.type)}
                                    </div>
                                    <div className="icons">
                                        {item_index > 0 ? (
                                            <span
                                                className="fa fa-arrow-up"
                                                title="Move up"
                                                onClick={() => switchItems(item_index, item_index - 1)}
                                            ></span>
                                        ) : null}
                                        {item_index < data.items.length - 1 ? (
                                            <span
                                                className="fa fa-arrow-down"
                                                title="Move down"
                                                onClick={() => switchItems(item_index, item_index + 1)}
                                            ></span>
                                        ) : null}
                                        <span
                                            className="fa fa-minus-circle"
                                            title="Delete"
                                            style={{ color: 'red' }}
                                            onClick={() => deleteItem(item_index)}
                                        ></span>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                    <div className="section-item-add" onClick={() => openNewItemModal(data.items.length)}>
                        +
                    </div>
                </div>
            </div>
            <Modal
                isOpen={is_new_item_modal_open}
                onRequestClose={closeNewItemModal}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                className={'modal_content'}
            >
                <ItemNew closeCallback={closeNewItemModal} insertCallback={addNewItem} />
            </Modal>
            <Modal
                isOpen={is_edit_item_modal_open}
                onRequestClose={closeEditItemModal}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                className={'modal_content'}
            >
                <ItemEdit
                    closeCallback={closeEditItemModal}
                    updateCallback={saveItem}
                    parentSection={data}
                    data={edit_item_index !== null ? JSON.parse(JSON.stringify(data.items[edit_item_index])) : {}}
                    datapoints={props.datapoints}
                    child_jobs={props.child_jobs}
                    job_type={getItemEditJobTypeBasedOnSectionType()}
                />
            </Modal>
        </>
    );
}
