import { create, all } from 'mathjs';
import { toNumber } from './numberFunctions';

export const defaultFormula = '{val}';

export class WidgetFormulaManager {
    // math;
    constructor() {
        this.math = create(all);
    }

    //To dashboard
    applyWidgetFormula(config) {
        const self = this;
        if (!config?.configuration || !Array.isArray(config.configuration)) return config;
        //const math = create(all);
        config.configuration.forEach((zone) => {
            try {
                zone.widgets.forEach((widget) => {
                    try {
                        return self.applyWidgetFormulaToWidget(this.math, widget);
                    } catch (err) {
                        console.log('Formula manager || Error applying formula', err);
                    }
                });
            } catch (err) {
                console.log('Formula manager || Error iterating widgets', err);
            }
        });

        return config;
    }
    //to single widget
    applyWidgetFormulaToWidget(math, widget) {
        if (!widget.configuration || !widget.configuration.formula) return widget;
        if (
            ['linechart', 'histogram', 'distribution', 'depthDistribution', 'opticontrol', 'text', 'heatmap'].includes(
                widget.type
            )
        ) {
            return widget;
        }

        const setResult = (widge, result) => {
            const dataNode = Array.isArray(widge.data) ? widge.data[0] : widge.data;
            dataNode.data.value = result;
            widge.data = dataNode;
        };
        //read variabledefinition from componentsettings

        const formulaWithVariablesReplaces = this.replaceVariableDefinitionWithValueInFormulaForWidget(widget);

        try {
            //console.log("Apply formula..",formulaWithVariablesReplaces);
            const allDataPointsAreNumbers = this.areDatapointsNumbers(widget);
            if (!allDataPointsAreNumbers) {
                setResult(widget, formulaWithVariablesReplaces);
                return;
            }

            //Going to regret not changing this... -> yes I did..
            const formulaResult = math.evaluate(formulaWithVariablesReplaces);

            const endresult = isNaN(formulaResult) || !isFinite(formulaResult) ? '--' : formulaResult;
            //For multi components, widget.data will be an array.. for single components, it will be a single value..

            // if(Array.isArray(widget.data)){

            // }
            //This is a poor solution and will probably only work for singlevalue components.
            //When using multiple components via componentSettings, we will have an array here, which will also be the case for many range components.
            setResult(widget, endresult);
        } catch (err) {
            console.log('widget', widget);
            console.log('Error evaluating formula', err);
        }

        return;
    }
    //to distribution component
    applyWidgetFormulaToDistribution(formula, data, componentSettings) {
        const self = this;
        //this.state.formulaManager.applyWidgetFormula(this.props.configuration)
        const variablesInFormula = self.getVariablesInFormula(formula);
        //console.log(componentSettings);
        if (variablesInFormula.length === 0) return data;

        function getDistributionKeys(dt) {
            try {
                return Object.keys(dt[0].data);
            } catch (err) {
                console.log('Could not determine distribution keys from data', dt);
            }
            return [];
        }
        //Distributions might typically return several distributions.. one for each topic/datavalue
        //Combine via formula.
        const distributionKeys = getDistributionKeys(data);
        const newLineDict = {};

        //Need to evaluate formula for each distributionkey (column)
        distributionKeys.forEach((key) => {
            const variableSubs = {};
            data.forEach((dataSet) => {
                //Build object with variable name and value that can be replaced in formula
                const valCs = componentSettings.find((cs) => cs.name === dataSet.name);
                if (!valCs?.varName) {
                    console.log(
                        `Could not find componsentSetting with name ${dataSet.name} or missing variable name`,
                        componentSettings,
                        dataSet
                    );
                    return;
                }
                const currentDataPoint = dataSet.data[key]; //key = g0,g1,g2 etc-

                variableSubs[valCs.varName] = currentDataPoint;
            });

            const replacedFormula = self.replaceVariableDefinitionWithValueInFormula(formula, variableSubs);
            const aggVal = self.evaluateFormula(replacedFormula);

            //console.log(`Evaluated distribution ${key} = ${aggVal}`);
            newLineDict[key] = aggVal || 0;
        });
        return newLineDict;
    }
    //to linechart component
    applyWidgetFormulaToLinechart(formula, data, componentSettings) {
        const self = this;
        //this.state.formulaManager.applyWidgetFormula(this.props.configuration)
        //let componentSettings = componentSettings;
        const variablesInFormula = self.getVariablesInFormula(formula);
        //console.log(componentSettings);
        if (variablesInFormula.length === 0) return data;

        if (variablesInFormula.length > 1) {
            //Add a new line with result..
            const newLineName = 'Agg';
            const newLineDict = {};
            //Create a dictionary where each property is a date in the x axis
            data.data.forEach((line) => {
                line.data.forEach((val) => {
                    const valCs = componentSettings.find((cs) => cs.name === val.name);
                    if (!valCs?.varName) {
                        console.log(
                            `Could not find componsentSetting with name ${val.name} or missing variable name`,
                            componentSettings
                        );
                        return;
                    }
                    const dataPoint = newLineDict[val.date] || { variables: {} };
                    dataPoint.variables[valCs.varName] = toNumber(val.value, 0);

                    newLineDict[val.date] = dataPoint;
                    //console.log(valCs,dataPoint);
                });
                return;
            });
            //Also convert back to a suitable linechart format before adding to collection...
            const newLine = {
                name: newLineName,
                data: [],
                singleValues: [],
            };
            Object.keys(newLineDict).forEach((dataPointKey) => {
                const dataPoint = newLineDict[dataPointKey];
                const replacedFormula = self.replaceVariableDefinitionWithValueInFormula(formula, dataPoint.variables);
                let aggVal = self.evaluateFormula(replacedFormula);
                aggVal = isNaN(aggVal) || !isFinite(aggVal) ? null : `${aggVal}`; //setting value to a string because fml
                //console.log(aggVal,replacedFormula,variablesInFormula,dataPoint.variables)
                dataPoint.value = aggVal;
                dataPoint.name = newLineName;
                dataPoint.date = dataPointKey;
                dataPoint.unit = '';
                newLine.data.push(dataPoint);
            });
            //Clearing all lines and pushing the agg.. consider making this a bit more dynamic..
            data.data = [];
            data.data.push(newLine);
            //console.log(newLine);
            //data.data.push(newLine);
            return data;
        }

        //console.log("apply widget formula..",data,variablesInFormula);
        //legacy functionality. -> Variables in formula = 1.. but could also end here when using a single custom variable name
        //let math = create(all);
        data.data.forEach((line) => {
            line.data.forEach((val) => {
                if (val.value) {
                    const evalled = this.math.evaluate(formula.replaceAll(variablesInFormula[0], val.value));
                    val.value = isNaN(evalled) || !isFinite(evalled) ? null : `${evalled}`; //val.value = math.evaluate(formula.replaceAll("{val}", val.value));

                    //console.log(val.value)
                }
            });
            return;
        });
        return data;
    }

    evaluateFormula(formula) {
        //Just runs evaluate.. Remember to run variable substitions first..
        try {
            return this.math.evaluate(formula);
        } catch (err) {
            // console.log("Could not evaluate: "+formula);
            return null;
        }
    }

    replaceVariableDefinitionWithValueInFormulaForWidget(widget) {
        let formula = widget.configuration.formula; //.replaceAll("{val}", widget.data.data.value);
        if (formula === '{val}') {
            //Legacy situations that only have one value anyway.
            formula = formula.replaceAll('{val}', widget.data.data.value);
            return formula;
        }

        const variableSubs = this.getVariableSubstitutionsFromWidget(widget);
        if (!variableSubs) return formula;

        for (const prop of Object.keys(variableSubs)) {
            formula = formula.replaceAll(`{${prop}}`, variableSubs[prop]);
        }
        return formula;
    }
    replaceVariableDefinitionWithValueInFormula(formula, variableSubs) {
        if (!variableSubs || !formula) return formula;

        for (const prop of Object.keys(variableSubs)) {
            formula = formula.replaceAll(`{${prop}}`, variableSubs[prop]);
        }
        return formula;
    }

    getVariableSubstitutionsFromWidget(widget) {
        if (widget.data?.data?.value && !widget.componentSettings) {
            //some sort of default/legacy behavior here.
            return { val: widget.data?.data?.value };
        }
        return this.getVariableSubstitutions(widget.componentSettings, widget.data);
    }
    getVariablesInFormula(formula) {
        const vars = [];
        if (!formula) return vars;

        const regExp = /{([^}]+)}/g; ///\{([^)]+)\}/;
        const matches = formula.match(regExp); //regExp.match(formula);
        //console.log(matches);
        return matches;
    }

    areDatapointsNumbers(widget) {
        //data.dType
        //Probably not the best way of doing things, but seeing as most values are numbers, and not all signals have a specific dtype..
        //check if dtype == str...
        const data = widget.data;
        const componentSettings = widget.componentSettings;

        if (!componentSettings) {
            //some sort of default/legacy behavior here.
            return true;
        }
        const textTypes = ['str', 'text'];
        // eslint-disable-next-line no-unused-vars
        const anyNotNumber = componentSettings.some((cs, i) => {
            //return true if not number.. ie text or array type..
            const dataNode = data?.find
                ? data?.find((dtNode) => {
                      //console.log("Matching?",cs.id,dtNode.data?.id);
                      return cs.id === dtNode.data?.id;
                  })
                : data;
            const dtype = dataNode?.data?.dType || dataNode?.dType; //<-- it is crazy how many data properties we have nested..
            if (!dtype) {
                //guessing number if dtype not defined.. but we could try to parse the value at this point..
                //todo: parse to check if really a number here..

                return false;
            }
            return textTypes.includes(dtype);
        });
        const areDatapointsNumbers = !anyNotNumber;
        return areDatapointsNumbers;
    }

    getVariableSubstitutions(componentSettings, data) {
        if (data?.data?.value && !componentSettings) {
            //some sort of default/legacy behavior here.
            return { val: data?.data?.value };
        }
        const variableSubs = {};
        if (!componentSettings) return variableSubs;

        componentSettings.forEach((cs, i) => {
            const dataNode = data?.find
                ? data?.find((dtNode) => {
                      //console.log("Matching?",cs.id,dtNode.data?.id);
                      return cs.id === dtNode.data?.id;
                  })
                : data;
            let varName = cs?.varName;
            if (!varName) {
                varName = `val${i === 0 ? '' : i + 1}`;
            }

            variableSubs[varName] = dataNode?.data?.value || 0;
        });

        return variableSubs;
    }
}
