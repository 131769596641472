import React from 'react';

import './Histogram.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import CustomDatePicker from '../../common/CustomDatePicker/CustomDatePicker';
import LeftNav from '../../../styles/icons/LeftNav';
import RightNav from '../../../styles/icons/RightNav';
import Spinner from '../../common/Spinner/Spinner';
import api from '../../../utility/api';
import { forEach, range } from 'lodash';

class Histogram extends React.Component {
    constructor(props) {
        super(props);

        let ticks = '';
        let period = '';

        switch (this.props.defaultPeriod) {
            case 'hour':
                ticks = 'minutes';
                period = 60;
                break;

            case 'week':
                ticks = 'day';
                period = 7;
                break;

            case 'month':
                ticks = 'day';
                period = 30;
                break;

            default:
                ticks = 'hour';
                period = 24;
        }

        this.state = {
            left: 'active',
            right: 'active',
            res: undefined,
            display: false,
            fetching: false,
            endDate: null,
            period: {
                period: period,
                interval: this.props.defaultPeriod || 'day',
                ticks: ticks,
            },
        };
        this.mounted = true;

        this.scrolling = this.scrolling.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.getChartInfo(false, (res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });
        }
    }

    componentDidMount() {
        if (this.props.user) {
            this.getChartInfo(false, (res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            res: null,
        });
        this.mounted = false;
    }

    getChartInfo = (reset, callback) => {
        let from = this.state.currentFrom;
        let to = this.state.currentTo;

        if (!this.state.currentTo || reset) {
            to =
                this.state.period.ticks === 'day'
                    ? moment(this.state.endDate)
                    : moment(this.state.endDate).second(0).millisecond(0);
            if (!this.state.endDate) {
                to =
                    this.state.period.ticks === 'day'
                        ? moment().minute(0).second(0).millisecond(0).utc()
                        : moment().second(0).millisecond(0).utc();
            }
        }

        if (!this.state.currentFrom || reset) {
            from =
                this.state.period.ticks === 'day'
                    ? to.clone().add(-60, this.state.period.ticks)
                    : to.clone().minute(0).second(0).millisecond(0).add(-60, this.state.period.ticks);
        }

        this.setState({
            currentTo: to,
            currentFrom: from,
        });

        const daterange =
            '&from=' +
            (this.props.job ? this.props.job.startDate.toISOString() : from.toISOString()) +
            '&to=' +
            (this.props.job ? this.props.job.endDate.toISOString() : to.toISOString());

        let apiUrl =
            process.env.REACT_APP_API +
            '/api/kpi/range?machinenumber=' +
            this.props.machineNumber +
            '&valueFormat=range' +
            daterange +
            '&rangeformat=' +
            this.state.period.ticks +
            '&dataType=' +
            this.props.dataType +
            '&dataValue=' +
            this.props.dataValue +
            '&ItemKey=' +
            this.props.itemKey;
        forEach(this.props.components, (component) => {
            apiUrl += '&componentId=' + component;
        });

        api.get(apiUrl)
            .then((res) => {
                const json = res.data;
                //FIX NULL VALUES
                let range;

                switch (this.state.period.ticks) {
                    case 'minutes':
                        range = this.enumerateMinutesBetweenDates(from, to, 1);
                        break;
                    case 'hour':
                        range = this.enumerateHoursBetweenDates(from, to, 1);
                        break;
                    case 'day':
                        range = this.enumerateBetweenDates(from, to, 1, 'days');
                        break;
                    case 'week':
                        range = this.enumerateBetweenDates(from, to, 1, 'weeks');
                        break;
                    default:
                        break;
                }

                const byDate = json.data.reduce((res, item) => {
                    res[new Date(item.date).toISOString()] = {
                        date: new Date(item.date).toISOString(),
                        value: item.value,
                    };
                    return res;
                }, {});

                // eslint-disable-next-line
                for (const r of range) {
                    if (!byDate[r]) {
                        byDate[r] = { date: r, value: null };
                    }
                }
                const data = {
                    threshold: json.threshold,
                    data: Object.values(byDate).sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date))),
                };

                return callback(data);
            })
            .catch((err) => {
                console.error(err);
                this.setState({ res: null });
            });
    };

    enumerateBetweenDates(startDate, endDate, tick, type) {
        const now = startDate.clone(),
            dates = [];

        while (now.isBefore(endDate)) {
            if (type === 'weeks') {
                dates.push(now.weekday(1).format('YYYY-MM-DDT00:00:00.000') + 'Z');
            } else if (type === 'minutes') {
                dates.push(now.seconds(0).milliseconds(0).toISOString());
            } else {
                dates.push(now.format('YYYY-MM-DDT00:00:00.000') + 'Z');
            }

            now.add(tick, type);
        }
        return dates;
    }

    enumerateHoursBetweenDates(startDate, endDate, tick) {
        const now = startDate.clone(),
            dates = [];

        while (now.isBefore(endDate)) {
            dates.push(now.minute(0).seconds(0).milliseconds(0).toISOString());
            now.add(tick, 'hours');
        }
        return dates;
    }

    enumerateMinutesBetweenDates(startDate, endDate, tick) {
        const now = startDate.clone(),
            dates = [];

        while (now.isBefore(endDate)) {
            dates.push(now.seconds(0).milliseconds(0).toISOString());
            now.add(tick, 'minutes');
        }
        return dates;
    }

    scrollChart(direction, e) {
        const _this = this;
        const chart = e.currentTarget.parentNode.parentNode;

        if (direction === 'left') {
            chart.scrollLeft = chart.scrollLeft - 50;
        } else {
            chart.scrollLeft = chart.scrollLeft + 50;
        }

        if (chart.scrollLeft === 0) {
            if (direction === 'left') {
                this.setState(
                    {
                        left: 'active',
                        right: 'active',
                        fetching: true,
                        currentFrom: this.state.currentFrom.add(-60, this.state.period.ticks),
                    },
                    function () {
                        _this.getChartInfo(false, (res) => {
                            if (this.mounted) {
                                this.setState({
                                    res: res,
                                    fetching: false,
                                });
                            }
                        });
                    }
                );
            }
        } else {
            if (chart.scrollLeft === chart.scrollWidth - chart.clientWidth) {
                if (direction === 'right') {
                    this.setState(
                        {
                            left: 'active',
                            right: 'active',
                            fetching: true,
                            currentTo: this.state.currentTo.add(60, this.state.period.ticks),
                        },
                        function () {
                            _this.getChartInfo(false, (res) => {
                                if (this.mounted) {
                                    this.setState({
                                        res: res,
                                        fetching: false,
                                    });
                                }
                            });
                        }
                    );
                }
            }
        }
    }

    scale(num, in_min, in_max, out_min, out_max) {
        return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    }

    calculateBarValue(current) {
        const minValue = this.props.configuration ? this.props.configuration.minValue : 0;
        const maxValue = this.props.configuration ? this.props.configuration.maxValue : 100;

        if (current <= minValue) {
            current = minValue;
        } else if (current >= maxValue) {
            current = maxValue;
        } else if (!current) {
            return 0;
        }

        return this.scale(current, minValue, maxValue, 0, 100);
    }

    getLabelPosition(r, max, min) {
        if (r === max) {
            return 100;
        } else if (r === min) {
            return 0;
        } else {
            return (r / max) * 100;
        }
    }

    mouseEnter = (i) => {
        this.setState({ display: i });
    };

    mouseLeave = () => {
        this.setState({ display: null });
    };

    setBarColor(value) {
        const threshold = (this.props.configuration || {}).threshold;
        if (threshold) {
            if (value > threshold.alertHigh || value < threshold.alertLow) {
                return 'linear-gradient(rgba(255,0,85,0.6), rgba(255,0,85,0))';
            } else if (value > threshold.warningHigh || value < threshold.warningLow) {
                return 'linear-gradient(rgba(201,181,78,0.6), rgba(201,181,78,0))';
            }
        }
        return 'linear-gradient(rgba(65,200,240,0.6), rgba(65,200,240,0))';
    }

    scrolling() {
        const div = this.refs.histogram;
        if (div.scrollLeft === 0) {
            this.setState(
                {
                    currentFrom: this.state.currentFrom.add(-60, this.state.period.ticks),
                    fetching: true,
                },
                function () {
                    this.getChartInfo(false, (res) => {
                        if (this.mounted) {
                            this.setState({
                                res: res,
                                fetching: false,
                            });
                        }
                    });
                }
            );
        } else if (div.scrollLeft + div.offsetWidth >= div.scrollWidth) {
            this.setState(
                {
                    currentFrom: this.state.currentFrom.add(60, this.state.period.ticks),
                    fetching: true,
                },
                function () {
                    this.getChartInfo(false, (res) => {
                        if (this.mounted) {
                            this.setState({
                                res: res,
                                fetching: false,
                            });
                        }
                    });
                }
            );
        }
    }

    openDropdown = () => {
        this.setState({ showDropdownOnParent: !this.state.showDropdownOnParent });
    };

    openPeriodDropdown = (_parent) => {
        this.setState({ showPeriodDropdownOnParent: !this.state.showPeriodDropdownOnParent });
    };

    daterange(val, period, parent, row, cancel) {
        if (!cancel) {
            if (period) {
                this.setState({ fetching: true, period: val }, () => {
                    this.getChartInfo(true, (res) => {
                        this.setState({
                            res,
                            fetching: false,
                        });
                    });
                });
            } else {
                this.setState({ fetching: true, endDate: val }, () => {
                    this.getChartInfo(true, (res) => {
                        this.setState({
                            res,
                            fetching: false,
                        });
                    });
                });
            }
        }

        this.setState({ showDropdownOnParent: false, showPeriodDropdownOnParent: false });
    }

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    render() {
        const res = this.state.res;

        if (res === undefined) {
            return <Spinner text="data" />;
        }

        const minValue = this.props.configuration ? this.props.configuration.minValue : 0;
        const maxValue = this.props.configuration ? this.props.configuration.maxValue : 100;
        const totalSpan = maxValue - minValue;
        const step = Math.ceil(totalSpan / 4);

        let reverseStepRange = range(minValue, maxValue + step, step).reverse();

        if (reverseStepRange.length < 1) {
            reverseStepRange = ['', 0];
        }

        return (
            <>
                {!this.props.expanded ? <div className="widget-title regular">{this.props.title}</div> : null}

                <div className="histogram-wrapper">
                    {!this.props.job ? (
                        <div className={'date-wrapper parent ' + (this.check() ? '' : ' mobile')}>
                            <div className="date-picker-button" onClick={() => this.openPeriodDropdown(true)}>
                                <div className="summary-value">Period:</div>
                                <div className="dates-value">{this.state.period ? this.state.period.interval : ''}</div>
                            </div>
                            {this.state.showPeriodDropdownOnParent ? (
                                <CustomDatePicker period={true} daterange={(val) => this.daterange(val, true, true)} />
                            ) : (
                                <div></div>
                            )}
                            <div className="date-picker-button" onClick={() => this.openDropdown(true)}>
                                <div className="summary-value">End time:</div>
                                <div className="dates-value">
                                    {this.state.endDate ? moment(this.state.endDate).format('DD.MM.YYYY HH:mm') : 'NOW'}
                                </div>
                            </div>

                            {this.state.showDropdownOnParent ? (
                                <CustomDatePicker
                                    mobile={!this.check()}
                                    selDate={this.state.endDate}
                                    daterange={(val, a, b, c, cancel) => this.daterange(val, false, true, null, cancel)}
                                />
                            ) : (
                                <div></div>
                            )}
                        </div>
                    ) : null}

                    {this.state.fetching ? <div className="loading-data">Fetching new data..</div> : null}

                    <div
                        ref="histogram"
                        onScroll={this.scrolling}
                        className={'bar-gauge-histogram' + (this.props.expanded ? ' expanded' : ' regular')}
                    >
                        <div className="line-nav">
                            <div
                                className={'left-nav ' + (this.state.left === 'active' ? 'active' : '')}
                                onClick={(e) => this.scrollChart('left', e)}
                            >
                                <LeftNav />
                            </div>
                            <div
                                className={'right-nav ' + (this.state.right === 'active' ? 'active' : '')}
                                onClick={(e) => this.scrollChart('right', e)}
                            >
                                <RightNav />
                            </div>
                        </div>
                        <div className="bar-gauge-histogram-values">
                            {reverseStepRange.map((r, i) => {
                                return <span key={'n' + i}>{r}</span>;
                                // style={{position:"absolute", bottom: this.getLabelPosition(r, maxValue, minValue) + '%'}}
                            })}
                        </div>
                        <div className="bar-gauge-historgram-bars">
                            {this.state.res
                                ? this.state.res.data.map((d, i) => {
                                      return (
                                          <div
                                              className="bar-gauge-historgram-bar"
                                              key={'hist' + i}
                                              onMouseOver={() => this.mouseEnter(i)}
                                              onMouseLeave={() => this.mouseLeave()}
                                          >
                                              <div className="x-label">
                                                  <span>
                                                      {i % 2 !== 0
                                                          ? this.state.period.ticks === 'hour'
                                                              ? this.state.res.data.length > 24
                                                                  ? moment(d.date).format('DD.MM HH:mm')
                                                                  : moment(d.date).format('HH:mm')
                                                              : this.state.period.ticks === 'minutes'
                                                              ? moment(d.date).format('DD.MM HH:mm')
                                                              : moment(d.date).format('DD:MMM')
                                                          : null}
                                                  </span>
                                              </div>
                                              <div
                                                  className="bar-gauge-historgram-progress"
                                                  style={{
                                                      height: this.calculateBarValue(d.value) + '%',
                                                      backgroundImage: this.setBarColor(d.value),
                                                  }}
                                              ></div>
                                              {/* <div className="bar-gauge-historgram-marker" style={{ bottom: '28%', backgroundColor: '#C9B54E' }}></div>
                                <div className="bar-gauge-historgram-marker" style={{ bottom: '57%', backgroundColor: '#DA3A3A' }}></div> */}
                                              <div
                                                  className={
                                                      this.state.display === i
                                                          ? 'histogram-hover'
                                                          : 'histogram-hover-hidden'
                                                  }
                                              >
                                                  <div>
                                                      {this.state.period.ticks === 'hour'
                                                          ? this.state.res.data.length > 24
                                                              ? moment(d.date).format('DD.MM HH:mm')
                                                              : moment(d.date).format('HH:mm')
                                                          : this.state.period.ticks === 'minutes'
                                                          ? moment(d.date).format('DD.MM HH:mm')
                                                          : moment(d.date).format('DD:MMM')}
                                                  </div>
                                                  <div>{d.value ? d.value.toFixed(2) : 'N/A'}</div>
                                              </div>
                                          </div>
                                      );
                                  })
                                : range(0, 24, 1).map((r, i) => {
                                      return (
                                          <div className="bar-gauge-historgram-bar" key={'hist' + i}>
                                              <div className="x-label">
                                                  <span></span>
                                              </div>
                                              <div
                                                  className="bar-gauge-historgram-progress"
                                                  style={{ height: '0%', backgroundImage: this.setBarColor() }}
                                              ></div>
                                          </div>
                                      );
                                  })}
                        </div>
                    </div>
                    {this.state.fetching && this.props.isMobile ? (
                        <Spinner className="histogram-more-data" text="more data" />
                    ) : null}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    endDate: state.itemrow.endDate,
    endDateParent: state.itemrow.endDateParent,
    period: state.itemrow.period || { period: 12, interval: 'day', ticks: 'hour' },
    periodParent: state.itemrow.periodParent || { period: 12, interval: 'day', ticks: 'hour' },
    isMobile: state.utility.isMobile,
});

export default connect(mapStateToProps)(Histogram);
