import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { noop } from 'lodash';
import propTypes from 'prop-types';
import { Component } from 'react';
import { getUserManager } from '../../utility/oidchelper';
import { ai } from '../../utility/telemetry/TelemetryService';

/** 
 * @render react
 * @name Authenticate
 * @description OpenId Connect based Authentication Component
 * @example  
 * <Authenticate OidcSettings={this.OidcSettings} userLoaded={this.userLoaded} userunLoaded={this.userUnLoaded} renderNotAuthenticated={this.NotAuthenticated}>
      <div>If you see this you are authenticated.</div>
   </Authenticate>
 */
class Authenticate extends Component {
    constructor(props) {
        super(props);
        this.signin = this.signin.bind(this);
        this.onUserLoaded = this.onUserLoaded.bind(this);
        this.signOut = this.signOut.bind(this);
        this.onUserUnloaded = this.onUserUnloaded.bind(this);

        this.state = { isAuthenticated: false };

        this.userManager = getUserManager(); // new UserManager(this.props.OidcSettings);
        this.userManager.events.addUserLoaded(this.onUserLoaded);
        this.userManager.events.addUserUnloaded(this.onUserUnloaded);
        this.userManager.events.addAccessTokenExpired((exp) => {
            console.log('Access token expired', exp);
        });
        this.userManager.events.addAccessTokenExpiring((exp) => {
            console.log('Access token expiring', exp);
        });
        this.userManager.events.addSilentRenewError((err) => {
            console.error('Silent renew error', err);
        });
        this.userManager.events.addUserSessionChanged((ch) => {
            console.log('User session changed', ch);
        });
    }

    componentDidMount() {
        this.handleGetUserAsync();
    }
    handleGetUserAsync = async () => {
        try {
            const user = await this.userManager.getUser();
            if (user != null) {
                this.onUserLoaded(user);
            } else if (window.location.href.includes('#id_token')) {
                //window.location.hash = decodeURIComponent(window.location.hash);
                const result = await this.userManager.signinRedirectCallback();
                console.log('signinredirectresult', result);
            } else {
                const signingResult = await this.signin();
                console.log('signinresult', signingResult);
            }
        } catch (err) {
            console.log('Error getting user', err);

            if (window.location.href.includes('#id_token')) {
                console.log('redirecting to root without id token');
                window.location.href = '/';
            }
        }
    };

    signOut() {
        this.userManager
            .signoutRedirect()
            .then(() => {
                this.userManager.clearStaleState();
            })
            .catch(noop);
    }

    onUserLoaded(user) {
        this.setState({ isAuthenticated: true });
        this.props.userLoaded(user);
    }

    onUserUnloaded() {
        this.setState({ isAuthenticated: false });

        this.props.userUnloaded();
    }

    componentDidCatch(error, _errorInfo) {
        console.error(error);
        ai.getAppInsights().trackException({ exception: error, severityLevel: SeverityLevel.Error });
    }

    async signin() {
        try {
            await this.userManager.signinRedirect();
        } catch (err) {
            console.log('Error signin', err.name, err);
            // if(isQuotaExceededError(err))
            //     clearLocalStorage();
            // else{console.log("Error unhandled.")}
            //if(err.m)//DOMException: Failed to execute 'setItem' on 'Storage
        }
    }
    async clearStaleState() {
        try {
            await this.userManager.clearStaleState();
        } catch (err) {
            console.log('Error clearStaleState', err.name, err);
        }
    }

    render() {
        //window.location.hash = decodeURIComponent(window.location.hash);

        if (window.location.pathname === '/silent-renew') {
            this.userManager.signinSilentCallback();
        }

        if (this.state.isAuthenticated) {
            return this.props.children;
        }
        return this.props.renderNotAuthenticated();
    }
}

Authenticate.defaultProps = {
    OidcSettings: {},
    userUnloaded: null,
    userLoaded: null,
    renderNotAuthenticated: null,
};

Authenticate.propTypes = {
    OidcSettings: propTypes.shape({
        /**
         * @property {string} OidcSettings.authority The URL of the OIDC/OAuth2 provider.
         */
        authority: propTypes.string.isRequired,
        /**
         * @property {string} OidcSettings.client_id  Your client application's identifier as registered with the OIDC/OAuth2 provider.
         */
        client_id: propTypes.string.isRequired,
        /**
         * @property {string} OidcSettings.redirect_uri The redirect URI of your client application to receive a response from the OIDC/OAuth2 provider.
         */
        redirect_uri: propTypes.string.isRequired,
        /**
         * @property {string} OidcSettings.post_logout_redirect_uri The URL of the OIDC/OAuth2 provider.
         */
        post_logout_redirect_uri: propTypes.string.isRequired,
        /**
         * @property {string} OidcSettings.response_type The type of response desired from the OIDC/OAuth2 provider ( default: 'id_token').
         */
        response_type: propTypes.string.isRequired,
        /**
         * @property {string} OidcSettings.scope The scope being requested from the OIDC/OAuth2 provider (default: 'openid').
         */
        scope: propTypes.string.isRequired,
    }).isRequired,
    /**
     * @property {func} userLoaded Raised when a user session has been established (or re-established), accepts one parameter 'user'.
     */
    userLoaded: propTypes.func,
    /**
     * @property {func} userUnLoaded Raised when a user session has been terminated.
     */
    userUnLoaded: propTypes.func,
    /**
     * @property {func} renderNotAuthenticated Renderprop used to render output when user is not authenticated
     */
    renderNotAuthenticated: propTypes.func.isRequired,
};

export default Authenticate;
