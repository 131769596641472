import React from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import api from '../../utility/api';
import Spinner from '../../components/common/Spinner/Spinner';
import Modal from 'react-modal';
import AdminItemTypeAddEdit from '../../components/common/AdminItemTypeAddEdit/AdminItemTypeAddEdit';
import { noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminItemTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.fetching = false;
        this.showSpinner = true;
        const rowContext = [
            {
                content: <span>Edit itemtype</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete itemtype</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];
        this.visibleColumns = [
            { Header: 'Name', accessor: 'name', filterable: true },
            {
                Header: () => null,
                id: 'rowcontext',
                Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
            },
        ];

        this.fetchItemTypes = this.fetchItemTypes.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.deleteItemType = this.deleteItemType.bind(this);
    }

    componentDidMount() {
        this.fetchItemTypes();
    }

    fetchItemTypes() {
        if (this.props.user != null) {
            if (!this.fetching) {
                api.get(process.env.REACT_APP_API + '/api/itemTypes')
                    .then((res) => {
                        this.setState({
                            itemTypes: res.data,
                        });
                        this.fetching = false;
                        this.showSpinner = false;
                        this.forceUpdate();
                    })
                    .catch(() => {
                        this.fetching = false;
                        this.showSpinner = false;
                        NotificationManager.error('Failed to fetch', 'Could not fetch item types');
                    });
            }
        }
    }

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItemType: item,
                editType: mode,
            },
            function () {
                this.fetchItemTypes();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchItemTypes();
            }
        );
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete item type</h1>
                        <p>
                            You are about to delete the item type
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteItemType(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteItemType = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/itemtypes/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Item type deleted', 'Item type successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete item type', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchItemTypes();
                })
                .catch(noop);
        }
    };

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase()) : false;
    }

    render() {
        const itemTypes = this.state.itemTypes;

        if (this.showSpinner) return <Spinner text="item types" />;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{itemTypes ? itemTypes.length : 0} Item types</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New item type</span>{' '}
                        </span>
                    </span>
                </div>
                <GenericTable
                    data={itemTypes}
                    columns={this.visibleColumns}
                    localPaging={true}
                    enableGlobalFilter={true}
                />

                {
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <AdminItemTypeAddEdit
                            editItemType={this.state.editItemType}
                            editedCallback={this.closeModal}
                            cancelCallback={this.closeModal}
                        ></AdminItemTypeAddEdit>
                    </Modal>
                }
            </>
        );
    }
}

AdminItemTypes.propTypes = {
    user: PropTypes.object,
};

export default AdminItemTypes;
