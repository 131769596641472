import React from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import 'react-table-6/react-table.css';
import GenericTable from '../../components/common/GenericTable';
import PeriodPicker from '../../components/common/PeriodPicker/PeriodPicker';
import Spinner from '../../components/common/Spinner/Spinner';
import {
    getDefaultTicksAndPeriod,
    getFromTimeBasedOnPeriodAndToTime,
    getToTimeFromEndTimeOrPeriod,
} from '../../components/Widgets/widgetHelper';
import { withRouteMatch } from '../../HOC';
import api from '../../utility/api';

class EventList extends React.Component {
    constructor(props) {
        super(props);

        let { ticks, period, interval } = getDefaultTicksAndPeriod(props.defaultPeriod);

        this.state = {
            showSub: true,
            pages: 0,
            count: 0,
            period: {
                period: period, //24
                interval: interval, //day
                ticks: ticks,
            },
            fetchingUrl: '',
        };

        this.visibleColumns = [
            { Header: 'Timestamp', accessor: 'timestamp', type: 'datetime', width: 170 },
            {
                Header: 'Component',
                accessor: 'topic',
                filterable: true,
                Cell: (row) => <div>{row.value.split('/').slice(5).join('/')}</div>,
            },
            { Header: 'Name', accessor: 'name', filterable: true, sortable: false, width: 200 },
            { Header: 'Value', accessor: 'value', filterable: true, sortable: false },
        ];

        this.fetchEvents = this.fetchEvents.bind(this);
        this.showSubComponentEvents = this.showSubComponentEvents.bind(this);
        this.onDateRangeChanged = this.onDateRangeChanged.bind(this);
    }

    componentDidMount() {
        this.setState({ fetchData: Math.random() });
        //this.fetchEvents();

        this.interval = setInterval(() => {
            this.setState({ fetchData: Math.random() });
        }, 60000);
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setState({ fetchData: Math.random() });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    fetchEvents = async ({ pageIndex, pageSize, sorted, filtered, globalFilter, callback }) => {
        const to = getToTimeFromEndTimeOrPeriod(this.state.endDate, this.state.period);
        const from = getFromTimeBasedOnPeriodAndToTime(to, this.state.period);
        console.log('Fetching events from/to', this.state.period, from.toISOString(), to.toISOString());

        const daterange = '&from=' + from.toISOString() + '&to=' + to.toISOString();

        const showSub = this.state.showSub;

        let url = this.props.match.params.itemId
            ? `${process.env.REACT_APP_API}/api/events/${this.props.plant.topicKey}/${this.props.match.params.itemId}/page/item`
            : `${process.env.REACT_APP_API}/api/events/${this.props.plant.topicKey}/page/plant`;
        url += `?pageSize=${pageSize || 20}&page=${pageIndex || 0}&showSub=${showSub}${daterange}`;
        if (sorted && sorted.length > 0) {
            sorted.forEach((sort) => {
                url += `&sortColumn=${sort.id}&desc=${sort.desc}`;
            });
        }
        if (filtered && filtered.length > 0) {
            filtered.forEach((filter) => {
                url += `&filterColumn=${filter.id}&filterValue=${filter.value}`;
            });
        }
        if (globalFilter && globalFilter.length > 0) {
            url += `&filterColumn=GLOBAL&filterValue=${globalFilter}`;
        }
        // if(this.state.fetchingUrl === url){
        //     console.log("Already fetching this information",url);
        //     return;
        // }
        this.setState({ loading: true, fetchingUrl: url });

        try {
            const res = await api.get(url);

            if (callback) {
                callback(res);
            } else {
                this.setState({
                    data: res.data.rows,
                    pages: res.data.pages,
                    count: res.data.count,
                    loading: false,
                });
            }
        } catch (err) {
            NotificationManager.error('Failed to fetch', 'Could not fetch data');
            console.log('Fetching events error', err);
            this.setState({ data: [], loading: false });
        }
    };

    // filterCaseInsensitive(filter, row) {
    //     const id = filter.pivotId || filter.id;
    //     return (
    //         row[id] !== undefined && row[id] !== null ?
    //             String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
    //             :
    //             false
    //     );
    // }

    showSubComponentEvents() {
        this.setState({
            showSub: !this.state.showSub,
            fetchData: Math.random(),
        });
    }

    // daterange(val, period, parent, row, cancel) {
    //     if (!cancel) {
    //         if (period) {
    //             this.setState({ period: val });
    //         } else {
    //             this.setState({ endDate: val });
    //         }
    //     }

    //     this.setState({ loadingData: true, showDropdownOnParent: false, showPeriodDropdownOnParent: false, fetchData: Math.random() });
    // }

    // openDropdown = () => {
    //     this.setState({ showDropdownOnParent: !this.state.showDropdownOnParent });
    // }

    // openPeriodDropdown = (parent) => {
    //     this.setState({ showPeriodDropdownOnParent: !this.state.showPeriodDropdownOnParent });
    // }
    onDateRangeChanged = (period, endDate) => {
        this.setState({ loadingData: true, period: period, endDate: endDate, fetchData: Math.random() });
        //  , function () {
        //     this.fetchEvents();
        //  });
    };

    render() {
        const events = this.state.data || [];
        if (!events) {
            return <Spinner text={this.props.settings ? 'settings' : 'events'} />;
        }

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>
                            {this.state.count <= 10 ? events.length : this.state.count}{' '}
                            {this.props.settings ? 'Settings' : 'Events'}
                        </h2>
                    </span>
                    <PeriodPicker
                        onDateRangeChanged={this.onDateRangeChanged}
                        period={this.state.period}
                        endDate={this.state.endDate}
                    ></PeriodPicker>
                    {/* <div ref={(element => this.datePicker = element)} className={"date-wrapper parent " + (!this.props.isMobile ? '' : ' mobile')}>
                        <div className="date-picker-button" onClick={() => this.openPeriodDropdown(true)}>
                            <div className="summary-value">Period:</div>
                            <div className="dates-value">{this.state.period ? this.state.period.interval : ""}</div>
                        </div>
                        {
                            this.state.showPeriodDropdownOnParent ? <CustomDatePicker period={true} daterange={(val) => this.daterange(val, true, true)} /> : <div></div>
                        }
                        <div className="date-picker-button" onClick={() => this.openDropdown(true)}>
                            <div className="summary-value">End time:</div>
                            <div className="dates-value">{this.state.endDate ? moment(this.state.endDate).format("DD.MM.YYYY HH:mm") : "NOW"}</div>
                        </div>

                        {
                            this.state.showDropdownOnParent ? <CustomDatePicker mobile={this.props.isMobile} selDate={this.state.endDate} daterange={(val, a, b, c, cancel) => this.daterange(val, false, true, null, cancel)} /> : <div></div>
                        }
                    </div> */}
                </div>
                {this.props.currentItem ? (
                    <div onClick={this.showSubComponentEvents} className="faux-checkbox">
                        <div className={this.state.showSub ? 'checkbox checked' : 'checkbox'}></div>
                        <span>Show subcomponent events</span>
                    </div>
                ) : null}
                <GenericTable
                    loading={this.state.loading}
                    triggerFetch={this.state.fetchData}
                    columns={this.visibleColumns}
                    data={events}
                    pageCount={this.state.pages}
                    enableGlobalFilter={true}
                    fetchData={this.fetchEvents}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentPlant,
});

export default withRouteMatch(connect(mapStateToProps)(EventList));
