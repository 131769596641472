import React from 'react';
import { Dock } from 'react-dock';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import api from '../../utility/api';
import UploadUploading from '../../images/list/Upload_Connected-Uploading.svg';
import UploadIdle from '../../images/list/Upload_Idle.svg';
import UploadNA from '../../images/list/Upload_Not-Available.svg';
import MarineLogo from '../../images/list/MarineTraffic_logo.svg';
import PLANT_TYPE from '../../constants';
import MapsLogo from '../../images/list/GoogleMaps_logo.svg';
import CommanderLogo from '../../images/Optimar_Commander_Negativ.svg';

import PositionUploading from '../../images/list/Location_Connected-Uploading.svg';
import PositionError from '../../images/list/Location_Error.svg';
import PositionIdle from '../../images/list/Location_Idle.svg';
import PositionNA from '../../images/list/Location_Not-Available.svg';
import Position from '../../images/list/Location.svg';

import Modal from 'react-modal';
import NoteAddEdit from '../../components/common/NoteAddEdit/index.js';

import './Sidepanel.css';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
    },
};

class Sidepanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            positionIdx: 0,
            dimModeIdx: 0,
            isVisible: false,
            fluid: true,
            customAnimation: false,
            slow: false,
            size: 0.25,
            selectedPlant: { position: {} },
            modalIsOpen: false,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.isVisible !== prevProps.isVisible) {
            this.setState({
                isVisible: this.props.isVisible,
            });
        }

        if (this.props.user && this.props.selectedPlantId) this.fetchPlantSidepanelData(this.props.selectedPlantId);
    }

    fetchPlantSidepanelData(id) {
        api.get(`/api/plants/${id}/sidepanel`)
            .then((res) => {
                const localTime = moment.utc(res.data.lastSampleDate).local().format('DD.MM.YYYY HH:mm');
                const lastConnection = res.data.lastSampleDate ? localTime : '';

                this.setState({
                    selectedPlant: res.data,
                    lastConnection: lastConnection,
                    isVisible: true,
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    setUploadImage(plant) {
        const timeDiff = moment().valueOf() - moment.utc(plant.lastSampleDate).local().valueOf();
        let image = UploadNA;
        let text = 'Not Available';

        if (timeDiff <= 60 * 60 * 1000) {
            // 1 hour
            image = UploadUploading;
            text = 'Uploading';
        } else if (timeDiff >= 12 * 60 * 60 * 1000) {
            image = UploadIdle;
            text = 'Idle / Standby';
        } else if (!plant.lastSampleDate) {
            image = UploadNA;
            text = 'Not Available';
        }

        return (
            <span className="cell-image-text">
                <img alt="Uploading" style={{ marginRight: '0.5em' }} src={image} />
                <span className={plant.lastSampleDate ? '' : 'cell-text-grey'}>
                    {text} <br />
                    {plant.lastSampleDate ? (
                        <span className="cell-metadata">{moment.utc(plant.lastSampleDate).local().fromNow()}</span>
                    ) : (
                        ''
                    )}
                </span>
            </span>
        );
    }

    renderFishFarmDetails() {
        return (
            <div className="sidepanel-connection">
                <table className="fish-farm-sidePanel-table">
                    <tbody>
                        <tr>
                            <td className="side-header-cell">Has reported lice:</td>
                            <td>{this.state.selectedPlant.bwData.hasReportedLice ? 'Yes' : 'No'}</td>
                        </tr>
                        <tr>
                            <td className="side-header-cell">Average adult female lice per fish:</td>
                            <td>
                                {this.state.selectedPlant.bwData.avgAdultFemaleLice
                                    ? this.state.selectedPlant.bwData.avgAdultFemaleLice
                                    : 0}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.isVisible !== nextProps.isVisible ||
            this.props.selectedPlantId !== nextProps.selectedPlantId ||
            this.state.selectedPlant.id !== nextState.selectedPlant.id ||
            this.state.modalIsOpen !== nextState.modalIsOpen
        ) {
            return true;
        }
        return false;
    }

    setPositionImage(plant) {
        const timeDiff = moment().valueOf() - moment.utc(plant.position.loggedDate).local().valueOf();
        let image = PositionNA;
        let text = '';

        if (plant.address) {
            text = plant.address;

            return (
                <span className="cell-image-text" style={{ alignItems: 'center' }}>
                    <img style={{ marginRight: '0.5em' }} alt="Position Icon" src={Position} />
                    <span className={plant.position.latitude ? 'cell-text' : 'cell-text-grey'}> {text}</span>
                </span>
            );
        } else if (plant.position.latitude) {
            text = plant.position.latitude.toFixed(2) + '°N ' + plant.position.longitude.toFixed(2) + '°W';
        } else {
            text = 'NOT AVAILABLE';
        }

        if (plant.plantTypeName !== 'Factory') {
            if (timeDiff <= 60 * 60 * 1000) {
                // 1 hour
                image = PositionUploading;
            } else if (plant.position.loggedDate) {
                image = PositionIdle;
            } else {
                image = PositionError;
            }
        } else {
            image = Position;
        }

        return (
            <span className="cell-image-text">
                <img style={{ marginRight: '0.5em' }} alt="Position Icon" src={image} />
                <span className={plant.position.latitude ? 'cell-text' : 'cell-text-grey'}>
                    {' '}
                    {text}
                    <br />
                    {plant.plantTypeName !== 'Factory' ? (
                        <span className="cell-metadata">{moment.utc(plant.position.loggedDate).local().fromNow()}</span>
                    ) : null}
                </span>
            </span>
        );
    }

    renderStatusText(isConnected) {
        return isConnected ? <span className="online">online</span> : <span className="offline">offline</span>;
    }
    renderLinkTable() {
        if (this.state.selectedPlant && this.state.selectedPlant.links && this.state.selectedPlant.links.length > 0) {
            const data = this.state.selectedPlant.links.map((l) => (
                <li key={l.type}>
                    <a href={l.address} target="_new" className="goto-btn">
                        <span className={'fa fa-' + l.icon}></span>&nbsp; {l.type}
                    </a>
                </li>
            ));

            return (
                <div className="status">
                    <center>
                        <h3>External Links</h3>
                        <ul className="goto-list">{data}</ul>
                    </center>
                </div>
            );
        }
    }

    openModal() {
        console.log('jbdsa');
        this.setState({
            modalIsOpen: true,
            editItem: null,
            editType: 'new',
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            editItem: null,
            editType: null,
        });
    }

    render() {
        if (!this.props.isVisible) return null;
        const duration = this.state.slow ? 2000 : 200;
        const dur = duration / 1000;
        const transitions = ['left', 'top', 'width', 'height']
            .map((p) => `${p} ${dur}s cubic-bezier(0, 1.5, 0.5, 1)`)
            .join(',');

        const styles = {
            root: {
                fontSize: '16px',
                color: '#999',
                height: '100vh',
                paddingTop: '3em',
                background: '#152A30',
            },
            main: {
                width: '100%',
                height: '150%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
                background: '#0d171e',
            },
            dockContent: {
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'top',
                float: 'right',
                flexDirection: 'column',
                wordWrap: 'normal',
                whiteSpace: 'normal',
                background: '#0d171e',
                color: '#919191',
            },
            remove: {
                position: 'absolute',
                zIndex: 1,
                left: '-0.8em',
                top: '50%',
                cursor: 'pointer',
                background: '#143744',
                paddingLeft: '0.4em',
                paddingRight: '0.1em',
                paddingTop: '1em',
                paddingBottom: '1em',
            },
        };

        const dockStyle = {
            top: '70px',
        };

        return (
            <>
                <Dock
                    position="right"
                    size={this.state.size}
                    dimMode="none"
                    zIndex={3}
                    isVisible={this.props.isVisible}
                    onVisibleChange={this.handleVisibleChange}
                    onSizeChange={this.handleSizeChange}
                    fluid={this.state.fluid}
                    dimStyle={{ background: 'rgba(0, 0, 100, 0.2)' }}
                    dockStyle={dockStyle}
                    dockHiddenStyle={
                        this.state.customAnimation
                            ? {
                                  transition: [transitions, `opacity 0.01s linear ${dur}s`].join(','),
                              }
                            : null
                    }
                    duration={duration}
                >
                    {
                        // eslint-disable-next-line no-unused-vars
                        ({ position, isResizing }) => (
                            <div style={styles.dockContent}>
                                <h1 className="sidebar-title">
                                    {this.state.selectedPlant.name
                                        ? this.state.selectedPlant.name.toUpperCase()
                                        : 'Loading...'}
                                </h1>

                                <div className="status">
                                    <img
                                        src={
                                            this.state.selectedPlant.image &&
                                            this.state.selectedPlant.image.includes('missing')
                                                ? CommanderLogo
                                                : this.state.selectedPlant.image
                                        }
                                        width="100%"
                                        alt={'Main image of ' + this.state.selectedPlant.name}
                                    />

                                    {this.state.selectedPlant.id ? (
                                        <>
                                            <Link to={'/plant/' + this.state.selectedPlant.id + '/overview'}>
                                                <button className="goto-btn">Open...</button>
                                            </Link>
                                            <button className="goto-btn" onClick={() => this.openModal()}>
                                                + Add note
                                            </button>
                                        </>
                                    ) : null}
                                </div>

                                <div className="sidepanel-data">
                                    <div className="sidepanel-connection">
                                        {this.setUploadImage(this.state.selectedPlant)}
                                    </div>
                                    <div className="sidepanel-connection">
                                        {this.setPositionImage(this.state.selectedPlant)}
                                        {this.state.selectedPlant.plantTypeName === PLANT_TYPE.sea ? (
                                            <a
                                                onClick={this.stopPropagation}
                                                href={
                                                    'https://www.marinetraffic.com/en/ais/details/ships/imo:' +
                                                    this.state.selectedPlant.imo
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="goto-plant-location"
                                            >
                                                <img alt="Marine Traffic logo" src={MarineLogo} />
                                            </a>
                                        ) : (
                                            <a
                                                onClick={this.stopPropagation}
                                                href={
                                                    'http://www.google.com/maps/place/' +
                                                    this.state.selectedPlant.position.latitude +
                                                    ',' +
                                                    this.state.selectedPlant.position.longitude
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="goto-plant-location"
                                            >
                                                <img alt="Google Maps logo" src={MapsLogo} />
                                            </a>
                                        )}
                                    </div>

                                    {this.state.selectedPlant && this.state.selectedPlant.plantTypeName
                                        ? this.state.selectedPlant.plantTypeName.toLowerCase() === 'fishfarm'
                                            ? this.renderFishFarmDetails()
                                            : ''
                                        : ''}

                                    {this.state.selectedPlant.accountManager ? (
                                        <>
                                            <span className="sidepanel-label">Customer Support manager</span>
                                            <span>{this.state.selectedPlant.accountManager}</span>
                                        </>
                                    ) : null}
                                </div>

                                <div></div>

                                {this.renderLinkTable()}

                                <FontAwesome
                                    name="angle-right"
                                    style={styles.remove}
                                    size="2x"
                                    onClick={() => this.props.close()}
                                />
                            </div>
                        )
                    }
                </Dock>
                {
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <NoteAddEdit
                            match={{ params: { plantId: this.state.selectedPlant.id } }}
                            editType={this.state.editType}
                            editItem={this.state.editItem}
                            closeModal={this.closeModal}
                        />
                    </Modal>
                }
            </>
        );
    }

    handleVisibleChange = (isVisible) => {
        this.setState({ isVisible });
    };

    handleSizeChange = (size) => {
        this.setState({ size });
    };

    handlePositionClick = () => {
        this.setState({ positionIdx: (this.state.positionIdx + 1) % 4 });
    };

    handleDimModeClick = () => {
        this.setState({ dimModeIdx: (this.state.dimModeIdx + 1) % 3 });
    };
}

export default Sidepanel;
