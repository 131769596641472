import React from 'react';
import './TransferOperationError.scss';
import TreeOperation from './TreeView/TreeOperation';
import EditOperation from './EditOperation/EditOperation';
import api from '../../utility/api';
import FontAwesome from 'react-fontawesome';
import { NotificationManager } from 'react-notifications';

export default function TransferOperationError({ info, closeTransferOperations }) {
    const rootJob = info.transferOperations.find(o => o.id === info.rootJobId);

    const [transferOperations, setTransferOperations] = React.useState(info.transferOperations);
    const [jobToEdit, setJobToEdit] = React.useState(info.transferOperations.find(o => o.isValid === false));
    const [isFetching, setIsFetching] = React.useState(false);

    const transferOperation = () => {
        const url = `${process.env.REACT_APP_API}/api/jobs/transferOperation/${info.rootJobId}/${info.plantToTransfer.value}`;

        api.post(url)
            .then((res) => {
                NotificationManager.success('Operation(s) transferred', `Operation(s) transferred from '${info.currentPlant.label}' to '${info.plantToTransfer.label}'`, 5000);

                closeTransferOperations();
            })
            .catch((err) => {
                NotificationManager.error('Error transferring operation(s)', 'Failed to transfer operation(s)', 5000);

                if (err?.response?.data?.transferOperations) {
                    setTransferOperations(err.response.data.transferOperations);

                    const jobToEditFromRequest = err.response.data.transferOperations.find(o => o.id === jobToEdit.id);

                    setJobToEdit(jobToEditFromRequest);
                }        

                console.error(err);
            });
    };

    const validateTransferOperation = () => {
        setIsFetching(true);
        const url = `${process.env.REACT_APP_API}/api/jobs/validateTransferOperation/${info.rootJobId}/${info.plantToTransfer.value}`;

        api.post(url)
            .then((res) => {
                const jobToEditFromRequest = res.data.transferOperations.find(o => o.id === jobToEdit.id);

                setTransferOperations(res.data.transferOperations || []);
                setJobToEdit(jobToEditFromRequest);
            })
            .catch((err) => {
                console.log(err);                
            })
            .finally(() => {
                setIsFetching(false);
            });
    };

    const handleJobChanges = (jobid, field, type) => {
        const operationToUpdateIndex = transferOperations.findIndex(o => o.id === jobid);

        if (type === 'create-operation-type' && field.type === transferOperations[operationToUpdateIndex].typeName) {
            validateTransferOperation();
        }
    };

    const handleJobSelect = (job)  => {
        setJobToEdit(job);
    };

    const renderButtons = () => {
        let isValidForm = transferOperations.every(o => o.isValid === true);
        
        const button = [
            <button
                key="btnCancelJobAddEdit"
                className="btn btn-yellow btn-grow"
                onClick={() => {
                    closeTransferOperations();
                }}
            >
                Cancel
            </button>,
            <button
                key="btnCreateJobAddEdit"
                disabled={!isValidForm}
                className="btn btn-blue btn-grow"                
                style={{ marginLeft : '30px' }}
                value="Submit"
                onClick={() => {
                    transferOperation();
                }}
            >
                {transferOperations.length === 1 ? 'Transfer operation' : 'Transfer operations'}
            </button>
        ];

        return button;
    };

    return (
        <div className="transfer-operation-container"> 
            <div className="transfer-operation-header"> 
                <div>
                    <h2>
                        Transfer operation:&nbsp;{rootJob.typeName}&nbsp;
                        <span>
                            {
                                isFetching  
                                ? <FontAwesome 
                                    className="fa fa-rotate-right fa-spin" 
                                    name="rotate-right" 
                                    style={{ 
                                        cursor : 'default', 
                                        color : '#76d4ef'
                                    }}
                                />
                                : <FontAwesome 
                                    className="fa fa-rotate-right" 
                                    name="rotate-right" 
                                    onClick={validateTransferOperation}
                                    style={{ 
                                        cursor : 'pointer', 
                                        color : '#41c9f0'
                                    }}
                                />                          
                            }                            
                        </span>
                    </h2>
                </div>
                <div>{renderButtons()}</div>
            </div>
            <div className="transfer-operation-content">
                <TreeOperation 
                    plant={info.currentPlant} 
                    jobs={transferOperations} 
                    rootJobId={rootJob.id} 
                    onJobSelect={handleJobSelect}
                >
                </TreeOperation>
                <EditOperation 
                    plant={info.plantToTransfer} 
                    job={jobToEdit}
                    onJobChanges={handleJobChanges}
                >
                </EditOperation>
            </div>            
        </div>
    );
}

