import React from 'react';
import FontAwesome from 'react-fontawesome';
import './Spinner.scss';

const GeneralSpinner = ({ text, size }) => {
    if (!size) {
        size = '2x';
    }
    return (
        <div className="loading-area">
            <FontAwesome name="spinner" size={size} spin />
            {text ? <span className="loading-text">{`${text}...`}</span> : null}
        </div>
    );
};
export default GeneralSpinner;
