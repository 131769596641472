import React from 'react';
import './BarGauge.scss';
import api from '../../../utility/api';
import { getTimeSince } from '../../../utility/timesince';
import { forEach } from 'lodash';
class BarGauge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
        this.getChartInfo = this.getChartInfo.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            (this.props.job || this.props.shouldFetch) &&
            this.props.user &&
            JSON.stringify(this.props) !== JSON.stringify(prevProps)
        ) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });

            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });
        }
    }

    componentDidMount() {
        if ((this.props.job || this.props.shouldFetch) && this.props.user && !this.props.data) {
            this.interval = setInterval(() => {
                this.getChartInfo((res) => {
                    console.log('INTERVAL');
                    if (this.mounted) {
                        this.setState({
                            res: res,
                        });
                    }
                });
            }, this.props.refreshInterval || 120000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.mounted = false;
    }

    getChartInfo = (callback) => {
        let apiUrl =
            process.env.REACT_APP_API +
            '/api/kpi?valueFormat=single&dataType=' +
            this.props.dataType +
            '&dataValue=' +
            this.props.dataValue;
        if (this.props.job) {
            const daterange =
                '&from=' + this.props.job.startDate.toISOString() + '&to=' + this.props.job.endDate.toISOString();
            apiUrl =
                process.env.REACT_APP_API +
                '/api/kpi/range?calculation=sum&valueFormat=' +
                (this.props.valueFormat || 'range') +
                daterange +
                '&rangeformat=hour&dataType=' +
                this.props.dataType +
                '&dataValue=' +
                this.props.dataValue;
        }

        forEach(this.props.components, (component) => {
            apiUrl += '&componentId=' + component;
        });

        api.get(apiUrl)
            .then((res) => {
                if (this.props.job) {
                    const summedData = res.data.data?.map((d) => d.value).reduce((a, b) => a + b, 0);
                    return callback({
                        data: {
                            value: summedData,
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            timestamp: new Date(Math.max(...res.data.data?.map((e) => new Date(e.date)))),
                        },
                    });
                }
                return callback(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    setBarColor() {
        const threshold = this.props.configuration.threshold;
        const value = this.state.res ? this.state.res.data.value : this.props.data ? this.props.data.data.value : 0;
        if (threshold) {
            if (value > threshold.alertHigh || value < threshold.alertLow) {
                return 'linear-gradient(90deg, rgba(255,0,85,0), rgba(255,0,85,0.6))';
            } else if (value > threshold.warningHigh || value < threshold.warningLow) {
                return 'linear-gradient(90deg, rgba(201,181,78,0), rgba(201,181,78,0.6))';
            }
        }
        return 'linear-gradient(90deg, rgba(65,200,240,0), rgba(65,200,240,0.6))';
    }

    render() {
        const res = this.props.job ? this.state.res || { data: {} } : this.state.res || this.props.data || { data: {} };

        const minValue = this.props.configuration.minValue;
        const maxValue = this.props.configuration.maxValue;

        const decimals = this.props.configuration.decimalPlaces || 1;
        const config = this.props.configuration;

        let current;
        try {
            current = res.data.value ? res.data.value.toFixed(decimals) : res.data.value;
        } catch (error) {
            current = res.data.value;
        }

        const totalSpan = maxValue - minValue;
        let currentCalc = current;
        if (current < 0 || minValue < 0) {
            currentCalc = (minValue - current) * -1;
        }

        let currentPercent = current != null ? (currentCalc / totalSpan) * 100 : 0;
        if (currentPercent > 100) {
            currentPercent = 100;
        } else if (currentPercent < 0) {
            currentPercent = 0;
        }

        return (
            <div className={'bar-gauge ' + (this.props.configuration.size ? this.props.configuration.size : '')}>
                <span className="time-since">{getTimeSince(res)}</span>
                <div className="widget-header">
                    <span className="widget-title">{this.props.title}</span>
                    <span className="widget-value">
                        {current != null
                            ? current +
                              (config ? (!config.usePayloadUnit ? config.unit || '' : res.data.payloadUnit || '') : '')
                            : ''}
                    </span>
                </div>

                <div className="bar-gauge-bar">
                    <div
                        className="bar-gauge-bar-progress"
                        style={{
                            width: currentPercent + '%',
                            backgroundImage: this.setBarColor(),
                            borderRight: '2px solid white',
                        }}
                    ></div>
                </div>

                <div className="bar-gauge-values">
                    <span>{minValue || 0}</span> <span>{maxValue || 100}</span>
                </div>
            </div>
        );
    }
}

BarGauge.propTypes = {};

export default BarGauge;
