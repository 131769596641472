import React from 'react';
import PropTypes from 'prop-types';
import '../widget.css';
import Logo from '../../../images/Optimar_Commander_Negativ.svg';
import UploadStatus from '../../common/PlantStatus/UploadStatus';
import Position from '../../common/PlantStatus/Position';
import SubscribeButton from '../../../components/common/SubscribeButton/SubscribeButton';
import api from '../../../utility/api';
import { isTablet } from 'react-device-detect';
import UserInfoToolTip from '../../common/UserInfoTooltip/UserInfoTooltip';

class PlantInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
    }

    componentDidUpdate(prevProps, _prevState) {
        if (this.props.user) {
            if (this.props.itemId && (!this.state.item || this.props.itemId !== prevProps.itemId)) {
                this.fetchItemData((res) => {
                    if (this.mounted)
                        this.setState({
                            item: res,
                            plant: undefined,
                        });
                });
            }
        }
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.itemId) {
                this.fetchItemData((res) => {
                    if (this.mounted)
                        this.setState({
                            item: res,
                            plant: undefined,
                        });
                });
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchItemData = (callback) => {
        api.get(`/api/items/${this.props.itemId}`)
            .then((res) => {
                callback(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    renderFishfarmFields() {
        const plant = this.props.plant;
        if (plant.plantTypeName !== 'Fishfarm') return null;

        return (
            <div className="fish-farm-table">
                <h3 className={isTablet ? 'tablet-h3' : ''}>Fish farm info</h3>
                <div className="plant-table">
                    <div className="table-row">
                        <span className="label">Has reported lice</span>
                        <span className="value">{plant.bwData.hasReportedLice ? 'Yes' : 'No'}</span>
                    </div>

                    <div className="table-row">
                        <span className="label">Is above lice threshold</span>
                        <span className="value">{plant.bwData.aboveLiceThreshold ? 'Yes' : 'No'}</span>
                    </div>

                    <div className="table-row">
                        <span className="label">Average adult female lice per fish</span>
                        <span className="value">
                            {plant.bwData.avgAdultFemaleLice ? plant.bwData.avgAdultFemaleLice : 0}
                        </span>
                    </div>

                    <div className="table-row">
                        <span className="label">Has salmonoids</span>
                        <span className="value">{plant.bwData.hasSalmonoids ? 'Yes' : 'No'}</span>
                    </div>

                    <div className="table-row">
                        <span className="label">Cleaner fish deployed</span>
                        <span className="value">{plant.bwData.hasCleanerFishDeployed ? 'Yes' : 'No'}</span>
                    </div>

                    <div className="table-row">
                        <span className="label">Substance treatments</span>
                        <span className="value">{plant.bwData.hasSubstanceTreatments ? 'Yes' : 'No'}</span>
                    </div>

                    <div className="table-row">
                        <span className="label">Is on land</span>
                        <span className="value">{plant.bwData.isOnLand ? 'Yes' : 'No'}</span>
                    </div>

                    <div className="table-row">
                        <span className="label">Is fallow</span>
                        <span className="value">{plant.bwData.isFallow ? 'Yes' : 'No'}</span>
                    </div>

                    <div className="table-row">
                        <span className="label">Municipality</span>
                        <span className="value">{plant.bwData.municipality}</span>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const plant = this.props.plant;
        let item = undefined;

        if (this.props.itemId) item = this.state.item;
        if (!item && !plant) return <div></div>;

        return (
            <>
                <div id="plant-info-wrapper">
                    {item ? (
                        <div id="plant-info">
                            <div className="plant-table">
                                <div className="table-row">
                                    <span className="label">Component Id</span>
                                    <span className="value">
                                        {this.state.item.componentId || <span className="inactive">-</span>}
                                    </span>
                                </div>
                                <div className="table-row">
                                    <span className="label">Specification</span>
                                    <span className="value">
                                        {this.state.item.specification || <span className="inactive">-</span>}
                                    </span>
                                </div>
                                <div className="table-row">
                                    <span className="label">Type</span>
                                    <span className="value">
                                        {this.state.item.itemTypeName || <span className="inactive">-</span>}
                                    </span>
                                </div>
                                <div className="table-row">
                                    <span className="label">Article</span>
                                    <span className="value">
                                        {this.state.item.articles.map((a) => a.label).join(', ') || (
                                            <span className="inactive">-</span>
                                        )}
                                    </span>
                                </div>
                                <div className="table-row">
                                    <span className="label">Order Pos</span>
                                    <span className="value">
                                        {this.state.item.orderPos || <span className="inactive">-</span>}
                                    </span>
                                </div>
                                <div className="table-row">
                                    <span className="label">Product Sheet</span>
                                    <span className="value">
                                        {this.state.item.productSheet != null ? (
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={this.state.item.productSheet}
                                            >
                                                DOWNLOAD
                                            </a>
                                        ) : (
                                            <span className="inactive">-</span>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div id="plant-info">
                                <div id="plant-info-details">
                                    <div className="plant-table">
                                        <div className="table-row">
                                            <span className="label">Cloud Connection</span>
                                            <span className="value">{UploadStatus(plant)}</span>
                                        </div>

                                        {plant.plantTypeName === 'Factory' ? (
                                            <div className="table-row">
                                                <span className="label">Address</span>
                                                <span className="value">{plant.address}</span>
                                            </div>
                                        ) : plant.plantTypeName === 'Fishfarm' ? (
                                            <div className="table-row">
                                                <span className="label">Position</span>
                                                <span className="map-value">{Position(plant)}</span>
                                            </div>
                                        ) : (
                                            <div className="table-row">
                                                <span className="label">Last Position</span>
                                                <span className="map-value">{Position(plant)}</span>
                                            </div>
                                        )}

                                        {plant.plantTypeName === 'Factory' ||
                                        plant.plantTypeName === 'Fishfarm' ? null : (
                                            <div className="table-row">
                                                <span className="label">IMO</span>
                                                <span className="value">{plant.imo || ''}</span>
                                            </div>
                                        )}
                                        <div className="table-row">
                                            <span className="label">Description</span>
                                            <span className="value">{plant.description || ''}</span>
                                        </div>
                                        <div className="table-row">
                                            <span className="label">Customer Support Manager</span>
                                            <span className="value" data-tip data-for="csmTooltip">
                                                {plant.accountManager || ''}
                                            </span>
                                            <UserInfoToolTip
                                                tooltipId="csmTooltip"
                                                userId={plant.accountManagerId}
                                            ></UserInfoToolTip>
                                        </div>
                                        {/*Hide warranty manager.. Should not be visible in Commander. #6816, 22.06.2022 */}
                                        {/* <div className="table-row">
                                    <span className="label">Warranty Manager</span><span className="value" data-tip data-for="warrantyManagerTooltip">{plant.warrantyManagerDisplayname || plant.warrantyManagerName || ''}</span>
                                    <UserInfoToolTip tooltipId="warrantyManagerTooltip" userId={plant.warrantyManagerId}>
                                    </UserInfoToolTip>
                                    
                                </div> */}
                                        <div className="table-row">
                                            <span className="label">Plant Key</span>
                                            <span className="value">{plant.topicKey || ''}</span>
                                        </div>
                                        <div className="table-row">
                                            <span className="label">Subscription</span>
                                            <span className="value">
                                                <SubscribeButton plant={plant.id} notificationType={'all'} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>{' '}
                            <div
                                id="plant-image"
                                style={{
                                    background: `url('${
                                        plant.image.includes('missing') ? Logo : plant.image
                                    }') no-repeat center center`,
                                }}
                            ></div>
                        </>
                    )}
                </div>
                {this.renderFishfarmFields()}
            </>
        );
    }
}

PlantInfo.propTypes = {
    itemId: PropTypes.string,
};

export default PlantInfo;
