import React from 'react';

export const ADD_UPDATE_BUTTON = 'btn btn-blue btn-grow';
export const CANCEL_BUTTON = 'btn btn-yellow btn-grow';
export const DISCARD_BUTTON = 'btn btn-red';

const GeneralButton = ({ children, disabled, onClick, className, hidden, style }) => {
    return (
        <button disabled={disabled} onClick={onClick} className={className} style={style} hidden={hidden}>
            {children}
        </button>
    );
};

export default GeneralButton;
