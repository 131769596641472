import React from 'react';
import api from '../../../utility/api';
import ReactDOM from 'react-dom';

class ItemDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterString: '',
            expanded: [],
            list: [],
            data: [],
            items: [],
            //handling dropdown position changes when window resize
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            showLabel: typeof props.showLabel === 'undefined' ? true : props.showLabel,
            showHiddenItems: typeof props.showHiddenItems === 'undefined' ? false : props.showHiddenItems,
        };
        this.searchBoxRef = React.createRef('map-search-input');
    }

    componentDidMount() {
        const self = this;
        window.addEventListener('resize', (event) => this.handleResize(self, event));
        window.addEventListener('scroll', (event) => this.handleScroll(self, event));

        this.getStructure(this.props.plantId);
    }
    componentWillUnmount() {
        const self = this;
        window.removeEventListener('resize', (event) => this.handleResize(self, event));
        window.removeEventListener('scroll', (event) => this.handleScroll(self, event));
    }

    componentDidUpdate() {
        if (this.state.items.length > 0 && !this.state.selectedItem) {
            if (this.props.selectedItem) {
                const propItm = this.props.selectedItem;
                const selectedItem =
                    typeof propItm === 'string'
                        ? this.state.items.find(
                              (item) =>
                                  item.topic?.replace(item.topic.split('/')[0], '') === '/' + this.props.selectedItem
                          )
                        : this.state.items.find((item) => item.id === propItm.id);
                if (selectedItem) {
                    this.setState({
                        selectedItem: selectedItem,
                    });
                }
            }
        }
        if (!this.state.selectedItem && this.props.currentItem && this.state.flatData) {
            const existingItem = this.state.flatData.find((x) => x.id === this.props.currentItem);
            console.log(existingItem);
            if (existingItem) {
                this.setState({
                    selectedItem: this.state.flatData.find((x) => x.id === this.props.currentItem),
                });
                this.props.selectItem(existingItem);
            }
        }
    }

    handleScroll(self, event) {
        if (self && self.setState) {
            self.setState({
                scrolltimeStamp: event.timeStamp,
            });
        } else {
            console.log('Missing self.');
        }
    }
    handleResize(self, _event) {
        if (self && self.setState) {
            self.setState({
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth,
            });
        } else {
            console.log('Missing self.');
        }
    }

    getStructure(plantId) {
        const apiUrl = process.env.REACT_APP_API + '/api/plants/' + plantId + '/items/structure';

        api.get(apiUrl)
            .then((res) => {
                this.fetchingStructure = false;
                if (res.status !== 204) {
                    this.unflatten(res.data);
                    this.setState({
                        flatData: res.data,
                    });
                } else if (res.status === 204) {
                    this.unflatten();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    unflatten(data) {
        if (data) {
            const ID_KEY = 'id';
            const PARENT_KEY = 'parentId';
            const CHILDREN_KEY = 'children';

            const tree = [],
                childrenOf = {};
            let item, id, parentId;

            for (let i = 0, length = data.length; i < length; i++) {
                item = data[i];
                //console.log(item);
                if (!this.state.showHiddenItems && !item.showInTableView) continue;
                id = item[ID_KEY];
                parentId = item[PARENT_KEY] || '00000000-0000-0000-0000-000000000000';
                // every item may have children
                childrenOf[id] = childrenOf[id] || [];
                // init its children
                item[CHILDREN_KEY] = childrenOf[id];
                item.toggled = true;
                if (parentId !== '00000000-0000-0000-0000-000000000000') {
                    // init its parent's children object
                    childrenOf[parentId] = childrenOf[parentId] || [];
                    // push it into its parent's children object
                    childrenOf[parentId].push(item);
                } else {
                    tree.push(item);
                }
            }

            this.setState(
                {
                    data: tree,
                    items: data,
                },
                () => this.generateList()
            );
        }
    }

    findNode(node) {
        const fString = this.state.filterString.toLowerCase();
        if (
            node.name.toLowerCase().includes(fString) ||
            (node.orderPos && node.orderPos.toLowerCase().includes(fString)) ||
            (node.productSheet && node.productSheet.toLowerCase().includes(fString)) ||
            node.children.find((child) => this.findNode(child))
        ) {
            if (!this.state.expanded.includes(node.parentId)) {
                const tmp = this.state.expanded;
                tmp.push(node.parentId);
                this.setState({
                    expanded: tmp,
                });
            }
            return true;
        }
    }

    generateList() {
        const list = [];

        const sortedNodes = this.state.data.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
        );

        sortedNodes.map((node) => {
            return this.generateListSection(node, list, 0);
        });

        //let flatList = this.collect(sortedNodes, [], 0);

        this.setState({
            list: list,
            generated: true,
        });
    }

    collect(array, res, prevIndex) {
        const _this = this;

        array.forEach(function (el) {
            if (el.children && el.children.length > 0) {
                _this.collect(el.children, res, prevIndex++);
            } else {
                res.push({ value: el.id, label: '-'.repeat(prevIndex++) + el.name });
            }
        });

        return res;
    }

    setItem(item) {
        if (item) {
            this.setState({ selectedItem: item }, this.props.selectItem(item));
        }
    }

    generateListSection(node, list, level) {
        if (node.parentId === '00000000-0000-0000-0000-000000000000' && this.findNode(node)) {
            list.push(
                <span key={node.id} className="search-drodown-element" onMouseDown={() => this.setItem(node)}>
                    {node.name}
                </span>
            );
        } else {
            const styles = {};
            if (level >= 1) {
                styles.paddingLeft = level * 17 + 'px';
            }

            if (this.state.filterString === '' || this.findNode(node)) {
                list.push(
                    <span
                        style={styles}
                        key={node.id}
                        className="search-drodown-element"
                        onMouseDown={() => this.setItem(node)}
                    >
                        {node.name}
                    </span>
                );
            }
        }

        if (node.children.length > 0) {
            ++level;
            const sortedChildren = node.children.sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
            );

            sortedChildren.map((n) => {
                return this.generateListSection(n, list, level);
            });
        }

        return;
    }

    getItemName() {
        const selected = this.state.items.find((item) => item.id === (this.state.selectedItem || {}).id);
        if (selected) {
            return this.escapapeItemName(selected.name);
        }

        return '';
    }

    escapapeItemName(itemName) {
        if (itemName) {
            return itemName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }
        return '';
    }
    buildDropdownList(scopeToRef) {
        const scopeStyle = {
            display: this.state.showItemList ? 'flex' : 'none',
        };
        if (scopeToRef && !this.searchBoxRef.current) return null;
        if (scopeToRef) {
            // const { offsetTop } = this.searchBoxRef.current;
            // console.log(`Offsetting: ${offsetTop}`);
            //onsole.log(this.searchBoxRef.current.getBoundingClientRect())
            //https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
            const searchBoxBoundRect = this.searchBoxRef.current.getBoundingClientRect();
            scopeStyle.left = `${searchBoxBoundRect.left + window.scrollX}px`;
            scopeStyle.top = `${searchBoxBoundRect.bottom + window.scrollY}px`;
            scopeStyle.width = `${searchBoxBoundRect.width}px`;
            scopeStyle.zIndex = 9999;
        }
        // menuPortalTarget={document.body}
        // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

        return (
            <div style={scopeStyle} className="search-items-dropdown">
                <span style={{ paddingLeft: '10px', fontSize: '12px' }}>
                    {this.state.list ? `Showing ${this.state.list.length} results` : ''}
                </span>
                {this.state.list
                    ? this.state.list.map((m) => {
                          return m;
                      })
                    : null}
            </div>
        );
    }

    render() {
        //parent must create a ref to modal dialog and pass into component via 'modalRef' in order to use ReactDOM.createPortal
        const domNode = this.props.modalRef?.current;
        //console.log("domNode2", domNode);
        return (
            <div className="form-group" style={{ position: 'relative' }}>
                {this.state.showLabel ? <label htmlFor="content">Component</label> : null}
                <div>
                    <input
                        onKeyPress={this.handleEnterPress}
                        autoComplete="off"
                        //ref={"map-search-input"}
                        ref={this.searchBoxRef}
                        type="search"
                        value={(this.state.selectedItem || {}).name}
                        placeholder="Search"
                        pattern={this.getItemName()}
                        className="search-items"
                        title="Name does not match selected item"
                        onFocusCapture={() => this.setState({ showItemList: true })}
                        onBlur={() => this.setState({ showItemList: false })}
                        onChange={(e) => {
                            this.setState(
                                { selectedItem: e.target.value, filterString: e.target.value },
                                this.generateList
                            );
                            this.props.onComponentChange(e);
                        }}
                        required={this.props.required}
                    />
                    {domNode
                        ? ReactDOM.createPortal(this.buildDropdownList(true), domNode.node)
                        : this.buildDropdownList()}
                </div>
            </div>
        );
    }
}

export default ItemDropdown;
