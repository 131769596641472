import React from 'react';

import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';

import api from '../../utility/api';

export async function ReportConfigCreate(props, callback) {
    try {
        const response = await api.post(process.env.REACT_APP_API + '/api/reportconfig?jobTypeId=' + props.id);

        if (response.status !== 200) throw new Error(response.status);

        NotificationManager.success('Report config created', 'Report config successfully created', 5000);
        callback();
    } catch (error) {
        console.error(error);
        NotificationManager.error('Error', 'Failed to create report config', 5000);
    }
}

export function ReportConfigRemove(props, callback) {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>Delete report config</h1>
                    <p>
                        You are about to delete the report config for
                        <br />{' '}
                    </p>
                    <span>
                        <b>{props.name}</b>
                    </span>
                    <p>Are you sure?</p>
                    <div className="buttons">
                        <button className="btn btn-yellow" onClick={onClose}>
                            No, cancel
                        </button>
                        <button
                            className="btn btn-red"
                            onClick={async () => {
                                try {
                                    const response = await api.delete(
                                        process.env.REACT_APP_API + '/api/reportconfig/' + props.reportConfigId
                                    );

                                    if (response.status !== 200) throw new Error(response.status);

                                    NotificationManager.success(
                                        'Report config deleted',
                                        'Report config successfully deleted',
                                        5000
                                    );
                                    callback();
                                } catch (error) {
                                    console.error(error);
                                    NotificationManager.error('Error', 'Failed to delete report config', 5000);
                                }
                                onClose();
                            }}
                        >
                            Yes, delete
                        </button>
                    </div>
                </div>
            );
        },
    });
}

export function ReportConfigClearReports(props, callback) {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>Delete all reports</h1>
                    <p>
                        You are about to delete all reports for
                        <br />{' '}
                    </p>
                    <span>
                        <b>{props.name}</b>
                    </span>
                    <p>Are you sure?</p>
                    <div className="buttons">
                        <button className="btn btn-yellow" onClick={onClose}>
                            No, cancel
                        </button>
                        <button
                            className="btn btn-red"
                            onClick={async () => {
                                try {
                                    const response = await api.delete(
                                        process.env.REACT_APP_API + '/api/jobtypes/clearreports/' + props.id
                                    );

                                    if (response.status !== 200) throw new Error(response.status);

                                    NotificationManager.success(
                                        'Reports deleted',
                                        'Reports successfully deleted',
                                        5000
                                    );
                                    callback();
                                } catch (error) {
                                    console.error(error);
                                    NotificationManager.error('Error', 'Failed to delete reports', 5000);
                                }
                                onClose();
                            }}
                        >
                            Yes, delete
                        </button>
                    </div>
                </div>
            );
        },
    });
}
