import axios from 'axios';
import store from '../index';
import { setToS } from '../actions';
import { NotificationManager } from 'react-notifications';
import { getToken, tryReauthenticate } from './oidchelper';

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use((req) => {
    //Set auth token from session storage
    const token = getToken();
    req.headers.Authorization = `Bearer ${token}`;

    //Handle impersonation if applicable.
    const impersonate = sessionStorage.getItem('userImpersonate');
    const impersonateName = sessionStorage.getItem('userImpersonateName');
    const impersonateAcc = sessionStorage.getItem('userImpersonateAcc');
    const impersonateRole = sessionStorage.getItem('userImpersonateRole');
    //console.log("request use",impersonate,impersonateName,impersonateAcc,impersonateRole)
    if (impersonate && impersonateName && impersonateAcc && impersonateRole) {
        req.headers['Commander-Usr-Imp'] = impersonate;
        req.headers['Commander-Usr-Imp-Acc'] = impersonateAcc;
        req.headers['Commander-Usr-Imp-Name'] = impersonateName;
        req.headers['Commander-Usr-Imp-Role'] = impersonateRole;
    }
    return req;
});
let reAuthPromise;
api.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        const res = err.response;
        if (res && res.status === 401 && !res.data.includes('Terms')) {
            console.log('No longer authenticated', res.data);
            if (reAuthPromise) return Promise.reject(err);

            reAuthPromise = tryReauthenticate();

            NotificationManager.warning('Credentials outdated. Renewing...', 'Error fetching data', 5000);
            reAuthPromise
                .then((res) => {
                    reAuthPromise = null;
                    if (res) {
                        console.log('Reauthenticated successfully', res);
                        NotificationManager.success(
                            'Credentials verified successfully. You can continue to use the app normally.',
                            'Authentication succeeded',
                            5000
                        );
                    } else {
                        console.log('Not reauthenticated successfully..');
                    }
                })
                .catch((err) => {
                    console.log('Not reauthenticated successfully', err);
                    reAuthPromise = null;
                });

            return Promise.reject(err);
        } else if (res && res.status === 401 && res.data.includes('Terms')) {
            //console.log("SET TOS FALSE PLS");
            store.dispatch(setToS(false));
        } else {
            return Promise.reject(err);
        }
    }
);

export default api;
//not in use.. getting token from sessionStorage instead on each call
export const setToken = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
};
