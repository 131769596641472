import { matchSorter } from 'match-sorter';
import React, { useState, forwardRef, useImperativeHandle, useCallback, useEffect, Suspense, lazy } from 'react';
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { ReactComponent as FilterIcon } from '../../../images/filter.svg';
import Spinner from '../../../components/common/Spinner/Spinner';
import { DefaultColumnFilter, GlobalFilter } from '../../../components/common/GenericTable/Filters';
import './OperationTable.scss';
import typeRender from '../../../components/common/GenericTable/Utility/ColumnTypeRenderer';
import defaultGlobalFilter from '../Utils/OperationTableUtil';
import { TABLE_VIEW } from '../joblist';
import DateRangePicker from '../../../components/common/DateRangePicker/DateRangePicker';
import { ViewSwitcher } from '../../../components/common/GanttViewSwitcher/ViewSwitcher';
import { useJoblistProviderContext } from '../Contexts/JoblistProvider';
import GanttFilter from '../Filter/GanttFilter';
import GeneralButton, {
    ADD_UPDATE_BUTTON,
    CANCEL_BUTTON,
} from '../../../components/common/Buttons/generalButton/GeneralButton';
import api from '../../../utility/api';
import { NotificationManager } from 'react-notifications';

const GanttTable = lazy(() => import('../GanttView/GanttTable.js'));

/**
 * @type {React.ForwardRefRenderFunction<
 *     { closeAllExpanded: () => void },
 *     {
 *         columns: any;
 *         data: any;
 *         localPaging?: boolean;
 *         fetchData: any;
 *         loading: any;
 *         defaultPageSize?: number;
 *         header: any;
 *         rowClick: any;
 *         triggerFetch: any;
 *         expandedRowContent: any;
 *         enableGlobalFilter: any;
 *         autoResetExpanded?: boolean;
 *         autoResetHiddenColumns?: boolean;
 *         autoResetFilters?: boolean;
 *         autoResetGroupBy?: boolean;
 *         autoResetSelectedRows?: boolean;
 *         autoResetRowState?: boolean;
 *         autoResetSortBy?: boolean;
 *         autoResetGlobalFilter?: boolean;
 *         isHeaderEnabled: boolean;
 *         isPagingEnabled: boolean;
 *         expandedRowContent: boolean;
 *     }
 * >}
 */
const OperationTable = forwardRef(
    (
        {
            columns,
            data,
            localPaging = false,
            fetchData,
            loading,
            pageCount: controlledPageCount,
            defaultPageSize = 10,
            header,
            rowClick,
            triggerFetch,
            expandedRowContent,
            enableGlobalFilter,
            autoResetExpanded = false,
            autoResetHiddenColumns = false,
            autoResetFilters = false,
            autoResetGroupBy = false,
            autoResetSelectedRows = false,
            autoResetRowState = false,
            autoResetSortBy = false,
            autoResetGlobalFilter = false,
            isHeaderEnabled = true,
            isPagingEnabled = true,
            expandedRowContentEnabled = true,
            enableChildFilter = false,
            viewType = TABLE_VIEW,
            toggleDashboard,
            fetchJobs,
            dateRange,
            onDateChange
        },
        ref
    ) => {
        const filterTypes = React.useMemo(
            () => ({
                fuzzyText: fuzzyTextFilterFn,
                text: (rows, id, filterValue) => {
                    // eslint-disable-next-line
                    return rows.filter((row) => {
                        const rowValue = row.values[id];
                        return rowValue !== undefined
                            ? // eslint-disable-next-line
                              String(rowValue)
                                  .toLowerCase()
                                  // eslint-disable-next-line
                                  .startsWith(String(filterValue).toLowerCase())
                            : true;
                    });
                },
            }),
            []
        );
        const filterOptions = { filteredIds: [] };
        const [isHeaderEnableInternal, setIsHeaderEnable] = useState(true);
        const [isPagingEnabledInternal, setisPagingEnabled] = useState(true);
        const [expandedRowContentEnabledInternal, setExpandedRowContentEnabled] = useState(true);
        const [enableChildFilterInternal, setenableChildFilter] = useState(false);

        const defaultColumn = React.useMemo(
            () => ({
                Filter: DefaultColumnFilter,
            }),
            []
        );

        const tableConfig = {
            columns,
            data,
            defaultColumn,
            filterTypes,
            getSubRows: (row) => row.subRows,
            initialState: {
                pageIndex: 0,
                hiddenColumns: columns.filter((column) => column.show === false).map((column) => column.id),
                filters: [],
                sortBy: [],
            },
            globalFilter: (rows, columnIds, filterValue) =>
                defaultGlobalFilter(rows, columnIds, filterValue, filterOptions),
            manualPagination: !localPaging,
            pageCount: controlledPageCount,
            autoResetPage: false,
            manualFilters: !localPaging,
            manualSortBy: !localPaging,
            autoResetExpanded,
            autoResetHiddenColumns,
            autoResetFilters,
            autoResetGroupBy,
            autoResetSelectedRows,
            autoResetRowState,
            autoResetSortBy,
            autoResetGlobalFilter,
        };

        if (localPaging) {
            delete tableConfig.pageCount;
        }

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            preGlobalFilteredRows,
            setGlobalFilter,
            setPageSize,
            toggleAllRowsExpanded,
            state: { pageIndex, pageSize, filters, sortBy, globalFilter },
        } = useTable(tableConfig, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination);

        React.useEffect(() => {
            setPageSize(defaultPageSize);
        }, [defaultPageSize, setPageSize]);
        React.useEffect(() => {
            setIsHeaderEnable(isHeaderEnabled);
            setisPagingEnabled(isPagingEnabled);
        }, [isHeaderEnabled, isPagingEnabled]);

        useImperativeHandle(
            ref,
            () => ({
                closeAllExpanded() {
                    toggleAllRowsExpanded(false);
                },
            }),
            [toggleAllRowsExpanded]
        );

        React.useEffect(() => {
            if (!localPaging) {
                fetchData({ pageIndex, pageSize, filtered: filters, sorted: sortBy });
            }
            // eslint-disable-next-line
        }, [pageIndex, pageSize]);

        React.useEffect(() => {
            fetchData?.({ pageIndex, pageSize, filtered: filters, sorted: sortBy, globalFilter });
        }, [triggerFetch, fetchData, pageIndex, pageSize, filters, sortBy, globalFilter]);

        React.useEffect(() => {
            setExpandedRowContentEnabled(expandedRowContentEnabled);
        }, [expandedRowContentEnabled]);
        useEffect(() => {
            setenableChildFilter(enableChildFilter);
        }, [enableChildFilter]);

        const setGlobalFilterWrapper = (value) => {
            setGlobalFilter(value);
        };

        const getSubRowsIds = useCallback((subRows) => {
            subRows.forEach((subRow) => {
                if (subRow.subRows) {
                    getSubRowsIds(subRow.subRows);
                }
            });
        }, []);

        React.useEffect(() => {
            if (viewType === TABLE_VIEW) {
                if (changedTasks && changedTasks.length > 0) {
                    setChangedTasks([]);
                }                
                if (enableChildFilterInternal) {
                    if (globalFilter) {
                        page.forEach((row) => {
                            row.toggleRowExpanded(row.id, true);
                        });
                    } else if (globalFilter === null) {
                        page.forEach((row) => {
                            if (row.isExpanded) {
                                row.toggleRowExpanded();
                            }
                        });
                        setGlobalFilter(undefined);
                    }
                }
            }
        }, [globalFilter, getSubRowsIds, page, setGlobalFilter, enableChildFilterInternal, viewType]);

        //Show or hide column filter.
        const toggleFilter = (columnId) => {
            if (isFiltering.includes(columnId)) {
                const tmp = [...isFiltering];
                tmp.splice(tmp.indexOf(columnId), 1);
                setIsFiltering(tmp);
                return;
            }
            setIsFiltering([...isFiltering, columnId]);
        };

        const [isFiltering, setIsFiltering] = React.useState([]);

        React.useEffect(() => {
            if (pageIndex + 1 > pageCount) {
                gotoPage(0);
            }
        }, [pageIndex, pageCount, gotoPage]);

        const { view, setView, isChecked, setIsChecked, changedTasks, setChangedTasks } =
            useJoblistProviderContext();

        const [ganttFiler, setGanttFiler] = useState();
        const [isGanttFilterActive, setIsGanttFilterActive] = useState(false);
        const [rerenderGantt, setRerenderGantt] = useState(false);
        const [savingState, setSavingState] = useState(false);

        useEffect(() => {
            RerenderGantt();
        }, [ganttFiler, view]);

        const saveChangesOnClick = async () => {
            let tasks = changedTasks;
            setSavingState(true);
            setChangedTasks([]);
            const response = await api.put(process.env.REACT_APP_API + '/api/jobs/bulk_update', tasks);
            if (response.status === 200) {
                NotificationManager.success('Job updated successfully', 'Job updated', 5000);
            } else {
                NotificationManager.error('Job not updated successfully', 'Error updating job', 5000);
            }
            await fetchJobs();
            RerenderGantt();
            setSavingState(false);
        };

        const RerenderGantt = () => setRerenderGantt(!rerenderGantt);

        return (
            <>
                {header ? (
                    <div className="gt-header">
                        <div className="gt-header-left">{header.left}</div>
                        <div className="gt-header-center">
                            {viewType === TABLE_VIEW ? null : (
                                <ViewSwitcher
                                    onViewModeChange={(viewMode) => setView(viewMode)}
                                    onViewListChange={setIsChecked}
                                    isChecked={isChecked}
                                    viewMode={view}
                                />
                            )}
                            <DateRangePicker value={dateRange} onChange={onDateChange} isClearable={false} />
                            {viewType === TABLE_VIEW ? null : (
                                <>
                                    <GeneralButton
                                        className={CANCEL_BUTTON}
                                        hidden={changedTasks.length === 0 ? true : false}
                                        onClick={() => {
                                            setChangedTasks([]);
                                            setRerenderGantt(!rerenderGantt);
                                        }}
                                        style={{ marginLeft: '30px' }}
                                    >
                                        Cancel
                                    </GeneralButton>
                                    <GeneralButton
                                        className={ADD_UPDATE_BUTTON}
                                        disabled={changedTasks.length === 0 ? true : false}
                                        onClick={saveChangesOnClick}
                                        style={{ marginLeft: '30px' }}
                                    >
                                        {savingState ? 'Saving...' : 'Save Changes'}
                                    </GeneralButton>
                                </>
                            )}
                            {header.center}
                        </div>
                        <div className="gt-header-right">{header.right}</div>
                    </div>
                ) : null}
                {viewType === TABLE_VIEW ? (
                    enableGlobalFilter ? (
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilterWrapper}
                        />
                    ) : null
                ) : (
                    <GanttFilter
                        ganttFilter={ganttFiler}
                        setGanttFilter={setGanttFiler}
                        data={data}
                        setIsGanttFilterActive={setIsGanttFilterActive}
                        isGanttFilterActive={isGanttFilterActive}
                    />
                )}
                {viewType === TABLE_VIEW ? (
                    <>
                        <table {...getTableProps} className="generictable">
                            <thead className="gt-thead">
                                {isHeaderEnableInternal
                                    ? headerGroups.map((headerGroup) => (
                                          <tr key={`hdrtr${headerGroup.id}`} {...headerGroup.getHeaderGroupProps()}>
                                              {headerGroup.headers.map((column) => (
                                                  <th
                                                      key={`hdrth${column.id}`}
                                                      {...column.getHeaderProps(column.getSortByToggleProps())}
                                                  >
                                                      <span>
                                                          {column.render('Header')}
                                                          {column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}
                                                          {column.canFilter ? (
                                                              <FilterIcon
                                                                  className="gt-filter-icon"
                                                                  onClick={(e) => {
                                                                      e.stopPropagation();
                                                                      toggleFilter(column.id);
                                                                  }}
                                                              />
                                                          ) : null}
                                                      </span>
                                                      <div>
                                                          {column.canFilter && isFiltering.includes(column.id)
                                                              ? column.render('Filter')
                                                              : null}
                                                      </div>
                                                  </th>
                                              ))}
                                          </tr>
                                      ))
                                    : null}
                            </thead>

                            <tbody {...getTableBodyProps()} className="gt-tbody">
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <React.Fragment key={`fragment-${row.id}`}>
                                            <tr
                                                {...row.getRowProps()}
                                                onClick={(e) => {
                                                    if (rowClick) {
                                                        rowClick(row, e);
                                                    }
                                                }}
                                                className={'gt-tr ' + (rowClick ? 'clickable' : '')}
                                            >
                                                {row.cells.map((cell, idx) => {
                                                    return (
                                                        <td
                                                            key={`tbodtr${cell.column.id}/${cell.row.id}`}
                                                            style={{
                                                                width: idx === 0 ? 50 : cell.column.width,
                                                                paddingLeft:
                                                                    idx === 0 || idx === 1
                                                                        ? row.original.depth * 23 + 'px'
                                                                        : 0,
                                                            }}
                                                            {...cell.getCellProps()}
                                                            className="gt-td"
                                                        >
                                                            {typeRender(cell)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>

                                            {row.isExpanded ? (
                                                <tr>
                                                    {expandedRowContentEnabledInternal ? (
                                                        <td colSpan={columns.length}>{expandedRowContent(row)}</td>
                                                    ) : null}
                                                </tr>
                                            ) : null}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                            {loading ? (
                                <div className="gt-spinner-container">
                                    <Spinner className="gt-spinner" text="data" />
                                </div>
                            ) : null}
                        </table>
                        {isPagingEnabledInternal ? (
                            <div className={'gt-pagination ' + (pageCount > 1 ? '' : 'center')}>
                                {pageCount > 1 ? (
                                    <div className="gt-btn-group">
                                        <button
                                            className="btn btn-blue"
                                            onClick={() => gotoPage(0)}
                                            disabled={!canPreviousPage}
                                        >
                                            {' '}
                                            &lt;&lt;{' '}
                                        </button>
                                        <button
                                            className="btn btn-blue"
                                            onClick={() => previousPage()}
                                            disabled={!canPreviousPage}
                                        >
                                            {' '}
                                            &lt;{' '}
                                        </button>
                                    </div>
                                ) : null}
                                <div className={'gt-page-settings'}>
                                    <div className="gt-pagenum">
                                        Page
                                        <input
                                            value={pageIndex + 1}
                                            type="number"
                                            className="gt-page-goto"
                                            onChange={(e) => gotoPage(e.target.value ? Number(e.target.value) - 1 : 0)}
                                        />
                                        of {pageCount}
                                    </div>
                                    <div className="gt-show-count">
                                        <div>Show</div>
                                        <select
                                            value={pageSize}
                                            onChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                        >
                                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize} rows
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {pageCount > 1 ? (
                                    <div className="gt-btn-group">
                                        <button
                                            className="btn btn-blue"
                                            onClick={() => nextPage()}
                                            disabled={!canNextPage}
                                        >
                                            {' '}
                                            &gt;{' '}
                                        </button>
                                        <button
                                            className="btn btn-blue"
                                            onClick={() => gotoPage(pageCount - 1)}
                                            disabled={!canNextPage}
                                        >
                                            {' '}
                                            &gt;&gt;{' '}
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </>
                ) : savingState ? (
                    <Spinner text="operation" />
                ) : (
                    <Suspense fallback={<span>Loading..</span>} key={rerenderGantt}>
                        <GanttTable
                            data={data}
                            toggleDashboard={toggleDashboard}
                            isGanttFilterActive={isGanttFilterActive}
                            ganttFiler={ganttFiler}
                        />
                    </Suspense>
                )}
            </>
        );
    }
);

export function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

export default OperationTable;
