import React from 'react';
import { defaultFormula } from '../../../utility/widgetformulamanager';

import { ComponentCollectionEditor, GetDecimalPrecisionField, GetFormulaField } from './utils/DatapointsUtils';
// import { ComponentEditor } from './utils/DatapointsUtils';

const datapoint_type_name = 'Aggregation';
const datapoint_sources = undefined;
// {
//     datavalues: "Data Values",
//     data_aggregates: "Data Aggregates",
//     trace: "Trace"
//}
const new_datapoint = {
    datapointId: 'aggDatapoint',
    type: 'aggregation',
    method: 'avg',
    // "componentId": "",
    // "datavalue": "",
    componentSettings: [],
    //"source":  ""//Object.keys(datapoint_sources)[0]
    formula: defaultFormula,
    numericPrecision: 2,
};

export default () => {
    return {
        name: datapoint_type_name,
        new_datapoint: new_datapoint,
        get_editor: get_editor,
        get_details: get_details,
    };
};

function get_editor(data, onInputChange, props, { show_timeframe = false } = {}) {
    if (!data.componentSettings) onInputChange('componentSettings', []);

    const onComponentEditorChange = (name, value) => {
        // if(name === "na")
        //     return;
        //let updatedData = Object.assign(data, value);

        //console.log("Should updated data",data,value,name)
        // //data.lines[index] =updatedLine;
        onInputChange(name, value);
    };
    const aggregation_form = (
        <div className="form-group">
            <label htmlFor="method">Aggregation Method (Over operation timerange)</label>
            <select
                required
                name="method"
                className={'input-control'}
                onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                value={data.method || ''}
            >
                <option key="m1" value="min">
                    Min
                </option>
                <option key="m2" value="max">
                    Max
                </option>
                <option key="m3" value="avg">
                    Average
                </option>
                <option key="m4" value="sum">
                    Sum
                </option>
                <option key="m5" value="distinct">
                    Distinct
                </option>
                <option key="m5" value="first">
                    First
                </option>
                <option key="m5" value="last">
                    Last
                </option>
            </select>
        </div>
    );

    return (
        <>
            {aggregation_form}

            {/* <ComponentEditor onInputChange={onComponentEditorChange} datapoint_sources={datapoint_sources} data={data} items={props.items} getDatavaluesFunc={props.getDatavaluesFunc} child_jobs ={props.child_jobs} otherProps = {{show_timeframe}} /> */}

            <ComponentCollectionEditor
                onInputChange={onComponentEditorChange}
                datapoint_sources={datapoint_sources}
                data={data}
                items={props.items}
                getDatavaluesFunc={props.getDatavaluesFunc}
                child_jobs={props.child_jobs}
                otherProps={{ show_timeframe }}
            />

            {GetFormulaField(data, onInputChange)}
            {GetDecimalPrecisionField(data, onInputChange)}
            {/* <div className="form-group">
            <label htmlFor="label">Formula</label>
            <input type="text" name="formula" value={data.formula} className="input-control" onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)} />  
        </div>    */}
        </>
    );
}

function get_details(data) {
    return (
        <ul className="datapoint_details">
            <li>Method: {data.method}</li>
            {data.componentSettings?.length > 0 ? (
                data.componentSettings.map((itm, idx) => {
                    return (
                        <li key={`cs_${idx}`}>
                            {itm.componentId} - <i>{itm.datavalue}</i>
                            {itm.__custom__ ? ' (custom)' : ''}
                        </li>
                    );
                })
            ) : (
                <li>
                    Data: {data.componentId} - <i>{data.datavalue}</i>
                    {data.__custom__ ? ' (custom)' : ''}
                </li>
            )}
        </ul>
    );
}
