import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useLocation } from 'react-router-dom';
import { useDataFetcher } from '../../hooks/useDataFetcher';
import api from '../../utility/api';
import { isClosedStatus } from './supportlist';

/**
 * @typedef {{
 *     id: string;
 *     parentId?: any;
 *     parentItemName?: any;
 *     plantId: string;
 *     itemTypeId: string;
 *     itemState: number;
 *     name: string;
 *     machineNumber?: any;
 *     componentId?: any;
 *     serialNumber?: any;
 *     articleId?: any;
 *     metadata?: any;
 *     modifiedOn?: any;
 *     modifiedBy?: any;
 *     showInTableView: boolean;
 *     threshold?: any;
 *     specification?: any;
 *     typeSpecification?: any;
 *     orderPos?: any;
 *     productSheet?: any;
 *     shortName?: any;
 *     itemTypeName: string;
 *     topicKey?: any;
 *     itemKey: string;
 *     topic: string;
 *     itemPath?: any;
 *     level: number;
 *     oldId: string;
 *     itemCategoryId?: any;
 *     subDeviceId?: any;
 *     articles: string;
 *     traceEnabled: boolean;
 * }} CurrentItem
 */
/**
 * @param {{ currentItem?: CurrentItem; accountMode: Boolean; plant: Object; showClosed: Boolean }}
 *
 * @returns {{
 *     cases: Case[];
 *     filteredCases: Case[];
 *     selectedCase: Case | undefined;
 *     refetch: () => void;
 *     fetchingCases: Boolean;
 * }}
 */
export const useSupportCases = ({ currentItem, accountMode, plant, showClosed }) => {
    const location = useLocation();
    const fetchedItemId = useRef(currentItem?.id);

    const [selectedCase, setSelectedCase] = useState();
    const {
        data: response,
        isLoading: fetchingCases,
        refetch: refetchCases,
    } = useDataFetcher(
        async () => {
            let url = accountMode ? 'api/support/account' : `api/support/${plant?.id}`;
            if (currentItem) {
                url += `/${currentItem.id}`;
            }
            return await api.get(url);
        },
        {
            onError(error) {
                console.log('Error fetching support cases', error.response?.data, error);
                NotificationManager.error('Could not fetch support cases', 'Failure', 5000);
            },
        }
    );

    const cases = useMemo(() => response?.data, [response]);

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const getFilteredCases = useCallback(
        (showClosed, data) => (showClosed ? data : data.filter((c) => !isClosedStatus(c.status))),
        []
    );
    useEffect(() => {
        if (fetchingCases) {
            return;
        }
        if (currentItem?.id !== fetchedItemId.current) {
            fetchedItemId.current = currentItem?.id;
            refetchCases();
        }
    }, [fetchingCases, currentItem, refetchCases]);

    useEffect(() => {
        if (cases === undefined) {
            return;
        }
        const supportId = searchParams.get('supportId');
        const openCase = cases.find((c) => c.id === supportId);
        setSelectedCase(openCase);
    }, [cases, searchParams]);

    const filteredCases = useMemo(() => {
        if (cases === undefined) {
            return [];
        }
        return getFilteredCases(showClosed, cases);
    }, [cases, getFilteredCases, showClosed]);

    return {
        selectedCase,
        filteredCases,
        refetchCases,
        fetchingCases,
        cases,
    };
};

/**
 * @typedef {{
 *     id: string;
 *     title: string;
 *     customerRef: string;
 *     optimarRef?: any;
 *     owner?: any;
 *     assignedTo?: any;
 *     ownerName?: any;
 *     assignedToName?: any;
 *     caseTypeId: string;
 *     caseType: string;
 *     description: string;
 *     item: string;
 *     itemPath: string;
 *     plant: string;
 *     reporter: string;
 *     reporterName: string;
 *     sender: string;
 *     statusId: number;
 *     status: string;
 *     created: Date;
 *     updated: Date;
 *     linkedNote?: any;
 *     plantName?: any;
 *     posNum: string;
 *     machineNumber?: any;
 *     deficiencyDescription?: any;
 *     situationDescription?: any;
 *     requiredAction?: any;
 *     occuredBefore?: any;
 *     customerClaimRef: string;
 *     drawingNo: string;
 *     requiredParts: string;
 *     customerNeeds: string;
 *     projectNo?: number;
 *     crmSourceId: string;
 *     crmCustomerId?: any;
 *     caseNo: string;
 *     plantKey?: any;
 *     componentPath?: any;
 *     imo?: any;
 * }} Case
 *   Based on API response
 */
