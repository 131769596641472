const fileTypeIcons = {
    fileTypes: [
        {
            icon: 'question-circle',
            ext: [],
        },
        {
            icon: 'file-word',
            ext: ['docx'],
        },
        {
            icon: 'file-excel',
            ext: ['xlsx', 'csv'],
        },
        {
            icon: 'file-powerpoint',
            ext: ['pptx'],
        },
        {
            icon: 'file-pdf',
            ext: ['pdf'],
        },
        {
            icon: 'file-image',
            ext: ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff'],
        },
        {
            icon: 'file-code',
            ext: ['xml', 'json'],
        },
        {
            icon: 'file-archive',
            ext: ['zip', 'rar', '7z'],
        },
        {
            icon: 'link',
            ext: ['link'],
        },
        {
            icon: 'envelope',
            ext: ['msg'],
        },
        
    ],
};
export default fileTypeIcons;
