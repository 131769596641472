import React from 'react';
import { isIPad13, isMobileOnly, isTablet } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import Authenticate from './components/Login/Authenticate';
import OidcSettings from './oidcsettings';
import './polyfill.js';
import { subscribeSilentReauthSuccess, updateSessionAccessToken } from './utility/oidchelper';

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { clearCurrentUser, setCurrentUser, setLocal, setMobile, setTablet, setToS } from './actions';
import './App.css';
import MainGrid from './components/common/Grid/MainGrid';
import Login from './components/Login/Login';
import { withLocation } from './HOC/withLocation';
import './styles/styles.css';
import api from './utility/api';
import { ai } from './utility/telemetry/TelemetryService';
import versionInfo from './version.json';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

export class App extends React.Component {
    constructor(props) {
        super(props);
        this.userLoaded = this.userLoaded.bind(this);
        this.userUnloaded = this.userUnloaded.bind(this);
        this.onSignOut = this.onSignOut.bind(this);
        this.notAuthenticated = this.notAuthenticated.bind(this);
        this.acceptToS = this.acceptToS.bind(this);
        this.fetchToSStatus = this.fetchToSStatus.bind(this);
        this.createAndDownloadFile = this.createAndDownloadFile.bind(this);

        this.state = {
            user: undefined,
            version: {},
            dismissed: false,
            tosStatusFetched: false,
            activeTos: {},
        };
    }

    componentDidMount() {
        this.check();
        this.validateAndCleanLocalStorage().catch((err) => console.log(err));
        const myKey = 'app_componentmount';
        //unSubscribeSilentReauthSuccess(myKey);
        subscribeSilentReauthSuccess(
            myKey,
            // eslint-disable-next-line lodash/prefer-noop
            () => {
                //Fired when user is reauthenticated.. try to reload images that might have been if the user lost access for a period..
                //If this does not work, consider just reloading the entire page..
                //Only gets called when reauthenticated via the api.js silent reauth
            }
        );
        if (process.env.REACT_APP_IS_LOCAL) {
            this.props.setLocal(true);
        } else {
            this.interval = setInterval(() => {
                this.getCurrentUserVersion();
            }, 300000);

            this.getCurrentUserVersion();
        }
    }

    async validateAndCleanLocalStorage() {
        try {
            //clearStaleState() is the "proper" way to clean up oidc entries in local storage, but it does not seem to do a very good job.
            //Consider a more manual approach perhaps by checking old oids entries for a created date.. and deleting the ones older than 40 days.. for instance..
            await this._auth.clearStaleState();
            // if(!validateLocalStorage()){
            //   console.log("Local storage failed validation. Clearing")
            //   clearLocalStorage();
            // }
        } catch (err) {
            console.log('Unable to clean localstorage', err);
        }
    }

    componentDidUpdate(_prevProps) {
        if (!this.props.tosAccepted && this.state.tosStatusFetched && !this.state.activeTos.id) {
            this.fetchActiveToS();
        }
    }

    async fetchActiveToS() {
        try {
            const res = await api.get('api/tos/active');
            const tos = res.data;

            const tosContent = await api.get(`api/tos/${tos.id}`, { responseType: 'arraybuffer' });
            //let tmp = Object.assign({}, this.state.activeTos);
            tos.termsFile = tosContent.data;
            this.setState({ activeTos: tos });
        } catch (err) {
            console.log('Unable to get Active ToS', err);
        }
    }

    async fetchToSStatus() {
        try {
            const res = await api.get('api/tos/status');
            if (res.data) {
                this.props.setToS(true);
            }
            this.setState({ tosStatusFetched: true });
        } catch (err) {
            console.log('Error fetching ToS status', err);
            this.setState({ tosStatusFetched: false });
        }
    }

    async getCurrentUserVersion() {
        try {
            const myHeaders = new Headers();
            myHeaders.append('pragma', 'no-cache');
            myHeaders.append('cache-control', 'no-cache');
            const response = await fetch('/version.json', {
                mode: 'cors',
                headers: myHeaders,
            });
            if (!response.status === 200) {
                throw new Error('HTTP error ' + response.status);
            }
            const json = await response.json();

            this.setState({
                version: json,
            });
        } catch (err) {
            console.error(err);
        }
    }

    userLoaded(user) {
        if (user && !this.props.user) {
            //console.log("new access token?",user.access_token)
            updateSessionAccessToken(user.access_token); //Should not be needed? -> interceptor handling token
            user.isAdmin = user.profile.role === 'admin' || user.profile.role === 'owner';
            user.isOwner = user.profile.role === 'owner';
            this.props.setCurrentUser(user);
            this.fetchToSStatus();
            this.setState({ user });
        }
    }

    userUnloaded() {
        this.props.clearCurrentUser();
    }

    notAuthenticated() {
        //console.log("not authenticated");
        const redirect = sessionStorage.getItem('redirect');
        if (!redirect && !this.state.user) {
            sessionStorage.setItem('redirect', this.props.location.pathname);
            sessionStorage.setItem('redirectSearch', this.props.location.search);
        }

        return <Login />;
    }

    onSignOut() {
        this._auth.signOut();
    }

    check() {
        this.props.setMobile(isMobileOnly);
        this.props.setTablet(isTablet || isIPad13);
    }

    reloadApp() {
        window.location.reload(true);
    }

    static getDerivedStateFromError(_error) {
        return { hasError: true };
    }

    componentDidCatch(error, _errorInfo) {
        console.error(error);
        ai.getAppInsights().trackException({ exception: error, severityLevel: SeverityLevel.Error });
    }

    acceptToS() {
        this.props.setToS(true);
        api.post('api/tos/accept').then((res) => {
            if (res.status === 200) {
                window.location.reload();
            }
        });
    }

    declineToS() {
        window.location = 'https://optimar.no';
    }

    createAndDownloadFile() {
        const blob = new Blob([this.state.activeTos.termsFile]);
        const fileName = 'Commander_Terms_of_Service.pdf';
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    render() {
        const redirect = sessionStorage.getItem('redirect');
        const redirectSearch = sessionStorage.getItem('redirectSearch');

        if (this.state.hasError) {
            return (
                <center>
                    <div>
                        Something went wrong! Click <a href={process.env.REACT_APP_URL}>here</a> to recover.
                    </div>
                </center>
            );
        }

        if (this.state.user && redirect && redirect !== '/') {
            //don't redirect when on root.. more specifically if url contains id_token..
            // will lead to neverending refresh loop because Authenticate might already be in the process of
            // authenticatin in the background. A refresh before it is finished will lead to the process starting all over again.
            sessionStorage.removeItem('redirect');
            sessionStorage.removeItem('redirectSearch');
            console.log('redirect to: ', redirect, redirectSearch);
            return <Redirect push to={`${redirect}${redirectSearch}`} />;
        }
        //return <Redirect push to={`${redirect}${redirectSearch}`} />

        return (
            <>
                <Authenticate
                    ref={(auth) => {
                        this._auth = auth;
                    }}
                    OidcSettings={OidcSettings}
                    userLoaded={this.userLoaded}
                    userUnloaded={this.userUnloaded}
                    renderNotAuthenticated={this.notAuthenticated}
                >
                    {Object.keys(this.state.version).length !== 0 ? (
                        !this.state.dismissed &&
                        (versionInfo || {}).ReleaseNumber !== (this.state.version || {}).ReleaseNumber ? (
                            <div className="update-app-warning">
                                <span onClick={() => this.reloadApp()}>
                                    Stay up to date - A new version of COMMANDER is available. Click <b>here</b> to
                                    reload.
                                </span>
                                <span
                                    className="dismiss"
                                    onClick={() => {
                                        this.setState({ dismissed: true });
                                    }}
                                >
                                    Dismiss
                                </span>
                            </div>
                        ) : null
                    ) : null}
                    <MainGrid
                        isOnPlant={this.props.location.pathname.includes('plant')}
                        isMobile={this.props.mobile}
                        user={this.props.user}
                        signOut={this.onSignOut}
                    />
                </Authenticate>

                <Modal
                    isOpen={this.state.tosStatusFetched && !this.props.tosAccepted}
                    //onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <div className="tos-modal">
                        <h1>Updated Terms of Service</h1>
                        <div className="tos-text">
                            The Terms of Service for Commander have been updated. To continue using Commander you will
                            need to accept the terms listed in the provided document.
                        </div>

                        <button onClick={this.createAndDownloadFile} className="btn btn-blue">
                            Download Terms of Service
                        </button>
                        <div className="tos-accept-deny">
                            <button style={{ marginRight: '10px' }} onClick={this.acceptToS} className="btn btn-blue">
                                Accept the terms
                            </button>
                            <button onClick={this.declineToS} className="btn btn-red">
                                Decline
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    mobile: state.utility.isMobile,
    isLocal: state.utility.isLocal,
    tosAccepted: state.utility.tosAccepted,
});

const mapDispatchToProps = {
    setCurrentUser,
    clearCurrentUser,
    setMobile,
    setTablet,
    setLocal,
    setToS,
};

export default withLocation(connect(mapStateToProps, mapDispatchToProps)(App));
