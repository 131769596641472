import React from 'react';
import FontAwesome from 'react-fontawesome';
import './Spinner.scss';

const SimpleSpinner = ({ size }) => {
    if (!size) {
        size = '2x';
    }
    return <FontAwesome name="spinner" size={size} spin />;
};

export default SimpleSpinner;
