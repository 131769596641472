export const SET_REPORTLIST = 'SET_REPORTLIST';
export const CLEAR_REPORTLIST = 'CLEAR_REPORTLIST';

export const setReportList = (reports) => {
    return {
        type: SET_REPORTLIST,
        data: reports,
    };
};

export const clearReportList = (reports) => {
    return {
        type: CLEAR_REPORTLIST,
        data: reports,
    };
};
