import React from 'react';

import { validate_jobtype, meta_editor, get_parent_metadata } from './utils/MetaUtils';
import { ReactComponent as DeleteIcon } from '../../../images/new/optimar_cross.svg';

import AggregationDatapoint from './AggregationDatapoint';

const datapoint_type_name = 'Children Table';
const new_datapoint = {
    datapointId: 'childrenTableDatapoint',
    type: 'childrentable',
    childrenType: 'Children Type',
    metadata: {},
    data: [],
};

export default () => {
    return {
        name: datapoint_type_name,
        new_datapoint: new_datapoint,
        get_editor: get_editor,
        get_details: get_details,
    };
};

function get_editor(data, onInputChange, props) {
    if (!data.data) onInputChange('data', []);

    if (!data.metadata) onInputChange('metadata', {});

    const available_metas = validate_jobtype(data.childrenType, props.child_jobs, onInputChange, 'childrenType');
    const parent_metas = get_parent_metadata(data.childrenType, props.job_type, props.child_jobs);

    const delete_datapoint = (index) => {
        data.data.splice(index, 1);
        onInputChange('data', data.data);
    };
    const add_new_datapoint = () => {
        data.data.push({ datapointId: 'datapointId', type: 'aggregation' });
        onInputChange('data', data.data);
    };
    const on_datapoint_change = (index, name, value) => {
        data.data[index][name] = value;
        onInputChange('data', data.data);
    };

    const delete_metadata = (name) => {
        delete data.metadata[name];
        onInputChange('metadata', data.metadata);
    };
    const add_new_metadata = (index) => {
        data.metadata['metaId_' + index] = '';
        onInputChange('metadata', data.metadata);
    };
    const on_metadata_change = (id, name, value) => {
        switch (name) {
            case 'name': {
                if (id === value) return;
                const new_metadata = {};
                Object.keys(data.metadata).map((element) => {
                    if (element === id) new_metadata[value] = data.metadata[element];
                    else new_metadata[element] = data.metadata[element];
                });
                data.metadata = new_metadata;
                break;
            }

            case 'meta':
                data.metadata[id] = value;
                break;

            default:
                break;
        }

        onInputChange('metadata', data.metadata);
    };

    const available_datapoints = data.data.map((element) => element.datapointId);

    return (
        <>
            <div className="form-group">
                <label htmlFor="childrenType">Child Type</label>
                <select
                    required
                    name="childrenType"
                    className={'input-control'}
                    onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                    value={data.childrenType}
                >
                    {props.child_jobs.map((element, index) => {
                        return (
                            <option key={'j' + (index + 1)} value={element.name}>
                                {element.name}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="child-data">
                <div className="fields">
                    <div className="fields-column">
                        <h2>Datapoints</h2>
                        {data.data.map((element, index) => {
                            return (
                                <fieldset className="childdp-fieldset" key={'childdp' + index}>
                                    <legend>Datapoint {index + 1}</legend>
                                    <DeleteIcon
                                        onClick={() => {
                                            delete_datapoint(index);
                                        }}
                                        className="delete-icon"
                                    />
                                    <div className="form-group">
                                        <label htmlFor="datapointId">Datapoint ID</label>
                                        <input
                                            type="text"
                                            name="datapointId"
                                            value={element.datapointId}
                                            className="input-control"
                                            onChange={(event) =>
                                                on_datapoint_change(
                                                    index,
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            }
                                        />
                                    </div>
                                    {AggregationDatapoint().get_editor(
                                        element,
                                        (name, value) => {
                                            on_datapoint_change(index, name, value);
                                        },
                                        props,
                                        { show_timeframe: false }
                                    )}
                                </fieldset>
                            );
                        })}
                        <button className="btn btn-blue" onClick={add_new_datapoint}>
                            Add datapoint
                        </button>
                    </div>
                    <div className="fields-column">
                        <h2>Metadata</h2>
                        {Object.keys(data.metadata).map((element, index) => {
                            return (
                                <fieldset className="childmd-fieldset" key={'childmd' + index}>
                                    <legend>Meta {index + 1}</legend>
                                    <DeleteIcon
                                        onClick={() => {
                                            delete_metadata(element);
                                        }}
                                        className="delete-icon"
                                    />
                                    <div className="form-group">
                                        <label htmlFor="name">Metadata ID</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={element}
                                            className="input-control"
                                            onChange={(event) =>
                                                on_metadata_change(
                                                    element,
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            }
                                        />
                                    </div>
                                    {meta_editor(
                                        (name, value) => {
                                            on_metadata_change(element, name, value);
                                        },
                                        data.metadata[element],
                                        available_metas,
                                        parent_metas,
                                        available_datapoints
                                    )}
                                </fieldset>
                            );
                        })}
                        <button
                            className="btn btn-blue"
                            onClick={() => add_new_metadata(Object.keys(data.metadata).length)}
                        >
                            Add metadata
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

function get_details(data) {
    return (
        <ul className="datapoint_details">
            <li>Children: {data.childrenType}</li>
        </ul>
    );
}
