//Show relevant info on widget

import React, { useRef } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as InfoIcon } from '../../images/optimar_question_16.svg';
import { newGuid } from '../../utility/guidFunctions';
import { defaultFormula } from '../../utility/widgetformulamanager';
import './WidgetInfo.scss';

//user is retrieved from redux state
function WidgetInfo({
    user,
    widget,
    tooltipId,
    placement: place = 'right',
    tooltipEffect: effect = 'solid',
    tooltipDelayHide: delayHide = 250,
    tooltipDelayShow: delayShow = 250,
}) {
    const guid = useRef(newGuid());
    const tooltipIdInternal = tooltipId ?? guid.current;

    if (!widget) {
        return null;
    }
    const renderComponentSetting = (cs) => {
        return (
            <div className="componentsetting" key={cs.topic}>
                <div className="tdv">
                    <div>{cs.topic}</div>
                    <div>{cs.dataValue}</div>
                </div>
                {cs.dataValue.startsWith('historic/') ? <div>{cs.aggMethod}</div> : null}
                <div className="varname">Variable: {cs.varName || 'N/A'}</div>
            </div>
        );
    };
    const renderBucket = (cs) => {
        return (
            <div className="bucket" key={cs.name}>
                <div>
                    {cs.name}: {cs.rangeMin} - {cs.rangeMax}
                </div>
            </div>
        );
    };
    if (!user.isAdmin) {
        //for now.. do not display info possibilities for normal users.
        return null;
    }
    //console.log("WidgetInfo",widget,user);
    return (
        <div className={'widget-config-info-tooltip'} data-tip data-for={tooltipIdInternal}>
            <InfoIcon className="infoicon" title={''}></InfoIcon>
            <ReactTooltip
                className="widgetInfoTooltip"
                id={tooltipIdInternal}
                title="Widget Configuration"
                place={place}
                effect={effect}
                delayHide={delayHide}
                delayShow={delayShow}
            >
                <div className="widgetInfoOuter">
                    {widget.componentSettings?.length > 0 ? (
                        <div className="componentsettings">
                            <h3>Components</h3>
                            {widget.componentSettings.map((cs) => renderComponentSetting(cs))}
                        </div>
                    ) : null}
                    {widget.distributionBuckets?.length > 0 ? (
                        <div className="distributionbuckets">
                            <h3>Buckets</h3>
                            {widget.distributionBuckets
                                .sort((a, b) => a.rangeMin - b.rangeMin)
                                .map((cs) => renderBucket(cs))}
                        </div>
                    ) : null}
                    {widget.configuration?.formula?.length > 0 && widget.configuration.formula !== defaultFormula ? (
                        <div className="formula">
                            <h3>Formula</h3>
                            <div>{widget.configuration.formula}</div>
                        </div>
                    ) : null}
                </div>
            </ReactTooltip>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetInfo);
//export default ;
