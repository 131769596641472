import React from 'react';
import PropTypes from 'prop-types';
import '../widget.css';
import './ValueCell.scss';
import { forEach } from 'lodash';
import AddKpiData from '../../common/AddKpiData/AddKpiData';
import Modal from 'react-modal';
import api from '../../../utility/api';
import { getTimeSince } from '../../../utility/timesince';
import { modalStyle } from '../../../utility/modalstyles';
import moment from 'moment';
import { getValueWithPrecisionOrDefault } from '../../../utility/numberFunctions';
import WidgetInfo from '../WidgetInfo';
class ValueCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputModalIsOpen: false,
        };
        this.overlayRef = null;
        this.mounted = true;
        this.getChartInfo = this.getChartInfo.bind(this);
        this.closeInputModal = this.closeInputModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            !this.props.data &&
            this.props.user &&
            JSON.stringify(prevProps) !== JSON.stringify(this.props) &&
            this.props.shouldFetch
        ) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });
        }
    }

    componentDidMount() {
        // console.log("MOUNT SINGLE", this.props);
        if (
            (this.props.job && this.props.calculation) ||
            (this.props.shouldFetch && this.props.user && !this.props.data)
        ) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });

            this.interval = setInterval(() => {
                this.getChartInfo((res) => {
                    if (this.mounted) {
                        this.setState({
                            res: res,
                        });
                    }
                });
            }, this.props.refreshInterval || 10000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.mounted = false;
    }

    getChartInfo = (callback) => {
        // let daterange = "&from=" + (this.props.endDate ? (moment(this.props.endDate).add(-this.props.period.period, this.props.period.interval).toISOString() + "&to=" + this.props.endDate) : moment().add(-this.props.period.period, this.props.period.interval).toISOString() + "&to=" + moment().utc().toISOString());
        let apiUrl =
            process.env.REACT_APP_API +
            '/api/kpi?machinenumber=' +
            this.props.machineNumber +
            '&valueFormat=single&calculation=last&dataType=' +
            this.props.dataType +
            '&dataValue=' +
            this.props.dataValue;
        if (this.props.job) {
            const daterange =
                '&from=' + this.props.job.startDate.toISOString() + '&to=' + this.props.job.endDate.toISOString();
            apiUrl =
                process.env.REACT_APP_API +
                '/api/kpi/range?calculation=' +
                this.props.calculation +
                '&valueFormat=' +
                (this.props.valueFormat || 'range') +
                daterange +
                '&rangeformat=hour&dataType=' +
                this.props.dataType +
                '&dataValue=' +
                this.props.dataValue;
        }

        forEach(this.props.components, (component) => {
            apiUrl += '&componentId=' + component;
        });

        api.get(apiUrl)
            .then((res) => {
                //console.log("RESSSS", res);
                if (this.props.calculation === 'avg') {
                    const valueObject = {
                        data: {
                            value: res.data.data.reduce((a, b) => a + (b.value || 0), 0) / res.data.data.length,
                            timestamp: res.data?.data?.[0].date,
                        },
                    };
                    return callback(valueObject);
                } else if (this.props.calculation === 'sum') {
                    const valueObject = {
                        data: {
                            value: res.data.data.reduce((a, b) => a + (b.value || 0), 0),
                            timestamp: res.data?.data?.[0].date,
                        },
                    };
                    return callback(valueObject);
                }
                //console.log(res.data)
                return callback(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    openInputModal(e) {
        e.stopPropagation();

        this.setState({
            inputModalIsOpen: true,
        });
    }

    closeInputModal() {
        this.setState({
            inputModalIsOpen: false,
        });
    }

    ifValidDate(res) {
        const today = new Date();
        if (today >= moment(this.props.job?.startDate) || today <= moment(this.props.job?.endDate)) {
            // today is in current job range
            //console.log("timestamp", res.data.timestamp, moment(this.props.job?.startDate), moment(this.props.job?.endDate))
            return this.dataIsBetweenJobDates(res);
        }
    }
    dataIsBetweenJobDates(res) {
        if (
            res.data?.timestamp &&
            moment(res.data.timestamp).isBetween(
                moment(this.props.job?.startDate),
                moment(this.props.job?.endDate),
                null,
                '[]'
            )
        ) {
            //timestamp on data is in current job range
            return true;
        }
        return false;
    }

    render() {
        function kFormatter(num, sep, precision) {
            return num > 999 ? (num / sep).toFixed(precision) : num !== null ? num.toFixed(precision) : 0;
        }
        const config = this.props.configuration;
        const res = this.state.res || this.props.data || { data: {} };
        //console.log("Single cell props", this.props, res);

        let value = res.data?.value;
        value = this.props.dataValueFilter && this.props.dataValueFilter === 'dead' ? value * -1 : value;

        const unit = config ? (!config.usePayloadUnit ? config.unit || '' : res.data?.payloadUnit || '') : '';
        const separator = config ? config.separator || '' : '';

        const precision = config ? config.numericPrecision || 0 : 0;

        const threshold = res.data?.threshold || {};
        let statusClass = 'normal';

        if (res.data?.value >= threshold.alertHigh || res.data?.value <= threshold.alertLow) {
            statusClass = 'alert';
        } else if (res.data?.value >= threshold.warningHigh || res.data?.value <= threshold.warningLow) {
            statusClass = 'warn';
        }
        let endValue = value;
        //split out to make more readable..
        if (this.props.job) {
            //if on job dashboard..
            //endValue = this.ifValidDate(res) ? (value && separator !== "" ? kFormatter(value, separator, precision) : getValueWithPrecisionOrDefault(value,precision, '0.0')) + " " + unit : "N/A";
            endValue = this.dataIsBetweenJobDates(res)
                ? (value && separator !== ''
                      ? kFormatter(value, separator, precision)
                      : getValueWithPrecisionOrDefault(value, precision, '0.0')) +
                  ' ' +
                  unit
                : 'N/A';
            //console.log("job singleval",res.data)
        } else {
            endValue =
                (value && separator !== ''
                    ? kFormatter(value, separator, precision)
                    : getValueWithPrecisionOrDefault(value, precision, '0.0')) +
                ' ' +
                unit; // && value.toFixed? value.toFixed(precision) : 0)) + " " + unit;
        }

        return (
            <>
                <div className="widget-config-info">
                    <WidgetInfo widget={this.props.widget} />
                </div>
                <div className={'value-cell-wrapper'}>
                    <span className="time-since">
                        {this.props.job ? (this.ifValidDate(res) ? getTimeSince(res) : '') : getTimeSince(res)}
                    </span>
                    <div className="data-wrapper">
                        <div className="data-title">
                            <span className="data-title__label">{this.props.title}</span>
                            {this.props.manualInput ? (
                                <span className="manual-add" onClick={(e) => this.openInputModal(e)}>
                                    ...
                                </span>
                            ) : null}
                        </div>
                        <div className="data">
                            <div className="data-value">
                                <span className="data-value__number">
                                    <span className={'single-value ' + statusClass}>{endValue}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.manualInput ? (
                    <Modal
                        isOpen={this.state.inputModalIsOpen}
                        onRequestClose={this.closeInputModal}
                        style={modalStyle}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <AddKpiData {...this.props} config={this.config} closeModal={this.closeInputModal} />
                    </Modal>
                ) : null}
            </>
        );
    }
}

ValueCell.propTypes = {
    itemId: PropTypes.string,
};

export default ValueCell;
