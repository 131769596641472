import React from 'react';
import AdminToSAddEdit from './TosAddEdit';
import { connect } from 'react-redux';
import GenericTable from '../../components/common/GenericTable';
import 'react-table-6/react-table.css';
import Modal from 'react-modal';
import moment from 'moment';
import api from '../../utility/api';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '12vw',
        width: '600px',
    },
};
class AdminToS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tos: [],
            visibleColumns: [
                { Header: 'Name', accessor: 'filename', filterable: true },
                {
                    Header: 'Modified',
                    accessor: 'modified',
                    Cell: (props) => <span>{moment.utc(props.value).local().format('DD.MM.YYYY HH:mm')}</span>,
                    filterable: true,
                },
                {
                    Header: 'Download',
                    accessor: 'id',
                    width: 100,
                    filterable: true,
                    Cell: (row) => (
                        <div className="link" style={{ cursor: 'pointer' }} onClick={() => this.getFile(row.value)}>
                            Download
                        </div>
                    ),
                },
            ],
            modalIsOpen: false,
        };

        this.fetchToS = this.fetchToS.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    getFile(id) {
        api.get(`api/tos/${id}`, { responseType: 'arraybuffer' }).then((res) => {
            this.createAndDownloadFile(res.data, 'Commander ToS.pdf');
        });
    }

    createAndDownloadFile(body, fileName) {
        const blob = new Blob([body]);
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    componentDidMount() {
        this.fetchToS();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.tos) {
            this.fetchToS();
        }
    }

    fetchToS() {
        if (this.props.user != null) {
            api.get(process.env.REACT_APP_API + '/api/tos')
                .then((res) => {
                    if (this.mounted) {
                        this.setState({
                            tos: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                editType: mode,
            },
            function () {
                this.fetchToS();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchToS();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]?.toLowerCase()).includes(filter.value?.toLowerCase()) : true;
    }

    render() {
        const tos = this.state.tos;
        const visibleColumns = this.state.visibleColumns;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{tos ? tos.length : 0} ToS'</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New ToS</span>{' '}
                        </span>
                    </span>
                </div>
                <GenericTable enableGlobalFilter={true} data={tos} columns={visibleColumns} localPaging={true} />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminToSAddEdit
                        editTag={this.state.editItem}
                        editedCallback={this.closeModal}
                        cancelCallback={this.closeModal}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminToS);
