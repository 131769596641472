import React from 'react';

const CustomNoDataComponent = (props) => {
    if (props.loading) {
        return null;
    }
    return <div className="rt-noData">No rows found</div>;
};

export default CustomNoDataComponent;
