import React from 'react';
import './AdminAccounts.css';
import { connect } from 'react-redux';
import AdminAccountAddEdit from '../../components/common/AdminAccountAddEdit/AdminAccountAddEdit';
import { setCurrentAdminAccount, clearCurrentAdminAccount } from '../../actions';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as DeleteIcon } from '../../images/list/optimar_cross.svg';
import { ReactComponent as EditIcon } from '../../images/list/optimar_edit.svg';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../utility/api';
import { noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '12vw',
        width: '600px',
    },
};

class AdminAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddAccountSection: false,
            showEditAccountSection: false,
            showDeleteAccountSection: false,
            accountToEdit: null,
            redirect: false,
            accounts: [],
            showInactive: false,
        };
        this.closeModal = this.closeModal.bind(this);
        this.fetchAccounts = this.fetchAccounts.bind(this);
        this.accountEdited = this.accountEdited.bind(this);
        this.singleAccount = this.singleAccount.bind(this);
        this.showInactiveAccounts = this.showInactiveAccounts.bind(this);

        const rowContext = [
            {
                content: <span>Edit account</span>,
                action: (row) => this.setState({ redirect: '/admin/account/' + row.original.id + '/general' }),
            },
            {
                content: <span>Configure dashboard</span>,
                action: (row) => this.setState({ redirect: '/admin/account/' + row.original.id + '/dashboard' }),
            },
            {
                content: <span>Delete account</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];

        this.visibleColumns = [
            { Header: 'Account', accessor: 'name', filterable: true, width: 300 },
            {
                Header: () => null,
                id: 'rowcontext',
                Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
            },
        ];

        this.mounted = true;
    }

    openModal(_item, _mode) {
        this.setState({
            modalIsOpen: true,
        });
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
            },
            function () {
                this.fetchAccounts();
            }
        );
    }

    changeView(view) {
        if (view === 'add') {
            this.props.clearCurrentAdminAccount();
        }
        this.setState({
            view: view,
        });
    }

    componentDidMount() {
        if (this.props.user) {
            this.fetchAccounts();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (this.props.user && !this.props.plants && !this.state.accounts) {
            this.fetchAccounts();
        }
    }

    singleAccount = (itemFromChild) => {
        this.setState({
            accountToEdit: itemFromChild,
        });
        this.changeView('single');
    };

    fetchAccounts() {
        if (this.props.user != null) {
            api.get(process.env.REACT_APP_API + '/api/accounts/admin')
                .then((res) => {
                    const data = res.data.map((account) => {
                        const accountButtons = [];
                        if (account.accountState !== 0) {
                            accountButtons.push(this.getEditLink(account));
                            accountButtons.push(this.getDeleteLink(account));
                        } else {
                            accountButtons.push(this.getEditLink(account));
                        }
                        account.buttons = accountButtons;
                        account.isDisabled = account.accountState === 0;
                        return account;
                    });
                    data.sort((a, b) => +a.isDisabled - +b.isDisabled || a.name.localeCompare(b.name));
                    if (this.mounted) {
                        this.setState({
                            accounts: data,
                        });
                    }
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }
    getEditLink(item) {
        return (
            <img
                key={'editButton' + item.id}
                className={'list-btn'}
                alt="Edit"
                title="Edit"
                src={EditIcon}
                onClick={() => this.editItem(item)}
            />
        );
    }

    getDeleteLink(item) {
        return (
            <img
                key={'deleteButton' + item.id}
                className={'list-btn'}
                alt="Delete"
                title="Delete"
                src={DeleteIcon}
                onClick={() => this.deleteItem(item)}
            />
        );
    }

    editItem = (account) => {
        this.props.setCurrentAdminAccount(account);
        this.redirect('/admin/account/edit');
    };

    accountEdited() {
        this.fetchAccounts();
        this.changeView('');
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete account</h1>
                        <p>
                            You are about to delete the account
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteItem(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteItem = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/accounts/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Account deleted', 'Account successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete account', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchAccounts();
                })
                .catch(noop);
        }
    };

    // eslint-disable-next-line lodash/prefer-noop
    duplicateAccount = (_itemFromChild) => {};

    addedAccount = (_itemFromChild) => {
        this.fetchAccounts();
        this.changeView('');
    };

    redirect(path) {
        this.setState({
            redirect: true,
            redirectPath: path,
        });
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
    }

    showInactiveAccounts() {
        this.setState({
            showInactive: !this.state.showInactive,
        });
    }

    render() {
        const accounts = this.state.accounts;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        if (this.state.view === 'add') {
            return (
                <div className="container">
                    <div className="adminuser-header">
                        <div className="table-header">Add Account</div>
                        <div className="add-row" onClick={() => this.changeView('')}>
                            Accounts List
                        </div>
                    </div>
                    <AdminAccountAddEdit currentAccounts={accounts} addedCallback={this.addedAccount} />
                </div>
            );
        } else if (this.state.view === 'edit') {
            return (
                <div className="container">
                    <AdminAccountAddEdit editedCallback={this.accountEdited} />
                </div>
            );
        } else {
            return (
                <>
                    <div className="main-header">
                        <div className="main-header-title">
                            <h2>
                                {accounts ? accounts.filter((a) => a.isDisabled === this.state.showInactive).length : 0}{' '}
                                Accounts
                            </h2>
                            {/*eslint-disable-next-line*/}
                            <a className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                                + New Account
                            </a>
                            <div className="table-tools" style={{ justifyContent: 'space-between' }}>
                                <div>
                                    <div
                                        style={{ marginLeft: '10px' }}
                                        onClick={this.showInactiveAccounts}
                                        className="faux-checkbox"
                                    >
                                        <div
                                            className={this.state.showInactive ? 'checkbox checked' : 'checkbox'}
                                        ></div>
                                        <span>Show inactive accounts</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GenericTable
                        data={this.state.accounts.filter((a) => a.isDisabled === this.state.showInactive)}
                        columns={this.visibleColumns}
                        localPaging={true}
                        enableGlobalFilter={true}
                    />
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <AdminAccountAddEdit
                            editedCallback={this.closeModal}
                            addedCallback={this.closeModal}
                            cancelCallback={this.closeModal}
                        />
                    </Modal>
                </>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    accountToEdit: state.plants.currentAdminAccount,
});

const mapDispatchToProps = {
    setCurrentAdminAccount,
    clearCurrentAdminAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAccounts);
