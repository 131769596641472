import React from 'react';
import PropTypes from 'prop-types';
import './DepthDistributionChart.scss';
import '../SizeDistributionChart/SizeDistributionChart.scss';

import { getValueWithPrecisionOrDefault } from '../../../../utility/numberFunctions';

class DepthDistributionChart extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.contentDiv = <div></div>;

        this.state = {
            displayBar: null,
            display: false,
            error: false,
            fishRegisteredThreshold: -40,
        };
    }

    componentDidUpdate(prevProps) {
        // console.log("DATA", this.props, prevProps);
        if (
            this.props.data &&
            this.props.data.data &&
            JSON.stringify(this.props.data.data) !== JSON.stringify(prevProps.data?.data)
        ) {
            this.contentDiv = this.createContent(this.props.data.data.value);
            this.setState({ display: true });
        }
    }

    mouseEnter = (i) => {
        this.setState({ displayBar: i });
    };

    mouseLeave = () => {
        this.setState({ displayBar: null });
    };

    render() {
        let res = [];
        if (this.props.data && this.props.data.data) {
            res = this.props.data.data.value ? this.props.data.data.value : [];
            this.contentDiv = this.createContent(res);
        }

        let infoDiv;

        if (this.props.fishRegisteredThreshold) {
            this.setState({ fishRegisteredThreshold: this.props.fishRegisteredThreshold });
        }

        if (res.length === 0 && !(this.props.data && this.props.data.error)) {
            infoDiv = <span className="time-since">Fetching data</span>;
        } else {
            infoDiv = undefined;
        }

        if (this.props.data && this.props.data.error) {
            infoDiv = <span className="time-since">No data</span>;
        }

        return (
            <div
                ref={this.myRef}
                id="chart"
                className={
                    'depth-distribution-chart ' +
                    (this.props.configuration?.mode === 'horizontal' ? 'size-distribution-chart' : '')
                }
            >
                {infoDiv}
                <div className="widget-header">
                    <span className="widget-title">{this.props.title ? this.props.title : 'Distribution'}</span>
                </div>
                {this.contentDiv}
            </div>
        );
    }

    createContent(res) {
        if (res && res.length > 0) {
            const decodedData = this.decodeData(res);
            const maxCount = Math.max.apply(Math, decodedData);
            const maxVal = this.props.configuration.maxValue || Math.max(...decodedData);
            const mode = this.props.configuration?.mode;
            return mode === 'angel' || mode === 'vertical' ? (
                <div className={'depth-distribution-chart-bars'}>
                    <div className="center-line"></div>
                    {decodedData
                        ? decodedData.map((d, i) => {
                              return (
                                  <div
                                      className={'depth-distribution-chart-bar ' + (mode === 'vertical' ? 'left' : '')}
                                      key={'ddc' + i}
                                      onMouseOver={() => this.mouseEnter(i)}
                                      onMouseLeave={() => this.mouseLeave()}
                                  >
                                      {i % 5 === 0 ? (
                                          <div
                                              className={'x-label ' + (mode === 'angel' ? 'guideline' : '')}
                                              style={{ width: this.calculateTraceLineValue(d, maxCount) + 'px' }}
                                          >
                                              <span>{i}</span>
                                          </div>
                                      ) : null}
                                      <div
                                          key={i + this.calculateBarValue(d, maxCount)}
                                          className={
                                              this.state.displayBar === i
                                                  ? 'depth-distribution-chart-progress highlighted'
                                                  : ' depth-distribution-chart-progress'
                                          }
                                          style={
                                              mode === 'angel'
                                                  ? {
                                                        width:
                                                            (Math.min(Math.max((d / maxVal) * 100, 0), 100) || 0) + '%',
                                                        backgroundColor: this.setBarColor(d),
                                                    }
                                                  : {
                                                        width:
                                                            (Math.min(Math.max((d / maxVal) * 100, 0), 100) || 0) + '%',
                                                        backgroundColor: this.setBarColor(d),
                                                    }
                                          }
                                      ></div>
                                      <div
                                          className={
                                              this.state.displayBar === i && d > this.state.fishRegisteredThreshold
                                                  ? 'depth-distribution-hover'
                                                  : 'depth-distribution-hover-hidden'
                                          }
                                      >
                                          <div>
                                              {i + 'm '} {getValueWithPrecisionOrDefault(d, 1, 0)}
                                              {this.props.configuration.unit}
                                          </div>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            ) : (
                <div className="size-distribution-chart-bars">
                    <div className="dist-yaxis">
                        <span className="dist-yaxis-val">{maxVal}</span>
                        <span className="dist-yaxis-val">{maxVal / 2}</span>
                        <span className="dist-yaxis-val">0</span>
                    </div>
                    {decodedData.map((d, i) => {
                        return (
                            <div
                                className="size-distribution-chart-bar"
                                key={'hist' + i}
                                onMouseOver={() => this.mouseEnter(i)}
                                onMouseLeave={() => this.mouseLeave()}
                            >
                                <div
                                    key={'bar' + i}
                                    id={'bar' + i}
                                    className={
                                        this.state.displayBar === i
                                            ? 'size-distribution-chart-progress highlighted'
                                            : ' size-distribution-chart-progress'
                                    }
                                    style={{ height: (Math.min(Math.max((d / maxVal) * 100, 0), 100) || 0) + '%' }}
                                ></div>
                                <div
                                    className={
                                        this.state.displayBar === i
                                            ? 'size-distribution-hover'
                                            : 'size-distribution-hover-hidden'
                                    }
                                >
                                    <div>
                                        {getValueWithPrecisionOrDefault(d, 1, '0.0')}
                                        {this.props.configuration.unit}
                                    </div>
                                </div>
                                {/* {
                                    <div className="x-label">
                                        <span>{i || ''}</span>
                                    </div>
                                } */}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return <div></div>;
        }
    }

    // https://stackoverflow.com/questions/8188548/splitting-a-js-array-into-n-arrays
    chunk(a, n, balanced) {
        if (n < 2) return [a];

        const len = a.length,
            out = [];
        let i = 0,
            size;

        if (len % n === 0) {
            size = Math.floor(len / n);
            while (i < len) {
                out.push(a.slice(i, (i += size)));
            }
        } else if (balanced) {
            while (i < len) {
                size = Math.ceil((len - i) / n--);
                out.push(a.slice(i, (i += size)));
            }
        } else {
            n--;
            size = Math.floor(len / n);
            if (len % size === 0) size--;
            while (i < size * n) {
                out.push(a.slice(i, (i += size)));
            }
            out.push(a.slice(size * n));
        }

        return out;
    }

    decodeData(arr) {
        if (typeof arr === 'string') arr = JSON.parse(arr);
        const calculationArray = this.chunk(arr, 31, true);
        const finalArr = [];

        calculationArray.forEach((meter) => {
            let sum = 0;
            let avg = 0;
            if (meter.length) {
                sum = meter.reduce((a, b) => {
                    return a + b;
                });
                avg = sum / meter.length;
            }
            finalArr.push(avg);
        });

        return finalArr;
    }

    setBarColor(value) {
        const threshold = this.props.threshold;

        if (threshold) {
            if (value > threshold.alertHigh || value < threshold.alertLow) {
                return '#ff0055';
            } else if (value > threshold.warningHigh || value < threshold.warningLow) {
                return '#C9B54E';
            }
        }
        return '#41C9F0';
    }

    calculateTraceLineValue(val, maxCount) {
        if (!this.myRef.current) return;
        const componentWidth = this.myRef.current.offsetWidth / 2;
        const maxLineWidthInPx = componentWidth - 30;
        const barWidthInPct = Math.round(this.calculateBarValue(val, maxCount, this.state.fishRegisteredThreshold));
        const barWidthInPx = (componentWidth / 100) * barWidthInPct - 20;

        return maxLineWidthInPx - barWidthInPx;
    }

    calculateBarValue(current, max) {
        const positiveThreshold = this.state.fishRegisteredThreshold * -1;
        const lifeRange = positiveThreshold + max;

        const currentPercent =
            (current > positiveThreshold ? (current + positiveThreshold) / (lifeRange / 100) : 0) * 0.85; // max 85% of div width
        return currentPercent;
    }
}

DepthDistributionChart.propTypes = {
    header: PropTypes.string,
    threshold: PropTypes.object,
    fishRegisteredThreshold: PropTypes.number,
    data: PropTypes.object,
};

export default DepthDistributionChart;
