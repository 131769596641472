import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Select, { components } from 'react-select';
import api from '../../utility/api';
import { setCurrentPlant, clearCurrentPlant, setPlantList, setMapVars } from '../../actions';
import { Link } from 'react-router-dom';
import '../../components/Maps/GoogleMap.css';
import Sidepanel from '../../../src/components/Sidepanel/Sidepanel';
import MapStyles from '../../components/Maps/styles';
import './maps.scss';
import GoogleMapReact from 'google-map-react';
import Marker from '../../components/Maps/Marker';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import Farm from '../../components/Maps/icons/farm_grey.svg';
import FarmRed from '../../components/Maps/icons/farm_red.svg';
import FarmYellow from '../../components/Maps/icons/farm_yellow.svg';
import m1 from '../../components/Maps/icons/m1.png';
import m2 from '../../components/Maps/icons/m2.png';
import m3 from '../../components/Maps/icons/m3.png';
import m4 from '../../components/Maps/icons/m4.png';
import m5 from '../../components/Maps/icons/m5.png';
import { ReactComponent as AngleDownIcon } from '../../images/list/optimar_angle_down.svg';
import Modal from 'react-modal';
import { constant, noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '20px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '12vw',
        overflow: 'visible',
    },
};

class Map extends React.Component {
    constructor(props) {
        super(props);
        const showNames = JSON.parse(localStorage.getItem('showNamesOnMap') || 'true');
        const showLocalities = JSON.parse(localStorage.getItem('showLocalities') || 'false');
        this.state = {
            isVisible: false,
            satellite: false,
            mapVars: undefined,
            filterString: '',
            redirect: undefined,
            selectedAccounts: undefined,
            inputValue: '',
            showLocalities: showLocalities != null ? showLocalities : false,
            showOnlyLiceLocs: false,
            mapApiLoaded: false,
            modalIsOpen: false,
            selectedFishFarm: undefined,
            mapOptions: {
                styles: MapStyles,
                fullscreenControl: false,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            },
            showNamesOnMap: showNames != null ? showNames : true,
            activeIndex: -1,
        };
        this.apiKey = 'AIzaSyAzDBVa737dr4DTxxqBdkqJw26A83Jh6IA';
        this.center = { lat: 62.46, lng: 6.21 };
        this.zoom = 5;
        this.google = window.google;
        this.markers = [];
        this.clusters = [];
        this.localityMarkers = [];
        this.localitiesClusterer = undefined;
        this.mapRef = undefined;
        this.maps = undefined;
        this.start = 0;
        this.startMap = 0;
        this.centerFilterResult = false;

        this.mcOptions = {
            styles: [
                {
                    height: 53,
                    url: m1,
                    width: 53,
                },
                {
                    height: 53,
                    url: m2,
                    width: 53,
                },
                {
                    height: 53,
                    url: m3,
                    width: 53,
                },
                {
                    height: 53,
                    url: m4,
                    width: 53,
                },
                {
                    height: 53,
                    url: m5,
                    width: 53,
                },
            ],
            maxZoom: 9,
        };

        this.closeSidepanel = this.closeSidepanel.bind(this);
        this.onMapChange = this.onMapChange.bind(this);
        this.search = this.search.bind(this);
        this.filter = this.filterPlants.bind(this);
        this.handleEnterPress = this.handleEnterPress.bind(this);
        this.handleChkChange = this.handleChkChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleToggleNames = this.handleToggleNames.bind(this);
        this.createFishFarmMarkers = this.createFishFarmMarkers.bind(this);
        this.filterPlants = this.filterPlants.bind(this);
        this.setMapPosition = this.setMapPosition.bind(this);
    }

    componentDidMount() {
        const _this = this;

        if (this.props.user) {
            this.fetchPlantData();
        }

        this.interval = setInterval(() => {
            this.fetchPlantData();
        }, 5 * 60 * 1000);

        document.onkeydown = function (e) {
            switch (e.keyCode) {
                case 40:
                case 38: {
                    let nextIndex = _this.state.activeIndex + 1;
                    if (e.keyCode === 38) {
                        nextIndex = _this.state.activeIndex - 1;
                    }

                    const plantPos = {
                        latitude: (_this.state.filteredPlants[nextIndex] || {}).latitude,
                        longitude: (_this.state.filteredPlants[nextIndex] || {}).longitude,
                    };
                    _this.setMapPosition(plantPos);

                    _this.setState({
                        activeIndex: nextIndex,
                    });

                    break;
                }
                default:
                    break;
            }
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
            JSON.stringify(nextState) !== JSON.stringify(this.state) ||
            nextState.filterString !== this.state.filterString
        ) {
            return true;
        } else {
            return false;
        }
    }

    componentDidUpdate() {
        if (this.props.user && !this.props.plants) {
            this.fetchPlantData(true);
        }

        this.filterPlants();

        this.props.clearCurrentPlant();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    openModal(item) {
        this.fetchAccounts();
        this.setState({
            modalIsOpen: true,
            selectedFishFarm: item,
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            selectedFishFarm: undefined,
            selectedAccounts: undefined,
        });
    }

    fetchAccounts() {
        api.get(process.env.REACT_APP_API + '/api/accounts/')
            .then((res) => {
                const allTags = res.data.map(function (d) {
                    return {
                        value: d.id,
                        label: d.name,
                    };
                });

                this.setState({
                    accounts: allTags,
                });
            })

            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    getLocalityIcon(locality) {
        const icon = {
            url: undefined,
            scaledSize: new this.google.maps.Size(15, 15),
            labelOrigin: new this.google.maps.Point(6, -10),
        };

        if (locality.aboveLiceThreshold) {
            icon.url = FarmRed;
        } else if (locality.hasReportedLice) {
            icon.url = FarmYellow;
        } else {
            icon.url = Farm;
        }
        return icon;
    }

    fetchPlantData(first) {
        api.get(`${process.env.REACT_APP_API}/api/plants`)
            .then((res) => {
                const plants = res.data;

                this.fetchFishFarms().then((result) => {
                    const fishFarms = result.data;
                    fishFarms.map((l) => {
                        l.lng = l.lon;
                        l.latitude = l.lat;
                        l.longitude = l.lon;
                        l.plantTypeName = 'Locality';
                        l.icon = this.getLocalityIcon(l);
                        return l;
                    });

                    this.props.setPlantList(plants.concat(fishFarms));

                    if (first) {
                        this.setState({
                            filteredPlants: plants.concat(fishFarms),
                        });
                    }

                    if (this.mapRef) {
                        this.createFishFarmMarkers();
                    }
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    toggleSatellite = () => {
        this.setState({
            mapOptions: {
                mapTypeId: !this.state.satellite ? this.maps.MapTypeId.SATELLITE : this.maps.MapTypeId.ROADMAP,
            },
        });

        this.setState({
            satellite: !this.state.satellite,
        });
    };

    toggleLocalities = () => {
        this.localitiesClusterer.clearMarkers();

        if (!this.state.showLocalities) {
            this.localityMarkers.map((l) => {
                return l.setVisible(true);
            });

            this.localitiesClusterer.addMarkers(this.localityMarkers);
        } else {
            this.localityMarkers.map((l) => {
                return l.setVisible(false);
            });
        }
        this.setState({ showLocalities: !this.state.showLocalities }, () =>
            localStorage.setItem('showLocalities', JSON.stringify(this.state.showLocalities))
        );
    };

    closeSidepanel = () => {
        this.setState({
            isVisible: false,
        });
    };

    onMapChange(mapVars) {
        if (mapVars) {
            localStorage.setItem('mapvars', JSON.stringify(mapVars));
        }
        this.props.setMapVars(mapVars);
    }

    search(e) {
        const filter = e.target.value.trimStart();
        this.setState({
            activeIndex: -1,
            filterString: filter,
        });
    }

    filterPlants() {
        this.centerFilterResult = false;
        if (!this.props.plants) {
            return [];
        }

        const map = this.mapRef;

        let result = [];
        result = Object.assign([], this.props.plants);

        //Remove any markers currently outside of map bounds
        if (map) {
            result = result.filter((x) =>
                map.getBounds().contains({ lat: parseFloat(x.latitude), lng: parseFloat(x.longitude) })
            );
        }

        if (!this.state.showLocalities) {
            result = result.filter((x) => x.plantTypeName !== 'Locality');
        }

        if (!this.state.filterString || this.state.filterString.length < 1) {
            if (this.state.showOnlyLiceLocs) {
                result = result.filter(
                    (x) =>
                        ((x.plantTypeName.toLowerCase() === 'fishfarm' ||
                            x.plantTypeName.toLowerCase() === 'locality') &&
                            x.aboveLiceThreshold) ||
                        (x.plantTypeName.toLowerCase() !== 'fishfarm' && x.plantTypeName.toLowerCase() !== 'locality')
                );
            }

            if (this.state.showLocalities && this.localitiesClusterer) {
                this.localitiesClusterer.clearMarkers();

                if (result.length > 0) {
                    this.localityMarkers.map((l) => {
                        return l.setVisible(false);
                    });
                    const tempResult = result.filter((x) => x.localityNo);

                    tempResult.map((l) => {
                        const marker = this.localityMarkers.find((x) => x.localityNo === l.localityNo);
                        if (marker) marker.setVisible(true);
                        return marker;
                    });
                }
                this.localitiesClusterer.addMarkers(this.localityMarkers.filter((x) => x.getVisible()));
            }

            this.setState({ filteredPlants: result });
        }

        const fString = this.state.filterString;

        if (this.state.showLocalities) {
            // eslint-disable-next-line
            result = this.props.plants.filter((o) => {
                return Object.keys(o).some((k) => {
                    if (typeof o[k] === 'string' || typeof o[k] === 'number')
                        return String(o[k]).toLowerCase().includes(fString.toLowerCase());
                });
            });

            if (result.length > 0) {
                if (this.localitiesClusterer) this.localitiesClusterer.clearMarkers();
                if (this.state.showOnlyLiceLocs) {
                    this.localityMarkers
                        .filter(
                            (x) => !x.searchText.toLowerCase().includes(fString.toLowerCase() || !x.aboveLiceThreshold)
                        )
                        .map((l) => {
                            return l.setVisible(false);
                        });
                } else {
                    this.localityMarkers
                        .filter((x) => !x.searchText.toLowerCase().includes(fString.toLowerCase()))
                        .map((l) => {
                            return l.setVisible(false);
                        });
                }

                if (this.state.showOnlyLiceLocs) {
                    this.localityMarkers
                        .filter(
                            (x) => x.searchText.toLowerCase().includes(fString.toLowerCase()) && x.aboveLiceThreshold
                        )
                        .map((l) => {
                            return l.setVisible(true);
                        });
                } else {
                    this.localityMarkers
                        .filter((x) => x.searchText.toLowerCase().includes(fString.toLowerCase()))
                        .map((l) => {
                            return l.setVisible(true);
                        });
                }

                if (this.localitiesClusterer)
                    this.localitiesClusterer.addMarkers(this.localityMarkers.filter((x) => x.getVisible()));
            } else {
                if (this.localitiesClusterer) this.localitiesClusterer.clearMarkers();
            }
        } else {
            // eslint-disable-next-line
            result = this.props.plants
                .filter((x) => x.plantTypeName !== 'Locality')
                .filter((o) => {
                    return Object.keys(o).some((k) => {
                        if (typeof o[k] === 'string' || typeof o[k] === 'number') {
                            return String(o[k]).toLowerCase().includes(fString.toLowerCase());
                        }
                        return false;
                    });
                });
        }

        if (result.length === 1) {
            this.centerFilterResult = true;
            this.setMapPosition({ latitude: result[0].latitude, longitude: result[0].longitude });
        }

        this.setState({ filteredPlants: result });
    }

    setSearch(fString) {
        this.setState({ filterString: fString }, () => {
            this.refs['map-search-input'].value = fString;
        });
    }

    setMapPosition(plant) {
        this.centerFilterResult = true;
        const mapVars = Object.assign({}, this.props.mapVars);
        mapVars.center = { lat: plant.latitude, lng: plant.longitude };
        this.props.setMapVars(mapVars);
    }

    handleMarkerClick = (id) => {
        const plant = this.props.plants.find((obj) => {
            return obj.id === id;
        });

        if (plant) {
            if (this.props.isMobile) {
                this.setState({ redirect: '/plant/' + plant.id + '/overview' });

                // this.setState({
                //   showMapInfo: this.state.showMapInfo && (this.state.showMapInfo.id === plant.id) ? {} : plant
                // });

                return;
            }

            this.setState({
                selectedPlantId: plant.id,
                isVisible: true,
            });
        }
    };

    handleEnterPress(e) {
        if (e.key === 'Enter') {
            const plant = this.state.filteredPlants[this.state.activeIndex !== -1 ? this.state.activeIndex : 0];
            if (plant && plant.plantTypeName !== 'Locality') {
                this.setState({ redirect: '/plant/' + plant.id + '/overview' });
            }
        }
    }

    handleChkChange(_e) {
        if (!this.state.showOnlyLiceLocs) {
            this.filterPlants();
        } else {
            this.filterPlants();
        }
        this.setState({ showOnlyLiceLocs: !this.state.showOnlyLiceLocs });
    }

    handleToggleNames() {
        this.setState({ showNamesOnMap: !this.state.showNamesOnMap }, () =>
            localStorage.setItem('showNamesOnMap', JSON.stringify(this.state.showNamesOnMap))
        );
    }

    handleLocalityNames() {
        if (this.state.showNamesOnMap && this.state.showLocalities)
            this.localityMarkers
                .filter((x) => this.mapRef.getBounds().contains(x.position))
                .map((m) => {
                    return m.setLabel({
                        color: 'white',
                        text: m.name,
                        fontSize: '11px',
                        fontFamily: 'Roboto, Arial, sans-serif',
                        fontWeight: '400',
                    });
                });
        else
            this.localityMarkers.map((m) => {
                return m.setLabel(null);
            });
    }

    async fetchFishFarms() {
        return await api.get(`${process.env.REACT_APP_API}/api/localities`);
    }

    handleMultiSelectChange = (selectedAccounts) => {
        this.setState({ selectedAccounts });
    };

    handleApiLoaded = (map, maps) => {
        this.mapRef = map;
        this.maps = maps;
        this.setState({ mapApiLoaded: true }, this.createFishFarmMarkers());
    };

    createFishFarmMarkers() {
        const plants = this.props.plants ? this.props.plants : [];
        const map = this.mapRef;
        const context = this;

        this.localityMarkers = plants
            .filter((x) => x.plantTypeName === 'Locality')
            .map((l) => {
                const marker = new this.google.maps.Marker({
                    position: l,
                    icon: l.icon,
                    localityNo: l.localityNo,
                    searchText: l.name + ' ' + l.municipality,
                    hasReportedLice: l.hasReportedLice,
                    visible: false,
                    name: l.name,
                });

                const infowindow = new this.google.maps.InfoWindow({
                    content:
                        '' +
                        '<div>' +
                        '<div class="info-header">' +
                        '<div class="info-name">' +
                        l.name +
                        '</div>' +
                        '</div>' +
                        '<div class="info-table-wrapper">' +
                        '<table class="info-table">' +
                        '<tr><td class="title-cell">Municipality:</td><td>' +
                        l.municipality +
                        '</td></tr>' +
                        '<tr><td class="title-cell">Has reported lice:</td><td>' +
                        (l.hasReportedLice ? 'Yes' : 'No') +
                        '</td></tr>' +
                        '<tr><td class="title-cell">Average adult female lice:</td><td>' +
                        (l.avgAdultFemaleLice ? l.avgAdultFemaleLice : 0) +
                        '</td></tr>' +
                        '<tr><td class="title-cell">Has cleaner fish deployed:</td><td>' +
                        (l.hasCleanerFishDeployed ? 'Yes' : 'No') +
                        '</td></tr>' +
                        '<tr><td class="title-cell">Has mechanical removal:</td><td>' +
                        (l.hasMechanicalRemoval ? 'Yes' : 'No') +
                        '</td></tr>' +
                        '<tr><td class="title-cell">Has substance treatments:</td><td>' +
                        (l.hasSubstanceTreatments ? 'Yes' : 'No') +
                        '</td></tr>' +
                        '<tr><td class="title-cell">Has salmonids:</td><td>' +
                        (l.hasSalmonoids ? 'Yes' : 'No') +
                        '</td></tr>' +
                        '</table>' +
                        '<div style="margin-top:30px">Data delivered by BarentsWatch</div>' +
                        '</div>' +
                        '</div>',
                    disableAutoPan: true,
                });

                if (!this.props.isMobile && !this.props.isTablet) {
                    this.google.maps.event.addListener(marker, 'click', function (_event) {
                        infowindow.close();
                        if (context.props.user.isAdmin) context.openModal(l);
                    });
                    this.google.maps.event.addListener(marker, 'mouseover', function (_event) {
                        infowindow.open(map, marker);
                    });
                    this.google.maps.event.addListener(marker, 'mouseout', function (_event) {
                        infowindow.close();
                    });
                } else {
                    this.google.maps.event.addListener(map, 'mouseUp', function (_event) {
                        if (infowindow) {
                            infowindow.close();
                        }
                    });

                    this.google.maps.event.addListener(marker, 'mousedown', function (_event) {
                        this.start = new Date().getTime();
                    });
                    this.google.maps.event.addListener(marker, 'mouseup', function (_event) {
                        const end = new Date().getTime();
                        const longpress = end - this.start < 250 ? false : true;
                        if (longpress && context.props.user.isAdmin) {
                            context.openModal(l);
                        } else {
                            infowindow.open(map, marker);
                        }
                    });
                }
                return marker;
            });

        if (!this.localitiesClusterer) this.localitiesClusterer = new MarkerClusterer(this.mapRef, [], this.mcOptions);

        this.filterPlants();
    }

    safariSatelliteButton() {
        if (
            navigator.userAgent.search('Safari') >= 0 &&
            navigator.userAgent.search('Chrome') < 0 &&
            navigator.userAgent.search('Windows') < 0
        ) {
            return ' safari-satellite';
        }
        return '';
    }

    handleSubmit(event) {
        event.preventDefault();

        const plant = {
            plantTypeId: 'AA55EB13-30B8-473E-B26F-CB6648075CFB', // fish farm
            name: this.state.selectedFishFarm.name,
            latitude: this.state.selectedFishFarm.lat,
            longitude: this.state.selectedFishFarm.lon,
            accounts: this.state.selectedAccounts ? this.state.selectedAccounts.map((a) => a.value) : null,
            localityNo: this.state.selectedFishFarm.localityNo,
            dashboardConfig: undefined,
            navigationConfig: undefined,
        };

        api.post(process.env.REACT_APP_API + '/api/admin/plants', plant)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Plant created', 'Plant successfully created', 5000);
                } else {
                    NotificationManager.error('Plant not created', 'Error creating plant', 5000);
                    throw new Error(response.status);
                }
            })
            .then(() => {
                this.markers = [];
                this.clusters = [];
                this.localityMarkers = [];
                this.fetchPlantData();
                this.closeModal();
            })
            .catch(() => {
                if (!plant.plantTypeId) {
                    NotificationManager.error('Plant type missing, please select one', 'Error creating plant', 5000);
                } else {
                    NotificationManager.error(
                        'Plant not created, are all fields set correctly?',
                        'Error creating plant',
                        5000
                    );
                }
            });
    }

    safariLabel() {
        if (
            navigator.userAgent.search('Safari') >= 0 &&
            navigator.userAgent.search('Chrome') < 0 &&
            navigator.userAgent.search('Windows') < 0
        ) {
            return ' safari-label';
        }
        return '';
    }

    render() {
        const markers = this.state.filteredPlants;
        const storageMapVars = JSON.parse(localStorage.getItem('mapvars'));
        let storageZoom, storageCenter;
        if (storageMapVars) {
            storageCenter = storageMapVars ? storageMapVars.center : null;
            storageZoom = storageMapVars ? storageMapVars.zoom : null;
        }
        this.handleLocalityNames();
        const { inputValue, menuIsOpen, showNamesOnMap, showMapInfo } = this.state;
        const DropdownIndicator = (props) => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <AngleDownIcon className="angle-down" />
                    </components.DropdownIndicator>
                )
            );
        };

        return (
            <div className="mapContainer">
                {this.state.redirect ? <Redirect to={this.state.redirect} /> : null}

                <input
                    onKeyPress={this.handleEnterPress}
                    autoComplete="off"
                    ref={'map-search-input'}
                    type="search"
                    placeholder="Search"
                    className="search-map"
                    title="Search for name, imo or project no"
                    onChange={this.search}
                />
                <div
                    style={{ display: this.state.filterString.length >= 2 ? 'flex' : 'none' }}
                    className="search-dropdown"
                >
                    <span style={{ paddingLeft: '10px', fontSize: '12px' }}>
                        {markers ? `Showing ${markers.length} results` : ''}
                    </span>
                    {markers
                        ? markers.map((m, i) => {
                              return (
                                  <span
                                      className={
                                          'search-drodown-element ' + (i === this.state.activeIndex ? 'active' : '')
                                      }
                                      key={'search-item-' + i}
                                      onClick={() => this.setSearch(m.name)}
                                  >
                                      {m.name}
                                  </span>
                              );
                          })
                        : null}
                </div>

                {this.state.mapApiLoaded ? (
                    <>
                        <button
                            className={'satellite-button' + this.safariSatelliteButton()}
                            onClick={this.toggleSatellite}
                        >
                            {this.state.satellite ? 'Regular' : 'Satellite'}
                        </button>

                        <div onClick={this.toggleLocalities} className="localitiesCheck faux-checkbox">
                            <div className={this.state.showLocalities ? 'checkbox checked' : 'checkbox'}></div>
                            <span>Show all fishfarms</span>
                        </div>

                        {/* <div onClick={this.handleChkChange} className="liceCheck faux-checkbox">
                <div className={this.state.showOnlyLiceLocs ? 'checkbox checked' : 'checkbox'}></div>
                <span>Only farms above lice threshold</span>
              </div> */}

                        <div onClick={this.handleToggleNames} className="toggleNamesCheck faux-checkbox">
                            <div className={this.state.showNamesOnMap ? 'checkbox checked' : 'checkbox'}></div>
                            <span>Show names</span>
                        </div>
                    </>
                ) : (
                    ''
                )}
                {this.props.isMobile && !this.props.isTablet ? (
                    <Link className="plantlist-toggle" to="/">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="21"
                            viewBox="0 0 25 21"
                            fill="#41cdf5"
                        >
                            <title>list</title>
                            <path d="M3.161,1.721H24.488a.5.5,0,1,0,0-1H3.161a.5.5,0,1,0,0,1Z" />
                            <circle cx="1.05" cy="1.05" r="1.05" />
                            <path d="M24.488,6.936H3.161a.5.5,0,1,0,0,1H24.488a.5.5,0,1,0,0-1Z" />
                            <circle cx="1.05" cy="7.35" r="1.05" />
                            <path d="M24.488,19.45H3.161a.5.5,0,1,0,0,1H24.488a.5.5,0,1,0,0-1Z" />
                            <circle cx="1.05" cy="19.95" r="1.05" />
                            <path d="M24.488,13.236H3.161a.5.5,0,1,0,0,1H24.488a.5.5,0,1,0,0-1Z" />
                            <circle cx="1.05" cy="13.65" r="1.05" />
                        </svg>
                    </Link>
                ) : null}

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <form className="admin-plant-add-edit-form" onSubmit={this.handleSubmit}>
                        <div>
                            <div className="info-header">
                                <div className="info-name">
                                    Add fish farm {this.state.selectedFishFarm ? this.state.selectedFishFarm.name : ''}{' '}
                                    to Commander
                                </div>
                            </div>
                            <div>
                                <label>Choose accounts</label>
                                <Select
                                    styles={customStyles}
                                    isMulti={true}
                                    value={this.state.selectedAccounts}
                                    isClearable
                                    isSearchable
                                    inputValue={inputValue}
                                    onInputChange={null}
                                    onChange={this.handleMultiSelectChange}
                                    className="Select-menu-outer"
                                    options={this.state.accounts}
                                    menuIsOpen={menuIsOpen}
                                    components={{ DropdownIndicator, IndicatorSeparator: constant(null) }}
                                    classNamePrefix={'optimar'}
                                />
                            </div>

                            <div className="react-confirm-alert-body">
                                <div className="buttons">
                                    <button
                                        className="btn btn-yellow"
                                        onClick={(_e) => {
                                            this.closeModal();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button className="btn btn-blue no-right-margin" onClick={noop}>
                                        Register fish farm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal>

                <Sidepanel
                    close={this.closeSidepanel}
                    user={this.props.user}
                    selectedPlantId={this.state.selectedPlantId}
                    isVisible={this.state.isVisible}
                />

                <div className="content-grid-map">
                    <div className="map-grid">
                        {/* <button className={"satellite-button" + this.safariSatelliteButton()} onClick={this.toggleSatellite}>{this.state.satellite ? "Regular" : "Satellite"}</button> */}
                        {this.props.isMobile && !this.props.isTablet ? (
                            <Link className="plantlist-toggle" to="/">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="21"
                                    viewBox="0 0 25 21"
                                    fill="#41cdf5"
                                >
                                    <title>list</title>
                                    <path d="M3.161,1.721H24.488a.5.5,0,1,0,0-1H3.161a.5.5,0,1,0,0,1Z" />
                                    <circle cx="1.05" cy="1.05" r="1.05" />
                                    <path d="M24.488,6.936H3.161a.5.5,0,1,0,0,1H24.488a.5.5,0,1,0,0-1Z" />
                                    <circle cx="1.05" cy="7.35" r="1.05" />
                                    <path d="M24.488,19.45H3.161a.5.5,0,1,0,0,1H24.488a.5.5,0,1,0,0-1Z" />
                                    <circle cx="1.05" cy="19.95" r="1.05" />
                                    <path d="M24.488,13.236H3.161a.5.5,0,1,0,0,1H24.488a.5.5,0,1,0,0-1Z" />
                                    <circle cx="1.05" cy="13.65" r="1.05" />
                                </svg>
                            </Link>
                        ) : null}

                        <GoogleMapReact
                            defaultCenter={this.center}
                            defaultZoom={this.zoom}
                            zoom={storageZoom || (this.props.mapVars ? this.props.mapVars.zoom : this.zoom)}
                            center={
                                this.centerFilterResult
                                    ? this.props.mapVars.center
                                    : storageCenter || (this.props.mapVars ? this.props.mapVars : this.center)
                            }
                            bootstrapURLKeys={{ key: this.apiKey }}
                            options={this.state.mapOptions}
                            onChange={this.onMapChange}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                        >
                            {markers
                                ? markers
                                      .filter((x) => x.plantTypeName !== 'Locality')
                                      .map((marker, index) => {
                                          if (marker.latitude && marker.longitude) {
                                              return (
                                                  <Marker
                                                      key={index}
                                                      lat={marker.latitude}
                                                      lng={marker.longitude}
                                                      heading={marker.heading}
                                                      speed={marker.speed}
                                                      showMapInfo={showMapInfo}
                                                      showName={showNamesOnMap}
                                                      info={{
                                                          id: marker.id,
                                                          name: marker.name,
                                                          IMO: marker.imo,
                                                          MMSI: marker.mmsi,
                                                          type: marker.plantTypeName,
                                                          lastConnectedTime: marker.lastSampleDate,
                                                          lastPositionDate: marker.loggedDate,
                                                          latitude: marker.latitude,
                                                          longitude: marker.longitude,
                                                          plantTypeName: marker.plantTypeName,
                                                          address: marker.address,
                                                          aboveLiceThreshold: marker.aboveLiceThreshold,
                                                          hasReportedLice: marker.hasReportedLice,
                                                      }}
                                                      type={marker.type}
                                                      handleMarkerClick={this.handleMarkerClick}
                                                  />
                                              );
                                          } else {
                                              return '';
                                          }
                                      })
                                : ''}
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plant,
    user: state.user.currentUser,
    plants: state.plants.plantList,
    mapVars: state.map.map,
    isMobile: state.utility.isMobile,
    isTablet: state.utility.isTablet,
});

const mapDispatchToProps = {
    setCurrentPlant,
    clearCurrentPlant,
    setPlantList,
    setMapVars,
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
