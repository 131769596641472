import React from 'react';
import { connect } from 'react-redux';
import './TreeNav.css';
import FontAwesome from 'react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars-2';
import GeneralSpinner from '../Spinner/GeneralSpinner';
import api from '../../../utility/api';
import { Tree } from 'antd';
import { now } from 'moment';
import { confirmAlert } from 'react-confirm-alert';

const { TreeNode } = Tree;

class TreeNavStructureCopy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            treeStructure: [],
            plants: [],
        };

        this.fetchPlants = this.fetchPlants.bind(this);
        this.handlePlantChange = this.handlePlantChange.bind(this);
        this.onFilterMouseUp = this.onFilterMouseUp.bind(this);
        this.filterData = this.filterData.bind(this);
        this.searchField = React.createRef();
        this.copyPlant = this.copyPlant.bind(this);
    }

    componentDidMount() {
        this.fetchPlants();
    }

    fetchPlants() {
        if (this.props.user) {
            api.get(process.env.REACT_APP_API + '/api/admin/plants')
                .then((res) => {
                    res.data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                    this.setState({ plants: res.data });
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    getStructure() {
        const apiUrl = process.env.REACT_APP_API + '/api/plants/' + this.state.chosenPlant.id + '/items/structure';

        api.get(apiUrl)
            .then((res) => {
                if (res.status !== 204) {
                    this.setTreeStructure(res.data, true);
                } else if (res.status === 204) {
                    this.setTreeStructure(null);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    setTreeStructure(data, doClone = false) {
        if (data) {
            const createDataTree = (dataset) => {
                const hashTable = Object.create(null);
                dataset.forEach((aData) => (hashTable[aData.id] = { ...aData, children: [] }));
                const dataTree = [];
                dataset.forEach((aData) => {
                    if (aData.parentId && aData.parentId !== '00000000-0000-0000-0000-000000000000') {
                        hashTable[aData.parentId] && hashTable[aData.parentId].children.push(hashTable[aData.id]);
                    } else {
                        dataTree.push(hashTable[aData.id]);
                    }
                });
                return dataTree;
            };

            const treeStruct = createDataTree(data);

            if (doClone) {
                this.setState({ treeStructure: treeStruct, originalStructure: JSON.parse(JSON.stringify(treeStruct)) });
            } else {
                this.setState({ treeStructure: treeStruct });
            }
        } else {
            this.setState({ treeStructure: [], originalStructure: [] });
        }
    }

    filterData(data, searchString) {
        const _data = JSON.parse(JSON.stringify(data));

        if (searchString === '') {
            return _data;
        }

        // eslint-disable-next-line
        return _data.filter(function iter(o) {
            let temp;
            if (o.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
                return true;
            }
            if (!Array.isArray(o.children)) {
                return false;
            }
            temp = o.children.filter(iter);
            if (temp.length) {
                o.children = temp;
                return true;
            }
        });
    }

    onFilterMouseUp(e) {
        const { value } = e.target;
        const filteredData = this.filterData(this.state.originalStructure, value);
        this.setState({ treeStructure: filteredData });
    }

    handlePlantChange(event) {
        event.preventDefault();
        const plant = this.state.plants.find((x) => x.id === event.target.value);

        this.setState(
            {
                chosenPlant: plant,
            },
            this.getStructure
        );
        this.searchField.current.value = '';
    }

    onSelect = (selectedKeys, selectedNodes) => {
        if (selectedKeys && selectedKeys.length > 0) {
            //temporary fix for empty array - user might have to click twice
            this.confirmCopy(selectedKeys, selectedNodes);
        }
    };

    copyPlant = (plantId) => {
        this.confirmCopy(null, null, plantId);
    };

    confirmCopy(selectedKeys, selectedNodes, plantId) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Copy structure</h1>
                        <p>
                            You are about to copy structure from
                            <br />{' '}
                        </p>
                        <span>
                            <b>{plantId ? this.state.chosenPlant.name : selectedNodes.node.props.title}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.props.copyStructureFrom({
                                        id: plantId ? plantId : selectedKeys[0],
                                        root: plantId ? true : false,
                                    });
                                    onClose();
                                }}
                            >
                                Yes, copy
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    renderChildren(tree) {
        return (
            <TreeNode title={tree.name} key={tree.id}>
                {tree.children.map((c) => {
                    if (c.children.length > 0) {
                        return this.renderChildren(c);
                    } else {
                        return <TreeNode title={c.name} key={c.id}></TreeNode>;
                    }
                })}
            </TreeNode>
        );
    }

    render() {
        const plants = this.state.plants || [];
        return (
            <>
                <Scrollbars
                    autoHide={false}
                    style={{
                        width: 630,
                        height: 550,
                    }}
                >
                    {this.props.isCopying ? (
                        <GeneralSpinner text="Saving" />
                    ) : (
                        <>
                            <form onSubmit={this.handleSubmit}>
                                <div id="type">
                                    <label htmlFor="plant_type">Choose Plant:</label>
                                    <>
                                        {' '}
                                        <select
                                            value={plants.id}
                                            id="plant_type"
                                            name="plantTypeId"
                                            onChange={this.handlePlantChange}
                                        >
                                            {this.props.editTag ? (
                                                <option value={this.props.editTag.plantId}>
                                                    {this.props.editTag.plantName}
                                                </option>
                                            ) : (
                                                <option value="0">All plants</option>
                                            )}
                                            {plants.map((plant, i) => {
                                                return (
                                                    <option key={'plant' + i} id={i} value={plant.id}>
                                                        {plant.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </>
                                </div>
                            </form>
                            <br />
                            <div className="list-search" style={{ width: '600px' }}>
                                <input
                                    autoComplete="off"
                                    ref={this.searchField}
                                    type="search"
                                    style={{ width: '100%' }}
                                    placeholder="Search"
                                    className="search-list"
                                    onChange={this.onFilterMouseUp.bind(this)}
                                />
                                <FontAwesome className="search-icon" name="search" />
                            </div>

                            {this.state.treeStructure && this.state.treeStructure.length > 0 ? (
                                <>
                                    <div
                                        className={'plant-node'}
                                        onClick={() => this.copyPlant(this.state.chosenPlant.id)}
                                    >
                                        {this.state.chosenPlant.name}
                                    </div>
                                    <Tree key={now()} onSelect={this.onSelect}>
                                        {this.state.treeStructure
                                            .filter((s) => s.parentId === '00000000-0000-0000-0000-000000000000')
                                            .map((entry) => {
                                                if (entry.children.length > 0) {
                                                    return this.renderChildren(entry);
                                                } else {
                                                    return <TreeNode title={entry.name} key={entry.id}></TreeNode>;
                                                }
                                            })}
                                    </Tree>
                                </>
                            ) : (
                                <div></div>
                            )}
                        </>
                    )}
                </Scrollbars>
                <button className="btn btn-yellow" onClick={this.props.closeModal}>
                    Cancel
                </button>
            </>
        );
    }
}

function mapStateToProps(state, _ownProps) {
    return {
        plant: state.plants.currentAdminPlant,
        user: state.user.currentUser,
    };
}

export default connect(mapStateToProps)(TreeNavStructureCopy);
