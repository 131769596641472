const OidcSettings = {
    authority: process.env.REACT_APP_AUTH,
    client_id: 'systemx_implicit',
    redirect_uri: window.location.origin,
    response_type: 'id_token token',
    scope: 'openid profile email',
    post_logout_redirect_uri: window.location.origin,
    automaticSilentRenew: true,
    silent_redirect_uri: window.location.origin + '/silent-renew',
    clockSkew: 600,
    accessTokenExpiringNotificationTime: 180,
};

export default OidcSettings;
