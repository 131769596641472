import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { clearCurrentPlant, setCurrentPlant } from '../../actions';
import Breadcrumb from '../../components/common/Breadcrumb/Breadcrumb';
import NavConfig from '../../components/common/Nav/NavConfig';
import NavContainer from '../../components/common/Nav/NavContainer';
import NoteAddEdit from '../../components/common/NoteAddEdit/index.js';
import CustomTable from '../../components/common/Table/CustomTable';
import { withLocation, withRouteMatch } from '../../HOC';
import { AccessModuleChecker } from '../../utility/accModule';
import api from '../../utility/api';
import AlarmList from '../alarmlist';
import Dashboard from '../dashboard';
import DocumentList from '../documentlist';
import EventList from '../eventlist';
import JobList from '../joblist';
import NoteList from '../notelist';
import Overview from '../overview';
import ParameterList from '../parameterlist';
import ReportList from '../reportlist';
import Support from '../support';
import TraceList from '../tracelist';
import './tables.css';

const usePlantConfig = true;

class Tables extends React.Component {
    //Test data
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            config: null,
            paths: [
                {
                    name: 'Home',
                    path:
                        '/plant/' +
                        this.props.match.params.plantId +
                        props.location.pathname.slice(props.location.pathname.lastIndexOf('/')),
                },
            ],
            currentTab: props.location.pathname.slice(props.location.pathname.lastIndexOf('/')),
            active: true,
            navVisible: false,
            currentItem: undefined,
            subPaths: [],
            showSubPaths: false,
        };
        this.fetchConfigData = this.fetchConfigData.bind(this);
        this.pushPath = this.pushPath.bind(this);
        this.popPath = this.popPath.bind(this);
        this.showTable = this.showTable.bind(this);
        this.fetchItemData = this.fetchItemData.bind(this);
        this.toggleSitemap = this.toggleSitemap.bind(this);
        this.toggleSubPaths = this.toggleSubPaths.bind(this);

        this.switchMode = this.switchMode.bind(this);
        this.mounted = true;
    }

    componentDidUpdate(prevProps) {
        if (isEqual(prevProps, this.props)) {
            return;
        }
        if (this.props.user && this.props.plant) {
            this.getCurrentItem().then(() =>
                Promise.all([
                    this.fetchConfigData(),
                    this.fetchItemData(this.props.endDateParent, this.props.periodParent),
                    this.fetchNavigation(),
                ]).then(([config, data, nav]) => {
                    if (this.mounted) {
                        this.setState(
                            {
                                data,
                                config,
                                navigation: nav,
                                active: true,
                                currentTab: window.location.pathname.slice(window.location.pathname.lastIndexOf('/')),
                            },
                            () => {
                                if (this.props.match.params.itemId) {
                                    this.getPathToHere(this.props.match.params.itemId);
                                } else {
                                    this.getSubPathsPlant(this.props.plant.id);
                                    if (this.mounted) {
                                        this.setState({
                                            paths: [
                                                {
                                                    name: this.props.plant.name,
                                                    path:
                                                        '/plant/' +
                                                        this.props.match.params.plantId +
                                                        this.state.currentTab,
                                                },
                                            ],
                                        });
                                    }
                                }
                            }
                        );
                    }
                })
            );
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    switchMode(editType, item) {
        this.setState({
            addNote: !this.state.addNote,
            editType,
            editItem: item,
        });
    }

    getSubPathsPlant(plantId) {
        api.get(`${process.env.REACT_APP_API}/api/breadcrumb/top?plantId=${plantId}`)
            .then((res) => {
                const formatedSubPaths = this.formatPath(res.data, true);
                if (this.mounted) {
                    this.setState({
                        subPaths: formatedSubPaths,
                    });
                }
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    getPathToHere(itemId) {
        api.get(`${process.env.REACT_APP_API}/api/breadcrumb?itemId=${itemId}`)
            .then((res) => {
                const formatedPath = this.formatPath(res.data);
                const formatedSubPaths = this.formatSubPaths(res.data);
                if (this.mounted) {
                    this.setState({
                        paths: formatedPath,
                        subPaths: formatedSubPaths,
                    });
                }
            })
            .catch((err) => {
                console.error('ERROR: ', err);
                if (this.mounted) {
                    this.setState({
                        paths: [
                            {
                                name: this.props.plant.name,
                                path: `/plant/${this.props.match.params.plantId}${this.state.currentTab}`,
                            },
                        ],
                    });
                }
            });
    }

    async getCurrentItem() {
        if (this.props.match.params.itemId) {
            const currentItem = await this.fetchCurrentItem();
            this.setState({
                currentItem,
            });
            return currentItem;
        }
        return false;
    }

    async fetchCurrentItem() {
        try {
            return (await api.get(`${process.env.REACT_APP_API}/api/items/${this.props.match.params.itemId}`)).data;
        } catch (err) {
            console.error('ERROR: ', err);
        }
    }

    formatPath(paths, top = false) {
        let tempPaths = [];
        if (paths[0] && !top) {
            tempPaths = [
                {
                    name: this.props.plant.name,
                    path: `/plant/${paths[0].plantId || this.props.match.params.plantId}${this.state.currentTab}`,
                },
            ];
        }

        paths.forEach((path) => {
            path.path = `/plant/${this.props.match.params.plantId}/items/${path.id}${this.state.currentTab}`;
        });

        return tempPaths.concat(paths);
    }

    formatSubPaths(paths) {
        const tmpPaths = [];
        try {
            paths = JSON.parse(paths[0].subComponents);
            if (paths && paths.length > 0) {
                paths.forEach((path) => {
                    //console.log(path);
                    if (!path.ShowInTableView) {
                        return;
                    }
                    const subPath = `/plant/${this.props.match.params.plantId}/items/${path.Id}${this.state.currentTab}`;
                    tmpPaths.push({ path: subPath, name: path.Name });
                });
            }
        } catch (ex) {
            console.error('Format subpath error');
        }
        return tmpPaths;
    }

    pushPath = (_path) => {
        if (this.props.match.params.plantId) {
            this.getPathToHere(this.props.match.params.plantId);
        } else {
            this.getPathToHere(this.props.match.params.itemId);
        }
    };

    popPath = (_path) => {
        if (this.props.match.params.plantId) {
            this.getPathToHere(this.props.match.params.plantId);
        } else {
            this.getPathToHere(this.props.match.params.itemId);
        }
    };

    async fetchItemData() {
        try {
            if (this.props.match.params.itemId) {
                const res = await api.get(
                    `${process.env.REACT_APP_API}/api/items/${this.props.match.params.itemId}/items`
                );
                if (res.status === 204) {
                    return [];
                }
                return res.data;
            }
            const res = await api.get(
                `${process.env.REACT_APP_API}/api/plants/${this.props.match.params.plantId}/items`
            );
            if (res.status === 204) {
                return [];
            }
            return res.data;
        } catch (err) {
            console.error(err);
        }
    }

    async fetchNavigation() {
        try {
            const res = await api.get(`${process.env.REACT_APP_API}/api/configurations/navigation`);
            return res.data;
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * @returns {Promise<ItemTypeViewModel>}
     */
    async fetchConfigData() {
        try {
            const res = await api.get(
                `${process.env.REACT_APP_API}/api/configurations?itemTypeId=${
                    (this.state.currentItem || {}).itemTypeId || ''
                }`
            );
            return res.data;
        } catch (err) {
            console.error(err);
        }
    }

    transitionStyles = {
        defaultStyle: {
            transform: 'translate(10em, 0)',
            opacity: 0,
        },
        enterStyle: {
            transition: 'transform 500ms, opacity 500ms',
            transitionTimingFunction: 'ease-in',
        },
        leaveStyle: {
            transition: 'transform 500ms, opacity 500ms',
            transitionTimingFunction: 'ease-out',
            transform: 'translate(10em, 0)',
            opacity: 0,
        },
        activeStyle: {
            transform: 'translate(0, 0)',
            opacity: 1,
        },
    };

    showTable = (val) => {
        this.setState({
            active: val,
        });
    };

    toggleSitemap(state) {
        this.setState({
            navVisible: state !== undefined ? state : !this.state.navVisible,
        });
    }

    toggleSubPaths() {
        this.setState({
            showSubPaths: !this.state.showSubPaths,
        });
    }

    render() {
        if (!this.props.plant) {
            return <div />;
        }
        const user = this.props.user;
        const customProps = {
            params: {
                plantId: this.props.match.params.plantId,
                itemId: this.props.match.params.itemId,
            },
        };

        const headingName = this.state.paths ? this.state.paths[this.state.paths.length - 1].name : '';
        //This entire thing needs a redo.. Looks like it is possible to configure navigation per itemtype.. but
        //This is not in use.. Always use plant navigation instead..
        // let navConfig = this.props.match.params.itemId ? this.state.navigation : JSON.parse(this.props.plant.navConfig)
        // let usePlantConfig = !this.props.match.params.itemId;
        const navConfig = JSON.parse(this.props.plant.navConfig);
        return (
            <>
                {this.props.plant ? (
                    <div className="breadcrumb-grid">
                        <Breadcrumb
                            isMobileOrTablet={this.props.isMobile || this.props.isTablet}
                            toggleSubPaths={this.toggleSubPaths}
                            showSubPaths={this.state.showSubPaths}
                            subPaths={this.state.subPaths}
                            showSitemap={this.state.navVisible}
                            toggleSitemap={this.toggleSitemap}
                            toggleTable={this.showTable}
                            paths={this.state.paths}
                        />
                        <div className="currentHeading">{headingName}</div>
                    </div>
                ) : null}
                <div className="tables-grid">
                    <NavContainer
                        path={this.props.match}
                        item={this.state.currentItem}
                        config={navConfig}
                        defaultConfig={NavConfig.detail}
                        plant={usePlantConfig}
                    />

                    <Switch>
                        <Route exact path="/plant/:plantId" name="Plant">
                            <AccessModuleChecker user={user} moduleName="KPI">
                                <Dashboard />
                            </AccessModuleChecker>
                        </Route>
                        <Route path="/plant/:plantId/kpi" name="Dashboard">
                            <AccessModuleChecker user={user} moduleName="KPI">
                                <Dashboard />
                            </AccessModuleChecker>
                        </Route>
                        <Route exact path="/plant/:plantId/items/:itemId/kpi" name="Plant">
                            <AccessModuleChecker user={user} moduleName="KPI">
                                <Dashboard
                                    currentItem={this.state.currentItem}
                                    chartdata={this.state.data}
                                    config={this.state.config}
                                    getChartInfoReq={this.fetchItemData}
                                    row={{ id: this.props.match.params.itemId }}
                                    plantId={this.props.match.params.plantId}
                                />
                            </AccessModuleChecker>
                        </Route>

                        <Route exact path="/plant/:plantId" name="Plant">
                            <Overview />
                        </Route>

                        <Route path="/plant/:plantId/overview" name="OverviewPlant">
                            <Overview />
                        </Route>

                        <Route path="/plant/:plantId/items/:itemId/overview" name="OverviewItem">
                            <Overview />
                        </Route>

                        <Route exact path="/plant/:plantId/system" name="SystemPlant">
                            <CustomTable
                                updateRows={this.fetchItemData}
                                plantId={this.props.match.params.plantId}
                                toggleTable={this.showTable}
                                user={this.props.user}
                                data={this.state.data}
                                config={this.state.config}
                                pushPath={this.pushPath}
                            />
                        </Route>

                        <Route exact path="/plant/:plantId/items/:itemId/system" name="SystemItem">
                            <CustomTable
                                updateRows={this.fetchItemData}
                                plantId={this.props.match.params.plantId}
                                toggleTable={this.showTable}
                                user={this.props.user}
                                data={this.state.data}
                                config={this.state.config}
                                pushPath={this.pushPath}
                            />
                        </Route>

                        <Route path="/plant/:plantId/report" name="Report">
                            <ReportList />
                        </Route>

                        <Route exact path="/plant/:plantId/items/:itemId/report" name="ReportItem">
                            <ReportList />
                        </Route>

                        <Route path="/plant/:plantId/note" name="NotePlant">
                            {this.state.addNote ? (
                                <NoteAddEdit
                                    match={customProps}
                                    toggle={this.switchMode}
                                    editType={this.state.editType}
                                    editItem={this.state.editItem}
                                />
                            ) : (
                                <AccessModuleChecker user={user} moduleName={'Notes'}>
                                    <NoteList
                                        itemId={this.props.match.params.itemId}
                                        plantId={this.props.match.params.plantId}
                                        toggle={this.switchMode}
                                    />
                                </AccessModuleChecker>
                            )}
                        </Route>
                        <Route exact path="/plant/:plantId/items/:itemId/note" name="NoteItem">
                            {this.state.addNote ? (
                                <NoteAddEdit
                                    match={customProps}
                                    toggle={this.switchMode}
                                    editType={this.state.editType}
                                    editItem={this.state.editItem}
                                />
                            ) : (
                                <AccessModuleChecker user={user} moduleName={'Notes'}>
                                    <NoteList
                                        itemId={this.props.match.params.itemId}
                                        plantId={this.props.match.params.plantId}
                                        toggle={this.switchMode}
                                    />
                                </AccessModuleChecker>
                            )}
                        </Route>

                        <Route path="/plant/:plantId/documentation" name="DocumentationPlant">
                            {this.state.addNote ? (
                                <div />
                            ) : (
                                <AccessModuleChecker user={user} moduleName={'Documentation'}>
                                    <DocumentList
                                        itemId={this.props.match.params.itemId}
                                        plantId={this.props.match.params.plantId}
                                        toggle={this.switchMode}
                                    />
                                </AccessModuleChecker>
                            )}
                        </Route>
                        <Route exact path="/plant/:plantId/items/:itemId/documentation" name="DocumentationItem">
                            {this.state.addNote ? (
                                <div />
                            ) : (
                                <AccessModuleChecker user={user} moduleName={'Documentation'}>
                                    <DocumentList
                                        itemId={this.props.match.params.itemId}
                                        plantId={this.props.match.params.plantId}
                                        toggle={this.switchMode}
                                    />
                                </AccessModuleChecker>
                            )}
                        </Route>

                        <Route path="/plant/:plantId/events" name="EventsPlant">
                            <AccessModuleChecker user={user} moduleName="Events">
                                <EventList
                                    currentItem={this.state.currentItem}
                                    itemId={this.props.match.params.itemId}
                                    plantId={this.props.match.params.plantId}
                                    toggle={this.switchMode}
                                />
                            </AccessModuleChecker>
                        </Route>

                        <Route exact path="/plant/:plantId/items/:itemId/events" name="EventsItem">
                            <AccessModuleChecker user={user} moduleName="Events">
                                <EventList
                                    currentItem={this.state.currentItem}
                                    itemId={this.props.match.params.itemId}
                                    plantId={this.props.match.params.plantId}
                                    toggle={this.switchMode}
                                />
                            </AccessModuleChecker>
                        </Route>

                        <Route path="/plant/:plantId/settings" name="ParametersPlant">
                            {this.state.addNote ? (
                                <div />
                            ) : (
                                <AccessModuleChecker user={user} moduleName={'Settings'}>
                                    <ParameterList
                                        currentItem={this.state.currentItem}
                                        itemId={this.props.match.params.itemId}
                                        plantId={this.props.match.params.plantId}
                                        toggle={this.switchMode}
                                    />
                                </AccessModuleChecker>
                            )}
                        </Route>
                        <Route exact path="/plant/:plantId/items/:itemId/settings" name="Alarms">
                            {this.state.addNote ? (
                                <div />
                            ) : (
                                <AccessModuleChecker user={user} moduleName={'Settings'}>
                                    <ParameterList
                                        currentItem={this.state.currentItem}
                                        itemId={this.props.match.params.itemId}
                                        plantId={this.props.match.params.plantId}
                                        toggle={this.switchMode}
                                    />
                                </AccessModuleChecker>
                            )}
                        </Route>

                        <Route exact path="/plant/:plantId/alarms" name="Alarms">
                            {this.state.addNote ? (
                                <div />
                            ) : (
                                <AccessModuleChecker user={user} moduleName={'Alarms'}>
                                    <AlarmList
                                        currentItem={this.state.currentItem}
                                        itemId={this.props.match.params.itemId}
                                        plantId={this.props.match.params.plantId}
                                        toggle={this.switchMode}
                                    />
                                </AccessModuleChecker>
                            )}
                        </Route>
                        <Route exact path="/plant/:plantId/items/:itemId/alarms" name="Alarms">
                            {this.state.addNote ? (
                                <div />
                            ) : (
                                <AccessModuleChecker user={user} moduleName={'Alarms'}>
                                    <AlarmList
                                        currentItem={this.state.currentItem}
                                        itemId={this.props.match.params.itemId}
                                        plantId={this.props.match.params.plantId}
                                        toggle={this.switchMode}
                                    />
                                </AccessModuleChecker>
                            )}
                        </Route>

                        <Route exact path="/plant/:plantId/trace" name="Traces">
                            <AccessModuleChecker user={user} moduleName="Traces">
                                <TraceList
                                    currentItem={this.state.currentItem}
                                    itemId={this.props.match.params.itemId}
                                    plantId={this.props.match.params.plantId}
                                />
                            </AccessModuleChecker>
                        </Route>

                        <Route exact path="/plant/:plantId/items/:itemId/trace" name="Traces">
                            <AccessModuleChecker user={user} moduleName="Traces">
                                <TraceList
                                    currentItem={this.state.currentItem}
                                    itemId={this.props.match.params.itemId}
                                    plantId={this.props.match.params.plantId}
                                />
                            </AccessModuleChecker>
                        </Route>

                        <Route exact path="/plant/:plantId/support" name="Support">
                            <AccessModuleChecker user={user} moduleName="Support">
                                <Support plantId={this.props.match.params.plantId} />
                            </AccessModuleChecker>
                        </Route>

                        <Route exact path="/plant/:plantId/items/:itemId/support" name="Support">
                            <AccessModuleChecker user={user} moduleName="Support">
                                <Support
                                    currentItem={this.state.currentItem}
                                    itemId={this.props.match.params.itemId}
                                    plantId={this.props.match.params.plantId}
                                />
                            </AccessModuleChecker>
                        </Route>

                        <Route exact path="/plant/:plantId/operations" name="Operations">
                            <AccessModuleChecker user={user} moduleName="Operations">
                                <JobList
                                    currentItem={this.state.currentItem}
                                    itemId={this.props.match.params.itemId}
                                    plantId={this.props.match.params.plantId}
                                />
                            </AccessModuleChecker>
                        </Route>

                        <Route exact path="/plant/:plantId/items/:itemId/operations" name="Operations">
                            <AccessModuleChecker user={user} moduleName="Operations">
                                <JobList
                                    currentItem={this.state.currentItem}
                                    itemId={this.props.match.params.itemId}
                                    plantId={this.props.match.params.plantId}
                                />
                            </AccessModuleChecker>
                        </Route>
                    </Switch>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plants.currentPlant,
    user: state.user.currentUser,
    periodParent: state.itemrow.periodParent || { period: 12, interval: 'hours', ticks: 'hour' },
    endDateParent: state.itemrow.endDateParent,
    isTablet: state.utility.isTablet,
    isMobile: state.utility.isMobile,
});

const mapDispatchToProps = {
    setCurrentPlant,
    clearCurrentPlant,
};

export default withLocation(withRouteMatch(connect(mapStateToProps, mapDispatchToProps)(Tables)));

/**
 * @typedef {{
 *     itemTypeId: String;
 *     displayName?: String;
 *     showDataIcon: Boolean;
 *     showExpander: Boolean;
 *     sortIndex: Number;
 *     sections?: unknown;
 *     navigation?: unknown;
 *     widgets: unknown[];
 *     configuration?: unknown;
 * }} ItemTypeViewModel
 */
