import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as DeleteIcon } from '../../images/list/optimar_cross.svg';
import { ReactComponent as EditIcon } from '../../images/list/optimar_edit.svg';
import { confirmAlert } from 'react-confirm-alert';
import ReactTable from 'react-table-6';
import Spinner from '../../components/common/Spinner/Spinner';
import AdminMachineNoAddEdit from '../../components/common/AdminMachineNoAddEdit/AdminMachineNoAddEdit';
import api from '../../utility/api';
import 'react-table-6/react-table.css';

import Modal from 'react-modal';
import { withRouteMatch } from '../../HOC';
import { noop } from 'lodash';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminMachineNo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.visibleColumns = [
            { Header: 'MachineNo', accessor: 'machineNo', filterable: true },
            { Header: 'OrderPos', accessor: 'orderPos', filterable: true },
            { Header: 'ProjectNo', accessor: 'projectNo', filterable: true },
            { Header: 'Plant', accessor: 'plantName', filterable: true },
            { Header: 'Description', accessor: 'description', filterable: true },
            {
                Header: '',
                Cell: (item) => {
                    return (
                        <div className="action-container">
                            <div
                                className="action-element "
                                alt="Delete tag"
                                title="Delete tag"
                                onClick={() => this.confirmDelete(item.original)}
                            >
                                <DeleteIcon className="action-icon red" />
                                <span className="list-edit-btn">Delete</span>
                            </div>
                            <div
                                className="action-element padding"
                                alt="Edit machine no"
                                title="Edit machine no"
                                onClick={() => this.openModal(item.original, 'edit')}
                            >
                                <EditIcon className="action-icon" />
                                <span className="list-edit-btn">Edit machineNo</span>
                            </div>
                        </div>
                    );
                },
            },
        ];

        this.fetchMachineNos = this.fetchMachineNos.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.fetchMachineNos();
    }

    componentDidUpdate() {
        if (this.props.user) {
            this.fetchMachineNos();
        }
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete machineNo</h1>
                        <p>
                            You are about to delete the machineNo
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.title}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteMachineNo(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    fetchMachineNos() {
        if (this.props.user != null) {
            api.get(process.env.REACT_APP_API + '/api/machineNos')
                .then((res) => {
                    this.setState({
                        data: res.data,
                    });
                })
                .catch(() => {
                    NotificationManager.error('Failed to fetch', 'Could not fetch machineNos');
                });
        }
    }

    deleteMachineNo = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/machineNos/' + itemFromChild.machineNo)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('MachineNo deleted', 'MachineNo successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete machineNo', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchMachineNos();
                })
                .catch(noop);
        }
    };

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                editType: mode,
            },
            function () {
                this.fetchMachineNos();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchMachineNos();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null
            ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
            : false;
    }

    render() {
        const machineNos = this.state.data;

        if (!machineNos) {
            return <Spinner text="machineNos" />;
        }

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{machineNos ? machineNos.length : 0} MachineNos</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New machineNo</span>{' '}
                        </span>
                    </span>
                </div>
                <ReactTable
                    data={machineNos}
                    columns={this.visibleColumns}
                    showPagination={true}
                    pageSize={20}
                    showPageSizeOptions={false}
                    minRows={0}
                    defaultFilterMethod={this.filterCaseInsensitive}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminMachineNoAddEdit
                        match={this.props.match}
                        machineNo={this.state.editItem}
                        o={this.closeModal}
                        cancelCallback={this.closeModal}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default withRouteMatch(connect(mapStateToProps)(AdminMachineNo));
