import React from 'react';
import './ProfileButton.css';
// import UserIcon from '../../../images/icons/optimar_user_blue.svg';

import { ReactComponent as UserIcon } from '../../../images/icons/optimar_user_blue.svg';

const ProfileButton = ({ profileImage, onClick }) => {
    let type = 'no-pimage';
    if (!profileImage) {
        //profileImage = <img src={UserIcon} alt="user icon"/>;
        profileImage = <UserIcon alt="user icon" />;
    } else {
        profileImage = <img alt="Profileimage" src={profileImage} className="profileimage" />;
        type = 'pimage';
    }

    return (
        <button onClick={onClick} className={`profilebutton ${type}`}>
            {profileImage}
            {/* <FontAwesome name="angle-down" /> */}
        </button>
    );
};

export default ProfileButton;
