import React from 'react';
import api from '../../utility/api';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';

export const fetchSingleExternalDocAsync = async (doc, props) => {
    const plantId = props.plantId || doc.plantId;
    const url =
        `${process.env.REACT_APP_API}/api/documents/` +
        (plantId + '/' + doc.projectId + '/' + doc.externalDocId + '/externalTmpDownloadToken');
    const response = await api.get(url);
    console.log('External download', response.data);
    if (response.status === 200) {
        window.location.href = `${process.env.REACT_APP_API}/api/download/` + response.data;
    }
};

export const fetchSingleDocAsync = async (doc, _props) => {
    const url = `${process.env.REACT_APP_API}/api/documents/` + (doc.id + (doc.articleId ? '/article' : ''));
    console.log('fetchsingledoc', url);
    NotificationManager.info('Downloading document', 'Please wait..');

    const response = await api.get(url, { responseType: 'arraybuffer' });
    return response;
};

export const createAndDownloadFile = (body, filename) => {
    const blob = new Blob([body]);
    const fileName = filename;
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export const confirmDelete = (item) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>Delete document</h1>
                    <p>
                        You are about to delete the document
                        <br />{' '}
                    </p>
                    <span>
                        <b>{item.filename}</b>
                    </span>
                    <p>Are you sure?</p>
                    <div className="buttons">
                        <button className="btn btn-yellow" onClick={onClose}>
                            No, cancel
                        </button>
                        <button
                            className="btn btn-red"
                            onClick={() => {
                                deleteDocument(item);
                                onClose();
                            }}
                        >
                            Yes, delete
                        </button>
                    </div>
                </div>
            );
        },
    });
};

const deleteDocument = async (doc) => {
    try {
        const response = await api.delete(process.env.REACT_APP_API + '/api/documents/' + doc.id);
        if (response.status === 200) {
            NotificationManager.success('Document deleted successfully', 'Document deleted', 5000);
        } else {
            console.log(response.status);
            NotificationManager.error('Document not deleted', 'Error deleting document', 5000);
        }
    } catch (err) {
        console.log(err);
        NotificationManager.error('Document not deleted', 'Error deleting document', 5000);
    }
};
