import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import AdminJobTypeAddEdit from '../../components/common/AdminJobTypeAddEdit/AdminJobTypeAddEdit';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import Spinner from '../../components/common/Spinner/Spinner';
import {
    ReportConfigClearReports,
    ReportConfigCreate,
    ReportConfigRemove,
} from '../../components/ReportDesigner/ReportControl';
import ReportDesigner from '../../components/ReportDesigner/ReportDesigner';
import api from '../../utility/api';
import './AdminJobTypes.scss';

class AdminJobTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showGlobalOnly: false,
            reportDesignerIsOpen: false,
            reportDesignerJobType: null,
        };
        this.fetching = false;
        this.showSpinner = true;
        const rowContext = [
            {
                content: <span>Edit operation type</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete operation type</span>,
                action: (row) => this.confirmDelete(row.original),
            },
            {
                content: <span>Add report configuration</span>,
                action: (row) => ReportConfigCreate(row.original, this.fetchJobTypes),
                hideContext: (row) => {
                    return row.original.reportConfigId ? true : false;
                },
            },
            {
                content: <span>Remove report configuration</span>,
                action: (row) =>
                    ReportConfigRemove(row.original, () => {
                        if (row.isExpanded) row.toggleRowExpanded();
                        this.fetchJobTypes();
                    }),
                hideContext: (row) => {
                    return row.original.reportConfigId ? false : true;
                },
            },
            {
                content: <span>Clear all generated reports</span>,
                action: (row) => ReportConfigClearReports(row.original, this.fetchJobTypes),
                hideContext: (row) => {
                    return row.original.reportConfigId ? false : true;
                },
            },
            {
                content: <span>Report Designer</span>,
                action: (row) => this.openReportDesigner(row.original),
                hideContext: (row) => {
                    return row.original.reportConfigId ? false : true;
                },
            },
        ];

        this.visibleColumns = [
            { Header: 'Name', accessor: 'name', filterable: true },
            { Header: 'Parent', accessor: 'parentName', filterable: true },
            { Header: 'Plant', accessor: 'plantName', filterable: true, id: 'plantName', show: !this.props.plantId },
            {
                Header: () => null,
                id: 'rowcontext',
                Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
            },
        ];

        this.fetchJobTypes = this.fetchJobTypes.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openReportDesigner = this.openReportDesigner.bind(this);
        this.closeReportDesigner = this.closeReportDesigner.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.deleteJobType = this.deleteJobType.bind(this);
    }

    componentDidMount() {
        this.fetchJobTypes();
    }

    fetchJobTypes() {
        if (this.props.user != null || this.props.plantId) {
            if (!this.fetching) {
                const sufix = this.props.plantId ? '?plantId=' + this.props.plantId : '';
                api.get(process.env.REACT_APP_API + '/api/jobtypes' + sufix)
                    .then((res) => {
                        this.setState({
                            jobTypes: res.data,
                        });
                        this.fetching = false;
                        this.showSpinner = false;
                        this.forceUpdate();
                    })
                    .catch(() => {
                        this.fetching = false;
                        this.showSpinner = false;
                        NotificationManager.error('Failed to fetch', 'Could not fetch item types');
                    });
            }
        }
    }

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editJobType: item,
                editType: mode,
            },
            function () {
                this.fetchJobTypes();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editJobType: null,
                editType: null,
            },
            function () {
                this.fetchJobTypes();
            }
        );
    }

    openReportDesigner(jobType) {
        this.setState({
            reportDesignerIsOpen: true,
            reportDesignerJobType: jobType,
        });
    }

    closeReportDesigner() {
        this.setState({
            reportDesignerIsOpen: false,
            reportDesignerJobType: null,
        });
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete item type</h1>
                        <p>
                            You are about to delete the operation type
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteJobType(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteJobType = (itemFromChild) => {
        if (this.props.user != null || this.props.plantId) {
            api.delete(process.env.REACT_APP_API + '/api/jobtypes/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success(
                            'Operation type deleted',
                            'Operation type successfully deleted',
                            5000
                        );
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete operation type', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchJobTypes();
                })
                .catch(noop);
        }
    };

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase()) : false;
    }

    toggleTopLevel = () => {
        this.setState({ showGlobalOnly: !this.state.showGlobalOnly });
    };

    render() {
        let jobTypes = this.state.jobTypes;

        if (this.state.showGlobalOnly) {
            jobTypes = this.state.jobTypes.filter(
                (f) => !f.plantId || f.plantId === '00000000-0000-0000-0000-000000000000'
            );
        }

        if (this.showSpinner) return <Spinner text="operation types" />;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{jobTypes ? jobTypes.length : 0} Operation types</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New operation type</span>{' '}
                        </span>
                        {this.props.plantId ? (
                            ''
                        ) : (
                            <div onClick={() => this.toggleTopLevel()} className={'list-filter-check faux-checkbox '}>
                                <div className={this.state.showGlobalOnly ? 'checkbox checked' : 'checkbox'}></div>
                                <span>Only global</span>
                            </div>
                        )}
                    </span>
                </div>
                <GenericTable
                    data={jobTypes}
                    columns={this.visibleColumns}
                    localPaging={true}
                    enableGlobalFilter={true}
                />

                {
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                        className={'addedit_modal_content'}
                    >
                        <AdminJobTypeAddEdit
                            allJobTypes={jobTypes}
                            editJobType={this.state.editJobType}
                            editedCallback={this.closeModal}
                            cancelCallback={this.closeModal}
                            plant_specific={this.props.plantId}
                        ></AdminJobTypeAddEdit>
                    </Modal>
                }
                <Modal
                    isOpen={this.state.reportDesignerIsOpen}
                    onRequestClose={this.closeDesigner}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    className={'designer_modal_content'}
                >
                    <ReportDesigner
                        jobType={this.state.reportDesignerJobType}
                        closeCallback={this.closeReportDesigner}
                        plant_speficic={this.props.plantId}
                    />
                </Modal>
            </>
        );
    }
}

AdminJobTypes.propTypes = {
    user: PropTypes.object,
};

export default AdminJobTypes;
