import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import api from '../../../utility/api';
import { EditPaneContext } from './DashboardEditPane';
import { ReactComponent as DeleteIcon } from '../../../images/new/optimar_cross.svg';
import { DataValueSelect } from '../../../utility/datavalueSelect';

export default function ComponentSettings(props) {
    const [components, setComponents] = useState([]);
    const [dataValues, setDataValues] = useState([]);
    const [plants, setPlants] = useState([]);
    const [selectedPlant, setSelectedPlant] = useState({});
    const [selectedComponent, setSelectedComponent] = useState('');
    const [state, setSettings] = useContext(EditPaneContext);
    const [fetchingDataValues, setFetchingDataValues] = useState(false);

    const dataCache = props.dataCache;
    useEffect(() => {
        if (props.plantId) {
            setComponents(props.components);
        } else {
            fetchPlantList((res) => {
                setPlants(res);
            });
        }

        setSelectedComponent(state[props.index].topic);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchItemStructure((res) => {
            setComponents(res);
        });
        // eslint-disable-next-line
    }, [selectedPlant]);
    //setFetchingDataValues
    useEffect(() => {
        const fetchDataValues = async (topic) => {
            if (topic && topic !== '') {
                try {
                    //console.log("fetching datavalues 2");
                    setFetchingDataValues(true);
                    const res = await api.get(
                        process.env.REACT_APP_API + '/api/items/datavalues?itemKeys=' + encodeURIComponent(topic)
                    );

                    const resString = JSON.stringify(res.data);
                    //console.log(resString,res.data,res.data.length);
                    if (JSON.stringify(dataValues) !== resString || res.data.length === 0) {
                        setDataValues(res.data);
                        setFetchingDataValues(false);
                    }
                } catch (err) {
                    console.error('ERROR: ', err);
                    setDataValues([]);
                    setFetchingDataValues(false);
                }
            } else {
                setDataValues([]);
                setFetchingDataValues(false);
            }
        };
        // }

        if (selectedComponent) {
            fetchDataValues(selectedComponent);
            // fetchDataValues(selectedComponent, (res) => {
            //     if (JSON.stringify(dataValues) !== JSON.stringify(res)) {
            //         setDataValues(res);
            //     }
            // });
        }
        // eslint-disable-next-line
    }, [selectedComponent]);

    const getAggMethodSelectorControl = () => {
        //console.log(state[props.index])
        // if(!components.dataValue)
        //     return null;
        if (!state[props.index].dataValue) return null;
        const dv = state[props.index].dataValue;
        const isHistoric = dv?.split('/')[0] === 'historic' || false;
        return (
            <>
                {isHistoric ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="unit">Aggregation method (Payload)</label>
                            <select
                                required
                                name="aggMethod"
                                className={'input-control'}
                                onChange={handleChange}
                                value={state[props.index].aggMethod}
                            >
                                <option value="mean">Average</option>
                                <option value="min">Min</option>
                                <option value="max">Max</option>
                                <option value="sum">Sum</option>
                                <option value="count">Count</option>
                                <option value="std">Standard deviation</option>
                            </select>
                        </span>
                    </div>
                ) : null}
            </>
        );
    };
    const getAccInPeriodMethodSelectorControl = () => {
        //Gets a dropdown enabling users to select if the latest payload should be selected or some handling over a range
        return (
            <>
                {
                    <div className="form-group">
                        <span>
                            <label htmlFor="unit">Aggregation method (Period)</label>
                            <select
                                name="aggMethodPeriod"
                                className={'input-control'}
                                onChange={handleChange}
                                value={state[props.index].aggMethodPeriod}
                            >
                                <option value="snapshot">Snapshot</option>
                                <option value="accInPeriodSum">Accumulate in Period - Sum</option>
                                <option value="accInPeriodAvg">Accumulate in Period - Avg</option>
                                {/* <option value="accInPeriodAlwaysIncreasing">Accumulate in Period - Always increasing</option> */}
                            </select>
                        </span>
                    </div>
                }
            </>
        );
    };

    const fetchItemStructure = async (callback) => {
        const plantId = props.plantId || selectedPlant.id || state[props.index].plantId;
        const data = await dataCache.fetchItemStructure(plantId);
        callback(data);
    };

    const fetchPlantList = async (callback) => {
        const data = await dataCache.fetchPlantList();
        callback(data);
    };

    const onDataValueChanged = (selectedOption) => {
        const tmp = Object.assign([], state);

        tmp[props.index].dataValue = selectedOption.value;
        setSettings(tmp);
    };

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = Object.assign([], state);
        tmp[props.index][name] = value;

        console.log('ComponentSettings state change', tmp);
        setSettings(tmp);
    };

    const setPlantId = (item) => {
        const tmp = Object.assign([], state);
        tmp[props.index].plantId = item.id;
        setSelectedPlant(item);
        setSettings(tmp);
    };

    const setTopic = (item) => {
        const tmp = Object.assign([], state);
        tmp[props.index].topic = item.topic;
        tmp[props.index].dataType = item.dataType;
        tmp[props.index].itemId = item.id;
        setSelectedComponent(item.topic);
        setSettings(tmp);
    };

    return (
        <div className="component-setting">
            <fieldset className="component-fieldset">
                <legend>Component {props.index + 1}</legend>
                <div className="component-fields" style={{ position: 'relative' }}>
                    <DeleteIcon
                        onClick={() => props.delete(props.index)}
                        className="delete-icon"
                        style={{ position: 'absolute', right: '0', zIndex: '9999' }}
                    />

                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={state[props.index].name}
                            onChange={(e) => handleChange(e)}
                            className="input-control"
                        />
                    </div>
                    {!props.plantId ? (
                        <div className="form-group">
                            <label htmlFor="component">Plant</label>
                            <Select
                                name={'plant'}
                                value={
                                    plants
                                        ? plants.filter((c) => c.id === state[props.index].plantId)
                                        : 'Please select a plant'
                                }
                                onChange={(item) => setPlantId(item)}
                                options={plants}
                                isMulti={false}
                                classNamePrefix={'optimar'}
                                getOptionLabel={(x) => x.name}
                                getOptionValue={(x) => x.id}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    ) : null}
                    <div className="form-group" style={{ position: 'relative' }}>
                        <label htmlFor="component">Component</label>
                        <Select
                            name={'component'}
                            value={
                                components
                                    ? components.filter((c) => c.topic === state[props.index].topic)
                                    : 'Please select a component'
                            }
                            onChange={(item) => setTopic(item)}
                            options={components || []}
                            isMulti={false}
                            classNamePrefix={'optimar'}
                            getOptionLabel={(x) => `${x.topic ? x.topic.substring(x.topic.indexOf('/') + 1) : 'N/A'}`}
                            getOptionValue={(x) => `${x.topic || 'N/A'}`}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                menu: (styles) => ({
                                    ...styles,
                                    width: 'auto',
                                }),
                            }}
                        />
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                                opacity: 0,
                                width: '100%',
                                height: 0,
                                position: 'absolute',
                            }}
                            value={state[props.index]?.topic}
                            required={state[props.index].link ? false : true}
                        />
                    </div>

                    <div className="form-group" style={{ position: 'relative' }}>
                        <label htmlFor="dataValue">Data value</label>
                        <DataValueSelect
                            options={dataValues || []}
                            fetchingDataValues={fetchingDataValues}
                            onChange={(selected) => onDataValueChanged(selected)}
                            widget={state[props.index]}
                        />
                        <input
                            tabIndex={-2}
                            autoComplete="off"
                            style={{
                                opacity: 0,
                                width: '100%',
                                height: 0,
                                position: 'absolute',
                            }}
                            value={state[props.index]?.dataValue}
                            required={state[props.index].link ? false : true}
                        />
                    </div>
                    {getAggMethodSelectorControl()}
                    {getAccInPeriodMethodSelectorControl()}
                    {props.type === 'link-button-group' ? (
                        <>
                            <div className="form-group">
                                <label htmlFor="icon">Icon</label>
                                <input
                                    type="text"
                                    name="icon"
                                    value={state[props.index].icon}
                                    onChange={(e) => handleChange(e)}
                                    className="input-control"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="link">Link</label>
                                <input
                                    type="text"
                                    name="link"
                                    value={state[props.index].link}
                                    onChange={(e) => handleChange(e)}
                                    className="input-control"
                                />
                            </div>
                        </>
                    ) : null}
                    <div className="form-group">
                        <label htmlFor="unit">Unit</label>
                        <input
                            type="text"
                            name="unit"
                            value={state[props.index].unit}
                            onChange={(e) => handleChange(e)}
                            className="input-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="varName">Variable Name</label>
                        <input
                            type="text"
                            name="varName"
                            value={
                                state[props.index].varName || (props.index === 0 ? 'val' : 'val' + (props.index + 1))
                            }
                            onChange={(e) => handleChange(e)}
                            className="input-control"
                        />
                    </div>
                </div>
            </fieldset>
        </div>
    );
}
