/* eslint-disable no-undef */
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';

import api from '../../utility/api';
import Spinner from '../../components/common/Spinner/Spinner';

import DatapointsDesigner from './DatapointsDesigner';
import DocumentDesigner from './DocumentDesigner';
import StyleDesigner from './StyleDesginer';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import './ReportDesigner.scss';

function ReportDesigner(props) {
    const [data, setData] = React.useState([]);
    const [fetchingItems, setFetching] = React.useState(null);
    const [unsavedData, setUnsavedData] = React.useState(false);
    const [viewType, setViewType] = React.useState(2);

    const getDatavaluesFunction = async (topic) => {
        try {
            let apiUrl = `${process.env.REACT_APP_API}/api/items/datavalues?itemKeys=${encodeURIComponent(topic)}`;
            if (props.jobType.plantId)
                apiUrl = `${process.env.REACT_APP_API}/api/items/plant/${
                    props.jobType.plantId
                }/datavalues?itemKeys=${encodeURIComponent(topic)}`;

            const res3 = await api.get(apiUrl);
            const datavalues = res3.data;
            return datavalues;
        } catch (err) {
            console.log('Error getting datavalues for', topic, err);
        }
    };

    const fetchReportConfig = async () => {
        if (!fetchingItems) {
            setFetching(true);

            try {
                const res = await api.get(
                    process.env.REACT_APP_API + '/api/reportconfig/' + props.jobType.reportConfigId
                );

                const tempData = res.data;
                tempData.config = JSON.parse(tempData.config || '{}');

                if (!('data' in tempData.config)) tempData.config.data = [];

                if (!('sections' in tempData.config)) tempData.config.sections = [];

                if (!('title' in tempData.config)) tempData.config.title = '';

                let items = null;
                //var datavalues = null

                if (props.jobType.plantId) {
                    const res2 = await api.get(
                        process.env.REACT_APP_API + '/api/plants/' + props.jobType.plantId + '/items/structure'
                    );
                    items = res2.data;

                    // var res3 = await api.get(process.env.REACT_APP_API + "/api/items/datavalues?itemKeys=" + items.map((elem) => elem.topic))
                    // datavalues = res3.data;
                }

                const res4 = await api.get(
                    process.env.REACT_APP_API + '/api/jobtypes/children/' + props.jobType.id + '?recursive=true'
                );

                setData({
                    ...data,
                    id: tempData.id,
                    config: tempData.config,
                    items: items,
                    //datavalues: datavalues,
                    child_jobs: res4.data,
                });
            } catch (err) {
                console.error(err);
            }

            setFetching(false);
        }
    };

    React.useEffect(() => {
        async function fetchData() {
            await fetchReportConfig();
        }
        fetchData();
    }, []);

    const onReportConfigChange = (json) => {
        setData({ ...data, config: json });
        setUnsavedData(true);
    };

    const onDataChange = (key, new_data) => {
        data.config[key] = new_data;
        onReportConfigChange(data.config);
    };

    const submitReportConfig = async () => {
        if (unsavedData) {
            try {
                const response = await api.post('api/reportconfig/' + data.id, { config: JSON.stringify(data.config) });

                if (response.status !== 200 && response.status !== 204) throw new Error(response.status);

                NotificationManager.success('Updated report config', 'Success', 5000);
                setUnsavedData(false);
            } catch (error) {
                console.error(error);
                NotificationManager.error('Error', 'Failed to update report config', 5000);
            }
        }

        props.closeCallback();
    };

    const cancel = () => {
        if (unsavedData) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body">
                            <h1>Drop changes</h1>
                            <p>
                                You have unsaved changes in the report config for
                                <br />{' '}
                            </p>
                            <span>
                                <b>{props.jobType.name}</b>
                            </span>
                            <p>Do you want to drop unsaved changes?</p>
                            <div className="buttons">
                                <button className="btn btn-yellow" onClick={onClose}>
                                    No, cancel
                                </button>
                                <button
                                    className="btn btn-red"
                                    onClick={() => {
                                        props.closeCallback();
                                        onClose();
                                    }}
                                >
                                    Yes, drop
                                </button>
                            </div>
                        </div>
                    );
                },
            });
        } else props.closeCallback();
    };

    return (
        <div className="reportconfig">
            <nav className="viewslist">
                {
                    <div className="viewlist-content" onClick={() => setViewType(2)}>
                        <span className={viewType === 2 ? 'view-active' : 'view-inactive'}>Sections</span>
                    </div>
                }
                <div className="viewlist-content" onClick={() => setViewType(1)}>
                    <span className={viewType === 1 ? 'view-active' : 'view-inactive'}>Data Points</span>
                </div>
                <div className="viewlist-content" onClick={() => setViewType(3)}>
                    <span className={viewType === 3 ? 'view-active' : 'view-inactive'}>Styles</span>
                </div>
                <div className="viewlist-content" onClick={() => setViewType(0)}>
                    <span className={viewType === 0 ? 'view-active' : 'view-inactive'}>Raw View</span>
                </div>
            </nav>
            {fetchingItems || !data.config ? (
                <Spinner text="report config" />
            ) : (
                <>
                    {viewType === 0 ? (
                        <div className="editor">
                            <Editor onChange={onReportConfigChange} mode="code" value={data.config} />
                        </div>
                    ) : null}
                    {viewType === 1 ? (
                        <div className="designer">
                            <DatapointsDesigner
                                data={data.config?.data}
                                onDataChange={(new_data) => onDataChange('data', new_data)}
                                items={data.items}
                                //datavalues = {data.datavalues}
                                getDatavaluesFunc={getDatavaluesFunction}
                                child_jobs={data.child_jobs}
                                job_type={props.jobType}
                            />
                        </div>
                    ) : null}
                    {viewType === 2 ? (
                        <div className="designer">
                            <DocumentDesigner
                                data={data.config?.sections}
                                title={data.config?.title}
                                onDataChange={(new_data) => onDataChange('sections', new_data)}
                                onTitleChange={(new_data) => onDataChange('title', new_data)}
                                datapoints={data.config?.data}
                                child_jobs={data.child_jobs}
                                job_type={props.jobType}
                                plant_speficic={props.plant_speficic}
                            />
                        </div>
                    ) : null}
                    {viewType === 3 ? (
                        <div className="designer">
                            <StyleDesigner
                                data={data.config?.style}
                                onDataChange={(new_data) => onDataChange('style', new_data)}
                            />
                        </div>
                    ) : null}
                    <div className="buttons">
                        <button onClick={cancel} className="btn btn-yellow">
                            Cancel
                        </button>
                        <button disabled={!unsavedData} onClick={submitReportConfig} className="btn btn-blue">
                            Submit report config
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default ReportDesigner;
