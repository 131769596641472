import { useEffect, useState, useCallback } from 'react';
import api from '../../utility/api';
import store from '../..';

/**
 * @typedef {Object} ReportImage
 *
 * @property {string} filename
 * @property {string} imageUrl
 *
 * @typedef {Object} ReportImagesState
 *
 * @property {boolean} isLoading
 * @property {ReportImage[] | undefined} images
 * @property {() => Promise<void>} refetch
 *
 * @returns {ReportImagesState}
 */
export const useReportImages = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState();
    const state = store.getState();
    const plantKey = state.plants.currentAdminPlant?.id;

    const fetchImages = useCallback(async (plantKey) => {
        setIsLoading(true);
        const imagesUrls = (await api.get(`api/reportlogo/${plantKey}`)).data;
        const images = imagesUrls.map((imageUrl) => ({ filename: getFileNameFromUrl(imageUrl), imageUrl }));

        setImages(images);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (plantKey) {
            fetchImages(plantKey);
        }
    }, [fetchImages, plantKey]);

    const refetch = useCallback(() => {
        if (plantKey) {
            fetchImages(plantKey);
        }
    }, [fetchImages, plantKey]);

    return { images, isLoading, refetch };
};
const getFileNameFromUrl = (url) => {
    const urlParts = url.split('/');
    return urlParts.at(-1);
};
