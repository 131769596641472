import React from 'react';
import { connect } from 'react-redux';
import { setCurrentPlant, clearCurrentPlant, setPlantList } from '../../actions';
import api from '../../utility/api';

import PlantTable from '../../components/common/Table/PlantTable';
import './plantlist.css';

class PlantList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plants: undefined,
            isVisible: false,
        };
    }

    componentDidUpdate() {
        this.props.clearCurrentPlant();
        if (this.props.user && !this.props.plants) {
            this.fetchPlantData();
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.fetchPlantData();
        }, 5 * 60 * 1000);

        if (this.props.user) {
            this.fetchPlantData();
        }

        this.props.clearCurrentPlant();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    fetchPlantData() {
        api.get(`api/plants`)
            .then((res) => {
                this.props.setPlantList(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    render() {
        if (!this.props.plants) {
            return <div></div>;
        }
        return (
            <div className="content-grid-plant">
                <div className="plantlist-grid">
                    <PlantTable plants={this.props.plants.filter((p) => p.plantTypeName !== 'Locality')} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plant,
    user: state.user.currentUser,
    plants: state.plants.plantList,
});

const mapDispatchToProps = {
    setCurrentPlant,
    clearCurrentPlant,
    setPlantList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantList);

// export default PlantList;
