import { CLEAR_CURRENT_USER, SET_CURRENT_USER } from '../actions';

const user = (
    state = {
        currentUser: undefined,
    },
    action
) => {
    switch (action.type) {
        case CLEAR_CURRENT_USER:
            return {
                ...state,
                currentUser: null,
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.data,
            };
        default:
            return state;
    }
};

export default user;
