export const SET_CHART_INFO = 'SET_CHART_INFO';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_PERIOD = 'SET_PERIOD';
export const CLEAR_DATE_RANGE = 'CLEAR_DATE_RANGE';
export const GET_CHART_INFO_REQ = 'GET_CHART_INFO_REQ';
export const GET_CHART_INFO_SUCC = 'GET_CHART_INFO_SUCC';
export const GET_CHART_INFO_FAIL = 'GET_CHART_INFO_FAIL';
export const SET_WIDGET = 'SET_WIDGET';
export const GET_WIDGET = 'GET_WIDGET';
export const SET_DASHBOARD_CONFIG = 'SET_DASHBOARD_CONFIG';
export const GET_DASHBOARD_CONFIG = 'GET_DASHBOARD_CONFIG';

export const setChartInfo = (data, id) => {
    return {
        type: SET_CHART_INFO,
        id: id,
        data: data,
    };
};

export const getChartInfoReq = (itemId, token, dateRange, timeRange) => {
    return {
        type: GET_CHART_INFO_REQ,
        itemId: itemId,
        dateRange: dateRange,
        timeRange: timeRange,
        token: token,
    };
};

export const getChartInfoSucc = (chartInfo) => {
    return {
        chartResult: chartInfo,
    };
};

export const setEndDate = (data, id, parent) => {
    return {
        type: SET_END_DATE,
        id: id,
        parent: parent,
        data: data,
    };
};

export const setPeriod = (data, id, parent) => {
    return {
        type: SET_PERIOD,
        id: id,
        parent: parent,
        data: data,
    };
};

export const clearDateRange = () => {
    return {
        type: CLEAR_DATE_RANGE,
    };
};

export const setWidget = (data) => {
    return {
        type: SET_WIDGET,
        data: data,
    };
};

export const getWidget = (data) => {
    return {
        type: GET_WIDGET,
        widget: data,
    };
};

export const setDashboardConfig = (data) => {
    return {
        type: SET_DASHBOARD_CONFIG,
        data: data,
    };
};

export const getDashboardConfig = (data) => {
    return {
        type: GET_DASHBOARD_CONFIG,
        dashboardConfig: data,
    };
};
