import React from 'react';
import PropTypes from 'prop-types';
import '../widget.css';
import './ProgressWheel.scss';
import { getTimeSince } from '../../../utility/timesince';

class ProgressWheel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
        this.wheel = React.createRef();
    }

    componentDidUpdate(_nextProps) {
        const wheel = this.refs.wheel;

        if (wheel) {
            const ctx = wheel.getContext('2d');

            const max = this.props.configuration.maxValue;
            const min = this.props.configuration.minValue;
            const current = this.props.data.data.value;
            const threshold = this.props.configuration.threshold;

            const total = max - min;

            let currentCalc = current;
            if (current < 0 || min < 0) {
                currentCalc = (min - current) * -1;
            }

            let currentPercent = current != null ? (currentCalc / total) * 100 : 0;
            if (currentPercent > 100) {
                currentPercent = 100;
            } else if (currentPercent < 0) {
                currentPercent = 0;
            }

            let progressFillStart = 'rgba(65, 205, 245, 0)';
            let progressFillStop = 'rgba(65, 205, 245, 1)';

            if (threshold && (current > threshold.alertHigh || current < threshold.alertLow)) {
                progressFillStart = 'rgba(255, 0, 85, 0)';
                progressFillStop = 'rgba(255, 0, 85, 1)';
            } else if (threshold && (current > threshold.warningHigh || current < threshold.warningLow)) {
                progressFillStart = 'rgba(201, 181, 78, 0)';
                progressFillStop = 'rgba(201, 181, 78, 1)';
            }

            const calcEnd = ((2 / 100) * currentPercent + 0.5) * Math.PI;

            const x = 26,
                y = 26,
                radius = 21,
                angleStart = 0.5 * Math.PI,
                angleEnd = calcEnd;

            // Create gradient
            const gradient = ctx.createLinearGradient(0, 60, 0, 0);
            gradient.addColorStop(0, progressFillStart);
            gradient.addColorStop(1, progressFillStop);

            // Draw circle
            ctx.beginPath();
            ctx.arc(x, x, radius, 0 * Math.PI, 2 * Math.PI, false);
            ctx.lineWidth = 10;
            ctx.strokeStyle = '#1C2B32';
            ctx.stroke();

            // Draw arc
            ctx.beginPath();
            ctx.arc(x, y, radius, angleStart, angleEnd);
            ctx.strokeStyle = gradient;
            ctx.lineWidth = 10;
            ctx.stroke();

            // Draw end
            ctx.beginPath();
            ctx.arc(x, x, radius, angleEnd, angleEnd + 0.05);
            ctx.lineWidth = 10;
            ctx.strokeStyle = '#ECECEC';
            ctx.stroke();
        }
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.mounted = false;
    }

    kFormatter = (num) => {
        return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
    };

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    render() {
        const res = this.props.data;
        if (!res) {
            return <div></div>;
        }
        res.configuration = this.props.configuration;

        console.log('r', res);

        const max = res.configuration.maxValue;
        const current = res.data.value;

        return (
            <div className="widget">
                <span className="time-since">{getTimeSince(res)}</span>

                <div className="widget-content progress-wheel">
                    <div className="gauge-wrapper">
                        <canvas ref="wheel" width="52" height="52"></canvas>
                    </div>
                    <div className="data-wrapper">
                        <div className="data-title">
                            <span className="data-title__label">{this.props.title}</span>
                        </div>
                        <div className="data">
                            <div className="data-value">
                                <span className="data-value__number">{current ? current.toFixed(1) : 0}</span>
                            </div>
                            <div className="data-units">
                                <span className="data-units__total">/{max}</span>
                                <span className="data-units__unit">{res.configuration.label}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProgressWheel.propTypes = {
    itemId: PropTypes.string,
};

export default ProgressWheel;
