import React from 'react';
import { useLocation } from 'react-router-dom';
/**
 * Higher order component that adds location to component
 * @param {import('react').ReactElement} Component
 * @returns
 */
export function withLocation(Component) {
    return function LocationWrapper(props) {
        const location = useLocation();
        return <Component {...props} location={location} />;
    };
}
