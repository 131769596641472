import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import { overlayStyle, dropzoneStyle } from '../../utility/dropzoneStyle';
import Dropdown from 'react-dropdown';
import { NotificationManager } from 'react-notifications';
import ReactCrop from 'react-image-crop';
import { useDebounceEffect } from './useDebouncedEffect';
import { imgPreview } from './imagePreview';
import { Stack } from '../common/Stack';
import { isEmpty } from 'lodash';

const accept = ['image/png', 'image/jpg', 'image/jpeg'];

/**
 * @callback OnImagePickerSubmit
 * @param {string} imageBlogUrl
 * @param {string} filename
 * @return {Promise<void>}
 *
 * @callback OnClose
 * @return {void}
 *
 * @typedef {Object} ImagePickerModalProps
 * @property {bool} isOpen
 * @property {OnImagePickerSubmit} onSubmit
 * @property {OnClose} onClose
 * @property {ImageSize} imageSize
 * @property {string} label
 * @property {string} imageUrl
 * @property {boolean} isLoadingImages
 * @property {import('./useReportImages'.ReportImage)[] | undefined} images
 *
 * @param {ImagePickerModalProps} props
 * @returns
 */
export const ImagePickerModal = ({
    isOpen,
    onSubmit,
    onClose,
    onImageChange,
    imageSize,
    imageUrl,
    images,
    isLoadingImages,
    imageType,
    ...props
}) => {
    const [image, setImage] = useState();
    const [fileNameInput, setFileNameInput] = useState('');
    const [crop, setCrop] = useState();
    const [cropCompleted, setCropCompleted] = useState();
    const [dropdownValue, setDropdownValue] = useState();
    const imageSourceRef = useRef(null);
    const imagePreviewSrc = usePreviewCropped({ crop: cropCompleted, imageSource: imageSourceRef.current });

    const isImageEdited = imagePreviewSrc !== undefined;
    const resetState = () => {
        setImage(undefined);
        setCrop(undefined);
        setCropCompleted(undefined);
    };

    const options = useMemo(
        () =>
            isLoadingImages
                ? []
                : images
                      ?.filter((imageDetails) => imageDetails.imageUrl.toLowerCase().includes(imageType.toLowerCase()))
                      ?.map((imgDetails) => ({ value: imgDetails.imageUrl, label: imgDetails.filename })),
        [images, isLoadingImages]
    );
    useEffect(() => {
        if (options?.length > 0 && imageUrl) {
            const currentImage = options.find((option) => option.value === imageUrl);
            setDropdownValue(currentImage);
            setFileNameInput(currentImage?.label ?? '');
        }
    }, [options, imageUrl]);

    const handleOnDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles?.length < 1) {
            NotificationManager.error('File type not supported', 'File error', 5000);
            return;
        }

        if (acceptedFiles?.length > 1) {
            NotificationManager.error('Only one file can be uploaded', 'Too many files', 5000);
            return;
        }
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
            setImage(reader.result.toString() || '');
        };
        if (acceptedFiles.length > 0) {
            reader.readAsDataURL(acceptedFiles[0]);
        }
        setFileNameInput('');
    }, []);

    const handleSave = () => {
        if (isImageEdited) {
            return onSubmit(imagePreviewSrc, fileNameInput);
        }
        return onImageChange(image);
    };

    const handleImageChange = (v) => {
        setDropdownValue(v);
        setImage(v.value);
        setFileNameInput(v.label);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {
                resetState();
                onClose();
            }}
            onAfterClose={resetState}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
            className={'auto_modal_content'}
        >
            <h2>{props.label}</h2>
            <Stack>
                {(image || imageUrl) && (
                    <ReactCrop
                        crop={crop}
                        onChange={setCrop}
                        aspect={getAspect(imageSize)}
                        onComplete={(c) => {
                            setCropCompleted(c);
                        }}
                    >
                        <img
                            src={image ?? imageUrl}
                            alt="uploaded"
                            crossOrigin="anonymous"
                            ref={imageSourceRef}
                            style={{ maxWidth: imageSize.width, width: '100%', minWidth: '400px' }}
                        />
                    </ReactCrop>
                )}
                {imagePreviewSrc && (
                    <img
                        alt="Crop preview"
                        src={imagePreviewSrc}
                        width={imageSize.width}
                        height={imageSize.height}
                        style={{ marginTop: '1rem' }}
                    />
                )}
            </Stack>
            <h3>Choose from your pictures</h3>
            <Dropdown
                options={options}
                onChange={handleImageChange}
                value={dropdownValue}
                placeholder="Select an option"
            />
            <Dropzone onDrop={handleOnDrop} accept={accept}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                        <div {...getRootProps()} style={dropzoneStyle}>
                            Drop files here or click to upload
                            <input {...getInputProps()} />
                            {isDragActive && <div style={overlayStyle}></div>}
                        </div>
                    );
                }}
            </Dropzone>
            <div className="react-confirm-alert-body">
                <h1>Upload</h1>
                <p>Are you sure you want to save these changes?</p>
                <div className="form-group">
                    <label htmlFor="name">
                        Filename
                        <input
                            className="input-control"
                            type="text"
                            name="name"
                            value={fileNameInput}
                            onChange={(e) => setFileNameInput(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <div className="buttons">
                    <button className="btn btn-yellow" onClick={onClose}>
                        No, cancel
                    </button>
                    <button
                        className="btn btn-blue"
                        onClick={handleSave}
                        disabled={!(imagePreviewSrc || image === dropdownValue?.value) || isEmpty(fileNameInput)}
                    >
                        Yes, save
                    </button>
                </div>
            </div>
        </Modal>
    );
};
ImagePickerModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
};

/**
 * @typedef {Object} ImageSize
 * @property {string} width Width in pixels
 * @property {string} height Height in pixels
 */

/**
 *
 * @param {ImageSize} imageSize
 */
const getAspect = ({ width, height }) => {
    if (!width.endsWith('px') || !height.endsWith('px')) {
        throw new Error('Values must be string ending in px');
    }

    return width.slice(0, -2) / height.slice(0, -2);
};

/**
 * @typedef {Object} usePreviewCroppedOptions
 * @property {HTMLImageElement | null} imageSource
 * @property {import('./canvasPreview.js').PixelCrop} crop
 */

/**
 *
 * @param {usePreviewCroppedOptions} props
 * @returns {string | undefined} blob url
 */
const usePreviewCropped = ({ crop, imageSource }) => {
    const [imagePreviewSrc, setImagePreviewSrc] = useState();

    const getImagePreview = useCallback(async () => {
        if (imageSource !== null && crop !== undefined) {
            const ip = await imgPreview(imageSource, crop);
            return setImagePreviewSrc(ip);
        }
        return setImagePreviewSrc(undefined);
    }, [crop, imageSource]);

    useDebounceEffect(getImagePreview, 100, [crop]);

    return imagePreviewSrc;
};
