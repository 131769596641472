import React from 'react';
import { ReactComponent as GreenIcon } from '../../../images/icons/green_circle.svg';
import { ReactComponent as RedIcon } from '../../../images/icons/red_circle.svg';
import { ReactComponent as YellowIcon } from '../../../images/icons/yellow_circle.svg';
import { ReactComponent as GrayIcon } from '../../../images/icons/gray_circle.svg';
import { ReactComponent as NoColorIcon } from '../../../images/icons/no_color_circle.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../images/icons/question_mark.svg';
import './DatavalueColorIndicator.scss';

const DatavalueColorIndicator = () => {
    return (
        <div className="wrapper">
            <div className="question">
                <QuestionMarkIcon />
            </div>
            <div className="color-desc-modal">
                <span className="color-desc-modal-header-text">Color Definitions:</span>
                <div className="color-desc-modal-color-definition">
                    <GreenIcon /> <span>All datavalues on topics updated within the last 48h.</span>
                </div>
                <div className="color-desc-modal-color-definition">
                    <YellowIcon /> <span>All datavalues on topic updated between 48h and 1 month.</span>
                </div>
                <div className="color-desc-modal-color-definition">
                    <RedIcon /> <span>Datavalues older than 1 month.</span>
                </div>
                <div className="color-desc-modal-color-definition">
                    <GrayIcon /> <span>One or more datavalues configured but not existing/present.</span>
                </div>
                <div className="color-desc-modal-color-definition">
                    <NoColorIcon /> <span> No datavalues configured, and no datavalues present.</span>
                </div>
            </div>
        </div>
    );
};

export default DatavalueColorIndicator;
