import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { create, all } from 'mathjs';
import api from '../../utility/api';
import './adminformulas.scss';
import { defaultFormula } from '../../utility/widgetformulamanager';
import { noop } from 'lodash';

class AdminFormulasAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plants: [],
            plantTypes: [],
            nameValid: false,
            formValid: false,
            isDirty: false,
            checkedItems: [],
            formula: { formula: defaultFormula },
            testVal: undefined,
            testResult: undefined,
            errResult: undefined,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.testFormula = this.testFormula.bind(this);
    }

    handleChange(event) {
        this.setState({
            isDirty: true,
            userAdded: false,
        });
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = this.state.formula;
        tmp[name] = value;

        this.setState({ formula: tmp });
    }

    componentDidMount() {
        if (this.props.editFormula) {
            this.setState({
                formula: this.props.editFormula,
                mode: 'edit',
            });
        }
    }

    handleSubmit(event) {
        if (this.state.mode === 'edit') {
            this.updateSubmit(event);
        } else {
            this.createSubmit(event);
        }
    }

    createSubmit(event) {
        event.preventDefault();

        if (!this.testFormula(event, 'submit')) return;
        if (this.props.allFormulas.map((n) => n.name).indexOf(this.state.formula.name) > -1) {
            this.setState({
                errResult: 'Formula name already exists',
            });
            return;
        }

        api.post('/api/widgetformulas', this.state.formula)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Formula created', 'Formula successfully created', 5000);
                    const tmp = this.state.formula;
                    tmp.name = '';
                    tmp.formula = '';

                    this.setState(
                        {
                            isDirty: false,
                            formValid: false,
                            formula: tmp,
                        },
                        this.props.editedCallback(response)
                    );
                } else {
                    NotificationManager.error('Error', 'Failed to create formula', 5000);
                    throw new Error(response.status);
                }
            })
            .then((res) => {
                this.props.addedCallback(res);
            })
            .catch(noop);
    }

    updateSubmit(event) {
        event.preventDefault();

        if (!this.testFormula(event, 'submit')) return;
        if (this.props.allFormulas.map((n) => n.name).indexOf(this.state.formula.name) > -1) {
            this.setState({
                errResult: 'Formula name already exists',
            });
            return;
        }

        api.put(process.env.REACT_APP_API + '/api/widgetformulas', this.state.formula)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Formula updated', 'Formula successfully updated', 5000);
                    this.props.editedCallback(response.data);
                } else {
                    NotificationManager.error('Error', 'Failed to update formula', 5000);
                    // throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    renderButton() {
        let button;

        if (this.state.mode === 'edit') {
            button = (
                <button className="btn btn-blue btn-grow" value="Submit">
                    Save changes
                </button>
            );
        } else {
            button = (
                <button className="btn btn-blue btn-grow" value="Submit">
                    Create
                </button>
            );
        }

        return button;
    }

    testFormula(e, mode) {
        e.preventDefault();
        const math = create(all);
        try {
            this.setState({
                errResult: null,
                testResult:
                    'Result: ' +
                    math
                        .evaluate(
                            this.state.formula.formula.replaceAll(
                                defaultFormula,
                                mode && mode === 'submit' ? 1 : this.state.testVal
                            )
                        )
                        .toFixed(2),
            });

            return true;
        } catch (ex) {
            console.error(ex.message);
            let errMsg = 'Invalid formula: ' + ex.message;
            if (isNaN(mode && mode === 'submit' ? 1 : this.state.testVal)) {
                errMsg = 'Test value must be set to a number';
            }

            this.setState({
                errResult: errMsg,
                testResult: null,
            });

            return false;
        }
    }

    render() {
        return (
            <div className="admin-plant-add-edit-form">
                {this.state.mode === 'edit' ? <h1>Edit formula</h1> : <h1>New formula</h1>}
                <form style={{ width: '100%' }} onSubmit={this.handleSubmit}>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            required
                            name="name"
                            value={this.state.formula.name || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>

                    <div>
                        <label htmlFor="formula">Formula:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            required
                            name="formula"
                            value={this.state.formula.formula || defaultFormula}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>

                    <div className="test-area">
                        <div style={{ maxWidth: '30%' }}>
                            <label htmlFor="test-value">Test value</label>
                            <input
                                name="test-value"
                                placeholder="Enter test value (numeric)"
                                className="admin-input-control"
                                type="number"
                                onChange={(e) => this.setState({ testVal: e.target.value })}
                            />
                        </div>
                        <button onClick={this.testFormula} className="btn btn-blue">
                            Test formula
                        </button>
                        {this.state.testResult ? <span className={'testresult'}>{this.state.testResult}</span> : null}
                        {this.state.errResult ? <span className={'testresult red'}>{this.state.errResult}</span> : null}
                    </div>

                    <div className="btn-row btn-row-grow">
                        <button className="btn btn-yellow btn-grow" onClick={this.props.cancelCallback}>
                            Cancel
                        </button>

                        {this.renderButton()}
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminFormulasAddEdit);
