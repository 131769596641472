import React from 'react';
import { useRouteMatch } from 'react-router-dom';
/**
 * Higher order component that adds RouteMatch to component
 * @param {import('react').ReactElement} Component
 * @returns
 */
export function withRouteMatch(Component) {
    return function RouteMatchWrapper(props) {
        const match = useRouteMatch();
        return <Component match={match} {...props} />;
    };
}
