import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import './DateRangePicker.scss';

import 'react-datepicker/dist/react-datepicker.css';
const DateRangePicker = ({ value, onChange, isClearable = false }) => {
    const [startDate, endDate] = value;

    const CustomInput = forwardRef(({ onClick, onChange }, ref) => (
        <div className="input-wrapper">
            <input
                value={
                    (startDate ? `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}` : '') +
                    (endDate ? ` - ${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}` : '')
                }
                onClick={onClick}
                onChange={onChange}
                ref={ref}
                placeholder={'Filter Date'}
            ></input>
            <span className="fa fa-calendar" title={'Filter Date'} onClick={onClick}></span>
        </div>
    ));
    return (
        <div className="date-picker-wrapper">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    let [startDate, endDate] = update;

                    if (endDate) {
                        endDate.setHours(23, 59, 59);
                    }
                    onChange([startDate, endDate]);
                }}
                isClearable={isClearable}
                monthsShown={1}
                customInput={<CustomInput />}
            />
        </div>
    );
};

export default DateRangePicker;
