import React from 'react';

const EarthIcon = ({ stroke }) => {
    return (
        <svg
            width="22px"
            height="22px"
            viewBox="0 0 22 22"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/no-unknown-property
            xlink="http://www.w3.org/1999/xlink"
        >
            <defs></defs>
            <g id="Nivå-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Forslag" transform="translate(-231.000000, -28.000000)" stroke="#68B4C9">
                    <g id="Group-7" className="nav-icon" transform="translate(232.000000, 29.000000)" stroke={stroke}>
                        <circle id="Oval-13" cx="10" cy="10" r="10"></circle>
                        <path
                            d="M4.42934783,1.76630435 C6.73007246,3.84963768 5.28076172,6.93837041 0.081415591,11.0325025"
                            id="Path-13"
                        ></path>
                        <path
                            d="M0.597826087,13.0163043 C2.5002123,12.3642012 3.9948129,12.2283316 5.08162789,12.6086957 C6.71185037,13.1792417 7.66314963,16.2770678 7.11945907,17.2553286 C6.7569987,17.9075025 6.7569987,18.7227553 7.11945907,19.701087"
                            id="Path-14"
                        ></path>
                        <path
                            d="M3.69554603,17.7988069 C4.22085527,16.8569194 4.22085527,16.0870273 3.69554603,15.4891304 C3.17023678,14.8912336 2.40034463,14.7915959 1.38586957,15.1902174"
                            id="Path-15"
                        ></path>
                        <path
                            d="M11.820546,0.271632982 C12.5996731,2.1196006 12.110578,3.17036062 10.3532609,3.42391304 C7.71728516,3.80424168 8.50532863,7.11945907 10.7880435,6.79347826 C13.0707583,6.46749745 15.2448837,4.83706267 15.8424975,6.33152174 C16.4401112,7.82598081 15.5976138,9.67370075 13.1792417,9.45641559 C10.7608696,9.23913043 8.69565217,9.21195652 8.69565217,10.6793478 C8.69565217,12.1467391 10.4075025,12.9076087 12.0108696,12.9076087 C13.6142366,12.9076087 11.9293478,17.7717391 13.3695652,17.9076087 C14.8097826,18.0434783 16.6847826,16.0326087 16.5488069,14.673913 C16.4128312,13.3152174 18.1248939,13.1792417 18.3422852,12.6901112 C18.5596765,12.2009808 18.0977199,11.2227199 16.0868504,11.5759808 C14.0759808,11.9292417 15.2987007,10.1629373 16.5488069,9.80967646 C17.798913,9.45641559 19.2120627,8.12510615 19.6467391,7.33695652"
                            id="Path-16"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default EarthIcon;
