import React from 'react';
import { connect } from 'react-redux';
import { setReportList, setCurrentPlant, clearReportList } from '../../actions';
import * as moment from 'moment';
import './reportlist.scss';
import FontAwesome from 'react-fontawesome';
import Spinner from '../../components/common/Spinner/Spinner';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import '../list/generic-list.scss';
import api from '../../utility/api';

class ReportList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: undefined,
            isVisible: false,
            downloading: [],
        };

        this.mounted = true;
        this.fetching = false;
        this.filter = this.filter.bind(this);
        this.search = this.search.bind(this);
    }

    componentDidUpdate() {
        if (this.props.plant && this.props.user && this.props.reports === undefined && !this.fetching) {
            this.fetchReportData();
        }
    }

    componentDidMount() {
        if (this.props.user !== undefined && this.props.reports === undefined) {
            this.fetchReportData();
        }
    }

    componentWillUnmount() {
        this.props.clearReportList();
        this.mounted = false;
        clearInterval(this.interval);
    }

    fetchReportData() {
        let url = `${process.env.REACT_APP_API}/api/edgeReports?topicKey=${this.props.plant.topicKey}&?itemId=${this.props.itemId}`;
        if (!this.props.itemId || this.props.itemId === '00000000-0000-0000-0000-000000000000') {
            url = `${process.env.REACT_APP_API}/api/edgeReports?topicKey=${this.props.plant.topicKey}`;
        }
        this.fetching = true;

        api.get(url)
            .then((res) => {
                this.fetching = false;
                if (this.mounted) {
                    this.props.setReportList(res.data);
                    this.forceUpdate();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    startReportGeneration(reportId) {
        if (this.props.user.profile.reportreader === 'true') {
            const tmp = this.state.downloading;
            tmp.push(reportId);

            this.setState({
                downloading: tmp,
            });
            import('../../components/PdfGeneration/PdfGenerator').then((pdfGen) => {
                const generateReport = pdfGen.default;
                generateReport(reportId, this.props.user, (err) => {
                    if (err) {
                        NotificationManager.error("Couldn't generate report.", 'Internal Error', 5000);
                    }
                    console.error(err);
                    const tmp = this.state.downloading;
                    tmp.splice(tmp.indexOf(reportId), 1);
                    this.setState({
                        downloading: tmp,
                    });
                });
            });
        } else {
            NotificationManager.error('You do not have permissions to read reports', 'Permission error', 5000);
        }
    }

    filter() {
        if (!this.state.filterString || this.state.filterString.length < 1) {
            return this.props.reports;
        }
        const fString = this.state.filterString.toLowerCase();

        // eslint-disable-next-line
        return this.props.reports.filter((o) => {
            // eslint-disable-next-line
            return Object.keys(o).some((k) => {
                if (k === 'createdAt') {
                    return new Date(o[k]).toString().toLowerCase().includes(fString.toLowerCase());
                }
                if (typeof o[k] === 'string' || typeof o[k] === 'number') {
                    return String(o[k]).toLowerCase().includes(fString.toLowerCase());
                }
            });
        });
    }

    search(e) {
        const filter = e.target.value.trimStart();
        this.setState({
            filterString: filter,
        });
    }

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    confirmDeleteReport(e, id, title) {
        e.stopPropagation();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete Report</h1>
                        <p>
                            You are about to delete a report
                            <br />{' '}
                        </p>
                        <span>
                            <b>{title}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteReport(id);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteReport(id) {
        api.delete('api/edgeReports/' + id)
            .then(() => {
                NotificationManager.success('Report deleted', 'Success');
                this.fetchReportData();
            })
            .catch(() => {
                NotificationManager.error('Could not delete report', 'Error');
            });
    }

    render() {
        let reports = null;
        if (this.props.reports && this.props.reports.length < 1) {
            return (
                <div className="reportlist-grid">
                    <div className="main-header">
                        {this.check() ? (
                            <span>
                                <h2>{reports ? reports.length : 0} Reports</h2>
                            </span>
                        ) : null}
                    </div>
                    <div className="table-tools">
                        <div className="list-search">
                            <input
                                autoComplete="off"
                                type="text"
                                placeholder="Search"
                                className="search-list"
                                onChange={this.search}
                            />
                            <FontAwesome className="search-icon" name="search" />
                        </div>
                    </div>
                    <div className="list">
                        <div className="list-items-header">
                            <span className="list-item-date">Date</span>
                            <span className="list-item-title">Report</span>
                            {this.props.user && this.props.user.profile.reportreader ? (
                                <div className="list-item-title"></div>
                            ) : null}
                        </div>
                        <center style={{ marginTop: '30px' }}>
                            <h3>No reports available</h3>
                        </center>
                    </div>
                </div>
            );
        }

        if (!this.fetching && this.props.reports) {
            reports = this.props.reports.sort(function (a, b) {
                a = new Date(a.createdAt);
                b = new Date(b.createdAt);
                return a > b ? -1 : a < b ? 1 : 0;
            });

            reports = this.filter();
            reports = reports.filter((x) => x.reportType !== '51c004ab-7546-4210-85a4-4d8f7bf48653'); // Temporarily hide stun and bleed reports
        }

        //console.log(reports);
        return (
            <div className="reportlist-grid">
                <div className="main-header">
                    {this.check() ? (
                        <span>
                            <h2>{reports ? reports.length : 0} Reports</h2>
                        </span>
                    ) : null}
                </div>
                <div className="table-tools">
                    <div className="list-search">
                        <input
                            autoComplete="off"
                            type="text"
                            placeholder="Search"
                            className="search-list"
                            onChange={this.search}
                        />
                        <FontAwesome className="search-icon" name="search" />
                    </div>
                </div>

                <div className="list">
                    <div className="list-items-header">
                        <span className="list-item-date">Date</span>
                        <span className="list-item-title">Report</span>
                        {this.props.user && this.props.user.profile.reportreader ? (
                            <div className="list-item-title"></div>
                        ) : null}
                    </div>
                    {this.fetching ? (
                        <Spinner text="reports" />
                    ) : reports ? (
                        reports.map((report, i) => {
                            return (
                                <div key={'report-' + i} className={'list-item'}>
                                    <div className="list-item-date">
                                        {moment.utc(report.createdAt).local().format('DD MMM YYYY HH:mm')}
                                    </div>

                                    <div className="list-item-title">
                                        {this.check() ? (
                                            report.description && report.description !== '' ? (
                                                report.description
                                            ) : (
                                                'Optilice Delousing Report ' +
                                                moment
                                                    .utc(report.report_date || report.createdAt)
                                                    .local()
                                                    .format('DD MMM YYYY HH:mm')
                                            )
                                        ) : (
                                            <span className="reportlist-link">
                                                {report.description && report.description !== ''
                                                    ? report.description
                                                    : 'Optilice Delousing Report ' +
                                                      moment
                                                          .utc(report.report_date || report.createdAt)
                                                          .local()
                                                          .format('DD MMM YYYY HH:mm')}
                                            </span>
                                        )}
                                    </div>

                                    {this.props.user.profile.reportreader ? (
                                        <div className="list-item-title">
                                            <div className="btn-row-grow">
                                                {this.props.user &&
                                                this.check() &&
                                                this.props.user.profile.reportreader ? (
                                                    !this.state.downloading.includes(report.id) ? (
                                                        <button
                                                            className="btn btn-blue"
                                                            onClick={() => {
                                                                this.startReportGeneration(report.id);
                                                            }}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-blue">
                                                            <FontAwesome
                                                                name="spinner"
                                                                className="download-icon"
                                                                spin
                                                            />{' '}
                                                            Downloading..
                                                        </button>
                                                    )
                                                ) : null}
                                                <button
                                                    className="btn btn-red"
                                                    onClick={(e) =>
                                                        this.confirmDeleteReport(e, report.id, report.description)
                                                    }
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plants.currentPlant,
    user: state.user.currentUser,
    isAdmin: state.user.currentUser.isAdmin,
    reports: state.reports.reportList,
});

const mapDispatchToProps = {
    setReportList,
    setCurrentPlant,
    clearReportList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportList);
