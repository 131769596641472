import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { clearCurrentPlant, setCurrentItem, setCurrentPlant, setNotificationList } from '../../../actions';
import Logo from '../Logo/Logo';
import NotificationBell from '../Notification/NotificationBell';
import ProfileDropdown from '../Profile/ProfileDropdown';
import './Header.scss';
import PlantnameDropdown from './PlantnameDropdown';
import { ReactComponent as MapIcon } from '../../../images/icons/optimar_map_blue.svg';
import { withParams } from '../../../HOC/withParams';
import { ReactComponent as ListIcon } from '../../../images/icons/optimar_list_blue.svg';
import { ReactComponent as SupportIcon } from '../../../images/optimar-support.svg';
import api from '../../../utility/api';
import { withRouteMatch } from '../../../HOC/withRouteMatch';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plantName: undefined,
            dropdownVisible: false,
            alerts: [],
            imgKey: 1,
            imgRef: React.createRef(),
        };
        this.fetching = false;
        this.handlePlantnameVisible = this.handlePlantnameVisible.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) === JSON.stringify(this.props)) return;

        try {
            if (this.props.match.params.itemId !== prevProps.match.params.itemId) {
                this.props.setCurrentItem(this.props.match.params.itemId);
            }

            if (this.props.user && this.props.match.params.plantId && !prevProps.currentPlant) {
                this.getPlantName(this.props.match.params.plantId, this.props.user);
                //this.fetchAlerts(); // TODO tmp for å hente alerts uten signalr hub
            } else if (!this.props.match.params.plantId) {
                this.props.clearCurrentPlant();
            } else if (this.props.user && this.props.match.params.plantId !== prevProps.match.params.plantId) {
                this.getPlantName(this.props.match.params.plantId, this.props.user);
            }
        } catch (err) {
            console.log('header error', err);
        }
        //this.triggerImageReloadAfterInterval();
        //this.subscribeImageReload();
    }
    componentDidMount() {
        //const myKey = "header_redoicons";
        //unSubscribeSilentReauthSuccess(myKey);
        //this.subscribeImageReloadOnReauth();
    }
    componentWillUnmount() {
        if (this.state.myReAuthKey) {
            //console.log("unsubscribing",this.state.myReAuthKey);
            //unSubscribeSilentReauthSuccess(this.state.myReAuthKey);
        }
    }

    onClick = () => {
        this.props.signOut();
    };
    fetchAlerts() {
        if (this.props.user) {
            api.get(process.env.REACT_APP_API + '/api/userNotifications')
                .then((res) => {
                    this.props.setNotificationList(res.data);
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    getPlantName(plantId, _user) {
        if (this.fetching) return;
        this.fetching = true;
        api.get(process.env.REACT_APP_API + '/api/plants/' + plantId + '/sidepanel')
            .then((res) => {
                this.fetching = false;
                this.props.setCurrentPlant(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    handlePlantnameVisible(val) {
        if (this.props.match.params.plantId) {
            this.setState({
                dropdownVisible: val,
            });
        }
    }

    navlinkIsActiveAccount(match, location) {
        //console.log(match, location);
        if (location.pathname.includes('account') && !location.pathname.includes('admin')) return true;
        return false;
    }

    isDialogFrame = () => {
        return window.location.pathname.indexOf('/frame/') >= 0;
    };

    render() {
        const isInFrame = this.isDialogFrame();
        return !this.props.isMobile || this.props.isTablet ? (
            <ul className="header">
                <li onClick={() => this.handlePlantnameVisible(false)} className="left-header">
                    <Logo key={`logo_${this.state.imgKey}`} clickable={!isInFrame} />
                    {isInFrame ? null : (
                        <>
                            <NavLink exact={true} activeClassName="link-active" className="home" to="/">
                                {/* <img key={`map_${this.state.imgKey}`} src={Map} alt="Map" 
                                /> */}
                                <MapIcon key={`map_${this.state.imgKey}`} alt="Map"></MapIcon>
                            </NavLink>
                            <NavLink
                                isActive={this.navlinkIsActiveAccount}
                                activeClassName="link-active"
                                className="home"
                                to="/account/plant"
                            >
                                {/* <img  key={`list_${this.state.imgKey}`} src={ListIcon} alt="List" 
                                /> */}
                                <ListIcon key={`list_${this.state.imgKey}`} alt="List"></ListIcon>
                            </NavLink>
                        </>
                    )}
                </li>
                <PlantnameDropdown
                    handleVisible={this.handlePlantnameVisible}
                    visible={this.state.dropdownVisible}
                    plantName={this.props.currentPlant ? this.props.currentPlant.name : ''}
                    plantId={this.props.match ? this.props.match.params.plantId : '/'}
                />
                <li className="rightmenu">
                    {isInFrame ? null : (
                        <>
                            <div className="header-support">
                                <SupportIcon key={`support_${this.state.imgKey}`} className="support-icon" />
                            </div>
                            <NotificationBell
                                key={`notification_${this.state.imgKey}`}
                                notifications={this.props.notifications}
                            />
                            <ProfileDropdown key={`profile_${this.state.imgKey}`} signOut={this.onClick} />
                        </>
                    )}
                </li>
            </ul>
        ) : (
            <ul className="header">
                <li onClick={() => this.handlePlantnameVisible(false)} className="left-header">
                    <Logo source={this.props.logo} />
                </li>
                <PlantnameDropdown
                    handleVisible={this.handlePlantnameVisible}
                    visible={this.state.dropdownVisible}
                    plantName={this.props.currentPlant ? this.props.currentPlant.name : ''}
                    plantId={this.props.match ? this.props.match.params.plantId : '/'}
                />
            </ul>
        );
    }
}

const mapStateToProps = (state) => ({
    currentPlant: state.plants.currentPlant,
    user: state.user.currentUser,
    isMobile: state.utility.isMobile,
    isTablet: state.utility.isTablet,
    notifications: state.notifications.notificationList,
});

const mapDispatchToProps = {
    setCurrentPlant,
    clearCurrentPlant,
    setNotificationList,
    setCurrentItem,
};

export default withRouteMatch(withParams(connect(mapStateToProps, mapDispatchToProps)(Header)));
