import { debounce } from 'lodash';
import { useEffect, useCallback } from 'react';

/**
 * @callback DelayedCallback
 * @return {Void}
 */

/**
 * @param {DelayedCallback}  fn
 * @param {number} waitTime
 * @param {readonly [*]} deps
 */
export function useDebounceEffect(fn, waitTime, deps) {
    const callback = useCallback(debounce(fn, waitTime), deps);

    useEffect(() => {
        callback();
        return callback.cancel;
    }, [callback]);
}
