import React from 'react';
import ProfileButton from './ProfileButton';
import './ProfileDropdown.scss';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';
import ProfileModal from './ProfileModal';
import FeedbackModal from './FeedbackModal';
import versionInfo from '../../../version.json';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '12vw',
        width: '600px',
    },
};

class ProfileDropdown extends React.Component {
    constructor() {
        super();

        this.state = {
            showMenu: false,
            redirect: false,
            redirectPath: '/',
            showUserModal: false,
            showFeedbackModal: false,
        };

        this.wrapperRef = React.createRef();

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleClickOutsideMenu = this.handleClickOutsideMenu.bind(this);
    }

    showMenu(event) {
        event.preventDefault();

        this.setState({ showMenu: true });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideMenu);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideMenu);
    }

    handleClickOutsideMenu(event) {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.closeMenu();
        }
    }

    closeMenu() {
        this.setState({ showMenu: false });
    }

    redirect(path) {
        this.setState(
            {
                redirect: true,
                redirectPath: path,
            },
            () => {
                this.setState({
                    redirect: false,
                    redirectPath: '/',
                });
            }
        );
    }

    stopImpersonation() {
        sessionStorage.removeItem('userImpersonate');
        sessionStorage.removeItem('userImpersonateName');
        sessionStorage.removeItem('userImpersonateAcc');
        sessionStorage.removeItem('userImpersonateAccName');
        sessionStorage.removeItem('userImpersonateRole');
        sessionStorage.removeItem('userImpersonateModules');
        window.location.replace('/');
    }

    closeModal() {
        this.setState({
            showUserModal: false,
            showFeedbackModal: false,
        });
    }

    render() {
        return (
            <>
                <div>
                    {this.state.redirect ? <Redirect to={this.state.redirectPath} /> : null}
                    <ProfileButton onClick={this.state.showMenu ? this.closeMenu : this.showMenu} />

                    {this.state.showMenu ? (
                        <div className="menu" ref={this.wrapperRef}>
                            <div>
                                <div className="username">
                                    <span>
                                        {sessionStorage.getItem('userImpersonateName') ||
                                            this.props.user.profile.Username}
                                    </span>
                                    <span>
                                        {sessionStorage.getItem('userImpersonateAccName') ||
                                            this.props.user.profile.AccountName}
                                    </span>
                                </div>
                            </div>
                            {(this.props.user.profile.role === 'admin' ||
                                this.props.user.profile.role === 'owner' ||
                                sessionStorage.getItem('userImpersonateRole') === 'admin') &&
                            sessionStorage.getItem('userImpersonateRole') !== 'user' ? (
                                <div>
                                    <button
                                        className="menubutton"
                                        onClick={() => {
                                            this.closeMenu();
                                            this.redirect('/admin/plant');
                                        }}
                                    >
                                        Admin
                                    </button>
                                </div>
                            ) : null}
                            <div>
                                <button
                                    className={'menubutton'}
                                    onClick={() => {
                                        this.closeMenu();
                                        this.setState({ showUserModal: true });
                                    }}
                                >
                                    User settings
                                </button>
                            </div>
                            <div>
                                <button
                                    className="menubutton"
                                    onClick={() => {
                                        this.closeMenu();
                                        this.setState({ showFeedbackModal: true });
                                    }}
                                >
                                    Send feedback
                                </button>
                            </div>
                            {sessionStorage.getItem('userImpersonate') ? (
                                <button className="menubutton" onClick={this.stopImpersonation}>
                                    Stop viewing as
                                </button>
                            ) : null}
                            <button onClick={this.props.signOut} className="menubutton logout-button">
                                {' '}
                                Log out{' '}
                            </button>
                            <div className={'release-number'}>Build version: {versionInfo.ReleaseNumber}</div>
                        </div>
                    ) : null}
                </div>

                <Modal
                    isOpen={this.state.showUserModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <ProfileModal closeModal={this.closeModal} />
                </Modal>

                <Modal
                    isOpen={this.state.showFeedbackModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <FeedbackModal closeModal={this.closeModal} />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(ProfileDropdown);
