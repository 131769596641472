import React from 'react';
import './AdminPlantAddEdit.scss';
import { connect } from 'react-redux';
import Geosuggest from 'react-geosuggest';
import { NotificationManager } from 'react-notifications';
import BaseSelect from 'react-select';
import { components } from 'react-select';
import { ReactComponent as AngleDownIcon } from '../../../images/list/optimar_angle_down.svg';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../../utility/api';
import RequiredSelect from '../RequiredSelect/RequiredSelect';
import { setCurrentAdminPlant } from '../../../actions';
import Dropzone from 'react-dropzone';
import FontAwesome from 'react-fontawesome';
import { overlayStyle, dropzoneStyle, thumbsContainer, thumb, thumbInner, img } from '../../../utility/dropzoneStyle';

const accept = ['image/png', 'image/jpg', 'image/jpeg'];

const Select = (props) => <RequiredSelect {...props} SelectComponent={BaseSelect} />;

class AdminPlantAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            plantTypes: [],
            bwSites: [],
            isDirty: false,
            checkedItems: [],
            plant: {},
            chooseAccounts: true,
            onSuggestFirstRun: true,
            inputValue: '',
            optimarUsers: [],
            manualPosition: false,
            plantTypesForSelect: [],
            files: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.fetchAccounts = this.fetchAccounts.bind(this);
        this.fetchingAccs = false;

        this.fetchPlantAccountsForPlant = this.fetchPlantAccountsForPlant.bind(this);
        this.handlePlantTypeChange = this.handlePlantTypeChange.bind(this);
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleChooseAccountsChange = this.handleChooseAccountsChange.bind(this);
        this.geoOnSuggestSelect = this.geoOnSuggestSelect.bind(this);
        this.geoOnUpdateSuggests = this.geoOnUpdateSuggests.bind(this);
        this.geoOnChange = this.geoOnChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.tryParseJSON = this.tryParseJSON.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount() {
        if (this.props.plant && this.props.type !== 'new') {
            console.log('props plant', this.props.plant);
            this.setState({
                mode: 'edit',
                plant: this.props.plant,
                isVessel: this.props.plant.isVessel,
                chooseAccounts: false,
            });

            this.fetchPlantAccountsForPlant(this.props.plant.id);
        } else {
            this.fetchAccounts();
        }

        if (this.props.account) {
            const account = this.props.account;

            const tmp = this.state.plant;
            tmp.accounts = [account.id];
            this.setState({ plant: tmp });
        }

        this.fetchPlantTypes();
        this.fetchBWLocalities();
        this.fetchOptimarUsers();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.plant) !== JSON.stringify(this.props.plant)) {
            console.log('plant props updated', this.props.plant);
            this.setState({
                plant: this.props.plant,
            });
        }
    }

    onInputChange = (inputValue, { action }) => {
        switch (action) {
            case 'input-change':
                this.setState({ inputValue });
                return;
            case 'menu-close': {
                let menuIsOpen = undefined;
                if (this.state.inputValue) {
                    menuIsOpen = true;
                }
                this.setState({
                    menuIsOpen,
                });
                return;
            }
            default:
                return;
        }
    };

    onDrop(files) {
        if (files.length < 1) {
            NotificationManager.error('File type not supported', 'File error', 5000);
            return;
        }

        if (files.length > 1) {
            NotificationManager.error('Only one file can be uploaded', 'Too many files', 5000);
            return;
        }
        const prevFiles = this.state.files;
        prevFiles.push(...files);
        this.setState({
            files: prevFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
        });
    }

    handleSiteChange(input) {
        const event = [];
        event.target = [];
        event.target.value = input.value;
        event.target.type = 'select-one';
        event.target.name = 'localityNo';
        const target = event.target;
        const plantlocalityNo = target.value;
        const plantLocality = this.state.bwSites.find((site) => site.localityNo === plantlocalityNo);
        this.handleChange(event);

        if (plantLocality) {
            const plant = this.state.plant;
            plant.latitude = plantLocality.lat;
            plant.longitude = plantLocality.lon;
            plant.name = plantLocality.name;
            this.setState({
                plant: plant,
            });
        }
    }

    handlePlantTypeChange(input) {
        // får ikke event fra react-select
        const event = [];
        event.target = [];
        event.target.value = input.value;
        event.target.type = 'select-one';
        event.target.name = 'plantTypeId';
        const target = event.target;
        const plantTypeId = target.value;
        const plantType = this.state.plantTypes.find((plantType) => plantType.id === plantTypeId);
        if (plantType.isVessel) {
            this.setState({
                isVessel: true,
            });
        } else {
            this.setState({
                isVessel: false,
            });
        }
        this.handleChange(event);
    }

    removeFile(index) {
        const files = this.state.files;
        files.splice(index, 1);
        this.setState({ files });
    }

    addAccount(account) {
        const tmp = this.state.plant;
        if (!tmp.accounts) {
            tmp.accounts = [];
        }

        const newArray = tmp.accounts.slice();
        newArray.push(account);

        tmp.accounts = newArray;
        this.setState({ plant: tmp });
    }

    removeAccount(account) {
        const array = [...this.state.plant.accounts];
        const index = array.indexOf(account);

        array.splice(index, 1);

        const tmp = this.state.plant;
        tmp.accounts = array;

        this.setState({ plant: tmp });
    }

    handleChange(event) {
        this.setState({
            isDirty: true,
            userAdded: false,
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;
        if (target.type === 'checkbox') {
            if (value) {
                this.addAccount(name);
            } else {
                this.removeAccount(name);
            }
        } else {
            const tmp = Object.assign({}, this.state.plant);
            tmp[name] = value;

            this.setState({ plant: tmp });
        }
    }

    handleChooseAccountsChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ chooseAccounts: value });
    }

    fetchOptimarUsers() {
        api.get(process.env.REACT_APP_API + '/api/users/optimar')
            .then((res) => {
                this.setState({ optimarUsers: res.data });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    fetchPlantAccountsForPlant(id) {
        api.get(process.env.REACT_APP_API + '/api/accountPlants/plant/' + id)
            .then((res) => {
                const tmp = this.state.plant;
                tmp.accounts = res.data.map((account) => account.accountId);
                this.setState({ plant: tmp }, this.fetchAccounts);
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    fetchAccounts() {
        this.fetchingAccs = true;
        api.get(process.env.REACT_APP_API + '/api/accounts/')
            .then((res) => {
                const tmp = this.state.plant;
                if (tmp.accounts) {
                    const filtered = res.data.filter((account) => tmp.accounts.indexOf(account.id) > -1);
                    const currentAccounts = filtered.map(function (d) {
                        return {
                            value: d.id,
                            label: d.name,
                        };
                    });
                    this.setState({
                        selectedOption: currentAccounts,
                        plant: tmp,
                    });
                } else {
                    const optimarAccount = res.data.find((x) => x.name === 'Optimar');
                    tmp.accounts = [{ value: optimarAccount.id, label: optimarAccount.name }];
                    this.setState({
                        selectedOption: [{ value: optimarAccount.id, label: optimarAccount.name }],
                        plant: tmp,
                    });
                }

                const allTags = res.data.map(function (d) {
                    return {
                        value: d.id,
                        label: d.name,
                    };
                });
                this.setState(
                    {
                        accounts: allTags,
                    },
                    () => (this.fetchingAccs = false)
                );
            })

            .catch((err) => {
                console.error('ERROR: ', err);
                this.fetchingAccs = false;
            });
    }

    fetchPlantTypes() {
        api.get(process.env.REACT_APP_API + '/api/planttypes/')
            .then((res) => {
                this.setState({
                    plantTypes: res.data,
                    plantTypesForSelect: res.data.map((type) => {
                        return { value: type.id, label: type.name };
                    }),
                });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    fetchBWLocalities() {
        api.get(process.env.REACT_APP_API + '/api/localities/all')
            .then((res) => this.setState({ bwSites: res.data }))
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.files?.length > 1) {
            NotificationManager.warning(
                'Please select only one image when setting plant image',
                'Only one image',
                5000
            );
            return;
        }
        if (this.state.mode === 'edit') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body">
                            <h1>Save changes</h1>
                            <p>Are you sure you want to save these changes?</p>
                            <div className="buttons">
                                <button className="btn btn-yellow" onClick={onClose}>
                                    No, cancel
                                </button>
                                <button
                                    className="btn btn-blue"
                                    onClick={() => {
                                        this.updateSubmit(event);
                                        onClose();
                                    }}
                                >
                                    Yes, save
                                </button>
                            </div>
                        </div>
                    );
                },
            });
        } else {
            this.createSubmit(event);
        }
    }

    tryParseJSON(jsonString) {
        try {
            const o = JSON.parse(jsonString);
            if (o && typeof o === 'object') {
                return o;
            }
        } catch (e) {
            console.log('error', e);
        }

        return undefined;
    }

    clearPlantState() {
        const tmp = this.state.plant;
        tmp.name = '';
        tmp.plantType = '';
        tmp.account = '';
        tmp.description = '';
        tmp.accountManager = '';
        tmp.imageUrl = '';
        tmp.address = '';
        tmp.imo = '';
        tmp.plantTypeId = '';

        this.setState({
            isDirty: false,
            plant: tmp,
        });
    }
    createSubmit = async (event) => {
        event.preventDefault();

        const plant = {
            plantTypeId: this.state.plant?.plantTypeId,
            name: this.state.plant.name,
            description: this.state.plant.description,
            accountManager: this.state.plant.accountManager,
            warrantyManager: this.state.plant.warrantyManager,
            latitude: this.state.plant.latitude,
            longitude: this.state.plant.longitude,
            accounts: this.state.plant.accounts ? this.state.plant.accounts.map((a) => a.value) : null,
            imo: this.state.plant.imo,
            liveData: false,
            ewon: null,
            sla: null,
            address: this.state.plant.address,
            imageUrl: this.state.plant.imageUrl,
            localityNo: this.state.plant.localityNo ? this.state.plant.localityNo : undefined,
        };

        if (this.state.isVessel) {
            plant.address = '';
            plant.latitude = null;
            plant.longitude = null;
        } else {
            plant.imo = null;
        }

        if (this.state.addressNotFound) {
            plant.address = '';
        }
        try {
            const response = await api.post(process.env.REACT_APP_API + '/api/admin/plants', plant);
            if (response.status === 200) {
                const newPlant = response.data;
                await this.setPlantImage(this.state.files, newPlant);
                NotificationManager.success('Plant created', 'Plant successfully created', 5000);
                this.clearPlantState();
                this.props.addedCallback(newPlant);
                if (this.props.closeModal) this.props.closeModal(newPlant);
            } else {
                //NotificationManager.error("Error creating plant", "Error creating plant, plant may have been partially created", 5000);
                throw new Error(response.status);
            }
        } catch (error) {
            if (!plant.plantTypeId) {
                NotificationManager.error('Plant type missing, please select one', 'Error creating plant', 5000);
            } else {
                NotificationManager.error(
                    'Plant not created, are all fields set correctly?',
                    'Error creating plant',
                    5000
                );
            }
        }
    };

    updateSubmit = async (event) => {
        event.preventDefault();

        const plant = {
            id: this.state.plant.id,
            plantTypeId: this.state.plant?.plantTypeId,
            name: this.state.plant.name,
            description: this.state.plant.description,
            accountManager: this.state.plant.accountManager,
            warrantyManager: this.state.plant.warrantyManager,
            latitude: this.state.plant.latitude,
            longitude: this.state.plant.longitude,
            imo: this.state.plant.imo,
            address: this.state.plant.address,
            imageUrl: this.state.plant.imageUrl,
        };

        if (this.state.isVessel) {
            plant.address = '';
            plant.latitude = null;
            plant.longitude = null;
        } else {
            plant.imo = '';
        }

        if (this.state.addressNotFound) {
            plant.address = '';
        }

        try {
            const response = await api.put(process.env.REACT_APP_API + '/api/admin/plants/' + plant.id, plant);
            if (response.status === 200) {
                await this.setPlantImage(this.state.files, plant);

                // if (this.state.files.length > 0) {
                //     this.state.files.forEach((file) => {
                //         this.fileUpload(file, plant);
                //     })
                // }

                this.props.setCurrentAdminPlant(plant);

                NotificationManager.success('Plant updated', 'Plant successfully updated', 5000);
                if (this.props.closeModal) this.props.closeModal();
            } else {
                NotificationManager.error('Error', 'Failed to update plant', 5000);
                // throw new Error(response.status);
            }
        } catch (error) {
            NotificationManager.error('Error', 'Failed to update plant', 5000);
            console.log('Error updating plant', error);
        }
    };

    setPlantImage = async (files, plant) => {
        if (files?.length > 1) {
            NotificationManager.warning(
                'Please select only one image when setting plant image',
                'Only one image',
                5000
            );
            return;
        } else if (files?.length > 0) {
            const imageurlPromises = files.map((file) => {
                return this.fileUpload(file, plant);
            });
            const imageUrls = await Promise.all(imageurlPromises);
            if (imageUrls?.length > 0) {
                plant.imageUrl = imageUrls[0];
                this.setState({
                    files: [],
                    plant: plant,
                });
            } else {
                console.log('No imageurls returned..');
            }
            return imageUrls;
        }
    };

    fileUpload = async (file, plant) => {
        //returns the url of the new image.
        const data = new FormData();
        data.append('File', file);
        data.append('Filename', file.name);

        try {
            const response = await api.post(process.env.REACT_APP_API + '/api/admin/plants/image/' + plant.id, data);
            if (response.status === 200) {
                //NotificationManager.success("Image updated", "Image updated", 5000)
                //plant.imageUrl = response.data;
                return response.data;
            } else {
                NotificationManager.error('Plant updated, but image upload failed', 'Image upload failed ', 5000);
                throw new Error(response.status);
            }
        } catch (error) {
            console.log('Error uploading file', error, file);
        }
        return null;
    };

    geoOnSuggestSelect(suggest) {
        this.setState({
            addressNotFound: false,
        });
        if (suggest) {
            const tmp = this.state.plant;
            tmp.latitude = suggest.location.lat;
            tmp.longitude = suggest.location.lng;
            tmp.address = suggest.label;

            this.setState({
                plant: tmp,
            });
        }
    }

    geoOnUpdateSuggests(suggest) {
        if (!this.state.onSuggestFirstRun) {
            const currentStatus = this.state.addressNotFound;
            if (suggest.length === 0) {
                if (currentStatus === false || currentStatus === undefined) {
                    this.setState({
                        addressNotFound: true,
                    });
                }
            } else {
                if (currentStatus === true) {
                    this.setState({
                        addressNotFound: false,
                    });
                }
            }
        }

        this.setState({
            onSuggestFirstRun: false,
        });
    }

    geoOnChange(address) {
        const tmp = this.state.plant;
        tmp.address = address;
        this.setState({ plant: tmp });
    }

    renderButton() {
        let button;

        if (this.state.mode === 'edit') {
            button = (
                <button disabled={this.fetchingAccs} className="btn btn-blue btn-grow" value="Submit">
                    Save
                </button>
            );
        } else {
            button = (
                <button disabled={this.fetchingAccs} className="btn btn-blue btn-grow" value="Submit">
                    Create
                </button>
            );
        }

        return button;
    }

    isFishFarm() {
        if (this.state.plantTypes.length === 0) return false;
        return this.state.plant.plantTypeId === this.state.plantTypes.find((x) => x.name === 'Fishfarm').id;
    }

    renderSiteSelect(styles, plant, DropdownIndicator) {
        if (this.state.plantTypes.length === 0) return;
        if (this.state.plant.plantTypeId === this.state.plantTypes.find((x) => x.name === 'Fishfarm').id) {
            return (
                <div>
                    <label htmlFor="site">Barentswatch site </label>
                    {!this.state.manualPosition ? (
                        <Select
                            required={false}
                            styles={styles}
                            value={this.getSites().find((site) => {
                                return site.value === plant.localityNo;
                            })}
                            onChange={this.handleSiteChange}
                            options={this.getSites()}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            classNamePrefix={'optimar'}
                        />
                    ) : (
                        <div>
                            <label htmlFor="name">Latitude</label>
                            <input
                                autoComplete="off"
                                type="number"
                                min="-90"
                                max="90"
                                name="latitude"
                                step="any"
                                className={'admin-input-control'}
                                value={this.state.plant.latitude || 0}
                                onChange={this.handleChange}
                            />

                            <label htmlFor="name">Longitude</label>
                            <input
                                autoComplete="off"
                                type="number"
                                min="-180"
                                max="180"
                                name="longitude"
                                step="any"
                                className={'admin-input-control'}
                                value={this.state.plant.longitude || 0}
                                onChange={this.handleChange}
                            />
                        </div>
                    )}
                    <label htmlFor="manualPosition">Manual position</label>
                    <input
                        name="manualposition"
                        type="checkbox"
                        checked={this.state.manualPosition}
                        value={this.state.manualPosition}
                        onChange={() => this.setState({ manualPosition: !this.state.manualPosition })}
                    />
                </div>
            );
        }
    }

    renderPlantTypeFields(google) {
        if (this.state.isVessel) {
            // || (this.state.plantTypes.find(x => x.id === this.props.plant?.plantTypeId) || {}).isVessel) {
            return (
                <div>
                    <label htmlFor="name">IMO</label>
                    <input
                        required
                        autoComplete="off"
                        type="number"
                        className={'admin-input-control'}
                        name="imo"
                        value={this.state.plant.imo || ''}
                        onChange={this.handleChange}
                    />
                </div>
            );
        } else if (!this.isFishFarm()) {
            return (
                <div>
                    <label htmlFor="name">Address</label>
                    <Geosuggest
                        inputClassName="admin-plant-add-edit-form-geosuggest"
                        ref={(el) => (this._geoSuggest = el)}
                        placeholder="Type and select suggestion"
                        onSuggestSelect={this.geoOnSuggestSelect}
                        onUpdateSuggests={this.geoOnUpdateSuggests}
                        onChange={this.geoOnChange}
                        location={new google.maps.LatLng(53.558572, 9.9278215)}
                        initialValue={this.state.plant.address}
                        radius={50}
                        maxFixtures={20}
                        minLength={3}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    getSites() {
        const sites = [];
        this.state.bwSites.forEach((site) => {
            sites.push({ value: site.localityNo, label: site.name });
        });
        return sites;
    }

    handleSelectChange(value) {
        this.setState({ value });
    }

    handleMultiSelectChange = (selectedOption) => {
        this.setState({ selectedOption });

        const tmp = this.state.plant;
        tmp.accounts = selectedOption;

        this.setState({
            plant: tmp,
            inputValue: '',
        });
    };

    render() {
        if (!this.props && !this.state.accounts) return;
        const google = window.google;
        const plant = this.state.plant;
        const warning = '';
        const { inputValue, menuIsOpen } = this.state;

        const DropdownIndicator = (props) => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <AngleDownIcon className="angle-down" />
                    </components.DropdownIndicator>
                )
            );
        };

        const styles = {
            control: (base) => ({
                ...base,
                background: '#1E2E34',
                borderRadius: '0.1em',
                borderStyle: 'none',
            }),
            singleValue: (base) => ({
                ...base,
                color: 'rgb(230, 230, 240)',
            }),
            input: (base) => ({
                ...base,
                color: 'blue',
            }),
        };

        const optimarUsers = this.state.optimarUsers.sort((a, b) => {
            if (a.username.toLowerCase() < b.username.toLowerCase()) return -1;
            if (a.username.toLowerCase() > b.username.toLowerCase()) return 1;
            return 0;
        });

        const thumbs = this.state.files.map((file, i) => (
            <div className="file-preview" key={'preview-' + i}>
                <div style={thumb} key={file.name + '-' + i}>
                    <FontAwesome name="remove" className="file-remove" onClick={() => this.removeFile(i)} />

                    <div style={thumbInner}>
                        <a href={file.preview} rel="noopener noreferrer" target="_blank">
                            {file.type.includes('image') ? (
                                <img src={file.preview} style={img} alt="preview" />
                            ) : (
                                <FontAwesome name="file" size="3x" style={{ margin: '0 auto', color: '#919191' }} />
                            )}
                        </a>
                    </div>
                </div>
                <span>{file.name}</span>
            </div>
        ));

        return (
            <div>
                {warning}
                <form className="admin-plant-add-edit-form" onSubmit={this.handleSubmit}>
                    {this.state.mode === 'edit' ? <h1>Edit plant</h1> : <h1>New plant</h1>}

                    <div>
                        <label htmlFor="plant_type">
                            Plant type <span className="small-text">&nbsp;Required</span>
                        </label>
                        <Select
                            required={true}
                            styles={styles}
                            value={this.state.plantTypesForSelect.find(
                                (op) => op.value === this.state.plant?.plantTypeId
                            )}
                            onChange={this.handlePlantTypeChange}
                            options={this.state.plantTypesForSelect}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            classNamePrefix={'optimar'}
                        />
                    </div>

                    {this.renderSiteSelect(styles, plant, DropdownIndicator)}

                    <div>
                        <label htmlFor="name">Plant name</label>
                        <input
                            required
                            autoComplete="off"
                            type="text"
                            className={'admin-input-control'}
                            name="name"
                            value={this.state.plant.name || ''}
                            onChange={this.handleChange}
                        />
                    </div>

                    <div>
                        <label htmlFor="description">Description</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'admin-input-control'}
                            name="description"
                            value={this.state.plant.description || ''}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="accountManager">Customer Support manager</label>
                        <select
                            className="admin-form-select"
                            value={this.state.plant.accountManager || ''}
                            name="accountManager"
                            onChange={this.handleChange}
                        >
                            <option default value="">
                                Choose CSM
                            </option>
                            {optimarUsers.map((usr, i) => {
                                return (
                                    <option key={'usr' + i} value={usr.id}>
                                        {usr.username}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {/*Hide warranty manager.. Should not be visible in Commander. #6816, 22.06.2022 */}
                    {/* <div>
                        <label htmlFor="warrantyManager">Warranty manager</label>
                        <select className="admin-form-select" value={this.state.plant.warrantyManager || ''} name="warrantyManager" onChange={this.handleChange}>
                            <option default value="">Choose warranty manager</option>
                            {
                                optimarUsers.map((usr, i) => {
                                    return <option title={usr.username} key={"usr" + i} value={usr.id}>{usr.displayname || usr.username}</option>
                                })
                            }
                        </select>
                    </div> */}

                    {this.renderPlantTypeFields(google)}

                    {this.state.addressNotFound && !this.isFishFarm() ? (
                        <div>
                            <div>
                                <b>No addresses found matching your criterias. Please provide latitude and longitude</b>
                            </div>
                            <label htmlFor="name">Latitude</label>
                            <input
                                autoComplete="off"
                                type="number"
                                min="-90"
                                max="90"
                                name="latitude"
                                step="any"
                                className={'admin-input-control'}
                                value={this.state.plant.latitude || 0}
                                onChange={this.handleChange}
                            />

                            <label htmlFor="name">Longitude</label>
                            <input
                                autoComplete="off"
                                type="number"
                                min="-180"
                                max="180"
                                name="longitude"
                                step="any"
                                className={'admin-input-control'}
                                value={this.state.plant.longitude || 0}
                                onChange={this.handleChange}
                            />
                        </div>
                    ) : null}

                    {!this.state.addressNotFound &&
                    (!this.state.plant.address || this.state.plant.address === '') &&
                    this.state.plant.latitude &&
                    this.state.plant.longitude ? (
                        <div>
                            <label htmlFor="name">Latitude</label>
                            <input
                                autoComplete="off"
                                type="number"
                                min="-90"
                                max="90"
                                name="latitude"
                                step="any"
                                className={'admin-input-control'}
                                value={this.state.plant.latitude || 0}
                                onChange={this.handleChange}
                            />

                            <label htmlFor="name">Longitude</label>
                            <input
                                autoComplete="off"
                                type="number"
                                min="-180"
                                max="180"
                                name="longitude"
                                step="any"
                                className={'admin-input-control'}
                                value={this.state.plant.longitude || 0}
                                onChange={this.handleChange}
                            />
                        </div>
                    ) : null}

                    <div>
                        <label htmlFor="name">Image URL</label>
                        <input
                            type="text"
                            autoComplete="off"
                            className={'admin-input-control'}
                            name="imageUrl"
                            value={this.state.plant.imageUrl || ''}
                            onChange={this.handleChange}
                        />
                    </div>

                    <Dropzone onDrop={this.onDrop.bind(this)} accept={accept}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <div {...getRootProps()} style={dropzoneStyle}>
                                Drop files here or click to upload
                                <input {...getInputProps()} />
                                {isDragActive && <div style={overlayStyle}></div>}
                            </div>
                        )}
                    </Dropzone>
                    <aside style={thumbsContainer}>{thumbs}</aside>

                    {this.state.mode === 'edit' ? null : (
                        <div>
                            <label>Choose Accounts</label>
                            <Select
                                styles={styles}
                                isMulti={true}
                                value={this.state.selectedOption}
                                isClearable
                                isSearchable
                                inputValue={inputValue}
                                onInputChange={this.onInputChange}
                                onChange={this.handleMultiSelectChange}
                                className="Select-menu-outer"
                                options={this.state.accounts}
                                menuIsOpen={menuIsOpen}
                                components={{ DropdownIndicator, IndicatorSeparator: null }}
                                classNamePrefix={'optimar'}
                            />
                        </div>
                    )}

                    <div className="btn-row btn-row-grow">
                        <button
                            className="btn btn-yellow btn-grow"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.cancelCallback();
                            }}
                        >
                            Cancel
                        </button>
                        {this.renderButton()}
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plants.currentAdminPlant,
    user: state.user.currentUser,
});

const mapDispatchToProps = {
    setCurrentAdminPlant,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPlantAddEdit);
