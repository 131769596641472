import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

// import { createLogger } from "redux-logger";
//import { Iterable } from "immutable";
import thunk from 'redux-thunk';

//import { getQuery } from "../utility";
// import { reducer } from "./combineReducers";
import { defaultState } from './defaultState';

import rootReducer from '../reducers';

import createSagaMiddleware from 'redux-saga';
import { initSagas } from './initSagas';

import { signalRMiddleware } from '../middlewares/signalRMiddleware';

// const stateTransformer = state => {
//   if (Iterable.isIterable(state)) return state.toJS();
//   else return state;
// };

// const logger = createLogger({
//   stateTransformer
// });
export const getStore = () => {
    console.info('Starting redux middleware');
    const sagaMiddleware = createSagaMiddleware();
    const middleWares = [sagaMiddleware, thunk, signalRMiddleware];
    // if (getQuery()["logger"]) {
    //   middleWares.push(logger);
    // }

    const composables = [applyMiddleware(...middleWares)];
    const enhancer = compose(...composables);

    const store = createStore(rootReducer, defaultState, composeWithDevTools(enhancer));

    console.info('Saga middleware implemented');
    initSagas(sagaMiddleware);
    return store;
};
