import React from 'react';
import './StatusSlider.scss';

// eslint-disable-next-line no-unused-vars
const StatusSlider = ({ id, status, click, selectedBuoy }) => {
    return (
        <>
            <div className="widget-header">
                <span className="widget-title">Status</span>
            </div>
            <div className="slider-wrapper">
                <span className="slider-label">Depth</span>
                <span className="slider"></span>
            </div>
            <div className="slider-wrapper">
                <span className="slider-label">Density</span>4 meters<span className="slider"></span>
            </div>
            <div className="slider-wrapper">
                <span className="slider-label">Speed</span>20cm / second<span className="slider"></span>
            </div>
            <div className="slider-wrapper">
                <span className="slider-label">Spread</span>7%<span className="slider"></span>
            </div>
            <div className="slider-wrapper">
                <span className="slider-label">FCR</span>1,18<span className="slider warn"></span>
            </div>
            <div className="slider-wrapper">
                <span className="slider-label">RGI</span>92<span className="slider error"></span>
            </div>
            <div className="slider-wrapper">
                <span className="slider-label">Mortality</span>0,4%<span className="slider"></span>
            </div>
        </>
    );
};

export default StatusSlider;
