import React from 'react';
import PropTypes from 'prop-types';
import './text.scss';

class Text extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate() {}

    componentDidMount() {}

    getFontSizeStyle(styleobj) {
        if (this.props.textSize) styleobj.fontSize = `${this.props.textSize}`;
    }

    getHorizontalAlignStyle(styleobj) {
        if (typeof this.props.horAlign === 'undefined') return;

        switch (this.props.horAlign) {
            case 'left':
                break;
            case 'center':
                styleobj.alignItems = 'center';
                break;
            case 'right':
                styleobj.alignItems = 'flex-end';
                break;
            default:
                break;
        }
    }
    getVerticalAlignStyle(styleobj) {
        if (typeof this.props.verAlign === 'undefined') return;

        switch (this.props.verAlign) {
            case 'top':
                break;
            case 'center':
                styleobj.justifyContent = 'center';
                break;
            case 'bottom':
                styleobj.justifyContent = 'flex-end';
                break;
            default:
                return;
        }
    }

    render() {
        const textSizeStyleObj = { lineHeight: 1 };
        this.getFontSizeStyle(textSizeStyleObj);

        const valueCellWrapperStyleObj = {};
        this.getHorizontalAlignStyle(valueCellWrapperStyleObj);
        this.getVerticalAlignStyle(valueCellWrapperStyleObj);

        valueCellWrapperStyleObj.display = 'flex';
        valueCellWrapperStyleObj.height = '100%';
        valueCellWrapperStyleObj.flex = '0 0 100%';
        valueCellWrapperStyleObj.flexWrap = 'wrap';
        return (
            <div
                className={'value-cell-wrapper ' + (this.props.textGradient ? 'text-gradient' : '')}
                style={valueCellWrapperStyleObj}
            >
                <div className={'widget-header'}>
                    <h3 style={textSizeStyleObj}>{this.props.title}</h3>
                </div>
            </div>
        );
    }
}

Text.propTypes = {
    itemId: PropTypes.string,
};

export default Text;
