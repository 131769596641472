import differenceInSeconds from 'date-fns/differenceInSeconds';
import moment from 'moment';

const PROJECT_TYPE = 'project';
const TASK_TYPE = 'task';

export const getChangedTasks = (tasks, parentTask, childrenModified, timeDiff = null) => {
    parentTask = tasks.find(t => t.id === parentTask.id);
    timeDiff ??= getTimeDifference(parentTask.start, parentTask.previousStart);

    tasks
        .filter((item) => item.type !== "project" && item.parentJobId === parentTask.id)
        .forEach((element) => {
            let [newStartDate, newEndDate] = [setNewTime(timeDiff, element.start), setNewTime(timeDiff, element.end)];

            element.start = newStartDate;
            element.end = newEndDate;
            element.progress = getPercentage(newStartDate, newEndDate);

            childrenModified.push(element);

            getChangedTasks(tasks, element, childrenModified, timeDiff);
        });

    return tasks;
};

export const getTimeDifference = (start, end) => {
    let timeDiff = new Date(start).getTime() - new Date(end).getTime();

    let diffDays;
    let diffHours;
    let diffMinutes;
    let diffSeconds;

    // calculate the time difference in days, hours, minutes, and seconds
    if (timeDiff > 0) {
        diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        diffHours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        diffMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        diffSeconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    } else {
        diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        diffHours = Math.ceil((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        diffMinutes = Math.ceil((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        diffSeconds = Math.ceil((timeDiff % (1000 * 60)) / 1000);
    }
    return { diffDays, diffHours, diffMinutes, diffSeconds };
};

export const setNewTime = (timediff, timeVal) => {
    let { diffDays, diffHours, diffMinutes, diffSeconds } = timediff;
    let date = new Date(timeVal);

    date.setDate(timeVal.getDate() + diffDays);
    date.setHours(timeVal.getHours() + diffHours);
    date.setMinutes(timeVal.getMinutes() + diffMinutes);
    date.setSeconds(timeVal.getSeconds() + diffSeconds);

    return date;
};

export function getPercentage(start, end, currentDate = new Date()) {
    const fullDiff = differenceInSeconds(end, start);

    const nowDiff = differenceInSeconds(currentDate, start);
    let currentPercent = (nowDiff / fullDiff) * 100;
    currentPercent = currentPercent > 100 ? 100 : currentPercent;
    currentPercent = currentPercent < 0 ? 0 : currentPercent;

    return currentPercent;
}

export function restoreNullEndDateValue(data) {
    return data.map((item) => {
        if (item.end && item.start && item.end.getTime() === item.start.getTime() && item.originalEndDate === null) {
            item.end = item.originalEndDate;
        }
        return item;
    });
}

export function manipulateData(data) {
    for (const item of data) {
        if (item.parentJobId === '00000000-0000-0000-0000-000000000000') {
            item.parentJobId = null;
        }

        if (item.parentJobId !== null && !data.some((i) => i.id === item.parentJobId)) {
            item.parentJobId = null;
            item.isOrphan = true;
        } else {
            item.isOrphan = false;
        }

        // When creating or updating an operation/job, the database is not saving the Z (for the Zero timezone) of the dateTime value: yyyy-MM-dd'T'HH:mm:ss'Z
        // So, when retrieving operations/jobs, it must set the date as UTC and get local time
        item.startDate = getLocalTime(item.startDate);
        if (item.endDate)
        {
        item.endDate = getLocalTime(item.endDate);
        }    

        // Keep copy of original date range values, because, for ganttview, when endDate is null, endDate receives the same value as startDate 
        // So, before send a request to update operation/job(s) with new dateRange, 
        // it must restore the null endDate if unchanged by the user
        item.originalStartDate = item.startDate;
        item.originalEndDate = item.endDate;
    }
    return data;
}

function getLocalTime(date)
{
    return new Date(moment.utc(date).local().toDate());
}

function removeNestedOrphanOperations(data) 
{    
    const itemsToBeFiltered = [];

    for (const item of data) {
        if (item.parentJobId !== null && !data.some((i) => i.id === item.parentJobId)) {
            itemsToBeFiltered.push(item.id);
        }
    }

    if (itemsToBeFiltered.length === 0) {
        return data;
    }

    data = data.filter(item => !itemsToBeFiltered.includes(item.id));

    return removeNestedOrphanOperations(data);
}

export function convertTasks(data, searchField, plantSelectedOptions) {
    const dataWithoutOrphans = data.filter(item => item.isOrphan === false);

    data = removeNestedOrphanOperations(dataWithoutOrphans)
        .filter((item) => item.startDate)
        .map((item) => {
            if (!item.endDate) {
                item.endDate = item.startDate;
            }
            return item;
        });
    
    if (searchField) {
        data = data.filter((item) => item.type.toLowerCase().includes(searchField.toLowerCase()));
    }

    const items = data.reduce((r, a) => {
        r[a.plantId] = [...(r[a.plantId] || []), a];
        return r;
    }, {});

    let keys = Object.keys(items);

    const tasks = [];

    let numberOfPlants = 0;

    if (plantSelectedOptions) {
        const plantSelectedOptionIds = plantSelectedOptions.map((x) => x.value);
        keys = keys.filter((id) => plantSelectedOptionIds.includes(id));
    }

    keys.forEach((key) => {
        tasks.push(ConvertToGanttProject(items, key));

        numberOfPlants++;

        let resultList = [];

        ProcessTree(items[key], resultList);

        resultList.forEach((i) => {
            tasks.push(i);
        });
    });

    return {
        tasks: tasks,
        numberOfPlants: numberOfPlants,
    };
}

function ProcessTree(items, resultList, parentJobId = null, indentLevel = 0) {
    let idList = items.map((k) => k.id);

    items
        .filter((item) => item.parentJobId === parentJobId || (indentLevel === 0 && !idList.includes(item.parentJobId)))
        .forEach((element) => {
            const childCount = items.filter((item) => item.parentJobId === element.id).length;
            resultList.push(ConvertToGanttTask(element, indentLevel, childCount));
            ProcessTree(items, resultList, element.id, indentLevel + 1);
        });
}

function ConvertToGanttProject(items, key) {
    let item = items[key][0];

    let projectStartDate = new Date(item.startDate);
    let projectEndDate = new Date(item.endDate);

    items[key].forEach((x) => {
        if (new Date(x.startDate) < projectStartDate) {
            projectStartDate = new Date(x.startDate);
        }

        if (new Date(x.endDate) > projectEndDate) {
            projectEndDate = new Date(x.endDate);
        }
    });

    return {
        start: projectStartDate,
        end: projectEndDate,
        name: item.plantName,
        id: item.plantId,
        childCount: items[key].length,
        progress: getPercentage(projectStartDate, projectEndDate),
        type: PROJECT_TYPE,
        hideChildren: true,
    };
}

function ConvertToGanttTask(i, indentLevel, childCount) {
    const startDate = new Date(i.startDate);
    const endDate = new Date(i.endDate);

    return {
        start: startDate,
        end: endDate,
        originalEndDate: i.originalEndDate,
        originalStartDate: i.originalStartDate,
        childCount: childCount,
        parentJobId: i.parentJobId,
        plantId: i.plantId,
        jobTypeId: i.jobTypeId,
        relationship: i.relationship,
        topic: i.topic,
        data: i,
        configuration: i.configuration,
        name: i.type,
        id: i.id,
        progress: getPercentage(startDate, endDate),
        type: TASK_TYPE,
        project: i.plantId,
        indentLevel: indentLevel,
    };
}

export function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }

    let progress = getPercentage(start, end);

    return [start, end, progress];
}
