import React from 'react';
import AdminItemDataTypeAddEdit from '../../components/common/AdminItemDataTypeAddEdit/AdminItemDataTypeAddEdit';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import Modal from 'react-modal';
import { ReactComponent as DeleteIcon } from '../../images/list/optimar_cross.svg';
import { ReactComponent as EditIcon } from '../../images/list/optimar_edit.svg';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../utility/api';
import { noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '12vw',
        width: '700px',
    },
};
class AdminDataTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddDataTypeSection: false,
            dataTypes: [],
            visibleColumns: [
                { Header: 'Name', accessor: 'name', filterable: true },
                {
                    Header: 'Is Trace',
                    accessor: 'isTrace',
                    width: 60,
                    filterable: false,
                    Cell: (item) => {
                        return <div style={{ textAlign: 'center' }}>{item.original.isTrace ? '✓' : ''}</div>;
                    },
                },
                {
                    Header: '',
                    Cell: (item) => {
                        return (
                            <div className="action-container">
                                <div
                                    className="action-element "
                                    alt="Delete datatype"
                                    title="Delete datatype"
                                    onClick={() => this.confirmDelete(item.original)}
                                >
                                    <DeleteIcon className="action-icon red" />
                                    <span className="list-edit-btn">Delete</span>
                                </div>
                                <div
                                    className="action-element padding"
                                    alt="Edit datatype"
                                    title="Edit datatype"
                                    onClick={() => this.openModal(item.original, 'edit')}
                                >
                                    <EditIcon className="action-icon" />
                                    <span className="list-edit-btn">Edit datatype</span>
                                </div>
                            </div>
                        );
                    },
                },
            ],
            modalIsOpen: false,
            editMode: false,
            editDataType: null,
        };

        this.fetchDataTypes = this.fetchDataTypes.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        this.fetchDataTypes();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.dataTypes) {
            this.fetchDataTypes();
        }
    }

    fetchDataTypes() {
        if (this.props.user != null) {
            api.get(process.env.REACT_APP_API + '/api/itemDataType')
                .then((res) => {
                    res.data.sort((a, b) => a.name.localeCompare(b.name));

                    if (this.mounted) {
                        this.setState({
                            dataTypes: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    editDataType = (itemFromChild) => {
        this.setState({
            editDataType: itemFromChild,
        });
    };

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete datatype</h1>
                        <p>
                            You are about to delete the datatype
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.title}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteDataType(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteDataType = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/itemdatatype/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Datatype deleted', 'Datatype successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete datatype', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchDataTypes();
                })
                .catch(noop);
        }
    };

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                editType: mode,
            },
            function () {
                this.fetchDataTypes();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchDataTypes();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
    }

    render() {
        const dataTypes = this.state.dataTypes;
        const visibleColumns = this.state.visibleColumns;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{dataTypes ? dataTypes.length : 0} Datatypes</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New Datatype</span>{' '}
                        </span>
                    </span>
                </div>
                <ReactTable
                    data={dataTypes}
                    columns={visibleColumns}
                    showPagination={false}
                    pageSize={dataTypes.length}
                    minRows={0}
                    defaultFilterMethod={this.filterCaseInsensitive}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminItemDataTypeAddEdit
                        editDataType={this.state.editItem}
                        editedCallback={this.closeModal}
                        cancelCallback={this.closeModal}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminDataTypes);
