import React from 'react';
import FontAwesome from 'react-fontawesome';
import MapInfo from './MapInfo';
import './Marker.css';
import PLANT_TYPE from '../../constants';
import * as moment from 'moment';
import { ReactComponent as Map_marker_vessel_active } from './icons/vessel_green.svg';
import { ReactComponent as Map_marker_vessel } from './icons/vessel_blue.svg';
import { ReactComponent as Map_marker_land_plant_active } from './icons/plant_green.svg';
import { ReactComponent as Map_marker_land_plant } from './icons/plant_blue.svg';
import { ReactComponent as Map_marker_vessel_green_transit } from './icons/vessel_green_transit.svg';
import { ReactComponent as Map_marker_vessel_blue_transit } from './icons/vessel_blue_transit.svg';
import { ReactComponent as Map_marker_farm } from './icons/farm_blue.svg';
import { ReactComponent as Map_marker_farm_yellow } from './icons/farm_yellow.svg';
import { ReactComponent as Map_marker_farm_red } from './icons/farm_red.svg';
import { ReactComponent as Map_marker_farm_green } from './icons/farm_green.svg';
import { isMobile } from 'react-device-detect';

class Marker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.display || false,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.info &&
            (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info) ||
                this.state.display !== nextState.display ||
                JSON.stringify(this.props.showMapInfo) !== JSON.stringify(nextProps.showMapInfo) ||
                this.props.showName !== nextProps.showName)
        ) {
            return true;
        }
        return false;
    }

    mouseEnter = () => {
        if (!isMobile) this.setState({ display: true });
    };

    mouseLeave = () => {
        if (!isMobile) this.setState({ display: false });
    };

    handleClick = () => {
        this.props.handleMarkerClick(this.props.info.id);
    };

    render() {
        let MarkerType = <FontAwesome name="circle" />;
        const timeDiff = moment().valueOf() - moment.utc(this.props.info.lastConnectedTime).local().valueOf();

        switch (this.props.info.type) {
            case PLANT_TYPE.sea:
                if (this.props.speed && this.props.speed > 0) {
                    MarkerType = Map_marker_vessel_green_transit;
                } else {
                    MarkerType = Map_marker_vessel_active;
                }

                if (timeDiff >= 60 * 60 * 1000 || !this.props.info.lastConnectedTime) {
                    if (this.props.speed && this.props.speed > 0) {
                        MarkerType = Map_marker_vessel_blue_transit;
                    } else {
                        MarkerType = Map_marker_vessel;
                    }
                }
                break;

            case PLANT_TYPE.fishFarm:
                if (this.props.info.aboveLiceThreshold) {
                    MarkerType = Map_marker_farm_red;
                } else if (this.props.info.hasReportedLice) {
                    MarkerType = Map_marker_farm_yellow;
                } else if (timeDiff <= 60 * 60 * 1000) {
                    MarkerType = Map_marker_farm_green;
                } else {
                    MarkerType = Map_marker_farm;
                }
                break;

            default:
                MarkerType = Map_marker_land_plant_active;
                if (timeDiff >= 60 * 60 * 1000 || !this.props.info.lastConnectedTime) {
                    MarkerType = Map_marker_land_plant;
                }
                break;
        }

        let status = '';

        if (!this.props.info.lastConnectedTime) {
            status = '';
        } else if (timeDiff <= 60 * 60 * 1000) {
            // 1 hour
            status = 'uploading';
        }
        // else if (timeDiff <= 12 * 60 * 60 * 1000) {
        //     status = "idle";
        // }
        else {
            status = 'idle';
        }

        const rot = -90 + this.props.heading;
        const rotation = {
            transformOrigin: 'center',
            transform: 'translate(-50%, -50%) rotate(' + rot + 'deg) scale(1.25)',
        };

        const showMapInfo = this.props.showMapInfo && this.props.showMapInfo.id === this.props.info.id;

        return (
            <div>
                <div className={this.props.showName ? 'marker-name' : 'hidden'}>
                    <span>{this.props.info.name}</span>
                </div>
                <div
                    id={'marker-' + this.props.info.id}
                    className={'marker'}
                    style={rotation}
                    onClick={this.handleClick}
                    onMouseOver={this.mouseEnter}
                    onMouseLeave={this.mouseLeave}
                >
                    <div className={'markerContainer'}>
                        <span
                            className={
                                status === 'uploading'
                                    ? 'pulsating-circle-green'
                                    : status === 'idle'
                                    ? 'pulsating-circle-yellow'
                                    : status === 'error'
                                    ? 'pulsating-circle-red'
                                    : ''
                            }
                        ></span>
                        <MarkerType plantid={this.props.info.id} />
                    </div>
                </div>
                {/* <img alt="Plant marker" src={markerType} style={rotation} className="marker" onClick={this.handleClick} onMouseOver={this.displayInfo} onMouseLeave={this.displayInfo}/> */}
                <div className={this.state.display || showMapInfo ? '' : 'mapinfo-hidden'}>
                    <MapInfo info={this.props.info} />
                </div>
            </div>
        );
    }
}

export default Marker;
