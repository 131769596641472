import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../../images/new/optimar_cross.svg';
import { newGuid } from '../../../../utility/guidFunctions';
import { toNumber } from '../../../../utility/numberFunctions';
import {
    DataValueSelect,
    fromDatavalueObjToDatasourceValueAndSource,
    fromSourceAndDataValueToStringRep,
    fromStringRepToDatavalueObject,
} from './../../../../utility/datavalueSelect';

/*  Description: Get forms editor for components
    Parameters:
        - onInputChange: callback when something changes
        - datapoint_sources: available datapoint sources
        - data: component config
        - items: available items
        - datavalues: available datavalues
        - child_jobs: available child jobs
        - config flags
    Returns: component forms editor
*/
//export function component_editor(onInputChange, datapoint_sources, data, items, getDatavaluesFunc, child_jobs, {show_timeframe = false} = {})
export function getDatasourceValueAndSourceFromDataValueObject(dvObj, valid_sources) {
    // datavalues: "Data Values",
    // data_aggregates: "Data Aggregates",
    // trace: "Trace",
    // events:"Event"
    if (!dvObj || typeof dvObj !== 'object')
        console.warn(
            'No item, or wrong type. User might have created a user defined datavalue without using custom component.',
            dvObj
        );

    return fromDatavalueObjToDatasourceValueAndSource(dvObj, valid_sources);
}

export function ComponentCollectionEditor(props) {
    const datapoint_sources = props.datapoint_sources;
    const data = props.data;
    const getDatavaluesFunc = props.getDatavaluesFunc;

    const get_new_datapoint = () => {
        return {
            id: newGuid(),
            componentId: '',
            datavalue: '',
            varName: undefined,
            //"source"

            //"__custom__":false
        };
    };

    const migrateComponentSettingsFromLegacy = (myData) => {
        //console.log("Migrating from legacy configuration",myData);
        const newDataPoint = get_new_datapoint();
        newDataPoint.varName = `val`;
        newDataPoint.componentId = myData.componentId;
        newDataPoint.datavalue = myData.datavalue;
        newDataPoint.source = myData.source;
        newDataPoint.__custom__ = myData.__custom__;
        newDataPoint.timeframe = myData.timeframe;
        console.log('Migrated legacy report config to componentSettings', newDataPoint, myData);
        return [newDataPoint];
    };
    const [componentSettings, setComponentSettings] = React.useState(
        data.componentSettings?.length > 0 ? data.componentSettings : migrateComponentSettingsFromLegacy(data)
    );

    React.useEffect(() => {
        props.onInputChange('componentSettings', componentSettings);
    }, [componentSettings, props]);

    const add_new = () => {
        const newDataPoint = get_new_datapoint();
        newDataPoint.varName = getNewVarName();
        setComponentSettings((oldSet) => [...oldSet, newDataPoint]);
    };
    const getNewVarName = () => {
        return `val${componentSettings.length === 0 ? '' : componentSettings.length + 1}`;
    };
    // callback for deleting a group
    const deleteItem = (index) => {
        //data.componentSettings.splice(index, 1)
        setComponentSettings((oldSet) => oldSet.filter((_, oldIndex) => oldIndex !== index));
        //onInputChange('componentSettings', data.componentSettings)
    };

    // callback for when something change in a group
    const on_component_change = (name, value) => {
        //console.log("on_component_change",value,componentSettings);

        setComponentSettings((oldSet) =>
            oldSet.map((_) => {
                if (value.id !== _.id) return _;
                return value;
            })
        );

        // data.componentSettings[index][name] = value;
        // onInputChange('componentSettings', data.componentSettings)
    };
    //console.log("comp set coll",componentSettings);
    // return the form
    return (
        <>
            <div>
                {componentSettings
                    ? componentSettings.map((element, index) => {
                          return (
                              <fieldset key={`compfldSet_${index}`} className="group-fieldset">
                                  <legend>Component {index + 1}</legend>
                                  <DeleteIcon
                                      onClick={() => {
                                          deleteItem(index);
                                      }}
                                      className="delete-icon"
                                  />
                                  <ComponentEditor
                                      key={`compEd_${index}`}
                                      onInputChange={on_component_change}
                                      datapoint_sources={datapoint_sources}
                                      data={element}
                                      items={props.items}
                                      getDatavaluesFunc={getDatavaluesFunc}
                                      child_jobs={props.child_jobs}
                                  />
                              </fieldset>
                          );
                      })
                    : null}
            </div>
            <button className="btn btn-blue" onClick={add_new}>
                Add Component
            </button>
        </>
    );
}

export function ComponentEditor(props) {
    const onInputChange = props.onInputChange;
    const datapoint_sources = props.datapoint_sources;
    const data = props.data;
    const items = props.items;
    const getDatavaluesFunc = props.getDatavaluesFunc;
    const child_jobs = props.child_jobs;
    const show_timeframe = props.otherProps?.show_timeframe || false;

    const [currentComponent, setCurrentComponent] = React.useState(data.componentId);
    const [currentDatavalue, setCurrentDatavalue] = React.useState(data.datavalue);
    const [datavalues, setDatavalues] = React.useState(null);
    const [fetchingDataValues, setFetchingDataValues] = React.useState(false);

    const [__custom__, setIsCustom] = React.useState(data.__custom__);
    const [source, setSource] = React.useState(data.source);
    const [timeframe, setTimeframe] = React.useState(data.timeframe);
    const [varName, setVarName] = React.useState(data.varName);
    const [aggMethod, setAggMethod] = React.useState(data.aggMethod);

    // validate source and timeframe
    //SW 11.03: -> try not to validate source at this point. leads to unexpected results if you set a value based on datavalue selector, which set source, then it automatically just resets here if
    //datapoint_sources have not been updated. I propose throwing an error instead.
    //Calling setState() here -> validate functions call onChange if data is erroneous.. will cause errors internally.. illegal behavior
    validate_source(source, datapoint_sources, onInputChange);
    validate_timeframe(timeframe, child_jobs, onInputChange);

    // validate datapoint if not custom
    //if (!data.__custom__) validate_datavalue(data.datavalue, datavalues, onInputChange)

    React.useEffect(() => {
        async function validateComponentAndFetchDatavalues() {
            const currentComponenttmp = currentComponent;
            try {
                setFetchingDataValues(true);
                // validate the component id
                //let datacomponentId = data.componentId;
                const topic = validate_componentid(currentComponenttmp, __custom__, items, onInputChange);
                let datavalues = await getDatavaluesFunc(topic);
                //If this bad boy has changed during the time it took to fetch datavalues..
                if (currentComponenttmp !== currentComponent) {
                    console.log(`Component changed. ${currentComponenttmp} no longer current`);
                    return;
                }
                //console.log("Finding...",currentComponenttmp,datavalues);
                // get available datapoints for topic (if not custom)
                datavalues =
                    topic && !__custom__ ? get_datavalues(topic, datavalues, datapoint_sources) : items ? [] : null;
                setDatavalues(datavalues);
            } catch (err) {
                console.log('Error fetching connected datavalues', err, currentComponenttmp);
            } finally {
                setFetchingDataValues(false);
            }
        }
        validateComponentAndFetchDatavalues();
    }, [currentComponent]);

    React.useEffect(() => {
        //Reporting object change further up the tree
        const myName = 'na';
        const newData = { ...data };
        newData.componentId = currentComponent;
        newData.datavalue = currentDatavalue;
        newData.__custom__ = __custom__;
        newData.source = source;
        newData.timeframe = timeframe;
        newData.varName = varName;
        newData.aggMethod = aggMethod;
        onInputChange(myName, newData);
    }, [currentComponent, currentDatavalue, __custom__, source, timeframe, varName, aggMethod]);

    // callback to call when a value changes
    // const on_change = (event) => {
    //    // console.log(event);
    //     var name = event.currentTarget.name
    //     var value = event.currentTarget.value

    //     on_change_internal(name,value);
    // }
    const on_datavalueselect_change = (name, event) => {
        // console.log("datavalueselct change",name,event)
        if (name === 'customdatavalue') {
            //var name = event.currentTarget.name
            const value = event.currentTarget.value;
            setCurrentDatavalue(value);
        } else {
            const originalObj = fromStringRepToDatavalueObject(event.value, datavalues);
            //var datavalueString = originalObj?.dataValue;
            let { datavalue, source } = getDatasourceValueAndSourceFromDataValueObject(originalObj, datapoint_sources);
            //console.log(datavalue,source);

            //on_change_internal("source",source);
            //on_change_internal(name,datavalue);
            setSource(source);
            setCurrentDatavalue(datavalue);
        }
    };

    // callback to call when a value changes
    const on_change_component = (event) => {
        //(name,value) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        // console.log("updating internal",name,value)
        if (name === 'component_selected_value') {
            if (value === '__custom__') {
                setIsCustom(true);

                // onInputChange("__custom__", true)
                // onInputChange("datavalue",data.datavalue?.dataValue);
            } else {
                setIsCustom(false);
                //onInputChange("__custom__", false)
                //onInputChange("componentId", value)
                setCurrentComponent(value);
            }
        } else {
            setCurrentComponent(value);
        }
        // // else if(name === "datavalue"){
        // //     if()
        // // }
        // else onInputChange(name, value)
    };

    // form for editing component timeframe
    const timeframe_form = (
        <div className="form-group">
            <label htmlFor="source">Timeframe</label>
            <select
                required
                name="timeframe"
                className={'input-control'}
                onChange={(event) => setTimeframe(event.currentTarget.value)}
                value={timeframe || ''}
            >
                <option key="t0" value={null}>
                    This job
                </option>
                {child_jobs
                    ? child_jobs.map((element, index) => {
                          return (
                              <option key={'t' + (index + 1)} value={element.id}>
                                  {element.name}
                              </option>
                          );
                      })
                    : null}
            </select>
        </div>
    );

    // form for editing component
    const component = (
        <div className="fields">
            <div className="fields-column">
                <div className="form-group">
                    <label htmlFor="componentId">Component</label>
                    {items ? (
                        <select
                            required
                            name="component_selected_value"
                            className={'input-control'}
                            onChange={on_change_component}
                            value={__custom__ ? '__custom__' : currentComponent}
                        >
                            {items.map((element, index) => {
                                let topic;
                                try {
                                    topic = element.topic.split(/\/(.+)/, 2)[1];
                                } catch {
                                    topic = element.topic;
                                }
                                return (
                                    <option key={'c' + index} value={topic}>
                                        {topic}
                                    </option>
                                );
                            })}
                            <option key="cc" value="__custom__">
                                Custom
                            </option>
                        </select>
                    ) : null}
                    {__custom__ ? (
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="componentId"
                            value={currentComponent}
                            required={true}
                            onChange={on_change_component}
                        />
                    ) : null}
                </div>
            </div>

            <div className="fields-column">
                <div className="form-group">
                    <label htmlFor="datavalue">Datavalue</label>
                    {!__custom__ ? (
                        <DataValueSelect
                            options={datavalues || []}
                            fetchingDataValues={fetchingDataValues}
                            onChange={(selected) => on_datavalueselect_change('datavalue', selected)}
                            widget={{ dataValue: fromSourceAndDataValueToStringRep(source, currentDatavalue) }}
                        />
                    ) : (
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="customdatavalue"
                            value={currentDatavalue}
                            required={true}
                            onChange={(event) => on_datavalueselect_change(event.currentTarget.name, event)}
                        />
                    )}
                </div>
            </div>
        </div>
    );

    // form for editing component datavalue
    const varandagg = (
        <div className="fields">
            <div className="fields-column">
                <div className="form-group">
                    <label htmlFor="varName">Variable Name</label>
                    <input
                        type="text"
                        name="varName"
                        value={varName}
                        className="input-control"
                        onChange={(event) => setVarName(event.currentTarget.value)}
                    />
                </div>
            </div>
            {source === 'data_aggregates' ? (
                <div className="fields-column">
                    <div className="form-group">
                        <label htmlFor="varName">Aggregation method</label>
                        <select
                            required
                            name="aggMethod"
                            className={'input-control'}
                            onChange={(event) => setAggMethod(event.currentTarget.value)}
                            value={aggMethod}
                        >
                            <option value="mean">Average</option>
                            <option value="min">Min</option>
                            <option value="max">Max</option>
                            <option value="sum">Sum</option>
                            <option value="count">Count</option>
                            <option value="std">Standard deviation</option>
                        </select>
                    </div>
                </div>
            ) : null}
        </div>
    );

    // return the form
    return (
        <>
            {show_timeframe ? timeframe_form : null}
            {/* {Object.keys(datapoint_sources).length > 1 ? datasource_form : null} */}
            {component}
            {varandagg}
        </>
    );
}

/*  Description: Function to get all available datavalues for topic and source
    Parameters:
        - topic: topic to filter
        - datavalues: all datavalues
        - source: source of the datavalue to filter
    Returns: list of available datavalues for topic and source
*/
function get_datavalues(topic, datavalues, legalSources) {
    if (!datavalues?.filter) {
        // || !legalSources || legalSources.length===0){
        //no datavalues returned for a given topic.
        return [];
    }
    //If no specified legal sources, return all
    if (!legalSources || legalSources.length === 0) return datavalues;
    //console.log(legalSources)
    return datavalues.filter((element) => {
        if (element.isEvent && 'events' in legalSources) return true;
        else if (element.isTrace && 'trace' in legalSources) return true;
        else if (element.historic && 'data_aggregates' in legalSources) return true;
        else if ('datavalues' in legalSources) return true;
        else return false;
    });
}

/*  Description: Function that validates a component ID
    Parameters:
        - component_id: component id to validate
        - custom_flag: flag for custom topic
        - items: available items
        - onChange: callback when something changes
    Returns: topic
*/
function validate_componentid(component_id, custom_flag, items, onChange) {
    // if undefined, make it empty string
    if (component_id === undefined) onChange('componentId', '');

    if (items && !custom_flag) {
        const availabe_component = items.find((element) => {
            let topic;
            try {
                topic = element.topic.split(/\/(.+)/, 2)[1];
            } catch {
                topic = element.topic;
            }
            return element.id === component_id || topic === component_id;
        });

        if (availabe_component) {
            let topic;
            try {
                topic = availabe_component.topic.split(/\/(.+)/, 2)[1];
            } catch {
                topic = availabe_component.topic;
            }
            // keep the value as topic value in the config to make it easy
            // to copy config from one plant to another
            if (topic !== component_id) onChange('componentId', topic);

            return topic;
        }
    }

    if (!custom_flag) onChange('__custom__', true);

    return component_id;
}

/*  Description: Function that validates a component datavalue
    Parameters:
        - datavalue: datavalue to validate
        - datavalues: avaialble datavalues
        - onChange: callback when something changes durring validation
    Returns: -
*/
// function validate_datavalue(datavalue, datavalues, onChange)
// {
//     if (datavalues)
//     {
//         if (!datavalues.length && datavalue !== "")
//             onChange('datavalue', "")
//         else if (datavalues.length && !datavalues.includes(datavalue.dataValue))
//             onChange('datavalue', datavalues[0])
//     }
// }

/*  Description: Function that validates a component source
    Parameters:
        - source: source to validate
        - datapoint_sources: avaialble sources
        - onChange: callback when something changes durring validation
    Returns: -
*/
function validate_source(source, datapoint_sources, _onChange) {
    //Gets called during render, and it is 'illegal' to call setState() during render.. return true or false instead.
    if (!datapoint_sources) {
        return true; //no specific source specified. all allowed
    }
    if (!source || !(source in datapoint_sources)) {
        if (!(source in datapoint_sources))
            console.warn(
                'Datapoint source has been set to a source that is not listed as supported',
                `Source: ${source}`,
                datapoint_sources
            );
        //onChange('source', Object.keys(datapoint_sources)[0])
        return false;
    }
    return true;
}

/*  Description: Function that validates a timeframe
    Parameters:
        - timeframe: timeframe to validate
        - child_jobs: avaialble child_jobs
        - onChange: callback when something changes durring validation
    Returns: -
*/
function validate_timeframe(timeframe, child_jobs, onChange) {
    if (timeframe && !child_jobs.find((job) => job.id === timeframe)) onChange('timeframe', null);
}

export function GetFormulaField(data, onInputChange) {
    return (
        <div className="form-group">
            <label htmlFor="label">Formula</label>
            <input
                type="text"
                name="formula"
                value={data.formula}
                className="input-control"
                onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
            />
        </div>
    );
}
export function GetDecimalPrecisionField(data, onInputChange) {
    return (
        <div className="form-group">
            <label htmlFor="numericPrecision">Decimal precision</label>
            <input
                autoComplete="off"
                type="number"
                min="0"
                max="10"
                className={'input-control'}
                name="numericPrecision"
                value={data.numericPrecision || 0}
                onChange={(event) => onInputChange(event.currentTarget.name, toNumber(event.currentTarget.value, 0))}
                required={false}
            />
        </div>
    );
}
