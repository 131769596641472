import format from 'date-fns/format';
import moment from 'moment';
import 'moment/locale/en-gb';
import React from 'react';
import 'react-datetime/css/react-datetime.css';
import 'react-notifications/lib/notifications.css';
import CreatableSelect from 'react-select/creatable';
import api from '../../../../utility/api';
import { tryParseJson } from '../../../../utility/jsonFunctions';
import DropdownList from '../../../../components/common/DropdownList/DropdownList';
import './JobTransferEdit.scss';

const selectStyle = { 
    menuPortal: (base) => ({ 
        ...base, 
        zIndex: 9999}),
    control: (css, _) => ({ 
        ...css, 
        backgroundColor: "#0d171e",
        color: "#e6e6f0"
    }),
    input: (css, _) => ({ 
        ...css, 
        color: "#e6e6f0"
    }),
    option: (css, _) => ({ 
        ...css, 
        backgroundColor: "#0d171e",
        color: "#e6e6f0"
    }),
    menu: (css, _) => ({
        ...css,
        border: "1px solid #2d4150",
        marginTop: '0px'
    }),
    menuList: (css, _) => ({
        ...css,
        backgroundColor: "#0d171e"
    })
};

class JobTransferEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job: null,
            jobtypes: [],
            validStart: true,
            validEnd: true,
            dynamicData: {},
            components : [],
            selectedItem  : {destinationTopic: '', name : ''},            
            selectedItemFilterString: '',
            isFetching: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.dateValidation = this.dateValidation.bind(this);
        this.fetchingStructure = false;
        this.fetchingTags = false;
        this.handleConfigChange = this.handleConfigChange.bind(this);
        this.getStructure = this.getStructure.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.handleComponentChange = this.handleComponentChange.bind(this);

        this.endDateRef = React.createRef();
        this.startDateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchJobTypes(this.props.plant.value);
        this.getStructure(this.props.plant.value);

        this.setState({
            job: this.props.editItem,
            dynamicData: tryParseJson(this.props.editItem.data, {}),
            selectedItem: { destinationTopic: this.props.editItem.destinationTopic || '', name : this.props.editItem.destinationTopic || ''}
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props?.editItem.destinationTopic !== prevProps.editItem?.destinationTopic) {
            this.getStructure(this.props.plant.value);
        }

        if (this.props?.editItem.jobTypeId !== prevProps.editItem?.jobTypeId){
            this.fetchJobTypes(this.props.plant.value);
        }

        if (
            this.props?.editItem?.id && this.props?.editItem?.id !== prevProps.editItem?.id
            || this.props?.editItem.destinationTopic !== prevProps.editItem?.destinationTopic
            || this.props?.editItem.jobTypeId !== prevProps.editItem?.jobTypeId)
        {
            this.setState({
                job: this.props.editItem,
                dynamicData: tryParseJson(this.props.editItem.data, {}),
                selectedItem: { destinationTopic: this.props.editItem.destinationTopic || '', name : this.props.editItem.destinationTopic || ''}
            });
        }
    }

    getStructure = async (plantId) => {
        if (!plantId)
        {
            this.setState({
                components : []
            });
            return;
        }

        const apiUrl = process.env.REACT_APP_API + '/api/plants/' + plantId + '/items/structure';       

        const res = await api.get(apiUrl);

        if (res.status === 200) {   
            this.setState({ components : res.data });
        }
    };

    fetchJobTypes = async (plantId) => {
        let jobtypes = [];

        if (plantId)
        {
            const res = await api.get(`${process.env.REACT_APP_API}/api/jobs/types/${plantId}`);

            jobtypes = res.data.map((j) => {
                return {
                    value: j.id,
                    label: j.name,
                    parentId: j.parentId,
                    parentName: j.parentName,
                    plantId: j.plantId,
                    configuration: j.configuration,
                };
            });
        } 

        this.setState({
            jobtypes
        });
    };

    handleTypeChange = (newValue, event) => {
        if (event.action === 'create-option') {
            const url = `${process.env.REACT_APP_API}/api/jobs/types/${this.props.plant.value}/${newValue.label}`;

            api.post(url).then((res) => {
                const jobTypesResult = res.data.map((j) => {
                    return {
                        value: j.id,
                        label: j.name,
                        configuration: '',
                    };
                });

                const jobResult = Object.assign({}, this.state.job);
                const jobType = (jobTypesResult || []).find((j) => j.label === newValue.label) || {};

                jobResult.jobTypeId = jobType?.value;
                jobResult.type = jobType?.label;

                this.onJobUpdate({
                    jobTypeId : jobResult.jobTypeId,
                    type : jobResult.type
                }, 'create-operation-type');

                this.setState(
                    {
                        jobtypes: jobTypesResult,
                        job: jobResult
                    }
                );
            });
        } else {
            const job = Object.assign({}, this.state.job);
            job.jobTypeId = newValue?.value;
            job.type = newValue?.label;
            job.configuration = newValue?.configuration || '[]';

            const dynData = this.buildDynamicDataWithDefaultValues(newValue);

            this.onJobUpdate({
                jobTypeId : job.jobTypeId,
                type : job.type
            }, 'change-operation-type');

            this.setState({ job: job, dynamicData: dynData });
        }
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = this.state.job;
        tmp[name] = value;

        this.setState({ job: tmp });
    }

    dateValidation(date, type) {
        if (type === 'start') {
            return this.state.job.endDate ? date.isSameOrBefore(moment(this.state.job.endDate).endOf('day')) : true;
        } else if (type === 'end') {
            return this.state.job.startDate
                ? date.isSameOrAfter(moment(this.state.job.startDate).startOf('day'))
                : true;
        }
        return;
    }

    handleDateSelect(date, name, dynamic) {
        if (dynamic) {
            const dynamicData = this.state.dynamicData;
            dynamicData[name] = date;

            this.setState({ dynamicData });
        } else {
            const tmp = this.state.job;
            tmp[name] = date;

            this.setState({
                job: tmp,
                showDatePicker: false,
            });
        }
    }

    handleSelectItem(item) {    
        const tmp = this.state.job;
        tmp.destinationTopic = item.destinationTopic != null ? item.topic.substring(item.topic?.indexOf('/') + 1) : '';

        this.onJobUpdate(tmp.destinationTopic, 'change-operation-topic');

        this.setState({
            job: tmp,
            selectedItem: item
        });
    }

    onJobUpdate(field, type) {
        this.props.onJobChanges(this.state.job.id, field, type);
    }

    handleConfigChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const dynamicData = this.state.dynamicData;
        dynamicData[name] = value;

        this.setState({ dynamicData });
    }

    buildDynamicDataWithDefaultValues(jobtype) {
        const dynData = {};
        const config = tryParseJson(jobtype?.configuration, {});

        if (config?.fields && config.fields.map) {
            config.fields.forEach((fld) => {
                if (typeof fld.defaultValue === 'undefined') {
                    return;
                }

                switch (fld.type) {
                    case 'date':
                        if (fld.defaultValue === 'NOW()') {
                            dynData[fld.name] = new Date();
                        } else {
                            dynData[fld.name] = fld.defaultValue;
                        }
                        break;
                    default:
                        dynData[fld.name] = fld.defaultValue;
                        break;
                }
            });
        }
            
        return dynData;
    }

    editField(field, index) {
        const data = this.state.dynamicData;
        const label = field.label || field.name;
        
        if (field.type === 'array') {
            const preppedChoices = field.choices.map((c) => {
                return { value: c, label: c };
            });
            return (
                <div className={'form-group'} key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="text"
                        className={'input-control'}
                        name={field.name}
                        readOnly 
                        disabled
                        value={preppedChoices.find((p) => p.value === data?.[field.name])}
                    />
                </div>
            );
        } else if (field.type === 'date') {
            return (
                <div className="form-group"  key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="text"
                        className={'input-control'}
                        name={field.name}
                        readOnly 
                        disabled
                        value={data?.[field.name] ? format(new Date(data?.[field.name]), 'dd.MM.yyyy HH:mm') : null}
                    />
                </div>
            );
        } else if (field.type === 'text') {
            return (
                <div className="form-group"  key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="text"
                        className={'input-control'}
                        name={field.name}
                        readOnly
                        disabled
                        value={data?.[field.name] || ''}
                    />
                </div>
            );
        } else if (field.type === 'number') {
            return (
                <div className="form-group"  key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="number"
                        className={'input-control'}
                        name={field.name}
                        readOnly
                        disabled
                        value={data?.[field.name] || ''}
                    />
                </div>
            );
        } else if (field.type === 'boolean') {
            return (
                <div className="form-group" key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="checkbox"
                        className={'input-control'}
                        name={field.name}
                        readOnly
                        disabled
                        checked={data?.[field.name]}
                    />
                </div>
            );
        } else {
            //not supported
            return <div key={index}></div>;
        }
    }

    handleComponentChange(e) {
        let jobToUpdate = {...this.state.job};
        jobToUpdate.destinationTopic = '';
        this.setState({ 
            selectedItem: { name : e.target.value}, 
            selectedItemFilterString: e.target.value,
            job: jobToUpdate
        });
    }

    render() {
        const config = tryParseJson(this.state.job?.configuration, {});

        return this.state.job != null ? (
            <form>
                {
                    this.props.editItem?.destinationTopic
                    ? (<div className="form-group">
                        <label htmlFor="component">Component</label>
                        <input
                            type="text"
                            className={'input-control'}
                            name={"Component"}
                            readOnly
                            disabled
                            value={this.props.editItem?.destinationTopic}
                        />
                    </div>) 
                    : (<div className="form-group">
                        <DropdownList
                            modalRef={this.props.modalRef}                      
                            components={this.state.components}
                            onSelectItem={this.handleSelectItem}
                            selectedItem={this.state.selectedItem}
                            onComponentChange={this.handleComponentChange}
                            required={true}
                            filterString={this.state.selectedItemFilterString}
                        />
                    </div>
                    )
                }
                {
                    this.props.editItem?.destinationType 
                    ? (<div className="form-group">
                        <label htmlFor="type">Type</label>
                        <input
                            type="text"
                            className={'input-control'}
                            name={"Type"}
                            readOnly
                            disabled
                            value={this.props.editItem?.destinationType}
                        />
                    </div>) 
                    : (<div className="form-group">
                        <label htmlFor="type">Type</label>
                        <CreatableSelect
                            isClearable
                            onChange={this.handleTypeChange}
                            options={(this.state.jobtypes || [])
                                .filter((j) => j.label === this.props.editItem?.destinationType)}
                            setValue={this.createNewJobType}
                            value={this.state.jobtypes?.find((j) => j.label === this.props.editItem?.destinationType)}
                            classNamePrefix={'optimar'}
                            menuPortalTarget={document.body}
                            styles={selectStyle}
                        />
                    </div>
                    )
                }

                <div className="form-group">
                    <label htmlFor="startDate">Start Date</label>
                    <input
                        type="text"
                        className={'input-control'}
                        name={"Start Date"}
                        readOnly
                        disabled
                        value={this.state.job && this.state.job.startDate && this.state.job.startDate !== "0001-01-01T00:00:00"
                            ? moment.utc(this.state.job.startDate).local().format('DD.MM.YYYY HH:mm')
                            : moment().utc().format('DD.MM.YYYY HH:mm')}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="endDate">End Date</label>
                    <input
                        type="text"
                        className={'input-control'}
                        name={"End Date"}
                        readOnly
                        disabled
                        value={this.state.job && this.state.job.endDate && this.state.job.endDate !== "0001-01-01T00:00:00"
                            ? moment.utc(this.state.job.endDate).local().format('DD.MM.YYYY HH:mm')
                            : ""}
                    />
                </div>

                {config?.fields?.map((f,i) => {
                    return this.editField(f,i);
                })}
            </form>
        ) : null;
    }
}

export default JobTransferEdit;
