import React from 'react';
import { ConfirmationAlert } from './ConfirmationAlert';
/**
 * @type {React.FC<{
 *     onCancel: React.MouseEventHandler<HTMLButtonElement>;
 *     onConfirm: React.MouseEventHandler<HTMLButtonElement>;
 * }>}
 */
export const ConfirmSaveJobTypeDashboardAlert = (props) => (
    <ConfirmationAlert
        title="Save as operation type dashboard"
        description="This will set this dashboard as default for all jobs that use the global operation type"
        {...props}
    />
);
