import React from 'react';

import { datapoints_get_available_customtable_fields, datapoints_source_validate } from './utils/DatapointsUtils';
import { meta_get_available_customtable_fields } from './utils/MetaUtils';

const item_type_name = 'Custom Table';
const configurable = true;

export default () => {
    return {
        name: item_type_name,
        configurable: configurable,
        create_new: create_new,
        get_editor,
    };
};

function get_editor(data, onInputChange, props) {
    const onContentChange = (name, value) => {
        if (name === 'type') onInputChange('content', { type: value, source: '' });
        if (name === 'source') onInputChange('content', { type: data.content.type, source: value });
    };

    let available_sources = undefined;
    let available_fields = undefined;

    if (data.content.type === 'datapoint') {
        available_sources = datapoints_source_validate(
            props.datapoints,
            ['processnotes', 'childrentable'],
            data.content.source,
            (value) => {
                onContentChange('source', value);
            }
        );
        available_fields = datapoints_get_available_customtable_fields(props.datapoints, data.content.source);
    }
    if (data.content.type === 'child') {
        if (
            props.child_jobs &&
            !props.child_jobs.find((element) => element.id.toLowerCase() === data.content.source.toLowerCase())
        )
            onContentChange('source', props.child_jobs[0].id);
        available_fields = meta_get_available_customtable_fields(props.child_jobs, data.content.source.toLowerCase());
    }

    data.fields.forEach((field, index) => {
        const field_found = available_fields.find((element) => element.key === field);

        if (!field_found) {
            if (!available_fields || available_fields.length === 0) {
                if (field !== '') {
                    data.fields[index] = '';
                    onInputChange('fields', data.fields);
                }
            } else {
                data.fields[index] = available_fields[0].key;
                onInputChange('fields', data.fields);
            }
        }
    });

    const content_type_editor = (
        <>
            <div className="form-group">
                <label htmlFor="type">Source Type</label>
                <select
                    required
                    name="type"
                    className={'input-control'}
                    onChange={(event) => onContentChange(event.currentTarget.name, event.currentTarget.value)}
                    value={data.content.type}
                >
                    <option key="ct1" value="datapoint">
                        Table Datapoint
                    </option>
                    <option key="ct2" value="child">
                        Child Metadata
                    </option>
                </select>
            </div>
            {data.content.type === 'datapoint' ? (
                <div className="form-group">
                    <label htmlFor="source">Source</label>
                    <select
                        required
                        name="source"
                        className={'input-control'}
                        onChange={(event) => onContentChange(event.currentTarget.name, event.currentTarget.value)}
                        value={data.content.source}
                    >
                        {available_sources
                            ? available_sources.map((element, index) => {
                                  return (
                                      <option key={'csd' + index} value={element.datapointId}>
                                          {element.datapointId}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                </div>
            ) : null}
            {data.content.type === 'child' ? (
                <div className="form-group">
                    <label htmlFor="source">Source</label>
                    <select
                        required
                        name="source"
                        className={'input-control'}
                        onChange={(event) => onContentChange(event.currentTarget.name, event.currentTarget.value)}
                        value={data.content.source.toLowerCase()}
                    >
                        {props.child_jobs
                            ? props.child_jobs.map((element, index) => {
                                  return (
                                      <option key={'csm' + index} value={element.id}>
                                          {element.name}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                </div>
            ) : null}
        </>
    );

    const header_data = header_utility.get_columns(data.header);
    const has_categories = Object.keys(header_data.categories).length > 0;

    const header_editor = (
        <>
            <div className="form-group">
                <label htmlFor="has_categories">Header has categories</label>
                <input
                    autoComplete="off"
                    type="checkbox"
                    className={'input-control'}
                    name="has_categories"
                    checked={has_categories}
                    required={true}
                    onChange={() =>
                        header_utility.set_has_categories(data, header_data, !has_categories, onInputChange)
                    }
                />
            </div>
            <label>Table Columns</label>
            <div></div>
            {has_categories ? (
                <>
                    {header_data.categories.map((category, index) => {
                        return (
                            <div key={'ctc' + index} className="customtable_header_l1">
                                <span
                                    className="fa fa-plus-circle"
                                    style={{ color: 'green' }}
                                    onClick={() =>
                                        header_utility.add_column_to_category(data, index, category, onInputChange)
                                    }
                                ></span>
                                <span
                                    className="fa fa-minus-circle"
                                    style={{ color: 'red' }}
                                    onClick={() => header_utility.delete_category(data, index, category, onInputChange)}
                                ></span>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="category"
                                    value={category.name}
                                    required={true}
                                    onChange={(event) =>
                                        header_utility.edit_category_label(
                                            data,
                                            index,
                                            category,
                                            event.currentTarget.value,
                                            onInputChange
                                        )
                                    }
                                />
                                {header_data.columns
                                    .filter(
                                        (_v, index) => index >= category.start && index < category.start + category.span
                                    )
                                    .map((element, c_index) => {
                                        return (
                                            <div
                                                draggable={true}
                                                key={'cth' + c_index}
                                                className="customtable_header_l2"
                                                onDrag={() =>
                                                    header_utility.on_category_drag(
                                                        data,
                                                        category.start + c_index,
                                                        c_index,
                                                        index,
                                                        onInputChange
                                                    )
                                                }
                                                onDragOver={(event) =>
                                                    header_utility.on_category_drag_over(
                                                        event,
                                                        data,
                                                        category.start + c_index,
                                                        c_index,
                                                        index,
                                                        onInputChange
                                                    )
                                                }
                                                onDragEnd={() => header_utility.on_category_drag_end(onInputChange)}
                                            >
                                                <span
                                                    className="fa fa-minus-circle"
                                                    style={{ color: 'red' }}
                                                    onClick={() =>
                                                        header_utility.delete_column_from_category(
                                                            data,
                                                            index,
                                                            category,
                                                            c_index,
                                                            onInputChange
                                                        )
                                                    }
                                                ></span>
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="label"
                                                    value={element}
                                                    required={true}
                                                    onChange={(event) =>
                                                        header_utility.edit_column_from_category(
                                                            data,
                                                            index,
                                                            category,
                                                            c_index,
                                                            event.currentTarget.name,
                                                            event.currentTarget.value,
                                                            onInputChange
                                                        )
                                                    }
                                                />
                                                <select
                                                    required
                                                    name="field"
                                                    onChange={(event) =>
                                                        header_utility.edit_column_from_category(
                                                            data,
                                                            index,
                                                            category,
                                                            c_index,
                                                            event.currentTarget.name,
                                                            event.currentTarget.value,
                                                            onInputChange
                                                        )
                                                    }
                                                    value={data.fields[c_index + category.start]}
                                                >
                                                    {available_fields
                                                        ? available_fields.map((element, f_index) => {
                                                              return (
                                                                  <option key={'cf1' + f_index} value={element.key}>
                                                                      {element.label}
                                                                  </option>
                                                              );
                                                          })
                                                        : null}
                                                </select>
                                                <span className="fa fa-arrows-alt"></span>
                                            </div>
                                        );
                                    })}
                            </div>
                        );
                    })}
                    <button onClick={() => header_utility.add_category(data, onInputChange)} className="btn btn-blue">
                        New Category
                    </button>
                </>
            ) : (
                <>
                    {header_data.columns.map((element, index) => {
                        return (
                            <div
                                draggable={true}
                                key={'cth' + index}
                                className="customtable_header_l1"
                                onDrag={() => header_utility.on_column_drag(data, index, onInputChange)}
                                onDragOver={(event) =>
                                    header_utility.on_column_drag_over(event, data, index, onInputChange)
                                }
                                onDragEnd={() => header_utility.on_column_drag_end(onInputChange)}
                            >
                                <span
                                    className="fa fa-minus-circle"
                                    style={{ color: 'red' }}
                                    onClick={() => header_utility.delete_column(data, index, onInputChange)}
                                ></span>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="label"
                                    value={element}
                                    required={true}
                                    onChange={(event) =>
                                        header_utility.edit_column(
                                            data,
                                            index,
                                            event.currentTarget.name,
                                            event.currentTarget.value,
                                            onInputChange
                                        )
                                    }
                                />
                                <select
                                    required
                                    name="field"
                                    onChange={(event) =>
                                        header_utility.edit_column(
                                            data,
                                            index,
                                            event.currentTarget.name,
                                            event.currentTarget.value,
                                            onInputChange
                                        )
                                    }
                                    value={data.fields[index]}
                                >
                                    {available_fields
                                        ? available_fields.map((element, f_index) => {
                                              return (
                                                  <option key={'cf1' + f_index} value={element.key}>
                                                      {element.label}
                                                  </option>
                                              );
                                          })
                                        : null}
                                </select>
                                <span className="fa fa-arrows-alt"></span>
                            </div>
                        );
                    })}
                    <button onClick={() => header_utility.add_column(data, onInputChange)} className="btn btn-blue">
                        New Column
                    </button>
                </>
            )}
        </>
    );

    const sortby_editor = (
        <div className="form-group">
            <label htmlFor="sortby">Sort By</label>
            <select
                required
                name="sortby"
                className={'input-control'}
                onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                value={data.sortby}
            >
                {available_fields
                    ? available_fields.map((element, f_index) => {
                          return (
                              <option key={'sb1' + f_index} value={element.key.split('#')[0]}>
                                  {element.label}
                              </option>
                          );
                      })
                    : null}
            </select>
        </div>
    );

    const textfield_editor = (
        <div className="form-group">
            <label htmlFor="text_field">Text Column</label>
            <select
                required
                name="text_field"
                className={'input-control'}
                onChange={(event) => {
                    event.currentTarget.value >= 0
                        ? onInputChange(event.currentTarget.name, event.currentTarget.value)
                        : onInputChange(event.currentTarget.name, undefined);
                }}
                value={data.hasOwnProperty('text_field') ? data.text_field : -1}
            >
                <option key={'tc'} value={-1}>
                    None
                </option>
                {header_data.columns.map((element, f_index) => {
                    return (
                        <option key={'tc' + f_index} value={f_index}>
                            {element}
                        </option>
                    );
                })}
            </select>
        </div>
    );

    return (
        <>
            {content_type_editor}
            {header_editor}
            {sortby_editor}
            {textfield_editor}
        </>
    );
}

function create_new() {
    return {
        type: 'customtable',
        content: {
            type: 'datapoint',
            source: '',
        },
        sortby: '',
        header: [],
        fields: [],
    };
}

const header_utility = {
    set_has_categories: (data, header_data, value, onChange) => {
        if (value) data.header = [{ '': header_data.columns }];
        else data.header = header_data.columns;

        onChange('header', data.header);
    },
    get_columns: (header_config) => {
        const columns = [];
        const categories = [];
        let category_start = 0;

        header_config.forEach((element) => {
            if (typeof element === 'string') columns.push(element);
            else {
                const my_key = Object.keys(element)[0];

                categories.push({
                    name: my_key,
                    span: element[my_key].length,
                    start: category_start,
                });
                category_start += element[my_key].length;
                columns.push(...header_utility.get_columns(element[my_key]).columns);
            }
        });

        return {
            columns,
            categories,
        };
    },
    delete_category: (data, category_index, category_data, onChange) => {
        data.header.splice(category_index, 1);
        data.fields.splice(category_data.start, category_data.span);
        onChange('header', data.header);
        onChange('fields', data.fields);
    },
    add_category: (data, onChange) => {
        data.header.push({
            '': [],
        });
        onChange('header', data.header);
    },
    add_column_to_category: (data, category_index, category_data, onChange) => {
        data.header[category_index][category_data.name].push('');
        data.fields.splice(category_data.start + category_data.span, 0, '');
        onChange('header', data.header);
        onChange('fields', data.fields);
    },
    delete_column_from_category: (data, category_index, category_data, column_index, onChange) => {
        data.header[category_index][category_data.name].splice(column_index, 1);
        data.fields.splice(category_data.start + column_index, 1);
        onChange('header', data.header);
        onChange('fields', data.fields);
    },
    edit_category_label: (data, category_index, category_data, value, onChange) => {
        const columns = data.header[category_index][category_data.name];
        data.header[category_index] = {};
        data.header[category_index][value] = columns;
        onChange('header', data.header);
    },
    edit_column_from_category: (data, category_index, category_data, column_index, key, value, onChange) => {
        if (key === 'label') {
            data.header[category_index][category_data.name][column_index] = value;
            onChange('header', data.header);
        } else if (key === 'field') {
            data.fields[category_data.start + column_index] = value;
            onChange('fields', data.fields);
        }
    },
    delete_column: (data, index, onChange) => {
        data.header.splice(index, 1);
        data.fields.splice(index, 1);
        onChange('header', data.header);
        onChange('fields', data.fields);
    },
    add_column: (data, onChange) => {
        data.header.push('');
        data.fields.push('');
        onChange('header', data.header);
        onChange('fields', data.fields);
    },
    edit_column: (data, column_index, key, value, onChange) => {
        if (key === 'label') {
            data.header[column_index] = value;
            onChange('header', data.header);
        } else if (key === 'field') {
            data.fields[column_index] = value;
            onChange('fields', data.fields);
        }
    },
    // drag functions
    // columns
    on_column_drag: (data, index, onChange) => {
        if (!data.drag) {
            data.drag = {
                index,
                type: 0,
            };
            onChange('drag', data.drag);
        }
    },
    on_column_drag_over: (event, data, index, onChange) => {
        if (data.drag.type === 0) event.preventDefault();
        else return;

        if (index !== data.drag.index) {
            const this_value = data.header[data.drag.index];
            const this_source = data.fields[data.drag.index];

            data.header.splice(data.drag.index, 1);
            data.header.splice(index, 0, this_value);

            data.fields.splice(data.drag.index, 1);
            data.fields.splice(index, 0, this_source);

            if (data.sortby === data.drag.index) data.sortby = index;
            else if (data.sortby > data.drag.index && data.sortby <= index) data.sortby--;
            else if (data.sortby < data.drag.index && data.sortby >= index) data.sortby++;

            onChange('header', data.header);
            onChange('fields', data.fields);
            onChange('drag', { index, type: 0 });
            onChange('sortby', data.sortby);
        }
    },
    on_column_drag_end: (onChange) => {
        onChange('drag', undefined);
    },
    // categories
    on_category_drag: (data, index, in_category_index, category_index, onChange) => {
        if (!data.drag) {
            data.drag = {
                index,
                type: 1,
                in_category_index,
                category_index,
            };
            onChange('drag', data.drag);
        }
    },
    on_category_drag_over: (event, data, index, in_category_index, category_index, onChange) => {
        if (data.drag.type === 1) event.preventDefault();
        else return;

        if (index !== data.drag.index) {
            const from_category = Object.keys(data.header[data.drag.category_index])[0];
            const this_value = data.header[data.drag.category_index][from_category][data.drag.in_category_index];

            if (category_index === data.drag.category_index) {
                data.header[category_index][from_category][data.drag.in_category_index] =
                    data.header[category_index][from_category][in_category_index];
                data.header[category_index][from_category][in_category_index] = this_value;
            } else {
                const to_category = Object.keys(data.header[category_index])[0];

                data.header[data.drag.category_index][from_category].splice(data.drag.in_category_index, 1);
                data.header[category_index][to_category].splice(in_category_index, 0, this_value);
            }

            const this_source = data.fields[data.drag.index];
            data.fields.splice(data.drag.index, 1);
            data.fields.splice(index, 0, this_source);

            if (data.sortby === data.drag.index) data.sortby = index;
            else if (data.sortby > data.drag.index && data.sortby <= index) data.sortby--;
            else if (data.sortby < data.drag.index && data.sortby >= index) data.sortby++;

            onChange('header', data.header);
            onChange('fields', data.fields);
            onChange('drag', { index, type: 1, in_category_index, category_index });
            onChange('sortby', data.sortby);
        }
    },
    on_category_drag_end: (onChange) => {
        onChange('drag', undefined);
    },
};
