import { SET_ACCOUNT_NOTELIST, CLEAR_ACCOUNT_NOTELIST, SET_ACCOUNT_DOCLIST, CLEAR_ACCOUNT_DOCLIST } from '../actions';

const noteList = (
    state = {
        noteList: undefined,
        docList: undefined,
    },
    action
) => {
    switch (action.type) {
        case SET_ACCOUNT_NOTELIST:
            return {
                ...state,
                noteList: action.data,
            };
        case CLEAR_ACCOUNT_NOTELIST:
            return {
                ...state,
                noteList: undefined,
            };
        case SET_ACCOUNT_DOCLIST:
            return {
                ...state,
                docList: action.data,
            };
        case CLEAR_ACCOUNT_DOCLIST:
            return {
                ...state,
                docList: undefined,
            };
        default:
            return state;
    }
};

export default noteList;
