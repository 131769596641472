import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
    let reactPlugin = null;
    let appInsights = null;

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (instrumentationKey, browserHistory) => {
        if (!instrumentationKey || !browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        try {
            reactPlugin = new ReactPlugin();

            appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: instrumentationKey,
                    maxBatchInterval: 0,
                    disableFetchTracking: true,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: {
                            history: browserHistory,
                        },
                    },
                },
            });

            appInsights.loadAppInsights();
        } catch (err) {
            console.warn('Error loading app insights', err);
        }
    };

    const getAppInsights = () => {
        return appInsights;
    };

    const getReactPlugin = () => {
        return reactPlugin;
    };

    return { getReactPlugin, getAppInsights, initialize };
};

export const ai = createTelemetryService();
