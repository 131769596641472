import React from 'react';
import './TreeOperation.scss';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import { tryParseJson } from '../../../utility/jsonFunctions';
import { ReactComponent as AngleRight } from '../../../images/icons/optimar_angle_right_white.svg';
import { ReactComponent as AngleDown } from '../../../images/icons/optimar_angle_down.svg';

function processTreeData(items, currentItem) {
    currentItem.children = items.filter(item => item.parentJobId === currentItem.id) || [];

    for (let index in currentItem.children) {
        processTreeData(items, currentItem.children[index]);
    }
}

const JobNodeElement = ({o,onJobSelect, isExpanded, handleExpandedToggle}) => {                
        const validSymbol = o.isValid  
                ? <FontAwesome className="fa fa-check-circle-o" name="check-circle"/> 
                : <FontAwesome className="fa-exclamation-circle" name="exclamation-circle"/>;

        const jobTitle = o.typeName;
            
        const startDate = o.startDate ? moment.utc(o.startDate).local().format('DD.MM.YYYY HH:mm')
                            : moment().utc().format('DD.MM.YYYY HH:mm');
        const endDate = o.endDate && o.endDate !== "0001-01-01T00:00:00" ? moment.utc(o.endDate).local().format('DD.MM.YYYY HH:mm')
                            : "";

        const config = tryParseJson(o.configuration, {});
        const data = tryParseJson(o.data, {});

        return (
            <ul key={o.id}>
                <li>
                    <h2>                        
                        <span>
                            {o.children && o.children.length > 0 ? (
                                isExpanded ? (
                                    <AngleDown 
                                        style={{
                                                cursor: 'pointer'
                                        }} 
                                        onClick={handleExpandedToggle}
                                    />
                                ) : (
                                    <AngleRight 
                                        style={{
                                            cursor: 'pointer'
                                        }} 
                                        onClick={handleExpandedToggle}
                                    />
                                )
                            ) : null}
                        </span>
                        <span
                            onClick={
                                o.isValid === false 
                                ? (() => onJobSelect(o)) 
                                : undefined
                            }
                            style={
                                o.isValid === false 
                                ? { 
                                    cursor: 'pointer',
                                    color : '#a40000'
                                } 
                                : { 
                                    cursor : 'default', 
                                    color : '#41c9f0'
                                }
                            }
                        > 
                            &nbsp;{validSymbol}&nbsp;{jobTitle}
                        </span>
                    </h2>   
                </li>
                {(o.validationMessage || []).map((message,index) => 
                    <li key={index} style={
                            { 
                                backgroundColor : '#221b21',
                                borderRadius: '10px',
                                border: '2px solid #a40000',
                                padding: '7px',
                                margin: '7px 20px 7px 0px'
                            }
                        }>
                        &nbsp;<FontAwesome className="fa-exclamation-circle" name="exclamation-circle"/>&nbsp;
                        {message || ""}
                    </li>
                )}
                <li>Component:&nbsp;{o.topic || ""}</li>
                <li>Operation type:&nbsp;{o.typeName || ""}</li>
                <li>Start date:&nbsp;{startDate}</li>
                <li>End date:&nbsp;{endDate}</li>
                {config?.fields?.map((field,index) => {
                    const label = field.label || field.name;
                    let value = data?.[field.name];
                    const preppedChoices = (field.choices || []).map((c) => {
                        return { value: c, label: c };
                    });

                    switch (field.type) {
                        case 'date':
                            value = value && value !== "0001-01-01T00:00:00"
                                ? moment.utc(value).local().format('DD.MM.YYYY HH:mm')
                                : "";
                            return <li key={index}>{label}:&nbsp;{value || ''}</li>;
                        case 'array':
                            return <li key={index}>{label}:&nbsp;{preppedChoices.find(p => p.value === value) || ''}</li>;
                        case 'boolean':
                            return <li key={index}>{label}:&nbsp;
                                        <input
                                            type="checkbox"
                                            readOnly
                                            disabled
                                            checked={value}
                                        />
                                    </li>;
                        default:
                            return <li key={index}>{label}:&nbsp;{value || ''}</li>;
                    }
                })}
            </ul>);
};

const TreeNode = ({ node, onJobSelect }) => {
    const [isExpanded, setIsExpanded] = React.useState(true);

    const handleExpandedToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="tree__node">
        <div className="tree__node-label">
            <JobNodeElement
                o={node}
                onJobSelect={onJobSelect}
                isExpanded={isExpanded}
                handleExpandedToggle={handleExpandedToggle}
            >
            </JobNodeElement>
        </div>
        {node.children && isExpanded && (
            <div className="tree__children">
            {node.children.map((childNode) => (
                <TreeNode 
                    node={childNode} 
                    key={childNode.id} 
                    onJobSelect={onJobSelect}
                />
            ))}
            </div>
        )}
        </div>
    );
};

const Tree = ({ data, onJobSelect }) => {
    return (
        <div className="tree">
        {data.map((node) => (
            <TreeNode node={node} key={node.id} onJobSelect={onJobSelect}/>
        ))}
        </div>
    );
};

export default function TreeOperation({ plant, jobs, rootJobId, onJobSelect }) {     
    const rootJob = jobs.find(item => item.id === rootJobId);
    processTreeData(jobs, rootJob);

    return (
        <div className="transfer-operation-edit-tree">
            <h3>From:&nbsp;{plant.label}</h3>
            <div className="form-container">
                <Tree data={[rootJob]} onJobSelect={onJobSelect}/>
            </div>
        </div>
    );
}
