import { SET_CHART_INFO } from '../actions';
import { SET_END_DATE } from '../actions';
import { SET_PERIOD } from '../actions';
import { CLEAR_DATE_RANGE } from '../actions';
import { GET_CHART_INFO_REQ } from '../actions';
import { GET_CHART_INFO_SUCC } from '../actions';
import { GET_CHART_INFO_FAIL } from '../actions';
import { SET_WIDGET } from '../actions';
import { GET_WIDGET } from '../actions';
import { SET_DASHBOARD_CONFIG } from '../actions';
import { GET_DASHBOARD_CONFIG } from '../actions';

const itemrow = (
    state = {
        chartResult: {},
        endDateParent: null,
        endDate: null,
        period: null,
        periodParent: {
            period: 24,
            interval: 'day',
            ticks: 'hour',
        },
        error: null,
        dashboardConfig: null,
    },
    action
) => {
    switch (action.type) {
        case SET_CHART_INFO:
            return {
                ...state,
                chartResult: { ...state.chartResult, [action.id]: action.data },
            };
        case SET_END_DATE:
            if (action.parent) {
                return {
                    ...state,
                    endDateParent: action.data,
                    endDate: null,
                };
            } else {
                return {
                    ...state,
                    endDate: { ...state.endDate, [action.id]: action.data },
                };
            }
        case SET_PERIOD:
            if (action.parent) {
                return {
                    ...state,
                    periodParent: action.data,
                    period: null,
                };
            } else {
                return {
                    ...state,
                    period: { ...state.period, [action.id]: action.data },
                };
            }
        case CLEAR_DATE_RANGE:
            return {
                ...state,
                periodParent: null,
                period: null,
                endDateParent: null,
                endDate: null,
            };
        case GET_CHART_INFO_REQ:
            return {
                ...state,
            };
        case GET_CHART_INFO_SUCC:
            return {
                ...state,
                chartResult: action.data,
            };
        case GET_CHART_INFO_FAIL:
            return {
                ...state,
            };
        case SET_WIDGET:
            return {
                ...state,
                widget: action.data,
            };
        case GET_WIDGET:
            return {
                ...state,
            };
        case SET_DASHBOARD_CONFIG:
            return {
                ...state,
                dashboardConfig: action.data,
            };
        case GET_DASHBOARD_CONFIG:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default itemrow;
