import { ViewMode } from 'gantt-task-react';
import moment from 'moment';
import React from 'react';
import './GanttChartConfiguration.scss';

export function GanttChartConfiguration({ view }) {
    let columnWidth = 150;

    if (view === ViewMode.Month) {
        columnWidth = 200;
    } else if (view === ViewMode.Week) {
        columnWidth = 150;
    } else if (view === ViewMode.Day) {
        columnWidth = 100;
    } else if (view === ViewMode.Hour) {
        columnWidth = 50;
    }

    const TooltipContent = ({ task, fontSize, fontFamily }) => {
        if (task) {
            return (
                <div className="tooltip-wrapper">
                    <span>
                        <b style={{ fontSize: fontSize + 6 }}>{task.name}</b>
                    </span>
                    <hr />
                    {task.childCount > 0 && <span>Child operation(s): {task.childCount}</span>}
                    <span>Start date: {moment(task.start).format('DD.MM.YYYY HH:mm:ss')} </span>
                    <span>End date: {moment(task.end).format('DD.MM.YYYY HH:mm:ss')} </span>

                    {task.end.getTime() - task.start.getTime() !== 0 && (
                        <span>{`Duration: ${~~(
                            (task.end.getTime() - task.start.getTime()) /
                            (1000 * 60 * 60 * 24)
                        )} day(s)`}</span>
                    )}
                    {task.progress ? <span>Progress: {task.progress.toFixed(2)}%</span> : null}
                </div>
            );
        }
    };

    const fontSize = '15px';
    const rowWidth = '250px';
    const maxWidth = '500px';

    const CustomTaskListTable = ({ rowHeight, tasks, fontFamily, locale, onExpanderClick }) => {
        return (
            <div
                className="taskListWrapper"
                style={{
                    fontFamily: fontFamily,
                    fontSize: fontSize,
                }}
            >
                {tasks.map((t) => {
                    let expanderSymbol = '';
                    if (t.childCount > 0) {
                        if (t.hideChildren === false) {
                            expanderSymbol = '▼';
                        } else if (t.hideChildren === true) {
                            expanderSymbol = '▶';
                        }
                    }

                    return (
                        <div className="taskListTableRow" style={{ height: rowHeight }} key={`${t.id}row`}>
                            <div
                                className="taskListCell"
                                style={{
                                    minWidth: rowWidth,
                                    maxWidth: maxWidth,
                                }}
                                title={t.name}
                            >
                                <div className="taskListNameWrapper">
                                    <div
                                        className={expanderSymbol ? 'taskListExpander' : 'taskListEmptyExpander'}
                                        onClick={() => onExpanderClick(t)}
                                    >
                                        {expanderSymbol}
                                    </div>
                                    <div className="taskListName" style={{ paddingLeft: t.indentLevel * 10 + 'px' }}>
                                        {t.name}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="taskListCell data"
                                style={{
                                    minWidth: rowWidth,
                                    maxWidth: rowWidth,
                                }}
                            >
                                {getDataCol(t.data)}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
    const CustomTaskListHeader = ({ headerHeight, fontFamily }) => {
        return (
            <div
                className="ganttTable"
                style={{
                    fontFamily: fontFamily,
                    fontSize: fontSize,
                }}
            >
                <div
                    className="ganttTable_Header"
                    style={{
                        height: headerHeight - 2,
                    }}
                >
                    <div
                        className="ganttTable_HeaderItem"
                        style={{
                            minWidth: rowWidth,
                        }}
                    >
                        &nbsp;Type
                    </div>
                    <div
                        className="ganttTable_HeaderItem data"
                        style={{
                            minWidth: rowWidth,
                        }}
                    >
                        &nbsp;Data
                    </div>
                </div>
            </div>
        );
    };

    return {
        table: {
            columnWidth: columnWidth,
        },
        tooltip: TooltipContent,
        customTaskListTable: CustomTaskListTable,
        customTaskListHeader: CustomTaskListHeader,
    };
}

export const getDataCol = (data, jobTypes = null) => {
    if (data) {
        if (!data?.jobTypeId) {
            return <div />;
        }
        try {
            let configAsString;
            if (jobTypes) {
                configAsString = jobTypes?.find((x) => data.jobTypeId === x.value)?.configuration || '{}';
            } else {
                configAsString = data.configuration || '{}';
            }
            const config = JSON.parse(configAsString);
            return (
                <ul className="job-data">
                    {Object.entries(JSON.parse(data.data || '{}')).map((o, index) => {
                        const valueName = o[0];
                        const value = o[1];
                        const matchingFieldConfig = config?.fields?.find((fld) => fld.name === valueName);
                        const visible =
                            typeof matchingFieldConfig?.displayInList === 'undefined'
                                ? true
                                : matchingFieldConfig.displayInList;
                        const displayLabel =
                            typeof matchingFieldConfig?.displayLabelInList === 'undefined'
                                ? true
                                : matchingFieldConfig.displayLabelInList;
                        const label = matchingFieldConfig?.label || valueName;
                        return visible === true ? (
                            <li key={index}>
                                {(displayLabel ? `${label}: ` : '') +
                                    (isDate(value, matchingFieldConfig)
                                        ? moment.utc(value).local().format('DD.MM.yyyy HH:mm')
                                        : value)}
                            </li>
                        ) : null;
                    })}
                </ul>
            );
        } catch (err) {
            console.log(`Unhandled error rendering data column || ${err}`);
            return <div />;
        }
    }
};

const isDate = (date, fieldConfig) => {
    if (fieldConfig?.type.indexOf('date') < 0) {
        return false;
    }
    const isnan = isNaN(date);
    //Seems like numbers like 1, 2, 3 etc can get parsed to a date in 2001.. :D
    if (isnan === false) {
        //if it is a number.. then it is hopefully not a date..
        return false;
    }

    const d = moment(date);
    const earliestDate = moment('1990-01-01');

    // console.log(d,earliestDate,d.isValid());
    return d.isValid() && d.isAfter(earliestDate);
};
