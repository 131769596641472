import React from 'react';
import Modal from 'react-modal';

import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';

import DatapointsFactory from './datapoints/DatapointsFactory';
import { DatapointEdit, DatapointNew } from './datapoints/DatapointsFactory';

export default function DatapointsDesigner(props) {
    const data = props.data;
    const [new_datapoint_modal, setNewDatapointModal] = React.useState(false);
    const [edit_datapoint_modal, setEditDatapointModal] = React.useState(false);
    const [edit_datapoint_index, setEditDatapointIndex] = React.useState(null);

    // datapoints table context menu
    const datapoints_table_context = [
        {
            content: <span>Edit datapoint</span>,
            action: (row_props) => {
                openEditDatapointModal(row_props.row.index);
            },
        },
        {
            content: <span>Delete datapoint</span>,
            action: (row_props) => {
                // TODO: Check if datapoint is in use
                data.splice(row_props.row.index, 1);
                props.onDataChange(data);
            },
        },
    ];

    // datapoints table columns definition
    const datapoints_table_columns = [
        { Header: 'ID', accessor: 'datapointId', disableSortBy: true, disableFilters: true },
        {
            Header: 'Type',
            disableSortBy: true,
            disableFilters: true,
            Cell: (row) => DatapointsFactory.get_name(row.row.original.type),
        },
        {
            Header: 'Details',
            disableSortBy: true,
            disableFilters: true,
            Cell: (row) => DatapointsFactory.get_details(row.row.original),
        },
        {
            Header: () => null,
            id: 'rowcontext',
            Cell: (row) => <RowContextMenu options={datapoints_table_context} row={row} />,
        },
    ];

    const closeNewDatapointModal = () => setNewDatapointModal(false);

    const addNewDatapoint = (new_datapoint) => {
        data.push(new_datapoint);
        props.onDataChange(data);
    };

    // functions for opening / closing datapoint edit modal
    const openEditDatapointModal = (index) => {
        setEditDatapointModal(true);
        setEditDatapointIndex(index);
    };
    const closeEditDatapointModal = () => {
        setEditDatapointModal(false);
        setEditDatapointIndex(null);
    };

    // callback to update the data of the currently editing datapoint
    const updateEditedDatapoint = (config) => {
        if (JSON.stringify(data[edit_datapoint_index]) !== JSON.stringify(config)) {
            // TODO: Check if it is in use
            data[edit_datapoint_index] = config;
            props.onDataChange(data);
        }
    };

    return (
        <>
            <div className="main-header">
                <span>
                    <h2>{data.length} Datapoints</h2>
                    <span className="main-header-action" onClick={() => setNewDatapointModal(true)}>
                        <span className="list-expand-toggle">+ New datapoint</span>
                    </span>
                </span>
            </div>

            <GenericTable data={data} columns={datapoints_table_columns} localPaging={true} />

            <Modal
                isOpen={edit_datapoint_modal}
                onRequestClose={closeEditDatapointModal}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                className={'modal_content'}
            >
                <DatapointEdit
                    config={edit_datapoint_index !== null ? JSON.parse(JSON.stringify(data[edit_datapoint_index])) : {}}
                    closeCallback={closeEditDatapointModal}
                    saveCallback={updateEditedDatapoint}
                    items={props.items}
                    //datavalues={props.datavalues}
                    getDatavaluesFunc={props.getDatavaluesFunc}
                    child_jobs={props.child_jobs}
                    job_type={props.job_type}
                />
            </Modal>

            <Modal
                isOpen={new_datapoint_modal}
                onRequestClose={closeNewDatapointModal}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                className={'modal_content'}
            >
                <DatapointNew
                    closeCallback={closeNewDatapointModal}
                    saveCallback={addNewDatapoint}
                    items={props.items}
                    //datavalues={props.datavalues}
                    getDatavaluesFunc={props.getDatavaluesFunc}
                    child_jobs={props.child_jobs}
                    job_type={props.job_type}
                />
            </Modal>
        </>
    );
}
