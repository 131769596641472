import React from 'react';
import PropTypes from 'prop-types';
import '../widget.css';
import './UserInput.scss';
import AddKpiData from '../../common/AddKpiData/AddKpiData';
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '30vw',
    },
};

class UserInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputModalIsOpen: false,
        };
        this.overlayRef = null;
        this.mounted = true;
        this.closeInputModal = this.closeInputModal.bind(this);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    openInputModal(e) {
        e.stopPropagation();

        this.setState({
            inputModalIsOpen: true,
        });
    }

    closeInputModal() {
        this.setState({
            inputModalIsOpen: false,
        });
    }

    render() {
        const config = this.props.configuration;

        return (
            <>
                <div className={'user-input-wrapper'}>
                    <button className="btn btn-blue manual-add" onClick={(e) => this.openInputModal(e)}>
                        {this.props.title}
                    </button>
                </div>

                {
                    <Modal
                        isOpen={this.state.inputModalIsOpen}
                        onRequestClose={this.closeInputModal}
                        style={customStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <AddKpiData {...this.props} config={config} closeModal={this.closeInputModal} />
                    </Modal>
                }
            </>
        );
    }
}

UserInput.propTypes = {
    itemId: PropTypes.string,
};

export default UserInput;
