import React from 'react';
import { ReactComponent as GaugeHorizontal } from '../../../images/widgets/optimar_component_icon__horizontal_bar.svg';
import { ReactComponent as GaugeRound } from '../../../images/widgets/optimar_component_icon__small_circle_gauge.svg';
import { ReactComponent as GraphHistogram } from '../../../images/widgets/optimar_component_icon__bar_chart.svg';
import { ReactComponent as GraphLine } from '../../../images/widgets/optimar_component_icon__line_chart.svg';
import { ReactComponent as ElementText } from '../../../images/widgets/optimar_component_icon__plain_number.svg';
import { ReactComponent as SingleNumber } from '../../../images/widgets/optimar_component_icon__plain_number.svg';
import { ReactComponent as StatusIndicator } from '../../../images/widgets/optimar_component_icon__status_indicator.svg';
import { ReactComponent as GaugeRoundLarge } from '../../../images/widgets/optimar_component_icon__large_circle_gauge.svg';
import { ReactComponent as SmallVerticalGauge } from '../../../images/widgets/optimar_component_icon__small_vertical_gauge.svg';

const AvailableWidget = (props) => {
    return (
        <div className="available-widgets">
            <h2>Add new widget</h2>

            <ul>
                <li onClick={() => props.addNewWidget('gauge')}>
                    <span className="widget-type-label">Gauge</span>
                    <GaugeRoundLarge />
                </li>
                <li onClick={() => props.addNewWidget('histogram')}>
                    <span className="widget-type-label">Histogram</span>
                    <GraphHistogram />
                </li>
                <li onClick={() => props.addNewWidget('linechart')}>
                    <span className="widget-type-label">Line chart</span>
                    <GraphLine />
                </li>
            </ul>
            <ul>
                <li onClick={() => props.addNewWidget('progress')}>
                    <span className="widget-type-label">Progress wheel</span>
                    <GaugeRound />
                </li>
                <li onClick={() => props.addNewWidget('single-cell')}>
                    <span className="widget-type-label">Single cell</span>
                    <SingleNumber />
                </li>
                <li onClick={() => props.addNewWidget('horizontal-bar')}>
                    <span className="widget-type-label">Horizontal bar</span>
                    <GaugeHorizontal />
                </li>
                <li onClick={() => props.addNewWidget('vertical-bar')}>
                    <span className="widget-type-label">Vertical bar</span>
                    <SmallVerticalGauge />
                </li>
            </ul>
            <ul>
                <li onClick={() => props.addNewWidget('opticontrol')}>
                    <span className="widget-type-label">Opti control</span>
                    <GaugeHorizontal />
                </li>
                <li onClick={() => props.addNewWidget('opti-control-live')}>
                    <span className="widget-type-label">Opti control live</span>
                    <GaugeHorizontal />
                </li>
                <li onClick={() => props.addNewWidget('lamp')}>
                    <span className="widget-type-label">Lamp</span>
                    <StatusIndicator />
                </li>
                <li onClick={() => props.addNewWidget('text')}>
                    <span className="widget-type-label">Text</span>
                    <ElementText />
                </li>
            </ul>
            <ul>
                <li onClick={() => props.addNewWidget('distribution')}>
                    <span className="widget-type-label">Manual Range Distribution</span>
                    <GraphHistogram />
                </li>
                <li onClick={() => props.addNewWidget('depthDistribution')}>
                    <span className="widget-type-label">Dynamic Distribution</span>
                    <GraphHistogram />
                </li>
                <li onClick={() => props.addNewWidget('user-input')}>
                    <span className="widget-type-label">Input</span>
                    <SingleNumber />
                </li>
                <li onClick={() => props.addNewWidget('link-button-group')}>
                    <span className="widget-type-label">Links</span>
                    <SingleNumber />
                </li>
            </ul>
            <ul>
                <li onClick={() => props.addNewWidget('log-list')}>
                    <span className="widget-type-label">Noteviewer</span>
                    <ElementText />
                </li>
                <li onClick={() => props.addNewWidget('datavalues-log-list')}>
                    <span className="widget-type-label">Data log</span>
                    <ElementText />
                </li>
                <li onClick={() => props.addNewWidget('heatmap')}>
                    <span className="widget-type-label">Heatmap</span>
                    <ElementText />
                </li>
            </ul>
            <button className="btn btn-yellow" onClick={(e) => props.closeWidgetModal(e)}>
                Cancel
            </button>
        </div>
    );
};
export default AvailableWidget;
