import { SET_MAP_VARS } from '../actions';

const notes = (
    state = {
        map: undefined,
    },
    action
) => {
    switch (action.type) {
        case SET_MAP_VARS:
            return {
                ...state,
                map: action.data,
            };
        default:
            return state;
    }
};

export default notes;
