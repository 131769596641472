import React from 'react';

import { datapoints_source_validate } from './utils/DatapointsUtils';

const item_type_name = 'Line Chart';
const configurable = true;

export default () => {
    return {
        name: item_type_name,
        configurable: configurable,
        create_new: create_new,
        get_editor,
    };
};

function get_editor(data, onInputChange, props) {
    const datapoints = datapoints_source_validate(props.datapoints, ['chart'], data.source, (value) => {
        onInputChange('source', value);
    });

    return (
        <>
            <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                    autoComplete="off"
                    type="text"
                    className={'input-control'}
                    name="title"
                    value={data.title}
                    required={true}
                    onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="xlabel">X Axis Label</label>
                <input
                    autoComplete="off"
                    type="text"
                    className={'input-control'}
                    name="xlabel"
                    value={data.xlabel}
                    required={true}
                    onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="ylabel">Y Axis Label</label>
                <input
                    autoComplete="off"
                    type="text"
                    className={'input-control'}
                    name="ylabel"
                    value={data.ylabel}
                    required={true}
                    onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="source">Source</label>
                <select
                    required
                    name="source"
                    className={'input-control'}
                    onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                    value={data.source}
                >
                    {datapoints
                        ? datapoints.map((element, index) => {
                              return (
                                  <option key={'lcs' + index} value={element.datapointId}>
                                      {element.datapointId}
                                  </option>
                              );
                          })
                        : null}
                </select>
            </div>
        </>
    );
}

function create_new() {
    return {
        type: 'linechart',
        title: 'Line Chart',
        xlabel: 'Time',
        ylabel: '',
        source: '',
    };
}
