import api from '../../../utility/api';
export default class DashboardEditPaneContext {
    promiseCache = {};

    fetchItemStructure = async (plantId) => {
        if (!plantId) {
            console.log('Missing plantId while getting structure');
            return null;
        }
        const url = process.env.REACT_APP_API + '/api/plants/' + plantId + '/items/structure';
        if (!this.promiseCache[url]) {
            //console.log("fetching item structure",this.promiseCache,url);
            this.promiseCache[url] = api.get(url);
        }

        try {
            const result = await this.promiseCache[url];
            return result.data;
        } catch (err) {
            console.error('ERROR: ', err);
        }

        // api.get()
        //     .then(res => callback(res.data))
        //     .catch(err => {
        //         console.error("ERROR: ", err);
        //     });
    };

    fetchPlantList = async () => {
        const url = process.env.REACT_APP_API + '/api/plants';
        if (!this.promiseCache[url]) {
            this.promiseCache[url] = api.get(url);
        }

        try {
            const result = await this.promiseCache[url];
            return result.data;
        } catch (err) {
            console.error('Failed to fetch plants', err);
        }
        // api.get(process.env.REACT_APP_API + "/api/plants").then(res => callback(res.data))
        //     .catch(err => {
        //         console.error("Failed to fetch plants", err);
        //     });
    };
}
