import React from 'react';
import { ConfirmationAlert } from './ConfirmationAlert';
/**
 * @type {React.FC<{
 *     onCancel: React.MouseEventHandler<HTMLButtonElement>;
 *     onConfirm: React.MouseEventHandler<HTMLButtonElement>;
 * }>}
 */
export const ConfirmDiscardDashboardAlert = (props) => (
    <ConfirmationAlert
        title="Discard dashboard"
        description="This will delete your dashboard and go back to the previous dashboard"
        {...props}
    />
);
