import React from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import api from '../../../utility/api';
import { ReactComponent as DownArrow } from '../../../images/new/optimar_angle_down.svg';
import { ReactComponent as RightArrow } from '../../../images/new/optimar_angle_right.svg';
import './FlatTreeItem.scss';
import { setCurrentAdminPlantItems, clearCurrentAdminPlantItems } from '../../../actions';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import { ReactComponent as CopyIcon } from '../../../images/list/optimar_copy.svg';
import { ReactComponent as AddIcon } from '../../../images/list/optimar_plus.svg';
import GeneralSpinner from '../Spinner/GeneralSpinner';
import { ReactComponent as GreenIcon } from '../../../images/icons/green_circle.svg';
import { ReactComponent as RedIcon } from '../../../images/icons/red_circle.svg';
import { ReactComponent as YellowIcon } from '../../../images/icons/yellow_circle.svg';
import { ReactComponent as GrayIcon } from '../../../images/icons/gray_circle.svg';
import { ReactComponent as NoColorIcon } from '../../../images/icons/no_color_circle.svg';
import DatavalueColorIndicator from '../DatavalueColorIndicator/DatavalueColorIndicator';
import { ReactComponent as RefreshIcon } from '../../../images/icons/refresh.svg';
class FlatTreeItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            list: [],
            generated: false,
            data: [],
            filterString: '',
            expanded: [],
            state: props.whatever,
            expandAll: false,
            flatData: [],
            loading: false,
            // array of {topic, oldestTime}
            treeTopicIndicators: [],
        };

        this.renderTreeIndicator = this.renderTreeIndicator.bind(this);
        this.getTreeIndicator = this.getTreeIndicator.bind(this);
        this.extractChildren = this.extractChildren.bind(this);
        this.extractUniqueTopics = this.extractUniqueTopics.bind(this);
        this.getRenderColorIcon = this.getRenderColorIcon.bind(this);
    }

    componentDidMount() {
        if (this.props.user && this.props.plant) {
            this.getStructure(this.props.user, this.props.plant);
        }

        console.log('mount');
        console.log(this.props);
        if (this.props.plant.topicKey) {
            this.getTreeIndicator(this.props.plant.topicKey);
        }
    }

    componentDidUpdate(prevProps, _prevState) {
        if (this.props.plant && this.props.user && JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.getStructure(this.props.user, this.props.plant);

            if (this.props.changeCount && this.props.user && this.props.plant) {
                if (this.props.changeCount > 0) {
                    this.props.resetChangeCount();
                }
            }
        }

        if (!this.state.generated) this.generateList();
    }

    async getTreeIndicator(topicKey) {
        try {
            const response = await api.get(
                process.env.REACT_APP_API + `/api/TopicDatavalue/get_tree_indicator?plantKey=${topicKey}`
            );
            if (response.status === 200) {
                this.setState({
                    treeTopicIndicators: response.data,
                });
            } else {
                console.log('failed');
            }
        } catch (error) {
            console.log(error);
        }
    }

    renderTreeIndicator(fullTopicKey) {
        let topicIndicators = this.state.treeTopicIndicators;

        // let data = this.state.data;
        // let extractedObject = this.extractChildren(data);
        // let extractedUniqueTopics = this.extractUniqueTopics(extractedObject, topicIndicators);
        // if (extractedUniqueTopics.some((x) => x.topic && x.topic.includes(fullTopicKey))) {
        //     return <NoColorIcon />;
        // }
        let matchedTopics = topicIndicators.filter((x) => {
            return x.topic.includes(fullTopicKey);
        });
        if (matchedTopics.length > 0) {
            if (matchedTopics[0].oldestTime === '0001-01-01T00:00:00') {
                return <GrayIcon />;
            }
            try {
                let date = new Date(Date.parse(matchedTopics[0].oldestTime));
                let now = new Date(Date.now());
                let hoursDiff = Math.abs(date.getTime() - now.getTime()) / (1000 * 60 * 60);
                return this.getRenderColorIcon(hoursDiff);
            } catch {
                return <NoColorIcon />;
            }
        } else {
            return <NoColorIcon />;
        }
    }

    extractChildren(data) {
        let result = [];
        data.forEach((item) => {
            const { children, ...rest } = item;
            result.push(rest);
            if (children.length > 0) {
                result = [...result, ...this.extractChildren(children)];
            }
        });
        return result;
    }

    extractUniqueTopics(a, b) {
        const bTopics = b.map((item) => item.topic);
        return a.filter((item) => !bTopics.includes(item.topic));
    }

    getRenderColorIcon(hoursDiff) {
        if (isNaN(hoursDiff)) {
            return <GrayIcon />;
        }
        if (hoursDiff < 48) {
            return <GreenIcon />;
        }
        if (48 < hoursDiff && hoursDiff < 24 * 30) {
            return <YellowIcon />;
        }
        if (hoursDiff > 24 * 30) {
            return <RedIcon />;
        }
    }

    getStructure(user, plant) {
        this.setState({ loading: true });
        const apiUrl = process.env.REACT_APP_API + '/api/plants/' + plant.id + '/items/structure';

        api.get(apiUrl)
            .then((res) => {
                if (res.status !== 204) {
                    this.unflatten(res.data);
                    this.setState({
                        flatData: res.data,
                        loading: false,
                    });
                    this.props.setCurrentAdminPlantItems(res.data);
                } else if (res.status === 204) {
                    this.unflatten();
                    this.props.clearCurrentAdminPlantItems();
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    unflatten(data) {
        const tree = [],
            childrenOf = {};
        if (data) {
            const ID_KEY = 'id';
            const PARENT_KEY = 'parentId';
            const CHILDREN_KEY = 'children';

            let item, id, parentId;

            for (let i = 0, length = data.length; i < length; i++) {
                item = data[i];
                id = item[ID_KEY];
                parentId = item[PARENT_KEY] || '00000000-0000-0000-0000-000000000000';
                // every item may have children
                childrenOf[id] = childrenOf[id] || [];
                // init its children
                item[CHILDREN_KEY] = childrenOf[id];
                item.toggled = true;
                if (parentId !== '00000000-0000-0000-0000-000000000000') {
                    // init its parent's children object
                    childrenOf[parentId] = childrenOf[parentId] || [];
                    // push it into its parent's children object
                    childrenOf[parentId].push(item);
                } else {
                    tree.push(item);
                }
            }
        }
        this.setState(
            {
                data: tree,
            },
            () => this.generateList()
        );
    }

    toggleExpand(e, id) {
        e.stopPropagation();
        if (this.state.expanded.includes(id)) {
            const tmp = this.state.expanded;
            tmp.splice(tmp.indexOf(id), 1);
            this.setState(
                {
                    expanded: tmp,
                },
                this.generateList()
            );
        } else {
            const tmp = this.state.expanded;
            tmp.push(id);
            this.setState(
                {
                    expanded: tmp,
                },
                this.generateList()
            );
        }
    }

    findNode(node) {
        const fString = this.state.filterString.toLowerCase();
        if (
            node.name.toLowerCase().includes(fString) ||
            (node.componentId && node.componentId.toLowerCase().includes(fString)) ||
            (node.orderPos && node.orderPos.toLowerCase().includes(fString)) ||
            (node.productSheet && node.productSheet.toLowerCase().includes(fString)) ||
            node.children.find((child) => this.findNode(child))
        ) {
            if (!this.state.expanded.includes(node.parentId)) {
                const tmp = this.state.expanded;
                tmp.push(node.parentId);
                this.setState({
                    expanded: tmp,
                });
            }
            return true;
        }
    }

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    generateListSection(node, list, level) {
        const expanded = this.state.expanded;
        if (node.parentId === '00000000-0000-0000-0000-000000000000' && this.findNode(node)) {
            list.push(
                <span
                    key={node.id}
                    className={'item-menu-line ' + (node.id === this.props.activeItem?.id ? 'active-menu' : '')}
                    onClick={() => this.props.editItemCallback(node)}
                    title="Edit item"
                >
                    {
                        node.children.length > 0 && this.state.filterString === '' ? (
                            expanded.includes(node.id) ? (
                                <span
                                    onClick={(e) => this.toggleExpand(e, node.id)}
                                    style={{ width: '27px', height: '27px' }}
                                    title="Collapse"
                                >
                                    <DownArrow
                                        style={{ boxSizing: 'content-box' }}
                                        title="Toggle expand"
                                        className="item-nav-collapser"
                                    />
                                </span>
                            ) : (
                                <span
                                    onClick={(e) => this.toggleExpand(e, node.id)}
                                    style={{ width: '27px', height: '27px' }}
                                    title="Expand"
                                >
                                    <RightArrow
                                        title="Toggle expand"
                                        className="item-nav-expander"
                                        style={{ boxSizing: 'content-box', stroke: '#41cdf5' }}
                                    />
                                </span>
                            )
                        ) : (
                            <span style={{ paddingLeft: '22px' }}>&nbsp;</span>
                        )
                        // null
                    }

                    <div
                        key={'nav' + node.id}
                        className={
                            'item-nav-node l-' + level + (node.id === this.props.activeItem?.id ? ' active-menu ' : '')
                        }
                    >
                        {node.name}
                    </div>

                    {node.topic ? <div className="topic-indicator">{this.renderTreeIndicator(node.topic)}</div> : null}

                    <div className="admin-plant-item-row-action-container">
                        {/* child add */}

                        <div
                            className="quick-add"
                            title="Quick add child"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.quickAddCallback(node);
                            }}
                        >
                            <AddIcon alt="Quick add child" className="action-icon" />
                        </div>
                        <div className="dotted">
                            <FontAwesome
                                name="ellipsis-h"
                                className={'list-btn'}
                                style={{ fontSize: '17px', color: '#41C9F0', border: '1px' }}
                                onClick={(e) => e.stopPropagation()}
                            />
                            <div className="admin-plant-item-row-actions">
                                {this.props.isCopying ? (
                                    <GeneralSpinner text="Saving" />
                                ) : (
                                    <>
                                        <div
                                            className="action-element"
                                            title="Copy from branch"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.copyStructure(node);
                                            }}
                                        >
                                            <CopyIcon className="action-icon" />
                                            <span className="list-edit-btn">Copy from branch</span>
                                        </div>
                                        <div
                                            className="action-element"
                                            title="New child"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.addCallback(node);
                                            }}
                                        >
                                            <AddIcon className="action-icon" />
                                            <span className="list-edit-btn">New child</span>
                                        </div>
                                        <div
                                            className="action-element"
                                            title="Duplicate"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.duplicateStructure(node);
                                            }}
                                        >
                                            <CopyIcon className="action-icon" />
                                            <span className="list-edit-btn">Duplicate</span>
                                        </div>
                                        <div
                                            className="action-element"
                                            title="Delete item"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.deleteCallback(node);
                                            }}
                                        >
                                            <DeleteIcon className="action-icon red" />
                                            <span className="list-edit-btn">Delete</span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </span>
            );
        } else {
            const styles = {};
            if (level >= 1) {
                if (this.check()) {
                    styles.paddingLeft = level * 20 + 'px';
                } else {
                    styles.paddingLeft = level * 5 + 'px';
                }
            }

            if (this.state.filterString === '' || this.findNode(node)) {
                list.push(
                    <span
                        style={styles}
                        key={node.id}
                        className={'item-menu-line ' + (node.id === this.props.activeItem?.id ? 'active-menu' : '')}
                        onClick={() => this.props.editItemCallback(node)}
                        title="Edit item"
                    >
                        {
                            node.children.length > 0 && this.state.filterString === '' ? (
                                expanded.includes(node.id) ? (
                                    <span
                                        onClick={(e) => this.toggleExpand(e, node.id)}
                                        style={{ width: '27px', height: '27px' }}
                                        title="Collapse"
                                    >
                                        <DownArrow title="Toggle expand" className="item-nav-collapser" />
                                    </span>
                                ) : (
                                    <span
                                        onClick={(e) => this.toggleExpand(e, node.id)}
                                        style={{ width: '27px', height: '27px' }}
                                        title="Expand"
                                    >
                                        <RightArrow
                                            title="Toggle expand"
                                            className="item-nav-expander"
                                            style={{ stroke: '#41cdf5' }}
                                        />
                                    </span>
                                )
                            ) : (
                                <span style={{ paddingLeft: '22px' }}>&nbsp;</span>
                            )
                            // null
                        }
                        <div
                            key={'nav' + node.id}
                            className={
                                'item-nav-node l-' +
                                level +
                                (node.id === this.props.activeItem?.id ? 'active-menu' : '')
                            }
                        >
                            {node.name}
                        </div>
                        {node.topic ? (
                            <div className="topic-indicator">{this.renderTreeIndicator(node.topic)}</div>
                        ) : null}
                        <div className="admin-plant-item-row-action-container">
                            <div
                                className="quick-add"
                                title="Quick add child"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.quickAddCallback(node);
                                }}
                            >
                                <AddIcon alt="Quick add child" className="action-icon" />
                            </div>
                            <div className="dotted">
                                <FontAwesome
                                    name="ellipsis-h"
                                    className={'list-btn'}
                                    style={{ fontSize: '17px', color: '#41C9F0', border: '1px' }}
                                />
                                <div className="admin-plant-item-row-actions">
                                    {this.props.isCopying ? (
                                        <GeneralSpinner text="Saving" />
                                    ) : (
                                        <>
                                            <div
                                                className="action-element"
                                                title="Copy from branch"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.copyStructure(node);
                                                }}
                                            >
                                                <CopyIcon className="action-icon" />
                                                <span className="list-edit-btn">Copy from branch</span>
                                            </div>
                                            <div
                                                className="action-element"
                                                title="New child"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.addCallback(node);
                                                }}
                                            >
                                                <AddIcon className="action-icon" />
                                                <span className="list-edit-btn">New child</span>
                                            </div>
                                            <div
                                                className="action-element"
                                                title="Duplicate"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.duplicateStructure(node);
                                                }}
                                            >
                                                <CopyIcon className="action-icon" />
                                                <span className="list-edit-btn">Duplicate</span>
                                            </div>
                                            <div
                                                className="action-element"
                                                title="Delete item"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.deleteCallback(node);
                                                }}
                                            >
                                                <DeleteIcon className="action-icon red" />
                                                <span className="list-edit-btn">Delete</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </span>
                );
            }
        }

        if (node.children.length > 0 && expanded.includes(node.id)) {
            ++level;
            const sortedChildren = node.children.sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
            );
            sortedChildren.map((n) => {
                return this.generateListSection(n, list, level);
            });
        }
    }

    expandAll(e, expand) {
        e.stopPropagation();
        if (expand) {
            this.setState(
                {
                    expanded: this.state.flatData.map((node) => {
                        return node.id;
                    }),
                    filterString: '',
                },
                () => this.generateList()
            );
        } else {
            this.setState(
                {
                    expanded: [],
                    filterString: '',
                },
                () => this.generateList()
            );
        }
    }

    generateList() {
        const list = [];
        list.push(
            <input
                autoComplete="off"
                key="veryunique2"
                type="text"
                placeholder={'Search tree..'}
                className="item-search-input item-search-nav"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                    this.setState({ filterString: e.target.value }, this.generateList);
                }}
            />
        );
        list.push(
            <span
                key="veryunique"
                className="nav-root"
                onClick={(e) => {
                    e.stopPropagation();
                    this.props.editPlantCallback();
                }}
                style={{ cursor: 'pointer' }}
                title="Edit plant"
            >
                <div className="left-nav-wrapper">
                    <div key="node-root" className="nav-node l-1">
                        {this.props.plant.name}
                    </div>
                    <RefreshIcon className="left-nav-wrapper-icon" onClick={() => this.componentDidMount()}>
                        refresh
                    </RefreshIcon>
                    <DatavalueColorIndicator className="left-nav-wrapper-icon" />
                </div>
                <div className="admin-plant-item-row-action-container">
                    {/* parent add */}
                    <div
                        className="quick-add"
                        style={{ marginTop: '4px' }}
                        title="Quick add child"
                        onClick={(e) => {
                            e.stopPropagation();
                            console.log(
                                '🚀 ~ file: FlatTreeItem.js:502 ~ FlatTreeItem ~ generateList ~ this.props.plant',
                                this.props.plant
                            );
                            this.props.quickAddCallback(null, this.props.plant.topicKey);
                        }}
                    >
                        <AddIcon title="Quick add child" className="action-icon" />
                    </div>
                    <div className="dotted">
                        <FontAwesome
                            name="ellipsis-h"
                            title=""
                            className={'list-btn'}
                            style={{ fontSize: '17px', color: '#41C9F0', border: '1px' }}
                        />
                        <div className="admin-plant-item-row-actions">
                            <div
                                className="action-element"
                                title="Copy from branch"
                                onClick={() => this.props.copyStructure()}
                            >
                                <CopyIcon className="action-icon" />
                                <span className="list-edit-btn">Copy from branch</span>
                            </div>
                            <div
                                className="action-element"
                                title="New child"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.addRootCallback();
                                }}
                            >
                                <AddIcon className="action-icon" />
                                <span className="list-edit-btn">New child</span>
                            </div>
                            <div
                                className="action-element"
                                title="From topic"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.generateFromTopicCallback();
                                }}
                            >
                                <AddIcon className="action-icon" />
                                <span className="list-edit-btn">From topic</span>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        );

        const sortedNodes = this.state.data.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
        );

        sortedNodes.map((node) => {
            return this.generateListSection(node, list, 0);
        });

        this.setState({
            list: list,
            generated: true,
        });
    }

    render() {
        return (
            <div className="item-treenav-dropdown">
                {this.state.loading ? <GeneralSpinner text="Loading items" /> : null}
                {this.state.generated ? this.state.list : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        plant: state.plants.currentAdminPlant,
        user: state.user.currentUser,
    };
};

const mapDispatchToProps = {
    setCurrentAdminPlantItems,
    clearCurrentAdminPlantItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlatTreeItem);
