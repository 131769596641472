import React from 'react';
import PlantInfo from '../../components/Widgets/PlantInfo/PlantInfo';
import { connect } from 'react-redux';
import { setCurrentPlant, clearCurrentPlant } from '../../actions';
import './overview.css';
import { withRouteMatch } from '../../HOC';

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className="dashboard-info-panel">
                <PlantInfo
                    plant={this.props.plant}
                    user={this.props.user}
                    plantId={this.props.match.params.plantId}
                    itemId={this.props.match.params.itemId}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plants.currentPlant,
    user: state.user.currentUser,
});

const mapDispatchToProps = {
    setCurrentPlant,
    clearCurrentPlant,
};

export default withRouteMatch(connect(mapStateToProps, mapDispatchToProps)(Overview));
