import React from 'react';
import './AdminTagsAddEdit.css';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import Select from 'react-select';
import { noop } from 'lodash';
class AdminTagsAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plants: [],
            plantTypes: [],
            nameValid: false,
            formValid: false,
            isDirty: false,
            checkedItems: [],
            tag: [],
            selectedPlants: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.fetchPlants = this.fetchPlants.bind(this);
        this.handlePlantChange = this.handlePlantChange.bind(this);
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            isDirty: true,
            userAdded: false,
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;
        const tmp = this.state.tag;
        tmp[name] = value;

        this.setState({ tag: tmp });
    }

    componentDidMount() {
        if (this.props.editTag) {
            this.setState({
                tag: this.props.editTag,
                selectedPlants: this.props.editTag.plants,
                mode: 'edit',
            });
        }
        this.fetchPlants();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) this.fetchPlants();
    }

    fetchPlants() {
        if (!this.props.user) return;

        api.get(process.env.REACT_APP_API + '/api/admin/plants')
            .then((res) => this.setState({ plants: res.data }))
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    handleSubmit(event) {
        if (this.state.mode === 'edit') {
            this.updateSubmit(event);
        } else {
            this.createSubmit(event);
        }
    }
    createSubmit(event) {
        event.preventDefault();

        const tag = {
            id: this.state.tag.id,
            title: this.state.tag.title,
            plantId: this.state.tag.plantId,
            accountId: this.state.tag.accountId,
            documentEnabled: this.state.tag.documentEnabled,
            noteEnabled: this.state.tag.noteEnabled,
            plants: this.state.selectedPlants,
        };

        api.post('/api/tags', tag)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Tag created', 'Tag successfully created', 5000);

                    this.setState(
                        {
                            isDirty: false,
                            formValid: false,
                        },
                        this.props.editedCallback(response)
                    );
                } else {
                    NotificationManager.error('Error', 'Failed to create tag', 5000);
                    throw new Error(response.status);
                }
            })
            .then((res) => {
                this.props.addedCallback(res);
            })
            .catch(noop);
    }

    updateSubmit(event) {
        event.preventDefault();

        const tag = {
            id: this.state.tag.id,
            title: this.state.tag.title,
            plantId: this.state.tag.plantId,
            accountId: this.state.tag.accountId,
            documentEnabled: this.state.tag.documentEnabled,
            noteEnabled: this.state.tag.noteEnabled,
            plants: this.state.selectedPlants,
        };

        api.put(process.env.REACT_APP_API + '/api/tags/' + tag.id, tag)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Keyword updated', 'Keyword successfully updated', 5000);
                    this.props.editedCallback(response.data);
                } else {
                    NotificationManager.error('Error', 'Failed to update keyword', 5000);
                    // throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    handleMultiSelectChange = (selectedPlants) => {
        this.setState({ selectedPlants });
    };

    handlePlantChange(event) {
        event.preventDefault();
        const tmp = this.state.tag;
        tmp.plantId = event.target.value;

        this.setState({
            tag: tmp,
        });
    }

    render() {
        const plants = this.state.plants;
        const plantsForDropdown = plants.map(function (d) {
            return {
                value: d.id,
                label: d.name,
            };
        });

        return (
            <div className="admin-plant-add-edit-form">
                {this.state.mode === 'edit' ? <h1>Edit keyword</h1> : <h1>New keyword</h1>}
                <form style={{ width: '100%' }} onSubmit={this.handleSubmit}>
                    <div>
                        <label htmlFor="name">Title:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            required
                            name="title"
                            value={this.state.tag.title || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>

                    <div>
                        <label htmlFor="documentEnabled">Document:</label>
                        <input
                            key={'documentEnabled'}
                            autoComplete="off"
                            name="documentEnabled"
                            type="checkbox"
                            checked={this.state.tag.documentEnabled || false}
                            onChange={(e) => {
                                this.handleChange(e);
                            }}
                        />

                        <label htmlFor="noteEnabled">Note:</label>
                        <input
                            key={'noteEnabled'}
                            autoComplete="off"
                            name="noteEnabled"
                            type="checkbox"
                            checked={this.state.tag.noteEnabled || false}
                            onChange={(e) => {
                                this.handleChange(e);
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="plant_type">Choose Plant:</label>
                        <Select
                            name={'plantTypeId'}
                            value={this.state.selectedPlants}
                            onChange={this.handleMultiSelectChange}
                            options={plantsForDropdown}
                            isMulti={true}
                            classNamePrefix={'optimar'}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    <div className="btn-row btn-row-grow">
                        <button className="btn btn-yellow btn-grow" onClick={this.props.cancelCallback}>
                            Cancel
                        </button>
                        <button className="btn btn-blue btn-grow" value="Submit">
                            {this.state.mode === 'edit' ? 'Save changes' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminTagsAddEdit);
