import React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import './CommentList.scss';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import { MentionsInput, Mention } from 'react-mentions';
import defaultMentionStyle from './mention-style';
import defaultStyle from './mention-wrapper-style';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import { confirmAlert } from 'react-confirm-alert';
import SimpleSpinner from '../Spinner/SimpleSpinner';

class CommentList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newComment: '',
            comments: props.comments,
            maxComments: 4,
            mentions: [],
            rawUsers: [],
            addingComment: false,
            gettingComment: false,
            mentionableUsers: [],
        };

        this.onEnter = this.onEnter.bind(this);
        this.sendComment = this.sendComment.bind(this);
        this.findMention = this.findMention.bind(this);
        this.getMentionText = this.getMentionText.bind(this);
        this.fetchMentionableUsers = this.fetchMentionableUsers.bind(this);
        this.confirmDeleteComment = this.confirmDeleteComment.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
    }

    componentDidMount() {
        this.fetchMentionableUsers();
        if (this.props.supportMode) return;

        this.getComments();

        this.interval = setInterval(() => {
            this.getComments();
        }, 10000);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.comments && prevProps.comments.length !== this.props.comments.length) {
            console.log('what?');
            this.setState({
                comments: this.props.comments,
            });
        }
    }

    componentWillUnmount() {
        if (this.props.supportMode) return;
        clearInterval(this.interval);
    }

    async getComments() {
        if (this.state.gettingComment || this.state.addingComment) {
            console.log('Already fetching comments. Please wait for it to finish.');
            return;
        }
        try {
            this.setState({ gettingComment: true });
            const response = await api.get(process.env.REACT_APP_API + '/api/notecomments?noteId=' + this.props.noteId);
            //.then((response) => {
            this.setState({
                comments: response.data,
                gettingComment: false,
            });
        } catch (err) {
            console.error('Error getting comments', err);
            this.setState({ gettingComment: false });
        }
    }

    //sendComment = async (e) => {
    async sendComment(_e) {
        if (this.state.addingComment) {
            console.log('Already adding a comment. Please wait for it to finish.');
            return;
        }
        const noteComment = {
            noteId: this.props.noteId,
            comment: this.state.newComment,
            deleted: false,
            createdOn: new Date(),
            createdBy: this.props.user.profile.Username,
            mentions: this.state.mentions,
            currentUserIsOwner: true,
        };
        if (noteComment.comment.length <= 0) {
            NotificationManager.warning('Please write something before submitting', 'Nothing to add', 5000);
            return;
        }
        let url = 'api/notecomments';
        if (this.props.supportMode) {
            url = `api/support/${this.props.caseId}/comments`;
            if (this.props.supportInternal) noteComment.internal = true;
        }
        const self = this;

        self.setState({ addingComment: true });
        try {
            const response = await api.post(url, noteComment);
            const comments = [response.data];
            //console.log(response.data.id);
            const tmp = Object.assign([], self.state.comments);
            NotificationManager.success('Comment added successfully', 'Comment added', 5000);
            const concatWithPossibleDupes = tmp?.length > 0 ? tmp.concat(comments) : comments;
            const concatedNoDupesSorted = concatWithPossibleDupes
                .filter((element, index) => {
                    //console.log(element);
                    return concatWithPossibleDupes.map((e) => e.id).indexOf(element.id) === index;
                })
                .sort((c1, c2) => new Date(c2.createdOn) - new Date(c1.createdOn));

            //console.log("commentslength",concatWithPossibleDupes.length,concatedNoDupesSorted.length)
            self.setState({
                comments: concatedNoDupesSorted,
                newComment: '',
                addingComment: false,
            });
        } catch (err) {
            NotificationManager.error('Could not add comment', 'Error commenting', 5000);
            console.error('Error adding comment', err);
            self.setState({ addingComment: false });
        }
    }

    async onEnter(e) {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            await this.sendComment(e);
            //e.target.value = '';
            //this.getComments();
        }
    }
    deleteComment = async (comment) => {
        const noteComment = { ...comment };

        noteComment.deleting = true;

        let url = 'api/notecomments/removeComment'; //Not sure this endpoint exists..
        if (this.props.supportMode) {
            url = `api/support/${this.props.caseId}/removeComment`;
        }

        //this.setState({removingComment:true});
        try {
            const tmpbefre = Object.assign([], this.state.comments);
            this.setState({
                comments: tmpbefre.map((cmp) => {
                    if (cmp.id === noteComment.id) {
                        return noteComment;
                    }
                    return cmp;
                }),
                // removingComment:false
            });

            await api.post(url, noteComment);

            noteComment.comment = 'This message has been deleted';
            noteComment.deleting = false;
            noteComment.deleted = true;

            //let comments = [noteComment];
            const tmpafter = Object.assign([], this.state.comments);
            NotificationManager.success('Comment removed successfully', 'Comment removed', 5000);
            this.setState({
                comments: tmpafter.map((cmp) => {
                    if (cmp.id === noteComment.id) {
                        return noteComment;
                    }
                    return cmp;
                }),
            });
        } catch (err) {
            NotificationManager.error('Could not remove comment', 'Error commenting', 5000);
            console.log(err);
        }
    };

    confirmDeleteComment = (comment) => {
        if (comment.deleting) {
            NotificationManager.warning('This comment is already deleting. Please wait.', 'Working on it', 5000);
            return;
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete comment</h1>
                        <p>Are you sure you wish to delete your comment?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteComment(comment);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };
    //fetchOptimarUsers() {
    fetchMentionableUsers = async () => {
        try {
            const plantId = this.props.plantId;
            if (!plantId) {
                console.log('Missing plantId. Cannot fetch mentionable users');
                return;
            }
            const res = await api.get(process.env.REACT_APP_API + '/api/plants/' + plantId + '/users');
            //console.log("plant users", res);
            //let res = await api.get(process.env.REACT_APP_API + "/api/users/optimar")
            const users = res.data.map((u) => {
                return {
                    id: u.id,
                    display: u.displayname ? u.displayname : u.username,
                };
            });
            this.setState({ rawUsers: res.data, mentionableUsers: users.filter((u) => u.display || u.username) });
        } catch (err) {
            console.error('ERROR: ', err);
        }
    };

    getMentionText(textSegment, elements) {
        const mentionText = textSegment.substring(textSegment.indexOf('@['), textSegment.indexOf(')') + 1);
        const keepFrom = mentionText.indexOf('@[') + 2;
        const keepTo = mentionText.indexOf(']');
        const userDisplayName = mentionText.substring(keepFrom, keepTo);
        const userObject = this.state.rawUsers.find((u) => u.displayname === userDisplayName);
        //console.log("userObject", userObject);
        elements.push(
            <span className="comment-mention">
                {'@' + userDisplayName}
                <div className="user-info">
                    <h3>Contact information</h3>
                    {userObject?.username}
                    <br />
                    {userObject?.phonenumber}
                </div>
            </span>
        );
        return mentionText;
    }

    findMention(comment) {
        const elements = [];

        while (comment.indexOf('@[') > -1) {
            const matchIdx = comment.indexOf('@[');

            if (matchIdx === 0) {
                const mentionText = this.getMentionText(comment, elements);
                comment = comment.replace(mentionText, '');
            } else if (matchIdx > -1) {
                const textBeforeMention = comment.substring(0, comment.indexOf('@['));
                elements.push(textBeforeMention);
                comment = comment.replace(textBeforeMention, '');

                const mentionText = this.getMentionText(comment, elements);
                comment = comment.replace(mentionText, '');
            }
        }

        elements.push(comment);
        //console.log("elements", elements);

        return elements;
    }

    render() {
        //console.log("mentions", this.state.mentions);
        const comments = Object.assign([], this.state.comments);
        comments.sort((a, b) => {
            return a.createdOn > b.createdOn ? -1 : 1;
        });
        return (
            <div className="comment-container">
                {!this.props.supportMode ? <h2 className="comment-header">Comments</h2> : null}
                <form onSubmit={(e) => this.sendComment(e)}>
                    {!this.props.readonly ? (
                        <>
                            {/* <textarea onKeyDown={this.onEnter} rows="3" cols="10" ref="comment-input" autoComplete="off" required name="comment" placeholder="Write new comment..." className="input-comment" onChange={(e) => { this.setState({ newComment: e.target.value }) }} type="text" /> */}
                            <MentionsInput
                                style={defaultStyle}
                                value={this.state.newComment}
                                onChange={(e, a, b, mentions) => {
                                    this.setState({ newComment: e.target.value, mentions });
                                }}
                                onKeyDown={this.onEnter}
                                autoComplete="off"
                                required
                                name="comment"
                                placeholder="Write new comment..."
                                className="input-comment"
                                disabled={this.state.addingComment}
                            >
                                <Mention trigger="@" data={this.state.mentionableUsers} style={defaultMentionStyle} />
                            </MentionsInput>
                            <div className="btn-wrapper">
                                <div onClick={(e) => this.sendComment(e)} className="btn btn-blue">
                                    {this.state.addingComment ? 'Adding comment..' : 'Add comment'}
                                </div>
                            </div>
                        </>
                    ) : null}

                    <div className="comment-list">
                        {comments
                            ? comments.map((comment, i) => {
                                  const c = comment.comment;
                                  if (i < this.state.maxComments) {
                                      return (
                                          <div
                                              className={`comment-wrapper ${
                                                  comment.currentUserIsOwner ? 'my-comment' : 'their-comment'
                                              }`}
                                              key={'comment-' + comment.id}
                                          >
                                              <span className="comment-author">{comment.createdBy}</span>
                                              <span className="comment-timestamp">
                                                  {moment.utc(comment.createdOn).fromNow()}
                                              </span>
                                              {comment.deleting ? (
                                                  <span className="comment-deleting">
                                                      <SimpleSpinner size={'1x'}></SimpleSpinner>
                                                  </span>
                                              ) : null}
                                              {comment.currentUserIsOwner && !comment.deleted ? (
                                                  <span className="comment-rm">
                                                      <DeleteIcon
                                                          onClick={() => this.confirmDeleteComment(comment)}
                                                          className="action-icon red"
                                                          style={{ cursor: 'pointer' }}
                                                      />
                                                  </span>
                                              ) : null}
                                              <div className={`comment-content ${comment.deleted ? 'inline' : ''}`}>
                                                  {this.findMention(c)}
                                              </div>
                                          </div>
                                      );
                                  } else {
                                      return null;
                                  }
                              })
                            : null}
                    </div>
                    {comments && this.state.maxComments < comments.length ? (
                        <span
                            className="more-comments"
                            onClick={() => {
                                this.setState({ maxComments: this.state.maxComments + 4 });
                            }}
                        >
                            More comments...
                        </span>
                    ) : null}
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plants.currentPlant,
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(CommentList);
