import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as Cross } from '../../../images/new/optimar_cross.svg';
import { ReactComponent as Download } from '../../../images/new/optimar_download.svg';
import './FileList.scss';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../../utility/api';

class FileList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
        };
    }

    fetchSingleDoc(doc) {
        if (this.props.downloadFunc) {
            return this.props.downloadFunc(doc);
        }

        const url = `/api/documents/${doc.id}`;
        this.setState({ fetching: true });
        api.get(url, {
            mode: 'cors',
            responseType: 'arraybuffer',
            headers: new Headers({
                Authorization: 'Bearer ' + this.props.user.access_token,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    this.createAndDownloadFile(response.data, doc.filename);
                    this.props.fetchNotesData();
                    NotificationManager.success('Document downloaded successfully', 'Download success');
                } else {
                    NotificationManager.error('Failed to download document', 'Download failed', 5000);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete file</h1>
                        <p>
                            Are you sure you want to delete this file?
                            <br />{' '}
                        </p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteSingleDoc(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteSingleDoc(doc) {
        if (this.props.deleteFunc) {
            return this.props.deleteFunc(doc);
        }
        const url = `/api/documents/${doc.id}`;
        this.setState({ fetching: true });
        api.delete(url, {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                Authorization: 'Bearer ' + this.props.user.access_token,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Document deleted successfully', 'Delete success');
                    this.props.fetchNotes();
                } else {
                    NotificationManager.error('Failed to delete document', 'Delete failed', 5000);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    createAndDownloadFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = filename;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({ fetching: false });
            }
        }
    }

    truncate(n, len) {
        const ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
        let filename = n.replace('.' + ext, '');
        if (filename.length <= len) {
            return n;
        }
        filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
        return filename + '.' + ext;
    }

    render() {
        const files = this.props.files || [];
        return (
            <div className="file-list">
                {files.map((file, i) => {
                    return (
                        <div key={'fileline-' + i} className="file-line" title={file.filename}>
                            {this.truncate(file.filename, 30)}
                            <div className="file-actions">
                                {!this.props.readOnly && (this.props.user.isAdmin || file.currentUserIsOwner) ? (
                                    <div onClick={() => this.confirmDelete(file)} className="file-delete">
                                        <Cross className="delete" />
                                        &nbsp;Delete
                                    </div>
                                ) : null}
                                <div onClick={() => this.fetchSingleDoc(file)} className="file-download">
                                    <Download style={{ stroke: '#41cdf5' }} className="download" />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default connect(mapStateToProps)(FileList);
