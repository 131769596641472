import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationTypes, setUserNotificationSettings } from '../../actions';
import Spinner from '../../components/common/Spinner/Spinner';
import api from '../../utility/api';
import { getNotificationMessageShort, isValidNotificationType } from '../../utility/notificationHelper';
import '../AdminPlants/AdminPlants.scss';
import './NotificationSettingEdit.scss';

const baseUrl = process.env.REACT_APP_API + '/api/userNotificationSettings';

const createTable = (notificationTypes, notificationTypeSettings, dispatch) => {
    return (
        <table className={'settings-table'}>
            <thead className={'table-header'}>
                <tr>
                    <th>
                        <span>Notification type</span>
                    </th>
                    <th className={'email-column'}>
                        <span>Enable email notification</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {notificationTypes &&
                    notificationTypes.map((row) => createRow(row, notificationTypeSettings, dispatch))}
            </tbody>
        </table>
    );
};

const createRow = (row, notificationTypeSettings, dispatch) => {
    const disabled = notificationTypeSettings.find((x) => x.id === row.id && x.muteEmailNotification === true);
    return (
        <tr key={`ns-row-${row.id}`}>
            <td>{getNotificationMessageShort(row.name)}</td>
            <td className={'email-column'}>
                <div className="toggle-container">
                    <div
                        className={'toggle ' + (!disabled ? 'on' : '')}
                        style={{ margin: 'auto' }}
                        title={
                            disabled
                                ? 'Email notification disabled. Click to activate'
                                : 'Email notification enabled. Click to disable'
                        }
                        onClick={() => toggleEmailNotification(row.id, dispatch)}
                    >
                        <div className="dot"></div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export const fetchNotificationTypes = async (dispatch) => {
    try {
        const response = await api.get(`${baseUrl}/types`);
        dispatch(setNotificationTypes(response.data));
    } catch (e) {
        console.error(e);
    }
};

export const fetchUserNotificationSettings = async (dispatch) => {
    try {
        const response = await api.get(`${baseUrl}`);
        dispatch(setUserNotificationSettings(response.data));
    } catch (e) {
        console.error(e);
    }
};

export const toggleEmailNotification = async (notificationTypeId, dispatch) => {
    try {
        const response = await api.post(`${baseUrl}/toggleEmailNotification/${notificationTypeId}`);
        await fetchUserNotificationSettings(dispatch);

        if (response.status === 200) {
            NotificationManager.success('Notification settings updated successfully', 'Setting update', 5000);
        } else {
            NotificationManager.warning('Notification settings unexpected result', 'Setting update', 5000);
        }
    } catch (e) {
        console.error(e);
    }
};

const NotificationSettingEdit = (props) => {
    const userNotificationSettings = useSelector((state) => state.notifications.userNotificationSettings);
    const notificationTypes = useSelector((state) => state.notifications.notificationTypes);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);

    const filteredNotificationTypes = notificationTypes?.filter(isValidNotificationType);

    props.CloseModalCallback.bind(this);

    useEffect(() => {
        fetchNotificationTypes(dispatch)
            .then(() => fetchUserNotificationSettings(dispatch))
            .then(() => {
                setLoading(false);
            });
    }, [dispatch]);

    if (isLoading) {
        return <Spinner text="notifications" />;
    }

    return (
        <div>
            <h2>Edit Notification Settings</h2>
            {createTable(filteredNotificationTypes, userNotificationSettings.notificationTypeSettings || [], dispatch)}
            <div className="btn-row" style={{ marginTop: '8px' }}>
                <button
                    className="btn btn-yellow"
                    style={{ marginLeft: 'auto', marginRight: 0 }}
                    onClick={() => props.CloseModalCallback()}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default NotificationSettingEdit;
