import api from './api';
export const uploadFileUsingFormData = async (file, props, documentApi = '/api/documents') => {
    const data = new FormData();
    if (!file) {
        throw new Error('No file to upload');
    }
    data.append('File', file);
    data.append('Filename', file.name);
    // data.append('Internal', internal);
    // data.append('SupportId', this.props.case.id);
    // data.append('CreatedAt', moment.utc().format())
    // data.append('Date', moment.utc().format())

    if (props) {
        for (const [key, value] of Object.entries(props)) {
            //console.log(`${key}: ${value}`);
            data.append(key, value);
        }
    }

    const res = await api.post(process.env.REACT_APP_API + documentApi, data);
    return res;
    //    if (res.status >= 200 && res.status < 300) {
    // NotificationManager.success("File uploaded successfully", "Upload succeeded", 5000);
    // this.getDocuments(internal);
    // this.setState({
    //     fileToUpload: null,
    //     internalFileToUpload: null
    // })
    //     }
    // }).catch(ex => {
    //     NotificationManager.error("Could not upload file, please try again", "Upload failed", 5000);
    // })
};
