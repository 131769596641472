import React from 'react';
import AccountOperationViews from './accountoperationviews';
import { ReactComponent as TableIcon } from '../../images/icons/optimar_list_blue.svg';
import { ReactComponent as TableIconInactive } from '../../images/icons/optimar_list_inactive.svg';
import { ReactComponent as GanttIcon } from '../../images/icons/optimar_gantt_blue.svg';
import { ReactComponent as GanttIconInactive } from '../../images/icons/optimar_gantt_inactive.svg';

export const ViewSwitcher = (props) => {
    return (
        <>
            <span></span>
            {props.View === AccountOperationViews.ListView ? (
                <TableIcon key="gth-4" onClick={() => props.onClick(AccountOperationViews.ListView)} />
            ) : (
                <TableIconInactive key="gth-4" onClick={() => props.onClick(AccountOperationViews.ListView)} />
            )}
            {props.View === AccountOperationViews.GanttChart ? (
                <GanttIcon key="gth-5" onClick={() => props.onClick(AccountOperationViews.GanttChart)} />
            ) : (
                <GanttIconInactive key="gth-5" onClick={() => props.onClick(AccountOperationViews.GanttChart)} />
            )}
        </>
    );
};
