import React from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import './AdminJobTypeAddEdit.scss';
import Select from 'react-select';
import GroupableSelect from '../GroupableSelect/GroupableSelect';
import { guidIsNullOrEmpty } from '../../../utility/guidFunctions';

class AdminJobTypeAddEdit extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props)

        this.state = {
            formErrors: { name: '', typeKey: '' },
            nameValid: false,
            typeKeyValid: false,
            isComponentOrIsStructuralValid: false,
            dataTypeValid: false,
            isDirty: false,
            editJobType: { isComponent: true },
            enableSubmit: true,
            plants: [],
            jobTypes: props.allJobTypes,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.editJobType) {
            const tmp = Object.assign({}, this.props.editJobType);

            this.setState({
                editJobType: tmp,
                mode: 'edit',
            });
        }
        this.fetchPlants();
    }

    handleSubmit(event) {
        if (this.state.mode === 'edit') {
            this.updateSubmit(event);
        } else {
            this.createSubmit(event);
        }
    }

    updateSubmit(event) {
        event.preventDefault();

        const tmpJobType = Object.assign({}, this.state.editJobType);

        if (this.props.plant_specific) tmpJobType.plantId = this.props.plant_specific;

        api.put(process.env.REACT_APP_API + '/api/jobtypes/' + tmpJobType.id, tmpJobType)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Operation type updated', 'Operation type successfully updated', 5000);
                    this.props.editedCallback(response.data);
                } else {
                    throw new Error(response.status);
                }
            })
            .catch(() => {
                NotificationManager.error('Error', 'Failed to update operation type', 5000);
            });
    }

    createSubmit(event) {
        event.preventDefault();
        const tmpJobType = Object.assign({}, this.state.editJobType);

        if (this.props.plant_specific) tmpJobType.plantId = this.props.plant_specific;

        api.post(process.env.REACT_APP_API + '/api/jobtypes', tmpJobType)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Operation type created', 'Operation type successfully created', 5000);
                    this.props.cancelCallback();
                } else {
                    throw new Error(response.status);
                }
            })
            .catch(() => {
                NotificationManager.error('Error', 'Failed to create operation type', 5000);
            });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const tmp = this.state.editJobType;
        tmp[name] = value;

        this.setState({ editJobType: tmp, isDirty: true });
    }

    handleSelectChange = (selected, type) => {
        //console.log(selected)
        if (type === 'parent') {
            //console.log("Handling select change",selected);
            const tmp = Object.assign({}, this.state.editJobType);
            tmp.parentId = selected?.value;
            this.setState({ editJobType: tmp });
            return;
        }
        const tmp = Object.assign({}, this.state.editJobType);
        tmp.plantId = selected?.value;
        this.setState({
            editJobType: tmp,
            selectedPlant: selected,
            jobTypes: this.getPropTypes(tmp.plantId),
        });
    };

    fetchPlants() {
        api.get(process.env.REACT_APP_API + '/api/admin/plants')
            .then((res) => this.setState({ plants: res.data }))
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }
    getPropTypes = (plantId) => {
        //todo: filter on selected plant..if any
        const selectedPlantId = plantId;
        if (!this.props.allJobTypes) return [];
        const jts = selectedPlantId
            ? this.props.allJobTypes.filter((jt) => jt.plantId === selectedPlantId || guidIsNullOrEmpty(jt.plantId))
            : this.props.allJobTypes;
        // console.log("Selected plant id",selectedPlantId,jts);
        return jts;
    };
    render() {
        const plants = this.state.plants;
        const plantsForDropdown = plants.map(function (d) {
            return {
                value: d.id,
                label: d.name,
            };
        });
        //console.log(this.props.allJobTypes)
        // const jobTypesForDropdown = this.props.allJobTypes.filter(x => x.id !== this.state.editJobType.id).map(function (d) {
        //     return {
        //         value: d.id,
        //         label: d.name
        //     }
        // })

        return (
            <div className="admin-itemtype-add-edit-form">
                {this.state.mode === 'edit' ? <h1>Edit operation type</h1> : <h1>New operation type</h1>}
                <form className="add-edit-form" onSubmit={this.handleSubmit}>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            required
                            name="name"
                            value={this.state.editJobType.name || ''}
                            onChange={this.handleChange}
                        />
                    </div>

                    <div>
                        <label htmlFor="name">Configuration:</label>
                        <textarea
                            type="text"
                            className={'admin-input-control'}
                            autoComplete="off"
                            name="configuration"
                            value={this.state.editJobType.configuration || ''}
                            onChange={this.handleChange}
                        />
                    </div>

                    {this.props.plant_specific ? (
                        ''
                    ) : (
                        <div className="form-group">
                            <label htmlFor="plant_type">Plant:</label>
                            <Select
                                name={'plantTypeId'}
                                isClearable
                                value={plantsForDropdown.filter((p) => p.value === this.state.editJobType.plantId)}
                                onChange={this.handleSelectChange}
                                options={plantsForDropdown}
                                classNamePrefix={'optimar'}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="plant_type">Parent type:</label>
                        <>
                            <GroupableSelect
                                name={'parentId'}
                                data={this.state.jobTypes}
                                onChange={(selected) => this.handleSelectChange(selected, 'parent')}
                                required={false}
                                labelFunc={(d) => d.name}
                                valueFunc={(d) => d.id}
                                groupBy={(d) => d.plantName}
                                defaultGroup={'Global'}
                                selectedValue={this.state.editJobType.parentId}
                            />
                            {/* <Select
                                name={"parentId"}
                                value={jobTypesForDropdown.filter(p => p.value === this.state.editJobType.parentId)}
                                onChange={(selected) => this.handleSelectChange(selected, "parent")}
                                options={jobTypesForDropdown}
                                classNamePrefix={"optimar"}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            /> */}
                        </>
                    </div>

                    <div className="btn-row btn-row-grow">
                        <button className="btn btn-yellow btn-grow" onClick={this.props.cancelCallback}>
                            Cancel
                        </button>
                        <button className="btn btn-blue btn-grow" disabled={!this.state.enableSubmit} value="Submit">
                            {this.state.mode === 'edit' ? 'Save changes' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

AdminJobTypeAddEdit.propTypes = {
    editJobType: PropTypes.object,
};

export default AdminJobTypeAddEdit;
