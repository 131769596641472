import React from 'react';
import { useAsyncDebounce } from 'react-table';
import FontAwesome from 'react-fontawesome';

// eslint-disable-next-line no-unused-vars
export default function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    // const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || null);
    }, 200);

    return (
        <div className="list-search">
            <input
                value={value || ''}
                name="global-search"
                className="global-search"
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Search`}
                style={{
                    fontSize: '1.1rem',
                    border: '0',
                }}
                autoComplete={'off'}
            />
            <FontAwesome className="search-icon" name="search" />
        </div>
    );
}
