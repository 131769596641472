import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { withLocation, withRouteMatch } from '../../../HOC';
import api from '../../../utility/api';
import AccountDashboard from '../../../views/accountdashboard';
import AdminAccModuleAddEdit from '../AdminAccountAddEdit/AdminAccModuleAddEdit';
import AdminAccountAddEdit from '../AdminAccountAddEdit/AdminAccountAddEdit';
import AdminAccPlantAddEdit from '../AdminAccountAddEdit/AdminAccPlantAddEdit';
import AdminAccUserAddEdit from '../AdminAccountAddEdit/AdminAccUserAddEdit';
import NavConfig from '../Nav/NavConfig';
import NavContainer from '../Nav/NavContainer';
import Spinner from '../Spinner/Spinner';
import './AdminAccountOverview.scss';

class AdminAccountOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: {},
        };
        this.NavConfig = JSON.parse(JSON.stringify(NavConfig));
        this.accountEdited = this.accountEdited.bind(this);
    }

    componentDidMount() {
        if (this.props.user) this.fetchAccount(this.props.match.params.accountId, this.props.user);
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.account) {
            this.fetchAccount(this.props.match.params.accountId, this.props.user);
        }
    }

    fetchAccount(accountId, user) {
        api.get(process.env.REACT_APP_API + `/api/accounts/${accountId}`, {
            mode: 'cors',
            headers: new Headers({
                Authorization: 'Bearer ' + user.access_token,
            }),
        }).then((res) => {
            this.setState({ account: res.data });
        });
    }

    accountEdited() {
        this.fetchAccount(this.props.match.params.accountId, this.props.user);
    }

    render() {
        if (!this.state.account.name) {
            return <Spinner text="account" />;
        }
        return (
            <div className="items-add-edit-container">
                <div className="items-add-edit-header">
                    <span>Account</span>
                </div>
                <div className="items-add-edit-content">
                    <div className="items-add-edit-tree-account">
                        <label className="ao-title">
                            <h4>{this.state.account.name} </h4>
                        </label>
                        <div>
                            {' '}
                            {this.state.account.accountState === 1 ? (
                                <span className="ao-title enabled">Enabled</span>
                            ) : (
                                <span className="ao-title disabled">Disabled</span>
                            )}
                        </div>
                    </div>
                    <div
                        className={
                            'items-add-edit ' +
                            (this.props.location.pathname.indexOf('dashboard') > -1 ? 'dashboard-edit' : '')
                        }
                    >
                        <Switch>
                            <Route path="/admin/account">
                                {this.props.location.pathname.indexOf('dashboard') === -1 ? (
                                    <NavContainer
                                        path={this.props.match}
                                        config={null}
                                        defaultConfig={this.NavConfig.accoverview}
                                        plant={null}
                                    />
                                ) : null}
                            </Route>
                        </Switch>
                        <div className="ao-container">
                            <Switch>
                                <Route exact path="/admin/account/:accountId/general" name="General account admin">
                                    <AdminAccountAddEdit
                                        accountToEdit={this.state.account}
                                        accountEdited={this.accountEdited}
                                    />
                                </Route>
                                <Route path="/admin/account/:accountId/plants" name="Account plants">
                                    <AdminAccPlantAddEdit accountToEdit={this.state.account} />
                                </Route>
                                <Route path="/admin/account/:accountId/users" name="Account users">
                                    <AdminAccUserAddEdit accountToEdit={this.state.account} />
                                </Route>
                                <Route path="/admin/account/:accountId/modules" name="Account modules">
                                    <AdminAccModuleAddEdit accountToEdit={this.state.account} />
                                </Route>
                                <Route path="/admin/account/:accountId/dashboard" name="Account dashboard">
                                    <AccountDashboard accountMode={true} adminEdit={true} />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default withLocation(withRouteMatch(connect(mapStateToProps)(AdminAccountOverview)));
