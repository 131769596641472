import { SET_DOCLIST, CLEAR_DOCLIST } from '../actions';

const documents = (
    state = {
        docList: undefined,
    },
    action
) => {
    switch (action.type) {
        case SET_DOCLIST:
            return {
                ...state,
                docList: action.data,
            };
        case CLEAR_DOCLIST:
            return {
                ...state,
                docList: undefined,
            };
        default:
            return state;
    }
};

export default documents;
