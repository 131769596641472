import React from 'react';
import PropTypes from 'prop-types';
import '../widget.css';
import './Statistics.scss';
import { forEach } from 'lodash';
import Spinner from '../../common/Spinner/Spinner';
import api from '../../../utility/api';
import { getTimeSince } from '../../../utility/timesince';

class ValueCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
        this.getChartInfo = this.getChartInfo.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.shouldFetch && this.props.user && JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });
        }
    }

    componentDidMount() {
        if (this.props.user) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });

            this.interval = setInterval(() => {
                this.getChartInfo((res) => {
                    if (this.mounted) {
                        this.setState({
                            res: res,
                        });
                    }
                });
            }, this.props.refreshInterval || 10000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.mounted = false;
    }

    getChartInfo = (callback) => {
        // let daterange = "&from=" + (this.props.endDate ? (moment(this.props.endDate).add(-this.props.period.period, this.props.period.interval).toISOString() + "&to=" + this.props.endDate) : moment().add(-this.props.period.period, this.props.period.interval).toISOString() + "&to=" + moment().utc().toISOString());
        let apiUrl =
            process.env.REACT_APP_API +
            '/api/plants/' +
            this.props.plantId +
            '/kpi?machinenumber=' +
            this.props.machineNumber +
            '&valueFormat=single&calculation=last&dataType=' +
            this.props.dataType +
            '&dataValue=' +
            this.props.dataValue;

        forEach(this.props.components, (component) => {
            apiUrl += '&componentId=' + component;
        });

        api.get(apiUrl)
            .then((res) => {
                return callback(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    render() {
        const res = this.state.res || this.props.data || { statistics: { min: 0, max: 0, avg: 0 }, data: {} };
        if (!res) {
            return <Spinner text="data" />;
        }

        return (
            <>
                <span className="time-since">{getTimeSince(res)}</span>
                <div className="widget-header">
                    <span className="widget-title">{this.props.title}</span>
                </div>
                <div className={'value-cell ' + (this.props.endDate ? 'end-date-selected' : '')}>
                    <div className={'widget-content'}>
                        <div className="statistics-container">
                            <span className="stat-cell">
                                <span className="stat-num">{res.statistics.max.toFixed(2) || 'N/A'}</span>
                                <span>Max</span>
                            </span>
                            <span className="stat-cell">
                                <span className="stat-num">{res.statistics.avg.toFixed(2) || 'N/A'}</span>
                                <span>Avg</span>
                            </span>
                            <span className="stat-cell">
                                <span className="stat-num">{res.statistics.min.toFixed(2) || 'N/A'}</span>
                                <span>Min</span>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

ValueCell.propTypes = {
    itemId: PropTypes.string,
};

export default ValueCell;
