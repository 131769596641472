import { constant, noop } from 'lodash';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import AdminPlantEventSubAddEdit from '../../components/common/AdminPlantEventSubAddEdit/AdminPlantEventSubAddEdit';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import Spinner from '../../components/common/Spinner/Spinner';
import api from '../../utility/api';
import { modalStyle } from '../../utility/modalstyles';

class AdminPlantEventSubs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.fetching = false;
        this.showSpinner = true;
        const rowContext = [
            {
                content: <span>Edit event subscription</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete event subscription</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];
        this.visibleColumns = [
            { Header: 'Component', accessor: 'topic', filterable: true },
            { Header: 'Data value', accessor: 'dataValue', filterable: true },
            { Header: 'Operator', accessor: 'triggerOperator', filterable: true },
            { Header: 'Action', accessor: 'triggerAction', filterable: true },
            { Header: 'Trigger value', accessor: 'triggerValue', filterable: true },
            {
                Header: 'Triggered',
                accessor: 'triggered',
                filterable: true,
                Cell: (row) => <div>{row.value?.toString()}</div>,
            },
            {
                Header: 'Trigger once',
                accessor: 'triggerOnce',
                filterable: true,
                Cell: (row) => <div>{row.value?.toString()}</div>,
            },
            {
                Header: 'Enabled',
                accessor: 'enabled',
                filterable: true,
                Cell: (row) => <div>{row.value?.toString()}</div>,
            },
            {
                Header: constant(null),
                id: 'rowcontext',
                Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
            },
        ];

        this.fetchEventSubscriptions = this.fetchEventSubscriptions.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.deleteEventSubscription = this.deleteEventSubscription.bind(this);
    }

    componentDidMount() {
        this.fetchEventSubscriptions();
    }

    fetchEventSubscriptions() {
        if (!this.fetching) {
            api.get(process.env.REACT_APP_API + '/api/admin/eventsubscriptions/all?plantId=' + this.props.plant.id)
                .then((res) => {
                    this.setState({
                        eventSubscriptions: res.data,
                    });
                    this.fetching = false;
                    this.showSpinner = false;
                    this.forceUpdate();
                })
                .catch(() => {
                    this.fetching = false;
                    this.showSpinner = false;
                    NotificationManager.error('Failed to fetch', 'Could not fetch event subscriptions');
                });
        }
    }

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editEventSubscription: item,
                editType: mode,
            },
            function () {
                this.fetchEventSubscriptions();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editEventSubscription: null,
                editType: null,
            },
            function () {
                this.fetchEventSubscriptions();
            }
        );
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete event subscription</h1>
                        <p>
                            You are about to delete the event subscription
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteEventSubscription(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteEventSubscription = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(
                process.env.REACT_APP_API +
                    '/api/admin/eventsubscriptions/' +
                    this.props.plant.id +
                    '/' +
                    itemFromChild.id
            )
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success(
                            'Event subscription deleted',
                            'Event subscription successfully deleted',
                            5000
                        );
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete event subscription ', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchEventSubscriptions();
                })
                .catch(noop);
        }
    };

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null
            ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
            : false;
    }

    render() {
        const eventSubs = this.state.eventSubscriptions;

        if (this.showSpinner) return <Spinner text="event subscriptions" />;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{eventSubs ? eventSubs.length : 0} Event subscriptions</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New event subscription</span>{' '}
                        </span>
                    </span>
                </div>
                <GenericTable data={eventSubs} columns={this.visibleColumns} localPaging={true} />

                {
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={modalStyle}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <AdminPlantEventSubAddEdit
                            editItem={this.state.editEventSubscription}
                            editType={this.state.editType}
                            closeModal={this.closeModal}
                        ></AdminPlantEventSubAddEdit>
                    </Modal>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        plant: state.plants.currentAdminPlant,
    };
};

export default connect(mapStateToProps)(AdminPlantEventSubs);
