import React from "react";
import { ViewMode } from "gantt-task-react";
import SelectorButton from "../../../components/common/Buttons/selectorbutton/SelectorButton";

export const GanttTimeSwitcher = ({
  onViewModeChange, view
}) => {
  return (
    <div>
      <SelectorButton
        isActive={view === ViewMode.Month}
        onClick={() => onViewModeChange(ViewMode.Month)}>Month</SelectorButton>
      
      <SelectorButton
        isActive={view === ViewMode.Week}
        onClick={() => onViewModeChange(ViewMode.Week)}>Week</SelectorButton>

      <SelectorButton 
        isActive={view === ViewMode.Day}
        onClick={() => onViewModeChange(ViewMode.Day)}>Day</SelectorButton>       

      <SelectorButton 
        isActive={view === ViewMode.Hour}
        onClick={() => onViewModeChange(ViewMode.Hour)}>Hour</SelectorButton> 
    </div>
  );
};
