import React from 'react';
import api from '../api';

import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';

export class DeviceVersioning {
    latestVersionPromise;
    plantVersionsPromise;
    plantUpdateEventsPromise;

    async fetchLatestVersions() {
        // do not use await with then() and catch() -> It is possible, but it does not behave as one would expect.
        try {
            if (!this.latestVersionPromise)
                this.latestVersionPromise = api.get(process.env.REACT_APP_API + '/api/admin/iotdevice/latestversion');

            const res = await this.latestVersionPromise;

            return res.data;
        } catch (err) {
            console.log(err);
            NotificationManager.error('Failed to fetch', 'Could not fetch latest versions');
        }
    }

    async fetchPlantVersion(plantKey, force = false) {
        // Maybe better so create a separate endpoint in the backend to just get 1 version?
        const allVersions = await this.fetchPlantVersions(force);
        const plantVersionsResult = allVersions[plantKey];
        //console.log(allVersions,plantVersionsResult,plantKey)
        return plantVersionsResult;
    }

    async fetchPlantVersions(force = false) {
        try {
            if (!this.plantVersionsPromise || force)
                this.plantVersionsPromise = api.get(process.env.REACT_APP_API + '/api/admin/iotdevice/plantversions');

            const res = await this.plantVersionsPromise;
            this.plantVersionsPromise = null;
            return res.data;
        } catch (err) {
            console.log(err);
            NotificationManager.error('Failed to fetch', 'Could not fetch plant versions');
        }
    }

    //Fetch update events from all plants.
    async fetchLatestUpdateEvents(force = false) {
        if (!this.plantUpdateEventsPromise || force)
            this.plantUpdateEventsPromise = api.post(process.env.REACT_APP_API + `/api/admin/iotdevice/updateevents`);
        const res = await this.plantUpdateEventsPromise;
        this.plantUpdateEventsPromise = null;

        return res.data;
    }
    //Fetch update events from a single plant
    async fetchLatestUpdateEvent(plantKey) {
        if (!plantKey) return;

        const res = await api.post(`/api/admin/iotdevice/updateevent/${plantKey}`);

        return res.data.message;
    }

    isLatestCommanderEdgeDockerVersion(myVersion) {
        //since isLatestVersion() is now an async function -> These functions will now return promises that must be awaited.
        return this.isLatestVersion(myVersion, 'edgeServiceDockerImageVersion');
    }

    isLatestCommanderEdgeVersion(myVersion) {
        //since isLatestVersion() is now an async function -> These functions will now return promises that must be awaited.
        return this.isLatestVersion(myVersion, 'edgeServiceVersion');
    }

    async isLatestVersion(myVersion, versionVarName) {
        const latestVersion = await this.fetchLatestVersions();

        if (!latestVersion) return true;

        console.log(latestVersion, myVersion, versionVarName);
        return latestVersion[versionVarName] === myVersion;
    }

    confirmUpdateDevice(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Update device</h1>
                        <p>
                            You are about to update this device to the latest version
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <span>
                            <b>Devices with version lower than 1.0.2114102 cannot be auto updated!</b>
                        </span>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.updateDevice(item);
                                    onClose();
                                }}
                            >
                                Yes, update
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    async updateDevice(item) {
        NotificationManager.info('Sending update command', 'Device Update', 5000);
        try {
            const res = await api.post(`api/admin/iotdevice/update/${item.deviceId}`);

            if (res.status === 200)
                NotificationManager.success(
                    'Device update command sent. The process might take a few minutes, and the device may be offline for a short period of time.',
                    'Update command sent'
                );
        } catch (err) {
            NotificationManager.error('Could not send update command. Try again later.', 'Failed to send update');
        }
    }
}
