import React from 'react';
import './OptiControlLive.scss';
import BarGauge from '../../Widgets/BarGauge/BarGauge';
import VerticalBar from '../../Widgets/VerticalBar/VerticalBar';
import Gauge from '../../Widgets/Gauge/Gauge';
import { format } from 'date-fns';

class OptiControlLive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: Date.now(),
            currentData: this.props.data,
            curDataString: this.props.data ? JSON.stringify(this.props.data) : null,
        };
        this.mounted = true;
    }

    componentDidUpdate() {
        if (this.props.data) {
            const newData = JSON.stringify(this.props.data);
            if (!this.state.curDataString || this.state.curDataString !== newData) {
                console.log('updating..');
                this.setState({
                    curDataString: newData,
                    currentData: this.props.data,
                });
            }
        } else {
            console.log('Skipping updating..');
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const w = this.props;
        // if (!w.data) return <h3>Loading Opti Control..</h3>;

        if (!this.state.currentData) return <h3>Loading Opti Control..</h3>;
        const currentData = this.state.currentData;
        return (
            <div className={'ocl-solution'}>
                <div className="data-title">
                    <span className="data-title__label">{w.title}</span>
                </div>
                <div className="ocl-main">
                    <div className={'vertical-bars'}>
                        {currentData.productionLines.map((l) => {
                            return (
                                <VerticalBar
                                    key={w.itemKey}
                                    title={l.description}
                                    data={{
                                        data: {
                                            value: l.produced || 0,
                                            downTime: l.dueToError || 0,
                                            timestamp: l.estimatedTimeEnd,
                                        },
                                    }}
                                    itemKey={w.itemKey}
                                    type={'opti-control-live'}
                                    configuration={{ minValue: 0, maxValue: l.capacityInPeriod || 0 }}
                                />
                            );
                        })}
                    </div>
                    <div className={'ocl-right-container'}>
                        <div className={'ocl-time'}>
                            <span>{format(this.state.time, 'HH:mm:ss')}</span>
                        </div>
                        <div className={'ocl-gauge'}>
                            <Gauge
                                title={''}
                                data={{
                                    data: { value: currentData.batchNumbers[0].productionSpeedPerHourInPeriod || 0 },
                                }}
                                itemKey={w.itemKey}
                                configuration={{
                                    minValue: 0,
                                    maxValue: currentData.batchNumbers[0].maxProductionSpeedPerHourInPeriod || 0,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={'ocl-line-bar'}>
                    <BarGauge
                        title={''}
                        data={{
                            data: {
                                value: currentData.batchNumbers[0].totalProduced || 0,
                                timestamp: currentData.batchNumbers[0].estimatedTimeEnd,
                            },
                        }}
                        itemKey={w.itemKey}
                        configuration={{
                            size: '',
                            minValue: 0,
                            maxValue:
                                currentData.batchNumbers[0].estimatedGrossWeight ||
                                currentData.batchNumbers[0].totalProduced +
                                    currentData.batchNumbers[0].totalRemaining ||
                                0,
                        }}
                    />
                    <div className={'etf'}>
                        <span className={'heading'}>ETF</span>
                        <span>{format(new Date(currentData.estimatedTimeEnd), 'HH:mm:ss')}</span>
                    </div>
                </div>
            </div>
        );
    }
}

OptiControlLive.propTypes = {};

export default OptiControlLive;
