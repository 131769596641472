import EarthIcon from '../../../styles/icons/EarthIcon';
import ListIcon from '../../../styles/icons/ListIcon';
import DashboardIcon from '../../../styles/icons/DashboardIcon';
import DownloadIcon from '../../../styles/icons/DownloadIcon';

const NavConfig = {
    account: [
        {
            name: 'Plants',
            link: '/account/plant',
            icon: DashboardIcon,
        },
        {
            name: 'Dashboard',
            link: '/account/dashboard',
            icon: DashboardIcon,
        },
        {
            name: 'Notes',
            link: '/account/notes',
            icon: DownloadIcon,
        },
        {
            name: 'Documentation',
            link: '/account/documentation',
            icon: DownloadIcon,
        },
        {
            name: 'Projects',
            link: '/account/projects',
        },
        {
            name: 'Support',
            link: '/account/support',
        },
        {
            name: 'Operations',
            link: '/account/operations',
        },
    ],
    map: [
        {
            name: 'Kart',
            link: '/',
            icon: EarthIcon,
        },
        {
            name: 'List',
            link: '/list',
            icon: ListIcon,
        },
        {
            name: 'AccountDashboard',
            link: '/accountdashboard',
            icon: DashboardIcon,
        },
    ],

    detail: [
        {
            name: 'Overview',
            link: '/overview',
            icon: DashboardIcon,
        },
        {
            name: 'Dashboard',
            link: '/kpi',
            icon: DownloadIcon,
        },
        {
            name: 'Notes',
            link: '/note',
            icon: DownloadIcon,
        },
        {
            name: 'Documentation',
            link: '/documentation',
            icon: DownloadIcon,
        },
        {
            name: 'Events',
            link: '/events',
            icon: DownloadIcon,
        },
        {
            name: 'Settings',
            link: '/settings',
            icon: DownloadIcon,
        },
        {
            name: 'Alarms',
            link: '/alarms',
            icon: DownloadIcon,
        },
        {
            name: 'Traces',
            link: '/trace',
            icon: DownloadIcon,
        },
        {
            name: 'Operations',
            link: '/operations',
        },
        {
            name: 'Support',
            link: '/support',
        },
    ],
    list: [
        {
            name: 'Kart',
            link: '/',
            icon: EarthIcon,
        },
        {
            name: 'List',
            link: '/list',
            icon: ListIcon,
        },
    ],
    admin: [
        {
            name: 'Users',
            link: '/admin/users',
            icon: DashboardIcon,
        },
        {
            name: 'Plants',
            link: '/admin/plant',
            icon: DownloadIcon,
        },
        {
            name: 'Accounts',
            link: '/admin/account',
            icon: DownloadIcon,
        },
        {
            name: 'Keywords',
            link: '/admin/tags',
            icon: DownloadIcon,
        },
        {
            name: 'Projects',
            link: '/admin/projects',
        },
        {
            name: 'Item types',
            link: '/admin/itemtypes',
        },
        {
            name: 'Operation types',
            link: '/admin/operationtypes',
        },
        {
            name: 'Articles',
            link: '/admin/articles',
        },
        {
            name: 'Formulas',
            link: '/admin/formulas',
        },
        {
            name: 'Gateways',
            link: '/admin/iotdevices',
        },
        {
            name: 'ToS',
            link: '/admin/tos',
        },
        {
            name: 'Roles',
            link: '/admin/role',
        },
    ],
    accoverview: [
        {
            name: 'General',
            link: '/admin/account/general',
        },
        {
            name: 'Users',
            link: '/admin/account/users',
        },
        {
            name: 'Attached plants',
            link: '/admin/account/plants',
        },
        {
            name: 'Modules',
            link: '/admin/account/modules',
        },
    ],
    plantoverview: [
        {
            name: 'General',
            link: '/admin/plant/items',
        },
        {
            name: 'Subdevices',
            link: '/admin/plant/subdevices',
        },
        {
            name: 'New items',
            link: '/admin/plant/newitems',
        },
        {
            name: 'Components',
            link: '/admin/plant/components',
        },
        {
            name: 'Accounts',
            link: '/admin/plant/accounts',
        },
        {
            name: 'Projects',
            link: '/admin/plant/projects',
        },
        // {
        //     name: 'SLA',
        //     link: '/admin/plant/sla'
        // },
        {
            name: 'Config',
            link: '/admin/plant/config',
        },
        {
            name: 'Log',
            link: '/admin/plant/log',
        },
        {
            name: 'Gateway',
            link: '/admin/plant/device',
        },
        {
            name: 'Event subscriptions',
            link: '/admin/plant/eventsubscriptions',
        },
        {
            name: 'Operation types',
            link: '/admin/plant/operationtypes',
        },
    ],
    projOverview: [
        {
            name: 'General',
            link: '/admin/project/general',
        },
        {
            name: 'Machines',
            link: '/admin/project/machines',
        },
    ],
    dashboards: [
        {
            name: 'My dashboards',
            link: '/dashboards/my',
        },
        {
            name: 'Shared dashboards',
            link: '/dashboards/shared',
        },
        {
            name: 'Account dashboards',
            link: '/dashboards/account',
        },
        {
            name: 'Plant dashboards',
            link: '/dashboards/plant',
        },
    ],
    dashboardedit: [
        {
            name: 'Preview',
            link: '/dashboards/:dashboardId',
        },
    ],
    dashboardeditpane: [
        {
            name: 'Settings',
            link: '',
        },
    ],
};

export default NavConfig;
