import { noop } from 'lodash';
import React from 'react';
import Dropzone from 'react-dropzone';
import FontAwesome from 'react-fontawesome';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import api from '../../utility/api';

const overlayStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '2.5em 0',
    background: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    color: '#fff',
};

const dropzoneStyle = {
    border: '1px dashed #919191',
    marginTop: '20px',
    position: 'relative',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    position: 'relative',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    margin: '0 auto',
};

const accept = ['application/pdf'];

class AdminArticleAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            existingFiles: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    handleSubmit(_event) {
        this.uploadFiles();
    }

    removeFile(index) {
        const files = this.state.files;
        files.splice(index, 1);
        this.setState({ files });
    }

    uploadFiles() {
        this.state.files.forEach((file) => {
            this.fileUpload(file);
        });
    }

    onDrop(files) {
        if (files.length < 1) {
            NotificationManager.error('File type not supported', 'File error', 5000);
            return;
        }
        const prevFiles = this.state.files;
        prevFiles.push(...files);
        this.setState({
            files: prevFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
        });
    }

    fileUpload(file) {
        if (this.uploading) {
            return;
        }

        const data = new FormData();
        data.append('File', file);
        api.post(process.env.REACT_APP_API + '/api/tos', data)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        files: [],
                    });
                    this.props.fetchToS();
                    NotificationManager.success('File uploaded', 'File uploaded successfully', 5000);
                    this.props.closeModal();
                } else {
                    NotificationManager.error('Error', 'Failed to upload file', 5000);
                    throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    render() {
        const { files } = this.state;

        const thumbs = files.map((file, i) => (
            <div className="file-preview" key={'preview-' + i}>
                <div style={thumb} key={file.name + '-' + i}>
                    <FontAwesome name="remove" className="file-remove" onClick={() => this.removeFile(i)} />

                    <div style={thumbInner}>
                        <a href={file.preview} rel="noopener noreferrer" target="_blank">
                            {file.type.includes('image') ? (
                                <img src={file.preview} style={img} alt="preview" />
                            ) : (
                                <FontAwesome name="file" size="3x" style={{ margin: '0 auto', color: '#919191' }} />
                            )}
                        </a>
                    </div>
                </div>
                <span>{file.name}</span>
            </div>
        ));

        return (
            <div className="admin-plant-add-edit-form">
                {this.state.mode === 'edit' ? <h1>Edit ToS</h1> : <h1>New ToS</h1>}
                <div className={'form-container ' + this.props.className ? this.props.className : null}>
                    <form style={{ width: 'unset' }} onSubmit={this.handleSubmit}>
                        <Dropzone onDrop={this.onDrop.bind(this)} accept={accept}>
                            {({ getRootProps, getInputProps, isDragActive, _isDragReject }) => (
                                <div {...getRootProps()} style={dropzoneStyle}>
                                    Drop file here or click to upload
                                    <input {...getInputProps()} />
                                    {isDragActive && <div style={overlayStyle}></div>}
                                </div>
                            )}
                        </Dropzone>

                        <aside style={thumbsContainer}>{thumbs}</aside>
                    </form>
                </div>
                <div className="existing-files">
                    {this.state.existingFiles.map((file) => {
                        return (
                            <div className="file" key={file.filename}>
                                {file.filename}
                            </div>
                        );
                    })}
                </div>

                <div className="btn-row btn-row-grow">
                    <button className="btn btn-yellow btn-grow" onClick={this.props.cancelCallback}>
                        Cancel
                    </button>
                    <button className="btn btn-blue btn-grow" onClick={this.handleSubmit} value="Submit">
                        Create
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminArticleAddEdit);
