import React from 'react';

export const FormErrors = ({ formErrors }) => (
    <div className="formErrors">
        {Object.keys(formErrors).map((fieldName, i) => {
            if (formErrors[fieldName].length > 0) {
                return (
                    <>
                        <span key={i}>
                            {(fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1, fieldName.length)).replace(
                                '_',
                                ' '
                            )}{' '}
                            {formErrors[fieldName]}
                        </span>
                        <br />
                    </>
                );
            } else {
                return '';
            }
        })}
    </div>
);
