import React from 'react';
//import PropTypes from 'prop-types';
import * as moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import api from '../../../utility/api';
import GenericTable from '../../common/GenericTable';
import PeriodPicker from '../../common/PeriodPicker/PeriodPicker';
import '../widget.css';
import { getDefaultTicksAndPeriod, getToAndFromBasedOnPeriodOrJob, IsRangeValid } from '../widgetHelper';
import { getColumns, getHeader } from './listdefinition';
import { groupBy } from 'lodash';

class DatavalueLogList extends React.Component {
    constructor(props) {
        super(props);

        const defaultPeriod = props.widget?.defaultPeriod || 'day';
        let { ticks, period } = getDefaultTicksAndPeriod(defaultPeriod);

        this.state = {
            values: undefined,
            loadingData: false,
            endDate: null,
            period: {
                period: period,
                interval: defaultPeriod,
                ticks: ticks,
            },
        };

        this.onDateRangeChanged = this.onDateRangeChanged.bind(this);
        this.getLogs = this.getLogs.bind(this);
    }

    componentDidMount() {
        this.getLogs();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.data && JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.getLogs();
        }
    }
    onDateRangeChanged = (period, endDate) => {
        this.setState({ loadingData: true, period: period, endDate: endDate }, function () {
            this.getLogs();
        });
    };

    getLogs = async (_callback) => {
        const period = this.state.period;
        let { from, to } = getToAndFromBasedOnPeriodOrJob(this.props.job, period, this.state.endDate);

        if (!IsRangeValid(from, to)) {
            console.log('Provided range is outside of maximum range.', from, to);
            return;
        }
        try {
            const daterange = '&from=' + from.toISOString() + '&to=' + to.toISOString();
            const limit = this.props.widget.limit || '10';
            const apiUrl = process.env.REACT_APP_API + '/api/kpi/values?limit=' + limit + daterange; //+ //"&rangeformat=minutes&dataValue=" + this.props.widget?.dataValue + "&componentSettings=" + encodeURIComponent(JSON.stringify(this.props.widget?.componentSettings));

            const data = new FormData();
            data.append('componentSettings', encodeURIComponent(JSON.stringify(this.props.widget?.componentSettings)));
            const res = await api.post(apiUrl, data);
            //.then((res) => {
            const json = res.data;
            console.log('json', json);

            //Handling versioning at this point. -> Datavalues with the same ID (not null) will be a newer version of the value..
            const final = [];
            //let sortedData = json?.data?.sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)));
            if (json?.data) {
                const groupedArr = groupBy(json?.data, 'id');

                Object.keys(groupedArr).forEach((g) => {
                    const itemsWithTheId = groupedArr[g];
                    //console.log(g,typeof(g),g === 'null',itemsWithTheId);
                    //due to groupBy function -> null is indeed a string now..
                    if (!g || g === 'null') {
                        final.push.apply(final, itemsWithTheId);
                        return;
                    }
                    const sortedItems = this.orderByDateDescendingOrReturnEmpty(itemsWithTheId); //itemsWithTheId.sort((a, b) => moment.utc(b.date).diff(moment.utc(a.date)));
                    const revisionCountInPeriod = itemsWithTheId.length - 1;
                    const currentVersion = sortedItems[0];
                    currentVersion.revisionCount = revisionCountInPeriod;
                    final.push(currentVersion);
                });
            }
            console.log('final...', final);
            this.setState({
                values: final,
                loadingData: false,
            });
        } catch (err) {
            NotificationManager.error('There was an error fetching the log list.');
            console.error(err);

            this.setState({
                loadingData: false,
            });
        }
    };
    orderByDateDescendingOrReturnEmpty(items) {
        return items?.sort((a, b) => moment.utc(b.date).diff(moment.utc(a.date))) || [];
    }

    render() {
        //console.log("datavalues", this.state.values)
        const dataToDisplay = this.orderByDateDescendingOrReturnEmpty(this.state.values); //?.sort((a,b) => new Date(b.date) - new Date(a.date)) || [];
        const columns = getColumns(this.props, dataToDisplay, {});
        return (
            <div className="widget">
                <div className="data-title">
                    <span className="data-title__label">{this.props.title}</span>
                </div>
                {!this.props.job ? (
                    <PeriodPicker
                        onDateRangeChanged={this.onDateRangeChanged}
                        period={this.state.period}
                        endDate={this.state.endDate}
                    ></PeriodPicker>
                ) : null}
                <GenericTable
                    enableGlobalFilter={true}
                    localPaging={true}
                    columns={columns}
                    data={dataToDisplay}
                    header={getHeader(dataToDisplay, {})}
                    enabledDatepicker={true}
                    loading={this.state.loadingData}
                    // autoResetExpanded={false}
                    //   onFilterChanging = {onFilterChanging}
                    //   onFilterChanged = {onFilterChanged}
                    //  resetExpandedState = {resetExpandedState}
                />
                {/* {this.state.loadingData ? <div className="loading-data"><Spinner text="new data.." /></div> : null}
                {
                    !res && !this.state.loadingData ? <div>No values found</div> : <>
                        <div className="widget-content-full">
                            <table className="widget-table log-table">
                                <thead>
                                    <tr>
                                        <th>
                                            Date
                                        </th>
                                        <th>
                                            Value
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        res?.map((e, i) => {
                                            console.log(e,i)
                                            return <tr key={i} onClick={() => this.openModal("edit", e)}>
                                                <td>{moment(e.date).format('DD.MM.YYYY HH:mm')}</td>
                                                <td>{e.value}</td>                                                
                                            </tr>
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </>} */}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    endDate: state.itemrow.endDate,
    endDateParent: state.itemrow.endDateParent,
});

export default connect(mapStateToProps)(DatavalueLogList);

// DatavalueLogList.propTypes = {
//     itemId: PropTypes.string
// }

// export default DatavalueLogList;
