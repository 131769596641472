export const SET_NOTELIST = 'SET_NOTELIST';
export const CLEAR_NOTELIST = 'CLEAR_NOTELIST';

export const setNoteList = (notes) => {
    return {
        type: SET_NOTELIST,
        data: notes,
    };
};

export const clearNoteList = (notes) => {
    return {
        type: CLEAR_NOTELIST,
        data: notes,
    };
};
