import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space } from 'antd';
import './DatavalueModalForm.scss';

const { Option } = Select;

const DatavalueModalForm = ({ predefinedDatavalueAttribute, handleDatavalueModalOpenClose, dtByTopicCallback, datavaluesByTopic }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        const fields = values.fields.map((field) => {
            return {
                key: field.key,
                value: field.value ?? predefinedDatavalueAttribute.dtypes[0],
                topic: field.topic ?? predefinedDatavalueAttribute.topicTypes[0].value,
                lastUpdatedTime: field.lastUpdatedTime,
            };
        });

        const dtByTopic = recreateDavaluesObject(fields);
        dtByTopicCallback(dtByTopic);
        handleDatavalueModalOpenClose();
    };
    const recreateDavaluesObject = (fields) => fields.reduce((result, element) => {
        const { payload, route } = getPayloadRoute(element.topic);

        const existingObj = result.find((obj) => obj.topic === element.topic);
        if (existingObj) {
            existingObj.datavalues.push({ key: element.key, value: element.value, lastUpdatedTime: element.lastUpdatedTime });
        } else {
            result.push({
                datavalues: [{ key: element.key, value: element.value, lastUpdatedTime: element.lastUpdatedTime }],
                topic: element.topic,
                payload,
                route,
            });
        }
        return result;
    }, []);

    const getPayloadRoute = (topic) => {
        let payload = 'VAR';
        let route = 'VAL';
        switch (topic) {
            case 'Datavalue':
                payload = 'VAR';
                route = 'VAL';
                break;
            case 'Historic':
                payload = 'VARLIST';
                route = 'VAL';
                break;
            case 'Event':
                payload = 'VAR';
                route = 'EVENT';
                break;
            case 'Trace':
                payload = 'VAR';
                route = 'TRACE';
                break;
            default:
                break;
        }
        return { payload, route };
    };
    const getInitialValue = () => {
        return datavaluesByTopic.flatMap(obj =>
            obj.datavalues.map(item => ({ ...item, topic: obj.topic }))
        );
    };
    const initialValues = getInitialValue();
    return (
        <Form form={form} name="dynamic_form_complex" onFinish={onFinish} style={{ maxWidth: 750 }} autoComplete="off">
            <Form.List name="fields" initialValue={initialValues}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => {
                            return (
                                <Space key={field.key} align="baseline">
                                    <Form.Item
                                        {...field}
                                        label="Datavalue"
                                        name={[field.name, 'key']}
                                        rules={[{ required: true, message: 'Missing datavalue' }]}
                                    >
                                        <Input
                                            onKeyDown={(e) => {
                                                e.key === 'Enter' && e.preventDefault();
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) =>
                                            prevValues.area !== curValues.area || prevValues.fields !== curValues.fields
                                        }
                                    >
                                        {() => (
                                            <Form.Item {...field} label="Dtype" name={[field.name, 'value']}>
                                                <Select
                                                    disabled={!predefinedDatavalueAttribute.dtypes}
                                                    style={{ width: 130 }}
                                                    defaultValue={predefinedDatavalueAttribute.dtypes[0]}
                                                >
                                                    {(predefinedDatavalueAttribute.dtypes || []).map((item) => (
                                                        <Option key={item} value={item}>
                                                            {item}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) =>
                                            prevValues.area !== curValues.area || prevValues.fields !== curValues.fields
                                        }
                                    >
                                        {() => (
                                            <Form.Item {...field} label="Topic Type" name={[field.name, 'topic']}>
                                                <Select
                                                    disabled={!predefinedDatavalueAttribute.topicTypes}
                                                    style={{ width: 130 }}
                                                    defaultValue={predefinedDatavalueAttribute.topicTypes[0].value}
                                                >
                                                    {(predefinedDatavalueAttribute.topicTypes || []).map((item) => (
                                                        <Option key={item.value} value={item.value}>
                                                            {item.value}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </Form.Item>

                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Space>
                            );
                        })}

                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add field
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item>
                <div style={{ display: 'flex', marginTop: 30, justifyContent: 'space-between' }}>
                    <button
                        onClick={handleDatavalueModalOpenClose}
                        className="btn btn-yellow"
                        style={{ minWidth: '45%', marginRight: 10 }}
                    >
                        Cancel
                    </button>
                    <button className="btn btn-blue" style={{ minWidth: '45%' }} value="Submit">
                        Save
                    </button>
                </div>
            </Form.Item>
        </Form>
    );
};

export default DatavalueModalForm;
