import React from 'react';
import './MapInfo.css';
import UploadUploading from '../../images/list/Upload_Connected-Uploading.svg';
import UploadIdle from '../../images/list/Upload_Idle.svg';
import UploadNA from '../../images/list/Upload_Not-Available.svg';
import PositionUploading from '../../images/list/Location_Connected-Uploading.svg';
import PositionError from '../../images/list/Location_Error.svg';
import PositionIdle from '../../images/list/Location_Idle.svg';
import PositionNA from '../../images/list/Location_Not-Available.svg';
import Position from '../../images/list/Location.svg';
import { Link } from 'react-router-dom';

let moment = require('moment');

const MapInfo = ({ info }) => {
    if ('default' in moment) {
        moment = moment.default;
    }

    const getPosition = (element) => {
        let xPosition = 0;
        let yPosition = 0;

        while (element) {
            xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
            yPosition += element.offsetTop - element.scrollTop + element.clientTop;
            element = element.offsetParent;
        }

        return { x: xPosition, y: yPosition };
    };

    const setUploadImage = (plant) => {
        const timeDiff = moment().valueOf() - moment.utc(plant.lastConnectedTime).local().valueOf();
        let image = UploadNA;
        let text = 'Not Available';

        if (timeDiff <= 60 * 60 * 1000) {
            // 1 hour
            image = UploadUploading;
            text = 'Uploading';
        } else if (timeDiff <= 12 * 60 * 60 * 1000) {
            image = UploadIdle;
            text = 'Idle / Standby';
        } else if (!plant.lastConnectedTime) {
            image = UploadNA;
            text = 'Not Available';
        }

        return (
            <span className="cell-image-text">
                <img alt="Uploading" style={{ marginRight: '0.5em' }} src={image} />
                <span className={plant.lastConnectedTime ? '' : 'cell-text-grey'}>
                    {text} <br />
                    {plant.lastConnectedTime ? (
                        <span className="cell-metadata">{moment.utc(plant.lastConnectedTime).local().fromNow()}</span>
                    ) : (
                        ''
                    )}
                </span>
            </span>
        );
    };

    const setPositionImage = (plant) => {
        const timeDiff = moment().valueOf() - moment.utc(plant.lastPositionDate).local().valueOf();
        let image = PositionNA;
        let text = '';

        if (plant.address) {
            text = plant.address;
            return (
                <span className="cell-image-text">
                    <img style={{ marginRight: '0.5em' }} alt="Position Icon" src={Position} />
                    <span className={plant.latitude ? 'cell-text' : 'cell-text-grey'}> {text}</span>
                </span>
            );
        } else if (plant.latitude) {
            text = plant.latitude.toFixed(2) + '°N ' + plant.longitude.toFixed(2) + '°W';
        } else {
            text = 'NOT AVAILABLE';
        }

        if (plant.type !== 'Factory') {
            if (timeDiff <= 60 * 60 * 1000) {
                // 1 hour
                image = PositionUploading;
            } else if (plant.lastPositionDate) {
                image = PositionIdle;
            } else {
                image = PositionError;
            }
        } else {
            image = Position;
        }

        return (
            <span className="cell-image-text">
                <img style={{ marginRight: '0.5em' }} alt="Position Icon" src={image} />
                <span className={plant.latitude ? 'cell-text' : 'cell-text-grey'}>
                    {' '}
                    {text}
                    <br />
                    {plant.type !== 'Factory' ? (
                        <span className="cell-metadata">{moment.utc(plant.lastPositionDate).local().fromNow()}</span>
                    ) : null}
                </span>
            </span>
        );
    };

    // Check if we need to flip the box to fit in map
    const offset = getPosition(document.getElementById('marker-' + info.id));
    let infoclass = 'mapinfo';
    if (offset.y < 250) {
        infoclass = 'mapinfo-flipped';
    }
    return (
        <div className={infoclass}>
            <Link to={`/plant/${info.id}/overview`} className="info-name">
                {info.name}
            </Link>
            <div className="info-data">
                <div className="info-connection">{setUploadImage(info)}</div>
                <div className="info-connection">
                    <span>{setPositionImage(info)}</span>
                </div>
            </div>
        </div>
    );
};

export default MapInfo;
