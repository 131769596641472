import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClickOutsideComponentsEffect } from '../../../hooks/useClickOutsideComponentsEffect';
import IconAngleRight from '../../../images/icons/optimar_angle_right_blue.svg';
import IconBars from '../../../images/icons/optimar_bars_blue.svg';
import FlatTreeNav from '../TreeNav/FlatTreeNav';
import './Breadcrumb.scss';

/**
 * @type {React.FC<{
 *     isMobileOrTablet: Boolean;
 *     toggleTable: (val: Boolean) => void;
 *     toggleSiteMap: (val: Boolean) => void;
 *     showSitemap: Boolean;
 *     subPaths: any[];
 *     paths: { name: string; path: string }[];
 * }>}
 */
const Breadcrumb = (props) => {
    const structureDropdown = useRef(null);
    const subLevelDropdown = useRef(null);
    const [showStructure, setShowStructure] = useState(false);
    const [showSubLevel, setShowSubLevel] = useState(false);

    const closeStructure = (e) => {
        if (
            e.target.nodeName !== 'path' &&
            e.target.nodeName !== 'svg' &&
            !structureDropdown.current?.contains(e.target)
        ) {
            setShowStructure(false);
        }
    };

    const closeSubLevel = (e) => {
        if (!subLevelDropdown.current?.contains(e.target)) {
            setShowSubLevel(false);
        }
    };

    const tableSizeCheck = () => {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 1100;
    };

    useClickOutsideComponentsEffect([structureDropdown], closeStructure);
    useClickOutsideComponentsEffect([subLevelDropdown], closeSubLevel);

    const onClick = () => {
        props.toggleTable(false);
    };

    if (!props.showSitemap) {
        document.removeEventListener('click', () => {
            console.log('props: ', props);
            if (props.showSitemap) {
                setShowStructure(false);
            }
        });
    }

    if (props.subPaths && props.subPaths.length > 0) {
        props.subPaths.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }
    let className = 'crumb';
    let separator = (
        <span className="separator">
            <img src={IconAngleRight} alt="Separator" />
        </span>
    ); /*<FontAwesome name="angle-right" className="separator" />*/
    return (
        <div className="breadcrumb">
            {props.isMobileOrTablet && !tableSizeCheck() ? (
                <>
                    <FlatTreeNav
                        currentLocation={window.location.pathname.slice(window.location.pathname.lastIndexOf('/'))}
                        show={showStructure}
                        sidePanelMode={true}
                        isMobileOrTablet={props.isMobileOrTablet}
                    />
                    <div
                        onClick={() => setShowStructure(!showStructure)}
                        ref={structureDropdown}
                        className="sitemap-button"
                    >
                        <img src={IconBars} alt="Menu" />
                    </div>
                </>
            ) : null}
            {props.paths.map((crumb, i) => {
                if (i === props.paths.length - 1 && props.paths.length > 1) {
                    className = `current ${className}`;
                    separator = null;
                }
                return (
                    <div key={i} onClick={onClick} className={className}>
                        <Link to={crumb.path}>
                            {crumb.name} {props.paths.length > 1 ? separator : ''}
                        </Link>
                    </div>
                );
            })}
            {props.subPaths.length > 0 ? (
                <span className="subpath-toggle" onClick={() => setShowSubLevel(!showSubLevel)}>
                    &nbsp; <img src={IconAngleRight} alt="Next..." /> ...
                    <div
                        ref={subLevelDropdown}
                        className="breadcrumb-subpaths"
                        style={{ display: showSubLevel ? 'flex' : ' none' }}
                    >
                        {props.subPaths.map((comp) => {
                            return (
                                <Link
                                    key={`subpath-${comp.id ?? comp.name}`}
                                    className="subpath"
                                    to={comp.path}
                                    onClick={onClick}
                                >
                                    {comp.name}
                                </Link>
                            );
                        })}
                    </div>
                </span>
            ) : null}
        </div>
    );
};

Breadcrumb.propTypes = {
    isMobileOrTablet: PropTypes.bool.isRequired,
    toggleSiteMap: PropTypes.func,
    toggleTable: PropTypes.func.isRequired,
    showSitemap: PropTypes.bool.isRequired,
    subPaths: PropTypes.array,
    paths: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Breadcrumb;
