import React from 'react';
import './GroupableSelect.scss';
import CreatableSelect from 'react-select/creatable';
import SimpleSpinner from '../Spinner/SimpleSpinner';

const customStyles = {
    option: (_base) => ({
        color: 'black',
    }),
};
const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#000000',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span style={{ color: data.color, fontWeight: 700, fontSize: 16 }}>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

//usage: const grouped = groupByFunc(pets, pet => pet.type);
const groupByFunc = (list, keyGetter, defaultGroup = 'No group') => {
    const map = new Map();
    list.forEach((item) => {
        let myKey = keyGetter(item);
        if (myKey == null || typeof myKey === 'undefined') myKey = defaultGroup;
        const collection = map.get(myKey);
        if (!collection) {
            map.set(myKey, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
};
const createReactSelectOptions = (options, labelFunc, valueFunc) => {
    try {
        return options.map((o) => {
            return {
                label: labelFunc(o),
                value: valueFunc(o),
                original: o,
            };
        });
    } catch (err) {
        console.log(`Error evaluating select options`, options);
    }
    return [];
};

/*
  dataPromise: A promise that returns data
  onChange: Called when the selected value changes
  required: true/false indicating that this control must have a value set
  labelFunc: A function that returns the displayValue of an item
  valueFunc: A function that returns the value of an item
  groupBy: A function called over the data in the dataPromise used when creating groups
  defaultGroup: The group to use if the groupBy function returns null
  createFunc: The function to call when trying to create new items.
*/
function GroupableSelect({
    data,
    onChange,
    required = false,
    isFetching = false,
    labelFunc,
    valueFunc,
    groupBy,
    defaultGroup,
    // eslint-disable-next-line no-unused-vars
    createFunc,
    selectedValue,
}) {
    const [datainternal, setData] = React.useState(data);
    //const [dataPromiseInternal, setDataPromise] = React.useState(dataPromise)
    const [fetching, setFetching] = React.useState(isFetching);
    const [groupedData, setGroupedData] = React.useState([]);
    const [selectedObject, setSelectedObject] = React.useState();

    React.useEffect(() => {
        //console.log("setting data");
        setData(data);
    }, [data]);
    React.useEffect(() => {
        setFetching(isFetching);
    }, [isFetching]);

    React.useEffect(() => {
        //find selected object based on selected value
        if (!selectedValue || !groupedData) return;
        let selObj;
        groupedData.forEach((grp) => {
            if (selObj) return;
            selObj = grp.options.find((opt) => {
                return opt.value === selectedValue;
            });
        });
        setSelectedObject(selObj);
    }, [groupedData, selectedValue]);

    React.useEffect(() => {
        //sort.. todo: Use provided sort function
        if (!groupedData) return;
        groupedData.sort((optGrpA, optGrpB) => {
            if (optGrpA.label === defaultGroup) return -1;
            return optGrpA.label.localeCompare(optGrpB.label);
        });
    }, [defaultGroup, groupedData]);

    React.useEffect(() => {
        if (fetching) return;
        //setFetching(true);
        //let datainternal = await dataPromiseInternal;

        //setData(data);

        if (!datainternal || datainternal.length === 0) return;
        const groupedOptions = [];
        const grouped = groupByFunc(datainternal, groupBy, defaultGroup);

        for (let [key, grpOptions] of grouped.entries()) {
            //console.log(key + " = ", grpOptions)
            //todo: parameterizable styling to color
            groupedOptions.push({
                label: key,
                color: '#89B374',
                options: createReactSelectOptions(grpOptions, labelFunc, valueFunc),
            });
            //{
            //       label: "Real time",
            //       color: "#89B374",
            //       type: "rt",
            //       options: createReactSelectOptions(props.options.filter(o => !o.isEvent && !o.historic && !o.isTrace), "rt", props.widget.supportedTypes)
            //     }
            setGroupedData(groupedOptions);
        }
        //setFetching(false);
    }, [datainternal, defaultGroup, fetching, groupBy, labelFunc, valueFunc]);

    return (
        <div>
            <CreatableSelect
                style={customStyles}
                styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (styles) => ({
                        ...styles,
                    }),
                }}
                menuPortalTarget={document.body}
                isClearable
                options={groupedData}
                formatGroupLabel={formatGroupLabel}
                value={selectedObject}
                onChange={(e) => {
                    setSelectedObject(e);
                    if (onChange) onChange(e);
                    else console.log('Missing onChange');
                }}
                classNamePrefix={'optimar'}
                required={required}
                formatCreateLabel={() => undefined}
            />
            {fetching ? (
                <div className="loadinganim">
                    <SimpleSpinner size={'2x'}></SimpleSpinner>
                </div>
            ) : null}
        </div>
    );
}

export default GroupableSelect;
