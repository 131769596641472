import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import ItemDropdown from '../ItemDropdown/ItemDropdown';

class AdminPlantSubdevicesItemSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            isConnecting: false,
            thisComponentOnly: false,
        };

        this.createSubmit = this.createSubmit.bind(this);
        this.checkChange = this.checkChange.bind(this);
    }

    componentDidMount() {
        if (this.props.edgeDevice?.thisComponentOnly) {
            this.setState({
                thisComponentOnly: true,
            });
        }
    }

    async createSubmit(event) {
        event.preventDefault();
        this.setState({
            isConnecting: true,
        });

        let errorMsg = 'Device not created';
        try {
            if (this.state.selectedItem != null && this.props.edgeDevice != null) {
                const response = await api.post(
                    process.env.REACT_APP_API +
                        '/api/admin/edgedevice/' +
                        this.props.edgeDevice.deviceId +
                        '/' +
                        this.state.selectedItem.id +
                        '/' +
                        (this.props.edgeDevice.itemId ? this.props.edgeDevice.itemId : this.state.selectedItem.id) +
                        '?thisComponentOnly=' +
                        !!this.state.thisComponentOnly,
                    {}
                );
                //var response = {'status':200}
                if (response.status === 200 || response.status === 204) {
                    NotificationManager.success(
                        'Device successfully created. Updating IoT device',
                        'Device created',
                        5000
                    );
                } else {
                    throw new Error('Device was not successfully added: ' + response.status);
                }
                const response2 = await api.post(
                    `${process.env.REACT_APP_API}/api/admin/iotdevice/updatedeviceidmappings?plantid=${this.props.plant.id}`,
                    {}
                );
                if (response2.status === 200 || response2.status === 204) {
                    NotificationManager.success(
                        'Successfully updated IoT device configuration',
                        'Device updated',
                        5000
                    );
                } else {
                    errorMsg =
                        'Device was successfully added, but we were unable to update the IoT device configuration';
                    throw new Error('Device was not successfully added: ' + response.status);
                }
                this.props.addedCallback();
            }
        } catch (err) {
            console.error(err);
            NotificationManager.error(errorMsg, 'Error adding device', 5000);
        } finally {
            this.setState({
                isConnecting: false,
            });
        }
    }

    selectItem(item) {
        console.log('Selected item', item);
        this.setState({
            selectedItem: item,
        });
    }

    checkChange(event) {
        console.log(event.target);
        this.setState({
            thisComponentOnly: event.target.checked,
        });
    }

    // ReactDOM.createPortal(
    //  },{domNode})
    render() {
        if (!this.props) return;
        console.log('props', this.props);

        let subPath = '';
        if (this.props.edgeDevice?.value) {
            subPath = this.props.edgeDevice.value.substring(
                this.props.edgeDevice.value.indexOf(this.props.plant.topicKey) + this.props.plant.topicKey.length
            );
            console.log(subPath);
        }
        const connectLabel = this.state.isConnecting ? 'Connecting...' : 'Connect';
        return (
            <div>
                <div className="admin-plant-items-add-edit-header">
                    <span>Connect edge device with item</span>
                </div>
                <form onSubmit={this.createSubmit} className="admin-plant-items-add-edit-form">
                    <ItemDropdown
                        modalRef={this.props.modalRef}
                        currentItem={this.props.edgeDevice?.itemId}
                        plantId={this.props.plant.id}
                        selectItem={(item) => this.selectItem(item)}
                    />
                    {this.state.selectedItem
                        ? this.props.plant.topicKey +
                          this.state.selectedItem.topic.substring(this.state.selectedItem.topic.lastIndexOf('/')) +
                          (this.state.thisComponentOnly ? '' : subPath.substring(subPath.lastIndexOf('/')))
                        : ''}
                    <br />
                    <label htmlFor="this-only">This child component only</label>
                    <input type="checkbox" checked={this.state.thisComponentOnly} onChange={this.checkChange} />
                    <div className="btn-row btn-row-grow">
                        <button
                            className="btn btn-yellow btn-grow"
                            disabled={this.state.isConnecting}
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.closeModal();
                            }}
                        >
                            Cancel
                        </button>
                        <button className="btn btn-grow btn-blue" disabled={this.state.isConnecting} value="Submit">
                            {connectLabel}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentAdminPlant,
});

export default connect(mapStateToProps)(AdminPlantSubdevicesItemSelect);
