import React from 'react';
import './AdminArticles.scss';
import AdminArticleAddEdit from '../../components/common/AdminArticleAddEdit/AdminArticleAddEdit';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import Modal from 'react-modal';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../utility/api';
import { noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '12vw',
        width: '600px',
    },
};
class AdminArticles extends React.Component {
    constructor(props) {
        super(props);
        const rowContext = [
            {
                content: <span>Edit article</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete article</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];
        this.state = {
            articles: [],
            visibleColumns: [
                { Header: 'Name', accessor: 'name', filterable: true },
                { Header: 'Brand', accessor: 'brand', filterable: true },
                { Header: 'Category', accessor: 'category', filterable: true },
                { Header: 'Description', accessor: 'description', filterable: true },
                {
                    Header: 'Modified',
                    accessor: 'modifiedOn',
                    Cell: (props) => <span>{moment(props.value).format('DD.MM.YYYY HH:mm')}</span>,
                    filterable: true,
                },
                {
                    Header: () => null,
                    id: 'rowcontext',
                    Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
                },
            ],
            modalIsOpen: false,
            editMode: false,
            editArticle: null,
        };

        this.fetchArticles = this.fetchArticles.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        this.fetchArticles();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.articles) {
            this.fetchArticles();
        }
    }

    fetchArticles() {
        if (this.props.user != null) {
            api.get(process.env.REACT_APP_API + '/api/article')
                .then((res) => {
                    res.data.sort((a, b) => a.name.localeCompare(b.name));

                    if (this.mounted) {
                        this.setState({
                            articles: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    editArticle = (itemFromChild) => {
        this.setState({
            editArticle: itemFromChild,
        });
    };

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete article</h1>
                        <p>
                            You are about to delete the article and any article documents
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.title}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteArticle(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteArticle = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/article/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Article deleted', 'Article successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete article', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchArticles();
                })
                .catch(noop);
        }
    };

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                editType: mode,
            },
            function () {
                this.fetchArticles();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchArticles();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]?.toLowerCase()).includes(filter.value?.toLowerCase()) : true;
    }

    render() {
        const articles = this.state.articles;
        const visibleColumns = this.state.visibleColumns;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{articles ? articles.length : 0} Articles</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New article</span>{' '}
                        </span>
                    </span>
                </div>
                <GenericTable data={articles} columns={visibleColumns} localPaging={true} enableGlobalFilter={true} />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminArticleAddEdit
                        allArticles={this.state.articles}
                        editArticle={this.state.editItem}
                        editedCallback={this.closeModal}
                        cancelCallback={this.closeModal}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminArticles);
