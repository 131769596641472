import React from 'react';
import * as moment from 'moment';

import UploadUploading from '../../../images/list/Upload_Connected-Uploading.svg';
import UploadError from '../../../images/list/Upload_Error.svg';
import UploadIdle from '../../../images/list/Upload_Idle.svg';
import UploadNA from '../../../images/list/Upload_Not-Available.svg';

const UploadStatus = (plant) => {
    const timeDiff = moment().valueOf() - moment.utc(plant.lastSampleDate).local().valueOf();
    let image = UploadNA;
    let text = 'Not Available';

    if (timeDiff <= 60 * 60 * 1000) {
        // 1 hour
        image = UploadUploading;
        text = 'Uploading';
    } else if (timeDiff <= 12 * 60 * 60 * 1000) {
        image = UploadIdle;
        text = 'Idle / Standby';
    } else if (!plant.lastSampleDate) {
        image = UploadError;
        text = 'Error Uploading';
    }

    return (
        <span className="cell-image-text">
            <img alt="Uploading" style={{ marginRight: '0.5em' }} src={image} />
            <span className={plant.lastSampleDate ? '' : 'cell-text-grey'}>
                {text} <br />
                {plant.lastSampleDate ? (
                    <span className="cell-metadata">{moment.utc(plant.lastSampleDate).local().fromNow()}</span>
                ) : (
                    ''
                )}
            </span>
        </span>
    );
};

export default UploadStatus;
