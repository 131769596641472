import { matchSorter } from 'match-sorter';
import React from 'react';
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { ReactComponent as FilterIcon } from '../../../../images/filter.svg';
import { DefaultColumnFilter } from '../../../../components/common/GenericTable/Filters';
import './jobstable.scss';

function JobsTable(
    {
        data,
        columns,
        defaultPageSize = 10,
    },
    ref
) {
    const [isFiltering, setIsFiltering] = React.useState([]);

    const filterTypes = React.useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                                .toLowerCase()
                                .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    React.useEffect(() => {
        setPageSize(defaultPageSize);
    }, []);
    
    const localPaging = true;

    const tableConfig = {
        columns,
        data: data,
        defaultColumn,
        filterTypes,
        getSubRows: (row) => row.subRows,
        initialState: {
            pageIndex: 0,
            hiddenColumns: columns.filter((column) => column.show === false).map((column) => column.id),
            filters: [],
            sortBy: [],
        },
        manualPagination: !localPaging,
        autoResetPage: false,
        manualFilters: !localPaging,
        manualSortBy: !localPaging,
        autoResetExpanded : false,
        autoResetHiddenColumns : false,
        autoResetFilters : false,
        autoResetGroupBy : false,
        autoResetSelectedRows : false,
        autoResetRowState : false,
        autoResetSortBy : false,
        autoResetGlobalFilter : false,
    };

    if (localPaging) {
        delete tableConfig.pageCount;
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setGlobalFilter,
        setPageSize,
        toggleAllRowsExpanded,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable(tableConfig, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination);

    React.useImperativeHandle(
        ref,
        () => ({
            closeAllExpanded() {
                toggleAllRowsExpanded(false);
            },
        }),
        [toggleAllRowsExpanded]
    );

    const getSubRowsIds = React.useCallback((subRows) => {
        subRows.forEach((subRow) => {
            if (subRow.subRows) {
                getSubRowsIds(subRow.subRows);
            }
        });
    }, []);

    React.useEffect(() => {
        if (globalFilter) {
            page.forEach((row) => {
                row.toggleRowExpanded(row.id, true);
            });
        } else if (globalFilter === null) {
            page.forEach((row) => {
                if (row.isExpanded) {
                    row.toggleRowExpanded();
                }
            });
            setGlobalFilter(undefined);
        }
    }, [globalFilter, getSubRowsIds, page, setGlobalFilter]);

    const toggleColumnFilter = (columnId) => {
        if (isFiltering.includes(columnId)) {
            const tmp = [...isFiltering];
            tmp.splice(tmp.indexOf(columnId), 1);
            setIsFiltering(tmp);
            return;
        }
        setIsFiltering([...isFiltering, columnId]);
    };

    React.useEffect(() => {
        if (pageIndex + 1 > pageCount) {
            gotoPage(0);
        }
    }, [pageIndex, pageCount, gotoPage]);

    return (
        <>
            <table {...getTableProps} className="generictable">
                <thead className="gt-thead">
                    {headerGroups.map((headerGroup) => (
                                <tr key={`hdrtr${headerGroup.id}`} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            key={`hdrth${column.id}`}
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                        >
                                            <span>
                                                {column.render('Header')}
                                                {column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}
                                                {column.canFilter ? (
                                                    <FilterIcon
                                                        className="gt-filter-icon"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleColumnFilter(column.id);
                                                        }}
                                                    />
                                                ) : null}
                                            </span>
                                            <div>
                                                {column.canFilter && isFiltering.includes(column.id)
                                                    ? column.render('Filter')
                                                    : null}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                </thead>

                <tbody {...getTableBodyProps()} className="gt-tbody">
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={`fragment-${row.id}`}>
                                <tr
                                    {...row.getRowProps()}
                                    className={'gt-tr'}
                                >
                                    {row.cells.map((cell, idx) => {                                  
                                        return (
                                            <td
                                                key={`tbodtr${cell.column.id}/${cell.row.id}`}
                                                style={{
                                                    width: idx === 0 ? 50 : cell.column.width,
                                                    paddingLeft:
                                                        idx === 0
                                                        ? row.original.indentLevel * 23 + 'px'
                                                        : idx === 2
                                                        ? (row.original.indentLevel - 1) * 20 + 'px'
                                                        : 0,
                                                }}
                                                {...cell.getCellProps()}
                                                className="gt-td"
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
            <div className={'gt-pagination ' + (pageCount > 1 ? '' : 'center')}>
                    {pageCount > 1 ? (
                        <div className="gt-btn-group">
                            <button
                                className="btn btn-blue"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {' '}
                                &lt;&lt;{' '}
                            </button>
                            <button
                                className="btn btn-blue"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {' '}
                                &lt;{' '}
                            </button>
                        </div>
                    ) : null}
                    <div className={'gt-page-settings'}>
                        <div className="gt-pagenum">
                            Page
                            <input
                                value={pageIndex + 1}
                                type="number"
                                className="gt-page-goto"
                                onChange={(e) => gotoPage(e.target.value ? Number(e.target.value) - 1 : 0)}
                            />
                            of {pageCount}
                        </div>
                        <div className="gt-show-count">
                            <div>Show</div>
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {pageCount > 1 ? (
                        <div className="gt-btn-group">
                            <button
                                className="btn btn-blue"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {' '}
                                &gt;{' '}
                            </button>
                            <button
                                className="btn btn-blue"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {' '}
                                &gt;&gt;{' '}
                            </button>
                        </div>
                    ) : null}
                </div>
        </>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

const ForwaredJobsTable = React.forwardRef(JobsTable);

export default ForwaredJobsTable;