import React from 'react';

import { ContentEditor } from './utils/ValueUtils';
import { ReactComponent as DeleteIcon } from '../../../images/new/optimar_cross.svg';

const item_type_name = 'Comment List';
const configurable = true;

export default () => {
    return {
        name: item_type_name,
        configurable: configurable,
        create_new: create_new,
        get_editor,
    };
};

function get_editor(data, onInputChange, props) {
    const onValueChange = (index, name, value) => {
        data.content[index][name] = value;
        onInputChange('content', data.content);
    };

    const addNewRow = () => {
        data.content.push({
            content: '{1}',
            values: {
                1: {
                    type: 'meta',
                    values: 'comment',
                },
            },
        });
        onInputChange('content', data.content);
    };

    const removeRow = (index) => {
        data.content.splice(index, 1);
        console.log(data.content);
        onInputChange('content', data.content);
    };

    const list_editor = data.content.map((elem, index) => {
        return (
            <fieldset key={'cl_item_' + index} className="values-fieldset">
                <legend>Row {index + 1}</legend>
                {Object.keys(data.content).length > 1 ? (
                    <DeleteIcon
                        style={{ float: 'right' }}
                        onClick={() => {
                            removeRow(index);
                        }}
                        className="delete-icon"
                    />
                ) : null}
                <ContentEditor
                    onInputChange={(name, value) => onValueChange(index, name, value)}
                    data={elem}
                    usage="commentlist"
                    job_type={props.job_type}
                    child_jobs={props.child_jobs}
                    datapoints={props.datapoints}
                />
            </fieldset>
        );
    });

    return (
        <>
            <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                    autoComplete="off"
                    type="text"
                    className={'input-control'}
                    name="title"
                    value={data.title}
                    required={true}
                    onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="header">List header</label>
                <input
                    autoComplete="off"
                    type="text"
                    className={'input-control'}
                    name="header"
                    value={data.header}
                    required={true}
                    onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="content">Rows</label>
                {list_editor}
                <button onClick={addNewRow} className="btn btn-blue">
                    Add new row
                </button>
            </div>
        </>
    );
}

function create_new() {
    return {
        type: 'commentlist',
        title: 'Comments',
        header: 'Comment',
        content: [],
    };
}
