import React from 'react';
import { ConfirmationAlert } from './ConfirmationAlert';

/**
 * @type {React.FC<{
 *     onCancel: React.MouseEventHandler<HTMLButtonElement>;
 *     onConfirm: React.MouseEventHandler<HTMLButtonElement>;
 * }>}
 */
export const ConfirmSaveItemTypeDashboardAlert = (props) => {
    return (
        <ConfirmationAlert
            title="Save as itemtype dashboard"
            description="This will set this dashboard as default for all items of this itemtype"
            {...props}
        />
    );
};
