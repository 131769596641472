import moment from 'moment';
import React from 'react';

export function getHeader(data, {}) {
    const header = {
        left: [
            <h3 className="gt-header-count" key="gth-0">
                Total: {data.length}
            </h3>,
        ],
        right: [],
    };
    return header;
}

export function getColumns(_props, _data, {}) {
    const columns = [
        {
            Header: 'Date',
            accessor: 'date',
            Cell: (row) => <div>{row.value ? moment.utc(row.value).local().format('DD.MM.YYYY HH:mm') : null}</div>,
        },
        {
            Header: 'Value',
            accessor: 'value',
            Cell: (row) => <div>{row.value}</div>,
        },
        // {
        //     Header: 'Revisions',
        //     accessor: 'revisionCount',
        //     Cell: row => <div>{row.value || 0}</div>
        // },
        // {
        //     Header: () => null,
        //     id: 'rowcontext',
        //     Cell: ({ row }) => (<RowContextMenu options={rowContext} row={row} />)
        // }
    ];

    return columns;
}
