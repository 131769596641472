import React from 'react';
import './AddKpiData.scss';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import * as Datetime from 'react-datetime';
import 'moment/locale/en-gb';

class AddKpiData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: [],
            success: null,
            value: '',
            selectedDate: new Date(),
            dType: props.configuration?.dType || 'text',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.saveKpiData = this.saveKpiData.bind(this);
    }

    saveKpiData(e) {
        e.preventDefault();

        const payload = {
            plantId: this.props.plantId,
            topic: this.props.components[0],
            toDevice: this.props.configuration?.toDevice,
            data: {
                name: this.props.payload.payloadName,
                value: this.state.value,
                dType: this.state.dType,
                t_stamp: this.state.selectedDate,
            },
        };

        api.post('/api/iothub/sendmessagetohub', payload)
            .then((_data) => {
                this.props.closeModal();
                NotificationManager.success('Data updated successfully', 'Data updated', 5000);
            })
            .catch((err) => {
                console.error('ERROR: ', err);
                NotificationManager.error('Data not updated successfully', 'Error updating data', 5000);
            });
    }

    handleChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') value = target.checked;

        this.setState({ value, name });
    }

    handleDateSelect(date, isInput) {
        if (isInput) {
            this.setState({
                value: date,
                showDatePicker: false,
                dType: 'datetime',
                inputDate: date,
            });
            return;
        }
        this.setState({
            selectedDate: date,
            showDatePicker: false,
        });
    }

    getInputType() {
        switch (this.state.dType) {
            case 'number':
            case 'text':
                return (
                    <input
                        autoComplete="off"
                        type={this.state.dType}
                        className={'input-control'}
                        name={this.state.dType}
                        onChange={this.handleChange}
                        required={true}
                    />
                );
            case 'bool':
                return (
                    <input
                        autoComplete="off"
                        type="checkbox"
                        className={'input-control'}
                        name={this.state.dType}
                        onChange={this.handleChange}
                        defaultChecked={false}
                    />
                );
            case 'datetime':
                return (
                    <Datetime
                        value={this.state.inputDate}
                        dateFormat="DD.MM.YYYY"
                        timeFormat="HH:mm"
                        onChange={(val) => this.handleDateSelect(val, true)}
                        className={'date-input-control'}
                        required={true}
                    />
                );
        }
    }

    render() {
        return (
            <div className="form-container" onClick={(e) => e.stopPropagation()}>
                <div className="form-header">
                    <h2>{this.props.title}</h2>
                </div>

                {this.state.showFormSuccess ? this._renderSuccessMessage() : null}
                <form onSubmit={this.saveKpiData}>
                    <div className="form-group">
                        <label htmlFor={this.state.dType}>Value</label>
                        {this.getInputType()}
                    </div>

                    <div className="form-group">
                        <label htmlFor="date">Date</label>
                        <Datetime
                            inputProps={{ readOnly: true }}
                            locale="en-gb"
                            value={this.state.selectedDate}
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            onChange={this.handleDateSelect}
                            className={'date-input-control'}
                            required={true}
                        />
                    </div>

                    <div className="btn-row-grow">
                        <button
                            className="btn btn-yellow btn-grow"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.closeModal();
                            }}
                        >
                            Cancel
                        </button>
                        <button className="btn btn-blue btn-grow" value="Submit">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddKpiData);
