import React from 'react';
import './NotificationButton.css';
//import AlarmIcon from '../../../images/icons/optimar_bell_blue.svg';

import { ReactComponent as AlarmIcon } from '../../../images/icons/optimar_bell_blue.svg';

const NotificationButton = (props) => {
    return (
        <div style={{ cursor: 'pointer' }} onClick={props.onClick} className={`notificationbutton no-pimage`}>
            {/* <img src={AlarmIcon} alt="Alarms" /> */}
            <AlarmIcon alt="Alarms" />
            {props.alertCount > 0 ? <div className="alert-count">{props.alertCount}</div> : null}
        </div>
    );
};

export default NotificationButton;
