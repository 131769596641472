import React from 'react';
import Dropzone from 'react-dropzone';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import 'moment/locale/en-gb';
import format from 'date-fns/format';
import Select from 'react-select';
import api from '../../../utility/api';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './FileUpload.css';
import { emptyGuid } from '../../../utility/guidFunctions';
import { noop } from 'lodash';

const overlayStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '2.5em 0',
    background: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    color: '#fff',
};

const dropzoneStyle = {
    marginTop: '20px',
    border: '1px dashed #919191',
    position: 'relative',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    position: 'relative',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    margin: '0 auto',
};

const accept = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'image/gif',
    'application/pdf',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
];

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            selectedDate: new Date(),
            selectedOption: null,
            selectedArticle: null,
            categories: [],
            itemName: null,
            itemId: null,
            items: [],
            list: [],
            data: [],
            tags: [],
            expanded: [],
            filterString: '',
            link: props.editItem?.link,
            linkName: props.editItem?.filename,
            plantId: props.plantId || props.editItem?.plantId,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.handleArticleSelect = this.handleArticleSelect.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.uploading = false;
        this.fetchItem = this.fetchItem.bind(this);
        this.itemChange = this.itemChange.bind(this);
        this.getItemName = this.getItemName.bind(this);
        this.editDocument = this.editDocument.bind(this);
    }

    componentDidMount() {
        this.getStructure(this.props.user, { id: this.props.plantId });
        this.fetchTags();

        if (this.props.editItem) {
            this.fetchSelectedTags();
            this.setState({ selectedDate: this.props.editItem.date });
            this.fetchItem(this.props.editItem.itemId);
        } else {
            this.fetchItem(this.props.itemId);
        }
    }

    fetchSelectedTags() {
        this.fetching = true;
        api.get('/api/tags/document/' + this.props.editItem.id)
            .then((res) => {
                const tags = res.data.map(function (d) {
                    return {
                        value: d.id,
                        label: d.title,
                    };
                });
                this.setState({ selectedOption: tags });
                this.fetching = false;
            })
            .catch((err) => {
                console.error('ERROR: ', err);
                this.fetching = false;
            });
    }

    // fetchSelectedCategories() {
    //     api.get(process.env.REACT_APP_API + "/api/documentcategory/document/" + this.props.editItem.id)
    //         .then(res => {
    //             let categories = res.data.map(cat => {
    //                 return { value: cat.id, label: cat.name }
    //             })
    //             this.setState({ selectedOption: categories })
    //         })
    //         .catch(err => {
    //             console.error("ERROR: ", err);
    //         });
    // }

    handleSubmit(e) {
        e.preventDefault();
        if (this.props.editItem) {
            this.editDocument();
        } else {
            if (this.props.type === 'file') {
                this.state.files.forEach((file) => {
                    this.fileUpload(file);
                });
            } else {
                this.fileUpload();
            }
        }
    }

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    onDrop(files) {
        if (files.length < 1) {
            NotificationManager.error('File type not supported', 'File error', 5000);
            return;
        }
        const prevFiles = this.state.files;
        prevFiles.push(...files);
        this.setState({
            files: prevFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
        });
    }

    fetchTags() {
        this.fetching = true;
        api.get(process.env.REACT_APP_API + '/api/tags?plantId=' + this.state.plantId + '&mode=Document')
            .then((res) => {
                const tags = res.data.map(function (d) {
                    return {
                        value: d.id,
                        label: d.title,
                    };
                });
                this.fetching = false;
                this.setState({ tags: tags });
                this.fetchingTags = false;
            })
            .catch((err) => {
                console.error('ERROR: ', err);
                this.fetching = false;
            });
    }

    fetchCategories() {
        api.get(process.env.REACT_APP_API + '/api/documentcategory')
            .then((res) => {
                const categories = res.data.map((cat) => {
                    return { value: cat.id, label: cat.name };
                });
                this.setState({ categories: categories });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    editDocument() {
        console.log('EDIT', this.state, this.state.itemId, this.props.editItem.itemId);
        const jsonData = {
            date: moment(this.state.selectedDate).utc().format(),
            itemId: this.state.itemId || this.props.editItem.itemId,
            tags: this.state.selectedOption,
        };

        if (this.props.type === 'link') {
            jsonData.link = this.state.link;
            jsonData.filename = this.state.linkName;
        }

        api.put(process.env.REACT_APP_API + '/api/documents/' + this.props.editItem.id, jsonData)
            .then((response) => {
                this.uploading = false;
                if (response.status === 200) {
                    // if (this.state.selectedOption) {
                    //     this.setCategories(response.data.id);
                    // }

                    NotificationManager.success('File edited', 'File edited successfully', 5000);
                    this.props.fetchDocs();
                    this.props.closeModal();
                } else {
                    NotificationManager.error('Error', 'Failed to edit file', 5000);
                    throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    fileUpload(file) {
        if (this.uploading) {
            return;
        }
        this.uploading = true;
        /*file.plantId = this.props.plant.id;
        file.createdAt = moment().utc().format();
        file.filename = file.name;*/
        const data = new FormData();
        if (this.props.type === 'file') {
            data.append('File', file);
            data.append('Filename', file.name);
        } else {
            data.append('Link', this.state.link);
            data.append('Filename', this.state.linkName);
        }

        data.append('PlantId', this.state.plantId);
        data.append('ItemId', this.state.itemId || this.props.itemId);
        data.append('JobId', this.state.jobId || this.props.jobId);
        data.append('CreatedAt', moment.utc().format());
        data.append('Date', moment(this.state.selectedDate).utc().format());
        data.append('Tags', this.state.selectedOption);
        api.post(process.env.REACT_APP_API + '/api/documents', data)
            .then((response) => {
                console.log(response);
                this.uploading = false;
                if (response.status === 200) {
                    // if (this.state.selectedOption) {
                    //     this.setCategories(response.data.id);
                    // }

                    if (this.props.type !== 'file') {
                        document.getElementById('link').value = '';
                        document.getElementById('linkname').value = '';
                    }

                    this.setState({
                        files: [],
                        link: '',
                        linkName: '',
                    });
                    this.props.fetchDocs();
                    NotificationManager.success('File uploaded', 'File uploaded successfully', 5000);
                    this.props.closeModal();
                } else {
                    NotificationManager.error('Error', 'Failed to upload file', 5000);
                    throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    // setCategories(id) {
    //     let url = process.env.REACT_APP_API + '/api/documentcategory/setcategory?docId=' + id;
    //     this.state.selectedOption.forEach((opt) => {
    //         url += '&catId=' + opt.value
    //     })
    //     api.post(url, {})
    //         .then((response) => {
    //             this.setState({ selectedOption: [] })
    //         }).catch(function (error) {
    //         });
    // }

    componentWillUnmount() {
        this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
    }

    removeFile(index) {
        const files = this.state.files;
        files.splice(index, 1);
        this.setState({ files });
    }

    handleDateSelect(date) {
        this.setState({
            selectedDate: date,
            showDatePicker: false,
        });
    }

    handleArticleSelect = (selectedArticle) => {
        this.setState({ selectedArticle });
    };

    handleMultiSelectChange = (selectedOption) => {
        this.setState({ selectedOption });
    };

    itemChange(itemName) {
        this.setState({ itemName });
    }

    fetchItem(itemId) {
        if (itemId) {
            api.get('/api/items/' + itemId)
                .then((res) => {
                    this.setState({ itemName: res.data.name });
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    getStructure = async (_user, _plant) => {
        const plantId = this.state.plantId; // plant?.id;
        // if(!plantId){
        //     //could occur when editing from account doc list
        //     plantId = this.props.editItem?.plantId;
        // }
        //plantid could theoretically be null at this point because we're using nullpropagation.. but crash and fix in those cases.
        const apiUrl = process.env.REACT_APP_API + '/api/plants/' + plantId + '/items/structure';

        try {
            const res = await api.get(apiUrl);
            //.then(res => {
            this.fetchingStructure = false;
            if (res.status !== 204) {
                this.unflatten(res.data);
                this.setState({
                    flatData: res.data,
                });
            } else if (res.status === 204) {
                this.unflatten();
            }
        } catch (err) {
            console.error(err);
        }
    };

    unflatten(data) {
        if (data) {
            const ID_KEY = 'id';
            const PARENT_KEY = 'parentId';
            const CHILDREN_KEY = 'children';

            const tree = [],
                childrenOf = {};
            let item, id, parentId;

            for (let i = 0, length = data.length; i < length; i++) {
                item = data[i];
                id = item[ID_KEY];
                parentId = item[PARENT_KEY] || emptyGuid(); // "00000000-0000-0000-0000-000000000000";
                // every item may have children
                childrenOf[id] = childrenOf[id] || [];
                // init its children
                item[CHILDREN_KEY] = childrenOf[id];
                item.toggled = true;
                if (parentId !== emptyGuid()) {
                    // init its parent's children object
                    childrenOf[parentId] = childrenOf[parentId] || [];
                    // push it into its parent's children object
                    childrenOf[parentId].push(item);
                } else {
                    tree.push(item);
                }
            }

            this.setState(
                {
                    data: tree,
                    items: data,
                },
                () => this.generateList()
            );
        }
    }

    findNode(node) {
        const fString = this.state.filterString.toLowerCase();
        if (
            node.name.toLowerCase().includes(fString) ||
            (node.orderPos && node.orderPos.toLowerCase().includes(fString)) ||
            (node.productSheet && node.productSheet.toLowerCase().includes(fString)) ||
            node.children.find((child) => this.findNode(child))
        ) {
            if (!this.state.expanded.includes(node.parentId)) {
                const tmp = this.state.expanded;
                tmp.push(node.parentId);
                this.setState({
                    expanded: tmp,
                });
            }
            return true;
        }
    }

    generateList() {
        const list = [];

        const sortedNodes = this.state.data.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
        );

        sortedNodes.map((node) => {
            return this.generateListSection(node, list, 0);
        });

        this.setState({
            list: list,
            generated: true,
        });
    }

    setItem(item) {
        if (item) {
            this.setState({
                itemId: item.id,
                itemName: item.name,
                filterString: '',
                showItemList: false,
            });
        }
    }

    generateListSection(node, list, level) {
        if (node.parentId === '00000000-0000-0000-0000-000000000000' && this.findNode(node)) {
            list.push(
                <span
                    key={node.id}
                    className="search-drodown-element"
                    onMouseDown={() => {
                        this.setItem(node);
                    }}
                >
                    {node.name}
                </span>
            );
        } else {
            const styles = {};
            if (level >= 1) {
                if (this.check()) {
                    styles.paddingLeft = level * 17 + 'px';
                } else {
                    styles.paddingLeft = level * 5 + 'px';
                }
            }

            if (this.state.filterString === '' || this.findNode(node)) {
                list.push(
                    <span
                        style={styles}
                        key={node.id}
                        className="search-drodown-element"
                        onMouseDown={() => {
                            this.setItem(node);
                        }}
                    >
                        {node.name}
                    </span>
                );
            }
        }

        if (node.children.length > 0) {
            ++level;

            const sortedChildren = node.children.sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
            );

            sortedChildren.map((n) => {
                return this.generateListSection(n, list, level);
            });
        }

        return;
    }

    getItemName() {
        const selected = this.state.items.find((item) => item.id === this.state.itemId);

        if (this.state.list.length > 0) {
            // List is open
            return this.escapapeItemName(this.state.itemName);
        } else if (selected) {
            return this.escapapeItemName(selected.name);
        }
        return '';
    }

    escapapeItemName(itemName) {
        if (itemName) {
            return itemName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }
        return '';
    }

    render() {
        const { files } = this.state;

        const inputProps = {
            required: true,
            readOnly: true,
        };

        const thumbs = files.map((file, i) => (
            <div className="file-preview" key={'preview-' + i}>
                <div style={thumb} key={file.name + '-' + i}>
                    <FontAwesome name="remove" className="file-remove" onClick={() => this.removeFile(i)} />

                    <div style={thumbInner}>
                        <a href={file.preview} rel="noopener noreferrer" target="_blank">
                            {file.type.includes('image') ? (
                                <img src={file.preview} style={img} alt="preview" />
                            ) : (
                                <FontAwesome name="file" size="3x" style={{ margin: '0 auto', color: '#919191' }} />
                            )}
                        </a>
                    </div>
                </div>
                <span>{file.name}</span>
            </div>
        ));

        let headerMsg = 'Upload File';
        if (this.props.editItem) {
            if (this.props.type === 'file') headerMsg = 'Edit File';
            if (this.props.type === 'link') headerMsg = 'Edit Link';
        } else {
            if (this.props.type === 'link') headerMsg = 'New Link';
        }

        return (
            <div
                className="form-container"
                onClick={(e) => (e.target?.id !== 'items-dropdown' ? this.setState({ showItemList: false }) : null)}
            >
                <div className="form-header">
                    <div className="table-header">{headerMsg}</div>
                </div>
                <form onSubmit={this.handleSubmit}>
                    {!this.props.editItem ? (
                        this.props.type === 'file' ? (
                            <Dropzone onDrop={this.onDrop.bind(this)} accept={accept}>
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div {...getRootProps()} style={dropzoneStyle}>
                                        Drop files here or click to upload
                                        <input {...getInputProps()} />
                                        {isDragActive && <div style={overlayStyle}></div>}
                                    </div>
                                )}
                            </Dropzone>
                        ) : null
                    ) : null}

                    {this.props.type === 'link' ? (
                        <>
                            <label htmlFor="name">Name:</label>
                            <input
                                autoComplete="off"
                                required
                                id="linkname"
                                name="name"
                                type="text"
                                className="input-control"
                                value={this.state.linkName}
                                onChange={(e) => this.setState({ linkName: e.target.value })}
                            />
                            <label htmlFor="link">URL Link:</label>
                            <input
                                autoComplete="off"
                                required
                                id="link"
                                name="link"
                                type="text"
                                className="input-control"
                                value={this.state.link}
                                onChange={(e) => this.setState({ link: e.target.value })}
                            />
                        </>
                    ) : null}
                    <aside style={thumbsContainer}>{thumbs}</aside>

                    <label htmlFor="date">Date:</label>
                    <Datetime
                        inputProps={inputProps}
                        locale="en-gb"
                        dateFormat="DD.MM.YYYY"
                        timeFormat="HH:mm"
                        value={
                            this.state.selectedDate
                                ? format(new Date(this.state.selectedDate), 'dd.MM.yyyy HH:mm')
                                : format(new Date(Date.now()), 'dd.MM.yyyy HH:mm')
                        }
                        onChange={this.handleDateSelect}
                        className={'date-input-control'}
                    />
                    {/* <div className="form-group">
                        <label htmlFor="tags">Keywords:</label>
                        <Select
                            name={"tags"}
                            value={this.state.selectedOption}
                            onChange={this.handleMultiSelectChange}
                            options={this.state.categories}
                            isMulti={true}
                            classNamePrefix={"optimar"}
                        />
                    </div> */}

                    <div className="form-group">
                        <label htmlFor="tags">Keywords</label>
                        <Select
                            name={'tags'}
                            value={this.state.selectedOption}
                            onChange={this.handleMultiSelectChange}
                            options={this.state.tags}
                            isMulti={true}
                            classNamePrefix={'optimar'}
                        />
                    </div>
                    <div className="form-group" style={{ position: 'relative' }}>
                        <label htmlFor="content">Component</label>
                        <div>
                            <input
                                id="items-dropdown"
                                onKeyPress={this.handleEnterPress}
                                autoComplete="off"
                                ref={'map-search-input'}
                                type="search"
                                value={this.state.itemName}
                                pattern={this.getItemName()}
                                placeholder="Search"
                                className="search-items"
                                title="Name does not match selected item"
                                onFocusCapture={() => this.setState({ showItemList: true })}
                                onChange={(e) => {
                                    this.itemChange(e.target.value);
                                    this.setState({ filterString: e.target.value }, this.generateList);
                                }}
                            />
                            <div
                                style={{ display: this.state.showItemList ? 'flex' : 'none' }}
                                className="search-items-dropdown"
                            >
                                <span style={{ paddingLeft: '10px', fontSize: '12px' }}>
                                    {this.state.list ? `Showing ${this.state.list.length} results` : ''}
                                </span>
                                {this.state.list
                                    ? this.state.list.map((m) => {
                                          return m;
                                      })
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="btn-row-grow">
                        <button className="btn btn-yellow btn-grow" onClick={() => this.props.closeModal()}>
                            Cancel
                        </button>
                        <button className="btn btn-blue btn-grow" value="Submit">
                            {this.props.editItem ? 'Save' : this.props.type === 'file' ? 'Upload' : 'Add'}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentPlant,
});

export default connect(mapStateToProps)(FileUpload);

export { accept };
