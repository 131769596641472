import React from 'react';
import Modal from 'react-modal';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';

import SectionDesigner from './SectionDesigner';
import NewSectionControl from './NewSectionControl';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        paddingTop: '40px',
        paddingRight: '40px',
        paddingBottom: '40px',
        paddingLeft: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

export default function DocumentDesigner(props) {
    const data = props.data;
    const [title, setTitle] = React.useState(props.title);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    // console.log("current data", data)
    // console.log("current props", props)
    // sections table context menu
    const sections_table_context = [
        {
            content: <span>Move section up</span>,
            action: (row_props) => {
                const index = row_props.row.index;

                if (row_props.row.isExpanded) {
                    row_props.toggleRowExpanded(index, false);
                }

                if (row_props.rows[index - 1].isExpanded) {
                    row_props.toggleRowExpanded(index - 1, false);
                }

                [data[index], data[index - 1]] = [data[index - 1], data[index]];

                props.onDataChange(data);
            },
            hideContext: (row) => {
                return row.row.index === 0;
            },
        },
        {
            content: <span>Move section down</span>,
            action: (row_props) => {
                const index = row_props.row.index;

                if (row_props.row.isExpanded) {
                    row_props.toggleRowExpanded(index, false);
                }

                if (row_props.rows[index + 1].isExpanded) {
                    row_props.toggleRowExpanded(index + 1, false);
                }

                [data[index], data[index + 1]] = [data[index + 1], data[index]];

                props.onDataChange(data);
            },
            hideContext: (row) => {
                return row.row.index === data.length - 1;
            },
        },
        {
            content: <span>Delete section</span>,
            action: (row_props) => {
                data.splice(row_props.row.index, 1);
                props.onDataChange(data);
            },
        },
    ];

    // sections table columns definition
    const sections_table_columns = [
        {
            Header: () => null,
            id: 'expander',
            Cell: ({ row }) => (
                <span className="row-expander-icon" {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? '-' : '+'}
                </span>
            ),
        },
        { Header: 'Title', accessor: 'title', disableSortBy: true, disableFilters: true },
        {
            Header: () => null,
            id: 'rowcontext',
            Cell: (row) => <RowContextMenu options={sections_table_context} row={row} />,
        },
    ];

    // callback when title changes
    const onTitleChange = (event) => {
        props.onTitleChange(event.currentTarget.value);
        setTitle(event.currentTarget.value);
    };

    // callback when a section changes
    const onReportSectionChange = (index, section) => {
        data[index] = Object.assign({}, section);
        props.onDataChange(data);
    };

    // function to add a new section
    const addNewSection = (sectionDetails) => {
        console.log('sections details on add', sectionDetails);
        data.push({
            sectionType: sectionDetails.type,
            jobTypeRef: sectionDetails.jobTypeRef,
            title: sectionDetails.title ? sectionDetails.title : 'New section',
            items: [],
        });
        props.onDataChange(data);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    // function that returns the section designer for a specific row
    const getSectionDesigner = (row) => {
        return (
            <SectionDesigner
                index={row.index}
                section={Object.assign({}, data[row.index])}
                onSectionChange={onReportSectionChange}
                datapoints={props.datapoints}
                child_jobs={props.child_jobs}
                job_type={props.job_type}
            />
        );
    };

    return (
        <>
            <div className="title-form">
                <label className="title">Report Title</label>
                <input
                    autoComplete="off"
                    type="text"
                    className={'input-control'}
                    name="title"
                    value={title}
                    onChange={onTitleChange}
                />
            </div>

            <div className="main-header">
                <span>
                    <h2>{data.length} Sections</h2>
                    <span className="main-header-action" onClick={() => openModal() /*addNewSection*/}>
                        <span className="list-expand-toggle">+ New section</span>
                    </span>
                </span>
            </div>
            <Modal isOpen={modalIsOpen} onRequestClose={() => closeModal()} style={customStyles} ariaHideApp={false}>
                <NewSectionControl
                    plantId={props.job_type?.plantId ? props.job_type?.plantId : props.plant_speficic}
                    onCloseCallback={() => closeModal()}
                    onSaveCallback={(sectionDetails) => {
                        addNewSection(sectionDetails);
                        closeModal();
                    }}
                ></NewSectionControl>
                {/* <button style={{position: 'absolute', bottom: '20px', right: '20px'}} onClick={this.closeModal} className="btn btn-yellow">Cancel</button> */}
            </Modal>

            <GenericTable
                data={data}
                columns={sections_table_columns}
                localPaging={true}
                expandedRowContent={getSectionDesigner}
            />
        </>
    );
}
