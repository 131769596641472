import { UserManager } from 'oidc-client';
import oidcSettings from '../oidcsettings';

let userManager;
let silentSigninPromise;

const reauthlisteners = {};

export const subscribeSilentReauthSuccess = (key, handler) => {
    if (reauthlisteners[key]) console.warn(`Subscriber with key ${key} already exists`);
    //throw new Error(`Subscriber with key ${key} already exists`);
    reauthlisteners[key] = handler;
};
export const unSubscribeSilentReauthSuccess = (key) => {
    reauthlisteners[key] = null;
};
const notifySilentReauthSuccessSubscribers = (user) => {
    console.log('Notifying subscribers that a user has been successfully reauthenticated');
    for (const [key, handler] of Object.entries(reauthlisteners)) {
        try {
            console.log(`Notifying ${key}`);
            handler(user);
        } catch (err) {
            console.log(`Error notifying ${key}`, err);
        }
    }
};

export const getSessionTokenKey = () => {
    return `oidc.user:${oidcSettings.authority}:${oidcSettings.client_id}`;
};

export const getUserManager = () => {
    if (!userManager) {
        userManager = new UserManager(oidcSettings);
    }
    return userManager;
};
export const getSession = () => {
    const key = getSessionTokenKey();
    let item = sessionStorage.getItem(key);
    if (!item) {
        console.log(`Missing session token at ${key}`);
        return null;
    }
    item = typeof item === 'string' ? JSON.parse(item) : item;
    return item;
};
export const getToken = () => {
    const item = getSession();
    //console.log("token",typeof(item),item.access_token);
    return item?.access_token;
};
export const tryReauthenticate = async () => {
    const userMgr = getUserManager();
    try {
        if (silentSigninPromise) {
            await silentSigninPromise;
            silentSigninPromise = null;
            return;
        }
        //clearOidcSessionToken(); // <-- this one seems to help when using signinRedirect.. but will definitely kill silent-renew..

        //window.location.hash = decodeURIComponent(window.location.hash);
        //const userMgr = getUserManager();
        //userMgr.signinRedirect();
        // silentSigninPromise = userMgr.signinSilentCallback();
        silentSigninPromise = userMgr.signinSilent();
        const signinResult = await silentSigninPromise;
        silentSigninPromise = null;
        //Returns the signed in user
        notifySilentReauthSuccessSubscribers(signinResult);
        return signinResult;
    } catch (err) {
        console.log('Silent signing failed', err, JSON.stringify(err));
        if (err.error && err.error === 'login_required') {
            // console.log("Login redirect required??");
            // userMgr.signinRedirect();
            await trySigninPromptOrLogout();
        }
        silentSigninPromise = null;
        return null;
    }
};
export const trySigninPromptOrLogout = async () => {
    let from = window.location.href;
    if (from.indexOf('loggedOut') >= 0) from = '/';
    const userManager = getUserManager();
    try {
        clearOidcSessionToken();
        await userManager.signinRedirect({
            prompt: 'login',
            state: { loggingInFrom: from },
        });
    } catch (err) {
        console.error('AuthProvider: Error signing in. Resetting user. Error = ', JSON.stringify(err));
        await userManager.removeUser();
    }
};
export const updateSessionAccessToken = (token) => {
    const key = getSessionTokenKey();
    const session = getSession();
    if (session?.access_token === token) {
        //console.log("Skipping access token update. They are the same");
        return;
    }
    //console.log("Updating access token",session.access_token,token);
    session.access_token = token;
    sessionStorage.setItem(key, JSON.stringify(session));
};

export const clearOidcSessionToken = () => {
    const key = getSessionTokenKey();
    sessionStorage.removeItem(key);
};
