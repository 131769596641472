import { filter, forEach, groupBy, maxBy, minBy } from 'lodash';
import moment from 'moment';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import api from '../../../utility/api';
import { getValueWithPrecisionOrDefault, toNumber } from '../../../utility/numberFunctions';
import { WidgetFormulaManager } from '../../../utility/widgetformulamanager';
import PeriodPicker from '../../common/PeriodPicker/PeriodPicker';
import Spinner from '../../common/Spinner/Spinner';
import '../widget.css';
import {
    getDateRangesFromPeriod,
    getDefaultTicksAndPeriod,
    getToAndFromBasedOnPeriodOrJob,
    IsRangeValid,
} from '../widgetHelper';
import WidgetInfo from '../WidgetInfo';
import './LineChartRegular.scss';

const dataValueFilter = '';
const formatString = 'DD.MM.YYYY HH:mm';

class LineChartRegular extends React.Component {
    constructor(props) {
        super(props);
        this.formatXAxis = this.formatXAxis.bind(this);
        this.formatYAxis = this.formatYAxis.bind(this);
        this.CustomizedDot = this.CustomizedDot.bind(this);
        this.SpreadTooltip = this.SpreadTooltip.bind(this);
        this.getChartInfoMapColorsAndSetStateAsync = this.getChartInfoMapColorsAndSetStateAsync.bind(this);
        this.getChartInfo = this.getChartInfo.bind(this);
        this.onDateRangeChanged = this.onDateRangeChanged.bind(this);

        const defaultPeriod = this.props.defaultPeriod || 'day';
        let { ticks, period } = getDefaultTicksAndPeriod(defaultPeriod);

        const defaultColor = { name: 'colorBlue', code: '#41cdf5', componentId: null };

        this.state = {
            selectedTab: 0,
            area: null,
            endDate: null,
            period: {
                period,
                interval: defaultPeriod,
                ticks,
            },
            loadingData: false,
            events: [],
            formulaManager: new WidgetFormulaManager(),

            defaultColor,
            colors: [
                { ...defaultColor },
                { name: 'colorWhite', code: '#ECECEC', componentId: null },
                { name: 'colorOrange', code: '#F08228', componentId: null },
                { name: 'colorPurple', code: '#D25AF0', componentId: null },
                { name: 'colorGreen', code: '#AAF03C', componentId: null },
            ],
        };
        this.mounted = true;
        this.singleValues = [];

        this.applyStd = this.applyStd.bind(this);
        this.applyWidgetFormula = this.applyWidgetFormula.bind(this);
    }

    getChartInfoMapColorsAndSetStateAsync = async () => {
        const propsOnStart = JSON.stringify(this.props);
        this.setState({ loadingData: true });
        const res = await this.getChartInfo();
        if (!this.mounted) {
            return;
        }

        if (!res?.data) {
            console.log('getChartInfo did not return any data');
        }

        const propsAfterChartInfo = JSON.stringify(this.props);
        if (propsAfterChartInfo !== propsOnStart) {
            console.log('Chart info returned no longer relevant.');
            return;
        }
        const colors = this.mapDataToColors(res?.data);
        this.setState({
            area: res?.data,
            events: res?.events,
            colors,
            loadingData: false,
        });
    };

    componentDidUpdate(prevProps) {
        if (!this.props.data && JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.getChartInfoMapColorsAndSetStateAsync();
        }
    }

    componentDidMount() {
        if (!this.props.data) {
            this.getChartInfoMapColorsAndSetStateAsync();

            if (!this.state.interval) {
                this.setState({
                    interval: setInterval(() => {
                        this.getChartInfoMapColorsAndSetStateAsync();
                    }, 120000),
                });
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
        this.mounted = false;
    }
    mapDataToColors(data) {
        const colors = this.state.colors;
        if (!data || !data.length) {
            return colors;
        }
        for (let i = 0; i < data.length; i++) {
            //Using simple index matching here.. But later we can use name to find the matching point.
            const line = data[i];
            if (i < colors.length) {
                colors[i].componentId = line.name;
            } else {
                console.warn('Number of lines exceed number of available colors!', line.name);
            }
        }
        return colors;
    }

    getColorByAreaName(name) {
        // eslint-disable-next-line lodash/prefer-is-nil
        if (typeof name === 'undefined' || name === null) {
            return this.state.defaultColor;
        }
        const color = this.state.colors.find((clr) => clr.componentId === name);

        return typeof color === 'undefined' || color == null ? this.state.defaultColor : color;
    }

    applyWidgetFormula(data) {
        if (!this.props.configuration.configuration) {
            return data;
        }
        const formula = this.props.configuration.configuration.formula;
        if (!formula) {
            return data;
        }
        return this.state.formulaManager.applyWidgetFormulaToLinechart(
            formula,
            data,
            this.props.configuration.componentSettings
        );
    }

    applyStd(data) {
        const showStd = this.props.configuration?.configuration?.showStd || false;
        if (!showStd) {
            return data;
        }

        data.data.forEach((line) => {
            line.data.forEach((val) => {
                if (!(val.value && val.std)) {
                    return;
                }
                const valueAsNumber = parseFloat(val.value);
                const stdAsNumber = parseFloat(val.std);
                val.stdMinMax = [
                    getValueWithPrecisionOrDefault(valueAsNumber - stdAsNumber, 2, val.value),
                    getValueWithPrecisionOrDefault(valueAsNumber + stdAsNumber, 2, val.value),
                ];
            });
            return;
        });
        return data;
    }

    getChartInfo = async () => {
        if (!this.mounted) {
            return;
        }
        const period = this.state.period;
        let { from, to } = getToAndFromBasedOnPeriodOrJob(this.props.job, period, this.state.endDate);
        if (!IsRangeValid(from, to)) {
            console.log('Provided range is outside of maximum range.', from, to);
            return;
        }
        const daterange = `&from=${from.toISOString()}&to=${to.toISOString()}`;

        let dataType;
        let dataValue;
        let timeInterval;
        const valueFormat = this.props.valueFormat || this.props.componentSettings?.length > 0 ? 'multirange' : 'range'; //"multirange":"range";
        const seriesName = this.props.configuration?.configuration?.seriesName || '';
        const seriesUnit = this.props.configuration?.configuration?.unit || '';
        const showStd = this.props.configuration?.configuration?.showStd || false;
        dataType = this.props.dataType;
        //dataValue is deprecated.
        dataValue = this.props.dataValue;

        let apiUrl =
            process.env.REACT_APP_API +
            '/api/kpi/range?calculation=' +
            (this.props.calculation || '') +
            '&events=' +
            this.props.events +
            '&valueFormat=' +
            valueFormat +
            daterange +
            '&rangeformat=' +
            (timeInterval || period.ticks) +
            '&dataType=' +
            dataType +
            '&dataValue=' +
            dataValue +
            '&dataValueFilter=' +
            dataValueFilter +
            '&seriesName=' +
            seriesName +
            '&seriesUnit=' +
            seriesUnit +
            '&showStd=' +
            showStd;
        forEach(this.props.components, (component) => {
            apiUrl += `&componentId=${component}`;
        });

        if (this.props.traceMode) {
            apiUrl =
                process.env.REACT_APP_API +
                `/api/trace/${this.props.plant.topicKey}/range?toDate=${to.toISOString()}&period=${
                    period.interval
                }&calculation=${this.props.calculation}`;
            if (this.props.currentItem) {
                apiUrl =
                    process.env.REACT_APP_API +
                    `/api/trace/${this.props.plant.topicKey}/${
                        this.props.currentItem.id
                    }/range?toDate=${to.toISOString()}&period=${period.interval}&showSubComponents=${
                        this.props.showSubComponents
                    }&itemTopic=${this.props.currentItem.topic}`;
            }
        }

        if (this.props.alarmMode) {
            apiUrl =
                process.env.REACT_APP_API +
                `/api/alarm/${this.props.plant.topicKey}/range?toDate=${to.toISOString()}&period=${period.interval}`;
            if (this.props.currentItem) {
                apiUrl =
                    process.env.REACT_APP_API +
                    `/api/alarm/${this.props.plant.topicKey}/${
                        this.props.currentItem.id
                    }/range?toDate=${to.toISOString()}&period=${period.interval}&showSubComponents=${
                        this.props.showSubComponents
                    }&showInactive=${this.props.showInactiveAlarms}`;
            }
        }

        const emptyXAzxisGapFillValue = this.props.alarmMode || this.props.traceMode ? 0 : null;

        try {
            if (!this.props.traceMode && !this.props.alarmMode) {
                const data = new FormData();
                data.append('componentSettings', encodeURIComponent(JSON.stringify(this.props.componentSettings)));
                const res = await api.post(apiUrl, data);
                const json = res.data;
                if (timeInterval && timeInterval === 'week') {
                    json.data.map((p) => {
                        p.weekNumber = moment(p.date).isoWeek();
                        p.value = dataValueFilter === 'dead' ? p.value * -1 : p.value;
                        return p;
                    });
                    this.applyStd(json.data);
                    return this.applyWidgetFormula(json.data);
                } else {
                    const fixedArr =
                        typeof json.data?.map === 'function'
                            ? json.data?.map((item) => {
                                  item.value = getValueWithPrecisionOrDefault(item.value, 2, 0);
                                  return item;
                              })
                            : [];
                    //range will contain all date intervals within the period, useful for visualizing data gaps
                    const range = getDateRangesFromPeriod(period, from, to); //<-- magic here...?
                    const groupedArr = groupBy(fixedArr, 'seriesName');

                    const final = Object.keys(groupedArr).map((g) => {
                        const byDate = groupedArr[g].reduce((res, item) => {
                            res[new Date(item.date).toISOString()] = {
                                date: new Date(item.date).toISOString(),
                                value: item.value,
                                name: item.seriesName,
                                unit:
                                    item.seriesUnit ||
                                    item.payloadUnit ||
                                    this.props.configuration?.configuration?.unit,
                                std: item.std || 0,
                                varName: item.varName,
                                aggMethod: item.aggMethod,
                            };
                            return res;
                        }, {});

                        for (const r of range) {
                            if (!byDate[r]) {
                                byDate[r] = { date: r, value: emptyXAzxisGapFillValue, name: g };
                            }
                        }

                        const gapFilledData = Object.values(byDate).sort((a, b) =>
                            moment.utc(a.date).diff(moment.utc(b.date))
                        );

                        return {
                            name: g,
                            data: gapFilledData,
                            singleValues: this.findLonelyIndexes(gapFilledData),
                        };
                    });

                    const data = {
                        data: final,
                        events: res.data.events,
                    };

                    this.applyStd(data);
                    return this.applyWidgetFormula(data);
                }
            }

            if (this.props.traceMode || this.props.alarmMode) {
                const res = await api.get(apiUrl);
                const json = res.data;
                if (timeInterval && timeInterval === 'week') {
                    json.data.map((p) => {
                        p.weekNumber = moment(p.date).isoWeek();
                        p.value = dataValueFilter === 'dead' ? p.value * -1 : p.value;
                        return p;
                    });

                    this.applyStd(json.data);
                    return this.applyWidgetFormula(json.data);
                } else {
                    //FIX NULL VALUES
                    //range will contain all date intervals within the period, useful for visualizing data gaps
                    const range = getDateRangesFromPeriod(period, from, to);

                    const fixedArr = json.data.map((item) => {
                        item.value = item.value ? item.value.toFixed(2) : item.value;
                        return item;
                    });
                    const groupedArr = groupBy(fixedArr, 'seriesName');

                    const final = Object.keys(groupedArr).map((g) => {
                        const byDate = groupedArr[g].reduce((res, item) => {
                            res[new Date(item.date).toISOString()] = {
                                date: new Date(item.date).toISOString(),
                                value: item.value,
                                name: item.seriesName,
                                unit: item.seriesUnit || item.payloadUnit,
                            };
                            return res;
                        }, {});
                        for (const r of range) {
                            if (!byDate[r]) {
                                byDate[r] = { date: r, value: emptyXAzxisGapFillValue, name: g };
                            }
                        }

                        const gapFilledData = Object.values(byDate).sort((a, b) =>
                            moment.utc(a.date).diff(moment.utc(b.date))
                        );

                        return {
                            name: g,
                            data: gapFilledData,
                            singleValues: this.findLonelyIndexes(gapFilledData),
                        };
                    });

                    const data = {
                        data: final,
                        events: res.data.events,
                    };

                    this.applyStd(data);
                    return this.applyWidgetFormula(data);
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    //finds values that sits alone with nulls on each side in array
    findLonelyIndexes(data) {
        const chk = (i) => (data[i] && data[i].value !== null) || false;
        return data.map((_, i) => (chk(i) && !chk(i - 1) && !chk(i + 1) ? i : null)).filter((i) => i !== null);
    }

    formatXAxis(tickItem, referenceLine) {
        const localTimeTick = moment(tickItem).utc().local();
        if (this.state.period) {
            if (this.state.period.interval === 'hour' || this.state.period.interval === 'day') {
                if (referenceLine) {
                    if (this.state.period.ticks === 'hour') {
                        return localTimeTick.minutes(0).second(0);
                    }
                    return localTimeTick.second(0).format('HH:mm');
                }
                return localTimeTick.format('HH:mm');
            }
            if (this.state.period.interval === 'week') {
                if (referenceLine) {
                    return localTimeTick.hour(0).minutes(0).second(0).format('DD.MM HH:mm');
                }

                return localTimeTick.format('ddd HH:mm');
            }
            //month view
            if (referenceLine) {
                return localTimeTick.hour(0).minutes(0).second(0).format('DD.MM HH:mm');
            }

            return localTimeTick.format('DD.MM HH:mm');
        }
        return localTimeTick.format('DD.MMM[\n]HH:mm');
    }

    formatYAxis(tickItem, max, min) {
        if (max > 10 || min < -10) {
            return tickItem.toFixed(0);
        }
        return tickItem.toFixed(2);
    }

    CustomizedDot(chartprops, singleValues, color) {
        const _this = this;
        const { cx, cy, payload, index } = chartprops;

        const currentEvents = filter(_this.state.events, function (a) {
            return _this.state.period.ticks === 'hour'
                ? moment(a.time).isSame(payload.date, 'hour')
                : moment(a.time).isSame(payload.date, 'day');
        });

        if (currentEvents && currentEvents.length > 0) {
            return (
                <svg
                    key={`event${payload.date}`}
                    xmlns="http://www.w3.org/2000/svg"
                    x={cx - 7}
                    y={payload.value ? cy - 7 : 0}
                    width="14"
                    height="14"
                    viewBox="0 0 20 20"
                >
                    <title>obs</title>
                    <circle cx="9.957" cy="9.766" r="7.979" fill="#0d171e" />
                    <path
                        d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm.985,14.519q0,.316-.3.316H9.361q-.3,0-.3-.331V12.685a.265.265,0,0,1,.227-.3.3.3,0,0,1,.073,0h1.323a.261.261,0,0,1,.3.286Zm.046-6.707c0,.4-.024.85-.068,1.346s-.078.865-.1,1.105-.04.436-.06.586-.03.231-.03.241a.333.333,0,0,1-.347.316H9.572a.333.333,0,0,1-.347-.316c0-.01-.01-.09-.029-.241s-.043-.345-.069-.586-.047-.5-.067-.782q-.09-1.233-.091-1.669V5.481a.279.279,0,0,1,.236-.316.268.268,0,0,1,.08,0h1.43a.279.279,0,0,1,.316.236.268.268,0,0,1,0,.08Z"
                        fill="#41c9f0"
                    />
                </svg>
            );
        }

        //add dot if value is alone
        if (singleValues.includes(index)) {
            return (
                <circle
                    key={`circle${cx}_${cy}`}
                    r="3"
                    stroke={color}
                    fill={color}
                    width="475"
                    height="170"
                    className="recharts-dot recharts-area-dot"
                    cx={cx}
                    cy={cy}
                />
            );
        }

        return null;
    }

    getCurrentEvents(props) {
        return filter(this.state.events, (a) => {
            return this.state.period.ticks === 'hour'
                ? moment(a.time).isSame(props.payload[0].payload.date, 'hour')
                : moment(a.time).isSame(props.payload[0].payload.date, 'day');
        });
    }

    SpreadTooltip(props) {
        return !props.payload || props.payload.length === 0 ? (
            ''
        ) : (
            <div className="tooltip-box">
                <div className="tooltip-date">{moment(props.payload[0].payload.date).format(formatString)}</div>
                {props.payload.map((p) => {
                    if (this.props.labelOverride) {
                        p.name = this.props.labelOverride;
                    }

                    let payloadName =
                        p.payload.name || (p.name !== 'undefined' && p.name !== 'null' ? p.name : '') || '';
                    let payloadValue = p.payload.value
                        ? getValueWithPrecisionOrDefault(toNumber(p.payload.value), 2)
                        : 0;
                    if (p.dataKey === 'stdMinMax') {
                        payloadName = 'Standard Deviation';
                        payloadValue = `${p.payload.stdMinMax[0]} ~ ${p.payload.stdMinMax[1]}`;
                    }
                    const color = this.getColorByAreaName(payloadName);
                    return (
                        <Fragment key={p.name}>
                            <div className="tooltip-label" key={`ttpbl${p.name}`} style={{ color: color.code }}>
                                {payloadName}
                            </div>
                            <div className="tooltip-value" key={`ttpval${p.name}`} style={{ color: color.code }}>
                                <div className="tooltipvalue" key={`ttpvalval${p.name}`}>
                                    {payloadValue}
                                </div>
                                <div className="tooltipunit" key={`ttpvalunit${p.name}`}>
                                    {p.payload.value ? p.payload.unit || this.props.configuration.unit || '' : ''}
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        );
    }

    getArea(dataSerie, _index) {
        const showStd = this.props.configuration?.configuration?.showStd ?? false;
        const useInterpol = this.props.configuration?.configuration?.useInterpolation ?? false;
        const color = this.getColorByAreaName(dataSerie.name);
        if (!showStd) {
            return (
                <Area
                    connectNulls={useInterpol}
                    fillOpacity="0.7"
                    isAnimationActive={false}
                    type="monotone"
                    data={dataSerie.data}
                    name={dataSerie.data[0]?.name}
                    key={`val${dataSerie.name || dataSerie.data[0]?.name}`}
                    dataKey="value"
                    dot={(e) => this.CustomizedDot(e, dataSerie.singleValues, color.code)}
                    stroke={color.code}
                    fill={`url(#${color.name})`}
                />
            );
        }

        return [
            <Area
                key={`std${dataSerie.name || dataSerie.data[0]?.name}`}
                connectNulls={useInterpol}
                fillOpacity="0.7"
                isAnimationActive={false}
                type="monotone"
                data={dataSerie.data}
                dataKey="stdMinMax"
                stroke={color.code}
                fill={`url(#${color.name})`}
            />,

            <Area
                connectNulls={useInterpol}
                fillOpacity="0.0"
                isAnimationActive={false}
                type="monotone"
                data={dataSerie.data}
                name={dataSerie.data[0]?.name}
                key={`val${dataSerie.name || dataSerie.data[0]?.name}`}
                dataKey="value"
                dot={(e) => this.CustomizedDot(e, dataSerie.singleValues, color.code)}
                stroke={color.code}
                fill={`url(#${color.name})`}
            />,
        ];
    }
    getYUnit(res) {
        let unit = '';
        try {
            unit =
                this.props.configuration?.configuration?.usePayloadUnit && res[0]?.data
                    ? res[0].data[0]?.unit
                    : this.props.configuration?.configuration?.unit || '';
        } catch (err) {
            console.log(`Error getting yunit`, err, res);
        }
        return <span className={'yunit'}>{unit}</span>;
    }
    /**
     * Workaround for the linechart component that does not sort x by date on insert per group.. Create an empty data
     * graph that contains all unique dates to prevent "new" dates getting inserted at the end..
     */
    getDataArrayWithAllDates(res) {
        const dates = {};
        forEach(res, (d) => {
            if (!d.data) {
                return;
            }

            for (const dataElement of d.data) {
                dates[dataElement.date] = null;
            }
        });

        const fillerCollection = Object.keys(dates)
            .sort()
            .map((d) => {
                return { date: d, value: null, fillerline: true };
            });
        return { data: fillerCollection, singleValues: [] };
    }

    onDateRangeChanged = (period, endDate) => {
        this.setState({ loadingData: true, period, endDate }, function () {
            this.getChartInfoMapColorsAndSetStateAsync();
        });
    };

    createChart(chartData) {
        let maxY = 0;
        let minY = 0;
        forEach(chartData, (d) => {
            const maxObj = d.data ? maxBy(d.data, (o) => toNumber(o.value)) : null;
            const tempMaxY = toNumber(maxObj?.value) * 1.1; //.toFixed(2);

            const minObj = d.data
                ? minBy(d.data, function (o) {
                      return toNumber(o.value);
                  })
                : null;
            const tempMinY = toNumber(minObj?.value) * 1.1; //.toFixed(2);
            if (tempMaxY > maxY && tempMaxY < Infinity) {
                maxY = Math.ceil(tempMaxY);
            }

            if (tempMinY < minY) {
                minY = Math.floor(tempMinY);
            }
        });

        return (
            <div className="widget">
                <div className="data-title">
                    <span className="data-title__label">{this.props.title}</span>
                </div>
                <div className="widget-config-info">
                    <WidgetInfo widget={this.props.widget} />
                </div>
                {this.props.job ? null : (
                    <PeriodPicker
                        onDateRangeChanged={this.onDateRangeChanged}
                        period={this.state.period}
                        endDate={this.state.endDate}
                    />
                )}

                {this.state.loadingData ? (
                    <div className="loading-data">
                        <Spinner text="new data.." />
                    </div>
                ) : null}

                <div className="widget-content line-chart">
                    {chartData.length === 0 ? (
                        <div className="no-data-message">No data available</div>
                    ) : (
                        <>
                            {this.getYUnit(chartData)}
                            <ResponsiveContainer height={250} width="100%">
                                <AreaChart
                                    className={'inner-line'}
                                    width={1600}
                                    height={300}
                                    margin={{ top: 20, right: 25, bottom: 30, left: 20 }}
                                >
                                    <defs>
                                        <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="8%" stopColor="#41cdf5" stopOpacity={0.6} />
                                            <stop offset="95%" stopColor="#41cdf5" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="colorWhite" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="8%" stopColor="#ECECEC" stopOpacity={0.6} />
                                            <stop offset="95%" stopColor="#ECECEC" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="colorPurple" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="8%" stopColor="#D25AF0" stopOpacity={0.6} />
                                            <stop offset="95%" stopColor="#D25AF0" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="colorOrange" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="8%" stopColor="#F08228" stopOpacity={0.6} />
                                            <stop offset="95%" stopColor="#F08228" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="colorOrange" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="8%" stopColor="#AAF03C" stopOpacity={0.6} />
                                            <stop offset="95%" stopColor="#AAF03C" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <YAxis
                                        orientation="left"
                                        padding={{ top: 20 }}
                                        dx={-10}
                                        stroke="#646464"
                                        tickFormatter={(i) => this.formatYAxis(i, maxY, minY)}
                                        domain={[minY, maxY]}
                                    />
                                    <XAxis
                                        stroke="#646464"
                                        dy={10}
                                        dataKey="date"
                                        tickFormatter={this.formatXAxis}
                                        angle={-30}
                                        allowDuplicatedCategory={false}
                                    />

                                    <Tooltip key="tooltip" content={this.SpreadTooltip} />

                                    {this.getArea(this.getDataArrayWithAllDates(chartData), -1)}

                                    {
                                        //Area supports switch: connectNulls ie <Area connectNulls />
                                        chartData.map((chartDataSerie, index) => this.getArea(chartDataSerie, index))
                                    }
                                </AreaChart>
                            </ResponsiveContainer>
                        </>
                    )}

                    {this.state.events && this.state.events.length > 0 ? (
                        <table className="event-table" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.events.map((e, i) => {
                                    return (
                                        <tr
                                            key={i}
                                            className={
                                                (this.state.currentEvents || []).find((c) => c.date === e.date)
                                                    ? 'active'
                                                    : ''
                                            }
                                        >
                                            <td>{moment(e.date).format('DD.MM.YYYY HH:mm')}</td>
                                            <td>{e.name}</td>
                                            <td>{e.value}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : null}
                </div>
            </div>
        );
    }

    render() {
        const res = this.state.area;

        if (!res) {
            if (this.state.loadingData) {
                return (
                    <div className="loading-data">
                        <Spinner text="data.." />
                    </div>
                );
            }
            return <div />;
        }

        return this.createChart(res);
    }
}

const mapStateToProps = (state) => ({
    endDate: state.itemrow.endDate,
    endDateParent: state.itemrow.endDateParent,
});

export default connect(mapStateToProps)(LineChartRegular);
