export const SET_DOCLIST = 'SET_DOCLIST';
export const CLEAR_DOCLIST = 'CLEAR_DOCLIST';

export const setDocList = (documents) => {
    return {
        type: SET_DOCLIST,
        data: documents,
    };
};

export const clearDocList = (documents) => {
    return {
        type: CLEAR_DOCLIST,
        data: documents,
    };
};
