import { SET_LOCAL, SET_MOBILE, SET_TABLET, SET_TOS } from '../actions';

const utility = (
    state = {
        isMobile: undefined,
        isLocal: false,
        isTablet: undefined,
        tosAccepted: false,
    },
    action
) => {
    switch (action.type) {
        case SET_MOBILE:
            return {
                ...state,
                isMobile: action.data,
            };
        case SET_LOCAL:
            return {
                ...state,
                isLocal: action.data,
            };
        case SET_TABLET:
            return {
                ...state,
                isTablet: action.data,
            };
        case SET_TOS:
            return {
                ...state,
                tosAccepted: action.data,
            };
        default:
            return state;
    }
};

export default utility;
