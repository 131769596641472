import moment from 'moment';
import 'moment/locale/en-gb';
import React, { useEffect, useState } from 'react';
import Spinner from '../../components/common/Spinner/Spinner';
import api from '../../utility/api';

export default function VCPInfo(props) {
    const [vcpInfo, setVCPInfo] = useState({});
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        if (props.accountMode) {
            return;
            //url = `${process.env.REACT_APP_API}/api/support/crm/account/vcp`
        }
        setFetching(true);
        const url = `${process.env.REACT_APP_API}/api/support/crm/${props.plant?.id}/vcp`;

        //let url = `${process.env.REACT_APP_API}/api/support/crm/vcp`;

        const fetchAsync = async (fetchUrl) => {
            try {
                const res = await api.get(fetchUrl);
                setVCPInfo(res.data);
            } catch (err) {
                console.log('Unable to fetch VCP', err.response?.data);
            } finally {
                setFetching(false);
            }
        };
        fetchAsync(url);
    }, [props.accountMode, props.plant?.id]);

    function getFormattedDate(date) {
        const minDate = moment('2000-01-01');
        return date && moment(date) > minDate ? moment.utc(date).local().format('DD.MM.YYYY') : 'No date specified';
    }

    if (fetching) return <Spinner text="Valued Customer Program information"></Spinner>;
    //console.log("VCP Info",vcpInfo)

    const btnrow = (
        <div className="btnRow">
            <button
                onClick={() => {
                    props.close();
                }}
                className="btn btn-yellow"
            >
                Close
            </button>
        </div>
    );

    if (props.accountMode) {
        return (
            <div className="support vcpinfo">
                <>
                    <h2>Valued Customer Program</h2>
                    <p>
                        This information cannot be shown here.
                        <br />
                        Visit each plant to view VCP information from their respective support pages.
                    </p>
                </>
                {btnrow}
            </div>
        );
    }
    if (!vcpInfo)
        return (
            <div className="support vcpinfo">
                <div>No VCP Information found</div>
                {btnrow}
            </div>
        );

    if (vcpInfo.vcp === 'No') {
        return (
            <div className="support vcpinfo">
                <h2>No access</h2>
                <p>
                    Your account does not have an active Valued Customer Program with Optimar AS
                    <br />
                    Contact Service &amp; Aftermarket on service@optimar.no or one of our Customer Support Managers for
                    more information.
                </p>
                {btnrow}
            </div>
        );
    }
    return (
        <div className="support vcpinfo">
            <h3>
                <div>Valued Customer Program</div>
            </h3>

            <form className="vcp-form">
                <div className="">
                    <label>Active</label>
                    <input disabled value={vcpInfo.vcp} className="input-control"></input>
                </div>
                <div className="vcp-startend">
                    <div>
                        <label>Start</label>
                        <input disabled value={getFormattedDate(vcpInfo.startDate)} className="input-control short" />
                        {/* <Datetime readOnly={true} isValidDate={(date) => { return true}} inputProps={{ readOnly: true }} locale="en-gb" dateFormat="DD.MM.YYYY" timeFormat="" value={vcpInfo.startDate && moment(vcpInfo.startDate) > minDate ? moment.utc(vcpInfo.startDate).local():""} className={"date-input-control"} /> */}
                        {/* <span>{vcpInfo.startDate}</span> */}
                    </div>
                    <div>
                        <label>End</label>
                        <input disabled value={getFormattedDate(vcpInfo.endDate)} className="input-control short" />
                        {/* <Datetime disabled  isValidDate={(date) => { return true}} inputProps={{ readOnly: true }} locale="en-gb" dateFormat="DD.MM.YYYY" timeFormat="HH:mm" value={vcpInfo.endDate && moment(vcpInfo.endDate) > minDate ? moment.utc(vcpInfo.endDate).local():""} className={"date-input-control"} /> */}
                    </div>
                </div>
                <div className="">
                    <label>Regular Condition Review Interval</label>
                    <input disabled value={vcpInfo.regularConditionReviewInterval} className="input-control" />
                    <input disabled value={vcpInfo.regularConditionReviewDescription} className="input-control" />
                </div>
                <div className="">
                    <label>Maintenance</label>
                    <input disabled value={vcpInfo.maintenance} className="input-control" />
                    <input disabled value={vcpInfo.maintenanceDescription} className="input-control" />
                </div>
                <div className="">
                    <label>Pre-Season Testing</label>
                    <input disabled value={vcpInfo.preSeasonTesting} className="input-control" />
                    <input disabled value={vcpInfo.preSeasonTestDescription} className="input-control" />
                </div>
                <div className="">
                    <label>Critical Response Time Alternative</label>
                    <input disabled value={vcpInfo.criticalResponseTimeAlternative} className="input-control" />
                    <input disabled value={vcpInfo.criticalResponseTimeDescription} className="input-control" />
                </div>
                <div className="">
                    <label>Spare Parts Arrangement</label>
                    <input disabled value={vcpInfo.sparePartsArrangement} className="input-control" />
                    <input disabled value={vcpInfo.sparePartsArrangementDescription} className="input-control" />
                </div>
                <div className="">
                    <label>24/7 Support Centre</label>
                    <input disabled value={vcpInfo.twentyFourSevenSupportCentre} className="input-control" />
                    <input disabled value={vcpInfo.twentyFourSevenSupportCentreDescription} className="input-control" />
                </div>
                <div className="">
                    <label>Remote Access for Technical Support</label>
                    <input disabled value={vcpInfo.remoteAccessforTechnicalSupport} className="input-control" />
                    <input
                        disabled
                        value={vcpInfo.remoteAccessforTechnicalSupportDescription}
                        className="input-control"
                    />
                </div>
                <div className="">
                    <label>Optimar Commander Alternative</label>
                    <input disabled value={vcpInfo.optimarCommanderAlternative} className="input-control" />
                    <input disabled value={vcpInfo.optimarCommanderAlternativeDescription} className="input-control" />
                </div>
                <div className="">
                    <label>Other Support Services</label>
                    <input disabled value={vcpInfo.otherSupportServices} className="input-control" />
                    <input disabled value={vcpInfo.otherSupportServicesDescription} className="input-control" />
                </div>
            </form>
            {btnrow}
        </div>
    );
}
