import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import api from '../../../../utility/api';
import { ReactComponent as DeleteIcon } from '../../../../images/new/optimar_cross.svg';
import { confirmAlert } from 'react-confirm-alert';
import './ProfileModal.scss';

class ProfileModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayname: '', // props.user.profile.Displayname,
            password: '',
            newPassword: '',
            confirmNewPass: '',
            displayPAT: false,
            PAT: '',
            PATExpiryDate: null,
        };
        this.submit = this.submit.bind(this);
        this.getPAT = this.getPAT.bind(this);
        this.deletePAT = this.deletePAT.bind(this);
        this.copyPAT = this.copyPAT.bind(this);
        this.extendPAT = this.extendPAT.bind(this);
    }

    //componentWillMount() {
    componentDidMount() {
        this.getAndSetDisplayName();
        this.getAndSetPatExpiry();
    }

    getAndSetPatExpiry = async () => {
        const res = await api.get('/api/currentuser/pat/expires');
        if (moment(res.data).isAfter(moment('1910-01-01'))) {
            this.setState({ PATExpiryDate: res.data });
        }
    };
    getAndSetDisplayName = async () => {
        const res = await api.get('/api/currentuser/displayname');
        this.setState({ displayname: res.data });
    };

    submit = async (e) => {
        e.preventDefault();
        if (this.state.newPassword !== this.state.confirmNewPass) {
            NotificationManager.warning('New password does not match confirm password', 'Password mismatch');
            return;
        }
        try {
            const shouldUpdatePass =
                this.canChangePwd() && this.state.newPassword === this.state.confirmNewPass && this.state.password;
            if (shouldUpdatePass) {
                //api.put(`/api/users/${this.props.user.profile.sub}/update`, {
                await api.put(`/api/currentuser/pwd`, {
                    confirmNewPassword: this.state.confirmNewPass,
                    newPassword: this.state.newPassword,
                    password: this.state.password,
                    displayname: this.state.displayname,
                });

                NotificationManager.success('Password updated successfully', 'Success');
            }
            if (this.props.user.displayname !== this.state.displayname) {
                await api.put(`/api/currentuser`, {
                    displayname: this.state.displayname,
                });
                NotificationManager.success('Displayname updated successfully', 'Success');
            }
            this.props.closeModal();
        } catch (err) {
            console.log(err);
            NotificationManager.error('Could not update user information.', 'Error');
        }
    };

    getPAT = async () => {
        const res = await api.get('/api/currentuser/pat');
        const expiry = await api.get('/api/currentuser/pat/expires');
        this.setState({ PAT: res.data, displayPAT: true, PATExpiryDate: expiry.data });
    };

    deletePAT() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete PAT</h1>
                        <p>
                            You are about to delete your Personal Access Token
                            <br />
                            Any applications that currently use this will not be able to communicate with Commander
                            until you replace it.
                            <br />
                            If you need a new token just click to display it again, and a new one will be generated.
                        </p>
                        <p>Are you sure you want to delete your Personal Access token?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    api.delete('/api/currentuser/pat').then(() => {
                                        this.setState({ displayPAT: false, PAT: '', PATExpiryDate: null });
                                        NotificationManager.success(
                                            'Personal Access Token has been deleted',
                                            'Success'
                                        );
                                        onClose();
                                    });
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    extendPAT() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Extend PAT lifetime</h1>
                        <p>
                            You are about extend the lifetime of your personal access token <br />
                            The new expiry date will be set to a year from now.
                        </p>
                        <p>Are you sure you want to extend the lifetime of your personal access token?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    api.post('/api/currentuser/pat/extend').then(() => {
                                        api.get('/api/currentuser/pat/expires').then((res) => {
                                            this.setState({ PATExpiryDate: res.data });
                                            NotificationManager.success(
                                                'Personal access token lifetime extended',
                                                'Success'
                                            );
                                            onClose();
                                        });
                                    });
                                }}
                            >
                                Yes, extend
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    copyPAT() {
        api.get('/api/currentuser/pat').then((res) => {
            this.copyToClipboard(res.data);
            NotificationManager.info('PAT copied to clipboard', 'Success');
            return;
        });
    }

    copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }
    canChangePwd = () => {
        return this.props.user.profile.IsAdUser === 'False';
    };

    render() {
        return (
            <div className="profilemodal">
                <h3 className="profile-title">User Settings</h3>
                <form className="profile-form" onSubmit={this.submit}>
                    <label htmlFor="displayname">Displayname*:&nbsp;</label>
                    <input
                        name="displayname"
                        value={this.state.displayname}
                        minLength={1}
                        onChange={(e) => this.setState({ displayname: e.target.value })}
                        required
                        className="input-control"
                        type="text"
                    />
                    {
                        // eslint-disable-next-line
                        this.canChangePwd() ? (
                            <>
                                <label>
                                    Old password:&nbsp;
                                    <input
                                        value={this.state.password}
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        className="input-control"
                                        type="password"
                                    />
                                </label>
                                <label>
                                    New password:&nbsp;
                                    <input
                                        value={this.state.newPassword}
                                        onChange={(e) => this.setState({ newPassword: e.target.value })}
                                        className="input-control"
                                        type="password"
                                    />
                                </label>
                                <label>
                                    Confirm new password:&nbsp;
                                    <input
                                        value={this.state.confirmNewPass}
                                        onChange={(e) => this.setState({ confirmNewPass: e.target.value })}
                                        className="input-control"
                                        type="password"
                                    />
                                </label>
                            </>
                        ) : null
                    }
                    <label>
                        Personal Access Token{' '}
                        {this.state.PATExpiryDate
                            ? ' (Expires on ' + moment(this.state.PATExpiryDate).format('DD.MM.YYYY') + ')'
                            : null}
                        <br />
                        {!this.state.displayPAT ? (
                            <span onClick={this.getPAT} className="pat-display">
                                Display your personal access token
                            </span>
                        ) : (
                            <>
                                <textarea className="pat-textarea" type="text" disabled value={this.state.PAT} />
                                <span className="pat-delete" onClick={this.deletePAT}>
                                    <DeleteIcon className="delete" />
                                    Delete token
                                </span>
                            </>
                        )}
                        <br />
                        <span onClick={this.copyPAT} className="pat-display">
                            Copy PAT
                        </span>
                        <br />
                        <span onClick={this.extendPAT} className="pat-display">
                            Extend token lifetime
                        </span>
                    </label>
                    <div className="btn-row-grow">
                        <button
                            className="btn btn-yellow btn-grow"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.closeModal();
                            }}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-blue btn-grow">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default connect(mapStateToProps)(ProfileModal);
