import React from 'react';
import './AdminPlantItems.scss';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import AdminPlantItemsAddEdit from '../../components/common/AdminPlantItemsAddEdit/AdminPlantItemsAddEdit';
import AdminPlantAddEdit from '../../components/common/AdminPlantAddEdit/AdminPlantAddEdit';
import AdminPlantItemsFromTopic from '../../components/common/AdminPlantItemsFromTopic/AdminPlantItemsFromTopic';
import Modal from 'react-modal';
import TreeNavStructureCopy from '../../components/common/TreeNav/TreeNavStructureCopy';
import FlatTreeItem from '../../components/common/TreeNav/FlatTreeItem';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../utility/api';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        paddingTop: '40px',
        paddingRight: '18px',
        paddingBottom: '40px',
        paddingLeft: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
    },
};

class AdminPlantItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changeCount: 0,
            modalIsOpen: false,
            isCopying: false,
            view: 'editPlant',
        };
        this.changeView = this.changeView.bind(this);
        this.addRootItem = this.addRootItem.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.copyStructureFrom = this.copyStructureFrom.bind(this);
        this.duplicateStructure = this.duplicateStructure.bind(this);
        this.generateFromTopic = this.generateFromTopic.bind(this);
        this.quickAdd = this.quickAdd.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.resetChangeCount = this.resetChangeCount.bind(this);
    }

    addItem = (item) => {
        this.setState({
            chosenItem: item,
            view: 'add',
        });
    };

    quickAdd = (item, topicKey) => {
        if (!item) {
            const tmp = {};
            tmp.name = 'None';
            tmp.topicKey = topicKey;
            item = tmp;
        }
        this.setState(
            {
                chosenItem: item,
                view: 'quickAdd',
            },
            () => (this.refs.child ? this.refs.child.getWrappedInstance().nameInput.focus() : null)
        );
    };

    editPlant = (_item) => {
        this.setState({
            // chosenItem: item,
            view: 'editPlant',
        });
    };

    addRootItem() {
        this.setState({
            chosenItem: null,
            view: 'addRoot',
        });
    }

    editItem = (item) => {
        let tmpCount = this.state.changeCount;
        tmpCount++;

        this.setState({
            chosenItem: item,
            view: 'edit',
            changeCount: tmpCount,
        });
    };

    generateFromTopic() {
        this.setState({
            chosenItem: null,
            view: 'generateFromTopic',
        });
    }

    changeView(view) {
        let tmpCount = this.state.changeCount;
        tmpCount++;

        this.setState({
            view: view,
            changeCount: tmpCount,
        });
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete item</h1>
                        <p>
                            You are about to delete the item
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteItem(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteItem = (item) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/items/' + item.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Item deleted', 'Item successfully deleted', 5000);
                        let tmpCount = this.state.changeCount;
                        tmpCount++;
                        this.setState({
                            changeCount: tmpCount,
                            chosenItem: null,
                            view: null,
                        });
                    } else {
                        NotificationManager.error('Error', 'Failed to delete item', 5000);
                        throw new Error(response.status);
                    }
                })
                .catch(function (error) {
                    error.log(error);
                });
        }
    };

    copyStructure = (item) => {
        this.setState(
            {
                parentItem: item,
            },
            () => this.openModal('new')
        );
    };

    duplicateStructure = (item) => {
        if (this.state.isCopying) {
            return;
        }

        this.setState({
            isCopying: true,
        });

        if (this.props.user != null && this.props.plant != null && item) {
            api.get(
                process.env.REACT_APP_API +
                    '/api/plants/' +
                    this.props.plant.id +
                    '/items/duplicateItemStructure/' +
                    item.id
            )
                .then(() => {
                    NotificationManager.success('Structure duplicated', 'Structure successfully duplicated', 5000);
                    let tmpCount = this.state.changeCount;
                    tmpCount++;
                    this.setState({
                        changeCount: tmpCount,
                        isCopying: false,
                    });
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                    NotificationManager.error('Failure', 'Structure duplication failed', 5000);
                    this.setState({
                        isCopying: false,
                    });
                });
        }
    };

    copyStructureFrom = (item) => {
        this.setState({
            isCopying: true,
        });

        let parentItemId = '00000000-0000-0000-0000-000000000000';
        const parentItem = this.state.parentItem;
        if (parentItem !== undefined) {
            parentItemId = parentItem.id;
        }

        if (this.props.user != null && this.props.plant != null && item) {
            api.get(
                process.env.REACT_APP_API +
                    '/api/plants/' +
                    this.props.plant.id +
                    '/items/copyItemStructure/' +
                    parentItemId +
                    '/' +
                    item.id +
                    (item.root ? '?fromRoot=true' : '')
            )
                .then((_res) => {
                    NotificationManager.success('Structure copied', 'Structure successfully copied', 5000);
                    let tmpCount = this.state.changeCount;
                    tmpCount++;
                    this.setState({
                        changeCount: tmpCount,
                        modalIsOpen: false,
                        isCopying: false,
                    });
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                    NotificationManager.error('Failure', 'Structure copy failed', 5000);
                    this.setState({
                        isCopying: false,
                    });
                });
        }
    };

    openModal(mode, _note) {
        this.setState({
            modalIsOpen: true,
            editItem: null,
            editType: mode,
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            editItem: null,
            editType: null,
        });
    }

    redirect(path) {
        this.setState({
            redirect: true,
            redirectPath: path,
        });
    }

    resetChangeCount() {
        this.setState({ changeCount: 0 });
    }

    render() {
        const currentUser = this.state.user;
        let content;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirectPath} />;
        }

        if (currentUser && currentUser.profile.role !== 'admin') {
            return <Redirect to="/" />;
        }

        if (this.state.view === 'add') {
            console.log('🚀 ~ file: AdminPlantItems.js:309 ~ AdminPlantItems ~ render ~ add');
            content = (
                <AdminPlantItemsAddEdit
                    plantItemParent={this.state.chosenItem}
                    addedCallback={() => this.changeView('')}
                    addRootCallback
                />
            );
        } else if (this.state.view === 'quickAdd') {
            console.log('🚀 ~ file: AdminPlantItems.js:318 ~ AdminPlantItems ~ render ~ quickAdd');
            content = (
                <AdminPlantItemsAddEdit
                    plantItemParent={this.state.chosenItem}
                    quickAdd="true"
                    ref="child"
                    addedCallback={() => this.changeView('')}
                    addRootCallback
                />
            );
        } else if (this.state.view === 'editPlant') {
            console.log('🚀 ~ file: AdminPlantItems.js:329 ~ AdminPlantItems ~ render ~ editPlant');
            content = (
                <AdminPlantAddEdit
                    plantToEdit={this.state.editItem}
                    editedCallback={() => this.changeView('')}
                    cancelCallback={() => this.changeView('')}
                />
            );
        } else if (this.state.view === 'addRoot') {
            console.log('🚀 ~ file: AdminPlantItems.js:338 ~ AdminPlantItems ~ render ~ addRoot');
            content = (
                <AdminPlantItemsAddEdit
                    addRootPlantItem="true"
                    addedCallback={() => this.changeView('')}
                    addRootCallback
                />
            );
        } else if (this.state.view === 'edit') {
            console.log('🚀 ~ file: AdminPlantItems.js:347 ~ AdminPlantItems ~ render ~ edit');
            content = (
                <AdminPlantItemsAddEdit
                    editPlantItem={this.state.chosenItem}
                    editedCallback={() => this.changeView('')}
                />
            );
        } else if (this.state.view === 'generateFromTopic') {
            console.log('🚀 ~ file: AdminPlantItems.js:355 ~ AdminPlantItems ~ render ~ generateFromTopic');
            content = <AdminPlantItemsFromTopic addedCallback={() => this.changeView('')} />;
        } else {
            content = (
                <div className="admin-plant-items-add-edit-header">
                    <span>Please select an action in the items tree to the left</span>
                </div>
            );
        }

        return (
            <div className="items-add-edit-container">
                <div className="items-add-edit-content">
                    <div className="items-add-edit-tree">
                        <FlatTreeItem
                            show={true}
                            editItemCallback={this.editItem}
                            addRootCallback={this.addRootItem}
                            addCallback={this.addItem}
                            deleteCallback={this.confirmDelete}
                            copyStructure={this.copyStructure}
                            duplicateStructure={this.duplicateStructure}
                            changeCount={this.state.changeCount}
                            resetChangeCount={this.resetChangeCount}
                            isCopying={this.state.isCopying}
                            generateFromTopicCallback={this.generateFromTopic}
                            quickAddCallback={this.quickAdd}
                            editPlantCallback={this.editPlant}
                            activeItem={this.state.chosenItem}
                        />
                    </div>
                    <div className="items-add-edit">{content}</div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <TreeNavStructureCopy
                        close={this.props.close}
                        closeModal={this.closeModal}
                        addCallback={this.addItem}
                        addRootCallback={this.addRootItem}
                        editCallback={this.editItem}
                        deleteCallback={this.confirmDelete}
                        changeCount={this.state.changeCount}
                        copyStructure={this.copyStructure}
                        copyStructureFrom={this.copyStructureFrom}
                        isCopying={this.state.isCopying}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentAdminPlant,
});

export default connect(mapStateToProps)(AdminPlantItems);
