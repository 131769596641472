import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './Nav.scss';

const NavItem = ({ name, link, className, external, activeClassName }) => {
    return external ? (
        <a href={link} target="_blank" rel="noopener noreferrer" className={className}>
            <div className="navcontent ">
                <span>{name}</span>
            </div>
        </a>
    ) : (
        <NavLink activeClassName={activeClassName} to={link} className={className}>
            <div className="navcontent ">
                <span>{name}</span>
            </div>
        </NavLink>
    );
};

NavItem.propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
};

export default NavItem;
