import React from 'react';
import './Header.scss';
import './PlantnameDropdown.css';

class PlantnameDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    toggleExpand = () => {
        if (this.props.plantId) {
            this.setState({
                expanded: !this.state.expanded,
            });
        }
    };

    render() {
        return (
            <div className="plantdropdown-container">
                <div
                    onClick={() => {
                        this.props.handleVisible(!this.props.visible);
                    }}
                    className="plantname-container"
                >
                    {<span className="header-plantname">{this.props.plantName.toUpperCase()}&nbsp;</span>}
                </div>
                {/* {this.props.visible ? 
                    <div className="plantdropdown">
                        <div className="pd-main-nav">
                            <div onClick={() => {this.props.handleVisible(false)}} className="pd-main-nav-element">
                                <Link to={"/plant/" + this.props.plantId}>System </Link>
                            </div>
                            <div onClick={() => {this.props.handleVisible(false)}} className="pd-main-nav-element">
                                <Link to={"/plant/" + this.props.plantId + "/report"}>Reports </Link>
                            </div>
                        </div>
                    </div>
                    :
                    null
                } */}
            </div>
        );
    }
}

export default PlantnameDropdown;
