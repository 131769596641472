import React from 'react';
import './AdminPlantAccounts.scss';
import api from '../../../utility/api';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import ReactTable from 'react-table-6';

class AdminPlantAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.visibleColumns = [
            { Header: 'Account', accessor: 'accountName', Cell: (row) => <div>{row.value}</div>, filterable: true },
            {
                Header: 'Associated',
                accessor: 'createdOn',
                Cell: (row) => <div>{format(new Date(row.value), 'dd.MM.yyyy')}</div>,
            },
        ];

        this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this);
    }

    componentDidMount() {
        if (this.props.plant) {
            api.get(process.env.REACT_APP_API + '/api/accountPlants/plant/' + this.props.plant.id)
                .then((res) => this.setState({ plantAccounts: res.data }))
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null
            ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
            : false;
    }

    render() {
        const res = this.state.plantAccounts;
        if (!res) {
            return <div></div>;
        }

        return (
            <div className="device-wrapper">
                <div className="main-header">
                    <span>
                        <h2>{res ? res.length : 0} accounts</h2>
                    </span>
                </div>
                <ReactTable
                    defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                    data={res}
                    columns={this.visibleColumns}
                    showPagination={false}
                    minRows={0}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        plant: state.plants.currentAdminPlant,
    };
};

export default connect(mapStateToProps)(AdminPlantAccounts);
