import moment from 'moment';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import GenericTable from '../../components/common/GenericTable';
import Spinner from '../../components/common/Spinner/Spinner';
import CaseView from './CaseView';
import './support.scss';
import SupportAddEdit from './SupportAddEdit';
import { useSupportCases } from './useSupportCases';
import VCPInfo from './VCPInfo';

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        paddingTop: '20px',
        paddingRight: '20px',
        paddingBottom: '20px',
        paddingLeft: '20px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '80vh',
    },
};

/**
 * @type {React.FC<
 *     {
 *         accountMode?: Boolean;
 *         plantId: String;
 *         currentItem: import('./useSupportCases').CurrentItem;
 *         itemId?: String;
 *     } & SupportListReduxState
 * >}
 */
const SupportList = (props) => {
    const history = useHistory();

    const [state, setState] = useState({
        selectedCase: null,
        showClosed: false,
    });

    const { fetchingCases, filteredCases, refetchCases, selectedCase, cases } = useSupportCases({
        currentItem: props.currentItem,
        accountMode: props.accountMode,
        plant: props.plant,
        showClosed: state.showClosed,
    });

    let visibleColumns = [
        {
            Header: 'Title',
            accessor: 'title',
            style: { whiteSpace: 'unset' },
            filterable: true,
            Cell: (row) => <div>{row.value}</div>,
        },
        {
            Header: 'Customer ref',
            accessor: 'customerRef',
            filterable: true,
            Cell: (row) => <div>{row.value}</div>,
        },
        { id: 'plant', accessor: 'plant', show: false },
        { id: 'item', accessor: 'item', show: false },
        { id: 'reporter', accessor: 'reporter', show: false },
        { id: 'caseTypeId', accessor: 'caseTypeId', show: false },
        {
            Header: 'Case type',
            accessor: 'caseType',
            style: { whiteSpace: 'unset' },
            filterable: true,
            Cell: (row) => <div>{row.value}</div>,
        },
        {
            Header: 'Description',
            id: 'description',
            show: false,
            accessor: 'description',
            filterable: true,
            Cell: (row) => <div>{row.value}</div>,
        },
        {
            Header: 'Component',
            accessor: 'itemPath',
            style: { whiteSpace: 'unset' },
            filterable: true,
            clickable: false,
            Cell: (cellInfo) => (
                <Link
                    onClick={(e) => e.stopPropagation()}
                    title={cellInfo.value}
                    to={`/plant/${cellInfo.data[cellInfo.row.index].plant}/items/${
                        cellInfo.data[cellInfo.row.index].item
                    }/support`}
                >
                    {cellInfo.value}
                </Link>
            ),
        },
        { Header: 'Status', accessor: 'status', filterable: true, Cell: (row) => <div>{row.value}</div> },
        {
            Header: 'Updated',
            accessor: 'updated',
            sortMethod: dateSort,
            filterable: true,
            Cell: (row) => <div>{moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss')}</div>,
        },
        {
            Header: 'Time since opened',
            id: 'updated2',
            Cell: ({ row }) => {
                return (
                    <div>
                        {isClosedStatus(row.original.status) ? '' : moment.utc(row.original.created).local().fromNow()}
                    </div>
                );
            },
        },
    ];

    if (props.accountMode) {
        visibleColumns = [{ Header: 'Plant', accessor: 'plantName', filterable: true, show: true }].concat(
            visibleColumns
        );
        visibleColumns.splice(
            visibleColumns.findIndex((x) => x.Header === 'Customer ref.'),
            1
        );
    }

    const modalRefCaseView = React.useRef(null);
    const modalRefSupportAddEdit = React.useRef(null);

    const showVCPInfo = () => {
        setState((prevState) => ({ ...prevState, showVCPInfo: true }));
    };
    function exportCSV() {
        const items = cases;
        const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        let csv = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        csv = csv.join('\r\n');

        //Download the file as CSV
        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', csv]);
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = 'SupportCases.csv'; //Name the file here
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    function handleShowClosedCasesClick() {
        setState((prevState) => ({
            ...prevState,
            showClosed: !prevState.showClosed,
        }));
    }

    function rowClick(row, _a, _b) {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('supportId', row.original.id);
        history.push(`support?${urlParams.toString()}`);
    }

    function closeSupportCase() {
        history.push(`support`);
    }
    if (fetchingCases) {
        return <Spinner text="cases" />;
    }

    return (
        <div>
            <span
                className="main-header-action"
                style={{ display: 'flex', paddingBottom: '15px', justifyContent: 'space-between' }}
            >
                <span style={{ display: 'flex' }}>
                    {!props.accountMode ? (
                        <span
                            onClick={() => setState((prevState) => ({ ...prevState, newCaseModal: true }))}
                            className="list-expand-toggle"
                        >
                            + New case
                        </span>
                    ) : null}
                    <span onClick={() => handleShowClosedCasesClick()} className="faux-checkbox">
                        <div className={state.showClosed ? 'checkbox checked' : 'checkbox'} />
                        <span>Show closed cases</span>
                    </span>
                </span>
                <div className="main-header-right">
                    {
                        <div onClick={showVCPInfo} className="list-expand-toggle" title="Valued Customer Program">
                            VCP
                        </div>
                    }
                    {props.user.isAdmin ? (
                        <div onClick={exportCSV} className="list-expand-toggle">
                            Export to CSV
                        </div>
                    ) : null}
                </div>
            </span>

            <GenericTable
                data={filteredCases}
                pageCount={state.pages}
                columns={visibleColumns}
                localPaging={true}
                rowClick={rowClick}
            />

            <Modal
                ref={modalRefCaseView}
                isOpen={selectedCase !== undefined}
                onRequestClose={() => {
                    closeSupportCase();
                    refetchCases();
                }}
                style={getModalStyle('view')}
                ariaHideApp={false}
            >
                <CaseView
                    modalRef={modalRefCaseView}
                    accountMode={props.accountMode}
                    close={() => {
                        closeSupportCase();
                    }}
                    case={selectedCase}
                />
            </Modal>
            <Modal
                ref={modalRefSupportAddEdit}
                isOpen={state.newCaseModal}
                onRequestClose={() => setState((prevState) => ({ ...prevState, newCaseModal: false }))}
                style={getModalStyle('addedit')}
                ariaHideApp={false}
            >
                <SupportAddEdit
                    modalRef={modalRefSupportAddEdit}
                    item={props.currentItem}
                    close={() => {
                        setState((prevState) => ({ ...prevState, newCaseModal: false }));
                        refetchCases();
                    }}
                />
            </Modal>
            <Modal
                isOpen={state.showVCPInfo}
                onRequestClose={() => setState((prevState) => ({ ...prevState, showVCPInfo: false }))}
                style={getModalStyle('addedit')}
                ariaHideApp={false}
            >
                <VCPInfo
                    accountMode={props.accountMode}
                    close={() => {
                        setState((prevState) => ({ ...prevState, showVCPInfo: false }));
                    }}
                    plant={props.plant}
                />
            </Modal>
        </div>
    );
};

/**
 * @typedef {Object} SupportListReduxState
 *
 * @property {Object} user
 * @property {Object} plant
 *
 * @param {any} state
 *
 * @returns {SupportListReduxState}
 */
const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        plant: state.plants.currentPlant,
    };
};

export default connect(mapStateToProps)(SupportList);

export function isClosedStatus(status) {
    if (!status) {
        return false;
    }
    const closedStatuses = [
        'Rejected',
        'Closed',
        'Cancelled',
        'Resolved',
        'Problem Solved',
        'Information Provided',
        'Claim Unvalid - Work Order Created',
    ];
    return closedStatuses.includes(status);
}

function dateSort(a, b) {
    a = new Date(a);
    b = new Date(b);
    return a > b ? 1 : -1;
}

function getModalStyle(mode) {
    const modalStyles = { ...modalStyle };
    modalStyles.content = { ...modalStyle.content };
    switch (mode) {
        case 'view':
            modalStyles.content.width = '90%';
            break;
        case 'addedit':
        default:
            break;
    }
    return modalStyles;
}
