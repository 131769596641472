import React from 'react';
// import './Table.scss';

import 'react-datepicker/dist/react-datepicker.css';
// import ChartIcon from '../../../images/graf.svg';
//import CloseIcon from '../../../images/lukk.svg';

import { setChartInfo, getChartInfoReq, setCurrentPlant, clearCurrentPlant } from '../../../actions';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import '../../../views/list/generic-list.scss';
import api from '../../../utility/api';
import TableSpinner from '../Spinner/TableSpinner';
import NoDataComponent from './NoDataComponent';
import { withRouteMatch } from '../../../HOC';

export class CustomTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parent: true,
            currentRow: null,
            items: [],
            showSub: true,
        };

        this.fetching = false;
        this.mounted = true;

        this.visibleColumns = [
            {
                Header: 'Path',
                accessor: 'itemPath',
                filterable: true,
                Cell: (item) => {
                    return (
                        <Link
                            className="action-element padding"
                            alt="Item path"
                            to={`/plant/${this.props.match.params.plantId}/items/${item.original.id}/overview`}
                        >
                            {item.original.itemPath}
                        </Link>
                    );
                },
                width: 500,
            },
            { Header: 'Type', accessor: 'itemTypeName', filterable: true },
            { Header: 'Specification', accessor: 'specification', filterable: true },
            { Header: 'Article number', accessor: 'articleId', filterable: true },
            { Header: 'Order pos', accessor: 'orderPos', filterable: true },
        ];
    }

    fetchSubItems() {
        this.fetching = true;
        let url = process.env.REACT_APP_API;
        if (this.props.match.params.itemId) {
            url += `/api/items/${this.props.match.params.itemId}/items/all`;
        } else {
            url += `/api/plants/${this.props.match.params.plantId}/items/all`;
        }

        api.get(url)
            .then((res) => {
                this.fetching = false;
                if (this.mounted) {
                    this.setState({
                        items: res.data,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    componentDidMount() {
        this.fetchSubItems();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getItemGroupName(groupId) {
        for (let i = 0; i < this.props.config.length; i++) {
            if (groupId === this.props.config[i].itemTypeId) {
                return this.props.config[i].displayName;
            }
        }
        return null;
    }

    renderStatus(statusCode) {
        let styles = {};
        switch (statusCode) {
            case 9:
                styles = {
                    borderLeft: '2px solid red',
                };

                break;

            default:
                styles = {
                    borderLeft: 'none',
                };
                return styles;
        }
    }

    onClick = (path) => {
        this.props.pushPath(path);
        this.props.toggleTable(false);
    };

    toggleTable = () => {
        this.props.toggleTable(false);
    };

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
    }

    filterSubComponents() {
        let items = Object.assign([], this.state.items);
        //Remove structural components
        items = items.filter((item) => {
            return !item.itemTypeName.toLowerCase().includes('line');
        });
        //Not showing subcomponents on item
        if (!this.state.showSub && items && this.props.match.params.itemId) {
            return items.filter((item) => {
                return item.parentId.toLowerCase() === this.props.match.params.itemId.toLowerCase();
            });
        } else if (!this.state.showSub) {
            //Not showing subcomponents on plant
            return items.filter((item) => {
                return !item.parentId;
            });
        }

        items.sort((a, b) => {
            if (a.topic < b.topic) return -1;
            if (a.topic > b.topic) return 1;
            return 0;
        });
        return items;
    }

    getNoDataProps = (props) => ({
        loading: props.loading,
    });

    render() {
        if (this.props.data && this.props.data.length === 0) {
            return (
                <center>
                    <h3>No equipment attached</h3>
                </center>
            );
        }

        const items = this.filterSubComponents();

        return (
            <>
                {items.length !== this.state.items.length ? (
                    <div className="table-tools">
                        <div
                            onClick={() => this.mounted && this.setState({ showSub: !this.state.showSub })}
                            className="faux-checkbox"
                        >
                            <div className={this.state.showSub ? 'checkbox checked' : 'checkbox'}></div>
                            <span>Show lower levels</span>
                        </div>
                    </div>
                ) : null}
                <ReactTable
                    data={items}
                    columns={this.visibleColumns}
                    showPagination={false}
                    pageSize={this.state.items.length}
                    minRows={0}
                    loading={items.length === 0}
                    LoadingComponent={TableSpinner}
                    NoDataComponent={NoDataComponent}
                    getNoDataProps={this.getNoDataProps}
                    defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    chartdata: state.itemrow.chartResult,
    plant: state.plants.currentPlant,
});

const mapDispatchToProps = {
    setChartInfo,
    getChartInfoReq,
    setCurrentPlant,
    clearCurrentPlant,
};

export default withRouteMatch(connect(mapStateToProps, mapDispatchToProps)(CustomTable));
