import React from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import 'jsoneditor-react/es/editor.min.css';
import './AdminPlantConfig.scss';
import { connect } from 'react-redux';

class AdminPlantConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onDashChange = this.onDashChange.bind(this);
        this.onNavChange = this.onNavChange.bind(this);
        this.submitDash = this.submitDash.bind(this);
        this.submitNav = this.submitNav.bind(this);
    }

    componentDidMount() {
        if (this.props.plant) {
            const dConf = JSON.parse(this.props.plant.dashboardConfig);
            const nConf = JSON.parse(this.props.plant.navigationConfig);
            console.log('dConf', dConf);
            this.setState({
                dashboardConfig: dConf || {},
                navigationConfig: nConf || {},
            });
        }
    }

    onDashChange(json) {
        this.setState({ dashboardConfig: json, dashDirty: true });
    }

    onNavChange(json) {
        this.setState({ navigationConfig: json, navDirty: true });
    }

    submitNav() {
        if (this.state.navDirty) {
            const data = {
                plantId: this.props.plant.id,
                data: JSON.stringify(this.state.navigationConfig),
            };
            api.post('api/configurations/navigation/' + data.plantId, data).then((res) => {
                if (res.status === 200 || res.status === 204) {
                    NotificationManager.success('Updated navigation config', 'Success');
                } else {
                    NotificationManager.error('Could not update navigation config', 'Error');
                }
            });
        }
    }

    submitDash() {
        if (this.state.dashDirty) {
            const data = {
                plantId: this.props.plant.id,
                data: JSON.stringify(this.state.dashboardConfig),
            };

            api.post('api/dashboard/new/published', data).then((res) => {
                if (res.status === 200 || res.status === 204) {
                    NotificationManager.success('Updated dashboard config', 'Success');
                } else {
                    NotificationManager.error('Could not update dashboard config', 'Error');
                }
            });
        }
    }

    render() {
        if (!this.state.navigationConfig || !this.state.dashboardConfig) return null;
        return (
            <div className="editor-wrapper">
                <div className="editor">
                    <Editor mode="code" onChange={this.onDashChange} value={this.state.dashboardConfig} />
                    <button onClick={this.submitDash} className="btn btn-blue">
                        Submit dashboard config
                    </button>
                </div>
                <div className="editor">
                    <Editor mode="code" onChange={this.onNavChange} value={this.state.navigationConfig} />
                    <button onClick={this.submitNav} className="btn btn-blue">
                        Submit navigation config
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        plant: state.plants.currentAdminPlant,
    };
};

export default connect(mapStateToProps)(AdminPlantConfig);
