import { useCallback } from 'react';
import api from '../../utility/api';
import store from '../..';

export const useUploadImage = () => {
    const state = store.getState();
    const plantId = state.plants.currentAdminPlant?.id;

    return useCallback(
        /**
         * @typedef {'LogoTop' | 'LogoBottom' | 'none'} ReportImageType
         */
        /**
         * @typedef {Object} BlobImageDto
         *
         * @property {string} filename
         * @property {Blob} blobUrl
         * @property {ReportImageType} imageType
         */
        /**
         * @param {BlobImageDto} imageInfo
         *
         * @returns {Promise<string>} Image storage url
         */
        async ({ filename, blobUrl, imageType }) => {
            const blob = await (await fetch(blobUrl)).blob();

            const allowedExtensions = new RegExp('.(jpg|png)$');
            const ext = allowedExtensions.test(filename) ? '' : getFileExtension(blob.type);

            const formData = new FormData();
            formData.append('fileContent', blob);
            formData.append('filename', filename + ext);
            formData.append('type', imageType);
            formData.append('plantId', plantId);

            return (
                await api.post(`/api/reportlogo/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
            ).data;
        },
        [plantId]
    );
};

/**
 * Gets image extension based on blobType
 *
 * @param {string} blobType
 */
const getFileExtension = (blobType) => {
    switch (blobType) {
        case 'image/png':
            return '.png';
        case 'image/jpeg':
        case 'image/jpg':
            return '.jpg';
        default:
            return '';
    }
};
