import { SET_CURRENTITEM } from '../actions';

const item = (
    state = {
        currentItem: undefined,
    },
    action
) => {
    switch (action.type) {
        case SET_CURRENTITEM:
            return {
                ...state,
                currentItem: action.data,
            };
        default:
            return state;
    }
};

export default item;
