import React from 'react';
import moment from 'moment';
import './PeriodPicker.scss';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

function PeriodPicker({ onDateRangeChanged, period, endDate }) {
    const [internalPeriod, setPeriod] = React.useState(period);
    const [internalEndDate, setEndDate] = React.useState(endDate);
    const [showDropdownOnParent, setShowDropdownOnParent] = React.useState(false);
    const [showPeriodDropdownOnParent, setShowPeriodDropdownOnParent] = React.useState(false);

    React.useEffect(() => {
        if (onDateRangeChanged) onDateRangeChanged(internalPeriod, internalEndDate);
        else console.log('Daterange changed, but there were no listeners.');
    }, [internalPeriod, internalEndDate]);

    const check = () => {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    };
    const openCloseDropdown = (open) => {
        //this.setState({ showDropdownOnParent: !this.state.showDropdownOnParent });
        setShowDropdownOnParent(open);
    };

    const openClosePeriodDropdown = (open) => {
        //this.setState({ showPeriodDropdownOnParent: !this.state.showPeriodDropdownOnParent });
        setShowPeriodDropdownOnParent(open);
    };

    const daterange = (val, isPeriod, parent, row, cancel) => {
        if (!cancel) {
            if (isPeriod) {
                //this.setState({ period: val });
                setPeriod(val);
            } else {
                //this.setState({ endDate: val });
                setEndDate(val);
            }
        }
        openClosePeriodDropdown(false);
        openCloseDropdown(false);
    };

    return (
        <div className={'date-wrapper parent ' + (check() ? '' : ' mobile')}>
            <div className="date-picker-button" onClick={() => openClosePeriodDropdown(true)}>
                <div className="summary-value">Period:</div>
                <div className="dates-value">{internalPeriod ? internalPeriod.interval : ''}</div>
            </div>
            {showPeriodDropdownOnParent ? (
                <CustomDatePicker period={true} daterange={(val) => daterange(val, true, true)} />
            ) : (
                <div></div>
            )}
            <div className="date-picker-button" onClick={() => openCloseDropdown(true)}>
                <div className="summary-value">End time:</div>
                <div className="dates-value">
                    {internalEndDate ? moment(internalEndDate).format('DD.MM.YYYY HH:mm') : 'NOW'}
                </div>
            </div>

            {showDropdownOnParent ? (
                <CustomDatePicker
                    mobile={!check()}
                    selDate={internalEndDate}
                    daterange={(val, a, b, c, cancel) => daterange(val, false, true, null, cancel)}
                />
            ) : (
                <div></div>
            )}
        </div>
    );
}
export default PeriodPicker;
