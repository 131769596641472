import React from 'react';
import { useParams } from 'react-router-dom';
/**
 * Higher order component that adds router params to component
 * @param {import('react').ReactElement} Component
 * @returns
 */
export function withParams(Component) {
    return function ParamsWrapper(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    };
}
