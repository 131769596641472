import React from 'react';
import api from '../../utility/api';

export default class NewSectionControl extends React.Component {
    constructor() {
        super();
        this.state = {
            sectionType: '',
            title: '',
            jobTypeRef: '',
            jobTypeRefOptions: [],
        };
    }

    async componentDidMount() {
        const jobTypes = await this.fetchJobTypes();
        console.log('jobtypes', jobTypes);

        const tmp = Object.assign({}, this.state);
        tmp.jobTypeRefOptions = jobTypes;
        this.setState(tmp);
    }
    setSelectedSectionType(val) {
        const tmp = Object.assign({}, this.state);
        tmp.sectionType = val;
        this.setState(tmp);
    }

    sectionTypeSeleted(val) {
        this.setSelectedSectionType(val);
        console.log('select', val);
    }
    onTitleChange(val) {
        const tmp = Object.assign({}, this.state);
        tmp.title = val;
        this.setState(tmp);
    }

    handleCancel() {
        this.props.onCloseCallback();
    }

    handleSave() {
        const sectionDetails = {
            type: this.state.sectionType,
            title: this.state.title,
            jobTypeRef: this.state.jobTypeRef,
        };

        this.props.onSaveCallback(sectionDetails);
    }

    jobTypeRefSelected(val) {
        const tmp = Object.assign({}, this.state);
        tmp.jobTypeRef = val;
        this.setState(tmp);
    }

    fetchJobTypes = async () => {
        const url = `${process.env.REACT_APP_API}/api/jobs/types/${this.props.plantId}`;

        const res = await api.get(url);
        // .then(res => {
        const jobtypes = res.data.map((j) => {
            return {
                value: j.id,
                label: j.name,
                parentId: j.parentId,
                parentName: j.parentName,
                plantId: j.plantId,
                configuration: j.configuration,
            };
        });
        return jobtypes;
        // this.setState({
        //     jobtypes,
        //     parent: this.props.editType === "child" ? this.props.editItem : null,
        //     parentType: this.props.editType === "child" ? jobtypes.filter(j => j.value === this.props.editItem.jobTypeId)?.[0] : null
        // })
    };

    render() {
        return (
            <>
                <h3>New section</h3>
                <div className="title-form">
                    <label className="title">Title</label>
                    <input
                        autoComplete="off"
                        type="text"
                        className={'input-control'}
                        name="title"
                        value={this.state.title}
                        onChange={(e) => this.onTitleChange(e.currentTarget.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="section-type">Type</label>
                    <select
                        className={'input-control'}
                        name="section-type"
                        value={this.state.sectionType}
                        onChange={(event) => this.sectionTypeSeleted(event.currentTarget.value)}
                    >
                        <option value="">Default</option>
                        <option value={'repeatersection'}>Repeater Section</option>
                    </select>
                </div>
                {this.state.sectionType && this.state.sectionType.indexOf('repeatersection') !== -1 ? (
                    <div className="form-group">
                        <label htmlFor="jobTypeRef">Job type ref</label>
                        <select
                            className={'input-control'}
                            name="jobTypeRef"
                            required={true}
                            value={this.state.jobTypeRef}
                            onChange={(e) => this.jobTypeRefSelected(e.currentTarget.value)}
                        >
                            {this.state.jobTypeRef ? null : (
                                <option key={'blank'} disabled>
                                    {' '}
                                </option>
                            )}
                            {this.state.jobTypeRefOptions.map((element) => (
                                <option key={element.value} value={element.value}>
                                    {element.label}
                                </option>
                            ))}
                        </select>
                    </div>
                ) : null}

                <div className="btn-row-grow">
                    <button
                        className="btn btn-yellow btn-grow"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleCancel();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-blue btn-grow"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleSave();
                        }}
                    >
                        Save
                    </button>
                </div>
            </>
        );
    }
}
