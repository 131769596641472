import moment from 'moment';

export const InitProcessGanttData = (props, isGanttFilterActive, parentId = null, project = null, indentLevel = 0) => {
    const result = [];

    props.forEach((data, idx) => {
        if (data.isOrphan) {
            return;
        }
        let current = new Date();
        let start = moment.utc(data.startDate).local().toDate();
        let end = moment
            .utc(data.endDate === null ? start : data.endDate)
            .local()
            .toDate();
        let progress = getProgressPercentage(current, start, end);

        let task = {
            start,
            end,
            originalEnd: data.endDate,
            originalStart: start,
            // if progress is less than 0, it means the task is not started yet, so we set the progress to 0
            progress: progress < 0 ? 0 : progress,
            name: data.type,
            id: data.id,
            type: data.endDate === null ? 'milestone' : parentId !== null ? 'task' : 'project',
            project: parentId,
            hideChildren: isGanttFilterActive ? false : true,
            dependencies: [project ? parentId : null],
            // if the task is a project, it should have at least a childCount of 1 to be able to expand and edit the project bar
            childCount: data.subRows.length === 0 ? (parentId ? 0 : 1) : data.subRows.length,
            data,
            indentLevel,
        };
        result.push(task);

        // if the task is a project, we need to add a duplicate task to show the project bar to enable it for editing
        if (task.project === null && task.type !== 'milestone') {
            let taskDup = {
                ...task,
                id: data.id + 'parent',
                originalId: data.id,
                type: data.endDate === null ? 'milestone' : parentId !== null ? 'task' : 'task',
                project: task.id,
                childCount: data.subRows.length,
            };
            result.push(taskDup);
        }

        if (data.subRows.length > 0) {
            result.push(
                ...InitProcessGanttData(data.subRows, isGanttFilterActive, data.id, task.project, indentLevel + 1)
            );
        }
    });
    return result;
};

export function getProgressPercentage(current, start, end) {
    let elapsedTime = current.getTime() - start.getTime();
    let totalTime = end.getTime() - start.getTime();
    return totalTime === 0 ? null : current.getTime() > end ? 100 : (elapsedTime / totalTime) * 100;
}

export function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
}

export const getLatestDate = (props) => {
    const latestDate = props.reduce((acc, obj) => {
        if (obj.start > acc.start) {
            return obj;
        } else {
            return acc;
        }
    });

    return latestDate.start;
};

export const getChangedTasks = (tasks, currentTask, parentTask, timeDiff = null) => {
    parentTask = { ...parentTask, id: parentTask.originalId ?? parentTask.id };

    timeDiff ??= getTimeDifference(parentTask.start, parentTask.originalStart);

    let newStartDate;
    let newEndDate;
    if (currentTask.id !== parentTask.id && !currentTask.originalId) {
        [newStartDate, newEndDate] = [setNewTime(timeDiff, currentTask.start), setNewTime(timeDiff, currentTask.end)];
    }

    let updatedTasks = [];
    const pushUpdatedTasks = (t) => {
        const index = updatedTasks.findIndex((obj) => obj.id === t.id);
        if (index !== -1) {
            updatedTasks[index] = t;
        } else {
            updatedTasks.push(t);
        }
    };

    if (newStartDate) {
        // edit task for parent operation only
        pushUpdatedTasks({
            ...currentTask,
            start: newStartDate,
            end: newEndDate,
            originalStart: newStartDate,
            originalEnd: newEndDate,
            progress: getProgressPercentage(new Date(), newStartDate, newEndDate),
            data: { ...currentTask.data, startDate: newStartDate, endDate: newEndDate },
        });
    } else {
        // edit task for children operation only
        pushUpdatedTasks({
            ...currentTask,
            originalStart: currentTask.start,
            originalEnd: currentTask.end,
            data: { ...currentTask.data, startDate: currentTask.start, endDate: currentTask.end },
        });
    }

    // recursive call for child tasks
    let childTasks = tasks.filter((t) => t.project === currentTask.originalId).filter((t, i) => i !== 0);

    if (childTasks.length === 0) {
        childTasks = tasks.filter((t) => currentTask.id === t.dependencies[0]);
    }

    childTasks.forEach((childTask) => {
        updatedTasks.push(...getChangedTasks(tasks, childTask, currentTask, timeDiff));
    });

    return updatedTasks;
};

export const getTimeDifference = (start, end) => {
    let timeDiff = new Date(start).getTime() - new Date(end).getTime();

    let diffDays;
    let diffHours;
    let diffMinutes;
    let diffSeconds;

    // calculate the time difference in days, hours, minutes, and seconds
    if (timeDiff > 0) {
        diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        diffHours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        diffMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        diffSeconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    } else {
        diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        diffHours = Math.ceil((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        diffMinutes = Math.ceil((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        diffSeconds = Math.ceil((timeDiff % (1000 * 60)) / 1000);
    }
    return { diffDays, diffHours, diffMinutes, diffSeconds };
};

export const setNewTime = (timediff, timeVal) => {
    let { diffDays, diffHours, diffMinutes, diffSeconds } = timediff;
    let date = new Date(timeVal);

    date.setDate(timeVal.getDate() + diffDays);
    date.setHours(timeVal.getHours() + diffHours);
    date.setMinutes(timeVal.getMinutes() + diffMinutes);
    date.setSeconds(timeVal.getSeconds() + diffSeconds);

    return date;
};
