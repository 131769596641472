import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import 'react-table-6/react-table.css';
import GenericTable from '../../components/common/GenericTable';
import '../../components/Widgets/Signal/Signal.scss';
import api from '../../utility/api';

class AdminPlantComponents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddTagSection: false,
            tags: [],
            visibleColumns: [
                { Header: 'Subdevice ID', accessor: 'subdeviceId', filterable: true },
                { Header: 'Parent Structure', accessor: 'parentStructure', filterable: true },
                { Header: 'Child Structure', accessor: 'childStructure', filterable: true },
                {
                    Header: 'Status',
                    accessor: 'lastPing',
                    width: 100,
                    filterable: true,
                    Cell: (row) => <div className="signal">{this.generateStatusCell(row.value)}</div>,
                },
            ],
            modalIsOpen: false,
            editMode: false,
            editTag: null,
        };

        this.fetchComponents = this.fetchComponents.bind(this);
        this.mounted = true;
    }

    generateStatusCell(value) {
        const time = moment(value);
        const timeDiff = moment.duration(moment().diff(time)).asHours();
        console.log(time, timeDiff);
        if (timeDiff < 1) {
            return (
                <div className="signal-wrapper">
                    <span className={'signal-lamp Green'}></span>
                </div>
            );
        } else if (timeDiff < 7 * 24) {
            return (
                <div className="signal-wrapper">
                    <span className={'signal-lamp Yellow'}></span>
                </div>
            );
        } else if (timeDiff > 7 * 24 && time.isAfter(moment('0001-01-02'))) {
            return (
                <div className="signal-wrapper">
                    <span className={'signal-lamp Red'}></span>
                </div>
            );
        } else {
            return (
                <div className="signal-wrapper">
                    <span className={'signal-lamp Grey'}></span>
                </div>
            );
        }
    }

    componentDidMount() {
        this.fetchComponents();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.tags) {
            this.fetchComponents();
        }
    }

    fetchComponents() {
        if (this.props.user != null) {
            api.get(process.env.REACT_APP_API + `/api/items/admin/${this.props.plantId}/componentsubdevices`)
                .then((res) => {
                    if (this.mounted) {
                        this.setState({
                            tags: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    render() {
        const tags = this.state.tags;
        const visibleColumns = this.state.visibleColumns;

        return <GenericTable enableGlobalFilter={true} data={tags} columns={visibleColumns} localPaging={true} />;
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminPlantComponents);
