import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_CHART_INFO_REQ, GET_CHART_INFO_SUCC } from '../actions';
import axios from 'axios';

export function* itemWatcherSaga() {
    yield takeLatest(GET_CHART_INFO_REQ, itemWorkerSaga);
}

function fetchChartInfo(action) {
    return axios({
        method: 'get',
        url:
            process.env.REACT_APP_API +
            '/api/items/' +
            action.itemId +
            '/metrics' +
            action.dateRange +
            (action.timeRange ? action.timeRange : '&rangeformat=hour'),
        headers: { Authorization: `Bearer ${action.token}` },
    });
}

function* itemWorkerSaga(action) {
    try {
        const response = yield call(fetchChartInfo, action);
        const data = response.data;
        response.data[action.itemId] = response.data;

        yield put({ type: GET_CHART_INFO_SUCC, data });
    } catch (err) {
        console.log(err);
    }
}
