export function datapoints_source_validate(datapoints, types, value, onChange) {
    const available_sources = datapoints.filter((element) => types.includes(element.type));

    if (available_sources.length > 0) {
        if (!available_sources.find((element) => element.datapointId === value))
            onChange(available_sources[0].datapointId);
    }

    return available_sources;
}

export function datapoints_get_available_customtable_fields(datapoints, value) {
    let available_fields = [];
    const datapoint = datapoints.find((element) => element.datapointId === value);

    if (datapoint && datapoint.type === 'childrentable') {
        datapoint.data.forEach((element) => {
            available_fields.push({ key: element.datapointId, label: element.datapointId });
        });
        Object.keys(datapoint.metadata).forEach((element) => {
            switch (datapoint.metadata[element]) {
                case '@endTime':
                case '@startTime':
                    available_fields.push({ key: element + '#time#HH:mm', label: element });
                    break;
                case '@totalTime':
                    available_fields.push({ key: element + '#duration', label: element });
                    break;
                default:
                    available_fields.push({ key: element, label: element });
                    break;
            }
        });
    }

    if (datapoint && datapoint.type === 'processnotes') {
        available_fields = [
            { key: 'Time#time#DD/MM/YY HH:mm', label: 'Time' },
            { key: 'Work', label: 'Work' },
            { key: 'Value', label: 'Comment' },
        ];
    }

    return available_fields;
}
