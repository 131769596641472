import React from 'react';

import { ComponentCollectionEditor } from './utils/DatapointsUtils';
import { validate_jobtype, meta_editor, get_parent_metadata } from './utils/MetaUtils';
import { ReactComponent as DeleteIcon } from '../../../images/new/optimar_cross.svg';

const datapoint_type_name = 'Valuelist';
const datapoint_sources = undefined; //undefined = all sources supported.
// {
//     events: "Events"
// };
const new_datapoint = {
    datapointId: 'valuelistDatapoint',
    type: 'processnotes',
    // "componentId": "",
    // "datavalue": "",
    componentSettings: [],
    childrenTypes: [],
};

export default () => {
    return {
        name: datapoint_type_name,
        new_datapoint: new_datapoint,
        get_editor: get_editor,
        get_details: get_details,
    };
};

function get_editor(data, onInputChange, props) {
    if (!data.childrenTypes) onInputChange('childrenTypes', []);
    if (!data.componentSettings) onInputChange('componentSettings', []);

    const delete_child = (index) => {
        data.childrenTypes.splice(index, 1);
        onInputChange('childrenTypes', data.childrenTypes);
    };

    const add_new_child = () => {
        data.childrenTypes.push({
            Label: {
                'Job Name': 'meta_field',
            },
        });
        onInputChange('childrenTypes', data.childrenTypes);
    };

    const on_child_change = (index, name, value) => {
        const label = Object.keys(data.childrenTypes[index])[0];
        switch (name) {
            case 'label':
                data.childrenTypes[index][value] = data.childrenTypes[index][label];
                delete data.childrenTypes[index][label];
                break;
            case 'jobtype':
                // eslint-disable-next-line no-case-declarations
                const old_type = Object.keys(data.childrenTypes[index][label])[0];
                data.childrenTypes[index][label][value] = data.childrenTypes[index][label][old_type];
                delete data.childrenTypes[index][label][old_type];
                break;
            case 'meta':
                // eslint-disable-next-line no-case-declarations
                const type = Object.keys(data.childrenTypes[index][label])[0];
                data.childrenTypes[index][label][type] = value;
                break;
            default:
                break;
        }
        onInputChange('childrenTypes', data.childrenTypes);
    };

    return (
        <>
            <br />
            {data.childrenTypes.map((ctype, index) => {
                const label = Object.keys(ctype)[0];
                const jobtype = Object.keys(ctype[label])[0];
                const meta = ctype[label][jobtype];

                const available_metas = validate_jobtype(
                    jobtype,
                    props.child_jobs,
                    (name, value) => {
                        on_child_change(index, name, value);
                    },
                    'jobtype'
                );
                const parent_metas = get_parent_metadata(jobtype, props.job_type, props.child_jobs);

                return (
                    <fieldset className="ctype-fieldset" key={'ctype' + index}>
                        <legend>Level {index + 1}</legend>
                        <DeleteIcon
                            onClick={() => {
                                delete_child(index);
                            }}
                            className="delete-icon"
                        />
                        <div className="form-group">
                            <label htmlFor="label">Label</label>
                            <input
                                type="text"
                                name="label"
                                value={label}
                                className="input-control"
                                onChange={(event) =>
                                    on_child_change(index, event.currentTarget.name, event.currentTarget.value)
                                }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="jobtype">Operation Type</label>
                            <select
                                required
                                name="jobtype"
                                className={'input-control'}
                                onChange={(event) =>
                                    on_child_change(index, event.currentTarget.name, event.currentTarget.value)
                                }
                                value={jobtype}
                            >
                                {props.child_jobs.map((element, index) => {
                                    return (
                                        <option key={'j' + (index + 1)} value={element.name}>
                                            {element.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        {meta_editor(
                            (name, value) => {
                                on_child_change(index, name, value);
                            },
                            meta,
                            available_metas,
                            parent_metas,
                            []
                        )}
                    </fieldset>
                );
            })}
            <button className="btn btn-blue" onClick={add_new_child}>
                Add child
            </button>
            {/* <ComponentEditor onInputChange={onInputChange} datapoint_sources={datapoint_sources} data={data} items={props.items} getDatavaluesFunc={props.getDatavaluesFunc} child_jobs ={props.child_jobs}/> */}

            <ComponentCollectionEditor
                onInputChange={onInputChange}
                datapoint_sources={datapoint_sources}
                data={data}
                items={props.items}
                getDatavaluesFunc={props.getDatavaluesFunc}
                child_jobs={props.child_jobs}
            />

            {/* {component_editor(onInputChange, datapoint_sources, data, props.items, props.datavalues, props.child_jobs)} */}
        </>
    );
}

function get_details(data) {
    return (
        <ul className="datapoint_details">
            <li>Children Levels: {data.childrenTypes.length}</li>
            <li>
                Data: {data.componentId} - <i>{data.datavalue}</i>
            </li>
        </ul>
    );
}
