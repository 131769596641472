import { combineReducers } from 'redux';

import title from './title.reducer';
import plants from './reducer.plants';
import user from './reducer.user';
import itemrow from './reducer.itemrow';
import reports from './reducer.reports';
import notes from './reducer.notes';
import map from './reducer.map';
import documents from './reducer.documents';
import utility from './reducer.utility';
import notifications from './reducer.notifications';
import account from './reducer.account';
import items from './items.reducer';

const rootReducer = combineReducers({
    title,
    plants,
    user,
    itemrow,
    reports,
    notes,
    map,
    documents,
    utility,
    notifications,
    account,
    items,
});

export default rootReducer;
