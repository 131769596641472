import React from 'react';
import Modal from 'react-modal';

import { confirmAlert } from 'react-confirm-alert';

import { ContentEditor } from './utils/ValueUtils';

const item_type_name = 'Datapoint Table';
const configurable = true;

export default () => {
    return {
        name: item_type_name,
        configurable: configurable,
        create_new: create_new,
        get_editor,
    };
};

function get_editor(data, onInputChange, props) {
    const columns_no = Math.max(...data.content.map((row) => row.length));

    const addNewRow = (index) => {
        data.content.splice(index, 0, Array(columns_no).fill({}));
        onInputChange('content', data.content);
    };

    const deleteRow = (index) => {
        data.content.splice(index, 1);
        onInputChange('content', data.content);
    };

    const addNewColumn = (index) => {
        data.content.forEach((row) => {
            row.splice(index, 0, {});
        });
        onInputChange('content', data.content);
    };

    const deleteColumn = (index) => {
        if (columns_no > 1) {
            data.content.forEach((row) => {
                row.splice(index, 1);
            });
            onInputChange('content', data.content);
        }
    };

    const onDrag = (x, y) => {
        if (!data.drag) {
            data.drag = {
                x,
                y,
                start: { x, y },
                initial: JSON.parse(JSON.stringify(data.content)),
            };
            onInputChange('drag', data.drag);
        }
    };

    const onDragOver = (event, x, y) => {
        let changes = false;

        // do nothing if drop in same position
        if (data.drag.x === x && data.drag.y === y) {
            event.preventDefault();
        }
        // reorder elements if drop in the same row
        else if (data.drag.x === x) {
            event.preventDefault();

            const this_cell = JSON.stringify(data.content[data.drag.x][data.drag.y]);
            data.content[data.drag.x].splice(data.drag.y, 1);
            data.content[data.drag.x].splice(y, 0, JSON.parse(this_cell));

            data.drag = {
                ...data.drag,
                x,
                y,
            };
            onInputChange('drag', data.drag);
            changes = true;
        }
        // look for empty space if drop on another row
        else
            for (const cell_idx in data.content[x]) {
                const cell_no = parseInt(cell_idx);
                // if empty space
                if (!data.content[x][cell_no].content) {
                    event.preventDefault();

                    data.content[x][cell_no] = JSON.parse(JSON.stringify(data.content[data.drag.x][data.drag.y]));

                    data.drag = {
                        ...data.drag,
                        x,
                        y: cell_no,
                    };
                    onInputChange('drag', data.drag);
                    changes = true;
                    break;
                }
            }

        // fix untouched rows
        if (changes) {
            for (const row_idx in data.content) {
                const row_no = parseInt(row_idx);

                if (data.drag.x !== row_no) {
                    data.content[row_no] = JSON.parse(JSON.stringify(data.drag.initial[row_no]));

                    if (row_no === data.drag.start.x) data.content[data.drag.start.x][data.drag.start.y] = {};
                }
            }

            onInputChange('content', data.content);
        }
    };

    const onDragEnd = () => {
        onInputChange('drag', undefined);
    };

    const openDatapointEditModal = (x, y) => {
        data.edit_modal = {
            x: x,
            y: y,
            open: true,
        };

        onInputChange('edit_modal', data.edit_modal);
    };

    const closeDatapointEditModal = () => {
        onInputChange('edit_modal', undefined);
    };

    const saveDatapointEditModal = (new_data) => {
        data.content[data.edit_modal.x][data.edit_modal.y] = new_data;
    };

    const add_new_row_table_row = (index) => {
        return (
            <tr>
                <td
                    colSpan={columns_no}
                    key={'nr_' + index}
                    title="Add New Row"
                    className="datapointtable-newrow"
                    onClick={() => addNewRow(index)}
                >
                    <span className="fa fa-plus-circle" style={{ color: 'green' }}></span>
                </td>
            </tr>
        );
    };

    const showAsSelect = (
        <div className="form-group">
            <label htmlFor="display">Display</label>
            <select
                className={'input-control'}
                name="display"
                value={data.display}
                required={false}
                onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
            >
                <option value="">Header and value</option>
                <option value={'table'}>Table</option>
            </select>
        </div>
    );
    const tableStyleSelect = (
        <div className="form-group">
            <label htmlFor="tableStyle">Table style</label>
            <select
                className={'input-control'}
                name="tableStyle"
                value={data.tableStyle}
                required={false}
                onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
            >
                <option value={''}>No headers</option>
                <option value="rowandcolasheader">First row and column as headers</option>
                <option value="rowisheader">First row as header</option>
            </select>
        </div>
    );

    const del_row_table_cell = (index) => {
        return data.content.length > 1 ? (
            <td className="datapointtable-del" key={'dr_' + index}>
                <span
                    className="fa fa-minus-circle"
                    title="Delete Row"
                    style={{ color: 'red' }}
                    onClick={() => deleteRow(index)}
                ></span>
            </td>
        ) : null;
    };

    const add_del_col_table_row = (
        <tr>
            {[...Array(columns_no)].map((_v, column_index) => {
                return (
                    <td className="datapointtable-newcol" key={'nc_' + column_index}>
                        <div className="add-left">
                            <span
                                className="fa fa-plus-circle"
                                style={{ color: 'green' }}
                                title="Add New Column to the Left"
                                onClick={() => addNewColumn(column_index)}
                            ></span>
                        </div>
                        <div className="del-col">
                            {columns_no > 1 ? (
                                <span
                                    className="fa fa-minus-circle"
                                    title="Delete Column"
                                    style={{ color: 'red' }}
                                    onClick={() => deleteColumn(column_index)}
                                ></span>
                            ) : (
                                <span></span>
                            )}
                        </div>
                        <div className="add-right">
                            <span
                                className="fa fa-plus-circle"
                                style={{ color: 'green' }}
                                title="Add New Column to the Right"
                                onClick={() => addNewColumn(column_index + 1)}
                            ></span>
                        </div>
                    </td>
                );
            })}
        </tr>
    );

    const table_cell = (row_index, column_index) => {
        const value = data.content[row_index][column_index];
        return (
            <td
                className="datapointtable-cell"
                key={'dc_' + row_index + '_' + column_index}
                onClick={() => openDatapointEditModal(row_index, column_index)}
                draggable={value.content ? true : false}
                onDrag={() => onDrag(row_index, column_index)}
                onDragOver={(event) => onDragOver(event, row_index, column_index)}
                onDragEnd={onDragEnd}
            >
                <div className="datapointtable-cell-content">
                    <div>
                        {value.content ? (
                            value.title ? (
                                <b>{value.title}</b>
                            ) : (
                                <b>
                                    <i>No title</i>
                                </b>
                            )
                        ) : (
                            <i>Empty</i>
                        )}
                    </div>
                </div>
            </td>
        );
    };

    return (
        <>
            {showAsSelect}
            {data.display === 'table' ? tableStyleSelect : null}
            <div className="form-group">
                <table className="datapointtable">
                    <tbody>
                        {add_del_col_table_row}
                        {add_new_row_table_row(0)}
                        {data.content.map((row, row_index) => {
                            return (
                                <React.Fragment key={'frg_1_' + row_index}>
                                    <tr>
                                        {row.map((_value, column_index) => {
                                            return table_cell(row_index, column_index);
                                        })}
                                        {del_row_table_cell(row_index)}
                                    </tr>
                                    {add_new_row_table_row(row_index + 1)}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Modal
                isOpen={data.edit_modal?.open}
                onRequestClose={closeDatapointEditModal}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                className={'modal_content'}
            >
                <CellEditor
                    data={
                        data.edit_modal
                            ? JSON.parse(JSON.stringify(data.content[data.edit_modal.x][data.edit_modal.y]))
                            : {}
                    }
                    closeCallback={closeDatapointEditModal}
                    saveCallback={saveDatapointEditModal}
                    job_type={props.job_type}
                    child_jobs={props.child_jobs}
                    datapoints={props.datapoints}
                />
            </Modal>
        </>
    );
}

function create_new() {
    return {
        type: 'datapointtable',
        content: [[{}, {}, {}]],
    };
}

function CellEditor(props) {
    const [data, setData] = React.useState(props.data);
    const [changed, setChanged] = React.useState(false);

    const onCancel = () => {
        if (changed)
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body">
                            <h1>Cancel editing datapoint</h1>
                            <p>
                                There are unsaved changes
                                <br />{' '}
                            </p>
                            <p>Are you sure you want to cancel editing?</p>
                            <div className="buttons">
                                <button className="btn btn-yellow" onClick={onClose}>
                                    No
                                </button>
                                <button
                                    className="btn btn-red"
                                    onClick={async () => {
                                        props.closeCallback();
                                        onClose();
                                    }}
                                >
                                    Yes, discard
                                </button>
                            </div>
                        </div>
                    );
                },
            });
        else props.closeCallback();
    };

    const onSave = () => {
        props.saveCallback(data);
        props.closeCallback();
    };

    const onInputChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
        setChanged(true);
    };

    const clearCell = () => {
        if (data.hasOwnProperty('content') || data.hasOwnProperty('emptycellHeight')) {
            delete data.content;
            delete data.values;
            data.title = '';
        } else {
            data.content = '{1}';
            data.values = {
                1: {
                    type: 'meta',
                    values: '',
                },
            };
        }

        setData({ ...data });
        setChanged(true);
    };

    return (
        <>
            <h2>Edit datapoint</h2>
            <div className="form-group">
                <label htmlFor="empty">Empty cell</label>
                <input
                    autoComplete="off"
                    type="checkbox"
                    className={'input-control'}
                    name="empty"
                    checked={!data.hasOwnProperty('content')}
                    required={true}
                    onChange={clearCell}
                />
            </div>
            {data.hasOwnProperty('content') ? (
                <>
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="title"
                            value={data.title}
                            required={true}
                            onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                        />
                    </div>
                    <ContentEditor
                        onInputChange={onInputChange}
                        data={data}
                        job_type={props.job_type}
                        child_jobs={props.child_jobs}
                        datapoints={props.datapoints}
                    />
                </>
            ) : null}
            <div className="buttons">
                <button onClick={onCancel} className="btn btn-yellow">
                    Cancel
                </button>
                <button onClick={onSave} className="btn btn-blue">
                    Save
                </button>
            </div>
        </>
    );
}
