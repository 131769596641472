import { noop, toInteger } from 'lodash';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getDashboardConfig, getWidget, setDashboardConfig, setWidget } from '../../../actions';
import NavConfig from '../../../components/common/Nav/NavConfig';
import NavContainer from '../../../components/common/Nav/NavContainer';
import api from '../../../utility/api';
import { DataValueSelect } from '../../../utility/datavalueSelect';
import { newGuid } from '../../../utility/guidFunctions';
import { defaultFormula } from '../../../utility/widgetformulamanager';
import AvailableWidgets from './AvailableWidgets';
import ComponentSettings from './ComponentSettings';
import './DashboardEditPane.scss';
import DashboardEditPaneContext from './DashboardEditPaneContext';
import DistributionBuckets from './DistributionBuckets';

const EditPaneContext = React.createContext([{}, noop]);
class DashboardEditPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            widget: null,
            selectedOption: [],
            success: null,
            supported: true,
            multirange: false,
            historic: false,
            eventMode: false,
            traceMode: (this.props.widget || {}).traceEnabled || false,
            fetchingDataValues: false,
            dataValues: [],
            distributionBuckets: [],
            showFormulas: false,
            widgetFormulas: [],
            editPaneDataCache: new DashboardEditPaneContext(),
        };
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleMultirange = this.handleMultirange.bind(this);
        this.handleEventMode = this.handleEventMode.bind(this);
        this.setComponentSettingsStateFromSub = this.setComponentSettingsStateFromSub.bind(this);
        this.setDistributionBucketsStateFromSub = this.setDistributionBucketsStateFromSub.bind(this);
        this.addNewComponent = this.addNewComponent.bind(this);
        this.addNewBucket = this.addNewBucket.bind(this);
        this.deleteMultiRangeComponent = this.deleteMultiRangeComponent.bind(this);
        this.deleteDistributionBucket = this.deleteDistributionBucket.bind(this);
        this.handleTraceMode = this.handleTraceMode.bind(this);
        this.fetchWidgetFormulas = this.fetchWidgetFormulas.bind(this);
        this.setWidgetFormula = this.setWidgetFormula.bind(this);
    }

    componentDidMount() {
        // console.log("WIDGET ON LOAD: ", this.props);
        this.fetchWidgetFormulas();
        this.fetchWidgetForms().then(() => {
            if (!this.props.accountMode) {
                this.fetchItemStructure(this.props.plantId);
            } else {
                this.getAccountPlants();
                if (this.props.widget && this.props.widget.plant) {
                    this.fetchItemStructure(this.props.widget.plant);
                }
            }
            if (this.props.widget && this.state.supported) {
                this.props.getDashboardConfig();

                const widget = Object.assign({}, this.props.widget);
                if (this.props.widget.configuration && !this.props.widget.configuration.formula) {
                    widget.configuration.formula = defaultFormula;
                }

                this.setState(
                    {
                        selectedOption: this.props.widget.components
                            ? this.props.widget.components.map((c) => {
                                  return { label: c, value: c, topic: c };
                              })
                            : null,
                        widget: widget,
                        traceMode: this.props.widget.traceEnabled,
                        historic:
                            this.props.widget.type === 'linechart' || this.props.widget.type === 'histogram'
                                ? true
                                : false,
                    },
                    () => {
                        this.fetchDataValues(this.state.selectedOption);
                    }
                );
            }
        });
    }

    componentDidUpdate(prevProps) {
        // console.log("CHANGE", this.state);
        if (this.props.widget && JSON.stringify(this.props.widget) !== JSON.stringify(prevProps.widget)) {
            this.fetchWidgetForms();
            if (!this.props.accountMode) {
                this.fetchItemStructure(this.props.plantId);
            } else {
                if (this.props.widget && this.props.widget.plant) {
                    this.fetchItemStructure(this.props.widget.plant);
                }
            }
            this.props.getDashboardConfig();

            if (this.props.widget) {
                this.setState(
                    {
                        selectedOption: this.props.widget.components.map((c) => {
                            return { label: c, value: c, topic: c };
                        }),
                        widget: this.props.widget,
                        historic:
                            this.props.widget.type === 'linechart' || this.props.widget.type === 'histogram'
                                ? true
                                : false,
                    },
                    () => {
                        this.fetchDataValues(this.state.selectedOption);
                    }
                );
            }
        }
    }

    fetchWidgetFormulas() {
        api.get('api/widgetformulas').then((res) => {
            this.setState({
                widgetFormulas: res.data,
            });
        });
    }

    getAccountPlants() {
        api.get('api/plants').then((res) => {
            const sortedPlants = res.data.sort((a, b) => {
                if (a.name > b.name) return 1;
                return -1;
            });
            this.setState({ accountPlants: sortedPlants }, () => this.fetchItemStructure(sortedPlants[0].id));
        });
    }

    async fetchDataValues(items) {
        //return (<div><SimpleSpinner size={"1x"}></SimpleSpinner></div>)
        if (items && items.length > 0) {
            if (!this.state.fetchingDataValues) this.setState({ fetchingDataValues: true });
            try {
                const apiUrl =
                    process.env.REACT_APP_API +
                    '/api/items/datavalues?accountMode=' +
                    this.props.accountMode +
                    '&itemKeys=' +
                    encodeURIComponent(items.map((value) => value.topic));
                const res = await api.get(apiUrl);
                //console.log(apiUrl);
                this.setState({
                    fetchingDataValues: false,
                    datavalues: res.data,
                });
                //   .then(res => this.setState({ datavalues: res.data }))
                //.catch(err => {
                // this.setState({ datavalues: [] })
                // console.error("ERROR: ", err);
                //  });
            } catch (err) {
                this.setState({
                    fetchingDataValues: false,
                    datavalues: [],
                });
                console.error('ERROR: ', err);
            }
        } else {
            this.setState({ datavalues: [] });
        }
    }

    fetchItemStructure(plantId) {
        api.get(process.env.REACT_APP_API + '/api/plants/' + plantId + '/items/structure')
            .then((res) => this.setState({ items: res.data }))
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    fetchWidgetForms() {
        return new Promise((resolve, reject) => {
            api.get(process.env.REACT_APP_API + '/api/dashboard/widgetforms')
                .then((res) => {
                    let widgetform = null;

                    if (this.props.widget) {
                        widgetform = res.data.filter((w) => w.Name === this.props.widget.type);
                    }
                    this.setState(
                        {
                            widgettypes: res.data,
                            widgetform: widgetform && widgetform[0] ? JSON.parse(widgetform[0].Form) : null,
                            supported: widgetform && widgetform[0] ? true : false,
                        },
                        () => resolve()
                    );
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                    reject();
                });
        });
    }

    confirmDeleteWidget(e) {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete widget</h1>
                        <p>
                            You are about to delete the widget
                            <br />{' '}
                        </p>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.saveWidgetToConfig(e, 'delete');
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    // uuidv4() {
    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    //         // eslint-disable-next-line
    //         var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    //         return v.toString(16);
    //     });
    // }

    validateWidgetConfig(widget) {
        //When using multirange components, all components must have a name.. (seriesname..)
        //WHen using the range endpoint, all data is returned as a single list, and then grouped on the client by seriesname.
        let isValid = true;
        let validMsg = '';

        const seriesNames = {};
        if (widget.componentSettings && widget.componentSettings.length > 1) {
            widget.componentSettings.forEach((cs) => {
                seriesNames[cs.name || 'na'] = 1;
            });
            if (Object.keys(seriesNames).length !== widget.componentSettings.length) {
                validMsg += ' Component names must be unique.';
                isValid = false;
            }
        }

        //console.log(seriesNames,widget.componentSettings);
        return { isValid, validMsg };
    }

    saveWidgetToConfig(e, action) {
        e.preventDefault();

        const { isValid, validMsg } = this.validateWidgetConfig(this.state.widget);
        if (!isValid) {
            NotificationManager.warning(
                'Widget configuration validation failed. ' + validMsg,
                'Widget validation failed',
                5000
            );
            return;
        }

        this.setState({ saving: true });

        const dashboardConfig = Object.assign({}, this.props.dashboardConfig);
        let configWidget = null;

        if (action === 'delete') {
            dashboardConfig.configuration[this.props.row].widgets.splice(this.props.column, 1);
        } else if (action === 'edit') {
            configWidget = this.findWidget(dashboardConfig.configuration, this.props.widget.wId);

            Object.assign(configWidget, this.state.widget);
            //remove data attribute
            delete configWidget.data;
        } else {
            //new
            dashboardConfig.configuration[this.props.row].widgets.splice(this.props.column, 0, this.state.widget);
        }

        api.put(process.env.REACT_APP_API + '/api/dashboard/' + this.props.dashboardId, dashboardConfig)
            .then(() => {
                this.setState({
                    success: true,
                    saving: false,
                });
                this.props.setWidget();
                this.props.setDashboardConfig(dashboardConfig);
                this.props.closeWidgetModal();

                NotificationManager.success(
                    'Widget ' + (action === 'delete' ? 'removed' : 'updated') + ' successfully',
                    'Widget ' + (action === 'delete' ? 'removed' : 'updated'),
                    5000
                );
            })
            .catch((err) => {
                console.error('ERROR: ', err);
                this.setState({
                    success: false,
                    saving: false,
                });

                NotificationManager.error('Widget not updated successfully', 'Error updating widget', 5000);
            });
    }

    findWidget(configuration, wId) {
        // eslint-disable-next-line
        for (const row of configuration) {
            // eslint-disable-next-line
            for (const widget of row.widgets) {
                if (widget.wId === wId) return widget;
            }
        }
    }

    addNewWidget(type) {
        let historic = false;
        if (type === 'histogram' || type === 'linechart') historic = true;

        const widgetform = this.state.widgettypes?.filter((w) => w.Name === type);

        const widget = JSON.parse(widgetform[0].Form);
        widget.wId = newGuid().substring(0, 8); //this.uuidv4().substring(0, 8);
        this.setState({
            widget,
            widgetform: JSON.parse(widgetform[0].Form),
            historic,
        });
    }

    handleMultirange() {
        const multirange = !this.state.multirange;

        const tmp = Object.assign({}, this.state.widget);
        tmp.valueFormat = multirange ? 'multirange' : '';

        this.setState({
            multirange: multirange,
            widget: tmp,
        });
    }

    handleEventMode() {
        const eventMode = !this.state.eventMode;

        const tmp = Object.assign({}, this.state.widget);
        tmp.valueFormat = eventMode ? 'eventmode' : '';

        this.setState(
            {
                eventMode: eventMode,
                widget: tmp,
            },
            this.fetchDataValues()
        );
    }

    handleTraceMode() {
        const traceMode = !this.state.traceMode;

        const tmp = Object.assign({}, this.state.widget);
        tmp.valueFormat = traceMode ? 'tracemode' : '';
        tmp.traceEnabled = traceMode;

        this.setState(
            {
                traceMode: traceMode,
                widget: tmp,
            },
            this.fetchDataValues()
        );
    }

    handleMultiSelectChange(items) {
        this.fetchDataValues(items);

        const tmp = Object.assign({}, this.state.widget);
        tmp.components = items ? items.map((value) => value.topic) : [];

        if (items) {
            const dataTypes = items.map((item) => {
                const currentItem = this.state.items.find((i) => i.topic === item.topic);
                return currentItem ? currentItem.dataType : null;
            });

            tmp.dataType = dataTypes.filter((x) => x)[0];
        }

        this.setState({
            selectedOption: items,
            widget: tmp,
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = Object.assign({}, this.state.widget);
        const configparams = [
            'limit',
            'minValue',
            'maxValue',
            'unit',
            'formula',
            'idealCycleTime',
            'runningMode',
            'expectedWeight',
            'toDevice',
            'locationId',
            'uniqueKey',
            'numericPrecision',
            'dType',
            'seriesName',
        ];
        if (configparams.indexOf(name) > -1) {
            tmp.configuration[name] = value;

            // let thresholds = tmp.configuration.threshold;
            // console.log("T", thresholds);
            // if (thresholds && (thresholds.warningLow === null || thresholds.alertLow === null || thresholds.warningHigh === null || thresholds.alertHigh === null)) {
            //     thresholds.warningLow = tmp.configuration.minValue;
            //     thresholds.alertLow = tmp.configuration.minValue;
            //     thresholds.warningHigh = tmp.configuration.maxValue;
            //     thresholds.alertHigh = tmp.configuration.maxValue;
            // }
        } else if (name === 'warningLow' || name === 'alertLow' || name === 'warningHigh' || name === 'alertHigh') {
            tmp.configuration.threshold[name] = value;
        } else if (name === 'name') {
            tmp.configuration[name] = value;
        } else if (name === 'payloadName' || name === 'payloadDescr') {
            tmp.payload[name] = value;
        } else {
            tmp[name] = value;
        }
        this.setState({ widget: tmp });
    }

    onDataValueChanged(selectedOption) {
        const tmp = Object.assign({}, this.state.widget);
        tmp.dataValue = selectedOption.value;
        if (this.state.widget.type === 'lamp') {
            if (!selectedOption.value.includes('event')) tmp.valueFormat = 'single';
        }

        this.setState({
            widget: tmp,
        });
    }

    onSelectChanged(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = Object.assign({}, this.state.widget);

        if (name.startsWith('color')) {
            tmp.configuration.color[toInteger(name.charAt(name.length - 1))] = value;
        } else {
            tmp[name] = value;
        }

        if (name === 'aggMethod') {
            tmp.configuration[name] = value;
        }
        if (name === 'mode') {
            tmp.configuration[name] = value;
        }

        if (name === 'dType') {
            tmp.configuration[name] = value;
        }

        if (name === 'dataValue') {
            tmp.histDataValue = (this.state.datavalues.filter((d) => d.dataValue === target.value) || {}).histDataValue;
        }

        if (name === 'type') {
            const widgetform = this.state.widgettypes?.filter((w) => w.Name === value);

            if (this.props.mode === 'NEW') {
                this.setState({
                    widget: JSON.parse(widgetform[0].Form),
                    widgetform: JSON.parse(widgetform[0].Form),
                });
            } else {
                this.setState({
                    widgetform: JSON.parse(widgetform[0].Form),
                });
            }
        }

        if (name === 'plant') {
            this.fetchItemStructure(value);
        }

        this.setState({
            widget: tmp,
        });
    }

    handleCheckboxChange(event) {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        const tmp = Object.assign({}, this.state.widget);

        if (name === 'customunit') {
            if (!tmp.configuration) {
                tmp.configuration = { usePayloadUnit: value };
            } else {
                tmp.configuration.usePayloadUnit = value;
            }
        } else if (
            name === 'toDevice' ||
            name === 'showSub' ||
            name === 'showClosed' ||
            name === 'useInterpolation' ||
            name === 'showStd'
        ) {
            tmp.configuration[name] = value;
        } else {
            tmp[name] = value;
        }

        //console.log("changing "+name)
        this.setState({ widget: tmp });
    }

    setComponentSettingsStateFromSub(componentSettings) {
        const tmp = Object.assign({}, this.state.widget);
        tmp.componentSettings = componentSettings;

        this.setState({ widget: tmp });
    }
    setDistributionBucketsStateFromSub(buckets) {
        const tmp = Object.assign({}, this.state.widget);
        tmp.distributionBuckets = buckets;

        this.setState({ widget: tmp });
    }

    addNewComponent() {
        const tmp = Object.assign({}, this.state.widget);

        if (!tmp.componentSettings) {
            tmp.componentSettings = [];
        }

        tmp.componentSettings.push({
            name: `name${tmp.componentSettings.length === 0 ? '' : tmp.componentSettings.length + 1}`,
            topic: '',
            dataValue: '',
            dataType: '',
            unit: '',
            varName: `val${tmp.componentSettings.length === 0 ? '' : tmp.componentSettings.length + 1}`,
            aggMethod: `mean`,
        });

        this.setState({ widget: tmp });
    }

    addNewBucket() {
        const tmp = Object.assign({}, this.state.widget);

        if (!tmp.distributionBuckets) {
            tmp.distributionBuckets = [];
        }

        tmp.distributionBuckets.push({
            name: '',
            rangeMin: 0,
            rangeMax: 0,
        });

        this.setState({ widget: tmp });
    }

    deleteMultiRangeComponent(index) {
        const tmp = Object.assign({}, this.state.widget);
        tmp.componentSettings.splice(index, 1);
        this.setState({
            widget: tmp,
        });
    }

    deleteDistributionBucket(index) {
        const tmp = Object.assign({}, this.state.widget);
        tmp.distributionBuckets.splice(index, 1);
        this.setState({
            widget: tmp,
        });
    }

    setWidgetFormula(formula) {
        const tmp = Object.assign({}, this.state.widget);
        tmp.configuration.formula = formula;
        this.setState({ widget: tmp });
    }

    isMultiRange(widget) {
        if (
            (widget || {}).valueFormat === 'multirange' ||
            (widget || {}).type === 'opticontrol' ||
            (widget || {}).type === 'linechart' ||
            (widget || {}).type === 'datavalues-log-list' ||
            (widget || {}).type === 'link-button-group' ||
            (widget || {}).type === 'single-cell' ||
            (widget || {}).type === 'distribution' ||
            (widget || {}).valueFormat === 'heatmap'
        )
            return true;

        return false;
    }

    render() {
        const form = this.state.widgetform;

        if (!form && this.props.mode !== 'NEW') {
            return (
                <div>
                    <NavContainer
                        path={this.props.match}
                        item={this.state.currentItem}
                        config={null}
                        defaultConfig={NavConfig.dashboardeditpane}
                        plant={null}
                    />
                    <h3>Loading...</h3>

                    <div className="btn-row">{this.getCloseWidgetModalBtn()}</div>
                </div>
            );
        }
        // if (this.props.mode !== "NEW") {
        //     return <div>
        //         <NavContainer path={this.props.match} item={this.state.currentItem} config={null} defaultConfig={NavConfig.dashboardeditpane} plant={null} />
        //         <h3>Component not supported</h3>

        //         <div className="btn-row">
        //             {this.getCloseWidgetModalBtn()}
        //         </div>
        //     </div>;
        // }

        //console.log(this.state.widget)
        return (
            <div className="dashboard-edit-pane">
                {this.props.mode === 'NEW' && !this.state.widgetform ? (
                    <AvailableWidgets
                        closeWidgetModal={(e) => this.props.closeWidgetModal(e)}
                        addNewWidget={(widget) => this.addNewWidget(widget)}
                    />
                ) : (
                    <>
                        <NavContainer
                            path={this.props.match}
                            item={this.state.currentItem}
                            config={null}
                            defaultConfig={NavConfig.dashboardeditpane}
                            plant={null}
                        />
                        <div>
                            {this.props.widget || this.props.mode === 'NEW' ? (
                                <div>
                                    <div className="form-container">
                                        <form
                                            className="widget-form"
                                            onSubmit={(e) => {
                                                this.saveWidgetToConfig(e, this.props.mode === 'NEW' ? 'new' : 'edit');
                                            }}
                                        >
                                            {this.getWidgetLabelControl(form)}
                                            {this.getWidgetTypeControl(form)}

                                            {/* {this.getTracemodeForLinechartControl(form)} */}
                                            {/* {this.getTracemodeForDistributionControl(form)} */}

                                            {this.getWidthControl(form)}
                                            {this.getPlantControl(form)}
                                            {this.getComponentsControl(form)}
                                            {this.getComponentControl(form)}
                                            {this.getDataValueControl(form)}
                                            {this.getAggMethodSelectorControl(form)}
                                            {this.getSeriesNameControl(form)}
                                            {this.getDistributionBucketControl(form)}
                                            {/* {this.getValueformatControlForSingleValue(form)} */}
                                            {this.getCalculationControl(form)}
                                            {this.getMinMaxControl(form)}
                                            {this.getFormulaControl(form)}
                                            {this.getLampNameControl(form)}

                                            {this.getUnitControl(form)}
                                            {this.getNumericPrecisionControl(form)}
                                            {this.getInputTypeControl(form)}
                                            {this.getDefaultPeriodControl(form)}
                                            {this.getThresholdControl(form)}
                                            {this.getEventsModeControl(form)}
                                            {this.getHistoryDisabledControl(form)}
                                            {this.getUseInterpolationForLineChartControl(form)}
                                            {this.getUseInterpolationForHistoryControl(form)}
                                            {this.getShowSubControl(form)}
                                            {this.getShowClosedControl(form)}
                                            {this.getLimitControl(form)}
                                            {this.getTextSizeControl(form)}
                                            {this.getHorAlignControl(form)}
                                            {this.getVerAlignControl(form)}
                                            {this.getGradientControl(form)}
                                            {this.getColorControl(form)}
                                            {this.getModeControl(form)}
                                            {this.getIdealCycleTimeControl(form)}
                                            {this.getRunningModeControl(form)}
                                            {this.getExpectedWeightControl(form)}
                                            {this.getToDeviceControl(form)}
                                            {this.getShowStandardDeviationControl(form)}
                                            {this.getPayloadControl(form)}
                                            {this.getLocationIdControl(form)}
                                            {this.getUniqueKeyControl(form)}

                                            <button
                                                className="btn btn-red btn-full-width"
                                                type="button"
                                                onClick={(e) => this.confirmDeleteWidget(e)}
                                            >
                                                Delete widget
                                            </button>

                                            <div className="btn-row">
                                                {this.getCloseWidgetModalBtn()}
                                                {this.getSaveWidgetModalBtn()}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                <h3>No component selected</h3>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }

    getWidgetLabelControl(form) {
        return (
            <>
                {' '}
                {Object.prototype.hasOwnProperty.call(form, 'title') ? (
                    <div className="form-group">
                        <label htmlFor="title">Label</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="title"
                            value={(this.state.widget || {}).title}
                            onChange={this.handleChange}
                            required={true}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getWidgetTypeControl(_form) {
        return (
            <div className="form-group">
                <label htmlFor="type">Widget type</label>
                <select
                    required
                    name="type"
                    className={'input-control'}
                    onChange={this.onSelectChanged}
                    value={(this.state.widget || {}).type}
                >
                    {this.state.widgettypes
                        ? this.state.widgettypes.map((i, index) => {
                              return (
                                  <option key={'wf' + index} value={i.Name}>
                                      {i.Name}
                                  </option>
                              );
                          })
                        : null}
                </select>
            </div>
        );
    }

    getUseInterpolationForLineChartControl(_form) {
        return (
            <>
                {(this.state.widget || {}).type === 'linechart' ? (
                    <div className="form-group">
                        <label htmlFor="useinterpolation">Use interpolation</label>
                        <input
                            type="checkbox"
                            disabled={this.state.eventMode}
                            className="form-checkbox"
                            name="useInterpolation"
                            value={
                                this.state.widget && this.state.widget.configuration
                                    ? this.state.widget.configuration.useInterpolation
                                    : false
                            }
                            checked={((this.state.widget || {}).configuration || {}).useInterpolation}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getUseInterpolationForHistoryControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'historyDisabled') &&
                this.state.widget?.type !== 'linechart' ? (
                    <div className="form-group">
                        <label htmlFor="useinterpolation">Use interpolation in history</label>
                        <input
                            type="checkbox"
                            className="form-checkbox"
                            name="useInterpolation"
                            value={this.state.widget?.configuration?.useInterpolation ?? false}
                            checked={this.state.widget?.configuration?.useInterpolation ?? false}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    // getTracemodeForLinechartControl(form){
    //     return (
    //         <>
    //         {
    //             (this.state.widget || {}).type === "linechart" ?
    //                 <div className="form-group">
    //                     <label htmlFor="multirange">
    //                         Multi range
    //                     </label>
    //                     <input disabled={this.state.widget.valueFormat === 'eventmode' || this.state.traceMode} type="checkbox" className="form-checkbox" name="multirange" value={(this.state.widget || {}).valueFormat === "multirange"} checked={(this.state.widget || {}).valueFormat === "multirange"} onChange={this.handleMultirange} />
    //                     <label htmlFor="eventmode">
    //                         Event mode
    //                     </label>
    //                     <input type="checkbox" disabled={this.state.multirange || this.state.traceMode} className="form-checkbox" name="eventmode" value={(this.state.widget || {}).valueFormat === "eventmode"} checked={(this.state.widget || {}).valueFormat === "eventmode"} onChange={this.handleEventMode} />

    //                     <label htmlFor="tracemode">
    //                         Trace mode
    //                     </label>
    //                     <input type="checkbox" disabled={this.state.multirange || this.state.eventMode} className="form-checkbox" name="tracemode" value={this.state.traceMode} checked={this.state.traceMode} onChange={this.handleTraceMode} />

    //                 </div> : null
    //         }
    //         </>)
    // }
    getTracemodeForDistributionControl(_form) {
        return (
            <>
                {(this.state.widget || {}).type === 'distribution' ? (
                    <div className="form-group">
                        <label htmlFor="tracemode">Trace mode</label>
                        <input
                            type="checkbox"
                            disabled={this.state.multirange || this.state.eventMode}
                            className="form-checkbox"
                            name="tracemode"
                            value={this.state.traceMode}
                            checked={this.state.traceMode}
                            onChange={this.handleTraceMode}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getWidthControl(_form) {
        return (
            <div className="form-group">
                <label htmlFor="zones">Width</label>
                <select
                    required
                    name="zones"
                    className={'input-control'}
                    onChange={this.onSelectChanged}
                    value={(this.state.widget || {}).zones ?? 'One'}
                >
                    <option value="one">One</option>
                    <option value="two">Two</option>
                    <option value="three">Three</option>
                    <option value="full">Full</option>
                </select>
            </div>
        );
    }
    getPlantControl(_form) {
        return (
            <>
                {this.props.accountMode &&
                this.state.accountPlants &&
                !((this.state.widget || {}).valueFormat === 'multirange') ? (
                    <>
                        <label htmlFor="plant">Plant</label>
                        <select
                            required
                            name="plant"
                            className="input-control"
                            onChange={this.onSelectChanged}
                            value={(this.state.widget || {}).plant}
                        >
                            {this.state.accountPlants.map((plant) => {
                                return (
                                    <option key={plant.id} value={plant.id}>
                                        {plant.name}
                                    </option>
                                );
                            })}
                        </select>
                    </>
                ) : null}
            </>
        );
    }

    getComponentControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'components') && !this.isMultiRange(this.state.widget) ? (
                    <div className="form-group" style={{ position: 'relative' }}>
                        <label htmlFor="component">Component</label>
                        <Select
                            name={'component'}
                            value={this.state.selectedOption}
                            onChange={this.handleMultiSelectChange}
                            options={this.state.items}
                            isMulti={true}
                            classNamePrefix={'optimar'}
                            getOptionLabel={(x) => `${x.topic}`}
                            getOptionValue={(x) => `${x.topic}`}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                menu: (styles) => ({
                                    ...styles,
                                    width: 'auto',
                                }),
                            }}
                            required={true}
                        />
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                                opacity: 0,
                                width: '100%',
                                height: 0,
                                position: 'absolute',
                            }}
                            value={this.state.selectedOption}
                            required={true}
                            readOnly
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getComponentsControl(_form) {
        //var contextProvider = new DashboardEditPaneContext();
        return (
            <>
                {this.isMultiRange(this.state.widget) ? (
                    <div className="form-group">
                        <label htmlFor="components">Components</label>
                        {
                            <EditPaneContext.Provider
                                value={[this.state.widget.componentSettings, this.setComponentSettingsStateFromSub]}
                            >
                                {this.state.widget.componentSettings
                                    ? this.state.widget.componentSettings.map((v, i) => {
                                          return (
                                              <ComponentSettings
                                                  key={'compSet' + i}
                                                  components={this.state.items}
                                                  delete={this.deleteMultiRangeComponent}
                                                  plantId={this.props.plantId}
                                                  type={(this.state.widget || {}).type}
                                                  index={i}
                                                  dataCache={this.state.editPaneDataCache}
                                              />
                                          );
                                      })
                                    : null}
                            </EditPaneContext.Provider>
                        }
                        <div className="btn btn-blue small-btn" onClick={this.addNewComponent}>
                            Add component
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
    getDataValueControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'dataValue') && !this.isMultiRange(this.state.widget) ? (
                    <div className="form-group" style={{ position: 'relative' }}>
                        <label htmlFor="dataValue">Data value</label>
                        <DataValueSelect
                            options={this.state.datavalues || []}
                            fetchingDataValues={this.state.fetchingDataValues}
                            onChange={(selected) => this.onDataValueChanged(selected)}
                            widget={this.state.widget || {}}
                            required={true}
                        />
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                                opacity: 0,
                                width: '100%',
                                height: 0,
                                position: 'absolute',
                            }}
                            value={this.state.widget?.dataValue}
                            required={true}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getSeriesNameControl(_form) {
        return (
            <>
                {(this.state.widget || {}).type === 'linechart' && !this.isMultiRange(this.state.widget) ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="seriesName">Series Name</label>
                            <input
                                autoComplete="off"
                                type="text"
                                className={'input-control'}
                                name="seriesName"
                                value={
                                    this.state.widget && this.state.widget.configuration
                                        ? this.state.widget.configuration.seriesName
                                        : ''
                                }
                                onChange={this.handleChange}
                                required={false}
                            />
                        </span>
                    </div>
                ) : null}
            </>
        );
    }
    getDistributionBucketControl(_form) {
        return (
            <>
                {(this.state.widget || {}).type === 'distribution' ? (
                    <div className="form-group">
                        <label htmlFor="ranges">Ranges</label>
                        {
                            <EditPaneContext.Provider
                                value={[this.state.widget.distributionBuckets, this.setDistributionBucketsStateFromSub]}
                            >
                                {this.state.widget.distributionBuckets
                                    ? this.state.widget.distributionBuckets.map((v, i) => {
                                          return (
                                              <DistributionBuckets
                                                  delete={this.deleteDistributionBucket}
                                                  index={i}
                                                  key={i} // fixme this should not use index but i dont know enough about deleteDistrubutionBucket to extract something uniq
                                              />
                                          );
                                      })
                                    : null}
                            </EditPaneContext.Provider>
                        }
                        <div className="btn btn-blue small-btn" onClick={this.addNewBucket}>
                            Add range
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
    getCalculationControl(_form) {
        const traceMode = this.isTraceMode();
        return (
            <>
                {(this.state.selectedOption || []).length > 1 ||
                (this.props.dashboardType === 'job' && this.state.widget?.type === 'single-cell') ||
                (traceMode && this.state.widget.dataValue !== 'count') ? (
                    <div className="form-group">
                        <label htmlFor="calculation">Calculation</label>
                        <select
                            name="calculation"
                            className={'input-control'}
                            onChange={this.onSelectChanged}
                            value={(this.state.widget || {}).calculation}
                        >
                            <option value=""></option>
                            <option value="last">Last</option>
                            <option value="low">Lowest</option>
                            <option value="high">Highest</option>
                            <option value="sum">Sum</option>
                            <option value="avg">Average</option>
                        </select>
                    </div>
                ) : null}
            </>
        );
    }
    // Valueformat should be specified within each component and handled together with aggregation method.
    // --> Name "Aggregation method over period"????
    // getValueformatControlForSingleValue(form){
    //     return (
    //         <>
    //         {
    //             (this.state.widget?.type === "single-cell") ? <div className="form-group">
    //                 <label htmlFor="calculation">Valueformat</label>
    //                 <select name="calculation" className={"input-control"} onChange={this.onSelectChanged} value={(this.state.widget || {}).valueFormat}>
    //                     <option value="single">Snapshot</option>
    //                     <option value="accinperiod">Accumulate in period</option>
    //                     <option value="accinperiodalwaysincreasing">Accumulate in period, always increasing</option>
    //                 </select>
    //             </div> : null
    //         }
    //         </>)
    // }
    getMinMaxControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'configuration') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'minValue') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'maxValue') &&
                (this.state.widget || {}).type !== 'lamp' ? (
                    <div className="form-group multiple-inline">
                        <span>
                            <label htmlFor="minValue">Min</label>
                            <input
                                autoComplete="off"
                                type="number"
                                className={'input-control'}
                                name="minValue"
                                value={
                                    this.state.widget && this.state.widget.configuration
                                        ? this.state.widget.configuration.minValue
                                        : ''
                                }
                                onChange={this.handleChange}
                                required={true}
                            />
                        </span>
                        <span>
                            <label htmlFor="maxValue">Max</label>
                            <input
                                autoComplete="off"
                                type="number"
                                className={'input-control'}
                                name="maxValue"
                                value={
                                    this.state.widget && this.state.widget.configuration
                                        ? this.state.widget.configuration.maxValue
                                        : ''
                                }
                                onChange={this.handleChange}
                                required={true}
                            />
                        </span>
                        {/* <span>
                    <label htmlFor="maxValue">Step</label>
                    <input autoComplete="off" type="text" className={"input-control"} name="step" value={this.state.widget ? this.state.widget.configuration.step : ""} onChange={this.handleChange} required={true} />
                </span> */}
                    </div>
                ) : null}
            </>
        );
    }
    getFormulaControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'configuration') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'formula') ? (
                    <>
                        <div className="form-group">
                            <span>
                                <label htmlFor="formula">Formula</label>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    className={'input-control'}
                                    name="formula"
                                    value={
                                        this.state.widget && this.state.widget.configuration
                                            ? this.state.widget.configuration.formula
                                            : ''
                                    }
                                    onChange={this.handleChange}
                                    required={false}
                                />
                            </span>
                            <span
                                onClick={() => this.setState({ showFormulas: !this.state.showFormulas })}
                                className="formula-link"
                            >
                                Formula list
                            </span>
                        </div>
                        {this.state.showFormulas ? (
                            <div className="formula-list">
                                {this.state.widgetFormulas.map((formula) => {
                                    return (
                                        <div className="formula" key={formula.name}>
                                            <span
                                                className="formula-name"
                                                onClick={() => this.setWidgetFormula(formula.formula)}
                                            >
                                                {formula.name}
                                            </span>
                                            <span className="formula-content">{formula.formula}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    }
    getLampNameControl(_form) {
        return (
            <>
                {this.state.widget?.type === 'lamp' ? (
                    <span>
                        <label htmlFor="name">Name</label>
                        <input
                            disabled={this.state.widget?.configuration?.usePayloadUnit}
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="name"
                            value={this.state.widget?.configuration ? this.state.widget.configuration.name : ''}
                            onChange={this.handleChange}
                        />
                    </span>
                ) : null}
            </>
        );
    }
    getUnitControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'configuration') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'unit') &&
                !((this.state.widget || {}).valueFormat === 'multirange') ? (
                    <div className="form-group">
                        {this.state.widget?.type !== 'lamp' ? (
                            <span>
                                <label htmlFor="unit">Unit</label>
                                <input
                                    disabled={this.state.widget?.configuration?.usePayloadUnit}
                                    autoComplete="off"
                                    type="text"
                                    className={'input-control'}
                                    name="unit"
                                    value={
                                        this.state.widget && this.state.widget.configuration
                                            ? this.state.widget.configuration.unit
                                            : ''
                                    }
                                    onChange={this.handleChange}
                                />
                            </span>
                        ) : null}
                        {(this.state.widget || {}).type !== 'distribution' && !this.state.traceMode ? (
                            <span>
                                <label htmlFor="customunit">
                                    {(this.state.widget || {}).type === 'lamp'
                                        ? 'Use payload name'
                                        : 'Use payload unit'}
                                </label>
                                <input
                                    type="checkbox"
                                    className="form-checkbox"
                                    name="customunit"
                                    value={
                                        this.state.widget && this.state.widget.configuration
                                            ? this.state.widget.configuration.usePayloadUnit
                                            : true
                                    }
                                    checked={((this.state.widget || {}).configuration || {}).usePayloadUnit}
                                    onChange={this.handleCheckboxChange}
                                />
                            </span>
                        ) : null}
                    </div>
                ) : null}
            </>
        );
    }
    getNumericPrecisionControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'configuration') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'numericPrecision') ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="numericPrecision">Decimal precision</label>
                            <input
                                autoComplete="off"
                                type="number"
                                min="0"
                                max="10"
                                className={'input-control'}
                                name="numericPrecision"
                                value={
                                    this.state.widget && this.state.widget.configuration
                                        ? this.state.widget.configuration.numericPrecision
                                        : 0
                                }
                                onChange={this.handleChange}
                                required={false}
                            />
                        </span>
                    </div>
                ) : null}
            </>
        );
    }
    getInputTypeControl(_form) {
        return (
            <>
                {this.state.widget?.type === 'user-input' ? (
                    <span>
                        <label htmlFor="name">Input type</label>
                        <select
                            name="dType"
                            className={'input-control'}
                            onChange={this.onSelectChanged}
                            value={(this.state.widget || {}).configuration?.dType}
                        >
                            <option value="text">Text</option>
                            <option value="datetime">Datetime</option>
                            <option value="number">Number</option>
                            <option value="bool">Checkbox</option>
                        </select>
                    </span>
                ) : null}
            </>
        );
    }
    getDefaultPeriodControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'defaultPeriod') ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="unit">Default period</label>
                            <select
                                required
                                name="defaultPeriod"
                                className={'input-control'}
                                onChange={this.onSelectChanged}
                                value={(this.state.widget || {}).defaultPeriod}
                            >
                                <option value="day">Day</option>
                                <option value="hour">Hour</option>
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                            </select>
                        </span>
                    </div>
                ) : null}
            </>
        );
    }
    getAggMethodSelectorControl(_form) {
        // console.log(this.state.widget)
        if (!this.state.widget?.dataValue) return null;
        const dv = this.state.widget.dataValue;
        const isHistoric = dv?.split('/')[0] === 'historic' || false;
        return (
            <>
                {isHistoric ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="unit">Aggregation method</label>
                            <select
                                required
                                name="aggMethod"
                                className={'input-control'}
                                onChange={this.onSelectChanged}
                                value={(this.state.widget || {}).configuration?.aggMethod}
                            >
                                <option value="mean">Average</option>
                                <option value="min">Min</option>
                                <option value="max">Max</option>
                                <option value="sum">Sum</option>
                                <option value="count">Count</option>
                                <option value="std">Standard deviation</option>
                            </select>
                        </span>
                    </div>
                ) : null}
            </>
        );
    }
    getThresholdControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'configuration') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'threshold') ? (
                    <>
                        <div className="form-group">
                            <span>
                                <label htmlFor="warningLow">Warning low</label>
                                <input
                                    autoComplete="off"
                                    min={this.state.widget?.configuration?.minValue || 0}
                                    max={this.state.widget?.configuration?.maxValue || null}
                                    type="number"
                                    className={'input-control'}
                                    name="warningLow"
                                    value={
                                        this.state.widget?.configuration?.threshold
                                            ? this.state.widget?.configuration?.threshold.warningLow
                                            : ''
                                    }
                                    onChange={this.handleChange}
                                    required={true}
                                />
                            </span>
                        </div>
                        <div className="form-group">
                            <span>
                                <label htmlFor="alertLow">Alert low</label>
                                <input
                                    autoComplete="off"
                                    min={this.state.widget?.configuration?.minValue || 0}
                                    max={this.state.widget?.configuration?.maxValue || null}
                                    type="number"
                                    className={'input-control'}
                                    name="alertLow"
                                    value={
                                        this.state.widget?.configuration?.threshold
                                            ? this.state.widget?.configuration?.threshold.alertLow
                                            : ''
                                    }
                                    onChange={this.handleChange}
                                    required={true}
                                />
                            </span>
                        </div>
                        <div className="form-group">
                            <span>
                                <label htmlFor="warningHigh">Warning high</label>
                                <input
                                    autoComplete="off"
                                    min={this.state.widget?.configuration?.minValue || 0}
                                    max={this.state.widget?.configuration?.maxValue || null}
                                    type="number"
                                    className={'input-control'}
                                    name="warningHigh"
                                    value={
                                        this.state.widget?.configuration?.threshold
                                            ? this.state.widget?.configuration?.threshold.warningHigh
                                            : ''
                                    }
                                    onChange={this.handleChange}
                                    required={true}
                                />
                            </span>
                        </div>
                        <div className="form-group">
                            <span>
                                <label htmlFor="alertHigh">Alert high</label>
                                <input
                                    autoComplete="off"
                                    min={this.state.widget?.configuration?.minValue || 0}
                                    max={this.state.widget?.configuration?.maxValue || null}
                                    type="number"
                                    className={'input-control'}
                                    name="alertHigh"
                                    value={
                                        this.state.widget?.configuration?.threshold
                                            ? this.state.widget?.configuration?.threshold.alertHigh
                                            : ''
                                    }
                                    onChange={this.handleChange}
                                    required={true}
                                />
                            </span>
                        </div>
                    </>
                ) : null}
            </>
        );
    }
    getEventsModeControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'events') && !this.state.traceMode ? (
                    <div className="form-group">
                        <label htmlFor="events">Show events</label>
                        <input
                            disabled={(this.state.widget || {}).valueFormat === 'eventmode'}
                            type="checkbox"
                            className="form-checkbox"
                            name="events"
                            value={(this.state.widget || {}).events}
                            checked={(this.state.widget || {}).events}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getHistoryDisabledControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'historyDisabled') && !this.state.traceMode ? (
                    <div className="form-group">
                        <label htmlFor="historyDisabled">Disable history</label>
                        <input
                            type="checkbox"
                            className="form-checkbox"
                            name="historyDisabled"
                            value={(this.state.widget || {}).historyDisabled}
                            checked={(this.state.widget || {}).historyDisabled}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getShowSubControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form?.configuration, 'showSub') ? (
                    <div className="form-group">
                        <label htmlFor="historyDisabled">Show sub components</label>
                        <input
                            type="checkbox"
                            className="form-checkbox"
                            name="showSub"
                            value={this.state.widget?.configuration?.showSub}
                            checked={this.state.widget?.configuration?.showSub}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getShowClosedControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form?.configuration, 'showClosed') ? (
                    <div className="form-group">
                        <label htmlFor="showClosed">Show closed</label>
                        <input
                            type="checkbox"
                            className="form-checkbox"
                            name="showClosed"
                            value={this.state.widget?.configuration?.showClosed}
                            checked={this.state.widget?.configuration?.showClosed}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getShowStandardDeviationControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form?.configuration, 'showStd') ? (
                    <div className="form-group">
                        <label htmlFor="showStd">Show standard deviation</label>
                        <input
                            type="checkbox"
                            className="form-checkbox"
                            name="showStd"
                            value={this.state.widget?.configuration?.showStd}
                            checked={this.state.widget?.configuration?.showStd}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getIdealCycleTimeControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'configuration') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'idealCycleTime') ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="idealCycleTime">Ideal cycle time</label>
                            <input
                                autoComplete="off"
                                type="number"
                                className={'input-control'}
                                name="idealCycleTime"
                                value={
                                    this.state.widget && this.state.widget.configuration
                                        ? this.state.widget.configuration.idealCycleTime
                                        : ''
                                }
                                onChange={this.handleChange}
                            />
                        </span>
                    </div>
                ) : null}
            </>
        );
    }
    getRunningModeControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'configuration') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'runningMode') ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="runningMode">Running mode</label>
                            <input
                                autoComplete="off"
                                type="number"
                                className={'input-control'}
                                name="runningMode"
                                value={
                                    this.state.widget && this.state.widget.configuration
                                        ? this.state.widget.configuration.runningMode
                                        : ''
                                }
                                onChange={this.handleChange}
                            />
                        </span>
                    </div>
                ) : null}
            </>
        );
    }
    getExpectedWeightControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'configuration') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'expectedWeight') ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="expectedWeight">Expected weight</label>
                            <input
                                autoComplete="off"
                                type="number"
                                className={'input-control'}
                                name="expectedWeight"
                                value={
                                    this.state.widget && this.state.widget.configuration
                                        ? this.state.widget.configuration.expectedWeight
                                        : ''
                                }
                                onChange={this.handleChange}
                            />
                        </span>
                    </div>
                ) : null}
            </>
        );
    }
    getToDeviceControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'configuration') &&
                Object.prototype.hasOwnProperty.call(form.configuration, 'toDevice') ? (
                    <div className="form-group">
                        <label htmlFor="toDevice">Send to device</label>
                        <input
                            type="checkbox"
                            className="form-checkbox"
                            name="toDevice"
                            value={this.state.widget?.configuration?.toDevice}
                            checked={this.state.widget?.configuration?.toDevice}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>
                ) : null}
            </>
        );
    }
    getPayloadControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, 'payload') ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="payloadName">Payload name</label>
                            <input
                                autoComplete="off"
                                type="text"
                                className={'input-control'}
                                name="payloadName"
                                value={
                                    this.state.widget && this.state.widget.payload
                                        ? this.state.widget.payload.payloadName
                                        : ''
                                }
                                onChange={this.handleChange}
                                required={true}
                            />
                        </span>

                        <span>
                            <label htmlFor="payloadDescr">Payload description</label>
                            <input
                                autoComplete="off"
                                type="text"
                                className={'input-control'}
                                name="payloadDescr"
                                value={
                                    this.state.widget && this.state.widget.payload
                                        ? this.state.widget.payload.payloadDescr
                                        : ''
                                }
                                onChange={this.handleChange}
                            />
                        </span>
                    </div>
                ) : null}
            </>
        );
    }
    getLocationIdControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form.configuration, 'locationId') ? (
                    <div className="form-group">
                        <label htmlFor="title">Location ID</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="locationId"
                            value={this.state.widget?.configuration?.locationId}
                            onChange={this.handleChange}
                            required={true}
                        />
                    </div>
                ) : null}
            </>
        );
    }

    getUniqueKeyControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form.configuration, 'uniqueKey') ? (
                    <div className="form-group">
                        <label htmlFor="title">Key</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="uniqueKey"
                            value={this.state.widget?.configuration?.uniqueKey}
                            onChange={this.handleChange}
                            required={true}
                        />
                    </div>
                ) : null}
            </>
        );
    }

    getCloseWidgetModalBtn() {
        return (
            <button className="btn btn-yellow" type="button" onClick={(e) => this.props.closeWidgetModal(e)}>
                Cancel
            </button>
        );
    }
    getSaveWidgetModalBtn() {
        return (
            <button className="btn btn-blue" type="submit" disabled={this.state.saving}>
                {this.state.saving ? 'Saving..' : 'Save changes'}
            </button>
        );
    }
    getLimitControl(form) {
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form?.configuration, 'limit') ? (
                    <div className="form-group">
                        <span>
                            <label htmlFor="limit">Limit</label>
                            <input
                                autoComplete="off"
                                type="number"
                                className={'input-control'}
                                name="limit"
                                value={
                                    this.state.widget?.configuration?.limit
                                        ? this.state.widget?.configuration?.limit
                                        : ''
                                }
                                onChange={this.handleChange}
                            />
                        </span>
                    </div>
                ) : null}
            </>
        );
    }

    getTextSizeControl(form) {
        const controlName = 'textSize';
        const options = [
            { key: 'Small', value: '12px' },
            { key: 'Medium', value: '18px' },
            { key: 'Large', value: '24px' },
            { key: 'XLarge', value: '46px' },
            { key: 'XXLarge', value: '64px' },
        ];
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, controlName)
                    ? this.getSelectControl(
                          controlName,
                          'dbEditTextSizeCtrl',
                          'Text size',
                          (this.state.widget || {}).textSize,
                          options
                      )
                    : null}
            </>
        );
    }
    getHorAlignControl(form) {
        const controlName = 'horAlign';
        const options = [
            { key: 'Left', value: 'left' },
            { key: 'Center', value: 'center' },
            { key: 'Right', value: 'right' },
        ];
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, controlName)
                    ? this.getSelectControl(
                          controlName,
                          'dbEditTextHorizontalCtrl',
                          'Horizontal alignment',
                          (this.state.widget || {}).horAlign,
                          options
                      )
                    : null}
            </>
        );
    }
    getVerAlignControl(form) {
        const controlName = 'verAlign';
        const options = [
            { key: 'Top', value: 'top' },
            { key: 'Center', value: 'center' },
            { key: 'Bottom', value: 'bottom' },
        ];
        return (
            <>
                {Object.prototype.hasOwnProperty.call(form, controlName)
                    ? this.getSelectControl(
                          controlName,
                          'dbEditTextVerticalCtrl',
                          'Vertical alignment',
                          (this.state.widget || {}).verAlign,
                          options
                      )
                    : null}
            </>
        );
    }

    getGradientControl(form) {
        const controlName = 'textGradient';

        return Object.prototype.hasOwnProperty.call(form, controlName) ? (
            <>
                <label htmlFor="textGradient">Enable gradient</label>
                <input
                    type="checkbox"
                    name="textGradient"
                    defaultChecked={(this.state.widget || {}).textGradient}
                    onChange={this.handleCheckboxChange}
                    checked={(this.state.widget || {}).textGradient}
                />
            </>
        ) : null;
    }

    getColorControl(form) {
        const controlName = 'color';
        const options = [
            { key: 'Grey', value: 'Grey' },
            { key: 'Green', value: 'Green' },
            { key: 'Red', value: 'Red' },
            { key: 'Yellow', value: 'Yellow' },
        ];

        return (
            <>
                {Object.prototype.hasOwnProperty.call(form.configuration, controlName)
                    ? this.getSelectControl(
                          controlName + '0',
                          'dbEditTextColorCtrl',
                          'No data',
                          this.state.widget?.configuration?.color?.[0],
                          options
                      )
                    : null}
                {Object.prototype.hasOwnProperty.call(form.configuration, controlName)
                    ? this.getSelectControl(
                          controlName + '1',
                          'dbEditTextColorCtrl',
                          'True',
                          this.state.widget?.configuration?.color?.[1],
                          options
                      )
                    : null}
                {Object.prototype.hasOwnProperty.call(form.configuration, controlName)
                    ? this.getSelectControl(
                          controlName + '2',
                          'dbEditTextColorCtrl',
                          'False',
                          this.state.widget?.configuration?.color?.[2],
                          options
                      )
                    : null}
            </>
        );
    }

    getModeControl(form) {
        const controlName = 'mode';
        if (Object.prototype.hasOwnProperty.call(form.configuration, controlName)) {
            const options = form.configuration.mode.map((o) => {
                return {
                    key: o,
                    value: o,
                };
            });

            return this.getSelectControl(
                controlName,
                'dbEditModeCtrl',
                'Mode',
                this.state.widget?.configuration?.mode,
                options
            );
        }
        return null;
    }

    getSelectControl(controlName, optionskeyPrefix, label, currentValue, options) {
        //Control name needs to be the same as the property name in config.. -> onSelectChanged uses this to map values back.
        const keyVal = options.find((keyVal) => keyVal.value === currentValue)?.value ?? '';
        return (
            <div className="form-group">
                <span>
                    <label htmlFor="unit">{label}</label>
                    <select
                        required
                        name={controlName}
                        className={'input-control'}
                        onChange={this.onSelectChanged}
                        value={keyVal}
                    >
                        {options.map((val, optIndex) => {
                            return (
                                <option key={`${optionskeyPrefix}_${optIndex}`} value={val.value}>
                                    {val.key}
                                </option>
                            );
                        })}
                    </select>
                </span>
            </div>
        );
    }
    isTraceMode() {
        return this.state.widget && this.state.widget.valueFormat === 'tracemode';
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    widget: state.itemrow.widget,
    dashboardConfig: state.itemrow.dashboardConfig,
});

const mapDispatchToProps = {
    getWidget,
    setWidget,
    getDashboardConfig,
    setDashboardConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardEditPane);
export { EditPaneContext };
