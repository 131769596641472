import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ReactTable from 'react-table-6';
import LineChartRegular from '../../components/Widgets/LineChartRegular/LineChartRegular';
import CustomDatePicker from '../../components/common/CustomDatePicker/CustomDatePicker';
import * as moment from 'moment';
import api from '../../utility/api';
import 'react-table-6/react-table.css';
import TableSpinner from '../../components/common/Spinner/TableSpinner';
import { withRouteMatch } from '../../HOC';

class TraceList extends React.Component {
    constructor(props) {
        super(props);

        let ticks = '';
        let period = '';

        switch (this.props.defaultPeriod) {
            case 'hour':
                ticks = 'minutes';
                period = 60;
                break;

            case 'week':
                ticks = 'day';
                period = 7;
                break;

            case 'month':
                ticks = 'day';
                period = 30;
                break;

            default:
                ticks = 'hour';
                period = 24;
        }

        this.state = {
            loading: false,
            period: {
                period: period,
                interval: this.props.defaultPeriod || 'day',
                ticks: ticks,
            },
            pages: 0,
            count: 0,
            showInactive: false,
            showSubComponentTraces: true,
            chartMode: true,
        };
        // moment(doc.date).format("DD MMM YYYY")
        this.visibleColumns = [
            {
                Header: 'Time',
                accessor: 'time',
                Cell: (row) => <div>{moment(row.value).format('DD.MM.YYYY HH:mm:ss')}</div>,
            },
            {
                Header: 'Path',
                accessor: 'topic',
                filterable: true,
                Cell: (row) => <div>{row.value.split('/').slice(5).join('/')}</div>,
            },
            {
                Header: 'Species',
                accessor: 'data.species',
                filterable: true,
                sortable: false,
                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
            },
            {
                Header: 'Weight',
                accessor: 'data.weight',
                filterable: true,
                sortable: false,
                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
            },
            {
                Header: 'Length',
                accessor: 'data.length',
                filterable: true,
                sortable: false,
                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
            },
            {
                Header: 'Volume',
                accessor: 'data.volume',
                filterable: true,
                sortable: false,
                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
            },
            {
                Header: 'Angle',
                accessor: 'data.angle',
                filterable: true,
                sortable: false,
                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
            },
        ];

        this.fetchTraces = this.fetchTraces.bind(this);
        this.showSubComponentTraces = this.showSubComponentTraces.bind(this);

        this.table = React.createRef();
    }

    componentDidMount() {
        this.fetchTraces();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.fetchTraces();
        }
    }

    fetchTraces(page, pageSize, sorted, filtered, callback) {
        if (this.props.match.params.itemId?.toLowerCase() !== this.props.currentItem?.id?.toLowerCase()) return;

        const to = moment(this.state.endDate);
        const from = to
            .clone()
            .minute(0)
            .second(0)
            .millisecond(0)
            .add(-this.state.period.period, this.state.period.ticks);

        const daterange = '&from=' + from.toISOString() + '&to=' + to.toISOString();

        let url = this.props.match.params.itemId
            ? `${process.env.REACT_APP_API}/api/trace?topic=${
                  this.props.plant.topicKey + '/' + this.props.currentItem.topic
              }`
            : `${process.env.REACT_APP_API}/api/trace?topic=${this.props.plant.topicKey}`;
        url += `&pageSize=${pageSize || 20}&page=${page || 0}&showSub=${this.state.showSubComponentTraces}${daterange}`;
        if (sorted && sorted.length > 0) {
            sorted.forEach((sort) => {
                url += `&sortColumn=${sort.id}&desc=${sort.desc}`;
            });
        }
        if (filtered && filtered.length > 0) {
            filtered.forEach((filter) => {
                url += `&filterColumn=${filter.id}&filterValue=${filter.value}`;
            });
        }
        api.get(url)
            .then((res) => {
                const tmp = res.data.rows.map((x) => {
                    x.data = JSON.parse(x.data);
                    return x;
                });
                if (callback) {
                    callback(res);
                } else {
                    if (tmp.find((x) => 'baader' in x.data)) {
                        this.visibleColumns.push({
                            Header: 'Quality',
                            accessor: 'data.baader.quality',
                            filterable: true,
                            Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
                        });
                    }
                    if (tmp.find((x) => 'gcr' in x.data)) {
                        this.visibleColumns.push(
                            {
                                Header: 'Fishpos Y',
                                accessor: 'data.gcr.y-fishpos',
                                filterable: true,
                                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
                            },
                            {
                                Header: 'X Stab',
                                accessor: 'data.gcr.x-stab',
                                filterable: true,
                                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
                            },
                            {
                                Header: 'Y Stab',
                                accessor: 'data.gcr.y-stab',
                                filterable: true,
                                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
                            },
                            {
                                Header: 'Movement',
                                accessor: 'data.gcr.movement',
                                filterable: true,
                                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
                            }
                        );
                    }
                    this.setState({
                        data: tmp,
                        pages: res.data.pages,
                        count: res.data.count,
                        loading: false,
                    });
                }
            })
            .catch((_err) => {
                NotificationManager.error('Failed to fetch', 'Could not fetch traces');
                this.setState({ data: [], loading: false });
            });
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null
            ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
            : false;
    }

    showSubComponentTraces() {
        this.setState(
            {
                showSubComponentTraces: !this.state.showSubComponentTraces,
            },
            () => {
                if (this.table && this.table.current) this.table.current.fireFetchData();
            }
        );
    }

    showInactiveTraces() {
        this.setState(
            {
                showInactive: !this.state.showInactive,
            },
            this.table.current.fireFetchData
        );
    }

    daterange(val, period, parent, row, cancel) {
        if (!cancel) {
            if (period) {
                this.setState({ period: val });
            } else {
                this.setState({ endDate: val });
            }
        }

        this.setState(
            { loadingData: true, showDropdownOnParent: false, showPeriodDropdownOnParent: false },
            function () {
                this.table.current.fireFetchData();
            }
        );
    }

    openDropdown = () => {
        this.setState({ showDropdownOnParent: !this.state.showDropdownOnParent });
    };

    openPeriodDropdown = (_parent) => {
        this.setState({ showPeriodDropdownOnParent: !this.state.showPeriodDropdownOnParent });
    };

    render() {
        const traces = this.state.data;
        const conf = {
            configuration: {
                unit: '#',
            },
        };

        return (
            <>
                <div className="main-header">
                    <span>
                        {!this.state.chartMode ? (
                            <h2>{this.state.count <= 10 ? (traces ? traces.length : 0) : this.state.count} Traces</h2>
                        ) : null}
                    </span>
                    {!this.state.chartMode ? (
                        <div className={'date-wrapper parent ' + (!this.props.isMobile ? '' : ' mobile')}>
                            <div className="date-picker-button" onClick={() => this.openPeriodDropdown(true)}>
                                <div className="summary-value">Period:</div>
                                <div className="dates-value">{this.state.period ? this.state.period.interval : ''}</div>
                            </div>
                            {this.state.showPeriodDropdownOnParent ? (
                                <CustomDatePicker period={true} daterange={(val) => this.daterange(val, true, true)} />
                            ) : (
                                <div></div>
                            )}
                            <div className="date-picker-button" onClick={() => this.openDropdown(true)}>
                                <div className="summary-value">End time:</div>
                                <div className="dates-value">
                                    {this.state.endDate ? moment(this.state.endDate).format('DD.MM.YYYY HH:mm') : 'NOW'}
                                </div>
                            </div>

                            {this.state.showDropdownOnParent ? (
                                <CustomDatePicker
                                    mobile={this.props.isMobile}
                                    selDate={this.state.endDate}
                                    daterange={(val, a, b, c, cancel) => this.daterange(val, false, true, null, cancel)}
                                />
                            ) : (
                                <div></div>
                            )}
                        </div>
                    ) : null}
                    <button
                        onClick={() => this.setState({ chartMode: !this.state.chartMode })}
                        className="btn btn-blue"
                    >
                        {!this.state.chartMode ? 'Show graph' : 'Show list'}
                    </button>
                </div>
                <div className="table-tools" style={{ justifyContent: 'space-between' }}>
                    <div>
                        {this.props.currentItem ? (
                            <div onClick={this.showSubComponentTraces} className="faux-checkbox">
                                <div
                                    className={this.state.showSubComponentTraces ? 'checkbox checked' : 'checkbox'}
                                ></div>
                                <span>Show subcomponent traces</span>
                            </div>
                        ) : null}
                    </div>
                </div>
                {this.state.chartMode ? (
                    <div className="alarm-line-container">
                        <LineChartRegular
                            showSubComponents={this.state.showSubComponentTraces}
                            labelOverride={'Trace count'}
                            currentItem={this.props.currentItem}
                            traceMode={true}
                            plant={this.props.plant}
                            refreshInterval={999999999}
                            configuration={conf}
                        />
                    </div>
                ) : (
                    <ReactTable
                        ref={this.table}
                        data={traces}
                        columns={this.visibleColumns}
                        showPagination={true}
                        pages={this.state.pages}
                        loading={this.state.loading}
                        LoadingComponent={TableSpinner}
                        defaultPageSize={20}
                        showPageSizeOptions={true}
                        minRows={0}
                        manual
                        onFetchData={(state, _instance) => {
                            console.log(state);
                            this.setState({ loading: true });
                            this.fetchTraces(state.page, state.pageSize, state.sorted, state.filtered, (res) => {
                                this.setState({
                                    data: res.data.rows,
                                    pages: res.data.pages,
                                    count: res.data.count,
                                    loading: false,
                                });
                            });
                        }}
                        defaultFilterMethod={this.filterCaseInsensitive}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentPlant,
    isMobile: state.utility.isMobile,
});

export default withRouteMatch(connect(mapStateToProps)(TraceList));
