import React from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import * as moment from 'moment';
import api from '../../utility/api';
import './alarmlist.scss';
import 'react-table-6/react-table.css';
import GenericTable from '../../components/common/GenericTable';
import { getDefaultTicksAndPeriod, getFromTimeBasedOnPeriodAndToTime } from '../../components/Widgets/widgetHelper';
import { withRouteMatch } from '../../HOC';
import { getColumns, getHeader } from './listdefinition';

class AlarmList extends React.Component {
    constructor(props) {
        super(props);

        let { ticks, period, interval } = getDefaultTicksAndPeriod(props.defaultPeriod);

        //console.log("alarmlist",ticks,period,interval);
        this.state = {
            loading: false,
            period: {
                period: period, //24
                interval: interval, //day
                ticks: ticks,
            },
            pages: 0,
            count: 0,
            showInactive: false,
            showSubComponentAlarms: true,
            graphView: false,
        };
        // moment(doc.date).format("DD MMM YYYY")
        //this.visibleColumns =
        this.visibleColumns = getColumns(props);
        //  [
        //     { Header: 'Time', accessor: 'time', width: 160, Cell: row => <div>{moment(row.value).format("DD.MM.YYYY HH:mm:ss")}</div> },
        //     { Header: 'Component', accessor: 'topic', filterable: true, width: 350, Cell: row => row.original.itemId ? <Link title={row.value.split('/').slice(5).join("/")} to={`/plant/${this.props.plant.id}/items/${row.original.itemId}/overview`}>{row.value.split('/').slice(5).join("/")}</Link> : row.value.split('/').slice(5).join("/") },
        //     { Header: 'Name', accessor: 'data.Name', filterable: true, sortable: false },
        //     { Header: 'Code', accessor: 'data.Code', filterable: true, width: 50, sortable: false, Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div> },
        //     { Header: 'Level', accessor: 'data.Level', filterable: true, width: 50, sortable: false, Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div> },
        //     { Header: 'Description', accessor: 'data.Description', filterable: true, sortable: false },
        //     { Header: 'Hints', accessor: 'data.Hints', filterable: true, sortable: false },
        //     { Header: 'Active', accessor: 'data.Active', filterable: true, width: 50, sortable: false, Cell: row => <div style={{ textAlign: 'center' }}>{!row.value || row.value === "false" ? '' : '✓'}</div> },
        //     { Header: 'Ack.', accessor: 'data.Ack', filterable: true, width: 50, sortable: false, Cell: row => <div style={{ textAlign: 'center' }}>{row.value ? '✓' : ''}</div> },

        // ];

        this.fetchAlarms = this.fetchAlarms.bind(this);
        this.showSubComponentAlarms = this.showSubComponentAlarms.bind(this);
        //this.showInactiveAlarms = this.showInactiveAlarms.bind(this);

        //this.table = React.createRef();
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate', prevProps.match.params, this.props.match.params);
        if (
            this.props.user &&
            this.props.plant &&
            JSON.stringify(prevProps.match.params) !== JSON.stringify(this.props.match.params)
        ) {
            //console.log(prevProps, this.props);
            this.fetchAlarms();
            //this.fetchBreadcrumb();
        }
    }

    componentDidMount() {
        if (this.props.user && this.props.plant) {
            this.fetchAlarms();
            // this.fetchBreadcrumb();
        }
    }

    componentWillUnmount() {
        //this.mounted = false;
        //clearInterval(this.interval);
    }

    // componentDidUpdate(prevProps) {
    //     if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
    //         this.fetchAlarms();
    //     }
    // }

    fetchAlarms = async (page, pageSize, sorted, filtered, callback) => {
        //if (this.props.match.params.itemId?.toLowerCase() !== this.props.currentItem?.id?.toLowerCase()) return;

        this.setState({
            // data: res.data.rows,
            // pages: res.data.pages,
            // count: res.data.count,
            loading: true,
        });
        let to;
        if (!this.state.endDate) {
            to = this.state.period.interval === 'day' ? moment().utc() : moment().utc(); //.hour(0).minute(0).second(0).millisecond(0).utc() : moment().minute(0).second(0).millisecond(0).utc();
        } else
            to =
                this.state.period.interval === 'day'
                    ? moment(this.state.endDate).utc()
                    : moment(this.state.endDate).minute(0).second(0).millisecond(0).utc();

        console.log('creating from.. ', this.props.currentItem?.topic);
        const from = getFromTimeBasedOnPeriodAndToTime(to, this.state.period); // to.clone().minute(0).second(0).millisecond(0).add(-this.state.period.period, this.state.period.interval);

        const showSub = this.state.showSubComponentAlarms;

        let url = this.props.match.params.itemId
            ? `${process.env.REACT_APP_API}/api/alarm?plantKey=${this.props.plant.topicKey}&itemId=${this.props.match.params.itemId}`
            : `${process.env.REACT_APP_API}/api/alarm/plant?plantKey=${this.props.plant.topicKey}`;
        //url += `&pageSize=${pageSize || 20}&page=${page || 0}&showSub=${showSub}&showInactive=${showInactive}${daterange}`;
        url += `&showSub=${showSub}&from=${from.toISOString()}&to=${to.toISOString()}`;

        // if (sorted && sorted.length > 0) {
        //     sorted.forEach((sort) => {
        //         url += `&sortColumn=${sort.id}&desc=${sort.desc}`;
        //     })
        // }
        // if (filtered && filtered.length > 0) {
        //     filtered.forEach((filter) => {
        //         url += `&filterColumn=${filter.id}&filterValue=${filter.value}`;
        //     })
        // }
        try {
            //console.log("fetching alarms",url)
            const res = await api.get(url);
            if (callback) {
                callback(res);
            } else {
                this.setState({
                    data: res.data.rows,
                    pages: res.data.pages,
                    count: res.data.count,
                    loading: false,
                });
            }
        } catch (err) {
            NotificationManager.error('Failed to fetch', 'Could not fetch alarms');
            console.log('Error fetching alarms', err);
            this.setState({ data: [], loading: false });
        }
    };

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null
            ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
            : false;
    }

    enableFiltering = () => {
        return this.state.fetching || !this.props.documents;
    };
    showSubComponentAlarms() {
        this.setState(
            {
                showSubComponentAlarms: !this.state.showSubComponentAlarms,
            },
            () => {
                this.fetchAlarms();
            }
        );
    }
    hasSubComponentAlarmsFilter = () => {
        return this.state.showSubComponentAlarms;
    };
    // hasInactiveAlarmsFilter= ()=>{
    //     return this.state.showInactive;
    // }
    // showInactiveAlarms= ()=>{
    //     this.setState({
    //         showInactive: !this.state.showInactive
    //     }, this.table && this.table.current ? this.table.current.fireFetchData : null)
    // }

    daterange = (val, period, parent, row, cancel) => {
        if (!cancel) {
            if (period) {
                this.setState({ period: val });
            } else {
                this.setState({ endDate: val });
            }
        }

        this.setState(
            { loadingData: true, showDropdownOnParent: false, showPeriodDropdownOnParent: false },
            function () {
                this.fetchAlarms();
            }
        );
    };

    openDropdown = () => {
        this.setState({ showDropdownOnParent: !this.state.showDropdownOnParent });
    };

    openPeriodDropdown = (_parent) => {
        this.setState({ showPeriodDropdownOnParent: !this.state.showPeriodDropdownOnParent });
    };

    render() {
        const alarms = this.state.data;
        const alarmRows = alarms || [];
        //console.log("alarms", alarms);
        // if (alarms) {
        //     console.log("alarms", alarms);
        //     alarmRows = alarmRows.concat(alarms);

        //     alarmRows = alarms.map(a => {
        //         return {
        //             time: a.time,
        //             topic: a.topic,
        //             itemId: a.itemId,
        //             data: JSON.parse(a.data)
        //         }
        //     })

        //     console.log("alarmrows", alarmRows);

        // }

        // const conf = {
        //     configuration: {
        //         unit: ''
        //     }
        // };
        // if(this.state.loading){
        //     return <Spinner text="alarms"></Spinner>
        // }

        return (
            <div className="alarmlist-grid">
                <div className="main-header">
                    <span>
                        {!(this.props.isMobile || this.props.isTablet) ? (
                            <h2>{this.state.count <= 10 ? (alarms ? alarms.length : 0) : this.state.count} Alarms</h2>
                        ) : null}
                    </span>
                </div>

                <GenericTable
                    header={getHeader(this.props, this.state, alarmRows, this.props.itemId, {
                        enableFiltering: this.enableFiltering,
                        //showInactiveAlarms: this.showInactiveAlarms,
                        showSubComponentAlarms: this.showSubComponentAlarms,
                        //hasInactiveAlarmsFilter:this.hasInactiveAlarmsFilter,
                        hasSubComponentAlarmsFilter: this.hasSubComponentAlarmsFilter,
                        openPeriodDropdown: this.openPeriodDropdown,
                        openDropdown: this.openDropdown,
                        daterange: this.daterange,
                    })}
                    columns={this.visibleColumns}
                    data={alarmRows}
                    localPaging={true}
                    enableGlobalFilter={false}
                    defaultPageSize={20}
                    loading={this.state.loading}
                />
            </div>
        );

        // return (
        //     <>

        //         <div className="main-header">
        //             <span>
        //                 {!this.state.graphView ? <h2>{this.state.count <= 10 ? (alarms ? alarms.length : 0) : this.state.count} Alarms</h2> : <h2>Alarms</h2>}
        //                 {/* <span className="main-header-action" onClick={() => this.openModal(null, "new")}> <span className="list-expand-toggle">+ New machineNo</span> </span> */}
        //             </span>

        //             <div style={{textAlign:'right'}}>
        //                 <button onClick={() => this.setState({ graphView: !this.state.graphView })} className="btn btn-blue" style={{marginTop:0,marginBottom:'4px'}}>{!this.state.graphView ? 'Show graph' : 'Show list'}</button>
        //                 {
        //                     !this.state.graphView ?
        //                         <div className={"date-wrapper parent " + (!this.props.isMobile ? '' : ' mobile')}>
        //                             <div className="date-picker-button" onClick={() => this.openPeriodDropdown(true)}>
        //                                 <div className="summary-value">Period:</div>
        //                                 <div className="dates-value">{this.state.period ? this.state.period.interval : ""}</div>
        //                             </div>
        //                             {
        //                                 this.state.showPeriodDropdownOnParent ? <CustomDatePicker period={true} daterange={(val) => this.daterange(val, true, true)} /> : <div></div>
        //                             }
        //                             <div className="date-picker-button" onClick={() => this.openDropdown(true)}>
        //                                 <div className="summary-value">End time:</div>
        //                                 <div className="dates-value">{this.state.endDate ? moment(this.state.endDate).format("DD.MM.YYYY HH:mm") : "NOW"}</div>
        //                             </div>

        //                             {
        //                                 this.state.showDropdownOnParent ? <CustomDatePicker mobile={this.props.isMobile} selDate={this.state.endDate} daterange={(val, a, b, c, cancel) => this.daterange(val, false, true, null, cancel)} /> : <div></div>
        //                             }
        //                         </div>
        //                         : null
        //                 }
        //             </div>
        //         </div>
        //         <div className="table-tools" style={{ justifyContent: 'space-between' }}>
        //             <div>
        //                 {
        //                     this.props.currentItem ?
        //                         <div onClick={this.showSubComponentAlarms} className="faux-checkbox">
        //                             <div className={this.state.showSubComponentAlarms ? 'checkbox checked' : 'checkbox'}></div>
        //                             <span>Show subcomponent alarms</span>
        //                         </div> : null
        //                 }
        //                 {<div style={{ marginLeft: '10px' }} onClick={this.showInactiveAlarms} className="faux-checkbox">
        //                     <div className={this.state.showInactive ? 'checkbox checked' : 'checkbox'}></div>
        //                     <span>Show inactive alarms</span>
        //                 </div>}
        //             </div>

        //         </div>
        //         {
        //             <ReactTable
        //                 ref={this.table}
        //                 data={alarmRows}
        //                 columns={this.visibleColumns}
        //                 showPagination={true}
        //                 pages={this.state.pages}
        //                 loading={this.state.loading}
        //                 LoadingComponent={TableSpinner}
        //                 defaultPageSize={20}
        //                 showPageSizeOptions={true}
        //                 minRows={0}
        //                 manual
        //                 onFetchData={(state, instance) => {
        //                     console.log(state);
        //                     this.setState({ loading: true })
        //                     this.fetchAlarms(state.page, state.pageSize, state.sorted, state.filtered, (res) => {
        //                         this.setState({
        //                             data: res.data.rows,
        //                             pages: res.data.pages,
        //                             count: res.data.count,
        //                             loading: false
        //                         })
        //                     })

        //                 }}
        //                 defaultFilterMethod={this.filterCaseInsensitive}
        //             />
        //         }

        //     </>

        // );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentPlant,
    isMobile: state.utility.isMobile,
});

export default withRouteMatch(connect(mapStateToProps)(AlarmList));
