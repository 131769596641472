import React from 'react';
import '../widget.css';
import './LinkButtonGroup.scss';
import LinkButton from '../LinkButton/LinkButton';

class LinkButtonGroup extends React.Component {
    render() {
        return (
            <div className="buoy-group">
                {this.props.config.componentSettings.map((b, i) => {
                    const res = this.props.config?.data?.filter((d) => d.data.id === b.id);
                    b.data = res ? res[0] : null;
                    return (
                        <div className="buoy-group-buoy" key={'buoy' + i}>
                            <LinkButton key={i} clickable={true} config={b} />
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default LinkButtonGroup;
