import React from 'react';
import '../widget.css';
import './ValueCellGroup.scss';

class ValueCellGroup extends React.Component {
    constructor(props) {
        super(props);
        //this.types = this.props.data ? this.props.data : [];

        this.types = {
            type: 'species',
            data: [
                {
                    name: 'Cod',
                    unit: 'blocks/min',
                    value: 3,
                },
                {
                    name: 'Haddock',
                    unit: 'blocks/min',
                    value: 7,
                },
                {
                    name: 'Pallock',
                    unit: 'pallets/min',
                    value: 2,
                },
            ],
        };
    }

    render() {
        return (
            <div className="value-cell-group">
                <div className="widget-header">
                    <span className="widget-title">{this.props.title}</span>
                </div>
                <div className={'value-cell-wrapper'}>
                    {this.types.data.map((f, i) => {
                        return (
                            <div key={'type' + i}>
                                <div
                                    className={
                                        'value-cell ' +
                                        (this.props.config && this.props.config.rounded ? 'rounded' : '')
                                    }
                                >
                                    <div className={'widget-content single-value-wrapper'}>
                                        <span className={'single-value'}>{f.value}</span>
                                        <span className={'single-value-unit'}>{f.unit}</span>
                                    </div>
                                </div>
                                <div className={'value-cell-name'}>{f.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default ValueCellGroup;
