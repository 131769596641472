import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import { ReactComponent as DuplicateIcon } from '../../../images/list/duplicate.svg';
import Spinner from '../Spinner/Spinner';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../../utility/api';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        paddingTop: '40px',
        paddingRight: '40px',
        paddingBottom: '40px',
        paddingLeft: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminAccModuleAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountModules: [],
            availableModules: [],
        };
        this.closeModal = this.closeModal.bind(this);

        this.visibleColumns = [
            { Header: 'Name', accessor: 'name', filterable: true, width: 300 },
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (props) => <span>{moment(props.value).format('DD.MM.YYYY HH:mm')}</span>,
                filterable: true,
                width: 300,
            },
            {
                Header: '',
                Cell: (item) => {
                    return (
                        <div className="action-container">
                            <div
                                className="action-element "
                                alt="Remove module"
                                title="Remove module"
                                onClick={() => this.confirmRemoveModule(item.original)}
                            >
                                <DeleteIcon className="action-icon red" />
                                <span className="list-edit-btn">Remove</span>
                            </div>
                        </div>
                    );
                },
                width: 300,
            },
        ];

        this.availableModulesVisibleColumns = [
            { Header: 'Name', accessor: 'name', filterable: false, width: 200 },
            {
                Header: '',
                Cell: (item) => {
                    return (
                        <div className="action-container">
                            <div
                                className="action-element"
                                alt="Add module"
                                title="Add module"
                                onClick={() => this.addModule(item.original)}
                            >
                                <DuplicateIcon className="action-icon red" />
                                <span className="list-edit-btn">Add</span>
                            </div>
                        </div>
                    );
                },
                width: 100,
            },
        ];
    }

    componentDidMount() {
        this.setState(
            {
                account: this.props.accountToEdit,
            },
            () => {
                this.fetchAccountModules();
                this.fetchAvailableModules();
            }
        );
    }

    openModal() {
        this.setState({
            modalIsOpen: true,
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
        });
    }

    fetchAccountModules() {
        if (this.props.user != null && this.state.account != null) {
            api.get(process.env.REACT_APP_API + '/api/accountModules/' + this.state.account.id)
                .then((modules) => {
                    this.setState({
                        accountModules: modules.data,
                    });
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    fetchAvailableModules() {
        api.get(process.env.REACT_APP_API + '/api/accountModules/' + this.state.account.id + '/available')
            .then((modules) => {
                this.setState({
                    availableModules: modules.data,
                });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    confirmRemoveModule(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Remove module</h1>
                        <p>
                            You are about to remove a module from the account
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.removeModule(item);
                                    onClose();
                                }}
                            >
                                Yes, remove
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    removeModule = (module) => {
        api.delete(process.env.REACT_APP_API + '/api/accountModules/' + module.id)
            .then((res) => {
                if (res.status === 200) {
                    NotificationManager.success('Module removed', 'Module successfully removed', 5000);
                    return res.data;
                } else {
                    NotificationManager.error('Error', 'Failed to remove module', 5000);
                    throw new Error(res.status);
                }
            })
            .then(() => {
                this.fetchAccountModules();
                this.fetchAvailableModules();
            });
    };

    addModule = (module) => {
        api.post(process.env.REACT_APP_API + '/api/accountModules', {
            ModuleId: module.id,
            AccountId: this.state.account.id,
        })
            .then((res) => {
                if (res.status === 200) {
                    NotificationManager.success('Module added', 'Module successfully added', 5000);
                    return res.data;
                } else {
                    NotificationManager.error('Error', 'Failed to add module', 5000);
                    throw new Error(res.status);
                }
            })
            .then(() => {
                this.closeModal();
                this.fetchAccountModules();
                this.fetchAvailableModules();
            });
    };

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
    }

    render() {
        const modules = this.state.accountModules;
        const availableModules = this.state.availableModules;

        if (!this.state.accountModules) {
            return <Spinner text="users" />;
        }

        return (
            <div>
                <div className="main-header">
                    <span>
                        <h2>{modules ? modules.length : 0} Modules</h2>
                        <span className="main-header-action" onClick={() => this.openModal()}>
                            {' '}
                            <span className="list-expand-toggle">+ Add module</span>{' '}
                        </span>
                    </span>
                </div>

                <ReactTable
                    defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                    data={modules}
                    columns={this.visibleColumns}
                    showPagination={false}
                    pageSize={modules.length}
                    minRows={0}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <ReactTable
                        defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                        data={availableModules}
                        columns={this.availableModulesVisibleColumns}
                        showPagination={false}
                        pageSize={availableModules.length}
                        minRows={0}
                    />
                    <button
                        style={{ position: 'absolute', bottom: '20px', right: '20px' }}
                        onClick={this.closeModal}
                        className="btn btn-yellow"
                    >
                        Cancel
                    </button>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default connect(mapStateToProps)(AdminAccModuleAddEdit);
