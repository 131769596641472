import React from 'react';
import ReactDOM from 'react-dom';

class DropdownList extends React.Component {
    constructor(props) {       
        super(props);
        this.state = {
            //handling dropdown position changes when window resize
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            showLabel: typeof props.showLabel === 'undefined' ? true : props.showLabel,
            showHiddenItems: typeof props.showHiddenItems === 'undefined' ? false : props.showHiddenItems,
        };
        this.searchBoxRef = React.createRef('map-search-input');

        this.generateList =  this.generateList.bind(this);
        this.buildDropdownList =  this.buildDropdownList.bind(this);        
    }

    componentDidMount() {
        const self = this;
        window.addEventListener('resize', (event) => this.handleResize(self, event));
        window.addEventListener('scroll', (event) => this.handleScroll(self, event));
    }

    componentWillUnmount() {
        const self = this;
        window.removeEventListener('resize', (event) => this.handleResize(self, event));
        window.removeEventListener('scroll', (event) => this.handleScroll(self, event));
    }

    componentDidUpdate() {  
    }

    handleScroll(self, event) {
        if (self && self.setState) {
            self.setState({
                scrolltimeStamp: event.timeStamp,
            });
        } else {
            console.log('Missing self.');
        }
    }

    handleResize(self, _event) {
        if (self && self.setState) {
            self.setState({
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth,
            });
        } else {
            console.log('Missing self.');
        }
    }

    unflatten() {
        if (this.props.components && this.props.components.length > 0) {
            const ID_KEY = 'id';
            const PARENT_KEY = 'parentId';
            const CHILDREN_KEY = 'children';

            const tree = [], childrenOf = {};
            let item, id, parentId;

            for (let i = 0, length = this.props.components.length; i < length; i++) {
                item = this.props.components[i];
                if (!this.state.showHiddenItems && !item.showInTableView) continue;
                id = item[ID_KEY];
                parentId = item[PARENT_KEY] || '00000000-0000-0000-0000-000000000000';
                // every item may have children
                childrenOf[id] = childrenOf[id] || [];
                // init its children
                item[CHILDREN_KEY] = childrenOf[id];
                item.toggled = true;
                if (parentId !== '00000000-0000-0000-0000-000000000000') {
                    // init its parent's children object
                    childrenOf[parentId] = childrenOf[parentId] || [];
                    // push it into its parent's children object
                    childrenOf[parentId].push(item);
                } else {
                    tree.push(item);
                }
            }  
            
            return this.generateList(tree);
        }
    }

    generateList(data) {
        const list = [];
        
        if (!data || data.length === 0)
        {
            return null;
        }

        const sortedNodes = data.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
        );

        sortedNodes.map((node) => {
            return this.generateListSection(node, list, 0);
        });

        return list;
    }

    findNode(node) {
        const fString = this.props.filterString.toLowerCase();
        if (
            node.name.toLowerCase().includes(fString) ||
            (node.orderPos && node.orderPos.toLowerCase().includes(fString)) ||
            (node.productSheet && node.productSheet.toLowerCase().includes(fString)) ||
            node.children.find((child) => this.findNode(child))
        ) {
            return true;
        }
    }

    collect(array, res, prevIndex) {
        const _this = this;

        array.forEach(function (el) {
            if (el.children && el.children.length > 0) {
                _this.collect(el.children, res, prevIndex++);
            } else {
                res.push({ value: el.id, label: '-'.repeat(prevIndex++) + el.name });
            }
        });

        return res;
    }

    setItem(item) {
        if (item) {
            this.props.onSelectItem(item);
        }
    }

    generateListSection(node, list, level) {
        if (node.parentId === '00000000-0000-0000-0000-000000000000' && this.findNode(node)) {
            list.push(
                <span key={node.id} className="search-drodown-element" onMouseDown={() => this.setItem(node)}>
                    {node.name}
                </span>
            );
        } else {
            const styles = {};
            if (level >= 1) {
                styles.paddingLeft = level * 17 + 'px';
            }

            if (this.props.filterString === '' || this.findNode(node)) {
                list.push(
                    <span
                        style={styles}
                        key={node.id}
                        className="search-drodown-element"
                        onMouseDown={() => this.setItem(node)}
                    >
                        {node.name}
                    </span>
                );
            }
        }

        if (node.children.length > 0) {
            ++level;
            const sortedChildren = node.children.sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
            );

            sortedChildren.map((n) => {
                return this.generateListSection(n, list, level);
            });
        }
    }

    getItemName() {
        const selected = this.props.components.find((item) => item.id === (this.props.selectedItem || {}).id);
        if (selected) {
            return this.escapapeItemName(selected.name);
        }

        return '';
    }

    escapapeItemName(itemName) {
        if (itemName) {
            return itemName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }
        return '';
    }

    buildDropdownList(scopeToRef, treeData) {
        const scopeStyle = {
            display: this.state.showItemList ? 'flex' : 'none',
        };
        if (scopeToRef && !this.searchBoxRef.current) return null;
        if (scopeToRef) {
            const searchBoxBoundRect = this.searchBoxRef.current.getBoundingClientRect();
            scopeStyle.left = `${searchBoxBoundRect.left + window.scrollX}px`;
            scopeStyle.top = `${searchBoxBoundRect.bottom + window.scrollY}px`;
            scopeStyle.width = `${searchBoxBoundRect.width}px`;
            scopeStyle.zIndex = 9999;
        }        

        return (
            <div style={scopeStyle} className="search-items-dropdown">
                <span style={{ paddingLeft: '10px', fontSize: '12px' }}>
                    {treeData ? `Showing ${treeData.length} results` : ''}
                </span>
                {treeData
                    ? treeData.map((m) => {
                          return m;
                      })
                    : null} 
            </div>
        );
    }

    
    render() {    
        const domNode = this.props.modalRef?.current;
        
        const treeData = this.unflatten();

        return (
            <div className="form-group" style={{ position: 'relative' }}>
                {this.state.showLabel ? <label htmlFor="content">Component</label> : null}
                <div>
                    <input
                        autoComplete="off"
                        ref={this.searchBoxRef}
                        type="search"
                        value={(this.props.selectedItem || { name : ''}).name}                        
                        placeholder="Search"
                        pattern={this.getItemName()}
                        className="search-items"
                        title=""
                        onFocusCapture={() => this.setState({ showItemList: true })}
                        onBlur={() => this.setState({ showItemList: false })}
                        onChange={this.props.onComponentChange}
                        required={this.props.required}
                    />
                    {domNode
                        ? ReactDOM.createPortal(this.buildDropdownList(true, treeData), domNode.node)
                        : this.buildDropdownList(false, treeData)}
                </div>
            </div>
        );
    }
}

export default DropdownList;
