export const SET_MOBILE = 'SET_MOBILE';
export const SET_LOCAL = 'SET_LOCAL';
export const SET_TABLET = 'SET_TABLET';
export const SET_TOS = 'SET_TOS';

export const setMobile = (isMobile) => {
    return {
        type: SET_MOBILE,
        data: isMobile,
    };
};

export const setLocal = (isLocal) => {
    return {
        type: SET_LOCAL,
        data: isLocal,
    };
};

export const setTablet = (isTablet) => {
    return {
        type: SET_TABLET,
        data: isTablet,
    };
};

export const setToS = (tosAccepted) => {
    return {
        type: SET_TOS,
        data: tosAccepted,
    };
};
