import React from 'react';
import './NoteAddEdit.css';
import { connect } from 'react-redux';
import { validate } from '../../../utility/validationHelper';
import Select from 'react-select';
import format from 'date-fns/format';
import './FormDatePicker.css';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/en-gb';
import Dropzone from 'react-dropzone';
import 'react-datetime/css/react-datetime.css';
import accept from '../../../utility/filetypes.js';
import api from '../../../utility/api';
import { withRouteMatch } from '../../../HOC';
import { noop } from 'lodash';

const overlayStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '2.5em 0',
    background: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    color: '#fff',
};

const dropzoneStyle = {
    marginBottom: '0.5em',
    borderRadius: '0.4em',
    position: 'relative',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1e2e34',
};

class NoteAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            note: [],
            selectedOption: null,
            selectedDate: new Date(),
            files: [],
            filterString: '',
            expanded: [],
            list: [],
            data: [],
            items: [],
            assignedToChanged: false,
            assignedToUsers: [],
            requestTypes: [],
            project: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.handleOnFocusOut = this.handleOnFocusOut.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.fetchItem = this.fetchItem.bind(this);
        this.itemChange = this.itemChange.bind(this);
        this.getItemName = this.getItemName.bind(this);
        this.fetchingStructure = false;
        this.fetchingTags = false;
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    handleOnFocusOut() {
        validate();
    }

    check() {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    componentDidMount() {
        if (this.props.editType === 'edit') {
            const note = this.props.editItem;
            note.date += 'Z';
            this.setState(
                {
                    note,
                },
                function () {
                    this.fetchTags();
                    this.fetchSelectedTags();
                    this.fetchItem();
                    this.fetchAssignedToUsers();
                    this.fetchRequestTypes();
                    this.fetchActiveProject();
                }
            );
        } else {
            const note = {
                reporter: this.props.user.profile.Username,
                itemId: this.props.match.params.itemId,
            };

            this.setState(
                {
                    note,
                },
                () => this.fetchItem(note.itemId)
            );

            this.fetchTags();
            this.fetchRequestTypes();
            this.fetchActiveProject();
        }
        if (this.props.user && this.props.plant && !this.state.flatData) {
            this.getStructure(this.props.user, this.props.plant);
        }
    }

    fetchItem(itemId) {
        if (itemId) {
            const tmp = Object.assign([], this.state.note);
            api.get(`/api/items/${itemId}`)
                .then((res) => {
                    tmp.itemName = res.data.name;
                    this.setState({ note: tmp });
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    componentDidUpdate() {
        if (this.props.user && this.props.plant && !this.state.flatData && !this.fetchingStructure) {
            this.fetchingStructure = true;
            this.getStructure(this.props.user, this.props.plant);
        } else if (this.props.user && !this.state.tags && !this.fetchingTags) {
            this.fetchingTags = true;
            this.fetchTags();
            this.fetchAssignedToUsers();
        }
        if (!this.state.generated) this.generateList();
    }

    fetchAssignedToUsers = async () => {
        try {
            const res = await api.get(
                `${process.env.REACT_APP_API}/api/plants/${this.props.match.params.plantId}/users`
            );
            this.setState({ assignedToUsers: res.data });
        } catch (err) {
            console.error('ERROR: ', err);
        }
    };

    fetchRequestTypes() {
        api.get(`${process.env.REACT_APP_API}/api/notes/requesttypes`)
            .then((res) => {
                this.setState({ requestTypes: res.data });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    fetchActiveProject() {
        api.get(`${process.env.REACT_APP_API}/api/projects/${this.props.match.params.plantId}/active`)
            .then((res) => {
                this.setState({ project: res.data });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    getStructure(user, plant) {
        const apiUrl = `${process.env.REACT_APP_API}/api/plants/${plant.id}/items/structure`;

        api.get(apiUrl)
            .then((res) => {
                this.fetchingStructure = false;
                if (res.status !== 204) {
                    this.unflatten(res.data);
                    this.setState({
                        flatData: res.data,
                    });
                } else if (res.status === 204) {
                    this.unflatten();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    unflatten(data) {
        if (!data) {
            return;
        }
        const ID_KEY = 'id';
        const PARENT_KEY = 'parentId';
        const CHILDREN_KEY = 'children';

        const tree = [];
        const childrenOf = {};
        let item;
        let id;
        let parentId;

        for (let i = 0, length = data.length; i < length; i++) {
            item = data[i];
            id = item[ID_KEY];
            parentId = item[PARENT_KEY] || '00000000-0000-0000-0000-000000000000';
            // every item may have children
            childrenOf[id] = childrenOf[id] || [];
            // init its children
            item[CHILDREN_KEY] = childrenOf[id];
            item.toggled = true;
            if (parentId === '00000000-0000-0000-0000-000000000000') {
                tree.push(item);
            } else {
                // init its parent's children object
                childrenOf[parentId] = childrenOf[parentId] || [];
                // push it into its parent's children object
                childrenOf[parentId].push(item);
            }
        }

        this.setState(
            {
                data: tree,
                items: data,
            },
            () => this.generateList()
        );
    }

    findNode(node) {
        const fString = this.state.filterString.toLowerCase();
        if (
            node.name.toLowerCase().includes(fString) ||
            (node.orderPos && node.orderPos.toLowerCase().includes(fString)) ||
            (node.productSheet && node.productSheet.toLowerCase().includes(fString)) ||
            node.children.find((child) => this.findNode(child))
        ) {
            if (!this.state.expanded.includes(node.parentId)) {
                const tmp = this.state.expanded;
                tmp.push(node.parentId);
                this.setState({
                    expanded: tmp,
                });
            }
            return true;
        }
    }

    generateList() {
        const list = [];

        const sortedNodes = this.state.data.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
        );

        sortedNodes.map((node) => {
            return this.generateListSection(node, list, 0);
        });

        this.setState({
            list,
            generated: true,
        });
    }

    setItem(item) {
        if (!item) {
            return;
        }
        const tmp = Object.assign([], this.state.note);
        tmp.itemId = item.id;
        tmp.itemName = item.name;
        this.setState({
            note: tmp,
            filterString: '',
        });
    }

    generateListSection(node, list, level) {
        if (node.parentId === '00000000-0000-0000-0000-000000000000' && this.findNode(node)) {
            list.push(
                <span key={node.id} className="search-drodown-element" onMouseDown={() => this.setItem(node)}>
                    {node.name}
                </span>
            );
        } else {
            const styles = {};
            if (level >= 1) {
                styles.paddingLeft = this.check() ? `${level * 17}px` : `${level * 5}px`;
            }

            if (this.state.filterString === '' || this.findNode(node)) {
                list.push(
                    <span
                        style={styles}
                        key={node.id}
                        className="search-drodown-element"
                        onMouseDown={() => this.setItem(node)}
                    >
                        {node.name}
                    </span>
                );
            }
        }

        if (node.children.length > 0) {
            ++level;

            const sortedChildren = node.children.sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
            );

            sortedChildren.map((n) => {
                return this.generateListSection(n, list, level);
            });
        }

        return;
    }

    handleMultiSelectChange = (selectedOption) => {
        this.setState({ selectedOption });

        const tmp = this.state.note;
        tmp.tags = selectedOption;

        this.setState({
            note: tmp,
        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = this.state.note;
        tmp[name] = value;

        this.setState({ note: tmp });
    }

    onSelectChanged(event) {
        const value = event.target.value;
        const name = event.target.name;

        const tmp = this.state.note;
        tmp[name] = value;
        const assignedToChanged = name === 'assignedToId';

        this.setState({
            note: tmp,
            assignedToChanged,
        });
    }

    handleDateSelect(date) {
        const tmp = this.state.note;
        tmp.date = date;

        this.setState({
            note: tmp,
            selectedDate: date,
            showDatePicker: false,
        });
    }

    fetchTags() {
        this.fetching = true;
        api.get(`${process.env.REACT_APP_API}/api/tags?plantId=${this.props.match.params.plantId}&mode=Note`)
            .then((res) => {
                const tags = res.data.map(function (d) {
                    return {
                        value: d.id,
                        label: d.title,
                    };
                });
                this.fetching = false;
                this.setState({ tags });
                this.fetchingTags = false;
            })
            .catch((err) => {
                console.error('ERROR: ', err);
                this.fetching = false;
            });
    }

    handleCheckboxChange(event) {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        const tmp = Object.assign({}, this.state.note);
        tmp[name] = value;

        this.setState({ note: tmp });
    }

    fetchSelectedTags() {
        this.fetching = true;
        api.get(`/api/tags/note/${this.state.note.id}`)
            .then((res) => {
                const tags = res.data.map(function (d) {
                    return {
                        value: d.id,
                        label: d.title,
                    };
                });
                this.setState({ selectedOption: tags });
                this.fetching = false;
            })
            .catch((err) => {
                console.error('ERROR: ', err);
                this.fetching = false;
            });
    }

    handleSubmit(event) {
        if (this.props.editType === 'edit') {
            this.updateSubmit(event);
        } else {
            this.createSubmit(event);
        }
    }

    createSubmit(event) {
        event.preventDefault();
        const note = {
            title: this.state.note.title,
            date: this.state.selectedDate,
            tags: this.state.note.tags,
            content: this.state.note.content,
            reporter: this.state.note.reporter,
            status: +this.state.note.status,
            plantId: this.props.match.params.plantId,
            itemId: this.state.note.itemId,
            assignedToId: this.state.note.assignedToId,
            optimarIssue: this.state.note.optimarIssue,
            requestType: this.state.note.requestType,
        };

        if (this.state.note.itemName === '') {
            note.itemId = null;
        }

        api.post(`${process.env.REACT_APP_API}/api/notes`, note).then((response) => {
            if (response.status === 200) {
                this.state.files.forEach((file) => {
                    this.fileUpload(file, response.data.id);
                });
                this.props.closeModal();
                NotificationManager.success('Note updated successfully', 'Note updated', 5000);
                return;
            }
            NotificationManager.error('Note not updated successfully', 'Error updating note', 5000);
        });
    }

    updateSubmit(event) {
        event.preventDefault();

        const note = {
            title: this.state.note.title,
            date: this.state.selectedDate.toISOString(),
            tags: this.state.note.tags || this.state.selectedOption,
            content: this.state.note.content,
            reporter: this.state.note.reporter,
            status: +this.state.note.status,
            itemId: this.state.note.itemId,
            assignedToId: this.state.note.assignedToId,
            assignedToChanged: this.state.assignedToChanged,
            optimarIssue: this.state.note.optimarIssue,
            requestType: this.state.note.requestType,
        };

        if (this.state.note.itemName === '') {
            note.itemId = null;
        }

        api.put(`${process.env.REACT_APP_API}/api/notes/${this.state.note.id}`, note).then((response) => {
            if (response.status === 200) {
                this.setState({
                    editItem: undefined,
                });
                this.state.files.forEach((file) => {
                    this.fileUpload(file, this.state.note.id);
                });
                NotificationManager.success('Note updated successfully', 'Note updated', 5000);
                this.props.closeModal();
                return;
            }
            NotificationManager.error('Note not updated successfully', 'Error updating note', 5000);
        });
    }

    fileUpload(file, noteId) {
        const data = new FormData();
        data.append('File', file);
        data.append('Filename', file.name);

        data.append('PlantId', this.props.plant.id);
        data.append('ItemId', this.props.itemId);
        data.append('NoteId', noteId);
        data.append('CreatedAt', moment.utc().format());
        data.append('Date', moment(this.state.selectedDate).utc().format());
        api.post(`${process.env.REACT_APP_API}/api/documents`, data)
            .then((response) => {
                if (response.status === 200) {
                    this.props.fetchDocs();
                    this.setState({
                        files: [],
                    });
                    NotificationManager.success('Note updated successfully', 'Note updated', 5000);
                    this.props.closeModal();
                    return;
                }
                NotificationManager.error('Note updated/added, but file upload failed', 'File upload failed ', 5000);
                throw new Error(response.status);
            })
            .catch(noop);
    }

    changeView = () => {
        window.location.href = this.props.match.params.itemId
            ? `/plant/${this.props.match.params.plantId}/items/${this.props.match.params.itemId}/note`
            : `/plant/${this.props.match.params.plantId}/note`;
    };

    renderButton() {
        const button =
            this.props.editType === 'edit' ? (
                <button disabled={this.fetching} className="btn btn-blue btn-grow" value="Submit">
                    Update
                </button>
            ) : (
                <button disabled={this.fetching} className="btn btn-blue btn-grow" value="Submit">
                    Create
                </button>
            );

        return button;
    }

    onDrop(files) {
        if (files.length < 1) {
            NotificationManager.error('File type not supported', 'File error', 5000);
            return;
        }
        const prevFiles = this.state.files;
        prevFiles.push(...files);
        this.setState({
            files: prevFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
        });
    }

    itemChange(itemName) {
        const tmp = Object.assign([], this.state.note);
        tmp.itemName = itemName;
        this.setState({ note: tmp });
    }

    getItemName() {
        const selected = this.state.items.find((item) => item.id === this.state.note.itemId);

        if (this.state.list.length > 0) {
            // List is open
            return this.escapapeItemName(this.state.note.itemName);
        } else if (selected) {
            return this.escapapeItemName(selected.name);
        }
        return '';
    }

    escapapeItemName(itemName) {
        if (itemName) {
            return itemName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }
        return '';
    }

    render() {
        if (!this.props && !this.state.accounts) return;

        console.log(this.state.note);

        return (
            <div
                className="form-container"
                onClick={(e) => (e.target?.id === 'items-dropdown' ? null : this.setState({ showItemList: false }))}
            >
                <div className="form-header">
                    <h2>{this.props.editType === 'edit' ? 'Edit Note' : 'Add Note'}</h2>
                </div>

                {this.state.showFormSuccess ? this._renderSuccessMessage() : null}
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="title"
                            value={this.state.note ? this.state.note.title : ''}
                            onChange={this.handleChange}
                            required={true}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="content">Content</label>
                        <textarea
                            type="text"
                            className={'input-control'}
                            name="content"
                            value={this.state.note ? this.state.note.content : ''}
                            onChange={this.handleChange}
                            required={true}
                        />
                    </div>

                    <div className="form-group" style={{ position: 'relative' }}>
                        <label htmlFor="content">Component</label>
                        <div>
                            <input
                                id="items-dropdown"
                                onKeyPress={this.handleEnterPress}
                                autoComplete="off"
                                ref={'map-search-input'}
                                type="search"
                                value={this.state.note.itemName}
                                pattern={this.getItemName()}
                                placeholder="Search"
                                className="search-items"
                                title="Name does not match selected item"
                                onFocusCapture={() => this.setState({ showItemList: true })}
                                onChange={(e) => {
                                    this.itemChange(e.target.value);
                                    this.setState({ filterString: e.target.value }, this.generateList);
                                }}
                            />
                            <div
                                style={{ display: this.state.showItemList ? 'flex' : 'none' }}
                                className="search-items-dropdown"
                            >
                                <span style={{ paddingLeft: '10px', fontSize: '12px' }}>
                                    {this.state.list ? `Showing ${this.state.list.length} results` : ''}
                                </span>
                                {this.state.list
                                    ? this.state.list.map((m) => {
                                          return m;
                                      })
                                    : null}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="tags">Keywords</label>
                        <Select
                            name={'tags'}
                            value={this.state.selectedOption}
                            onChange={this.handleMultiSelectChange}
                            options={this.state.tags}
                            isMulti={true}
                            classNamePrefix={'optimar'}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="reporter">Reporter</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="reporter"
                            value={this.state.note.reporter}
                            onChange={this.handleChange}
                            required={true}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="assignedTo">Assigned to</label>
                        <select
                            value={this.state.note.assignedToId || ''}
                            name="assignedToId"
                            onChange={this.onSelectChanged}
                        >
                            <option default value="">
                                Choose assigned to
                            </option>
                            {this.state.assignedToUsers.map((usr, i) => {
                                return (
                                    <option title={usr.username} key={`usr${i}`} value={usr.id}>
                                        {usr.displayname || usr.username}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select
                            name="status"
                            value={this.state.note.status !== null ? this.state.note.status : 1}
                            onChange={this.onSelectChanged}
                        >
                            <option value={0}>None</option>
                            <option value={1}>Open</option>
                            <option value={2}>Fixed</option>
                            <option value={3}>Closed</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="date">Date</label>
                        <Datetime
                            inputProps={{ readOnly: true }}
                            locale="en-gb"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            value={
                                this.state.note && this.state.note.date
                                    ? format(new Date(this.state.note.date), 'dd.MM.yyyy HH:mm')
                                    : format(new Date(Date.now()), 'dd.MM.yyyy HH:mm')
                            }
                            onChange={this.handleDateSelect}
                            className={'date-input-control'}
                        />
                    </div>

                    <div className="form-group">
                        <label>Add file</label>
                        <Dropzone onDrop={this.onDrop.bind(this)} accept={accept}>
                            {({ getRootProps, getInputProps, isDragActive, _isDragReject }) => (
                                <div {...getRootProps()} style={dropzoneStyle}>
                                    Drop files here or click to upload
                                    <input {...getInputProps()} />
                                    {isDragActive && <div style={overlayStyle} />}
                                </div>
                            )}
                        </Dropzone>
                    </div>

                    {this.state.files && this.state.files.length > 0 ? (
                        <>
                            <label>Files</label>
                            {this.state.files.map((file, i) => {
                                return (
                                    <span key={`file-${i}`}>
                                        {file.name + (i < this.state.files.length - 1 ? ',' : '')}&nbsp;
                                    </span>
                                );
                            })}
                            <br />
                        </>
                    ) : null}

                    <div className="btn-row-grow">
                        <button
                            className="btn btn-yellow btn-grow"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.closeModal();
                            }}
                        >
                            Cancel
                        </button>
                        {this.renderButton()}
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentPlant,
});

export default withRouteMatch(connect(mapStateToProps)(NoteAddEdit));
