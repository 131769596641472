import React from 'react';

const DashboardIcon = () => {
    return (
        <svg width="6.0082299mm" height="4.9108041mm" viewBox="0 0 7.8082299 6.6108041" version="1.1" id="svg5318">
            <defs id="defs5312" />
            <g label="Layer 1" id="layer1" transform="translate(-18.774459,-12.480312)">
                <path
                    className="nav-icon"
                    fill="#919191"
                    d="m 22.383369,14.883485 c 0,0.332619 -0.27441,0.598714 -0.59871,0.598714 h -2.40317 c -0.33262,0 -0.60703,-0.266095 -0.60703,-0.598714 v -1.804459 c 0,-0.332619 0.27441,-0.598714 0.59871,-0.598714 h 2.40317 c 0.33262,0 0.59872,0.274411 0.59872,0.598714 v 1.804459 z m 0,3.608916 c 0,0.332619 -0.27441,0.598715 -0.59871,0.598715 h -2.40317 c -0.33262,0 -0.60703,-0.274411 -0.60703,-0.598715 v -1.804458 c 0,-0.332619 0.27441,-0.598714 0.59871,-0.598714 h 2.40317 c 0.33262,0 0.59872,0.27441 0.59872,0.598714 v 1.804458 z m 4.19932,-3.608916 c 0,0.332619 -0.27441,0.598714 -0.59872,0.598714 h -2.40317 c -0.33262,0 -0.59871,-0.274411 -0.59871,-0.598714 v -1.804459 c 0,-0.332619 0.27441,-0.598714 0.59871,-0.598714 h 2.40317 c 0.33262,0 0.59872,0.274411 0.59872,0.598714 z m 0,3.608916 c 0,0.332619 -0.27441,0.598715 -0.59872,0.598715 h -2.40317 c -0.33262,0 -0.59871,-0.274411 -0.59871,-0.598715 v -1.804458 c 0,-0.332619 0.27441,-0.598714 0.59871,-0.598714 h 2.40317 c 0.33262,0 0.59872,0.27441 0.59872,0.598714 z"
                    id="path4574"
                />
            </g>
        </svg>
    );
};

export default DashboardIcon;
