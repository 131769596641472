import React from 'react';

import { ComponentEditor } from './utils/DatapointsUtils';
import { ReactComponent as DeleteIcon } from '../../../images/new/optimar_cross.svg';

const datapoint_type_name = 'Chart';
const datapoint_sources = undefined;
// {
//     data_aggregates: "Data Aggregates"
// }
const new_datapoint = {
    datapointId: 'chartDatapoint',
    type: 'chart',
    lines: [],
};

export default () => {
    return {
        name: datapoint_type_name,
        new_datapoint: new_datapoint,
        get_editor: get_editor,
        get_details: get_details,
    };
};

function get_editor(data, onInputChange, props) {
    if (!data.lines) onInputChange('lines', []);

    // callback for deleting a line
    const delete_line = (index) => {
        data.lines.splice(index, 1);
        onInputChange('lines', data.lines);
    };

    // callback for adding a line
    const add_new_line = () => {
        data.lines.push(defaultLine());
        onInputChange('lines', data.lines);
    };
    const defaultLine = (label = '') => {
        return {
            label: label,
            type: 'line',
            interval: '1 hour',
            componentId: '',
            datavalue: '',
        };
    };
    const defaultLimit = (label = '') => {
        return {
            label: label,
            type: 'limit',
            value: 0,
        };
    };
    // callback for when something change in a line
    const on_line_change = (index, name, value) => {
        //console.log("chart data point on line change",index,name,value);
        if (name === 'type') {
            //reset line on type change.
            if (value === 'line') data.lines[index] = defaultLine(data.lines[index].label);
            else if (value === 'limit') data.lines[index] = defaultLimit(data.lines[index].label);
        } else if (name === 'na') {
            //some crash between new ComponentEditor and old functionality here..
            //When the name = na, then we are receiving the entire object..
            const updatedLine = Object.assign(data.lines[index], value);

            // console.log("Updated line",data.lines[index],updatedLine)
            data.lines[index] = updatedLine;
        } else {
            data.lines[index][name] = value;
        }
        onInputChange('lines', data.lines);
    };

    return (
        <>
            <br />
            {data.lines.map((line, index) => {
                return (
                    <fieldset className="line-fieldset" key={'lfs' + index}>
                        <legend>Line {index + 1}</legend>
                        <DeleteIcon
                            onClick={() => {
                                delete_line(index);
                            }}
                            className="delete-icon"
                        />
                        <div className="fields">
                            <div className="fields-column">
                                <div className="form-group">
                                    <label htmlFor="label">Label</label>
                                    <input
                                        type="text"
                                        name="label"
                                        value={line.label}
                                        className="input-control"
                                        onChange={(event) =>
                                            on_line_change(index, event.currentTarget.name, event.currentTarget.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="fields-column">
                                <div className="form-group" style={{ position: 'relative' }}>
                                    <label htmlFor="type">Type</label>
                                    <select
                                        required
                                        name="type"
                                        className={'input-control'}
                                        value={line.type}
                                        onChange={(event) =>
                                            on_line_change(index, event.currentTarget.name, event.currentTarget.value)
                                        }
                                    >
                                        <option key="t1" value="line">
                                            Line
                                        </option>
                                        <option key="t2" value="limit">
                                            Limit
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="fields">
                            {line.type === 'line' ? (
                                <div className="fields-column">
                                    <div className="form-group" style={{ position: 'relative' }}>
                                        <label htmlFor="interval">Interval</label>
                                        {/* <input type="text" name="interval" value={line.interval} className="input-control" onChange={(event) => on_line_change(index, event.currentTarget.name, event.currentTarget.value)} /> */}
                                        <select
                                            required
                                            name="interval"
                                            className={'input-control'}
                                            value={line.interval}
                                            onChange={(event) =>
                                                on_line_change(
                                                    index,
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            }
                                        >
                                            <option key="t1" value="5 minutes">
                                                5 minutes
                                            </option>
                                            <option key="t2" value="30 minutes">
                                                30 minutes
                                            </option>
                                            <option key="t3" value="1 hour">
                                                hourly
                                            </option>
                                            <option key="t4" value="3 hours">
                                                3 hours
                                            </option>
                                            <option key="t5" value="6 hours">
                                                6 hours
                                            </option>
                                            <option key="t6" value="12 hours">
                                                12 hours
                                            </option>
                                            <option key="t7" value="24 hours">
                                                24 hours
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            ) : null}
                            {/* </div> */}
                            {/* <div className="fields-column">
                           
                        </div> */}
                        </div>
                        {line.type === 'line' ? (
                            <ComponentEditor
                                onInputChange={(name, value) => {
                                    on_line_change(index, name, value);
                                }}
                                datapoint_sources={datapoint_sources}
                                data={line}
                                items={props.items}
                                getDatavaluesFunc={props.getDatavaluesFunc}
                                child_jobs={props.child_jobs}
                            />
                        ) : (
                            <div className="form-group">
                                <label htmlFor="value">Value</label>
                                <input
                                    type="number"
                                    name="value"
                                    value={line.value}
                                    className="input-control"
                                    onChange={(event) =>
                                        on_line_change(index, event.currentTarget.name, event.currentTarget.value)
                                    }
                                />
                            </div>
                        )}
                    </fieldset>
                );
            })}
            <button className="btn btn-blue" onClick={add_new_line}>
                Add line
            </button>
        </>
    );
}

function get_details(data) {
    return (
        <ul className="datapoint_details">
            {/* <li>Lines: {data.lines.length}</li> */}
            {data.lines?.length > 0 ? (
                data.lines.map((itm, idx) => {
                    switch (itm.type) {
                        case 'limit':
                            return (
                                <li key={`cs_${idx}`}>
                                    ({itm.type}) {itm.label} - <i>{itm.value}</i>
                                </li>
                            );
                        default:
                            return (
                                <li key={`cs_${idx}`}>
                                    {itm.componentId ?? 'MISSING COMPONENT'} -{' '}
                                    <i>{itm.datavalue ?? 'MISSING DATAVALUE'}</i> {itm.__custom__ ? ' (custom)' : ''}
                                </li>
                            );
                    }
                })
            ) : (
                <li>No lines added</li>
            )}
        </ul>
    );
}
