import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import AdminProjectAddEdit from '../../components/common/AdminProjectAddEdit/AdminProjectAddEdit';
import 'react-table-6/react-table.css';
import api from '../../utility/api';
import moment from 'moment';

import Modal from 'react-modal';
import { withRouteMatch } from '../../HOC';
import { noop } from 'lodash';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminProjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
        const rowContext = [
            {
                content: <span>Edit project</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete project</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];
        this.visibleColumns = [
            { Header: 'ProjectNo', accessor: 'projectNo', filterable: true },
            { Header: 'Name', accessor: 'projectName', filterable: true },
            { Header: 'Plant', accessor: 'plantName', filterable: true },
            { Header: 'Project manager', accessor: 'projectManagerName', filterable: true },
            {
                Header: 'FAT',
                accessor: 'factoryAcceptanceTest',
                Cell: (props) => <span>{props.value ? moment(props.value).format('DD.MM.YYYY') : ''}</span>,
                filterable: true,
            },
            {
                Header: 'Handover',
                accessor: 'customerHandover',
                Cell: (props) => <span>{props.value ? moment(props.value).format('DD.MM.YYYY') : ''}</span>,
                filterable: true,
            },
            {
                Header: 'End of warranty',
                accessor: 'endOfWarrantyPeriod',
                Cell: (props) => <span>{props.value ? moment(props.value).format('DD.MM.YYYY') : ''}</span>,
                filterable: true,
            },
            {
                Header: () => null,
                id: 'rowcontext',
                Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
            },
        ];

        this.fetchProjects = this.fetchProjects.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        this.fetchProjects();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) this.fetchProjects();
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete project</h1>
                        <p>
                            You are about to delete the project
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.title}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteProject(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    fetchProjects() {
        let plantId = '';
        if ((this.props.plant || {}).id && this.props.match.path.indexOf('/admin/plant') > -1)
            plantId = this.props.plant.id;

        if (this.props.user) {
            api.get(process.env.REACT_APP_API + '/api/projects' + (plantId ? '/' + plantId : ''))
                .then((res) => {
                    if (this.mounted) {
                        this.setState({
                            data: res.data,
                        });
                    }
                })
                .catch(() => {
                    NotificationManager.error('Failed to fetch', 'Could not fetch projects');
                });
        }
    }

    deleteProject = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/projects/' + itemFromChild.projectNo)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Project deleted', 'Project successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete project', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchProjects();
                })
                .catch(noop);
        }
    };

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                editType: mode,
            },
            function () {
                this.fetchProjects();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchProjects();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase()) : false;
    }

    render() {
        const projects = this.state.data;
        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{projects ? projects.length : 0} Projects</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            {' '}
                            <span className="list-expand-toggle">+ New project</span>{' '}
                        </span>
                    </span>
                </div>
                <GenericTable
                    data={projects}
                    columns={this.visibleColumns}
                    localPaging={true}
                    enableGlobalFilter={true}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminProjectAddEdit
                        match={this.props.match}
                        editProject={this.state.editItem}
                        editedCallback={this.closeModal}
                        cancelCallback={this.closeModal}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        plant: state.plants.currentAdminPlant,
    };
};

export default withRouteMatch(connect(mapStateToProps)(AdminProjects));
