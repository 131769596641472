import PropTypes from 'prop-types';
import React from 'react';
import api from '../../../../utility/api';
//import CustomDatePicker from '../../../common/CustomDatePicker/CustomDatePicker';
import { connect } from 'react-redux';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import colorVars from '../../../../styles/vars.scss';
import { getValueWithPrecisionOrDefault } from '../../../../utility/numberFunctions';
import { WidgetFormulaManager } from '../../../../utility/widgetformulamanager';
import PeriodPicker from '../../../common/PeriodPicker/PeriodPicker';
import Spinner from '../../../common/Spinner/Spinner';
import { getDefaultTicksAndPeriod, getToAndFromBasedOnPeriodOrJob, IsRangeValid } from '../../widgetHelper';
import WidgetInfo from '../../WidgetInfo';
import './SizeDistributionChart.scss';

class SizeDistributionChart extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        //this.contentDiv = <div></div>;

        const defaultPeriod = this.props.defaultPeriod || 'day';
        let { ticks, period } = getDefaultTicksAndPeriod(defaultPeriod);

        this.state = {
            displayBar: null,
            display: false,
            loading: true,
            data: [],
            fetching: true,
            error: false,
            endDate: null,
            period: {
                period: period,
                interval: defaultPeriod,
                ticks: ticks,
            },
        };

        this.applyWidgetFormula = this.applyWidgetFormula.bind(this);
        this.fetchDistribution = this.fetchDistribution.bind(this);
        this.onDateRangeChanged = this.onDateRangeChanged.bind(this);
        this.createNonDistContent = this.createNonDistContent.bind(this);
        this.createDistContent = this.createDistContent.bind(this);
        this.createContent = this.createContent.bind(this);
    }

    componentDidMount() {
        if (this.props.data && this.props.data.data && !this.props.distMode) {
            //this.contentDiv = this.createContent(this.props.data.data);
            this.setState({ display: true });
        } else if (this.props.distMode) {
            this.fetchDistribution();
        }
    }

    fetchDistribution = async () => {
        let { from, to } = getToAndFromBasedOnPeriodOrJob(this.props.job, this.state.period, this.state.endDate);
        try {
            if (!IsRangeValid(from, to)) {
                console.log('Provided range is outside of maximum range.', from, to);
                return;
            }
            this.setState({ fetching: true });
            const daterange = 'from=' + from.toISOString() + '&to=' + to.toISOString();

            const config = { ...this.props.configuration };

            const res = await api.post(`${process.env.REACT_APP_API}/api/kpi/distribution?${daterange}`, config);
            //depending on how many componentsettings were selected, a single item or an array of items will be returned here.
            // console.log("distdata", res.data.data);
            const data = this.applyWidgetFormula(res.data.data, config.componentSettings);
            const formatedData = [];
            const keys = Object.keys(data);
            for (const key in data) {
                //oh god why..
                const bucketIndex = keys.indexOf(key);
                const bucket = config.distributionBuckets[bucketIndex];

                formatedData.push({
                    name: bucket.name,
                    value: data[key],
                });
            }
            //console.log("Completed data",formatedData);
            this.setState({
                data: formatedData,
                fetching: false,
            });
        } catch (err) {
            console.log('Error fetching distribution', err);
            this.setState({
                fetching: false,
            });
        }
    };

    createNonDistContent(res) {
        if (res.xvals && res.xvals.length > 0) {
            const weightValues = res.xvals;
            return (
                <div className="size-distribution-chart-bars">
                    {weightValues.map((d, i) => {
                        return (
                            <div
                                className="size-distribution-chart-bar"
                                key={'hist' + i}
                                onMouseOver={() => this.mouseEnter(i)}
                                onMouseLeave={() => this.mouseLeave()}
                            >
                                {
                                    <div
                                        className="x-label"
                                        style={{ height: this.calculateTraceLineValue(res.yvals[i]) + 'px' }}
                                    >
                                        <span>{getValueWithPrecisionOrDefault(d / 1000, 2, 0)}</span>
                                    </div>
                                }
                                <div
                                    key={'bar' + i}
                                    id={'bar' + i}
                                    className={
                                        this.state.displayBar === i
                                            ? 'size-distribution-chart-progress highlighted'
                                            : ' size-distribution-chart-progress'
                                    }
                                    style={{ height: res.yvals[i] + '%' }}
                                ></div>
                                <div
                                    className={
                                        this.state.displayBar === i
                                            ? 'size-distribution-hover'
                                            : 'size-distribution-hover-hidden'
                                    }
                                >
                                    <div>{getValueWithPrecisionOrDefault(res.yvals[i], 1, '0.0')}%</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return <div></div>;
        }
    }

    createDistContent() {
        //let maxVal = this.props.configuration.maxValue || Math.max(...this.state.data);
        // if(this.state.fetching){
        //     return <span className="time-since">Fetching data</span>;
        // }
        if (!this.state.data || this.state.data.length === 0) {
            return <span className="no-data">No data available</span>;
        }
        return (
            <div className="size-distribution-chart-bars" style={{ width: '100%', height: 200 }}>
                <ResponsiveContainer>
                    <BarChart
                        width={500}
                        height={300}
                        data={this.state.data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="0 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={this.distTooltip} cursor={false} />
                        {/* <Legend  /> */}
                        <Bar dataKey="value" fill={colorVars.bluefaded} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
    distTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="tooltip-box">
                    <div className="tooltip-label" key={'ttpbl'}>
                        {label}
                    </div>
                    <div className="tooltip-value" key={'ttpval'}>
                        <div className="tooltipvalue" key={'ttpvalval'}>
                            {payload[0].value}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    calculateTraceLineValue(val) {
        if (!this.myRef.current) return;
        const barHeightInPx = this.myRef.current.offsetHeight - (this.myRef.current.offsetHeight / 100) * val;
        return barHeightInPx - 20;
    }
    applyWidgetFormula(data, config) {
        const formula = this.props.configuration.configuration.formula;
        if (!formula) return data;
        const formulaManager = new WidgetFormulaManager();
        const dataArray = Array.isArray(data) ? data : [data];
        const appliedData = formulaManager.applyWidgetFormulaToDistribution(formula, dataArray, config);
        return appliedData;
        // console.log("distdataform", formula);
    }

    mouseEnter = (i) => {
        this.setState({ displayBar: i });
    };

    mouseLeave = () => {
        this.setState({ displayBar: null });
    };
    onDateRangeChanged = (period, endDate) => {
        console.log('Changing period..', period, endDate);
        //this.setState({ loadingData: true, showDropdownOnParent: false, showPeriodDropdownOnParent: false }, function () {
        this.setState({ loadingData: true, period: period, endDate: endDate }, function () {
            this.fetchDistribution();
        });
    };
    createContent() {
        if (this.state.fetching) {
            return (
                <div className="loading-data">
                    <Spinner text="data" />
                </div>
            );
        }
        if (this.props.data && this.props.data.data && !this.props.distMode) {
            return this.createNonDistContent(this.props.data.data);
        } else if (this.props.distMode && this.state.data) {
            return this.createDistContent();
            //console.log("Created dist content..");
        }
    }

    render() {
        //console.log("scss",colorVars);

        let infoDiv;
        if (!this.props.distMode) {
            if (
                (!this.props.data || (this.props.data && !this.props.data.data)) &&
                !(this.props.data && this.props.data.error)
            ) {
                infoDiv = <span className="time-since">Fetching data</span>;
            } else {
                infoDiv = undefined;
            }

            if (this.props.data && this.props.data.error) {
                infoDiv = <span className="time-since no-left-margin">No data</span>;
            }
        }
        return (
            <div className="sizedistribouter">
                <div className="widget-config-info">
                    <WidgetInfo widget={this.props.widget} />
                </div>
                {!this.props.job ? (
                    <PeriodPicker
                        onDateRangeChanged={this.onDateRangeChanged}
                        period={this.state.period}
                        endDate={this.state.endDate}
                    ></PeriodPicker>
                ) : null}

                <div className="infoDiv">{infoDiv}</div>
                <div className="widget-header">
                    <span className="widget-title">
                        {this.props.header ? this.props.header : this.props.title || 'Size distribution'}
                    </span>
                </div>
                <div ref={this.myRef} id="chart" className="size-distribution-chart">
                    {this.createContent()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    endDate: state.itemrow.endDate,
    endDateParent: state.itemrow.endDateParent,
    period: state.itemrow.period || { period: 12, interval: 'day', ticks: 'hour' },
    periodParent: state.itemrow.periodParent || { period: 12, interval: 'day', ticks: 'hour' },
    isMobile: state.utility.isMobile,
});

SizeDistributionChart.propTypes = {
    header: PropTypes.string,
    data: PropTypes.object,
};

export default connect(mapStateToProps)(SizeDistributionChart);
