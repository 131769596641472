import { matchSorter } from 'match-sorter';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { ReactComponent as FilterIcon } from '../../../images/filter.svg';
import Spinner from '../Spinner/Spinner';
import { DefaultColumnFilter, GlobalFilter } from './Filters';
import './GenericTable.scss';
import typeRender from './Utility/ColumnTypeRenderer';

/**
 * @type {React.ForwardRefRenderFunction<
 *     { closeAllExpanded: () => void },
 *     {
 *         columns: any;
 *         data: any;
 *         localPaging?: boolean;
 *         fetchData: any;
 *         loading: any;
 *         defaultPageSize?: number;
 *         header: any;
 *         rowClick: any;
 *         triggerFetch: any;
 *         expandedRowContent: any;
 *         enableGlobalFilter: any;
 *         autoResetExpanded?: boolean;
 *         autoResetHiddenColumns?: boolean;
 *         autoResetFilters?: boolean;
 *         autoResetGroupBy?: boolean;
 *         autoResetSelectedRows?: boolean;
 *         autoResetRowState?: boolean;
 *         autoResetSortBy?: boolean;
 *         autoResetGlobalFilter?: boolean;
 *     }
 * >}
 */
const GenericTable = forwardRef(
    (
        {
            columns,
            data,
            localPaging = false,
            fetchData,
            loading,
            pageCount: controlledPageCount,
            defaultPageSize = 10,
            header,
            rowClick,
            triggerFetch,
            expandedRowContent,
            enableGlobalFilter,
            autoResetExpanded = false,
            autoResetHiddenColumns = false,
            autoResetFilters = false,
            autoResetGroupBy = false,
            autoResetSelectedRows = false,
            autoResetRowState = false,
            autoResetSortBy = false,
            autoResetGlobalFilter = false,
        },
        ref
    ) => {
        const filterTypes = React.useMemo(
            () => ({
                fuzzyText: fuzzyTextFilterFn,
                text: (rows, id, filterValue) => {
                    // eslint-disable-next-line
                    return rows.filter((row) => {
                        const rowValue = row.values[id];
                        return rowValue !== undefined
                            ? // eslint-disable-next-line
                              String(rowValue)
                                  .toLowerCase()
                                  // eslint-disable-next-line
                                  .startsWith(String(filterValue).toLowerCase())
                            : true;
                    });
                },
            }),
            []
        );

        const [datainternal, setData] = React.useState([]);

        const defaultColumn = React.useMemo(
            () => ({
                Filter: DefaultColumnFilter,
            }),
            []
        );

        const tableConfig = {
            columns,
            data: datainternal,
            defaultColumn,
            filterTypes,
            initialState: {
                pageIndex: 0,
                hiddenColumns: columns.filter((column) => column.show === false).map((column) => column.id),
                filters: [],
                sortBy: [],
            },
            manualPagination: !localPaging,
            pageCount: controlledPageCount,
            autoResetPage: false,
            manualFilters: !localPaging,
            manualSortBy: !localPaging,
            autoResetExpanded,
            autoResetHiddenColumns,
            autoResetFilters,
            autoResetGroupBy,
            autoResetSelectedRows,
            autoResetRowState,
            autoResetSortBy,
            autoResetGlobalFilter,
        };

        if (localPaging) {
            delete tableConfig.pageCount;
        }

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            preGlobalFilteredRows,
            setGlobalFilter,
            setPageSize,
            toggleAllRowsExpanded,
            state: { pageIndex, pageSize, filters, sortBy, globalFilter },
        } = useTable(tableConfig, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination);

        React.useEffect(() => {
            setPageSize(defaultPageSize);
        }, [defaultPageSize, setPageSize]);

        useImperativeHandle(
            ref,
            () => ({
                closeAllExpanded() {
                    toggleAllRowsExpanded(false);
                },
            }),
            [toggleAllRowsExpanded]
        );

        React.useEffect(() => {
            if (!localPaging) {
                fetchData({ pageIndex, pageSize, filtered: filters, sorted: sortBy });
            }
            // eslint-disable-next-line
        }, [pageIndex, pageSize]);

        React.useEffect(() => {
            fetchData?.({ pageIndex, pageSize, filtered: filters, sorted: sortBy, globalFilter });
        }, [triggerFetch, fetchData, pageIndex, pageSize, filters, sortBy, globalFilter]);

        //Effect for setting data.
        React.useEffect(() => {
            setData(data);
        }, [data]);

        const setGlobalFilterWrapper = (value) => {
            setGlobalFilter(value);
        };

        //Show or hide column filter.
        const toggleFilter = (columnId) => {
            if (isFiltering.includes(columnId)) {
                const tmp = [...isFiltering];
                tmp.splice(tmp.indexOf(columnId), 1);
                setIsFiltering(tmp);
                return;
            }
            setIsFiltering([...isFiltering, columnId]);
        };

        const [isFiltering, setIsFiltering] = React.useState([]);

        React.useEffect(() => {
            if (pageIndex + 1 > pageCount) {
                gotoPage(0);
            }
        }, [pageIndex, pageCount, gotoPage]);

        return (
            <>
                {header ? (
                    <div className="gt-header">
                        <div className="gt-header-left">{header.left}</div>
                        <div className="gt-header-center">{header.center}</div>
                        <div className="gt-header-right">{header.right}</div>
                    </div>
                ) : null}
                {enableGlobalFilter ? (
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilterWrapper}
                    />
                ) : null}
                <table {...getTableProps} className="generictable">
                    <thead className="gt-thead">
                        {headerGroups.map((headerGroup) => (
                            <tr key={`hdrtr${headerGroup.id}`} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        key={`hdrth${column.id}`}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        <span>
                                            {column.render('Header')}
                                            {column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}
                                            {column.canFilter ? (
                                                <FilterIcon
                                                    className="gt-filter-icon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        toggleFilter(column.id);
                                                    }}
                                                />
                                            ) : null}
                                        </span>
                                        <div>
                                            {column.canFilter && isFiltering.includes(column.id)
                                                ? column.render('Filter')
                                                : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} className="gt-tbody">
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={`fragment-${row.id}`}>
                                    <tr
                                        {...row.getRowProps()}
                                        onClick={(e) => {
                                            if (rowClick) {
                                                rowClick(row, e);
                                            }
                                        }}
                                        className={'gt-tr ' + (rowClick ? 'clickable' : '')}
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    key={`tbodtr${cell.column.id}/${cell.row.id}`}
                                                    style={{ width: cell.column.width }}
                                                    {...cell.getCellProps()}
                                                    className="gt-td"
                                                >
                                                    {typeRender(cell)}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                    {row.isExpanded ? (
                                        <tr>
                                            <td colSpan={columns.length}>{expandedRowContent(row)}</td>
                                        </tr>
                                    ) : null}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                    {loading ? (
                        <div className="gt-spinner-container">
                            <Spinner className="gt-spinner" text="data" />
                        </div>
                    ) : null}
                </table>
                <div className={'gt-pagination ' + (pageCount > 1 ? '' : 'center')}>
                    {pageCount > 1 ? (
                        <div className="gt-btn-group">
                            <button className="btn btn-blue" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {' '}
                                &lt;&lt;{' '}
                            </button>
                            <button className="btn btn-blue" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {' '}
                                &lt;{' '}
                            </button>
                        </div>
                    ) : null}
                    <div className={'gt-page-settings'}>
                        <div className="gt-pagenum">
                            Page
                            <input
                                value={pageIndex + 1}
                                type="number"
                                className="gt-page-goto"
                                onChange={(e) => gotoPage(e.target.value ? Number(e.target.value) - 1 : 0)}
                            />
                            of {pageCount}
                        </div>
                        <div className="gt-show-count">
                            <div>Show</div>
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {pageCount > 1 ? (
                        <div className="gt-btn-group">
                            <button className="btn btn-blue" onClick={() => nextPage()} disabled={!canNextPage}>
                                {' '}
                                &gt;{' '}
                            </button>
                            <button
                                className="btn btn-blue"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {' '}
                                &gt;&gt;{' '}
                            </button>
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
);

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

/*
Usage for filters: https://react-table.tanstack.com/docs/examples/filtering
        columns: [
          {
            Header: 'Age',
            accessor: 'age',
            Filter: SliderColumnFilter,
            filter: 'equals',
          },
          {
            Header: 'Visits',
            accessor: 'visits',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Status',
            accessor: 'status',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Profile Progress',
            accessor: 'progress',
            Filter: SliderColumnFilter,
            filter: filterGreaterThan,
          },

*/
//  https://react-table.tanstack.com/docs/examples/filtering
//

// This is a custom filter UI for selecting
// a unique option from a list
// eslint-disable-next-line no-unused-vars
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}
//This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
// eslint-disable-next-line no-unused-vars
function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={(e) => {
                    setFilter(parseInt(e.target.value, 10));
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
// eslint-disable-next-line no-unused-vars
function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    );
}

export default GenericTable;
