import React from 'react';
import ReactDOM from 'react-dom';
import Datetime from 'react-datetime';

class ModalDatetime extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: undefined,
            //handling dropdown position changes when window resize
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            postRenderTriggered: false,

            portalStyles: this.getPortalStyles(5000),
        };
    }
    componentDidMount() {
        //console.log(this.props);
        const self = this;
        window.addEventListener('resize', () => this.handleResize(self));
        window.addEventListener('scroll', (event) => this.handleScroll(self, event));

        if (this.props.modalRef?.current) {
            //var modalContentWindow = ReactDOM.findDOMNode(".ReactModal__Content");
            const modalContentWindow = document.querySelector('.ReactModal__Content');
            // console.log(modalContentWindow)
            modalContentWindow.addEventListener('scroll', (event) => this.handleScroll(self, event));
        }
    }
    componentWillUnmount() {
        const self = this;
        window.removeEventListener('resize', () => this.handleResize(self));
        window.removeEventListener('scroll', (event) => this.handleScroll(self, event));

        if (this.props.modalRef?.current) {
            //var modalContentWindow = ReactDOM.findDOMNode(".ReactModal__Content");
            const modalContentWindow = document.querySelector('.ReactModal__Content');
            if (modalContentWindow) {
                modalContentWindow.removeEventListener('scroll', (event) => this.handleScroll(self, event));
            }
        }
    }

    componentDidUpdate() {
        const self = this;
        if (!self.state.postRenderTriggered) {
            self.setState({
                windowHeight: window.innerHeight + 1,
                windowWidth: window.innerWidth + 1,
                postRenderTriggered: true,
                portalStyles: self.getPortalStyles(5000),
            });
        }
    }

    getPortalStyles(zIndex) {
        const parentRefRect = this.props.scopeToParentRef?.current?.getBoundingClientRect();

        //var modalContentWindow = document.querySelector('.ReactModal__Content');
        //console.log(modalContentWindow, modalContentWindow.scrollTop, modalContentWindow.scrollHeight )
        const yPos = parentRefRect?.bottom + window.scrollY; // +modalContentWindow.scrollTop;
        //console.log(yPos, parentRefRect?.bottom, window.scrollY, modalContentWindow.scrollTop )

        const portalStyles = {
            left: `${parentRefRect?.left + window.scrollX}px`,
            top: `${yPos}px`,
            width: `${parentRefRect?.width}px`,
            position: 'absolute',
            zIndex: zIndex,
        };
        return portalStyles;
    }

    handleScroll(self, event) {
        if (!self.state.open) {
            return;
        }
        //console.log(event)
        if (self && self.setState) {
            self.setState({
                scrolltimeStamp: event.timeStamp,
                portalStyles: self.getPortalStyles(this.state.portalStyles.zIndex),
            });
        } else {
            console.log('Missing self.');
        }
    }
    handleResize(self) {
        if (!self.state.open) {
            return;
        }
        if (self && self.setState) {
            self.setState({
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth,
                portalStyles: self.getPortalStyles(this.state.portalStyles.zIndex),
            });
        } else {
            console.log('Missing self.');
        }
    }

    handleFocus = (_e) => {
        //console.log("OnOpen");
        this.setState(
            Object.assign({}, this.state, {
                open: true,
            })
        );
    };

    handleClose = (_e) => {
        //console.log("OnClose");
        this.setState(
            Object.assign({}, this.state, {
                open: false,
            })
        );
    };
    handleOnClick = (_e) => {
        //console.log("OnClick");
        this.setState(
            Object.assign({}, this.state, {
                open: true,
            })
        );
    };

    // renderDay = (props, currentDate) => {
    //     return (
    //         <td
    //             {...props}
    //             onDoubleClick={this.handleClose}
    //         >
    //             {currentDate.date()}
    //         </td>
    //     );
    // }

    render() {
        const domNode = this.props.modalRef?.current;
        const { open } = this.state;

        const usePortal = domNode && open;
        //console.log(`UsePortal: ${usePortal} || open: ${open}`);
        // return usePortal? ReactDOM.createPortal(<div className={"modal-child-wrapper"} style={this.state.portalStyles}>{children }</div>, domNode.node):
        // <>{children}</>
        const dt = (
            <Datetime
                className={open && 'rdtOpen'}
                onFocus={this.handleFocus}
                onBlur={this.handleClose}
                {...this.props}
            />
        );

        let portalStyles = this.state.portalStyles;
        portalStyles = this.getPortalStyles(open ? 9999 : 5000);
        return usePortal ? (
            ReactDOM.createPortal(
                <div className={'modal-child-wrapper'} style={portalStyles}>
                    {dt}
                </div>,
                domNode.node
            )
        ) : (
            <div>{dt}</div>
        );
    }
}
export default ModalDatetime;
