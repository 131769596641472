import { SET_REPORTLIST, CLEAR_REPORTLIST } from '../actions';

const reports = (
    state = {
        reportList: undefined,
    },
    action
) => {
    switch (action.type) {
        case SET_REPORTLIST:
            return {
                ...state,
                reportList: action.data,
            };
        case CLEAR_REPORTLIST:
            return {
                ...state,
                reportList: undefined,
            };
        default:
            return state;
    }
};

export default reports;
