import React from 'react';
import './AdminPlants.scss';
import AdminPlantAddEdit from '../../components/common/AdminPlantAddEdit/AdminPlantAddEdit';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { setCurrentAdminPlant, clearCurrentAdminPlant } from '../../actions';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../utility/api';

import Modal from 'react-modal';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminPlants extends React.Component {
    constructor(props) {
        super(props);
        const rowContext = [
            {
                content: <span>Configure plant</span>,
                action: (row) => this.editPlantItems(row.original),
            },
            {
                content: <span>Delete plant</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];
        this.state = {
            fetching: false,
            showAddPlantSection: false,
            showEditPlantSection: false,
            showDeletePlantSection: false,
            visibleColumns: [
                { Header: 'Name', accessor: 'name', filterable: true },
                { Header: 'Topic Key', accessor: 'topicKey', filterable: true },
                { Header: 'Type', accessor: 'plantTypeName', filterable: true },
                { Header: 'IMO', accessor: 'imo', filterable: true },
                {
                    Header: 'Position',
                    accessor: 'mtTracking',
                    filterable: false,
                    Cell: (cellInfo) => {
                        const item = cellInfo.data[cellInfo.row.index];
                        return item.mmsi || item.imo ? (
                            <div className="toggle-container">
                                <div
                                    className={'toggle ' + (item.locationTracking ? 'on' : '')}
                                    title={
                                        item.locationTracking
                                            ? 'Location tracking active. Click to deactive tracking'
                                            : 'Location tracking not active. Click to activate tracking'
                                    }
                                    onClick={() => this.toggleTracking(item, item.locationTracking ? false : true)}
                                >
                                    <div className="dot"></div>
                                </div>
                            </div>
                        ) : null;
                    },
                },
                {
                    Header: () => null,
                    id: 'rowcontext',
                    Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
                },
            ],
            editMode: false,
            editUser: null,
            plants: [],
            modalIsOpen: false,
            counter: 0,
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        if (this.props.user) {
            this.fetchPlants();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(_nextProps) {
        if (this.props.user && !this.state.plants) {
            this.fetchPlants();
        }
    }

    fetchPlants = async () => {
        try {
            this.setState({ fetching: true });
            const res = await api.get(`${process.env.REACT_APP_API}/api/admin/plants`);

            res.data.sort((a, b) => +b.plantState - +a.plantState || a.name.localeCompare(b.name));
            if (this.mounted) {
                this.setState({
                    plants: res.data,
                    fetching: false,
                });
            }
        } catch (err) {
            console.error('ERROR: ', err);
            this.setState({
                fetching: false,
            });
        }
    };

    createNotification = (type, message, title) => {
        const timeout = 5000;

        switch (type) {
            case 'info':
                NotificationManager.info(message, timeout);
                break;
            case 'success':
                NotificationManager.success(message, title, timeout);
                break;
            case 'warning':
                NotificationManager.warning(message, title, timeout);
                break;
            case 'error':
                NotificationManager.error(message, title, timeout, () => {
                    alert('callback');
                });
                break;
            default:
        }
    };

    toggleTracking(plant, mode) {
        api.post(process.env.REACT_APP_API + '/api/admin/plants/tracking/' + plant.id + '/' + mode, plant).then(
            (response) => {
                if (response.status === 200) {
                    NotificationManager.success('Tracking changed', 'Plant tracking changed', 5000);
                    this.fetchPlants();
                } else {
                    NotificationManager.error('Tracking not changed', 'Error changing tracking', 5000);
                    throw new Error(response.status);
                }
            }
        );
    }

    editPlant = (plant) => {
        this.props.setCurrentAdminPlant(plant);
        this.setState({
            editItem: plant,
        });
    };

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete plant</h1>
                        <p>
                            You are about to delete the plant
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deletePlant(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deletePlant = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/admin/plants/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Plant deleted', 'Plant successfully deleted', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete plant', 5000);
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchPlants();
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    };

    editPlantItems = (plant) => {
        this.props.setCurrentAdminPlant(plant);
        this.setState({ redirect: true });
    };

    addedPlant = (_itemFromChild) => {
        this.fetchPlants();
    };

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                editType: mode,
            },
            function () {
                this.fetchPlants();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchPlants();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase()) : true;
    }

    render() {
        const plants = this.state.plants;
        const visibleColumns = this.state.visibleColumns;
        const currentUser = this.state.user;

        if (this.state.redirect) {
            console.log('PROPS', this.props);
            return <Redirect to={'/admin/plant/' + this.props.currentAdminPlant.id + '/items'} />;
        }

        if (currentUser && currentUser.profile.role !== 'admin') {
            return <Redirect to="/" />;
        }

        return (
            <>
                <div className="main-header">
                    <div className="main-header-title">
                        <h2>{plants ? plants.filter((plant) => plant.plantState === 1).length : 0} Plants</h2>
                        {/* The linter flags a tags without href as invalid. They are not. Valid HTML5 placeholder hyperlinks. */}
                        {/* eslint-disable-next-line */}
                        <a className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            + New Plant
                        </a>
                    </div>
                </div>
                <GenericTable
                    data={plants}
                    columns={visibleColumns}
                    localPaging={true}
                    enableGlobalFilter={true}
                    loading={this.state.fetching}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminPlantAddEdit
                        type={this.state.editType}
                        plantToEdit={this.state.editItem}
                        editedCallback={this.closeModal}
                        addedCallback={this.closeModal}
                        cancelCallback={this.closeModal}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    currentAdminPlant: state.plants.currentAdminPlant,
});

const mapDispatchToProps = {
    setCurrentAdminPlant,
    clearCurrentAdminPlant,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPlants);
