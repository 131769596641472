import React from 'react';

import 'gantt-task-react/dist/index.css';
import { ViewMode } from 'gantt-task-react';
import SelectorButton from '../Buttons/selectorbutton/SelectorButton';

export const ViewSwitcher = ({ onViewModeChange, onViewListChange, isChecked, viewMode }) => {
    return (
        <div className="ViewContainer">
            <SelectorButton isActive={viewMode === ViewMode.Month} onClick={() => onViewModeChange(ViewMode.Month)}>
                Month
            </SelectorButton>
            <SelectorButton isActive={viewMode === ViewMode.Week} onClick={() => onViewModeChange(ViewMode.Week)}>
                Week
            </SelectorButton>
            <SelectorButton isActive={viewMode === ViewMode.Day} onClick={() => onViewModeChange(ViewMode.Day)}>
                Day
            </SelectorButton>
            <SelectorButton isActive={viewMode === ViewMode.Hour} onClick={() => onViewModeChange(ViewMode.Hour)}>
                Hour
            </SelectorButton>

            {/* <div className="Switch">
                <label className="Switch_Toggle">
                    <input type="checkbox" defaultChecked={isChecked} onClick={() => onViewListChange(!isChecked)} />
                    <span className="Slider" />
                </label>
                Show Task List
            </div> */}
        </div>
    );
};
