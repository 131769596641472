import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import { ReactComponent as EditIcon } from '../../../images/list/optimar_edit.svg';
import AdminUserAddEdit from '../AdminUserAddEdit/AdminUserAddEdit';
import Spinner from '../Spinner/Spinner';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../../utility/api';
import GenericTable from '../GenericTable';
import RowContextMenu from '../GenericTable/TableWidgets/RowContextMenu';
import { noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        paddingTop: '40px',
        paddingRight: '40px',
        paddingBottom: '40px',
        paddingLeft: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminAccUserAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.closeModal = this.closeModal.bind(this);

        this.visibleColumns = [
            { Header: 'User', accessor: 'username', filterable: true, width: 350 },
            { Header: 'Roles', accessor: 'roles', filterable: false, width: 350, type: 'array' },
            {
                Header: 'Created',
                accessor: 'createdOn',
                Cell: (props) => <span>{moment(props.value).format('DD.MM.YYYY HH:mm')}</span>,
                filterable: true,
                width: 350,
            },
            {
                Header: '',
                id: 'rowcontext',
                Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
            },
        ];

        const rowContext = [
            {
                content: <span>Edit user</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete user</span>,
                action: (row) => this.confirmDeleteUser(row.original),
            },
        ];
    }

    componentDidMount() {
        this.setState(
            {
                account: this.props.accountToEdit,
            },
            () => {
                this.fetchAccountUsers();
            }
        );
    }

    openModal(user, mode) {
        if (mode === 'edit') {
            this.setState({
                modalIsOpen: true,
                editUser: user,
            });
        } else {
            this.setState({
                modalIsOpen: true,
            });
        }
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editUser: null,
            },
            () => {
                this.fetchAccountUsers();
            }
        );
    }

    editUser(user) {
        this.setState({
            editUser: user,
        });
    }

    detachUser = (itemFromChild) => {
        if (this.props.user != null) {
            api.put(process.env.REACT_APP_API + '/api/users/detach/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then(() => {
                    this.fetchAccountUsers();
                })
                .catch(noop);
        }
    };

    fetchAccountUsers() {
        if (this.props.user != null && this.state.account != null) {
            api.get(process.env.REACT_APP_API + '/api/users/account/' + this.state.account.id)
                .then((res) =>
                    res.data.map((user) => {
                        const userButtons = [];

                        if (user.applicationUserState !== 0) {
                            userButtons.push(this.getEditLink(user));
                            userButtons.push(this.getDeleteLink(user));
                        } else {
                            userButtons.push(this.getEditLink(user));
                        }

                        user.buttons = userButtons;
                        user.isDisabled = user.applicationUserState === 0;
                        user.roles = user.roles.map((role) => {
                            return { id: role?.id, name: role?.title, permissions: role?.permissions };
                        });
                        return user;
                    })
                )
                .then((users) =>
                    users.sort((a, b) => +a.isDisabled - +b.isDisabled || a.username.localeCompare(b.username))
                )
                .then((users) => {
                    this.setState({
                        accountUsers: users,
                    });
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    confirmDeleteUser(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete user</h1>
                        <p>
                            You are about to delete the user
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.username}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteUser(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteUser = (user) => {
        if (this.props.user != null) {
            api.delete(process.env.REACT_APP_API + '/api/users/' + user.id)
                .then((res) => {
                    if (res.status === 200) {
                        NotificationManager.success('User deleted', 'User successfully deleted', 5000);
                        return res.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to delete user', 5000);
                        throw new Error(res.status);
                    }
                })
                .then(() => {
                    this.fetchAccountUsers();
                })
                .catch(noop);
        }
    };

    getEditLink(user) {
        return (
            <img
                key={'editButton' + user.id}
                className={'list-btn'}
                alt="Edit"
                title="Edit"
                src={EditIcon}
                onClick={() => this.editUser(user)}
            />
        );
    }

    getDeleteLink(user) {
        return (
            <img
                key={'deleteButton' + user.id}
                className={'list-btn'}
                alt="Delete"
                title="Delete"
                src={DeleteIcon}
                onClick={() => this.confirmDeleteUser(user)}
            />
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
    }

    render() {
        if (!this.state.accountUsers) {
            return <Spinner text="users" />;
        }

        const users = this.state.accountUsers.filter((user) => {
            return user.applicationUserState === 1;
        });

        return (
            <div>
                <div className="main-header">
                    <span>
                        <h2>{users ? users.length : 0} Users</h2>
                        <span className="main-header-action" onClick={() => this.openModal(null, 'new')}>
                            <span className="list-expand-toggle">+ New user</span>
                        </span>
                    </span>
                </div>

                <GenericTable
                    defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                    data={users}
                    columns={this.visibleColumns}
                    showPagination={false}
                    pageSize={users.length}
                    minRows={0}
                    localPaging={true}
                ></GenericTable>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <AdminUserAddEdit
                        account={this.state.editUser ? null : this.props.accountToEdit}
                        editUser={this.state.editUser}
                        editedCallback={this.closeModal}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default connect(mapStateToProps)(AdminAccUserAddEdit);
