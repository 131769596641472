import React from 'react';
import './AdminAccountAddEdit.css';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
class AdminAccountAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: { name: '', account_state: '' },
            nameValid: false,
            accountStateValid: false,
            accountDuplicateValid: false,

            formValid: false,
            isDirty: false,
            checkedItems: [],
            account: {},

            usersVisibleColumns: [{ name: 'username', headerName: 'Username' }],
            plantsVisibleColumns: [{ name: 'plantName', headerName: 'Name' }],
            modalIsOpen: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.handleOnFocusOut = this.handleOnFocusOut.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.changeView = this.changeView.bind(this);

        this.getAttachedItems = this.getAttachedItems.bind(this);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    handleChange(event) {
        this.setState({
            isDirty: true,
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const tmp = this.state.account;
        switch (name) {
            case 'accountState':
                tmp[name] = parseInt(value, 10);
                break;
            default:
                tmp[name] = value;
                break;
        }

        this.setState({ account: tmp });
    }

    validateField(fieldName, value) {
        const fieldValidationErrors = this.state.formErrors;

        let nameValid = this.state.nameValid;
        let accountStateValid = this.state.accountStateValid;
        let accountDuplicateValid = this.state.accountDuplicateValid;
        const existingAccounts = this.props.currentAccounts || [];
        switch (fieldName) {
            case 'name':
                nameValid = value.length >= 3;
                fieldValidationErrors.name = nameValid ? '' : ' is invalid';
                this.setState({
                    nameValid: nameValid,
                });
                accountDuplicateValid = !existingAccounts.some((e) => e.name.toUpperCase() === value.toUpperCase());
                fieldValidationErrors.account = accountDuplicateValid ? '' : ' already exists';
                this.setState({
                    accountDuplicateValid: accountDuplicateValid,
                });
                break;
            case 'accountState':
                accountStateValid = value.toString() === '0' || value.toString() === '1';
                fieldValidationErrors.account_state = accountStateValid ? '' : ' is invalid';
                this.setState({
                    accountStateValid: accountStateValid,
                });
                break;
            default:
                break;
        }
        this.setState(
            {
                formErrors: fieldValidationErrors,
            },
            this.validateForm
        );
    }

    validateForm() {
        this.setState({
            formValid:
                this.state.nameValid &&
                this.state.accountStateValid &&
                this.state.accountDuplicateValid &&
                this.state.isDirty,
        });
    }

    componentDidMount() {
        if (this.props.accountToEdit) {
            this.setState(
                {
                    mode: 'edit',
                    account: this.props.accountToEdit,
                },
                this.afterMounting
            );
        }
    }

    afterMounting() {
        this.validateFields();
    }

    validateFields() {
        this.validateField('name', this.state.account.name);
        this.validateField('accountState', this.state.account.accountState);
    }

    handleSubmit(event) {
        if (this.state.mode === 'edit') {
            this.updateSubmit(event);
        } else {
            this.createSubmit(event);
        }
    }

    createSubmit = async (event) => {
        event.preventDefault();
        // let account = {
        //     parentId: null,
        //     name: this.state.account.name,
        //     organizationNumber: null,
        //     address: null,
        //     zipCode: null,
        //     place: null,
        //     countryCode: null,
        //     longitude: null,
        //     latitude: null,
        //     phone: null,
        //     website: null,
        //     accountState: this.state.account.accountState,
        //     accountName: null,
        //     countryName: null
        // }
        try {
            console.log(this.state.account);
            const response = await api.post('/api/accounts/', this.state.account);

            if (response.status === 200) {
                NotificationManager.success('Account created', 'Account successfully created', 5000);
                const newAccount = response.data;

                const tmp = this.state.account;
                tmp.name = '';
                tmp.accountState = 3;

                this.props.addedCallback();

                this.setState({
                    isDirty: false,
                    formValid: false,
                    plantTypeValid: false,
                    accountValid: false,
                    account: tmp,
                });
                return newAccount;
            } else {
                //NotificationManager.error("Error", "Error creating account", 5000);
                throw new Error(response);
            }
        } catch (error) {
            console.log('Error creating account', error);
            NotificationManager.error('Error', 'Error creating account', 5000);
        }
    };

    updateSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await api.put('/api/accounts/' + this.state.account.id, this.state.account);

            if (response.status === 200) {
                NotificationManager.success('Account updated', 'Account successfully updated', 5000);
                this.setState({
                    isDirty: false,
                    formValid: false,
                    plantTypeValid: false,
                    accountValid: false,
                });
                this.props.accountEdited();
                if (this.props.editedCallback) this.props.editedCallback();
                this.redirect('/admin/account');
            } else {
                throw new Error(response);
            }
        } catch (error) {
            NotificationManager.error('Error', 'Failed to update account', 5000);
            console.log('fail update: ', error);
        }
    };

    handleAccountChange(event) {
        this.handleChange(event);
        this.handleOnFocusOut(event);
    }

    handleOnFocusOut(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.validateField(name, value);
    }

    renderButton() {
        let button;

        if (this.state.mode === 'edit') {
            button = (
                <button className="btn btn-blue btn-full-width" value="Submit">
                    Save changes
                </button>
            );
        } else {
            button = (
                <button className="btn btn-blue btn-grow" value="Submit">
                    Create
                </button>
            );
        }

        return button;
    }

    changeView(view) {
        this.setState({
            view: view,
        });
    }

    redirect(path) {
        this.setState({
            redirect: true,
            redirectPath: path,
        });
    }

    getAttachedItems() {
        let result = [];
        if (this.state.accountPlants) {
            result = this.state.accountPlants.filter((item) => item.accountName !== null);
        }

        return result;
    }

    getDetachedItems() {
        let result = [];
        if (this.state.accountPlants) {
            result = this.state.accountPlants.filter((item) => item.accountName === null);
        }
        return result;
    }

    openModal(mode, _note) {
        this.setState({
            modalIsOpen: true,
            editItem: null,
            editType: mode,
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            editItem: null,
            editType: null,
        });
    }

    render() {
        return (
            <form className="admin-plant-add-edit-form" onSubmit={this.handleSubmit}>
                {this.state.mode === 'edit' ? null : <h1>New account</h1>}
                <div>
                    <label htmlFor="name">Name:</label>
                    <input
                        required
                        className="admin-input-control"
                        autoComplete="off"
                        type="text"
                        name="name"
                        value={this.state.account.name || ''}
                        onChange={this.handleChange}
                        onBlur={this.handleOnFocusOut}
                    />
                </div>
                <div>
                    <label htmlFor="crmAccountId">CRM Account Id:</label>
                    <input
                        className="admin-input-control"
                        autoComplete="off"
                        type="text"
                        name="crmAccountId"
                        value={this.state.account.crmAccountId || ''}
                        onChange={this.handleChange}
                        onBlur={this.handleOnFocusOut}
                    />
                </div>

                <div>
                    <label htmlFor="account_state">Choose Account State:</label>
                    <select
                        required
                        className={'admin-plant-items-input-control'}
                        value={this.state.account.accountState}
                        name="accountState"
                        onChange={this.handleAccountChange}
                    >
                        <option default value="">
                            Choose Account State
                        </option>
                        <option value="0">Disabled</option>
                        <option value="1">Enabled</option>
                    </select>
                </div>

                <div className="btn-row btn-row-grow">
                    {this.state.mode === 'edit' ? null : (
                        <button className="btn btn-yellow btn-grow" onClick={this.props.cancelCallback}>
                            Cancel
                        </button>
                    )}
                    {this.renderButton()}
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    //accountToEdit: state.plants.currentAdminAccount
});

export default connect(mapStateToProps)(AdminAccountAddEdit);
