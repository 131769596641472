import React, { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { ImagePickerModal } from './ImagePickerModal';
import FontAwesome from 'react-fontawesome';

/**
 * @typedef {Object} ImagePickerProps
 * @property {bool} editable
 * @property {string | undefined} imageUrl
 *
 * @typedef {ImagePickerProps & import('./ImagePickerModal').ImagePickerModalProps & import('./useReportImages'.ReportImagesState)} Props
 *
 * @param {Props} props
 * @returns
 */
export const ImagePicker = ({
    editable,
    imageSize,
    imageUrl,
    onSubmit,
    onImageChange,
    defaultImages,
    isDefaultImagesLoading,
    imageType,
    ...props
}) => {
    const [display, setDisplay] = useState(false);
    const [defaultImage, setDefaultImage] = useState(null);

    const showImagePicker = () => {
        if (editable) setDisplay(true);
    };

    const onClose = () => {
        setDisplay(false);
    };

    useEffect(() => {
        if (!isDefaultImagesLoading) {
            const img = defaultImages?.find((image) => image.type === imageType);
            setDefaultImage(img);
        }
    }, [defaultImages, isDefaultImagesLoading]);

    return (
        <>
            <div
                onClick={showImagePicker}
                style={{
                    width: '50px',
                    height: '50px',
                    cursor: `${editable ? 'pointer' : 'default'}`,
                }}
            >
                {imageUrl ? (
                    <img
                        alt="thumbnail"
                        style={{
                            height: '50px',
                            aspectRatio: `${imageSize.width} / ${imageSize.height}`,
                        }}
                        src={imageUrl}
                    />
                ) : defaultImage ? (
                    <img
                        alt="thumbnail"
                        style={{
                            height: '50px',
                            aspectRatio: `${imageSize.width} / ${imageSize.height}`,
                        }}
                        src={defaultImage.url}
                    />
                ) : (
                    <FontAwesome name="file" size="3x" style={{ margin: '0 auto', color: '#919191' }} />
                )}
            </div>
            <ImagePickerModal
                {...props}
                imageType={imageType}
                imageSize={imageSize}
                isOpen={display}
                imageUrl={imageUrl}
                onSubmit={async (blobUrl, filename) => {
                    await onSubmit(blobUrl, filename);
                    onClose();
                }}
                onClose={onClose}
                onImageChange={(imageUrl) => {
                    onImageChange(imageUrl);
                    onClose();
                }}
            />
        </>
    );
};
