import React from 'react';
import NavContainer from '../../components/common/Nav/NavContainer';
import NavConfig from '../../components/common/Nav/NavConfig';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminUsers from '../AdminUsers';
import AdminPlants from '../AdminPlants';
import AdminAccounts from '../AdminAccounts';
import AdminProjects from '../AdminProjects/AdminProjects';
import AdminMachineNo from '../AdminMachineNo/AdminMachineNo';
import AdminPlantTabs from '../AdminPlantTabs';
import AdminAccountOverview from '../../components/common/AdminAccountOverview/AdminAccountOverview.js';
import AdminProjectOverview from '../../components/common/AdminProjectOverview/AdminProjectOverview.js';
import AdminTags from '../AdminTags';
import AdminToS from '../tos';
import AdminDataTypes from '../AdminDataTypes';
import AdminArticles from '../AdminArticles';
import AdminAccountAddEdit from '../../components/common/AdminAccountAddEdit/AdminAccountAddEdit.js';
import AdminFormulas from '../AdminFormulas';
import './admin.scss';
import AdminItemTypes from '../AdminItemTypes/AdminItemTypes';
import AdminJobTypes from '../AdminJobTypes/AdminJobTypes';
import AdminIotDevices from '../../components/common/AdminIotDevices/AdminIotDevices';
import AdminRole from '../../components/common/AdminRole/AdminRole';
import { withRouteMatch } from '../../HOC';

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            config: null,
            active: true,
            navVisible: false,
            currentItem: undefined,
        };
    }

    render() {
        const currentUser = this.props.user;
        if (currentUser && currentUser.profile.role !== 'admin' && currentUser.profile.role !== 'owner') {
            return <Redirect to="/" />;
        }

        return (
            <div className="admin-container">
                <h1>Admin</h1>
                <NavContainer
                    path={this.props.match}
                    item={this.state.currentItem}
                    config={null}
                    defaultConfig={NavConfig.admin}
                    plant={null}
                    user={this.props.user}
                />

                <Switch>
                    <Route path="/admin/users" name="Users">
                        <AdminUsers />
                    </Route>
                    <Route exact path="/admin/plant" name="Plants">
                        <AdminPlants />
                    </Route>
                    <Route exact path="/admin/account" name="Accounts">
                        <AdminAccounts />
                    </Route>
                    <Route path="/admin/tags" name="Tags">
                        <AdminTags />
                    </Route>
                    <Route path="/admin/plant/:plantId" name="Items">
                        <AdminPlantTabs />
                    </Route>
                    <Route exact path="/admin/account/edit" name="Account Edit">
                        <AdminAccountAddEdit />
                    </Route>
                    <Route path="/admin/account/:accountId" name="Account Edit">
                        <AdminAccountOverview />
                    </Route>
                    <Route path="/admin/projects" name="Projects">
                        <AdminProjects />
                    </Route>
                    <Route path="/admin/project/:projectId" name="Projects">
                        <AdminProjectOverview />
                    </Route>
                    <Route path="/admin/machines" name="MachineNo">
                        <AdminMachineNo />
                    </Route>
                    <Route path="/admin/itemtypes" name="Item types">
                        <AdminItemTypes user={this.props.user} />
                    </Route>
                    <Route path="/admin/operationtypes" name="Operation types">
                        <AdminJobTypes user={this.props.user} />
                    </Route>
                    <Route path="/admin/articles" name="Articles">
                        <AdminArticles user={this.props.user} />
                    </Route>
                    <Route path="/admin/datatypes" name="DataTypes">
                        <AdminDataTypes user={this.props.user} />
                    </Route>
                    <Route path="/admin/formulas" name="Formulas">
                        <AdminFormulas user={this.props.user} />
                    </Route>
                    <Route path="/admin/iotdevices" name="IotDevices">
                        <AdminIotDevices user={this.props.user} />
                    </Route>
                    <Route path="/admin/tos" name="Terms of Service">
                        <AdminToS user={this.props.user} />
                    </Route>
                    <Route path="/admin/role" name="Role">
                        <AdminRole />
                    </Route>
                </Switch>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default withRouteMatch(connect(mapStateToProps)(Admin));
