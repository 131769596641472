import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../../utility/api';
import { noop } from 'lodash';

class AdminPlantAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDirty: false,
            checkedItems: [],
            machineNo: {},
            inputValue: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            isDirty: true,
            userAdded: false,
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;
        const tmp = this.state.machineNo;
        tmp[name] = value;

        this.setState({ plant: tmp });
    }

    componentDidMount() {
        if (this.props.machineNo && this.props.type !== 'new') {
            this.setState({
                mode: 'edit',
                machineNo: this.props.machineNo,
            });
        }
    }

    fetchMachineNo(id) {
        api.get(process.env.REACT_APP_API + '/api/machineno/' + id)
            .then((res) => {
                this.setState({ machineNo: res.data });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.mode === 'edit') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body">
                            <h1>Save changes</h1>
                            <p>Are you sure you want to save these changes?</p>
                            <div className="buttons">
                                <button className="btn btn-yellow" onClick={onClose}>
                                    No, cancel
                                </button>
                                <button
                                    className="btn btn-red"
                                    onClick={() => {
                                        this.updateSubmit(event);
                                        onClose();
                                    }}
                                >
                                    Yes, save
                                </button>
                            </div>
                        </div>
                    );
                },
            });
        } else {
            this.createSubmit(event);
        }
    }

    createSubmit(event) {
        event.preventDefault();

        const machineNo = {
            machineNo: this.state.machineNo.machineNo,
            ce: this.state.machineNo.ce,
            madeIn: this.state.machineNo.madeIn,
            orderPos: this.state.machineNo.orderPos,
            projectNo: this.props.match.params.projectId,
            pos: this.state.machineNo.pos,
            description: this.state.machineNo.description,
            mechanicalMainDrawing: this.state.machineNo.mechanicalMainDrawing,
            programversion: this.state.machineNo.programversion,
            elDrawing: this.state.machineNo.elDrawing,
            testAssemblyPics: this.state.machineNo.testAssemblyPics,
            iat: this.state.machineNo.iat,
            assortedDocuments: this.state.machineNo.assortedDocuments,
            machines: this.state.machineNo.machines,
            watchOutForNextTime: this.state.machineNo.watchOutForNextTime,
            informationMachines: this.state.machineNo.informationMachines,
            typicalProblems: this.state.machineNo.typicalProblems,
            workAfterDelivery: this.state.machineNo.workAfterDelivery,
            column1: this.state.machineNo.column1,
            factoryAssemblyPics: this.state.machineNo.factoryAssemblyPics,
            year: this.state.machineNo.year,
        };

        api.post(process.env.REACT_APP_API + '/api/machineNos', machineNo)
            .then((res) => {
                if (res.status === 200) {
                    NotificationManager.success('MachineNo created', 'Plant successfully created', 5000);
                    this.props.editedCallback(res.data);
                } else {
                    NotificationManager.error('MachineNo not created', 'Error creating MachineNo', 5000);
                    throw new Error(res.status);
                }
            })
            .then((res) => {
                this.props.addedCallback(res);
            })
            .catch(noop);
    }

    updateSubmit(event) {
        event.preventDefault();

        const machineNo = {
            machineNo: this.state.machineNo.machineNo,
            ce: this.state.machineNo.ce,
            madeIn: this.state.machineNo.madeIn,
            orderPos: this.state.machineNo.orderPos,
            projectNo: this.state.machineNo.projectNo,
            pos: this.state.machineNo.pos,
            description: this.state.machineNo.description,
            mechanicalMainDrawing: this.state.machineNo.mechanicalMainDrawing,
            programversion: this.state.machineNo.programversion,
            elDrawing: this.state.machineNo.elDrawing,
            testAssemblyPics: this.state.machineNo.testAssemblyPics,
            iat: this.state.machineNo.iat,
            assortedDocuments: this.state.machineNo.assortedDocuments,
            machines: this.state.machineNo.machines,
            watchOutForNextTime: this.state.machineNo.watchOutForNextTime,
            informationMachines: this.state.machineNo.informationMachines,
            typicalProblems: this.state.machineNo.typicalProblems,
            workAfterDelivery: this.state.machineNo.workAfterDelivery,
            column1: this.state.machineNo.column1,
            factoryAssemblyPics: this.state.machineNo.factoryAssemblyPics,
            year: this.state.machineNo.year,
        };

        api.put(process.env.REACT_APP_API + '/api/machineNos/' + machineNo.machineNo, machineNo)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('MachineNo updated', 'MachineNo successfully updated', 5000);
                    this.props.editedCallback(response.data);
                } else {
                    NotificationManager.error('Error', 'Failed to update MachineNo', 5000);
                    // throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    renderButton() {
        let button;

        if (this.state.mode === 'edit') {
            button = (
                <button className="btn btn-blue btn-grow" value="Submit">
                    Save
                </button>
            );
        } else {
            button = (
                <button className="btn btn-blue btn-grow" value="Submit">
                    Create
                </button>
            );
        }

        return button;
    }

    render() {
        return (
            <div>
                <form className="admin-plant-add-edit-form" onSubmit={this.handleSubmit}>
                    {this.state.mode === 'edit' ? <h1>Edit MachineNo</h1> : <h1>New MachineNo</h1>}

                    <div>
                        <label htmlFor="machineNo">MachineNo</label>
                        <input
                            type="text"
                            disabled={this.state.mode === 'edit' ? true : false}
                            style={this.state.mode === 'edit' ? { cursor: 'not-allowed' } : {}}
                            className="admin-input-control"
                            name="machineNo"
                            value={this.state.machineNo.machineNo}
                            onChange={this.handleChange}
                        />
                    </div>

                    <div>
                        <label htmlFor="ce">CE</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="ce"
                            value={this.state.machineNo.ce}
                            onChange={this.handleChange}
                        />
                    </div>

                    <div>
                        <label htmlFor="madeIn">Made In</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="madeIn"
                            value={this.state.machineNo.madeIn}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="orderPos">Order Pos</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="orderPos"
                            value={this.state.machineNo.orderPos}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="pos">Pos</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="pos"
                            value={this.state.machineNo.pos}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="description">Description</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="description"
                            value={this.state.machineNo.description}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="mechanicalMainDrawing">Mechanical Main Drawing</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="mechanicalMainDrawing"
                            value={this.state.machineNo.mechanicalMainDrawing}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="programversion">Programversion</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="programversion"
                            value={this.state.machineNo.programversion}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="elDrawing">El Drawing</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="elDrawing"
                            value={this.state.machineNo.elDrawing}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="testAssemblyPics">Test Assembly Pics</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="testAssemblyPics"
                            value={this.state.machineNo.testAssemblyPics}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="iat">IAT</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="iat"
                            value={this.state.machineNo.iat}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="assortedDocuments">Assorted Documents</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="assortedDocuments"
                            value={this.state.machineNo.assortedDocuments}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="machines">machines</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="machines"
                            value={this.state.machineNo.machines}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="watchOutForNextTime">Watch out for next time</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="watchOutForNextTime"
                            value={this.state.machineNo.watchOutForNextTime}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="informationMachines">Information Machines</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="informationMachines"
                            value={this.state.machineNo.informationMachines}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="typicalProblems">Typical Problems</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="typicalProblems"
                            value={this.state.machineNo.typicalProblems}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="workAfterDelivery">Work After Delivery</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="workAfterDelivery"
                            value={this.state.machineNo.workAfterDelivery}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="column1">Column1</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="column1"
                            value={this.state.machineNo.column1}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="factoryAssemblyPics">Factory Assembly Pics</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="factoryAssemblyPics"
                            value={this.state.machineNo.factoryAssemblyPics}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="year">Year</label>
                        <input
                            type="text"
                            className="admin-input-control"
                            name="year"
                            value={this.state.machineNo.year}
                            onChange={this.handleChange}
                        />
                    </div>

                    <div className="btn-row btn-row-grow">
                        <button
                            className="btn btn-yellow btn-grow"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.cancelCallback();
                            }}
                        >
                            Cancel
                        </button>
                        {this.renderButton()}
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plants.currentAdminPlant,
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminPlantAddEdit);
