//provides basic and reusable functions used when configuring and generating sections

export const isRepeaterSection = (sectionType) => {
    if (!sectionType) return false;

    return sectionType === 'repeatersection';
};
export const getSectionTypeLabel = (sectionType) => {
    if (typeof sectionType === 'undefined' || sectionType.length === 0) return 'Default';
    switch (sectionType) {
        case 'repeatersection':
            return 'Repeater Section';
        default:
            return 'Unknown';
    }
};
