import format from 'date-fns/format';
import moment from 'moment';
import 'moment/locale/en-gb';
import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { connect } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import api from '../../../utility/api';
import { guidIsNullOrEmpty } from '../../../utility/guidFunctions';
import { tryParseJson } from '../../../utility/jsonFunctions';
import { validate } from '../../../utility/validationHelper';
import ItemDropdown from '../ItemDropdown/ItemDropdown';
import ModalDatetime from '../ModalWrapper/ModalDatetime';
import './JobAddEdit.scss';

class JobAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job: null,
            jobtypes: [],
            validStart: true,
            validEnd: true,
            dynamicData: {},
            isFetching: false,
            wasEdited: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.handleOnFocusOut = this.handleOnFocusOut.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.dateValidation = this.dateValidation.bind(this);
        this.fetchingStructure = false;
        this.fetchingTags = false;
        this.handleConfigChange = this.handleConfigChange.bind(this);
        this.handleComponentChange = this.handleComponentChange.bind(this);

        this.endDateRef = React.createRef();
        this.startDateRef = React.createRef();
    }

    handleOnFocusOut() {
        validate();
    }

    componentDidMount() {
        this.fetchJobTypes();
        if (this.props.editType === 'edit') {
            this.setState({
                job: this.props.editItem,
                dynamicData: tryParseJson(this.props.editItem.data, {})
            });
        } else if (this.props.editType !== 'edit') {
            const job = {
                data: '',
                endDate: null,
                parentJobId: this.props.editType === 'child' ? this.props.editItem.id : null,
                startDate: new Date(),
                topic: null,
                type: null,
                jobTypeId: null,
                plantId: this.props.plantId,
                relationship: null,
            };

            this.setState({ job });
        }
    }

    componentDidUpdate() {}

    fetchJobTypes = async () => {
        const url = `${process.env.REACT_APP_API}/api/jobs/types/${this.props.plantId}`;

        const res = await api.get(url);

        const jobtypes = res.data.map((j) => {
            return {
                value: j.id,
                label: j.name,
                parentId: j.parentId,
                parentName: j.parentName,
                plantId: j.plantId,
                configuration: j.configuration,
            };
        });

        this.setState({
            jobtypes,
            parent: this.props.editType === 'child' ? this.props.editItem : null,
            parentType:
                this.props.editType === 'child'
                    ? jobtypes.filter((j) => j.value === this.props.editItem.jobTypeId)?.[0]
                    : null,
        });
    };

    handleTypeChange = (newValue, event) => {
        if (event.action === 'create-option') {

            let url = `${process.env.REACT_APP_API}/api/jobs/types/${this.props.plantId}/${newValue.label}`;

            if (this.props.editType === 'child') {
                url = url + `/${this.props.editItem.jobTypeId}`;
            }

            api.post(url).then((res) => {
                this.setState(
                    {
                        jobtypes: res.data.map((j) => {
                            return {
                                value: j.id,
                                label: j.name,
                                configuration: '',
                                parentId : j.parentId
                            };
                        }),
                    },
                    () => {
                        const job = Object.assign({}, this.state.job);
                        job.jobTypeId = this.state.jobtypes.filter((j) => j.label === newValue.label)[0].value;
                        this.setState({ job: job, wasEdited : true });
                    }
                );
            });
        } else {
            const job = Object.assign({}, this.state.job);
            job.jobTypeId = newValue?.value;
            job.configuration = newValue?.configuration || '[]';

            const dynData = this.buildDynamicDataWithDefaultValues(newValue);

            this.setState({ job: job, dynamicData: dynData, wasEdited : true });
        }
    };

    handleSelectChange(name, selected) {
        if (name === 'relationship') {
            const tmp = this.state.job;            

            let selectedValue = "";

            if (selected && selected.value) {
                selectedValue = selected.value;
            }

            tmp[name] = selectedValue;
            
            this.setState({ job: tmp, wasEdited : true });
        } else {
            const dynamicData = this.state.dynamicData;
            dynamicData[name] = selected.value;

            this.setState({ dynamicData, wasEdited : true });
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = this.state.job;
        tmp[name] = value;

        this.setState({ job: tmp, wasEdited : true });
    }

    dateValidation(date, type) {
        // temporary disable date validation regarding parent times
        return this.regularDateValidation(date, type);

        if (this.state.job.relationship === 'Part of') {
            if (
                (this.state.parent?.startDate
                    ? moment(date).isSameOrAfter(moment(this.state.parent.startDate).startOf('day'))
                    : true) &&
                (this.state.parent?.endDate
                    ? moment(date).isSameOrBefore(moment(this.state.parent?.endDate).endOf('day'))
                    : true)
            ) {
                return this.regularDateValidation(date, type);
            }
        } else {
            if (
                this.state.parent?.endDate
                    ? moment(date).isSameOrAfter(moment(this.state.parent.endDate).startOf('day'))
                    : true
            ) {
                return this.regularDateValidation(date, type);
            }
        }
    }

    regularDateValidation(date, type) {
        if (type === 'start') {
            return this.state.job.endDate ? date.isSameOrBefore(moment(this.state.job.endDate).endOf('day')) : true;
        } else if (type === 'end') {
            return this.state.job.startDate
                ? date.isSameOrAfter(moment(this.state.job.startDate).startOf('day'))
                : true;
        }
        return;
    }

    handleDateSelect(date, name, dynamic) {
        if (dynamic) {
            const dynamicData = this.state.dynamicData;
            dynamicData[name] = date;

            this.setState({ dynamicData, wasEdited : true });
        } else {
            const tmp = this.state.job;
            tmp[name] = date;

            this.setState({
                job: tmp,
                showDatePicker: false, 
                wasEdited : true
            });
        }
    }

    selectItem(item) {
        const tmp = this.state.job;
        tmp.topic = item.topic != null ? item.topic.substring(item.topic?.indexOf('/') + 1) : '';

        this.setState({
            job: tmp,
            wasEdited : true
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const job = Object.assign({}, this.state.job);
        job.data = JSON.stringify(this.state.dynamicData);   

        const startDate = new Date(job.startDate);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        job.startDate = startDate;

        if (job.endDate){            
            const endDate = new Date(job.endDate);
            endDate.setSeconds(0);
            endDate.setMilliseconds(0);
            job.endDate = endDate;

            if (job.startDate.getTime() > job.endDate.getTime()) {
                NotificationManager.error('End date should be always greater than start date', 'Error creating job', 5000);
                return;
            }
        }  
        
        this.setState({isFetching : true});

        if (this.props.editType === 'edit') {
            this.updateSubmit();
        } else {
            this.createSubmit();
        }
    }

    createSubmit() {
        const job = Object.assign({}, this.state.job);
        job.data = JSON.stringify(this.state.dynamicData);

        api.post(process.env.REACT_APP_API + '/api/jobs', job).then((response) => {
            if (response.status === 200) {
                this.props.toggleModal();
                NotificationManager.success('Job created successfully', 'Job created', 5000);
            } else {
                NotificationManager.error('Job not created successfully', 'Error creating job', 5000);
            }
        }).catch((error) => {
            let errorMessage = 'Job not created successfully';

            if (error?.response?.data) {
                errorMessage = error.response.data;
            }

            NotificationManager.error(errorMessage, 'Error creating job', 5000);            
        }).finally(() => {
            this.setState({isFetching : false});
        });
    }

    updateSubmit() {
        const job = Object.assign({}, this.state.job);
        job.data = JSON.stringify(this.state.dynamicData);

        api.put(process.env.REACT_APP_API + '/api/jobs/' + this.state.job.id, job).then((response) => {
            if (response.status === 200) {
                this.setState({
                    editItem: null,
                });

                NotificationManager.success('Job updated successfully', 'Job updated', 5000);
                this.props.toggleModal();
            } else {
                NotificationManager.error('Job not updated successfully', 'Error updating job', 5000);
            }
        }).catch((error) => {
            let errorMessage = 'Job not updated successfully';

            if (error?.response?.data) {
                errorMessage = error.response.data;
            }

            NotificationManager.error(errorMessage, 'Error updating job', 5000);
        }).finally(() => {
            this.setState({isFetching : false});
        });
    }

    renderButtons() {
        const job = this.state.job;
        let isValidForm = job.topic && job.jobTypeId && job.plantId;

        if (this.props.editType === 'edit' && !this.state.wasEdited) {            
            isValidForm = false;
        }
        
        const button = [
            <button
                key="btnCancelJobAddEdit"
                className="btn btn-yellow btn-grow"
                onClick={(e) => {
                    e.preventDefault();
                    this.props.toggleModal("Cancel");
                }}
            >
                Cancel
            </button>,
        ];

        if (this.props.editType === 'edit') {
            button.push(
                <button
                    key="btnUpdateJobAddEdit"
                    disabled={!isValidForm || this.state.isFetching}
                    className="btn btn-blue btn-grow"
                    value="Submit"
                >
                    Update
                </button>
            );
        } else {
            button.push(
                <button
                    key="btnCreateJobAddEdit"
                    disabled={!isValidForm || this.state.isFetching}
                    className="btn btn-blue btn-grow"
                    value="Submit"
                >
                    Create
                </button>
            );
        }

        return button;
    }

    handleConfigChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const tmp = this.state.job;

        const dynamicData = this.state.dynamicData;
        dynamicData[name] = value;

        this.setState({ job: tmp, dynamicData, wasEdited : true });
    }

    buildDynamicDataWithDefaultValues(jobtype) {
        const dynData = {};
        const config = tryParseJson(jobtype?.configuration, {});

        if (config?.fields && config.fields.map)
            config.fields.map((fld) => {
                if (typeof fld.defaultValue === 'undefined') return;

                switch (fld.type) {
                    case 'date':
                        if (fld.defaultValue === 'NOW()') dynData[fld.name] = new Date();
                        else dynData[fld.name] = fld.defaultValue;
                        break;
                    default:
                        dynData[fld.name] = fld.defaultValue;
                        break;
                }
            });
        return dynData;
    }

    editField(field) {
        const data = this.state.dynamicData;
        const label = field.label || field.name;
        //var defaultValue = field.defaultValue;
        //console.log("defaultvalue??",defaultValue);
        //todo: add some kind of signaling to show if "showInList:true/false??"
        if (field.type === 'array') {
            const preppedChoices = field.choices.map((c) => {
                return { value: c, label: c };
            });
            return (
                <div className={'form-group'}>
                    <label htmlFor={field.name}>{label}</label>
                    <Select
                        isClearable
                        onChange={this.handleSelectChange.bind(this, field.name)}
                        options={preppedChoices}
                        classNamePrefix={'optimar'}
                        value={preppedChoices.find((p) => p.value === data?.[field.name])}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            );
        } else if (field.type === 'date') {
            return (
                <div className="form-group">
                    <label htmlFor={field.name}>{label}</label>
                    <Datetime
                        name={field.name}
                        inputProps={{ readOnly: true }}
                        locale="en-gb"
                        dateFormat="DD.MM.YYYY"
                        timeFormat="HH:mm"
                        value={data?.[field.name] ? format(new Date(data?.[field.name]), 'dd.MM.yyyy HH:mm') : null}
                        onChange={(date) => this.handleDateSelect(date, field.name, true)}
                        className={'date-input-control'}
                    />
                </div>
            );
        } else if (field.type === 'text') {
            return (
                <div className="form-group">
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="text"
                        className={'input-control'}
                        name={field.name}
                        onChange={this.handleConfigChange}
                        value={data?.[field.name]}
                    />
                </div>
            );
        } else if (field.type === 'number') {
            return (
                <div className="form-group">
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="number"
                        className={'input-control'}
                        name={field.name}
                        onChange={this.handleConfigChange}
                        value={data?.[field.name]}
                    />
                </div>
            );
        } else if (field.type === 'boolean') {
            return (
                <div className="form-group">
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="checkbox"
                        className={'input-control'}
                        name={field.name}
                        onChange={this.handleConfigChange}
                        checked={data?.[field.name]}
                    />
                </div>
            );
        } else {
            //not supported
            return <div></div>;
        }
    }

    handleComponentChange(e) {
        let jobToUpdate = {...this.state.job};
        jobToUpdate.topic = '';

        this.setState({
            job: jobToUpdate,
            wasEdited : true
        });
    }

    render() {
        const config = tryParseJson(this.state.job?.configuration, {}); //JSON.parse(this.state.job?.configuration || '[]');

        let parentDisplayName = '';
        let parentDisplayHeader = '';
        let headerDisplayValue = '';
        let availableJobTypes = [];
        if (this.props.editType === 'child') {
            const parentData = tryParseJson(this.state.parent?.data, {}); //this.state.parent?.data ? JSON.parse(this.state.parent?.data) : {};
            const parentConfig = tryParseJson(this.state.parentType?.configuration, {}); //this.state.parentType?.configuration ? JSON.parse(this.state.parentType?.configuration) : {};

            parentDisplayName = parentData[parentConfig?.relationDisplayConfig?.displayValue];
            parentDisplayHeader = parentConfig?.relationDisplayConfig?.header;

            headerDisplayValue = parentDisplayName
                ? parentDisplayHeader?.toLowerCase() + ' ' + parentDisplayName
                : this.state.parent?.type 
                ? 'type ' + this.state.parent?.type
                : '';
            const parentJobType = this.state.parent?.jobTypeId;

            availableJobTypes = this.state.jobtypes
                .filter((x) => x.parentId === parentJobType)
                .sort((a, b) => a.label?.localeCompare(b.label) ?? -1);
        } else {
            availableJobTypes = this.state.jobtypes;

            let parentJobTypeId = null;

            if (this.props.editType === 'edit') {
                parentJobTypeId = availableJobTypes.find(j => j.value === this.props.editItem.jobTypeId)?.parentId;
            }

            if (parentJobTypeId){
                availableJobTypes = availableJobTypes
                    .filter(jt => jt.parentId === parentJobTypeId);
            } else {
                availableJobTypes = availableJobTypes
                    .filter((x) => guidIsNullOrEmpty(x.parentId));
            }

            availableJobTypes = availableJobTypes
                .sort((a, b) => a.label?.localeCompare(b.label) ?? -1);
        }

        return this.state.job != null ? (
            <div className="form-container">
                <div className="form-header">
                    <h2>
                        {this.props.editType === 'edit'
                            ? 'Edit operation'
                            : this.props.editType === 'child'
                            ? 'Add child operation to ' + headerDisplayValue
                            : 'Add operation'}
                    </h2>
                </div>

                {this.state.showFormSuccess ? this._renderSuccessMessage() : null}
                <form onSubmit={this.handleSubmit}>
                    <ItemDropdown
                        modalRef={this.props.modalRef}
                        plantId={this.props.plantId}
                        onComponentChange={this.handleComponentChange}
                        selectItem={(item) => this.selectItem(item)}
                        selectedItem={this.state.job?.topic}
                        required={true}
                    />

                    <div className="form-group">
                        <label htmlFor="type">Type</label>
                        <CreatableSelect
                            isClearable
                            onChange={this.handleTypeChange}
                            options={availableJobTypes}
                            setValue={this.createNewJobType}
                            value={this.state.jobtypes?.find((j) => j.value === this.state.job?.jobTypeId)}
                            classNamePrefix={'optimar'}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>

                    <div className="form-group" style={{ height: '100px' }}>
                        <label htmlFor="startDate">Start Date</label>
                        <div ref={this.startDateRef} />
                        <ModalDatetime
                            modalRef={this.props.modalRef}
                            scopeToParentRef={this.startDateRef}
                            name="startDate"
                            isValidDate={(date) => {
                                return this.props.editType === 'child'
                                    ? this.dateValidation(date, 'start')
                                    : this.regularDateValidation(date, 'start');
                            }}
                            inputProps={{ readOnly: true }}
                            locale="en-gb"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            value={
                                this.state.job && this.state.job.startDate
                                    ? moment.utc(this.state.job.startDate).local()
                                    : moment().utc()
                            }
                            onChange={(date) => this.handleDateSelect(date, 'startDate')}
                            className={'date-input-control'}
                        />
                        {/* <ModalChildWrapper modalRef={this.props.modalRef} scopeToParentRef={this.startDateRef}  >
                                <Datetime name="startDate" isValidDate={(date) => { return this.props.editType === "child" ? this.dateValidation(date, "start") : this.regularDateValidation(date, "start") }} inputProps={{ readOnly: true }} locale="en-gb" dateFormat="DD.MM.YYYY" timeFormat="HH:mm" value={this.state.job && this.state.job.startDate ? moment.utc(this.state.job.startDate).local() : moment().utc()} onChange={(date) => this.handleDateSelect(date, "startDate")} className={"date-input-control"} />
                            
                            </ModalChildWrapper> */}
                    </div>

                    <div className="form-group" style={{ height: '100px' }}>
                        <label htmlFor="endDate">End Date</label>
                        <div ref={this.endDateRef} />
                        {/* <ModalChildWrapper modalRef={this.props.modalRef} scopeToParentRef={this.endDateRef}  > */}
                        <ModalDatetime
                            modalRef={this.props.modalRef}
                            scopeToParentRef={this.endDateRef}
                            name="endDate"
                            isValidDate={(date) => {
                                return this.props.editType === 'child'
                                    ? this.dateValidation(date, 'end')
                                    : this.regularDateValidation(date, 'end');
                            }}
                            inputProps={{ readOnly: true }}
                            locale="en-gb"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            value={
                                this.state.job && this.state.job.endDate
                                    ? moment.utc(this.state.job.endDate).local()
                                    : null
                            }
                            onChange={(date) => this.handleDateSelect(date, 'endDate')}
                            className={'date-input-control'}
                        />
                        {/* </ModalChildWrapper> */}
                    </div>

                    {config?.fields?.map((f) => {
                        return this.editField(f);
                    })}
                    <div className="btn-row-grow">{this.renderButtons()}</div>                    
                </form>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentPlant,
});

export default connect(mapStateToProps)(JobAddEdit);
