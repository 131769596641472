import React, { useRef } from 'react';
import { confirmDelete } from '../../joblist/listdefinition';
import Modal from 'react-modal';
import { modalStyle } from '../../../utility/modalstyles';
import JobAddEdit from '../../../components/common/JobAddEdit/JobAddEdit';
import ForwaredJobsTable from './table/jobstable';

import moment from 'moment';
import { constant } from 'lodash';
import { ReactComponent as AngleRight } from '../../../images/icons/optimar_angle_right_white.svg';
import { ReactComponent as AngleDown } from '../../../images/icons/optimar_angle_down.svg';
import { ReactComponent as Warning } from '../../../images/table/warning.svg';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import RowContextMenu from '../../../components/common/GenericTable/TableWidgets/RowContextMenu';
import './joblist.scss';
import { getDataCol } from '../../../components/common/GanttChart/GanttChartConfiguration';

const JobList = (props) => {
    const [editType, setEditType] = React.useState('edit');
    const [currentItem, setCurrentItem] = React.useState(null);
    const [modalOpen, setModalState] = React.useState(false);
    const [jobs, setJobs] = React.useState();

    const genericTableFunctionsRef = useRef(null);

    React.useEffect(() => {
        props.onResetOperationChanges();
    }, []);

    React.useEffect(() => {
        if (props.data) {
            let result = processData(props.data, props.globalFilterValue, props.plantSelectedOptions);
            props.onNumberOfPlantsChange(result.numberOfPlants);
            setJobs(result.jobs);
        }
    }, [props.data, props.globalFilterValue, props.plantSelectedOptions, props.jobTypes]);

    //#region Modals
    const toggleModal = (item, type) => {
        setEditType(type);
        setCurrentItem(item);
        setModalState(!modalOpen);
    };

    function deleteOperation(job) {
        confirmDelete(job, props.onDeleteJob);
    }

    function editOperation(job) {
        toggleModal(job, 'edit');
    }

    function addChildOperation(job) {
        toggleModal(job, 'child');
    }

    function toggleJobDetails(job) {
        props.onToggleJobDetails({
            data: job.data,
            startDate: job.startDate,
            endDate: job.endDate,
            parentJobId: job.parentJobId,
            topic: job.topic,
            type: job.type,
            jobTypeId: job.jobTypeId,
            plantId: job.plantId,
            relationship: job.relationship,
            id: job.id,
        });
    }

    const generateJobReport = (item) => {
        props.onGenerateJobReport(item);
    };

    const transferOperation = (item) => {
        props.onTransferOperation(item);
    };
    //#endregion

    const columns = getColumns({
        editOperation,
        addChildOperation,
        deleteOperation,
        generateJobReport,
        transferOperation,
        toggleJobDetails,
        jobTypes: props.jobTypes,
    });

    return (
        <div className="joblist">
            {jobs && jobs.length > 0 ? (
                <ForwaredJobsTable ref={genericTableFunctionsRef} data={jobs} columns={columns} />
            ) : (
                <div style={{ textAlign: 'center' }}>No operations are available</div>
            )}
            {currentItem && (
                <Modal
                    ref={props.modalRef}
                    isOpen={modalOpen}
                    style={modalStyle}
                    onRequestClose={() => {
                        props.fetchJobs();
                        toggleModal();
                    }}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <JobAddEdit
                        modalRef={props.modalRef}
                        editItem={currentItem}
                        editType={editType}
                        plantId={currentItem.plantId}
                        toggleModal={() => {
                            props.fetchJobs();
                            toggleModal(null);
                        }}
                    />
                </Modal>
            )}
        </div>
    );
};

function getColumns({
    editOperation,
    addChildOperation,
    deleteOperation,
    generateJobReport,
    transferOperation,
    toggleJobDetails,
    jobTypes,
}) {
    const isDate = (date, fieldConfig) => {
        if (fieldConfig?.type.indexOf('date') < 0) {
            return false;
        }
        const isnan = isNaN(date);

        if (isnan === false) {
            return false;
        }

        const d = moment(date);
        const earliestDate = moment('1990-01-01');

        return d.isValid() && d.isAfter(earliestDate);
    };

    const rowContext = [
        {
            content: <span>Dashboard & details</span>,
            action: (row) => toggleJobDetails(row.original),
        },
        {
            content: <span>Edit operation</span>,
            action: (row) => editOperation(row.original),
        },
        {
            content: <span>New child operation</span>,
            action: (row) => addChildOperation(row.original, 'child'),
        },
        {
            content: <span>Delete operation</span>,
            action: (row) => deleteOperation(row.original),
        },
        {
            content: <span>Download report</span>,
            action: (row) => generateJobReport(row.original),
            hideContext: ({ original }) => !jobTypes.find((x) => original.jobTypeId === x.value)?.reportConfigId,
        },
        {
            content: <span>Transfer</span>,
            action: (row) => transferOperation(row.original),
        },
    ];

    return [
        {
            Header: constant(null),
            id: 'expander',
            Cell: ({ row, _rows, _toggleRowExpanded }) => {
                return row.original.jobTypeId === '00000000-0000-0000-0000-000000000000' &&
                    !row.original?.project ? null : (
                    <span
                        className="row-expander-icon"
                        {...row.getToggleRowExpandedProps({
                            onClick: () => {
                                row.toggleRowExpanded();
                            },
                        })}
                    >
                        {row.subRows && row.subRows.length > 0 ? (
                            row.isExpanded ? (
                                <AngleDown fill="red" stroke="red" />
                            ) : (
                                <AngleRight fill="red" stroke="red" />
                            )
                        ) : null}
                        <span style={{ maxHeight: '200px', display: 'flex', alignItems: 'center' }}>
                            {row.original.isOrphan ? <Warning /> : null}
                        </span>
                    </span>
                );
            },
        },
        {
            Header: 'Plant',
            accessor: 'plantName',
            Cell: (row) => {
                return <div>{row.value}</div>;
            },
        },
        {
            Header: 'Type',
            accessor: 'type',
            Cell: (row) => {
                if (row.row.original?.project) {
                    return <div />;
                }

                return (
                    <div className="clickable" onClick={() => toggleJobDetails(row.row.original)}>
                        {row.value}
                    </div>
                );
            },
        },
        {
            Header: 'Data',
            accessor: 'data',
            Cell: ({ row }) => {
                return getDataCol(row.original, jobTypes);
            },
        },
        {
            Header: 'Start Date',
            accessor: 'startDate',
            Cell: (row) => <div>{row.value ? moment.utc(row.value).local().format('DD.MM.YYYY HH:mm') : null}</div>,
        },
        {
            Header: 'End Date',
            accessor: 'endDate',
            Cell: (row) => <div>{row.value ? moment.utc(row.value).local().format('DD.MM.YYYY HH:mm') : null}</div>,
        },
        {
            Header: 'Time',
            Cell: ({ row }) => {
                if (row.original?.startDate && row.original?.endDate) {
                    const fullDiff = differenceInSeconds(
                        new Date(row.original?.endDate),
                        new Date(row.original?.startDate)
                    );
                    const nowDiff = differenceInSeconds(new Date(), new Date(row.original?.startDate));
                    let currentPercent = (nowDiff / fullDiff) * 100;
                    currentPercent = currentPercent > 100 ? 100 : currentPercent;
                    currentPercent = currentPercent < 0 ? 0 : currentPercent;

                    return (
                        <div>
                            <div className="bar-gauge-bar" style={{ width: '100px' }}>
                                <div
                                    className="bar-gauge-bar-progress"
                                    style={{
                                        width: `${currentPercent}%`,
                                        backgroundImage:
                                            'linear-gradient(90deg, rgba(65,200,240,0), rgba(65,200,240,0.6))',
                                    }}
                                />
                            </div>
                        </div>
                    );
                }
                return <div />;
            },
        },
        {
            Header: 'Modified',
            accessor: 'modifiedOn',
            Cell: (row) =>
                row.row?.original?.project ? (
                    <div></div>
                ) : (
                    <div>{moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss')}</div>
                ),
        },
        {
            Header: constant(null),
            id: 'rowcontext',
            Cell: ({ row }) =>
                row.original?.project ? <div></div> : <RowContextMenu options={rowContext} row={row} />,
        },
    ];
}

function processData(data, searchField, plantSelectedOptions) {
    let resultList = [];
    let numberOfPlants = 0;

    if (searchField) {
        data = data.filter((item) => item.type.toLowerCase().includes(searchField.toLowerCase()));
    }

    const items = data.reduce((r, a) => {
        r[a.plantId] = [...(r[a.plantId] || []), a];
        return r;
    }, {});

    let keys = Object.keys(items);

    if (plantSelectedOptions) {
        const plantSelectedOptionIds = plantSelectedOptions.map((x) => x.value);
        keys = keys.filter((id) => plantSelectedOptionIds.includes(id));
    }

    keys.forEach((key) => {
        let rootData = items[key].filter(
            (item) => item.parentJobId === null || !items[key].map((k) => k.id).includes(item.parentJobId)
        );

        let plantRow = createProjectRow(rootData, items, key);

        numberOfPlants++;

        resultList.push(plantRow);

        processTreeData(rootData, items[key]);
    });
    return {
        jobs: resultList,
        numberOfPlants: numberOfPlants,
    };
}

function processTreeData(items, data, indentLevel = 1) {
    for (let index in items) {
        const subRows = data.filter((item) => item.parentJobId === items[index].id) || [];
        const childCount = subRows.length;

        items[index].subRows = subRows;
        items[index].childCount = childCount;
        items[index].indentLevel = indentLevel;

        if (childCount > 0) {
            processTreeData(subRows, data, indentLevel + 1);
        }
    }
}

function createProjectRow(rootData, items, key) {
    let item = items[key][0];

    let startDate = new Date(item.startDate);
    let endDate = new Date(item.endDate);

    items[key].forEach((x) => {
        if (new Date(x.startDate) < startDate) {
            startDate = new Date(x.startDate);
        }

        if (new Date(x.endDate) > endDate) {
            endDate = new Date(x.endDate);
        }
    });

    return {
        startDate: startDate,
        endDate: endDate,
        plantName: item.plantName,
        id: item.plantId,
        subRows: rootData,
        childCount: rootData.length,
        indentLevel: 0,
        project: true,
    };
}

export default JobList;
