export const getNotificationLink = (n) => {
    let link = '';
    switch (n.type.toLowerCase()) {
        case 'note':
        case 'comment':
            if (n.itemId) {
                link = `/plant/${n.plantId}/items/${n.itemId}/note`;
            } else {
                link = `/plant/${n.plantId}/note`;
            }

            break;
        case 'support':
        case 'supportcomment':
        case 'supportcommentremoved':
        case 'supportupdate':
        case 'supportassignedto':
        case 'supportstatuschanged':
        case 'supportdocument':
            if (n.itemId) {
                link = `/plant/${n.plantId}/items/${n.itemId}/support`;
            } else {
                link = `/plant/${n.plantId}/support`;
            }
            break;
        default:
            link = '';
    }
    return link;
};

export const getNotificationMessage = (notification) => {
    if (!notification) {
        return '';
    }

    const type = notification.type ?? '';
    const objectName = notification.objectName;

    switch (type.toLowerCase()) {
        case 'comment':
            return `New comment on note "${objectName}"`;
        case 'note':
            return `New note: ${objectName}`;
        case 'support':
            return `New support case: ${objectName}`;
        case 'supportcomment':
            return `New comment on support case ${objectName}`;
        case 'supportcommentremoved':
            return `Comment removed from support case ${objectName}`;
        case 'supportassignedto':
            return `Support case assigned: ${objectName}`;
        case 'supportstatuschanged':
            return `Support case changed status: ${objectName}`;
        case 'supportdocument':
            return `Document added to support case ${objectName}`;
        case 'supportdocumentremoved':
            return `Document removed from support case ${objectName}`;
        case 'supportupdate':
            return `Support case updated: ${objectName}`;
        default:
            return 'New notification';
    }
};

export const getNotificationMessageShort = (type) => {
    switch (type.toLowerCase()) {
        case 'comment':
            return `New comment`;
        case 'note':
            return `New note`;
        case 'support':
            return `New support case`;
        case 'supportcomment':
            return `New comment on support case`;
        case 'supportcommentremoved':
            return `Comment removed from support case`;
        case 'supportassignedto':
            return 'Support case assigned';
        case 'supportstatuschanged':
            return 'Support case changed status';
        case 'supportdocument':
            return 'Document added to support case';
        case 'supportdocumentremoved':
            return 'Document removed from support case';
        case 'supportupdate':
            return 'Support case updated';
        default:
            return 'New notification';
    }
};

export const isValidNotificationType = (notificationType) => {
    const name = notificationType.name ?? '';
    switch (name.toLowerCase()) {
        case 'none':
        case 'all':
            return false;
        default:
            return true;
    }
};

export const getNotificationPath = (n) => {
    let path = '';
    if (n.plantName) {
        path += n.plantName;
        if (n.itemPath) {
            path += '/' + n.itemPath;
        }
    }
    return path;
};

export const getNotificationItem = (n) => {
    console.log(n);
    return n.objectName;
};

export const getSearchParams = (n) => {
    console.log(n);
    if (!n.type || !n.type.toLowerCase().includes('support')) return '';

    return `?supportId=${n.object}`;
};
