export const SET_NOTIFICATIONLIST = 'SET_NOTIFICATIONLIST';
export const CLEAR_NOTIFICATIONLIST = 'CLEAR_NOTIFICATIONLIST';
export const SET_USER_NOTIFICATION_SETTINGS = 'SET_USER_NOTIFICATION_SETTINGS';
export const SET_NOTIFICATION_TYPES = 'SET_NOTIFICATION_TYPES';

export const setNotificationList = (alerts) => {
    return {
        type: SET_NOTIFICATIONLIST,
        data: alerts,
    };
};

export const clearNotificationList = (alerts) => {
    return {
        type: CLEAR_NOTIFICATIONLIST,
        data: alerts,
    };
};

export const setUserNotificationSettings = (userNotificationSettings) => {
    return {
        type: SET_USER_NOTIFICATION_SETTINGS,
        data: userNotificationSettings,
    };
};

export const setNotificationTypes = (notificationTypes) => {
    return {
        type: SET_NOTIFICATION_TYPES,
        data: notificationTypes,
    };
};
