import { ViewMode } from 'gantt-task-react';
import React, { createContext, useContext, useState } from 'react';

const JoblistProviderContext = createContext();

const JoblistProvider = ({ children }) => {
    const [changedTasks, setChangedTasks] = useState([]);
    const [isChecked, setIsChecked] = React.useState(true);

    const [view, setView] = React.useState(ViewMode.Day);

    return (
        <JoblistProviderContext.Provider
            value={{ view, setView, isChecked, setIsChecked, changedTasks, setChangedTasks }}
        >
            {children}
        </JoblistProviderContext.Provider>
    );
};
export const useJoblistProviderContext = () => useContext(JoblistProviderContext);
export default JoblistProvider;
