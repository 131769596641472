import { useCallback, useEffect, useState } from 'react';
import api from '../../utility/api';

/**
 * @typedef {Object} DefaultImage
 *
 * @property {boolean} isLoading
 * @property {Array<DefaultImage> | undefined} images
 *
 * @returns {ReportImagesState}
 */
export const useDefaultImages = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState();

    const fetchImages = useCallback(async () => {
        setIsLoading(true);
        const defaultImages = await fetchDefaultImages();
        setImages(defaultImages);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchImages();
    }, [fetchImages]);

    return { images, isLoading };
};

export const fetchDefaultImages = async () => {
    return (await api.get(`api/reportlogo/defaultImages`)).data;
};
