import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import api from '../../../utility/api';
import moment from 'moment';

import Modal from 'react-modal';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminPlantLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.visibleColumns = [
            {
                Header: 'Timestamp',
                accessor: 'time',
                width: 180,
                filterable: true,
                Cell: (props) => <span>{moment(props.value).format('DD.MM.YYYY HH:mm:ss')}</span>,
            },
            { Header: 'Content', accessor: 'data', filterable: false },
        ];

        this.fetchPerfs = this.fetchPerfs.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        this.fetchPerfs();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) this.fetchPerfs();
    }

    fetchPerfs() {
        if (this.props.plant) {
            api.get(
                process.env.REACT_APP_API +
                    '/api/admin/plants/' +
                    this.props.plant.id +
                    '/log?topic=' +
                    this.props.plant.topicKey
            )
                .then((res) => {
                    if (this.mounted) {
                        this.setState({
                            data: res.data,
                        });
                    }
                })
                .catch(() => {
                    NotificationManager.error('Failed to fetch', 'Could not fetch host info');
                });
        }
    }

    openModal(item, mode) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                editType: mode,
            },
            function () {
                this.fetchProjects();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
                editType: null,
            },
            function () {
                this.fetchProjects();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase()) : false;
    }

    generateHtmlFromJson(data) {
        const htmlArr = [];
        for (const key in data) {
            if (typeof data[key] == 'object' && data[key] != null) {
                htmlArr.push(
                    <li>
                        {key}:<ul>{this.generateHtmlFromJson(data[key])}</ul>
                    </li>
                );
            } else {
                htmlArr.push(
                    <li>
                        {key}:"{data[key]}"
                    </li>
                );
            }
        }

        return <ul>{htmlArr}</ul>;
    }

    render() {
        const perfs = this.state.data;
        if (!perfs || (perfs && perfs.length < 1)) return <div>No data</div>;

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>Current Log - {moment(perfs[0].time).format('DD.MM.YYYY HH:mm:ss')}</h2>
                    </span>
                </div>
                {
                    //Done intentionally - not worried about xss as this information comes directly from commander machine
                    <div>{this.generateHtmlFromJson(JSON.parse(perfs[0].data))}</div>
                }
                <div className="main-header">
                    <span>
                        <h2>{perfs ? perfs.length : 0} Log</h2>
                    </span>
                </div>
                <ReactTable
                    defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                    data={perfs}
                    columns={this.visibleColumns}
                    showPagination={true}
                    defaultPageSize={20}
                    minRows={0}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <div>JSON data</div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        plant: state.plants.currentAdminPlant,
    };
};

export default connect(mapStateToProps)(AdminPlantLog);
