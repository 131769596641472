import React, { useRef } from 'react';
import '../GenericTable.scss';
import { ReactComponent as CtxIcon } from '../../../../images/list/menu.svg';
import { useClickOutsideComponentsEffect } from '../../../../hooks/useClickOutsideComponentsEffect';

const RowContextMenu = ({ options, row }) => {
    let [show, setShow] = React.useState(false);
    const ctxIconRef = useRef(null);
    const ctxMenuRef = useRef(null);
    useClickOutsideComponentsEffect([ctxIconRef, ctxMenuRef], () => {
        setShow(false);
    });

    return (
        <div className="gtw-ctx">
            <span ref={ctxIconRef} className="gtw-ctx-icon" onClick={() => setShow(true)}>
                <CtxIcon />
            </span>
            {show && (
                <div className="gtw-ctx-menu" ref={ctxMenuRef}>
                    {options.map((opt, index) => {
                        if (opt.hideContext && opt.hideContext(row)) {
                            return null;
                        }
                        return (
                            <div key={'row_ctx_' + index} onClick={() => opt.action(row)} className="gtw-ctx-opt">
                                {opt.content}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default RowContextMenu;
