import { optimarColorPalette } from '../assets/colors';
import { get_image_from_url } from './utils';

const defaultProfileConfig = {
    //// SIZES
    // Document
    DOCUMENT_WIDTH: 555, // for A4
    // Separators heights
    CHAPTER_LINE_HEIGHT: 5,
    SUB_CHAPTER_LINE_HEIGHT: 2,
    DATA_POINT_LINE_HEIGHT: 0.5,

    //// COLORS
    // Main colors
    HEAD_FOOT_COLOR: optimarColorPalette.primaryLogo,
    SEPERATOR_LINE_COLOR: optimarColorPalette.secondaryBright,
    // Checkbox colors
    CHECKBOX_BACKGROUND_COLOR: optimarColorPalette.primaryDark,
    CHECKBOX_CHECK_COLOR: optimarColorPalette.primaryLight,

    //Table variables -> Use Customtable colors instead.
    // TABLE_HEADER_FILL_COLOR : optimarColorPalette.primaryLight,
    // TABLE_ROW_FILL_COLOR : optimarColorPalette.white,
    // TABLE_LINE_COLOR : optimarColorPalette.secondaryBright,

    // Customtable colors
    DATA_TABLE_HEADER_COLOR: '#7F7F7F',
    DATA_TABLE_HEADER_TEXT_COLOR: '#FFFFFF',
    DATA_TABLE_LINE_COLOR: '#FFFFFF',
    DATA_TABLE_ROW_COLOR: '#F2F2F2',
    DATA_TABLE_ROW_TEXT_COLOR: '#000000',
    // Charts colors
    LINECHART_COLORS: Object.values(optimarColorPalette),
    LINECHART_LIMIT_COLOR: '#ff000050',
    BARCHART_COLOR: optimarColorPalette.primaryLogo,
    // Histogram table rows
    HISTOGRAM_N1_ROW_COLOR: '#E9EBF5',
    HISTOGRAM_N2_ROW_COLOR: '#CFD5EA',

    //// MARGINS pdfmake: [left, top, right, bottom]
    TITLE_MARGINS: [1, 15, 0, 3],
    DATA_POINT_TITLE_MARGINS: [1, 15, 0, -5],
    DATA_POINT_TEXT_MARGINS: [1, 0, 0, 0],
    TABLE_MARGINS: [0, 15, 0, 0],
    TABLE_TEXT_MARGINS: [0, 5, 2, 5],
    FOOTER_MARGINS: [0, 0, 0, 0],
    DATA_POINT_LINE_MARGINS: [0, 0, 0, 0],

    //// FONT
    DOCUMENT_HEADER_FONT: { bold: true, fontSize: 20 },
    HEADER_FONT: { bold: true, fontSize: 12 },
    SUB_HEADER_FONT: { bold: false, fontSize: 12 },
    DATAPOINT_HEADER_FONT: { bold: false, fontSize: 12 },
    DATAPOINT_TEXT_FONT: { bold: false, fontSize: 12 },
    DATATABLE_HEADER_FONT: { bold: true, fontSize: 12 },
    DATATABLE_TEXT_FONT: { bold: false, fontSize: 12 },
};

export function getProfileConfig(modifiers) {
    const profileConf = { ...defaultProfileConfig };
    if (modifiers && modifiers.colors) {
        for (const [key, value] of Object.entries(modifiers.colors)) {
            const upperKey = key.toUpperCase();
            if ((upperKey.endsWith('COLOR') || upperKey.endsWith('COLORS')) && upperKey in profileConf) {
                //console.log("configuring key?",key,value)
                profileConf[upperKey] = value;
            }
            //else
            //console.log("Skipping key?",key,value)
        }
    }

    if (modifiers?.separators && modifiers.separators.length === 3) {
        profileConf.CHAPTER_LINE_HEIGHT = modifiers.separators[0];
        profileConf.SUB_CHAPTER_LINE_HEIGHT = modifiers.separators[1];
        profileConf.DATA_POINT_LINE_HEIGHT = modifiers.separators[2];
    }

    if (modifiers && modifiers.fonts) {
        for (const [key, value] of Object.entries(modifiers.fonts)) {
            if (key.endsWith('font') && key.toUpperCase() in profileConf) profileConf[key.toUpperCase()] = value;
        }
    }

    return profileConf;
}

export function getDefaultProfileConfig() {
    return defaultProfileConfig;
}

export function getPdfConfig(profile) {
    return {
        pageSize: 'A4',
        pageMargins: [20, 70, 20, 53], // bottom margin optimized for logoimages with height = 130px
        styles: {
            documentHeader: {
                margin: profile.TITLE_MARGINS,
                ...profile.DOCUMENT_HEADER_FONT,
            },
            header: {
                margin: profile.TITLE_MARGINS,
                ...profile.HEADER_FONT,
            },
            subHeader: {
                margin: profile.TITLE_MARGINS,
                ...profile.SUB_HEADER_FONT,
            },
            dataPointHeader: {
                margin: profile.DATA_POINT_TITLE_MARGINS,
                ...profile.DATAPOINT_HEADER_FONT,
            },
            dataPointText: {
                margin: profile.DATA_POINT_TEXT_MARGINS,
                ...profile.DATAPOINT_TEXT_FONT,
            },
            dataTableHeader: {
                color: profile.DATA_TABLE_HEADER_TEXT_COLOR,
                fillColor: profile.DATA_TABLE_HEADER_COLOR,
                border: [true, true, true, true],
                margin: profile.TABLE_TEXT_MARGINS,
                ...profile.DATATABLE_HEADER_FONT,
            },
            dataTableRow: {
                color: profile.DATA_TABLE_ROW_TEXT_COLOR,
                fillColor: profile.DATA_TABLE_ROW_COLOR,
                border: [true, true, true, true],
                margin: profile.TABLE_TEXT_MARGINS,
                ...profile.DATATABLE_TEXT_FONT,
            },
            customFillDataTableRow: {
                color: profile.DATA_TABLE_ROW_TEXT_COLOR,
                border: [true, true, true, true],
                margin: profile.TABLE_TEXT_MARGINS,
                ...profile.DATATABLE_TEXT_FONT,
            },
            footer: {
                margin: profile.FOOTER_MARGINS,
            },
            linestylechapter: {},
            linestylesubchapter: {},
            linestyledatapoint: {
                margin: profile.DATA_POINT_LINE_MARGINS,
                lineHeight: 0,
            },
        },
    };
}

export async function getProfileLogos(modifiers) {
    const logos = [undefined, undefined, undefined];

    if (!modifiers || !modifiers.logos) return logos;

    if (modifiers.logos.top) logos[0] = await get_image_from_url(modifiers.logos.top);

    if (modifiers.logos.bottom_left) logos[1] = await get_image_from_url(modifiers.logos.bottom_left);

    if (modifiers.logos.bottom_right) logos[2] = await get_image_from_url(modifiers.logos.bottom_right);

    return logos;
}
