import { SET_NOTELIST, CLEAR_NOTELIST } from '../actions';

const notes = (
    state = {
        noteList: undefined,
    },
    action
) => {
    switch (action.type) {
        case SET_NOTELIST:
            return {
                ...state,
                noteList: action.data,
            };
        case CLEAR_NOTELIST:
            return {
                ...state,
                noteList: undefined,
            };
        default:
            return state;
    }
};

export default notes;
