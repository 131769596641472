import React from 'react';
import './AdminUserAddEdit.scss';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../../utility/api';
import { FormErrors } from '../AdminPlantItemsAddEdit/FormErrors';

class AdminUserAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            formErrors: { username: '', password: '', account: '', displayname: '' },
            usernameValid: false,
            passwordValid: false,
            accountValid: false,
            displaynameValid: true,
            formValid: false,
            isDirty: false,
            reportReader: false,
            user: { roles: [] },
            claims: undefined,
            submitting: false,
            currentTab: 'general',
            addedRoles: [],
            removedRoles: [],
            allRoles: [],
            filteredRoles: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchAccounts = this.fetchAccounts.bind(this);
        this.handleOnFocusOut = this.handleOnFocusOut.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.impersonateUser = this.impersonateUser.bind(this);
        this.undeleteUser = this.undeleteUser.bind(this);
        this.renderModalContent = this.renderModalContent.bind(this);
        this.fetchRoleData = this.fetchRoleData.bind(this);
    }

    componentDidMount() {
        if (this.props.editUser) {
            this.setState(
                {
                    mode: 'edit',
                    user: this.props.editUser,
                    formValid: true,
                },
                this.validateFields
            );
        }

        if (this.props.account) {
            const account = this.props.account;

            const tmp = this.state.user;
            tmp.accountId = account.id;
            this.setState({ user: tmp }, this.validateFields);
            // this.validateField('accountId', account.id)
        }

        this.fetchAccounts();
        this.fetchRoleData();
        if (this.props.editUser) {
            this.fetchClaims(this.props.editUser.id, () => {
                this.isReportReader();
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) this.fetchAccounts();
    }

    handleChange(event) {
        console.log('handleChange', event);
        this.setState({
            isDirty: true,
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const tmp = Object.assign({}, this.state.user);
        tmp[name] = value;

        //Is handled in setState below this statemant?
        // switch (name) {
        //     case 'aduser':
        //         this.validateField("passwordHash", this.state.user.passwordHash);
        //         break;
        //     default:
        //         break;
        // }

        this.setState({ user: tmp }, this.validateFields);

        if (name === 'newPassword') {
            if (value) {
                this.setState({
                    passwordValid: false,
                    formValid: false,
                });
            }
        }
    }

    handleAccountChange(event) {
        this.handleChange(event);
        this.handleOnFocusOut(event);
    }

    handleOnFocusOut(event) {
        const target = event.target;
        // eslint-disable-next-line no-unused-vars
        const value = target.type === 'checkbox' ? target.checked : target.value;
        // eslint-disable-next-line no-unused-vars
        const name = target.name;
        //Necessery?
        // this.validateField(name, value);
    }

    validateFields() {
        const usernameValid = this.validateUsername(this.state.user.username || '');
        const passwordHashValid = this.validatePasswordHash();
        const accountIdValid = this.validateAccount(this.state.user.accountId);

        const stateToSet = {
            usernameValid: usernameValid.isValid,
            passwordValid: passwordHashValid.isValid,
            accountValid: accountIdValid.isValid,

            formErrors: {
                username: usernameValid.message ?? '',
                password: passwordHashValid.message ?? '',
                account: accountIdValid.message ?? '',
            },
            formValid: usernameValid.isValid && passwordHashValid.isValid && accountIdValid.isValid,
        };
        console.log('stateToSet', stateToSet);
        this.setState(stateToSet);
    }

    validateAccount(userAccountId) {
        const accountId = userAccountId || '';
        const accountValid = accountId.length >= 2;
        return {
            isValid: accountValid,
            message: accountValid ? '' : ' not chosen',
        };
    }

    validateUsername(username) {
        const usernameValid = username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        return {
            isValid: usernameValid,
            message: usernameValid ? '' : ' is invalid',
        };
    }

    validatePasswordHash() {
        const aduser = this.state.user.aduser;
        let passwordValid = false;
        // let message = '';
        if (aduser || this.state.user.newPassword || this.state.mode !== 'edit') {
            if (aduser) {
                passwordValid = true;
            } else {
                const password = this.state.user.passwordHash || '';
                passwordValid = password.length >= 6;
            }
        } else {
            passwordValid = true;
        }

        if (this.state.user.enableCommanderUserLogin) {
            return {
                isValid: passwordValid,
                message: passwordValid ? '' : ' is too short',
            };
        } else return { isValid: true };
    }

    validateDisplayname(displaynameValue) {
        const tmpUser = Object.assign({}, this.state.user);

        this.setState({
            isDirty: true,
        });

        let displaynameValid = true;
        console.log('current displayname', displaynameValue);
        if (!displaynameValue) {
            this.setState({ displaynameValid: true });
            return;
        }

        displaynameValid = !(displaynameValue.length < 4);
        this.setState({ displaynameValid: displaynameValid });
        this.state.formErrors.displayname = displaynameValid ? '' : ' is too short';
        console.log('displayname validation', displaynameValid);
        tmpUser.displayname = displaynameValue;
        this.state.user = tmpUser;
    }

    validateField(fieldName) {
        const fieldValidationErrors = this.state.formErrors;
        let passwordValid = this.state.passwordValid;
        // let displaynameValid = this.state.displaynameValid;
        const aduser = this.state.user.aduser;

        switch (fieldName) {
            case 'aduser':
                if (aduser) {
                    passwordValid = true;
                } else {
                    const password = this.state.user.passwordHash || '';
                    passwordValid = password.length >= 6;
                }
                this.setState({
                    passwordValid: passwordValid,
                });
                fieldValidationErrors.password = passwordValid ? '' : ' is too short';
                break;

            default:
                break;
        }
    }

    validateForm() {
        return (
            this.state.usernameValid &&
            this.state.passwordValid &&
            this.state.accountValid &&
            this.state.displaynameValid
        );
    }

    fetchAccounts() {
        api(process.env.REACT_APP_API + '/api/accounts/')
            .then((res) => {
                this.setState({ accounts: res.data });
                console.log('fetch accounts', res.data);
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    fetchClaims(userId, callback) {
        api.get(process.env.REACT_APP_API + '/api/applicationUserClaims/' + userId)
            .then((res) => this.setState({ claims: res.data, oldClaims: res.data }, callback))
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    async handleSubmit() {
        this.setState({
            submitting: true,
        });
        const functionToRun = this.state.mode === 'edit' ? this.updateSubmit : this.createSubmit;

        const result = await functionToRun();
        console.log('updateCreate result', result);
        if (result.status === 1) {
            await this.attachRoleToUser(result.userId);
            await this.detachRoleFromUser(result.userId);
            this.props.editedCallback();
        }
    }

    makeReportReader = async (id) => {
        const claim = {
            claimType: 'reportreader',
            claimValue: 'true',
            userId: id || this.state.user.id,
        };

        const response = await api.post(process.env.REACT_APP_API + '/api/applicationUserClaims/', claim);

        if (response.status === 200) {
            //return response.json();
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    removeReportReader = async () => {
        const claimId = this.state.claims.filter((claim) => claim.claimType === 'reportreader')[0].id;
        const response = await api.delete(process.env.REACT_APP_API + '/api/applicationUserClaims/' + claimId);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    createSubmit = async () => {
        const _this = this;
        const ApplicationUser = {
            Username: this.state.user.username,
            UsernameConfirmed: false,
            PasswordHash: this.state.user.passwordHash,
            PhonenumberConfirmed: false,
            TwoFactorEnabled: false,
            LockoutEnabled: false,
            AccessFailedCount: 0,
            ApplicationUserState: 1,
            AccountId: this.state.user.accountId,
            EnableCommanderUserLogin: this.state.user.enableCommanderUserLogin,
            IsAdUser: this.state.user.isAdUser,
            Displayname: this.state.user.displayname,
        };

        // if (this.state.user.aduser) {
        //     ApplicationUser.passwordHash = ''
        // }
        try {
            const exists = await api.get(
                process.env.REACT_APP_API + `/api/users/exists?email=${ApplicationUser.Username}`
            );
            console.log('exists state', exists);
            if (exists.data.exists && exists.data.applicationUserState === '1') {
                NotificationManager.warning(
                    'User already exists and should be available in the users list',
                    'User already exists',
                    5000
                );
                //_this.setState({ exists: true });
                return { status: 0 };
            } else if (exists.data.exists && exists.data.applicationUserState === '0') {
                NotificationManager.warning('User already exists, but is deactivated.', 'User already exists', 5000);
                _this.setState({ exists: true });
                return { status: 0 };
            }

            const response = await api.post(process.env.REACT_APP_API + '/api/users/', ApplicationUser);
            if (response?.data?.id) {
                NotificationManager.success('User created', 'User successfully created', 5000);

                if (this.state.user.reportReader) {
                    await this.makeReportReader(response.data.id);
                    //return response.data;
                } else {
                    //return response.data;
                }
                const tmp = this.state.user;
                tmp.username = '';
                tmp.passwordHash = '';
                tmp.account = '';
                tmp.description = '';
                tmp.displayname = '';
                this.setState({
                    isDirty: false,
                    formValid: false,
                    usernameValid: false,
                    accountValid: false,
                    displaynameValid: false,
                    user: tmp,
                });
                return { status: 1, userId: response.data.id };
                //this.props.addedCallback(response);
            } else {
                throw new Error(response.status);
            }
        } catch (error) {
            // if (error.response.data === "User already exists") {
            //     NotificationManager.error("User not created", "User already exists", 5000);
            //     _this.setState({ exists: true });
            // } else {
            NotificationManager.error('User not created', 'Error creating user', 5000);
            return { status: 0 };

            // }
        } finally {
            this.setState({ submitting: false });
        }
    };

    getReportReader() {
        const reportReader = this.state.claims.filter((claim) => claim.claimType === 'reportreader');
        if (reportReader.length > 0) {
            return reportReader[0];
        }
        return null;
    }

    updateSubmit = async () => {
        let currentStatus = 0;
        const applicationUser = {
            Id: this.state.user.id,
            Username: this.state.user.username,
            PasswordHash: this.state.user.passwordHash,
            AccountId: this.state.user.accountId,
            //AdUser: this.state.user.aduser,
            IsAdUser: this.state.user.isAdUser,
            EnableCommanderUserLogin: this.state.user.enableCommanderUserLogin,
            NewPassword: this.state.user.newPassword,
            Displayname: this.state.user.displayname,
        };
        const reportReader = this.getReportReader();
        if (reportReader && this.state.user.reportReader === false) {
            this.removeReportReader();
        }

        if (!reportReader && this.state.user.reportReader) {
            this.makeReportReader();
        }
        try {
            const response = await api.put(
                process.env.REACT_APP_API + '/api/users/' + applicationUser.Id,
                applicationUser
            );

            if (response.status === 200) {
                NotificationManager.success('User updated', 'User successfully updated', 5000);
                this.props.editedCallback(response.data);
                currentStatus = 1;
            } else {
                NotificationManager.error('Error', 'Error updating user', 5000);
                currentStatus = 0;
            }
        } catch (error) {
            currentStatus = 0;
        } finally {
            this.setState({ submitting: false });
        }
        return { status: currentStatus };
    };

    renderButton() {
        let button;

        if (this.state.mode === 'edit') {
            button = (
                <button className="btn btn-blue btn-grow" onClick={() => this.handleSubmit()} value="Submit">
                    Save changes
                </button>
            );
        } else {
            button = (
                <button className="btn btn-blue btn-grow" onClick={() => this.handleSubmit()} value="Submit">
                    Create
                </button>
            );
        }

        return button;
    }

    isReportReader() {
        if (this.state.claims.filter((claim) => claim.claimType === 'reportreader').length > 0) {
            const tmp = this.state.user;
            tmp.reportReader = true;
            this.setState({
                user: tmp,
            });
        }
    }

    setAdmin(makeAdmin) {
        if (makeAdmin) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body">
                            <h1>Make admin</h1>
                            <p>
                                Are you sure you want to make this user an admin? This will give the user access to all
                                accounts and plants.
                                <br />
                            </p>
                            <div className="buttons">
                                <button className="btn btn-yellow" onClick={onClose}>
                                    No, cancel
                                </button>
                                <button
                                    className="btn btn-blue"
                                    onClick={() => {
                                        this.makeAdmin();
                                        onClose();
                                    }}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    );
                },
            });
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body">
                            <h1>Remove admin</h1>
                            <p>
                                Are you sure you want to remove this user's admin rights?
                                <br />
                            </p>
                            <div className="buttons">
                                <button className="btn btn-yellow" onClick={onClose}>
                                    No, cancel
                                </button>
                                <button
                                    className="btn btn-blue"
                                    onClick={() => {
                                        this.removeAdmin();
                                        onClose();
                                    }}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    );
                },
            });
        }
    }

    makeAdmin() {
        api.post('/api/users/' + this.state.user.id + '/admin').then((response) => {
            if (response.status === 200) {
                this.fetchClaims(this.state.user.id);
                NotificationManager.success('User promoted to admin', 'Success', 5000);
            } else {
                NotificationManager.error('Could not promote user', 'Error', 5000);
            }
        });
    }

    removeAdmin() {
        api.delete('/api/users/' + this.state.user.id + '/admin').then((response) => {
            if (response.status === 200) {
                this.fetchClaims(this.state.user.id);
                NotificationManager.success("User's admin permissions removed", 'Success', 5000);
            } else {
                NotificationManager.error('Could not remove admin permissions', 'Error', 5000);
            }
        });
    }

    undeleteUser() {
        api.post('/api/users/' + this.state.user.username + '/undelete').then((response) => {
            if (response.status === 200) {
                NotificationManager.success('User has been reactivated', 'Success', 5000);
                this.props.editedCallback();
            } else {
                NotificationManager.error('Could not activate user', 'Error', 5000);
            }
        });
    }

    isAdmin() {
        return (
            this.state.claims.filter((claim) => claim.claimType === 'role' && claim.claimValue === 'admin').length > 0
        );
    }

    canMakeAdmin() {
        return (
            this.state.mode === 'edit' &&
            this.props.user &&
            this.state.user &&
            this.state.claims &&
            this.props.user.profile.role === 'owner' &&
            !(
                this.state.claims.filter((claim) => claim.claimType === 'role' && claim.claimValue === 'owner').length >
                0
            )
        );
    }

    renderPassword() {
        //console.log(this.state.user)
        const result = [];
        result.push(
            <div key={'adinputwrapper'}>
                <label key={'ad'} htmlFor="aduser">
                    Organizational account:
                </label>
                <input
                    key={'adinput'}
                    autoComplete="off"
                    name="isAdUser"
                    type="checkbox"
                    checked={this.state.user.isAdUser}
                    onChange={(e) => {
                        this.handleChange(e);
                        this.handleOnFocusOut(e);
                    }}
                />
            </div>
        );

        //if(this.state.user.isAdUser)
        result.push(
            <span key={'orgaccinfo'} className="small-text">
                If the user has an organizational account, then the credentials are maintained through the external
                authentication provider.
            </span>
        );
        //result.push();

        result.push(
            <div key={'enableCommanderLoginWrapper'}>
                <label key={'enableCommanderLogin'} htmlFor="enableCommanderLoginInput">
                    Enable Commander login:
                </label>
                <input
                    key={'enableCommanderLoginInput'}
                    autoComplete="off"
                    name="enableCommanderUserLogin"
                    type="checkbox"
                    checked={this.state.user.enableCommanderUserLogin}
                    onChange={(e) => {
                        this.handleChange(e);
                        this.handleOnFocusOut(e);
                    }}
                />
            </div>
        );
        // result.push();

        if (this.state.user.enableCommanderUserLogin) {
            //Can configure password if commander user login is enabled (Meaning that Commander handled auth. not a third party provider)

            if (this.state.mode === 'edit') {
                //console.log(this.state.user);
                if (this.state.user.enableCommanderUserLogin) {
                    result.push(
                        <div key={'changepwWrapper'}>
                            <label key={'changepw1'} htmlFor="newPassword">
                                Change Password:
                            </label>
                            <input
                                key={'changepwinput1'}
                                autoComplete="off"
                                name="newPassword"
                                type="checkbox"
                                checked={this.state.user.newPassword || false}
                                onChange={(e) => {
                                    this.handleChange(e);
                                    this.handleOnFocusOut(e);
                                }} // Firing every 2nd click
                                //onClick={(e) => { this.handleChange(e); this.handleOnFocusOut(e) }}
                            />
                        </div>
                    );
                    //result.push( );
                }
            }

            if (this.state.user.newPassword) {
                result.push(
                    <label key={'newpw2'} htmlFor="name">
                        New password <span className="small-text">&nbsp;Required</span>
                    </label>
                );
                result.push(
                    <input
                        key={'newpwinput2'}
                        title="Password needs to be 6 characters or longer"
                        className={'admin-input-control'}
                        pattern=".{6,500}"
                        required
                        autoComplete="off"
                        type="password"
                        name="passwordHash"
                        value={this.state.user.passwordHash || ''}
                        onChange={(e) => {
                            this.handleChange(e);
                        }}
                    />
                );
            } else if (this.state.mode !== 'edit') {
                result.push(
                    <label key={'newpw1'} htmlFor="newPassword">
                        Password <span className="small-text">&nbsp;Required</span>
                    </label>
                );
                result.push(
                    <input
                        key={'newpwinput1'}
                        title="Password needs to be 6 characters or longer"
                        className={'admin-input-control'}
                        pattern=".{6,500}"
                        required
                        autoComplete="off"
                        type="password"
                        name="passwordHash"
                        value={this.state.user.passwordHash || ''}
                        onChange={(e) => {
                            this.handleChange(e);
                        }}
                    />
                );
            }
        }

        //if (this.state.mode !== "edit") {

        //}
        return result;
    }

    impersonateUser() {
        const role = this.state.claims.filter((claim) => claim.claimType === 'role');

        sessionStorage.setItem('userImpersonate', this.state.user.id);
        sessionStorage.setItem('userImpersonateAcc', this.state.user.accountId);
        sessionStorage.setItem(
            'userImpersonateAccName',
            this.state.accounts.filter((x) => x.id === this.state.user.accountId)[0].name
        );
        sessionStorage.setItem('userImpersonateName', this.state.user.username);
        if (role.length === 1) {
            sessionStorage.setItem('userImpersonateRole', role[0].claimValue);
        } else {
            sessionStorage.setItem('userImpersonateRole', 'user');
        }

        api.get('/api/accountModules/' + this.state.user.accountId).then((res) => {
            sessionStorage.setItem('userImpersonateModules', JSON.stringify(res.data.map((x) => x.name)));
            window.location.replace('/');
        });
    }

    fetchRoleData() {
        api.get(`api/roles`)
            .then((res) => {
                this.setState({
                    filteredRoles: res.data
                        .filter((r) => {
                            return !this.state.user.roles.some((existingRole) => existingRole.id === r.id);
                        })
                        .map((r) => {
                            r.name = r.title;
                            return r;
                        }),
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    addRoleToUser(role) {
        this.setState({
            addedRoles: [...this.state.addedRoles, role],
        });
    }

    revertAddRoleToUser(role) {
        this.setState({
            addedRoles: [...this.state.addedRoles].filter((r) => r.id !== role.id),
        });
    }

    removeRoleFromUser(role) {
        this.setState({
            removedRoles: [...this.state.removedRoles, role],
        });
    }

    revertRemoveRoleFromUser(role) {
        this.setState({
            removedRoles: [...this.state.removedRoles].filter((r) => r.id !== role.id),
        });
    }

    async attachRoleToUser(userId) {
        if (this.state.addedRoles.length === 0) return;
        const userRoles = this.state.addedRoles.map((role) => {
            return {
                UserId: userId ? userId : this.state.user.id,
                RoleId: role.id,
            };
        });
        try {
            await api.post(`api/roles/attach`, userRoles);
        } catch (error) {
            console.debug(error);
        }
    }

    async detachRoleFromUser(userId) {
        if (this.state.removedRoles.length === 0) return;

        const userRoles = this.state.removedRoles.map((role) => {
            return {
                UserId: userId ? userId : this.state.user.id,
                RoleId: role.id,
            };
        });
        try {
            await api.post(`api/roles/detach`, userRoles);
        } catch (error) {
            console.debug(error);
        }
    }

    renderModalContent() {
        console.log('current user', this.state);
        const accounts = this.state.accounts;
        if (this.state.currentTab === 'general') {
            return (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {this.state.mode === 'edit' ? <h1>Edit user</h1> : <h1>New user</h1>}
                        {this.canMakeAdmin() ? (
                            <div>
                                <button
                                    onClick={this.impersonateUser}
                                    style={{ marginRight: '10px' }}
                                    className="btn btn-blue"
                                >
                                    View As
                                </button>
                                <button
                                    onClick={this.isAdmin() ? () => this.setAdmin(false) : () => this.setAdmin(true)}
                                    className="btn btn-blue"
                                >
                                    {this.isAdmin() ? 'Remove admin' : 'Make Admin'}
                                </button>
                            </div>
                        ) : (
                            <span></span>
                        )}
                    </div>
                    <form className="admin-plant-add-edit-form" onSubmit={this.handleSubmit}>
                        <div>
                            <label htmlFor="displayname">Display name</label>
                            <input
                                className={'admin-input-control'}
                                title="Must be either lower or upper -case characters"
                                required
                                autoComplete="off"
                                type="name"
                                name="displayname"
                                value={this.state.user.displayname || ''}
                                onChange={(event) => this.validateDisplayname(event.target.value)}
                            />
                        </div>

                        <div>
                            <label htmlFor="name">
                                Username (e-mail) <span className="small-text">&nbsp;Required</span>
                            </label>
                            <input
                                className={'admin-input-control'}
                                title="Must be a valid email, e.g. user@example.com"
                                required
                                autoComplete="off"
                                type="email"
                                name="username"
                                value={this.state.user.username || ''}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div>
                            {this.renderPassword()}
                            <label htmlFor="reportReader" style={{ marginLeft: '10px' }}>
                                Report reader:
                            </label>
                            <input
                                autoComplete="off"
                                name="reportReader"
                                type="checkbox"
                                checked={this.state.user.reportReader || false}
                                onChange={this.handleChange}
                                onBlur={this.handleOnFocusOut}
                            />
                        </div>

                        <div>
                            <label htmlFor="name">
                                Choose Account <span className="small-text">&nbsp;Required</span>
                            </label>
                            <select
                                className="admin-form-select"
                                required
                                value={this.state.user.accountId}
                                name="accountId"
                                onChange={this.handleAccountChange}
                            >
                                <option default value="">
                                    Choose Account
                                </option>
                                {accounts.map((account, i) => {
                                    return (
                                        <option key={'account' + i} value={account.id}>
                                            {account.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        {this.state.exists ? (
                            <div className="btn-row-grow">
                                <span>User has previously been created.</span>
                                <div
                                    className="btn btn-blue btn-grow"
                                    style={{ 'text-align': 'center' }}
                                    onClick={this.undeleteUser}
                                >
                                    Reactivate user
                                </div>
                            </div>
                        ) : null}
                    </form>
                </div>
            );
        } else if (this.state.currentTab === 'roles') {
            return (
                <div className="admin-users-modal-content">
                    <div className="admin-users-modal-roles">
                        {this.state.user.roles.map((role) => {
                            return (
                                <div key={role?.Id} className="admin-users-modal-items">
                                    <div className="admin-users-modal-item">
                                        <div>{role?.name}</div>
                                        {this.renderRoleButton(true, role)}
                                    </div>
                                </div>
                            );
                        })}
                        {this.state?.filteredRoles.map((role) => {
                            return (
                                <div key={role.Id} className="admin-users-modal-items">
                                    <div className="admin-users-modal-item">
                                        <div>{role?.name}</div>
                                        {this.renderRoleButton(false, role)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    }

    renderRoleButton(existingRole, role) {
        if (existingRole && !this.state.removedRoles.some((r) => r.id === role.id)) {
            return (
                <button
                    className="btn btn-yellow admin-role-modal-permission-item-button"
                    onClick={() => this.removeRoleFromUser(role)}
                >
                    Detach
                </button>
            );
        } else if (existingRole) {
            return (
                <button
                    className="btn btn-blue admin-role-modal-permission-item-button"
                    onClick={() => this.revertRemoveRoleFromUser(role)}
                >
                    Attach
                </button>
            );
        } else if (this.state.addedRoles.some((r) => r.id === role.id)) {
            return (
                <button
                    className="btn btn-yellow admin-role-modal-permission-item-button"
                    onClick={() => this.revertAddRoleToUser(role)}
                >
                    Detach
                </button>
            );
        } else {
            return (
                <button
                    className="btn btn-blue admin-role-modal-permission-item-button"
                    onClick={() => this.addRoleToUser(role)}
                >
                    Attach
                </button>
            );
        }
    }

    render() {
        if (!this.props && !this.state.accounts) return;

        return (
            <div className="admin-users-edit">
                <div className="admin-users-edit-tabs">
                    <div
                        onClick={() => this.setState({ currentTab: 'general' })}
                        className={
                            'admin-users-edit-tab ' +
                            (this.state.currentTab === 'general' ? 'admin-users-edit-tab--active' : '')
                        }
                    >
                        <p>General</p>
                    </div>
                    <div
                        onClick={() => this.setState({ currentTab: 'roles' })}
                        className={
                            'admin-users-edit-tab ' +
                            (this.state.currentTab === 'roles' ? 'admin-users-edit-tab--active' : '')
                        }
                    >
                        <p>Roles</p>
                    </div>
                </div>
                {this.renderModalContent()}
                <div style={{ marginTop: '10px' }}>
                    <FormErrors
                        style={{ color: '#FF6B6B' }}
                        className="formErrors"
                        formErrors={this.state.formErrors}
                    />
                </div>

                <div className="btn-row-grow admin-users-edit-bottom-buttons">
                    <button className="btn btn-yellow btn-grow" onClick={this.props.editedCallback}>
                        Cancel
                    </button>
                    {this.renderButton()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminUserAddEdit);
