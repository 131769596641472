import React from "react";
import './SelectorButton.scss';

const SelectorButton = (props) => {
  return (
    <button
        className={props.isActive ? "SelectorButton-Active" : "SelectorButton"} 
        onClick={props.onClick}
        >
        {props.children}
    </button>  
  );
};

export default SelectorButton;