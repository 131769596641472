import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import TelemetryProvider from './utility/telemetry/TelemetryProvider';

import 'font-awesome/css/font-awesome.min.css';
import App from './App';
import './index.css';
import unregister from './registerServiceWorker';
import './styles/styles.scss';

import { noop } from 'lodash';
import { getStore } from './store/getStore';

if (process.env.REACT_APP_IS_PROD) {
    console.log = noop;
}
const store = getStore();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <TelemetryProvider>
                <Route path="/" name="Home">
                    <App />
                </Route>
            </TelemetryProvider>
        </BrowserRouter>
    </Provider>
);

unregister();

export default store;
