import format from 'date-fns/format';
import moment from 'moment';
import 'moment/locale/en-gb';
import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { connect } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import api from '../../../utility/api';
import { guidIsNullOrEmpty } from '../../../utility/guidFunctions';
import { tryParseJson } from '../../../utility/jsonFunctions';
import { validate } from '../../../utility/validationHelper';
import DropdownList from '../DropdownList/DropdownList';
import ModalDatetime from '../ModalWrapper/ModalDatetime';
import './JobAccountAddEdit.scss';

const selectStyle = { 
    menuPortal: (base) => ({ 
        ...base, 
        zIndex: 9999}),
    control: (css, _) => ({ 
        ...css, 
        backgroundColor: "#0d171e",
        color: "#e6e6f0"
    }),
    input: (css, _) => ({ 
        ...css, 
        color: "#e6e6f0"
    }),
    option: (css, _) => ({ 
        ...css, 
        backgroundColor: "#0d171e",
        color: "#e6e6f0"
    }),
    menu: (css, _) => ({
        ...css,
        border: "1px solid #2d4150",
        marginTop: '0px'
    }),
    menuList: (css, _) => ({
        ...css,
        backgroundColor: "#0d171e"
    })
};

class JobAccountAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job: null,
            jobtypes: [],
            validStart: true,
            validEnd: true,
            dynamicData: {},
            components : [],
            selectedItem  : {topic: '', name : ''},            
            selectedItemFilterString: '',
            currentPlant : {
                id : null,
                name : null
            },
            isFetching: false,
            plantOptions : []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.handleOnFocusOut = this.handleOnFocusOut.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.dateValidation = this.dateValidation.bind(this);
        this.fetchingStructure = false;
        this.fetchingTags = false;
        this.handleConfigChange = this.handleConfigChange.bind(this);
        this.getStructure = this.getStructure.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.handleComponentChange = this.handleComponentChange.bind(this);
        this.fetchAccountPlants = this.fetchAccountPlants.bind(this);
        
        this.endDateRef = React.createRef();
        this.startDateRef = React.createRef();
    }

    handleOnFocusOut() {
        validate();
    }

    componentDidMount() {
        this.fetchAccountPlants();

        if (this.props.editType === 'edit') {
            this.setState({
                job: this.props.editItem,
                dynamicData: tryParseJson(this.props.editItem.data, {}),
            });
        } else if (this.props.editType !== 'edit') {
            const job = {
                data: '',
                endDate: null,
                parentJobId: this.props.editType === 'child' ? this.props.editItem.id : null,
                startDate: new Date(),
                topic: null,
                type: null,
                jobTypeId: null,
                plantId: null,
                relationship: null,
            };

            this.setState({ job });
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if (this.state.currentPlant.id !== prevState.currentPlant.id) {
            this.fetchJobTypes(this.state.currentPlant.id);
            this.getStructure(this.state.currentPlant.id);           
        }
    }

    getStructure(plantId) {
        if (!plantId)
        {
            this.setState({
                components : []
            });
            return;
        }

        const apiUrl = process.env.REACT_APP_API + '/api/plants/' + plantId + '/items/structure';       

        api.get(apiUrl)
            .then((res) => {
                if (res.status !== 204) {   
                    this.setState({ components : res.data });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    fetchJobTypes = async (plantId) => {
        let jobtypes = [];

        if (plantId)
        {
            const res = await api.get(`${process.env.REACT_APP_API}/api/jobs/types/${plantId}`);

            jobtypes = res.data.map((j) => {
                return {
                    value: j.id,
                    label: j.name,
                    parentId: j.parentId,
                    parentName: j.parentName,
                    plantId: j.plantId,
                    configuration: j.configuration,
                };
            });
        } 

        this.setState({
            jobtypes,
            parent: this.props.editType === 'child' ? this.props.editItem : null,
            parentType:
                this.props.editType === 'child'
                    ? jobtypes.filter((j) => j.value === this.props.editItem.jobTypeId)?.[0]
                    : null,
        });
    };

    handleTypeChange = (newValue, event) => {
        if (event.action === 'create-option') {
            const url = `${process.env.REACT_APP_API}/api/jobs/types/${this.state.currentPlant.id}/${newValue.label}`;

            api.post(url).then((res) => {
                const jobTypesResult = res.data.map((j) => {
                    return {
                        value: j.id,
                        label: j.name,
                        configuration: '',
                    };
                });

                const jobResult = Object.assign({}, this.state.job);
                jobResult.jobTypeId = jobTypesResult.filter((j) => j.label === newValue.label)[0].value;

                this.setState(
                    {
                        jobtypes: jobTypesResult,
                        job: jobResult
                    }
                );
            });
        } else {
            const job = Object.assign({}, this.state.job);
            job.jobTypeId = newValue?.value;
            job.configuration = newValue?.configuration || '[]';

            const dynData = this.buildDynamicDataWithDefaultValues(newValue);

            this.setState({ job: job, dynamicData: dynData });
        }
    };

    handlePlantChange = (newValue) => {
        let newJob = {...this.state.job};
        newJob.topic = '';
        newJob.name = '';
        newJob.plantId = (newValue ||{}).value;
        
        this.setState({
            currentPlant: {
                id : (newValue ||{}).value,
                name : (newValue ||{}).label
            },
            job: newJob,
            selectedItem: { topic: '', name : ''}
        });
    };

    handleSelectChange(name, selected) {
        if (name === 'relationship') {
            const tmp = this.state.job;
            tmp[name] = selected.value;
            this.setState({ job: tmp });
        } else {
            const dynamicData = this.state.dynamicData;
            dynamicData[name] = selected.value;

            this.setState({ dynamicData });
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = this.state.job;
        tmp[name] = value;

        this.setState({ job: tmp });
    }

    dateValidation(date, type) {
        return this.regularDateValidation(date, type);
    }

    regularDateValidation(date, type) {
        if (type === 'start') {
            return this.state.job.endDate ? date.isSameOrBefore(moment(this.state.job.endDate).endOf('day')) : true;
        } else if (type === 'end') {
            return this.state.job.startDate
                ? date.isSameOrAfter(moment(this.state.job.startDate).startOf('day'))
                : true;
        }
        return;
    }

    handleDateSelect(date, name, dynamic) {
        if (dynamic) {
            const dynamicData = this.state.dynamicData;
            dynamicData[name] = date;

            this.setState({ dynamicData });
        } else {
            const tmp = this.state.job;
            tmp[name] = date;

            this.setState({
                job: tmp,
                showDatePicker: false,
            });
        }
    }

    handleSelectItem(item) {    
        const tmp = this.state.job;
        tmp.topic = item.topic != null ? item.topic.substring(item.topic?.indexOf('/') + 1) : '';

        this.setState({
            job: tmp,
            selectedItem: item
        });
    }

    handleSubmit(event) {        
        event.preventDefault();

        const job = Object.assign({}, this.state.job);
        job.data = JSON.stringify(this.state.dynamicData);   

        const startDate = new Date(job.startDate);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        job.startDate = startDate;

        if (job.endDate){            
            const endDate = new Date(job.endDate);
            endDate.setSeconds(0);
            endDate.setMilliseconds(0);
            job.endDate = endDate;

            if (job.startDate.getTime() > job.endDate.getTime()) {
                NotificationManager.error('End date should be always greater than start date', 'Error creating job', 5000);
                return;
            }
        }   

        this.setState({isFetching : true});

        if (this.props.editType === 'edit') {
            this.updateSubmit(job);
        } else {
            this.createSubmit(job);
        }
    }

    createSubmit(job) {
        api.post(process.env.REACT_APP_API + '/api/jobs', job).then((response) => {
            if (response.status === 200) {
                this.props.toggleModal();
                NotificationManager.success('Job created successfully', 'Job created', 5000);
            } else {
                NotificationManager.error('Job not created successfully', 'Error creating job', 5000);
            }
        }).catch((error) => {
            let errorMessage = 'Job not created successfully';

            if (error?.response?.data) {
                errorMessage = error.response.data;
            }

            NotificationManager.error(errorMessage, 'Error creating job', 5000);            
        }).finally(() => {
            this.setState({isFetching : false});
        });
    }

    updateSubmit(job) {
        api.put(process.env.REACT_APP_API + '/api/jobs/' + job.id, job).then((response) => {
            if (response.status === 200) {
                this.setState({
                    editItem: null,
                });

                NotificationManager.success('Job updated successfully', 'Job updated', 5000);
                this.props.toggleModal();
            } else {
                NotificationManager.error('Job not updated successfully', 'Error updating job', 5000);
            }
        }).catch((error) => {
            let errorMessage = 'Job not updated successfully';

            if (error?.response?.data) {
                errorMessage = error.response.data;
            }

            NotificationManager.error(errorMessage, 'Error updating job', 5000);
        }).finally(() => {
            this.setState({isFetching : false});
        });
    }

    fetchAccountPlants(){
        const apiUrl = process.env.REACT_APP_API + '/api/plants/accountplants';       

        api.get(apiUrl)
            .then((res) => {
                const accountPlants = (res.data || [])
                    .map((plant) => ({ label : plant.name, value : plant.id }));
                    
                this.setState({ plantOptions : accountPlants });
            })
            .catch((err) => {
                NotificationManager.error('Error retrieving account plants', 'Failed to retrieve account plants', 5000);
                console.error(err);
            });
    }

    renderButtons() {
        const job = this.state.job;
        let isValidForm = job.topic && job.jobTypeId && job.plantId;
        
        const button = [
            <button
                key="btnCancelJobAddEdit"
                className="btn btn-yellow btn-grow"
                onClick={(e) => {
                    e.preventDefault();
                    this.props.toggleModal("Cancel");
                }}
            >
                Cancel
            </button>,
        ];

        if (this.props.editType === 'edit') {
            button.push(
                <button
                    key="btnUpdateJobAddEdit"
                    disabled={!isValidForm || this.state.isFetching}
                    className="btn btn-blue btn-grow"
                    value="Submit"
                >
                    Update
                </button>
            );
        } else {
            button.push(
                <button
                    key="btnCreateJobAddEdit"
                    disabled={!isValidForm || this.state.isFetching}
                    className="btn btn-blue btn-grow"
                    value="Submit"
                >
                    Create
                </button>
            );
        }

        return button;
    }

    handleConfigChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const dynamicData = this.state.dynamicData;
        dynamicData[name] = value;

        this.setState({ dynamicData });
    }

    buildDynamicDataWithDefaultValues(jobtype) {
        const dynData = {};
        const config = tryParseJson(jobtype?.configuration, {});

        if (config?.fields && config.fields.map) {
            config.fields.forEach((fld) => {
                if (typeof fld.defaultValue === 'undefined') {
                    return;
                }

                switch (fld.type) {
                    case 'date':
                        if (fld.defaultValue === 'NOW()') {
                            dynData[fld.name] = new Date();
                        } else {
                            dynData[fld.name] = fld.defaultValue;
                        }
                        break;
                    default:
                        dynData[fld.name] = fld.defaultValue;
                        break;
                }
            });
        }
            
        return dynData;
    }

    editField(field, index) {
        const data = this.state.dynamicData;
        const label = field.label || field.name;
        
        if (field.type === 'array') {
            const preppedChoices = field.choices.map((c) => {
                return { value: c, label: c };
            });
            return (
                <div className={'form-group'} key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <Select
                        isClearable
                        onChange={this.handleSelectChange.bind(this, field.name)}
                        options={preppedChoices}
                        classNamePrefix={'optimar'}
                        value={preppedChoices.find((p) => p.value === data?.[field.name])}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            );
        } else if (field.type === 'date') {
            return (
                <div className="form-group"  key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <Datetime
                        name={field.name}
                        inputProps={{ readOnly: true }}
                        locale="en-gb"
                        dateFormat="DD.MM.YYYY"
                        timeFormat="HH:mm"
                        value={data?.[field.name] ? format(new Date(data?.[field.name]), 'dd.MM.yyyy HH:mm') : null}
                        onChange={(date) => this.handleDateSelect(date, field.name, true)}
                        className={'date-input-control'}
                    />
                </div>
            );
        } else if (field.type === 'text') {
            return (
                <div className="form-group"  key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="text"
                        className={'input-control'}
                        name={field.name}
                        onChange={this.handleConfigChange}
                        value={data?.[field.name] || ''}
                    />
                </div>
            );
        } else if (field.type === 'number') {
            return (
                <div className="form-group"  key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="number"
                        className={'input-control'}
                        name={field.name}
                        onChange={this.handleConfigChange}
                        value={data?.[field.name] || ''}
                    />
                </div>
            );
        } else if (field.type === 'boolean') {
            return (
                <div className="form-group" key={index}>
                    <label htmlFor={field.name}>{label}</label>
                    <input
                        type="checkbox"
                        className={'input-control'}
                        name={field.name}
                        onChange={this.handleConfigChange}
                        checked={data?.[field.name]}
                    />
                </div>
            );
        } else {
            //not supported
            return <div key={index}></div>;
        }
    }

    handleComponentChange(e) {
        let jobToUpdate = {...this.state.job};
        jobToUpdate.topic = '';
        this.setState({ 
            selectedItem: { name : e.target.value}, 
            selectedItemFilterString: e.target.value,
            job: jobToUpdate
        });
    }

    render() {
        const config = tryParseJson(this.state.job?.configuration, {});

        let parentDisplayName = '';
        let parentDisplayHeader = '';
        let headerDisplayValue = '';
        let availableJobTypes = [];
        if (this.props.editType === 'child') {
            const parentData = tryParseJson(this.state.parent?.data, {}); 
            const parentConfig = tryParseJson(this.state.parentType?.configuration, {}); 

            parentDisplayName = parentData[parentConfig?.relationDisplayConfig?.displayValue];
            parentDisplayHeader = parentConfig?.relationDisplayConfig?.header;

            headerDisplayValue = parentDisplayName
                ? parentDisplayHeader?.toLowerCase() + ' ' + parentDisplayName
                : this.state.parent?.type 
                ? 'type ' + this.state.parent?.type
                : '';
            const parentJobType = this.state.parent?.jobTypeId;

            availableJobTypes = this.state.jobtypes
                .filter((x) => x.parentId === parentJobType)
                .sort((a, b) => a.label?.localeCompare(b.label) ?? -1);
        } else {
            availableJobTypes = this.state.jobtypes;
            let parentJobTypeId = null;

            if (this.props.editType === 'edit') {
                parentJobTypeId = availableJobTypes.find(j => j.value === this.props.editItem.jobTypeId)?.parentId;
            }

            if (parentJobTypeId){
                availableJobTypes = availableJobTypes
                    .filter(jt => jt.parentId === parentJobTypeId);
            } else {
                availableJobTypes = availableJobTypes
                    .filter((x) => guidIsNullOrEmpty(x.parentId));
            }

            availableJobTypes = availableJobTypes
                .sort((a, b) => a.label?.localeCompare(b.label) ?? -1);
        }      

        return this.state.job != null ? (
            <div className="form-container">
                <div className="form-header">
                    <h2>
                        {this.props.editType === 'edit'
                            ? 'Edit operation'
                            : this.props.editType === 'child'
                            ? 'Add child operation to ' + headerDisplayValue
                            : 'Add operation'}
                    </h2>
                </div>

                {this.state.showFormSuccess ? this._renderSuccessMessage() : null}
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="type">Plant</label>
                        <Select
                            isClearable
                            onChange={this.handlePlantChange}
                            options={this.state.plantOptions}
                            value={ 
                                this.state.currentPlant.id && this.state.currentPlant.name
                                ? { label : this.state.currentPlant.name, value : this.state.currentPlant.id }
                                : null
                            }
                            classNamePrefix={'optimar'}
                            menuPortalTarget={document.body}
                            styles={selectStyle}
                        />
                    </div>
                    <div className="form-group">
                        <DropdownList
                            modalRef={this.props.modalRef}                      
                            components={this.state.components}
                            onSelectItem={this.handleSelectItem}
                            selectedItem={this.state.selectedItem}
                            onComponentChange={this.handleComponentChange}
                            required={true}
                            filterString={this.state.selectedItemFilterString}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="type">Type</label>
                        <CreatableSelect
                            isClearable
                            onChange={this.handleTypeChange}
                            options={availableJobTypes}
                            setValue={this.createNewJobType}
                            value={this.state.jobtypes?.find((j) => j.value === this.state.job?.jobTypeId) || null}
                            classNamePrefix={'optimar'}
                            menuPortalTarget={document.body}
                            styles={selectStyle}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="startDate">Start Date</label>
                        <div ref={this.startDateRef} />
                        <ModalDatetime
                            modalRef={this.props.modalRef}
                            scopeToParentRef={this.startDateRef}
                            name="startDate"
                            isValidDate={(date) => {
                                return this.props.editType === 'child'
                                    ? this.dateValidation(date, 'start')
                                    : this.regularDateValidation(date, 'start');
                            }}
                            inputProps={{ readOnly: true }}
                            locale="en-gb"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            value={
                                this.state.job && this.state.job.startDate
                                    ? moment.utc(this.state.job.startDate).local()
                                    : moment().utc()
                            }
                            onChange={(date) => this.handleDateSelect(date, 'startDate')}
                            className={'date-input-control'}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="endDate">End Date</label>
                        <div ref={this.endDateRef} />
                        <ModalDatetime
                            modalRef={this.props.modalRef}
                            scopeToParentRef={this.endDateRef}
                            name="endDate"
                            isValidDate={(date) => {
                                return this.props.editType === 'child'
                                    ? this.dateValidation(date, 'end')
                                    : this.regularDateValidation(date, 'end');
                            }}
                            inputProps={{ readOnly: true }}
                            locale="en-gb"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            value={
                                this.state.job && this.state.job.endDate
                                    ? moment.utc(this.state.job.endDate).local()
                                    : null
                            }
                            onChange={(date) => this.handleDateSelect(date, 'endDate')}
                            className={'date-input-control'}
                        />
                    </div>

                    {config?.fields?.map((f,i) => {
                        return this.editField(f,i);
                    })}

                    <div className="btn-row-grow">{this.renderButtons()}</div>
                </form>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(JobAccountAddEdit);
