import React from 'react';
import './AdminPlantItemsAddEdit.scss';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { FormErrors } from './FormErrors';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as AngleDownIcon } from '../../../images/list/optimar_angle_down.svg';
import { Redirect } from 'react-router-dom';
import api from '../../../utility/api';
import { TreeSelect } from 'antd';
import GeneralSpinner from '../Spinner/GeneralSpinner';
import Modal from 'react-modal';
import { ReactComponent as AddIcon } from '../../../images/list/optimar_plus.svg';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import { ReactComponent as GreenIcon } from '../../../images/icons/green_circle.svg';
import { ReactComponent as RedIcon } from '../../../images/icons/red_circle.svg';
import { ReactComponent as YellowIcon } from '../../../images/icons/yellow_circle.svg';
import { ReactComponent as GrayIcon } from '../../../images/icons/gray_circle.svg';
import { ReactComponent as NoColorIcon } from '../../../images/icons/no_color_circle.svg';
import FontAwesome from 'react-fontawesome';
import { ReactComponent as QuestionMarkIcon } from '../../../images/icons/question_mark.svg';
import DatavalueColorIndicator from '../DatavalueColorIndicator/DatavalueColorIndicator';
import DatavalueModalForm from './DatavalueModalForm';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        paddingTop: '40px',
        paddingRight: '40px',
        paddingBottom: '40px',
        paddingLeft: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        overflow: 'none',
    },
};

const datavalueInitialState = {
    key: '',
    value: 'number',
};
const datavaluesByTopicInitialState = [];
class AdminPlantItemsAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemTypes: [],
            formErrors: { plant_Type: '', name: '' },
            nameValid: false,
            formValid: false,
            isDirty: false,
            plantItem: {
                articles: [],
            },
            sortedItems: [],
            parentValue: undefined,
            loading: false,
            isSubmitting: false,
            predefinedDatavalueAttribute: {
                topicTypes: [
                    { label: 'Datavalue', value: 'Datavalue' },
                    { label: 'Historic', value: 'Historic' },
                    { label: 'Event', value: 'Event' },
                    { label: 'Trace', value: 'Trace' },
                ],
                dtypes: ['number', 'bool', 'text', 'guid'],
                routeTypes: ['VAL', 'EVENT', 'ALARM', 'PARAM', 'TRACE'],
            },
            //assuming the structure of datavaluesByTopic is the array of {datavalues,topic,route,payload}
            //assuming datavalues is [] of {key,value,lastUpdatedTime}
            datavaluesByTopic: datavaluesByTopicInitialState, //to display added datavalues after added
            isTrafficLightIndicatorLoading: false,
            isDatavalueModalOpen: false,
            retryGetDatavaluesStatusIsTrue: true,
            modalError: undefined,
            isDropdownParentOpen: true,
            fields: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.fetchItemTypes = this.fetchItemTypes.bind(this);
        this.fetchArticles = this.fetchArticles.bind(this);
        this.handleOnFocusOut = this.handleOnFocusOut.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleItemTypeChange = this.handleItemTypeChange.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.handleParentChange = this.handleParentChange.bind(this);

        this.myRef = React.createRef();
        this.handleDatavalueModalOpenClose = this.handleDatavalueModalOpenClose.bind(this);
        this.handleEditDatavalue = this.handleEditDatavalue.bind(this);
        this.handleAddDatavalue = this.handleAddDatavalue.bind(this);
        this.submitDatavalues = this.submitDatavalues.bind(this);
        this.fetchDatavaluePlantStatus = this.fetchDatavaluePlantStatus.bind(this);
        this.renderDatavalueStatusIndicator = this.renderDatavalueStatusIndicator.bind(this);

        //callbacks
        this.dtByTopicCallback = this.dtByTopicCallback.bind(this);
    }

    dtByTopicCallback(fields) {

        this.setState({ ...this.state, datavaluesByTopic: fields });
    }

    handleAddDatavalue() {
        this.handleDatavalueModalOpenClose();
    }
    handleDatavalueModalOpenClose() {
        this.setState({
            isDatavalueModalOpen: !this.state.isDatavalueModalOpen,
            modalError: undefined,
        });
    }

    handleEditDatavalue() {
        this.setState({
            isDatavalueModalOpen: true,
        });
    }

    handleDeleteDavalueByTopic(event, parentIdx, childIdx) {
        let datavaluesByTopic = [...this.state.datavaluesByTopic];
        datavaluesByTopic[parentIdx].datavalues.splice(childIdx, 1);
        this.setState({
            datavaluesByTopic,
        });
    }

    renderDatavalueStatusIndicator(time) {
        try {
            let date = new Date(Date.parse(time));
            let now = new Date(Date.now());
            let hoursDiff = Math.abs(date.getTime() - now.getTime()) / (1000 * 60 * 60);

            if (isNaN(hoursDiff)) {
                return <GrayIcon />;
            }
            if (hoursDiff < 48) {
                return <GreenIcon />;
            }
            if (48 < hoursDiff && hoursDiff < 24 * 30) {
                return <YellowIcon />;
            }
            if (hoursDiff > 24 * 30) {
                return <RedIcon />;
            }
        } catch (error) {
            return <NoColorIcon />;
        }
    }

    closeMenu = (event) => {
        this.setState({
            isDropdownParentOpen: this.myRef.current && !this.myRef.current.contains(event.target) ? false : true,
        });
    };

    handleChange(event) {
        console.log(event.target.name, event.target.value);
        this.setState({
            isDirty: true,
            itemAdded: false,
            validated: false,
        });
        const target = event.target;
        const name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;

        if (name === 'itemKey') {
            value = value.replace(/([^A-Za-z0-9])/g, '');
        }

        const tmp = Object.assign({}, this.state.plantItem);
        tmp[name] = value;
        console.log(tmp);
        if (
            name === 'itemName' &&
            (!this.state.plantItem.itemKey || this.state.originalItemKeyEmpty || this.props.quickAdd)
        ) {
            this.setState({
                originalItemKeyEmpty: true,
            });
            tmp.itemKey = value.replace(/([^A-Za-z0-9])/g, '');
        }

        this.setState({ plantItem: tmp });
    }

    validateFields() {
        this.validateField('plantId', this.state.plantItem.plantId);
    }

    validateField(fieldName, value) {
        const fieldValidationErrors = this.state.formErrors;
        let plantIdValid = this.state.plantIdValid;
        // let nameValid = this.state.nameValid;
        switch (fieldName) {
            case 'plantId':
                plantIdValid = value.length > 30;
                fieldValidationErrors.plant_id = plantIdValid ? '' : ' is invalid';
                this.setState({
                    plantIdValid: plantIdValid,
                });
                break;
            default:
                break;
        }
        this.setState(
            {
                formErrors: fieldValidationErrors,
                validated: true,
            },
            this.validateForm
        );
    }

    validateForm() {
        this.setState({ formValid: this.state.plantIdValid });
    }

    componentWillUnmount() {
        document.removeEventListener('wheel', this.closeMenu);
        document.removeEventListener('mousedown', this.closeMenu);
    }

    componentDidMount() {
        console.log('mounted');
        document.addEventListener('wheel', this.closeMenu);
        document.addEventListener('mousedown', this.closeMenu);
        this.fetchItemTypes();
        this.populateParentHierarchy();
        if (this.props.plantItemParent) {
            // Create child
            const tmp = {};
            tmp.parentItemName = this.props.plantItemParent.name;
            tmp.itemName = 'New item';
            tmp.plantId = this.props.plant.id;
            tmp.parentId = this.props.plantItemParent.id;
            tmp.itemTypeId = '';
            let parentTopicKey = this.props.plantItemParent.topicKey
                ? this.props.plantItemParent.topicKey
                : this.props.plantItemParent.topic;
            this.setState(
                {
                    mode: 'create',
                    plantItem: tmp,
                    parentTopicKey,
                },
                this.validateFields
            );
        } else if (this.props.addRootPlantItem) {
            // Create root
            const tmp = {};
            tmp.parentItemName = 'None';
            tmp.itemName = 'New item';
            tmp.plantId = this.props.plant.id;
            tmp.itemTypeId = '';
            this.setState(
                {
                    plantItem: tmp,
                },
                this.validateFields
            );
        } else if (this.props.editPlantItem) {
            // Edit Existing
            this.setState(
                {
                    mode: 'edit',
                    parentValue:
                        this.props.editPlantItem.parentId !== undefined
                            ? this.props.editPlantItem.parentId !== '00000000-0000-0000-0000-000000000000'
                                ? this.props.editPlantItem.parentId
                                : undefined
                            : undefined,
                },
                () => this.fetchItem(this.props.editPlantItem.id, this.props.editPlantItem.topic)
            );
        }
    }

    populateParentHierarchy(itemId) {
        let tree = [],
            childrenOf = {};
        if (this.props.items) {
            const ID_KEY = 'id';
            const PARENT_KEY = 'parentId';
            const CHILDREN_KEY = 'children';
            let item, id, parentId;
            for (let i = 0, length = this.props.items.length; i < length; i++) {
                item = this.props.items[i];
                id = item[ID_KEY];
                parentId = item[PARENT_KEY] || '00000000-0000-0000-0000-000000000000';
                // every item may have children
                childrenOf[id] = childrenOf[id] || [];
                // init its children
                item[CHILDREN_KEY] = childrenOf[id];
                item.toggled = true;
                if (parentId !== '00000000-0000-0000-0000-000000000000') {
                    // init its parent's children object
                    childrenOf[parentId] = childrenOf[parentId] || [];
                    // push it into its parent's children object
                    childrenOf[parentId].push(item);
                } else {
                    tree.push(item);
                }
            }
        }
        const sortedTree = tree.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
        );

        const itemsHierarchy = this.sortItems(sortedTree, itemId);
        this.setState({ sortedItems: itemsHierarchy });
    }

    sortItems(arr, id) {
        const sortedArray = [];

        arr.forEach((element) => {
            if (element.id !== id) {
                element.title = element.name;
                element.value = element.id;
                element.key = element.id;

                if (element.children && element.children.length > 0) element.children = this.sortChildren(element, id);

                sortedArray.push(element);
            }
        });
        return sortedArray;
    }

    sortChildren(item, id) {
        const children = [];

        if (item.children && item.children.length > 0) {
            const sortedChildren = item.children
                .sort((a, b) =>
                    a.name.toUpperCase() > b.name.toUpperCase()
                        ? 1
                        : b.name.toUpperCase() > a.name.toUpperCase()
                            ? -1
                            : 0
                )
                .filter((x) => x.id !== id);

            sortedChildren.forEach((element) => {
                if (element.id !== id) {
                    element.title = element.name;
                    element.value = element.id;
                    element.key = element.id;

                    if (element.children && element.children.length > 0)
                        element.children = this.sortChildren(element, id);

                    children.push(element);
                }
            });
        }
        return children;
    }

    componentDidUpdate(prevProps, _prevState) {
        const { plantItemParent } = prevProps;
        //if(JSON.stringify(this.props) === JSON.stringify(prevProps)) return;
        if (
            this.props.plantItemParent &&
            JSON.stringify(this.props.plantItemParent) !== JSON.stringify(plantItemParent)
        ) {
            console.log('1', this.props.plantItemParent);
            const tmp = {};
            tmp.parentItemName = this.props.plantItemParent.name;
            tmp.parentId = this.props.plantItemParent.id;
            tmp.plantId = this.props.plant.id;
            tmp.itemTypeId = 0;
            tmp.itemName = 'New item';
            tmp.itemTypeId = '';
            tmp.topicKey = this.props.plantItemParent.topicKey;
            let parentTopicKey = this.props.plantItemParent.topicKey
                ? this.props.plantItemParent.topicKey
                : this.props.plantItemParent.topic;
            this.setState({
                plantItem: tmp,
                mode: 'create',
                parentTopicKey,
            });
        } else if (
            this.props.editPlantItem &&
            (prevProps.editPlantItem === undefined || this.props.editPlantItem.id !== prevProps.editPlantItem.id)
        ) {
            console.log('2');
            this.populateParentHierarchy(this.props.editPlantItem.id);
            this.setState(
                {
                    mode: 'edit',
                    parentValue:
                        this.props.editPlantItem.parentId !== undefined
                            ? this.props.editPlantItem.parentId !== '00000000-0000-0000-0000-000000000000'
                                ? this.props.editPlantItem.parentId
                                : undefined
                            : undefined,
                },
                () => this.fetchItem(this.props.editPlantItem.id, this.props.editPlantItem.topic)
            );
            this.fetchDatavaluePlantStatus(this.props.editPlantItem.topic);
        } else if (this.props.addRootPlantItem) {
            console.log('3');
            // Create root
            console.log('plantItem', this.state.plantItem);
            const tmp = {};
            tmp.parentItemName = 'None';
            tmp.itemName = 'New item';
            tmp.plantId = this.props.plant.id;
            tmp.itemTypeId = '';
            if (!this.state.plantItem && JSON.stringify(tmp) !== JSON.stringify(this.state.plantItem)) {
                this.setState(
                    {
                        plantItem: Object.assign({}, tmp),
                        mode: '',
                    },
                    this.validateFields
                );
            }
        }

        if (this.state.sortedItems.length <= 0 && this.props.items > 0) {
            this.populateParentHierarchy();
        }
    }

    async fetchDatavaluePlantStatus(plantTopicKey) {
        this.setState({
            datavaluesByTopic: datavaluesByTopicInitialState,
            isTrafficLightIndicatorLoading: true,
        });
        try {
            let plantKey = plantTopicKey.substring(0, plantTopicKey.indexOf('/'));
            let topicKey = plantTopicKey.substring(plantTopicKey.indexOf('/') + 1, plantTopicKey.length);
            const response = await api.get(
                process.env.REACT_APP_API +
                `/api/TopicDatavalue/get_plant_status?plantKey=${plantKey}&topicKey=${topicKey}`
            );

            if (response.status === 200) {
                let datavaluesByTopic = [];
                response.data.forEach(({ id, topic, datavalueModified, dTypesAsDict }) => {
                    let datavalues = [];
                    let topics = topic.split('/');
                    let selectedTopic = '';
                    let selectedPayload = topics[2];
                    let selectedRoute = topics[3];
                    if (topics.includes('VAR') && topics.includes('TRACE')) {
                        selectedTopic = 'Trace';
                    } else if (topics.includes('VAR') && topics.includes('EVENT')) {
                        selectedTopic = 'Event';
                    } else if (topics.includes('VARLIST') && topics.includes('VAL')) {
                        selectedTopic = 'Historic';
                    } else if (topics.includes('VAR') && topics.includes('VAL')) {
                        selectedTopic = 'Datavalue';
                    } else {
                        selectedTopic = topics[3][0] + topics[3].substring(1).toLowerCase();
                    }

                    dTypesAsDict.forEach((element, index) => {
                        datavalues.push({
                            key: element.key,
                            value: element.value,
                            lastUpdatedTime: datavalueModified[index] ? datavalueModified[index].value : null,
                        });
                    });
                    datavaluesByTopic.push({
                        datavalues,
                        topic: selectedTopic,
                        payload: selectedPayload,
                        route: selectedRoute,
                    });
                    this.setState({
                        datavaluesByTopic,
                        isTrafficLightIndicatorLoading: false,
                    });
                });
                console.log('success');
            } else {
                console.log('failed');
                this.setState({
                    isTrafficLightIndicatorLoading: false,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    fetchItemTypes() {
        api.get(process.env.REACT_APP_API + '/api/itemTypes/')
            .then((res) => this.setState({ itemTypes: res.data }))
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    fetchArticles() {
        api.get(process.env.REACT_APP_API + '/api/article')
            .then((res) => {
                const tmp = Object.assign({}, this.state.plantItem);
                tmp.articles = tmp.articles || [];
                const filtered = res.data.filter(
                    (article) =>
                        tmp.articles
                            .map((x) => {
                                return x.articleId;
                            })
                            .indexOf(article.id) > -1
                );
                const currentArticles = filtered.map(function (d) {
                    return {
                        value: d.id,
                        label: d.name,
                        articleId: d.id,
                    };
                });

                tmp.articles = currentArticles;

                const allArticles = res.data.map(function (d) {
                    return {
                        value: d.id,
                        label: d.name,
                    };
                });

                this.setState({
                    selectedArticles: currentArticles,
                    plantItem: tmp,
                    articles: allArticles,
                });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    fetchItem(plantItemId, topic) {
        this.setState({ loading: true });
        api.get(process.env.REACT_APP_API + '/api/items/' + plantItemId)
            .then((res) => {
                res.data.itemName = res.data.name;
                if (!res.data.parentItemName) {
                    res.data.parentItemName = 'None';
                }
                this.setState({ plantItem: res.data, loading: false, parentTopicKey: topic }, () => {
                    this.fetchArticles();
                    this.validateFields();
                });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    async handleSubmit(event) {
        console.log('handleSubmit');
        this.setState({ isSubmitting: true });
        if (this.state.mode === 'edit') {
            await this.updateSubmit(event);
        } else {
            await this.createSubmit(event);
        }
        await this.submitDatavalues();
        this.setState({ isSubmitting: false });
    }

    async submitDatavalues() {
        let plantCode = this.state.parentTopicKey;
        if (this.state.mode !== 'edit') {
            plantCode = plantCode + '/' + this.state.plantItem.itemKey;
        }
        console.log("🚀 ~ file: AdminPlantItemsAddEdit.js:853 ~ AdminPlantItemsAddEdit ~ submitDatavalues ~ plantCode:", plantCode);
        try {
            let payload = { topicDatavalues: this.state.datavaluesByTopic, plantCode };
            const response = await api.put(
                process.env.REACT_APP_API + '/api/TopicDatavalue/add_edit_topic_datavalues',
                payload
            );
            if (response.status === 200) {
                let { success, failed } = response.data;
                if (success > 0) {
                    NotificationManager.success('Datavalue(s) updated', `Datavalue(s) successfully updated.`, 5000);
                } else {
                    NotificationManager.error('Error', 'Failed to update datavalues', 5000);
                }
            } else {
                NotificationManager.error('Error', 'Failed to update datavalues', 5000);
            }
        } catch (error) {
            NotificationManager.error('Error', 'Failed to update datavalues', 5000);
        }
    }

    async createSubmit(event) {
        event.preventDefault();
        const plantItem = this.state.plantItem;
        plantItem.name = plantItem.itemName;
        plantItem.itemState = 1;
        plantItem.showInTableView = true;
        try {
            const response = await api.post(process.env.REACT_APP_API + '/api/items', plantItem);

            if (response.status === 200) {
                NotificationManager.success('Item created', 'Item successfully created', 5000);
                this.props.addedCallback();
            } else {
                NotificationManager.error('Error', 'Failed to create item', 5000);
                throw new Error(response.status);
            }
        } catch (error) {
            NotificationManager.error('Error', 'Failed to create item', 5000);
        }
    }

    async updateSubmit(event) {
        event.preventDefault();
        const plantItem = this.state.plantItem;
        plantItem.name = plantItem.itemName;
        plantItem.itemState = 1;
        if (plantItem.showInTableView === null) {
            plantItem.showInTableView = true;
        }

        plantItem.articles.forEach((a) => {
            a.ItemId = plantItem.id;
            a.ArticleId = a.value;
            a.Id = '00000000-0000-0000-0000-000000000000';
        });
        try {
            const response = await api.put(process.env.REACT_APP_API + '/api/items/' + plantItem.id, plantItem);

            if (response.status === 200) {
                NotificationManager.success('Item updated', 'Item successfully updated', 5000);
                this.props.editedCallback();
                this.redirect('/admin/plant/items');
            } else {
                NotificationManager.error('Error', 'Failed to update item', 5000);
            }
        } catch (error) {
            NotificationManager.error('Error', 'Failed to update item', 5000);
        }
    }

    handleOnFocusOut(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.validateField(name, value);
    }

    handleItemTypeChange(event) {
        this.handleChange(event);
        this.handleOnFocusOut(event);
    }

    renderButton() {
        const button = [
            <button
                key={'cancle-btn'}
                className="btn btn-yellow btn-grow"
                onClick={(e) => {
                    e.preventDefault();
                    this.props.closeModal();
                }}
            >
                Cancel
            </button>,
        ];

        if (this.state.mode === 'edit') {
            button.push(
                <button
                    key={'saving'}
                    className="btn btn-blue btn-grow"
                    value="Submit"
                    disabled={!this.state.formValid}
                >
                    {this.state.isSubmitting ? 'Saving changes...' : 'Save changes'}
                </button>
            );
        } else {
            button.push(
                <button
                    key={'creating'}
                    className="btn btn-blue btn-grow"
                    value="Submit"
                    disabled={!this.state.formValid}
                >
                    {this.state.isSubmitting ? 'Creating...' : 'Create'}
                </button>
            );
        }
        return button;
    }

    redirect(path) {
        this.setState({
            redirect: true,
            redirectPath: path,
        });
    }

    handleParentChange(e) {
        console.log(e);
        const tmp = Object.assign({}, this.state.plantItem);
        if (e === tmp.parentId) return;

        tmp.parentId = e;
        this.setState({ parentValue: e, isDirty: true, plantItem: tmp });
    }

    handleMultiSelectChange = (selectedArticles) => {
        this.setState({ selectedArticles });

        const tmp = this.state.plantItem;
        tmp.articles = selectedArticles;

        this.setState({
            plantItem: tmp,
        });
    };

    onInputChange = (inputValue, { action }) => {
        console.log(action);
        switch (action) {
            case 'input-change':
                this.setState({ inputValue });
                return;
            case 'menu-close': {
                let menuIsOpen = undefined;
                if (this.state.inputValue) {
                    menuIsOpen = true;
                }
                this.setState({
                    menuIsOpen,
                });
                return;
            }
            default:
                return;
        }
    };

    render() {
        if (!this.props) return;

        if (this.state.datavaluesByTopic.length === 0) {
            if (this.props.editPlantItem) {
                if (this.state.retryGetDatavaluesStatusIsTrue) {
                    this.fetchDatavaluePlantStatus(this.props.editPlantItem.topic);
                    this.setState({
                        retryGetDatavaluesStatusIsTrue: false,
                    });
                }
            }
        }
        if (this.state.mode === 'create' && this.state.datavaluesByTopic !== datavaluesByTopicInitialState) {
            this.setState({
                datavaluesByTopic: datavaluesByTopicInitialState,
            });
        }

        if (this.state.redirect) {
            return <Redirect to={this.state.redirectPath} />;
        }
        if (this.state.loading) {
            return <GeneralSpinner text="Loading item" />;
        }

        const DropdownIndicator = (props) => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <AngleDownIcon className="angle-down" />
                    </components.DropdownIndicator>
                )
            );
        };

        const itemTypes = this.state.itemTypes.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        const formValid = this.state.formValid;
        const formDirty = this.state.isDirty;
        const quickAdd = this.props.quickAdd || false;
        let warning = '';

        if (!formValid && formDirty) {
            warning = (
                <div>
                    <FormErrors formErrors={this.state.formErrors} />
                </div>
            );
        }

        const { inputValue, menuIsOpen } = this.state;

        const styles = {
            control: (base) => ({
                ...base,
                background: '#1E2E34',
                borderRadius: '0.1em',
                borderStyle: 'none',
            }),
            singleValue: (base) => ({
                ...base,
                color: 'rgb(230, 230, 240)',
            }),
            input: (base) => ({
                ...base,
                color: 'blue',
            }),
        };

        return (
            <div>
                <div className="admin-plant-items-add-edit-header">
                    <span>{this.state.plantItem.itemName}</span>
                </div>
                <div className="admin-plant-items-add-edit-subheader">
                    <span>{this.state.plantItem.topic}</span>
                </div>

                {warning}
                <form onSubmit={this.handleSubmit} className="admin-plant-items-add-edit-form">
                    <div ref={this.myRef}>
                        <label htmlFor="parentItem">Parent Item</label>
                        <TreeSelect
                            showSearch
                            name="parentItem"
                            style={{ width: '100%' }}
                            value={
                                this.props.plantItemParent
                                    ? this.props.plantItemParent
                                        ? this.props.plantItemParent.name
                                        : 'None'
                                    : this.state.parentValue
                            }
                            dropdownStyle={{
                                maxHeight: 600,
                                overflow: 'auto',
                                visibility: this.state.isDropdownParentOpen ? 'visible' : 'hidden',
                                backgroundColor: '#1e2e34',
                                color: '#fff !important',
                            }}
                            treeData={this.state.sortedItems}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            placeholder="None"
                            treeNodeFilterProp="name"
                            // treeDefaultExpandAll
                            allowClear
                            onChange={this.handleParentChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="itemType">
                            Item Type {!quickAdd ? <span className="small-text">&nbsp;Required</span> : null}
                        </label>
                        <select
                            required={!quickAdd}
                            value={this.state.plantItem.itemTypeId}
                            className={'admin-plant-items-input-control'}
                            name="itemTypeId"
                            onChange={this.handleItemTypeChange}
                        >
                            <option value="">Choose Item Type</option>
                            {itemTypes.map((type, i) => {
                                return (
                                    <option key={'itemType-' + i} id={i} value={type.id}>
                                        {type.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="itemName">
                            Name <span className="small-text">&nbsp;Required</span>
                        </label>
                        <input
                            required
                            ref={(input) => {
                                this.nameInput = input;
                            }}
                            autoComplete="off"
                            type="text"
                            className={'admin-plant-items-input-control'}
                            name="itemName"
                            value={this.state.plantItem.itemName || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>

                    <div>
                        <label htmlFor="itemKey">
                            Item Key {!quickAdd ? <span className="small-text">&nbsp;Required</span> : null}
                        </label>
                        {!quickAdd ? (
                            <input
                                required
                                autoComplete="off"
                                type="text"
                                className={'admin-plant-items-input-control'}
                                name="itemKey"
                                value={this.state.plantItem.itemKey || ''}
                                onChange={this.handleChange}
                                onBlur={this.handleOnFocusOut}
                            />
                        ) : (
                            <input
                                autoComplete="off"
                                type="text"
                                className={'admin-plant-items-input-control'}
                                name="itemKey"
                                value={this.state.plantItem.itemKey || ''}
                                onChange={this.handleChange}
                                onBlur={this.handleOnFocusOut}
                            />
                        )}
                    </div>

                    {!quickAdd ? (
                        <div>
                            <label htmlFor="machineNumber">Machine Number</label>
                            <input
                                autoComplete="off"
                                type="number"
                                className={'admin-plant-items-input-control'}
                                name="machineNumber"
                                value={this.state.plantItem.machineNumber || ''}
                                onChange={this.handleChange}
                                onBlur={this.handleOnFocusOut}
                            />
                        </div>
                    ) : null}

                    {!quickAdd ? (
                        <div>
                            <label htmlFor="componentId">Component ID</label>
                            <input
                                autoComplete="off"
                                type="text"
                                className={'admin-plant-items-input-control'}
                                name="componentId"
                                value={this.state.plantItem.componentId || ''}
                                onChange={this.handleChange}
                                onBlur={this.handleOnFocusOut}
                            />
                        </div>
                    ) : null}

                    {!quickAdd ? (
                        <div>
                            <label htmlFor="serialNumber">Serial Number</label>
                            <input
                                autoComplete="off"
                                type="text"
                                className={'admin-plant-items-input-control'}
                                name="serialNumber"
                                value={this.state.plantItem.serialNumber || ''}
                                onChange={this.handleChange}
                                onBlur={this.handleOnFocusOut}
                            />
                        </div>
                    ) : null}

                    {!quickAdd ? (
                        <div className="form-group">
                            <label htmlFor="articles">Articles</label>
                            <Select
                                styles={styles}
                                isMulti={true}
                                value={this.state.selectedArticles}
                                isClearable
                                isSearchable
                                inputValue={inputValue}
                                onInputChange={this.onInputChange}
                                onChange={this.handleMultiSelectChange}
                                className="Select-menu-outer"
                                options={this.state.articles}
                                menuIsOpen={menuIsOpen}
                                components={{ DropdownIndicator, IndicatorSeparator: null }}
                                classNamePrefix={'optimar'}
                            />
                        </div>
                    ) : null}

                    {/*!quickAdd ?
                        <div>
                            <label htmlFor="specification">Specification</label>
                            <input autoComplete="off" type="text" className={"admin-plant-items-input-control"} name="specification" value={this.state.plantItem.specification || ""} onChange={this.handleChange} onBlur={this.handleOnFocusOut} />
                        </div>
                    : null*/}

                    {/*!quickAdd ?
                        <div>
                            <label htmlFor="typeSpecification">Type Specification</label>
                            <input autoComplete="off" type="text" className={"admin-plant-items-input-control"} name="typeSpecification" value={this.state.plantItem.typeSpecification || ""} onChange={this.handleChange} onBlur={this.handleOnFocusOut} />
                        </div>
                    : null*/}

                    {!quickAdd ? (
                        <div>
                            <label htmlFor="orderPos">Order Pos</label>
                            <input
                                autoComplete="off"
                                type="text"
                                className={'admin-plant-items-input-control'}
                                name="orderPos"
                                value={this.state.plantItem.orderPos || ''}
                                onChange={this.handleChange}
                                onBlur={this.handleOnFocusOut}
                            />
                        </div>
                    ) : null}

                    {!quickAdd ? (
                        <div>
                            <label htmlFor="productSheet">Product Sheet</label>
                            <input
                                autoComplete="off"
                                type="text"
                                className={'admin-plant-items-input-control'}
                                name="productSheet"
                                value={this.state.plantItem.productSheet || ''}
                                onChange={this.handleChange}
                                onBlur={this.handleOnFocusOut}
                            />
                        </div>
                    ) : null}

                    <div>
                        <label htmlFor="productSheet">Subdevice ID</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'admin-plant-items-input-control'}
                            name="subDeviceId"
                            value={this.state.plantItem.subDeviceId || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>

                    <div>
                        <label htmlFor="addDataValue" className="addDataValue">
                            <span>Datavalue</span>
                            <span
                                className="add-datavalue"
                                onClick={this.handleAddDatavalue}
                                style={{ cursor: 'pointer' }}
                            >
                                &nbsp;+ Add
                            </span>
                            <DatavalueColorIndicator />
                        </label>
                        <div className={'datavalues-wrapper'}>
                            {this.state.isTrafficLightIndicatorLoading ? (
                                <GeneralSpinner text="Loading item" />
                            ) : (
                                this.state.datavaluesByTopic.map((props, parentIdx) => {
                                    const { datavalues, topic, payload, route } = props;
                                    return (
                                        <>
                                            {datavalues.length > 0 ? (
                                                <span
                                                    key={topic + payload + route}
                                                    className="topic-text-header"
                                                    style={{ paddingLeft: 10 }}
                                                >
                                                    {topic} ({route}/{payload})
                                                </span>
                                            ) : null}
                                            <table style={{ paddingLeft: 20 }}>
                                                <tbody>
                                                    {datavalues.map(({ key, value, lastUpdatedTime }, childIdx) => {
                                                        return (
                                                            <>
                                                                <tr key={key + value}>
                                                                    <td className="td-datavalue td-key-value">
                                                                        {key} ({value})
                                                                    </td>
                                                                    <td className="td-datavalue circle-icon">
                                                                        {this.renderDatavalueStatusIndicator(
                                                                            lastUpdatedTime
                                                                        )}
                                                                    </td>
                                                                    <td className="td-datavalue dotted-datavalue dotted">
                                                                        <FontAwesome
                                                                            name="ellipsis-h"
                                                                            className={'list-btn'}
                                                                            style={{
                                                                                fontSize: '17px',
                                                                                color: '#41C9F0',
                                                                                border: '1px',
                                                                            }}
                                                                            onClick={(e) => e.stopPropagation()}
                                                                        />
                                                                        <div className="admin-plant-item-row-actions">
                                                                            <div
                                                                                className="action-element"
                                                                                title="Copy from branch"
                                                                                onClick={(e) => {
                                                                                    // e.stopPropagation();
                                                                                    this.handleEditDatavalue(
                                                                                        e,
                                                                                        props,
                                                                                        parentIdx,
                                                                                        childIdx
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <AddIcon className="action-icon" />
                                                                                <span className="list-edit-btn">
                                                                                    Edit
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                className="action-element"
                                                                                title="Delete item"
                                                                                onClick={(e) => {
                                                                                    // e.stopPropagation();
                                                                                    // this.props.deleteCallback(node);
                                                                                    this.handleDeleteDavalueByTopic(
                                                                                        props,
                                                                                        parentIdx,
                                                                                        childIdx
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <DeleteIcon className="action-icon red" />
                                                                                <span className="list-edit-btn">
                                                                                    Delete
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </>
                                    );
                                })
                            )}
                        </div>
                    </div>

                    {!quickAdd ? (
                        <div>
                            <label htmlFor="showInTableView">Show in table view</label>
                            <input
                                width="50px"
                                autoComplete="off"
                                type="checkbox"
                                className={'admin-plant-items-input-control'}
                                name="showInTableView"
                                defaultChecked={this.state.plantItem.showInTableView || true}
                                checked={this.state.plantItem.showInTableView}
                                onChange={this.handleChange}
                                onBlur={this.handleOnFocusOut}
                            />
                        </div>
                    ) : null}

                    {/*!quickAdd ?
                        <div>
                            <label htmlFor="traceEnabled">Trace Enabled</label>
                            <input width="50px" autoComplete="off" type="checkbox" className={"admin-plant-items-input-control"} name="traceEnabled" defaultChecked={this.state.plantItem.traceEnabled} checked={this.state.plantItem.traceEnabled} onChange={this.handleChange} onBlur={this.handleOnFocusOut} />
                    </div> : null*/}

                    <div className="btn-row btn-row-grow">{this.renderButton()}</div>
                </form>
                <Modal
                    isOpen={this.state.isDatavalueModalOpen}
                    onRequestClose={this.handleDatavalueModalOpenClose}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    {this.state.modalError ? <span>{this.state.modalError}</span> : null}
                    <DatavalueModalForm
                        predefinedDatavalueAttribute={this.state.predefinedDatavalueAttribute}
                        handleDatavalueModalOpenClose={this.handleDatavalueModalOpenClose}
                        dtByTopicCallback={this.dtByTopicCallback}
                        datavaluesByTopic={this.state.datavaluesByTopic}
                    />

                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentAdminPlant,
    items: state.plants.currentAdminPlantItems,
});

export default connect(mapStateToProps)(AdminPlantItemsAddEdit);
