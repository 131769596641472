export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';

export const setCurrentUser = (user) => {
    return {
        type: SET_CURRENT_USER,
        data: user,
    };
};

export const clearCurrentUser = () => {
    return {
        type: CLEAR_CURRENT_USER,
        data: null,
    };
};
