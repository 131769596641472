import * as moment from 'moment';
export const getTimeSince = (res) => {
    //res.data can also be a collection of datapoints here.

    if (res.data && Object.keys(res.data).length === 0) return 'Fetching data';

    let diffTicks = '';
    let diffDescr = ' minutes ago';

    let timestamp = res.data?.timestamp;
    if (!timestamp) {
        if (Array.isArray(res.data)) {
            timestamp = res.data[0].timestamp;
        }
    }
    if (timestamp) {
        diffTicks = moment(new Date()).diff(timestamp, 'minutes');

        if (diffTicks < 3) {
            diffTicks = '';
            diffDescr = '';
        } else if (diffTicks > 60) {
            diffTicks = moment(new Date()).diff(timestamp, 'hours');
            diffDescr = ' hours ago';

            if (diffTicks > 24) {
                diffTicks = moment(new Date()).diff(timestamp, 'days');
                diffDescr = ' days ago';

                if (diffTicks > 50) {
                    diffTicks = '';
                    diffDescr = 'Old data';
                }
            }
        }
    }
    //console.log(res, timestamp)
    const timeSince = timestamp ? diffTicks + diffDescr : 'No data';

    return timeSince;
};
