export default {
    control: {
        backgroundColor: '#1e2e34',
        fontSize: 16,
        fontWeight: 'normal',
        color: '#e6e6f0',
    },

    '&multiLine': {
        control: {
            fontFamily: 'monospace',
            minHeight: 63,
        },
        highlighter: {
            padding: 9,
            border: '1px solid transparent',
        },
        input: {
            padding: 9,
            border: '1px solid silver',
            color: '#e6e6f0',
        },
    },

    '&singleLine': {
        display: 'inline-block',
        width: 180,

        highlighter: {
            padding: 1,
            border: '2px inset transparent',
        },
        input: {
            padding: 1,
            border: '2px inset',
            color: '#e6e6f0',
        },
    },

    suggestions: {
        list: {
            backgroundColor: '#1e2e34',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 16,
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#0D171E',
            },
        },
    },
};
