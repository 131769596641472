import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { setPlantList } from '../../../actions';
import { withLocation, withRouteMatch } from '../../../HOC';
import { AccessModuleChecker } from '../../../utility/accModule';
import api from '../../../utility/api';
import Dashboard from '../../../views/dashboard';
import DocumentList from '../../../views/documentlist/index';
import NoteList from '../../../views/notelist/notelist';
import PlantList from '../../../views/plantlist';
import SupportList from '../../../views/support';
import ProjectList from '../../ProjectList';
import NavConfig from '../Nav/NavConfig';
import NavContainer from '../Nav/NavContainer';
import AccountJobs from '../../../views/accountjobs/accountjobs';
import './account.scss';
//import AdminRole from "../AdminRole/AdminRole";

class Account extends React.Component {
    constructor(props) {
        super(props);

        this.props.match.params = { accountId: this.props.user.profile.AccountId };
        this.fetching = false;
        this.state = {
            pages: 0,
            count: 0,
        };
    }

    componentDidUpdate() {
        if (this.props.location.pathname === '/account' && this.props.user) {
            this.fetchPlantData();
        }
    }

    fetchPlantData() {
        if (!this.fetching) {
            this.fetching = true;
            api.get(`api/plants`)
                .then((res) => {
                    this.fetching = false;
                    this.props.setPlantList(res.data);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    render() {
        const user = this.props.user;
        return (
            <div className="account-container">
                <h1>{this.props.user.profile.AccountName}</h1>
                <NavContainer defaultConfig={NavConfig.account} path={this.props.match} />

                <Switch>
                    <Route exact path="/account/plant" name="Plants">
                        <PlantList />
                    </Route>                    
                    <Route exact path="/account/operations" name="Operations">
                        <AccountJobs />
                    </Route>
                    <Route path="/account/dashboard" name="Fleet dashboard">
                        <AccessModuleChecker user={user} moduleName="KPI">
                            <Dashboard type="account" accountMode={true} />
                        </AccessModuleChecker>
                    </Route>
                    <Route exact path="/account/notes" name="Notes">
                        <AccessModuleChecker user={user} moduleName="Notes">
                            <NoteList />
                        </AccessModuleChecker>
                    </Route>
                    <Route exact path="/account/documentation" name="Documentation">
                        <AccessModuleChecker user={user} moduleName="Documentation">
                            <DocumentList />
                        </AccessModuleChecker>
                    </Route>
                    <Route exact path="/account/projects" name="Projects">
                        <ProjectList />
                    </Route>
                    <Route exact path="/account/support" name="Support">
                        <AccessModuleChecker user={user} moduleName="Support">
                            <SupportList accountMode={true} />
                        </AccessModuleChecker>
                    </Route>
                    <Route path="/account" name="Plants">
                        <PlantList />
                    </Route>
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

const mapDispatchToProps = {
    setPlantList,
};

export default withLocation(withRouteMatch(connect(mapStateToProps, mapDispatchToProps)(Account)));
