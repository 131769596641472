import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import FileUpload from '../../components/common/FileUpload/FileUpload';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import Spinner from '../../components/common/Spinner/Spinner';
import api from '../../utility/api';
import { guidIsNullOrEmpty } from '../../utility/guidFunctions';
import { confirmDelete, createAndDownloadFile, fetchSingleDocAsync } from './../documentlist/documentoperations';
import fileIcons from './../documentlist/filetype-icons';
import './jobdocuments.scss';

library.add(fas, far);

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
    },
};

function JobDocuments(props) {
    const [documents, setDocuments] = React.useState([]);
    const [fetchingDocuments, setFetchingDocuments] = React.useState(false);
    const [modalOpen, setModalState] = React.useState(false);
    const [linkOrFile, setLinkOrFile] = React.useState(null);
    const [editItem, setEditItem] = React.useState(null);

    const fetchDocumentsCallback = React.useCallback(() => {
        if (fetchingDocuments) {
            return;
        }
        if (guidIsNullOrEmpty(props.plantId) || guidIsNullOrEmpty(props.jobId)) {
            console.log(`Missing plant and/or job id. Cannot show documents.`);
        }
        setFetchingDocuments(true);
        const fetchAsync = async () => {
            try {
                const url = `${process.env.REACT_APP_API}/api/documents/job/${props.plantId}/${props.jobId}`;

                const res = await api.get(url);
                if (res?.data) setDocuments(res.data);
                else {
                    console.log('No data returned');
                }
            } catch (err) {
                console.log(`Error fetching job documents || jobid: ${props.jobId} || plantid: ${props.plantId} `, err);
            } finally {
                setFetchingDocuments(false);
            }
        };
        fetchAsync();
    });

    React.useEffect(() => {
        fetchDocumentsCallback();
    }, []);

    function openModal(type) {
        setLinkOrFile(type);
        setModalState(true);
    }

    function closeModal() {
        setModalState(false);
        setEditItem(null);
    }

    function canUpload() {
        if (!sessionStorage.getItem('userImpersonate') && props.plantId) {
            return true;
        }
        return false;
    }

    function getHeader() {
        const header = {
            left: [],
            right: [],
        };
        return header;
    }

    const openFile = (doc) => {
        console.log('openFile', doc);
        //let doc = row.original;
        if (!doc) {
            NotificationManager.warning('Please try again later', 'Unable to open document', 5000);
            return;
        }
        doc.link && !doc.external ? document.open(doc.link) : fetchSingleDoc(doc);
    };
    const editFile = (doc) => {
        setEditItem(doc);
        openModal(doc.link ? 'link' : 'file');
    };

    const fetchSingleDoc = async (doc) => {
        try {
            const response = await fetchSingleDocAsync(doc, props);
            if (response.status === 200) {
                createAndDownloadFile(response.data, doc.filename);
                NotificationManager.success('Document downloaded successfully', 'Download success');
            } else {
                NotificationManager.error('Failed to download document', 'Download failed', 5000);
            }
        } catch (err) {
            console.error(err);
            NotificationManager.error('Failed to download document', 'Download failed', 5000);
        }
    };

    const hideDocModifyContextMenuFunctions = (row) => {
        if (row.original.external?.length > 0) {
            return true;
        }
        return false;
    };

    function getColumns() {
        const rowContext = [
            {
                content: <span>Open</span>,
                action: (row) => openFile(row.original, 'open'),
                hideContext: false,
            },
            {
                content: <span>Edit</span>,
                action: (row) => editFile(row.original, 'edit'),
                hideContext: hideDocModifyContextMenuFunctions,
            },
            {
                content: <span>Delete</span>,
                action: (row) => confirmDelete(row.original),
                hideContext: hideDocModifyContextMenuFunctions,
            },
        ];




        const createFileLink = (doc) => {
            return doc.link && !doc.external ? (
                <a className="link-link" href={doc.link} target="_blank" rel="noopener noreferrer">
                    <h4>
                        <FontAwesomeIcon icon={['fas', 'link']} />
                        {doc.filename}
                    </h4>
                </a>
            ) : (
                <h4 className="document-link" onClick={() => fetchSingleDoc(doc)}>
                    <FontAwesomeIcon
                        icon={[
                            'far',
                            fileIcons.fileTypes.filter(
                                (f) => f.ext.indexOf(doc.filename.toLowerCase().split('.').pop()) > -1
                            )[0]?.icon || fileIcons.fileTypes[0].icon,
                        ]}
                    />
                    {doc.documentTitle || doc.filename}
                </h4>
            );
        };

        const columns = [
            {
                Header: 'Date',
                accessor: 'createdAt',
                Cell: (row) => (
                    <div>{row.value ? moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss') : null}</div>
                ),
            },
            {
                Header: 'Filename',
                accessor: 'filename',
                Cell: (cellInfo) => createFileLink(cellInfo.data[cellInfo.row.index]),
            },
            // {
            //     Header: 'Component',
            //     accessor: 'itemName',
            //     Cell: cellInfo => {
            //         if (cellInfo.data[cellInfo.row.index].itemName) {
            //             return createLink(cellInfo.data[cellInfo.row.index].itemId, cellInfo.data[cellInfo.row.index].itemName,cellInfo.row)
            //         } else {
            //             return cellInfo.data[cellInfo.row.index].articleItems
            //         }
            //     }
            // },
            {
                Header: 'Uploaded by',
                accessor: 'createdByName',
            },
            // {
            //     Header: 'Type',
            //     accessor: 'documentType'
            // },
            {
                Header: () => null,
                id: 'rowcontext',
                Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
            },
        ];

        return columns;
    }

    if (fetchingDocuments) {
        return (
            <div className="documentlist-grid">
                <Spinner text="documents"></Spinner>
            </div>
        );
    }

    return (
        <div className="documentlist-grid">
            <div className="main-header">
                <span>
                    {<h2>{documents.length} Documents</h2>}
                    {canUpload() ? (
                        <>
                            <span
                                className="main-header-action"
                                style={{ paddingRight: 0 }}
                                onClick={() => openModal('file')}
                            >
                                {' '}
                                <span className="list-expand-toggle">+ Upload file</span>{' '}
                            </span>
                            <span
                                className="main-header-action"
                                style={{ padding: 0 }}
                                onClick={() => openModal('link')}
                            >
                                {' '}
                                <span className="list-expand-toggle">+ New link</span>{' '}
                            </span>
                        </>
                    ) : null}
                </span>
            </div>

            <GenericTable
                header={getHeader(documents, {})}
                columns={getColumns()}
                data={documents}
                localPaging={true}
                enableGlobalFilter={true}
                defaultPageSize={20}
            />
            <Modal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div>
                    <FileUpload
                        editItem={editItem}
                        type={linkOrFile}
                        closeModal={closeModal}
                        jobId={props.jobId}
                        fetchDocs={fetchDocumentsCallback}
                        plantId={props.plantId}
                    />
                </div>
            </Modal>
        </div>
    );
}
export default JobDocuments;
