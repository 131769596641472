import format from 'date-fns/format';
import { debounce, noop } from 'lodash';
import 'moment/locale/en-gb';
import React from 'react';
import Datetime from 'react-datetime';
import FontAwesome from 'react-fontawesome';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { withRouteMatch } from '../../../HOC';
import api from '../../../utility/api';
import './AdminProjectAddEdit.scss';
class AdminProjectsAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plants: [],
            plantTypes: [],
            nameValid: false,
            formValid: false,
            isDirty: false,
            checkedItems: [],
            createNewPlant: false,
            project: { plantId: 0 },
            optimarUsers: [],
            site: null,
            checkingProject: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.fetchPlants = this.fetchPlants.bind(this);
        this.handlePlantChange = this.handlePlantChange.bind(this);
        this.setCreateNewPlant = this.setCreateNewPlant.bind(this);
        this.handleFATDateSelect = this.handleFATDateSelect.bind(this);
        this.handleHandoverDateSelect = this.handleHandoverDateSelect.bind(this);
        this.handleWarrantyDateSelect = this.handleWarrantyDateSelect.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);

        this.checkProject = debounce((e) => {
            this.checkIfProjectExist(e);
        }, 300);
    }

    handleChange(event) {
        this.setState({
            isDirty: true,
            userAdded: false,
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const tmp = Object.assign({}, this.state.project);
        tmp[name] = value;

        this.setState({ project: tmp, site: null });
    }

    checkIfProjectExist(event) {
        this.setState({ checkingProject: true });
        api.get(process.env.REACT_APP_API + '/api/projects/check/' + event.target.value)
            .then((res) => {
                this.setState({ site: res.data, checkingProject: false });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    componentDidMount() {
        if (this.props.editProject) {
            this.setState({
                project: this.props.editProject,
                mode: 'edit',
            });
        } else if (this.props.match?.params?.plantId) {
            const tmp = Object.assign({}, this.state.project);
            tmp.plantId = this.props.match.params.plantId;
            this.setState({ project: tmp });
        }
        this.fetchPlants();
        this.fetchOptimarUsers();
    }

    componentDidUpdate() {}

    fetchPlants() {
        api.get(process.env.REACT_APP_API + '/api/admin/plants')
            .then((res) => this.setState({ plants: res.data }))
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    handleSubmit(event) {
        if (this.state.mode === 'edit') {
            this.updateSubmit(event);
        } else {
            this.createSubmit(event);
        }
    }
    createSubmit(event) {
        event.preventDefault();

        const project = {
            projectNo: this.state.project.projectNo,
            projectName: this.state.project.projectName,
            plantId: this.state.project.plantId === 0 ? null : this.state.project.plantId,
            externalDocUrl: this.state.project.externalDocUrl,
            createNewPlant: this.state.createNewPlant,
            projectManager: this.state.project.projectManager,
            factoryAcceptanceTest: this.state.project.factoryAcceptanceTest,
            customerHandover: this.state.project.customerHandover,
            endOfWarrantyPeriod: this.state.project.endOfWarrantyPeriod,
        };

        api.post(process.env.REACT_APP_API + '/api/projects', project)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Project created', 'Project successfully created', 5000);
                    this.props.cancelCallback();
                } else {
                    throw new Error(response.status);
                }
            })
            .catch(function (error) {
                NotificationManager.error('Error', error.response.data, 5000);
            });
    }

    updateSubmit(event) {
        event.preventDefault();

        const project = {
            projectNo: this.state.project.projectNo,
            projectName: this.state.project.projectName,
            plantId: this.state.project.plantId === 0 ? null : this.state.project.plantId,
            externalDocUrl: this.state.project.externalDocUrl,
            createNewPlant: this.state.createNewPlant,
            projectManager: this.state.project.projectManager,
            factoryAcceptanceTest: this.state.project.factoryAcceptanceTest,
            customerHandover: this.state.project.customerHandover,
            endOfWarrantyPeriod: this.state.project.endOfWarrantyPeriod,
        };

        api.put(process.env.REACT_APP_API + '/api/projects/' + project.projectNo, project)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Project updated', 'Project successfully updated', 5000);
                    this.props.editedCallback(response.data);
                } else {
                    NotificationManager.error('Error', 'Failed to update project', 5000);
                    // throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    handleFATDateSelect(date) {
        const tmp = this.state.project;
        tmp.factoryAcceptanceTest = date;

        this.setState({
            note: tmp,
        });
    }

    handleHandoverDateSelect(date) {
        const tmp = this.state.project;
        tmp.customerHandover = date;

        this.setState({
            note: tmp,
        });
    }

    handleWarrantyDateSelect(date) {
        const tmp = this.state.project;
        tmp.endOfWarrantyPeriod = date;

        this.setState({
            note: tmp,
        });
    }

    onSelectChanged(event) {
        const value = event.target.value;
        const name = event.target.name;

        const tmp = this.state.project;
        tmp[name] = value;

        this.setState({
            project: tmp,
        });
    }

    fetchOptimarUsers() {
        api.get(process.env.REACT_APP_API + '/api/users/optimar')
            .then((res) => {
                this.setState({ optimarUsers: res.data });
            })
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    renderButton() {
        let button;

        if (this.state.mode === 'edit') {
            button = (
                <button className="btn btn-blue btn-grow" value="Submit">
                    Save changes
                </button>
            );
        } else {
            button = (
                <button className="btn btn-blue btn-grow" value="Submit">
                    Create
                </button>
            );
        }

        return button;
    }

    handlePlantChange(event) {
        event.preventDefault();
        const tmp = this.state.project;
        tmp.plantId = event.target.value;

        this.setState({
            project: tmp,
            createNewPlant: false,
        });
    }

    setCreateNewPlant() {
        if (!this.state.createNewPlant) {
            const newState = Object.assign({}, this.state);
            newState.createNewPlant = !this.state.createNewPlant;
            newState.project.plantId = 0;
            this.setState(newState);
        } else {
            this.setState({ createNewPlant: !this.state.createNewPlant });
        }
    }

    render() {
        const plants = Object.assign([], this.state.plants);
        plants.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });

        let plantName = '';
        if (this.state.project) {
            plantName = plants.find((x) => x.id === this.state.project.plantId);
            if (plantName) {
                plantName = plantName.name;
            }
        }

        return (
            <div className="admin-project-add-edit-form">
                {this.state.mode === 'edit' ? <h1>Edit project</h1> : <h1>New project</h1>}
                <form className="add-edit-form" onSubmit={this.handleSubmit}>
                    <div>
                        <label htmlFor="name">Project number:</label>
                        <input
                            disabled={this.state.mode === 'edit'}
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="number"
                            required
                            name="projectNo"
                            value={this.state.project.projectNo || ''}
                            onChange={(e) => {
                                e.persist();
                                this.checkProject(e);
                                this.handleChange(e);
                            }}
                            onBlur={this.handleOnFocusOut}
                        />
                        {this.state.checkingProject ? (
                            <span>
                                <FontAwesome name={'spinner'} spin={true} /> Validating project number...
                            </span>
                        ) : (
                            ''
                        )}
                        {this.state.site === null ? (
                            ''
                        ) : this.state.site !== 'True' ? (
                            <span className={'project-not-exists'}>
                                Corresponding SharePoint project not found for this project number
                            </span>
                        ) : (
                            <span className={'project-exists'}>Matching project found</span>
                        )}
                    </div>

                    <div>
                        <label htmlFor="name">Project name:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            required
                            name="projectName"
                            value={this.state.project.projectName || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>

                    <div>
                        <label htmlFor="name">Project manager:</label>
                        <select
                            value={this.state.project.projectManager || ''}
                            name="projectManager"
                            onChange={this.onSelectChanged}
                        >
                            <option value="">Choose project manager</option>
                            {this.state.optimarUsers.map((usr, i) => {
                                return (
                                    <option
                                        title={usr.username}
                                        key={'usr' + i}
                                        value={usr.id}
                                        selected={usr.id === this.state.project.projectManager}
                                    >
                                        {usr.displayname || usr.username}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="name">Factory acceptance test (FAT):</label>
                        <Datetime
                            inputProps={{ readOnly: true }}
                            locale="en-gb"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            value={
                                this.state.project && this.state.project.factoryAcceptanceTest
                                    ? format(new Date(this.state.project.factoryAcceptanceTest), 'dd.MM.yyyy HH:mm')
                                    : ''
                            }
                            onChange={this.handleFATDateSelect}
                            className={'date-input-control'}
                        />
                    </div>

                    <div>
                        <label htmlFor="name">Customer handover:</label>
                        <Datetime
                            inputProps={{ readOnly: true }}
                            locale="en-gb"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            value={
                                this.state.project && this.state.project.customerHandover
                                    ? format(new Date(this.state.project.customerHandover), 'dd.MM.yyyy HH:mm')
                                    : ''
                            }
                            onChange={this.handleHandoverDateSelect}
                            className={'date-input-control'}
                        />
                    </div>

                    <div>
                        <label htmlFor="name">End of warranty period:</label>
                        <Datetime
                            inputProps={{ readOnly: true }}
                            locale="en-gb"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            value={
                                this.state.project && this.state.project.endOfWarrantyPeriod
                                    ? format(new Date(this.state.project.endOfWarrantyPeriod), 'dd.MM.yyyy HH:mm')
                                    : ''
                            }
                            onChange={this.handleWarrantyDateSelect}
                            className={'date-input-control'}
                        />
                    </div>

                    <div className="plant-chooser">
                        <label htmlFor="plant_type">Choose Plant:</label>
                        <select
                            className="admin-form-select"
                            value={this.state.project.plantId ? this.state.project.plantId : 0}
                            name="plantTypeId"
                            onChange={this.handlePlantChange}
                        >
                            {this.state.project && this.state.project.plantId && this.state.project.plantId !== 0 ? (
                                <option value={this.state.project.plantId}>{plantName}</option>
                            ) : (
                                <option value="0">Choose a plant</option>
                            )}
                            {plants.map((plant, i) => {
                                return (
                                    <option key={'plant' + i} id={i} value={plant.id}>
                                        {plant.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {!this.state.project.plantId ? (
                        <>
                            <div className="admin-input-control left-aligned">Or</div>
                            <div className="admin-input-control new-plant-checkbox">
                                <div onClick={this.setCreateNewPlant} className="faux-checkbox">
                                    <div className={this.state.createNewPlant ? 'checkbox checked' : 'checkbox'}></div>
                                    <span>Create new plant</span>
                                </div>
                            </div>
                            <span>If you create a new plant it will get the same name as the project</span>
                        </>
                    ) : null}
                    <div className="btn-row btn-row-grow">
                        <button className="btn btn-yellow btn-grow" onClick={this.props.cancelCallback}>
                            Cancel
                        </button>
                        {this.renderButton()}
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default withRouteMatch(connect(mapStateToProps)(AdminProjectsAddEdit));
