import React from 'react';
import './TankGauge.scss';
import { getTimeSince } from '../../../utility/timesince';

class TankGauge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
    }

    render() {
        const res = this.state.res || this.props.data || { data: {} };

        const minValue = this.props.configuration.minValue;
        const maxValue = this.props.configuration.maxValue;

        const decimals = this.props.configuration.decimalPlaces || 1;
        const current = res.data.value ? res.data.value.toFixed(decimals) : res.data.value;

        const totalSpan = maxValue - minValue;
        let currentCalc = current;
        if (current < 0) {
            currentCalc = (minValue - current) * -1;
        }

        let currentPercent = current !== null ? (currentCalc / totalSpan) * 100 : 0;
        if (currentPercent > 100) {
            currentPercent = 100;
        } else if (currentPercent < 0) {
            currentPercent = 0;
        }

        return (
            <div className="vertical-bar">
                <span className="time-since">{getTimeSince(res)}</span>

                <div className="component">
                    <div className="gauge-wrapper">
                        <div className="tank-gauge-wrapper">
                            <div className="tank-gauge-level" style={{ height: current + '%' }}>
                                <svg
                                    className="tank-gauge-level__waterline"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="52"
                                    height="3"
                                    viewBox="0 0 52 3"
                                >
                                    <path
                                        d="M43.3,3a12.536,12.536,0,0,1-4.722-.82A9.954,9.954,0,0,0,34.7,1.5a10.333,10.333,0,0,0-3.989.688A12.635,12.635,0,0,1,26,3a12.9,12.9,0,0,1-4.824-.825A10.068,10.068,0,0,0,17.3,1.5a9.954,9.954,0,0,0-3.878.68A12.536,12.536,0,0,1,8.7,3a12.909,12.909,0,0,1-4.824-.825A10.072,10.072,0,0,0,0,1.5V0A12.635,12.635,0,0,1,4.711.812,10.337,10.337,0,0,0,8.7,1.5,9.954,9.954,0,0,0,12.578.82,12.536,12.536,0,0,1,17.3,0a12.636,12.636,0,0,1,4.712.812A10.328,10.328,0,0,0,26,1.5,10.072,10.072,0,0,0,29.876.825,12.909,12.909,0,0,1,34.7,0a12.536,12.536,0,0,1,4.722.82A9.954,9.954,0,0,0,43.3,1.5,10.072,10.072,0,0,0,47.176.825,12.9,12.9,0,0,1,52,0V1.5a10.333,10.333,0,0,0-3.989.688A12.635,12.635,0,0,1,43.3,3Z"
                                        fill="#ececec"
                                    />
                                </svg>
                                <div className="tank-gauge-product"></div>
                            </div>
                        </div>
                    </div>

                    <div className="data-wrapper">
                        <div className="data-title">
                            <span className="data-title__label">{this.props.title}</span>
                        </div>
                        <div className="data">
                            <div className="data-value">
                                <span className="data-value__number">{current}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TankGauge.propTypes = {};

export default TankGauge;
