import React from 'react';

const item_type_name = 'Subtitle';
const configurable = true;

const Subtitle = () => {
    return {
        name: item_type_name,
        configurable: configurable,
        create_new: create_new,
        get_editor,
    };
};
export default Subtitle;

function get_editor(data, onInputChange, _props) {
    return (
        <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
                autoComplete="off"
                type="text"
                className={'input-control'}
                name="title"
                value={data.title}
                required={true}
                onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
            />
        </div>
    );
}

function create_new() {
    return {
        type: 'subtitle',
        title: 'Subtitle',
    };
}
