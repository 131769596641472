import moment from 'moment';
import React from 'react';

export default function ColumnTypeRenderer(cell) {
    switch (cell.column.type) {
        case 'datetime':
            return cell.value ? moment(cell.value).format('DD.MM.YYYY HH:mm:ss') : null;
        case 'array': {
            let arrayAsString = '';
            cell.value.forEach((value) => (arrayAsString += ', ' + (value?.name ? value?.name : '')));
            arrayAsString = arrayAsString.slice(2);
            return (
                <div className="gt-td-overflow-hidden" style={{ width: cell.column.originalWidth + 'px' }}>
                    {arrayAsString}
                </div>
            );
        }
        default:
            return cell.render('Cell');
    }
}
