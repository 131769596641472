import React from 'react';

const RightNav = () => {
    return (
        <svg
            width="33px"
            height="33px"
            viewBox="0 0 33 33"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/no-unknown-property
            xlink="http://www.w3.org/1999/xlink"
        >
            <g id="Nivå-1-–-Raffinement" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard-Copy-3" transform="translate(-642.000000, -352.000000)" fill="#41C9F0">
                    <g
                        id="Group-Copy-4"
                        transform="translate(658.500000, 368.500000) scale(-1, 1) translate(-658.500000, -368.500000) translate(643.000000, 353.000000)"
                    >
                        <path
                            d="M15.5,31.5 C6.663444,31.5 -0.5,24.336556 -0.5,15.5 C-0.5,6.663444 6.663444,-0.5 15.5,-0.5 C24.336556,-0.5 31.5,6.663444 31.5,15.5 C31.5,24.336556 24.336556,31.5 15.5,31.5 Z M15.5,30.5 C23.7842712,30.5 30.5,23.7842712 30.5,15.5 C30.5,7.21572875 23.7842712,0.5 15.5,0.5 C7.21572875,0.5 0.5,7.21572875 0.5,15.5 C0.5,23.7842712 7.21572875,30.5 15.5,30.5 Z"
                            id="Oval"
                            fillRule="nonzero"
                        ></path>
                        <polygon
                            id="Triangle-Copy-2"
                            transform="translate(14.500000, 15.500000) scale(-1, 1) rotate(-270.000000) translate(-14.500000, -15.500000) "
                            points="14.5 12 19 19 10 19"
                        ></polygon>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default RightNav;
