import moment from 'moment';
import React, { useMemo, useRef, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import { useLocation } from 'react-router-dom';
import OperationTable from './TableView/OperationTable';
import JobAddEdit from '../../components/common/JobAddEdit/JobAddEdit';
import Spinner from '../../components/common/Spinner/Spinner';
import api from '../../utility/api';
import { guidIsNullOrEmpty } from '../../utility/guidFunctions';
import { modalStyle } from '../../utility/modalstyles';
import './jobslist.scss';
import { getColumns, getHeader } from './listdefinition';
import Dashboard from '../dashboard';
import JobDocuments from './jobdocuments';
import { ReactComponent as CloseIcon } from '../../images/close.svg';
import { confirmDelete } from './listdefinition';
import JoblistProvider from './Contexts/JoblistProvider';
import SelectDestinationPlant from '../../components/common/TransferOperation/SelectDestinationPlant/SelectDestinationPlant';
import TransferOperationError from '../TransferOperationError/TransferOperationError';
import { getDataCol } from '../../components/common/GanttChart/GanttChartConfiguration';

export const TABLE_VIEW = 0;
export const GANTT_VIEW = 1;
const viewTypeDash = 0;
const viewTypeDocs = 1;

function JobsList(props) {
    /**
     * @type {React.RefObject<{ closeAllExpanded: () => void } | null>}
     */

    const genericTableFunctionsRef = useRef(null);

    const [data, setData] = React.useState();
    const [dataByParent, setDataByParent] = useState();
    const [dataByParentAferFilter, setdataByParentAferFilter] = useState([]);
    const [modalOpen, setModalState] = React.useState(false);
    const [editType, setEditType] = React.useState('edit');
    const [currentItem, setCurrentItem] = React.useState(null);
    const [fetchingItems, setFetching] = React.useState(null);
    const [showTopLevelOnly, setTopLevelFilter] = React.useState(true);
    const [parent, setParentView] = React.useState(null);
    const [jobTypes, setJobTypes] = React.useState(null);
    const [dashboardOpen, setDashboardOpen] = useState(false);
    const [dashboardRow, setDashboardRow] = useState(undefined);
    const [sideDashboardViewType, setSideDashboardViewType] = useState(viewTypeDash);
    const [view, setView] = useState(TABLE_VIEW);

    const [transferOperationModalOpen, setTransferOperationModalOpen] = React.useState(false);
    const [transferOperationId, setTransferOperationId] = React.useState(null);
    const [transferOperationPlantId, setTransferOperationPlantId] = React.useState(null);
    const [redirectTransferOperationError, setRedirectToTransferOperationError] = React.useState(false);
    const [transferOperationErrorInfo, setTransferOperationErrorInfo] = React.useState(null);

    const [dateRange, setDateRange] = React.useState([
        new Date(moment().subtract(1, 'months')),
        new Date(new Date(moment().add(1, 'months'))),
    ]);

    const modalRef = React.useRef();

    const fetchJobs = React.useCallback(async () => {
        const [startDate, endDate] = dateRange;

        if (!startDate || !endDate) {
            return;
        }

        setFetching(true);
        const url = `${process.env.REACT_APP_API}/api/jobs/getlist`;

        try {
            const res = await api.post(url, {
                PlantIds: [props.plantId],
                StartDate: startDate,
                EndDate: endDate,
                ItemId: props.itemId,
            });

            for (const item of res.data.jobs) {
                if (item.parentJobId === '00000000-0000-0000-0000-000000000000') {
                    item.parentJobId = null;
                }
            }

            setData(res.data.jobs);
            setDataByParent(res.data.jobs);

            setFetching(false);
        } catch (error) {
            console.log(error);
            NotificationManager.error('Failed to get operations', 'Error getting operations', 5000);
            setFetching(false);
        }
    }, [props.itemId, props.plantId, dateRange]);

    const location = useLocation();

    React.useEffect(() => {
        if (!fetchingItems) {
            fetchJobs();
        }
    }, [props.itemId, fetchJobs, dateRange, location]);

    const fetchJobTypes = React.useCallback(async () => {
        const url = `${process.env.REACT_APP_API}/api/jobs/types/${props.plantId}`;

        const res = await api.get(url);
        const jobtypes = res.data.map((j) => {
            return {
                value: j.id,
                label: j.name,
                configuration: j.configuration,
                reportConfigId: j.reportConfigId,
            };
        });
        setJobTypes(jobtypes);
    }, [props.plantId]);

    // Intial data load
    React.useEffect(() => {
        if (!jobTypes) {
            fetchJobTypes();
        }
    }, [fetchJobTypes, jobTypes]);

    useEffect(() => {
        if (dataByParent) {
            let data = checkParent(dataByParent);
            const nestedData = nestData(data);
            setdataByParentAferFilter(nestedData);
        }

        //check parent, change to null if does not exist
        function checkParent(data) {
            for (const item of data) {
                if (!data.some((i) => i.id === item.parentJobId) && item.parentJobId !== null) {
                    item.parentJobId = null;
                    item.isOrphan = true;
                } else {
                    item.isOrphan = false;
                }
            }
            return data;
        }
        //nest the element based on parentJobId
        function nestData(data, parentJobId = null, depth = 0) {
            return data
                .filter((item) => item.parentJobId === parentJobId)
                .map((item) => ({
                    ...item,
                    depth,
                    // children: nestData(data, item.id, depth + 1),
                    subRows: nestData(data, item.id, depth + 1),
                }));
        }
    }, [dataByParent]);

    useEffect(() => {
        console.log('sideDash changes');
    }, [sideDashboardViewType]);

    const deleteJob = React.useCallback(
        (item) => {
            const url = `${process.env.REACT_APP_API}/api/jobs/${item.id}`;
            api.delete(url).then((res) => {
                if (res.status === 200) {
                    fetchJobs();
                    NotificationManager.success('Job deleted successfully', 'Job deleted', 5000);
                } else {
                    NotificationManager.success('Job not deleted successfully', 'Job not deleted', 5000);
                }
            });
        },
        [fetchJobs]
    );

    /**
     * Finds the all jobs that relates to the start id (parentJobId). This relation can be directly or jobs that is
     * chained. Trip<- <-Location<-cage<-throw <-Delivery
     *
     * @param {string} parentId Start id of the chain
     * @param {array} chainArray Array of jobs
     *
     * @returns Returns object chain of all jobs related to start id
     */
    const getJobChain = (parentId, chainArray) => {
        function findTheChain(baseId, data = [], acc = {}) {
            for (let index = data.length - 1; index >= 0; index--) {
                const node = data[index];
                if (node && node.parentJobId === baseId) {
                    data.splice(index, 1);
                    acc[node.id] = {
                        node,
                        children: findTheChain(node.id, data, {}),
                    };
                }
            }
            return acc;
        }
        return findTheChain(parentId, chainArray);
    };

    /**
     * Finds the last trip for before the current. It returns the found trip or undefined of not found
     *
     * @param {object} currentTrip The object of the trip
     * @param {array} allJobs Array of all jobs
     *
     * @returns The last trip object or undefined
     */
    const findLastTrip = (currentTrip, allJobs) => {
        const currentTripStartTime = moment.utc(currentTrip.startDate).unix();
        const lastTrip = allJobs
            .filter((job) => {
                const isTypeTrip = job.type.toLowerCase().includes('trip');
                const isNotCurrentJob = currentTrip.id !== job.id;
                const isBeforeThisTrip = moment.utc(job.startDate).unix() < currentTripStartTime;
                return isTypeTrip && isNotCurrentJob && isBeforeThisTrip;
            })
            .sort((trip1, trip2) => {
                // Sort for newest first
                return moment.utc(trip2).unix() - moment.utc(trip1).unix();
            });
        return lastTrip[0];
    };

    const generateJobReport = async (item) => {
        NotificationManager.info('Generating and downloading report. Please wait.', 'Generating', 5000);
        const completeCallback = (isError, error) => {
            if (isError) {
                if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
                    NotificationManager.error('User not allowed to generate report', 'Report not generated', 5000);
                    return;
                }
                NotificationManager.error('Report not generated successfully', 'Report not generated', 5000);
            } else {
                NotificationManager.success('Report generated successfully', 'Report generated', 5000);
            }
        };
        try {
            const jobs = getJobChain(item.id, Object.assign([], data));
            const reportConfigId = jobTypes.find((x) => item.jobTypeId === x.value).reportConfigId;

            const lastTripJob = findLastTrip(item, data);

            let lastTrip;
            if (lastTripJob) {
                lastTrip = { trip: lastTripJob, jobs };
                lastTrip.jobs = lastTrip ? getJobChain(lastTripJob.id, data) : undefined;
            }

            const fetchedJobData = api.post(`/api/edgeReports/job/${item.id}`);
            const fetchedReportConfig = api.get(`/api/reportconfig/${reportConfigId}`);
            const modulePdfGenerator = import('../../utility/pdfGenerator');
            const promises = await Promise.all([fetchedJobData, modulePdfGenerator, fetchedReportConfig]);
            const [response, pdfGenerator, reportConfigResponse] = promises;

            const reportData = JSON.parse(response.data.report.report).Data;
            const reportConfig = JSON.parse(reportConfigResponse.data.config);
            const currentTrip = { trip: Object.assign({}, item), jobs };
            const mergedData = {
                reportData,
                currentTrip,
                lastTrip,
                reportConfig,
            };

            pdfGenerator.generatePdfFromType(item.type, mergedData, completeCallback);
        } catch (error) {
            completeCallback(true, error);
            console.error(error);
        }
    };
    const toggleDashboard = (row, type) => {
        // console.log('🚀 ~ file: joblist.js:249 ~ toggleDashboard ~ row:', row);
        setDashboardOpen(!dashboardOpen);
        setSideDashboardViewType(viewTypeDash);
        if (row) setDashboardRow(row);
    };

    const toggleGanttView = () => {
        setView(GANTT_VIEW);
    };
    const toggleTableView = () => {
        setView(TABLE_VIEW);
    };
    const toggleModal = (item, type) => {
        setEditType(type);
        setCurrentItem(item);
        setModalState(!modalOpen);
    };

    const toggleTopLevel = () => {
        setTopLevelFilter(!showTopLevelOnly);
    };

    const findParent = (arr, item) => {
        const currentItem = { ...item };
        currentItem.parent = arr
            .filter((x) => item.parentJobId === x.id)
            .sort((a, b) => a.id - b.id)
            .map((y) => findParent(arr, y));

        return currentItem;
    };

    const toggleParentView = (parent, header) => {
        if (showTopLevelOnly) {
            setTopLevelFilter(false);
        }
        if (header) {
            const currentHeaderNode = findParent(data, parent);
            setParentView(currentHeaderNode);
        } else {
            setParentView(parent);
        }
        genericTableFunctionsRef.current.closeAllExpanded();
    };

    const transferOperation = async (job) => {
        setTransferOperationId(job.id);
        setTransferOperationPlantId(job.plantId);
        setTransferOperationModalOpen(true);
    };

    const clearFilter = () => {
        if (!showTopLevelOnly) {
            setTopLevelFilter(true);
        }
        setParentView(null);
        genericTableFunctionsRef.current.closeAllExpanded();
    };

    const getChilds = (current, data) => {
        const dataToDisplay = [];
        data.forEach((node) => {
            if (current && node.parentJobId === current.id) {
                dataToDisplay.push(node);
            }
        });
        return dataToDisplay;
    };

    const columns = getColumns(props, dataByParentAferFilter, {
        toggleDashboard,
        toggleModal,
        deleteJob,
        generateJobReport,
        toggleParentView,
        transferOperation,
        jobTypes,
    });

    const dataToDisplay = useMemo(() => {
        let dataToDisplay = dataByParentAferFilter;
        if (showTopLevelOnly && dataByParentAferFilter !== undefined) {
            dataToDisplay = dataByParentAferFilter.filter((f) => guidIsNullOrEmpty(f.parentJobId)); //!f.parentJobId );
        }

        if (parent) {
            return getChilds(parent, dataToDisplay);
        }
        return dataToDisplay;
    }, [dataByParentAferFilter, parent, showTopLevelOnly]);

    if (!jobTypes || data === undefined) {
        return <Spinner text="jobs" />;
    }

    const getSideDashboard = () => {
        const data = getDataCol(dashboardRow);
        return (
            <div>
                <div className="side-panel-header">
                    <nav className="viewslist">
                        <div className="viewlist-content" onClick={() => setSideDashboardViewType(viewTypeDash)}>
                            <span className={sideDashboardViewType === viewTypeDash ? 'view-active' : 'view-inactive'}>
                                Dashboard
                            </span>
                        </div>

                        <div className="viewlist-content" onClick={() => setSideDashboardViewType(viewTypeDocs)}>
                            <span className={sideDashboardViewType === viewTypeDocs ? 'view-active' : 'view-inactive'}>
                                Documents
                            </span>
                        </div>
                    </nav>

                    <CloseIcon
                        className="side-panel-close-btn"
                        onClick={() => {
                            toggleDashboard(null);
                        }}
                    />
                </div>
                <div className="viewslist">
                    <div className="viewlist-title">
                        <span className="title">Type:</span>
                        <span>{dashboardRow.type}</span>
                    </div>

                    <div className="viewlist-title">
                        <span className="title">Data:</span>
                        <span className="data">{data}</span>
                    </div>
                </div>
                <div className="side-panel-content">
                    {sideDashboardViewType === viewTypeDash ? (
                        <Dashboard
                            dashboardType="job"
                            jobTypeId={dashboardRow.jobTypeId}
                            job={{
                                startDate: dashboardRow.startDate ? moment.utc(dashboardRow.startDate) : new Date(),
                                endDate: dashboardRow.endDate ? moment.utc(dashboardRow.endDate) : new Date(),
                            }}
                            match={{ params: { plantId: dashboardRow.plantId } }}
                            currentItem={{ topic: dashboardRow.topic }}
                        />
                    ) : null}
                    {sideDashboardViewType === viewTypeDocs ? (
                        <div style={{ paddingTop: '20px' }}>
                            <JobDocuments plantId={dashboardRow.plantId} jobId={dashboardRow.id} />
                        </div>
                    ) : null}
                </div>
                <div className="side-panel-footer">
                    <div
                        className="btn btn-blue"
                        onClick={() => {
                            toggleModal(dashboardRow, 'child');
                        }}
                    >
                        Add child operation
                    </div>
                    <div
                        className="btn btn-blue"
                        onClick={() => {
                            toggleModal(dashboardRow, 'edit');
                        }}
                    >
                        Edit operation
                    </div>
                    <div
                        className="btn btn-blue"
                        onClick={() => {
                            confirmDelete(dashboardRow, deleteJob);
                            toggleDashboard(null);
                        }}
                    >
                        Delete operation
                    </div>
                    {jobTypes.find((x) => dashboardRow.jobTypeId === x.value)?.reportConfigId ? (
                        <div
                            className="btn btn-blue"
                            onClick={() => {
                                generateJobReport(dashboardRow);
                            }}
                        >
                            Download Report
                        </div>
                    ) : null}
                    <div
                        className="btn btn-blue"
                        onClick={() => {
                            transferOperation(dashboardRow);
                            toggleDashboard(null);
                        }}
                    >
                        Transfer
                    </div>
                </div>
            </div>
        );
    };

    const redirectToTransferOperationError = (ErrorInfo) => {
        setTransferOperationErrorInfo(ErrorInfo);
        setRedirectToTransferOperationError(true);
    };

    const redirectBacktoOperationsPlanner = () => {
        fetchJobs();
        setRedirectToTransferOperationError(false);
    };

    if (redirectTransferOperationError) {
        return (
            <TransferOperationError
                info={transferOperationErrorInfo}
                closeTransferOperations={redirectBacktoOperationsPlanner}
            ></TransferOperationError>
        );
    }

    return (
        <div className="jobslist">
            <JoblistProvider>
                <OperationTable
                    ref={genericTableFunctionsRef}
                    enableGlobalFilter={true}
                    localPaging={true}
                    columns={columns}
                    data={dataToDisplay}
                    onDateChange={setDateRange}
                    dateRange={dateRange}
                    header={getHeader(data, showTopLevelOnly, parent, view, {
                        toggleModal,
                        toggleTopLevel,
                        clearFilter,
                        toggleParentView,
                        toggleGanttView,
                        toggleTableView,
                    })}
                    toggleDashboard={toggleDashboard}
                    // expandedRowContent={
                    //     (row) => {
                    //         console.log("🚀 ~ file: joblist.js:460 ~ JobsList ~ row", row);
                    //         return getChildItems(row.original);
                    //     }
                    //     // getDashboard(row, setViewType, () => {
                    //     //     return viewType;
                    //     // })
                    // }
                    viewType={view}
                    fetchJobs={fetchJobs}
                    enabledDatepicker={true}
                    loading={fetchingItems}
                    autoResetExpanded={false}
                    expandedRowContentEnabled={false}
                    enableChildFilter={true}
                />
                <Modal
                    ref={modalRef}
                    isOpen={modalOpen}
                    style={modalStyle}
                    onRequestClose={() => {
                        // Fetch jobs when modal is closed to keep in sync
                        fetchJobs();
                        toggleModal();
                    }}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <JobAddEdit
                        modalRef={modalRef}
                        editItem={currentItem}
                        editType={editType}
                        plantId={props.plantId}
                        toggleModal={() => {
                            fetchJobs();
                            toggleModal(null);
                        }}
                    />
                </Modal>
                {dashboardRow ? (
                    <Modal
                        className="right-panel-modal"
                        overlayClassName="right-panel-modal-overlay"
                        // style={rightModalStyle}
                        isOpen={dashboardOpen}
                        onRequestClose={() => {
                            toggleDashboard();
                        }}
                        ariaHideApp={false}
                    >
                        {getSideDashboard()}

                        <Modal
                            ref={modalRef}
                            isOpen={modalOpen && dashboardOpen}
                            style={modalStyle}
                            onRequestClose={() => {
                                // Fetch jobs when modal is closed to keep in sync
                                fetchJobs();
                                toggleModal();
                            }}
                            ariaHideApp={false}
                            shouldCloseOnOverlayClick={false}
                        >
                            <JobAddEdit
                                modalRef={modalRef}
                                editItem={currentItem}
                                editType={editType}
                                plantId={props.plantId}
                                toggleModal={() => {
                                    fetchJobs();
                                    toggleModal(null);
                                }}
                            />
                        </Modal>
                    </Modal>
                ) : null}
                {transferOperationModalOpen && (
                    <SelectDestinationPlant
                        modalRef={modalRef}
                        modalOpen={transferOperationModalOpen}
                        fetchJobs={fetchJobs}
                        onToggleModal={setTransferOperationModalOpen}
                        redirect={redirectToTransferOperationError}
                        jobId={transferOperationId}
                        jobPlantId={transferOperationPlantId}
                    ></SelectDestinationPlant>
                )}
            </JoblistProvider>
        </div>
    );
}

export default JobsList;
