import React from 'react';
import { NotificationManager } from 'react-notifications';
import Ganttchart from "./ganttview/ganttchart";
import Spinner from '../../components/common/Spinner/Spinner';
import { GlobalFilter } from '../../components/common/GenericTable/Filters';
import api from '../../utility/api';
import AccountOperationViews from './accountoperationviews';
import JobList from './jobslist/joblist';
import moment from 'moment';
import AccountJobHeader from './header/accountjobheader';
import { ViewMode } from "gantt-task-react";
import { manipulateData, restoreNullEndDateValue } from "./ganttview/helper";
import JobDetails from './jobdetails/jobdetails';
import SelectDestinationPlant from '../../components/common/TransferOperation/SelectDestinationPlant/SelectDestinationPlant';
import TransferOperationError from '../TransferOperationError/TransferOperationError';

function AccountJobs() {
    const [data, setData] = React.useState();
    const [accountOperationView, setAccountOperationView] = React.useState(AccountOperationViews.ListView);
    const [fetchingItems, setFetching] = React.useState(null);
    const [jobTypes, setJobTypes] = React.useState(null);
    const [globalFilterValue, setGlobalFilterValue] = React.useState("");
    const [dateRange, setDateRange] = React.useState([new Date(moment().subtract(1, 'months')), new Date(new Date(moment().add(1, 'months')))]);
    const [plantSelectedOptions, setPlantSelectedOptions] = React.useState([]);
    const [plantOptions, setPlantOptions] = React.useState([]);
    const [plantOptionsRawData, setPlantOptionsRawData] = React.useState([]);    
    const [numberOfPlants, setNumberOfPlants] = React.useState(0);     
    const [ganttTimeView, setGanttTimeView] = React.useState(ViewMode.Day);
    const [operationChanges, setOperationChanges] = React.useState([]); 
    const [disabledSaveChanges, setDisabledSaveChanges] = React.useState(true); 
    const [savingState, setSavingState] = React.useState(false);

    const [jobDetailsOpen, setJobDetailsOpen] = React.useState(false);
    const [jobDetailsRow, setJobDetailsRow] = React.useState(undefined);

    const [transferOperationModalOpen, setTransferOperationModalOpen] = React.useState(false);
    const [transferOperationId, setTransferOperationId] = React.useState(null);
    const [transferOperationPlantId, setTransferOperationPlantId] = React.useState(null);
    const [redirectTransferOperationError, setRedirectToTransferOperationError] = React.useState(false);
    const [transferOperationErrorInfo, setTransferOperationErrorInfo] = React.useState(null);
    
    const modalRef = React.useRef();
    
    React.useEffect(() => {
        if(operationChanges.length === 0)
        {
            setDisabledSaveChanges(true);
            return;
        }

        setDisabledSaveChanges(false);
        
    }, [operationChanges]);

    function clearOperationChanges() {
        setOperationChanges([]);
        fetchJobs();
    }

    async function saveOperationChanges(operations) {
        setFetching(true);
        const url = `${process.env.REACT_APP_API}/api/jobs/updatedaterangejobs`;

        try
        {
            const res = await api.post(url, operations);

            if (res.status) {
                setOperationChanges([]);

                if (operations.length === res.data.jobs.length){
                    setTimeout(() => {
                        fetchJobs();
                    }, "500");

                    return;
                } 

                NotificationManager.error('Fail to update one or more operation(s)', 'Error update one or more operation(s)', 5000);

                setFetching(false); 
            }
        }
        catch(error) 
        {
            let errorMessage = 'Failed to update operations';

            if (error?.response?.data?.errorMessage) {
                errorMessage = error.response.data.errorMessage;
            }
            
            NotificationManager.error(errorMessage, 'Error update operations', 5000);
            setFetching(false); 
        }
    }

    const fetchJobs = React.useCallback(async () => {
        const [startDate, endDate] = dateRange;

        if (!startDate || !endDate)
        {
            return;
        }

        setFetching(true);
        const url = `${process.env.REACT_APP_API}/api/jobs/getlist`;
        
        try
        {
            const res = await api.post(url, {
                "PlantIds" : [],
                "StartDate" : startDate,
                "EndDate" : endDate
            });
            
            const plants = res.data.plants;
            const jobs = res.data.jobs;
            
            setData(manipulateData(jobs));
            setOperationChanges([]);

            const plantOptionsResult = (plants || []).map(item => {
                return {
                    label : item.name,
                    value : item.id
                };
            });
            
            setPlantOptions(plantOptionsResult);
            setPlantSelectedOptions(plantOptionsResult);
            setPlantOptionsRawData(plants);

            setFetching(false);
        }
        catch(error) 
        {
            console.log(error);
            NotificationManager.error('Failed to get operations', 'Error getting operations', 5000);
            setFetching(false); 
        }
    }, [dateRange]);

    const deleteJob = React.useCallback(
        (item) => {
            const url = `${process.env.REACT_APP_API}/api/jobs/${item.id}`;
            api.delete(url).then((res) => {
                if (res.status === 200) {
                    fetchJobs();
                    NotificationManager.success('Job deleted successfully', 'Job deleted', 5000);
                } else {
                    NotificationManager.success('Job not deleted successfully', 'Job not deleted', 5000);
                }
            });
        },
        [fetchJobs]
    );

    const fetchJobTypes = React.useCallback(async () => {
        setFetching(true);   
        const url = `${process.env.REACT_APP_API}/api/jobs/types`;
        
        try
        {           
            const plants = (plantOptions || []).map(item => item.value);

            const res = await api.post(url, { PlantIds : plants });
            const jobtypes = res.data.map((j) => {
                return {
                    value: j.id,
                    label: j.name,
                    configuration: j.configuration,
                    reportConfigId: j.reportConfigId,
                };
            });
            setJobTypes(jobtypes);
            setFetching(false);  
        }
        catch(error) 
        {
            setFetching(false); 
        }
    }, [plantOptions]);

    React.useEffect(() => {
        if (!fetchingItems) {
            fetchJobs();  
        }      
    }, [dateRange]);

    React.useEffect(() => {
        if (plantOptions && plantOptions.length > 0)
        {
            fetchJobTypes();
        }        
    }, [plantOptions]);

    const handleOperationClick = (task) => {
        toggleJobDetails({
            "data": task.data,            
            "startDate": task.start,
            "endDate": task.end,
            "parentJobId": task.parentJobId,
            "topic": task.topic,
            "type": task.name,
            "jobTypeId": task.jobTypeId,
            "plantId": task.plantId,
            "relationship": task.relationship,
            "id": task.id
        });
    };

    const toggleJobDetails = (job) => {
        setJobDetailsOpen(!jobDetailsOpen);
        if (job) setJobDetailsRow(job);
    };

    const handleSaveChanges = async () => {
        setSavingState(true);
        let operations = restoreNullEndDateValue(operationChanges);
        
        operations = (operations || []).map(i => { return { id : i.id , startdate : i.start, enddate : i.end };  });

        saveOperationChanges(operations);

        setSavingState(false);
    };

    const handleOperationChanges = (tasks) => {
        let listOperationChanges = [...operationChanges];

        tasks.forEach((task) => {
            let operationChange = {
                id : task.id,
                name : task.name,
                type : task.type,
                project : task.project,
                start : task.start,
                end : task.end,
                originalEndDate: task.originalEndDate,
                originalStartDate : task.originalStartDate
            };
    
            const operationIndex = operationChanges.findIndex(item => item.id === task.id);                    
    
            if (operationIndex === -1)
            {
                listOperationChanges.push(operationChange);
            } else {
                listOperationChanges[operationIndex] = operationChange;
            }
        });        

        setOperationChanges(listOperationChanges);
    };

    const generateJobReport = async (item) => {
        const getJobChain = (parentId, chainArray) => {
            function findTheChain(baseId, data = [], acc = {}) {
                for (let index = data.length - 1; index >= 0; index--) {
                    const node = data[index];
                    if (node && node.parentJobId === baseId) {
                        data.splice(index, 1);
                        acc[node.id] = {
                            node,
                            children: findTheChain(node.id, data, {}),
                        };
                    }
                }
                return acc;
            }
            return findTheChain(parentId, chainArray);
        };

        const findLastTrip = (currentTrip, allJobs) => {
            const currentTripStartTime = moment.utc(currentTrip.startDate).unix();
            const lastTrip = allJobs
                .filter((job) => {
                    const isTypeTrip = job.type.toLowerCase().includes('trip');
                    const isNotCurrentJob = currentTrip.id !== job.id;
                    const isBeforeThisTrip = moment.utc(job.startDate).unix() < currentTripStartTime;
                    return isTypeTrip && isNotCurrentJob && isBeforeThisTrip;
                })
                .sort((trip1, trip2) => {
                    // Sort for newest first
                    return moment.utc(trip2).unix() - moment.utc(trip1).unix();
                });
            return lastTrip[0];
        };
        
        NotificationManager.info('Generating and downloading report. Please wait.', 'Generating', 5000);
        const completeCallback = (isError, error) => {
            if (isError) {
                if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
                    NotificationManager.error('User not allowed to generate report', 'Report not generated', 5000);
                    return;
                }
                NotificationManager.error('Report not generated successfully', 'Report not generated', 5000);
            } else {
                NotificationManager.success('Report generated successfully', 'Report generated', 5000);
            }
        };
        try {
            const jobs = getJobChain(item.id, Object.assign([], data));
            const reportConfigId = jobTypes.find((x) => item.jobTypeId === x.value).reportConfigId;

            const lastTripJob = findLastTrip(item, data);

            let lastTrip;
            if (lastTripJob) {
                lastTrip = { trip: lastTripJob, jobs };
                lastTrip.jobs = lastTrip ? getJobChain(lastTripJob.id, data) : undefined;
            }

            const fetchedJobData = api.post(`/api/edgeReports/job/${item.id}`);
            const fetchedReportConfig = api.get(`/api/reportconfig/${reportConfigId}`);
            const modulePdfGenerator = import('../../utility/pdfGenerator');
            const promises = await Promise.all([fetchedJobData, modulePdfGenerator, fetchedReportConfig]);
            const [response, pdfGenerator, reportConfigResponse] = promises;

            const reportData = JSON.parse(response.data.report.report).Data;
            const reportConfig = JSON.parse(reportConfigResponse.data.config);
            const currentTrip = { trip: Object.assign({}, item), jobs };
            const mergedData = {
                reportData,
                currentTrip,
                lastTrip,
                reportConfig,
            };

            pdfGenerator.generatePdfFromType(item.type, mergedData, completeCallback);
        } catch (error) {
            completeCallback(true, error);
            console.error(error);
        }
    };

    const transferOperation = async (job) => {
        setTransferOperationId(job.id);
        setTransferOperationPlantId(job.plantId);        
        setTransferOperationModalOpen(true);
    };

    const redirectToTransferOperationError = (ErrorInfo) => {
        setTransferOperationErrorInfo(ErrorInfo);
        setRedirectToTransferOperationError(true);
    };

    const redirectBacktoOperationsPlanner = () => {
        fetchJobs();
        setRedirectToTransferOperationError(false);       
    };

    let jobDetailsPlant = null;

    if (jobDetailsRow && jobDetailsRow.plantId)
    {
        jobDetailsPlant = (plantOptionsRawData || []).find(p => p.id === jobDetailsRow.plantId);
    }

    if (redirectTransferOperationError) {
        return <TransferOperationError
                info={transferOperationErrorInfo}
                closeTransferOperations={redirectBacktoOperationsPlanner}
            ></TransferOperationError>;  
    }

    if (fetchingItems) {
        return <Spinner text="operations" />;
    }

    return (
        <div className="accountjobs">       
            <AccountJobHeader
                accountOperationView={accountOperationView}
                fetchJobs={fetchJobs}
                numberOfPlants = {numberOfPlants}
                ganttView={ganttTimeView}
                onGanttViewModeChange={setGanttTimeView} 
                onDateRangeChange={setDateRange}
                dateRange={dateRange}                
                onViewSwitcherClick={setAccountOperationView} 
                plantOptions={plantOptions  || []}
                plantSelectedOptions={plantSelectedOptions}
                onPlantSelectedOptionsChange={setPlantSelectedOptions}
                onClearOperationChanges={clearOperationChanges}
                onSaveChanges={handleSaveChanges}
                disabledSaveChanges={disabledSaveChanges}
                savingState={savingState}>                            
            </AccountJobHeader>
            <div className="gt-header">
                <GlobalFilter
                    globalFilter={globalFilterValue}
                    setGlobalFilter={setGlobalFilterValue}
                />
            </div>            
            {accountOperationView === AccountOperationViews.GanttChart ?
                <Ganttchart
                    onResetOperationChanges={() => setOperationChanges([])} 
                    plantSelectedOptions={plantSelectedOptions}
                    timeviewMode={ganttTimeView}
                    data={data}
                    globalFilterValue={globalFilterValue}
                    onNumberOfPlantsChange={setNumberOfPlants}
                    onTasksChange={handleOperationChanges}
                    onTaskClick={handleOperationClick}>
                </Ganttchart> : 
                <JobList 
                    data={data}
                    globalFilterValue={globalFilterValue}
                    plantSelectedOptions={plantSelectedOptions}
                    onToggleJobDetails={toggleJobDetails}
                    onNumberOfPlantsChange={setNumberOfPlants}
                    onDeleteJob={deleteJob}
                    fetchJobs={fetchJobs}
                    jobTypes={jobTypes}
                    onGenerateJobReport={generateJobReport}
                    onTransferOperation={transferOperation}
                    onResetOperationChanges={() => setOperationChanges([])}
                    modalRef={modalRef}>
                </JobList>
            }
            {jobDetailsPlant && <JobDetails
                modalRef={modalRef}
                setJobDetailsOpen={setJobDetailsOpen}
                toggleJobDetails={toggleJobDetails}
                onTransferOperation={transferOperation}
                jobDetailsOpen={jobDetailsOpen}
                fetchJobs={fetchJobs}
                data={data}
                jobTypes={jobTypes}
                jobDetailsRow={jobDetailsRow}
                plant={jobDetailsPlant}>
            </JobDetails>}            
            {transferOperationModalOpen && <SelectDestinationPlant
                modalRef={modalRef}
                modalOpen={transferOperationModalOpen}
                fetchJobs={fetchJobs}
                onToggleModal={setTransferOperationModalOpen}
                redirect={redirectToTransferOperationError}
                jobId={transferOperationId}
                jobPlantId={transferOperationPlantId}
            >
            </SelectDestinationPlant>} 
        </div>
    );
}

export default AccountJobs;
