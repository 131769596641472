import React from 'react';
import { connect } from 'react-redux';
import { setCurrentPlant } from '../../../actions';
import SubscribeButton from '../SubscribeButton/SubscribeButton';

import { Redirect } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

import PLANT_TYPE from '../../../constants';

import { ReactComponent as Map_marker_vessel_active } from '../../Maps/icons/vessel_green.svg';
import { ReactComponent as Map_marker_vessel } from '../../Maps/icons/vessel_blue.svg';
import { ReactComponent as Map_marker_land_plant_active } from '../../Maps/icons/plant_green.svg';
import { ReactComponent as Map_marker_land_plant } from '../../Maps/icons/plant_blue.svg';
import { ReactComponent as Map_marker_farm_green } from '../../Maps/icons/farm_green.svg';
import { ReactComponent as Map_marker_farm } from '../../Maps/icons/farm_blue.svg';

import Location from '../../../images/list/Location.svg';
import UploadUploading from '../../../images/list/Upload_Connected-Uploading.svg';
import UploadIdle from '../../../images/list/Upload_Idle.svg';
import UploadNA from '../../../images/list/Upload_Not-Available.svg';

import PositionUploading from '../../../images/list/Location_Connected-Uploading.svg';
import PositionError from '../../../images/list/Location_Error.svg';
import PositionIdle from '../../../images/list/Location_Idle.svg';
import PositionNA from '../../../images/list/Location_Not-Available.svg';
import Position from '../../../images/list/Location.svg';

import Spinner from '../Spinner/Spinner';
import Map from '../../../images/system/map.svg';

import { Link } from 'react-router-dom';

import * as moment from 'moment';

import './PlantTable.css';

class PlantTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            redirectTo: null,
        };

        this.handleSetCurrentPlant = this.handleSetCurrentPlant.bind(this);
        this.search = this.search.bind(this);
        this.filterPlants = this.filterPlants.bind(this);
        this.goToExternal = this.goToExternal.bind(this);
        this.handleEnterPress = this.handleEnterPress.bind(this);
    }

    componentDidMount() {
        if (this.props.plants) this.refs['plant-search'].focus();
    }

    componentDidUpdate() {
        if (this.props.plants) this.refs['plant-search'].focus();
    }

    setUploadImage(plant) {
        const timeDiff = moment().valueOf() - moment.utc(plant.lastSampleDate).local().valueOf();
        let image = UploadNA;
        let text = 'Not Available';

        if (timeDiff <= 60 * 60 * 1000) {
            // 1 hour
            image = UploadUploading;
            text = 'Uploading';
        } else if (timeDiff >= 12 * 60 * 60 * 1000) {
            image = UploadIdle;
            text = 'Idle / Standby';
        } else if (!plant.lastSampleDate) {
            image = UploadNA;
            text = 'Not Available';
        }

        return (
            <span className="cell-image-text">
                <img alt="Uploading" style={{ marginRight: '0.5em' }} src={image} />
                <span className={plant.lastSampleDate ? '' : 'cell-text-grey'}>
                    {text} <br />
                    {plant.lastSampleDate ? (
                        <span className="cell-metadata">{moment.utc(plant.lastSampleDate).local().fromNow()}</span>
                    ) : (
                        ''
                    )}
                </span>
            </span>
        );
    }

    setPositionImage(plant) {
        const timeDiff = moment().valueOf() - moment.utc(plant.loggedDate).local().valueOf();
        let image = PositionNA;
        let text = '';

        if (plant.address) {
            text = plant.address;

            return (
                <span className="cell-image-text">
                    <img style={{ marginRight: '0.5em' }} alt="Position Icon" src={Location} />
                    <span className={plant.latitude ? 'cell-text' : 'cell-text-grey'}> {text}</span>
                </span>
            );
        } else if (plant.latitude) {
            text = plant.latitude.toFixed(2) + '°N ' + plant.longitude.toFixed(2) + '°W';
        } else {
            text = 'Not Available';
        }

        if (plant.plantTypeName !== 'Factory') {
            if (timeDiff <= 60 * 60 * 1000) {
                // 1 hour
                image = PositionUploading;
            } else if (plant.loggedDate) {
                image = PositionIdle;
            } else {
                image = PositionError;
            }
        } else {
            image = Position;
        }

        return (
            <span className="cell-image-text">
                <img style={{ marginRight: '0.5em' }} alt="Position Icon" src={image} />
                <span className={plant.latitude ? 'cell-text' : 'cell-text-grey'}>
                    {' '}
                    {text}
                    <br />
                    {plant.plantTypeName !== 'Factory' ? (
                        <span className="cell-metadata">{moment.utc(plant.loggedDate).local().fromNow()}</span>
                    ) : null}
                </span>
            </span>
        );
    }

    setMarkerType(plant) {
        const timeDiff = moment().valueOf() - moment.utc(plant.lastSampleDate).local().valueOf();
        let MarkerType = '';

        if (plant.plantTypeName === PLANT_TYPE.sea) {
            MarkerType = Map_marker_vessel_active;
        } else if (plant.plantTypeName === PLANT_TYPE.fishFarm) {
            MarkerType = Map_marker_farm_green;
        } else {
            MarkerType = Map_marker_land_plant_active;
        }
        if (timeDiff >= 60 * 60 * 1000 || !plant.lastSampleDate) {
            if (plant.plantTypeName === PLANT_TYPE.sea) {
                MarkerType = Map_marker_vessel;
            } else if (plant.plantTypeName === PLANT_TYPE.fishFarm) {
                MarkerType = Map_marker_farm;
            } else {
                MarkerType = Map_marker_land_plant;
            }
        }

        return <MarkerType plantid={plant.id} />;
    }

    handleOnClick(plantUrl) {
        this.setState({ redirect: true, redirectTo: plantUrl });
    }

    stopPropagation(e) {
        if (e) {
            if (e.stopPropagation) {
                e.stopPropagation();
            }
            if (e.nativeEvent && e.nativeEvent.stopImmediatePropagation) {
                e.nativeEvent.stopImmediatePropagation();
            }
        }
    }

    handleSetCurrentPlant(plant) {
        this.props.setCurrentPlant(plant);

        const url = 'plant/' + plant.id + '/overview';
        this.handleOnClick(url);
    }
    search(e) {
        const filter = e.target.value.trimStart();
        this.setState({
            filterString: filter,
        });
    }

    filterPlants() {
        const plants = this.props.plants.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        if (!this.state.filterString || this.state.filterString.length < 1) {
            return plants;
        }
        const fString = this.state.filterString.toLowerCase();
        return plants.filter((plant) => {
            const includes = [plant.name, plant.imo, plant.accountManagerDisplayname, plant.accountManagerName].filter(
                (prop) => prop?.toString().toLowerCase().includes(fString)
            );
            return includes.length > 0;
            //return plant.name.toLowerCase().includes(fString) || (plant.imo && plant.imo.toString().includes(fString))
        });
    }

    handleEnterPress(e) {
        if (e.key === 'Enter') {
            const plant = this.filterPlants()[0];
            if (plant) this.handleSetCurrentPlant(plant);
        }
    }

    goToExternal(e, url) {
        e.stopPropagation();
        window.open(url);
    }

    render() {
        if (!this.props.plants) {
            return <Spinner text="plants" />;
        }
        return (
            <div className="plantlist-container">
                {!this.props.isMobile ? (
                    <div className="list-search">
                        <input
                            autoComplete="off"
                            type="search"
                            ref="plant-search"
                            placeholder="Search list..."
                            className="search-plant"
                            onChange={this.search}
                            onKeyPress={this.handleEnterPress}
                        />
                        <FontAwesome className="search-icon" name="search" />
                    </div>
                ) : (
                    <div className="list-search">
                        <input
                            autoComplete="off"
                            type="search"
                            ref="plant-search"
                            placeholder="Search list..."
                            className="search-plant"
                            onChange={this.search}
                            autoFocus
                        />
                        <FontAwesome className="search-icon" name="search" />
                        <span>
                            <Link className="map-toggle" to="/map">
                                <img alt="Map icon" src={Map} />
                            </Link>
                        </span>
                    </div>
                )}
                {this.state.redirect ? <Redirect push to={'/' + this.state.redirectTo} /> : null}
                <div className="plantlist">
                    {this.props.plants
                        ? this.filterPlants().map((plant, i) => {
                              let errorClass = '';
                              if (plant.status !== 0 && plant.status !== null) {
                                  errorClass = ' plantlist-error';
                              }
                              return (
                                  <Link
                                      key={'plant' + i}
                                      to={'/plant/' + plant.id + '/overview'}
                                      className={'plantlist-row' + errorClass}
                                  >
                                      {!this.props.isMobile ? (
                                          <>
                                              <div className="plantlist-cell">
                                                  <div className="planttype">{this.setMarkerType(plant)}</div>
                                                  {plant.name}
                                              </div>

                                              <div className="plantlist-cell">{this.setUploadImage(plant)}</div>
                                              <div className="plantlist-cell">{this.setPositionImage(plant)}</div>

                                              <div className="plantlist-cell plantlist-accountmanager">
                                                  {
                                                      <>
                                                          <span className="cell-metadata">
                                                              Customer Support Manager
                                                          </span>
                                                          <span title={plant.accountManagerName}>
                                                              {plant.accountManagerDisplayname ||
                                                                  plant.accountManagerName ||
                                                                  'N/A'}
                                                          </span>
                                                      </>
                                                  }
                                              </div>
                                              <div className="plantlist-cell subscribe">
                                                  {
                                                      <SubscribeButton
                                                          external={true}
                                                          isSubbed={plant.isSubbed}
                                                          plant={plant.id}
                                                          notificationType={'all'}
                                                      />
                                                  }
                                              </div>
                                          </>
                                      ) : (
                                          <>
                                              <div className="plantlist-cell">
                                                  <div className="planttype">{this.setMarkerType(plant)}</div>
                                                  <div className="plantname">{plant.name}</div>
                                                  <div className="plantlist-cell subscribe">
                                                      {
                                                          <SubscribeButton
                                                              external={true}
                                                              isSubbed={plant.isSubbed}
                                                              plant={plant.id}
                                                              notificationType={'all'}
                                                          />
                                                      }
                                                  </div>
                                              </div>
                                              <div className="plantstatus">
                                                  <div className="plantlist-cell">{this.setUploadImage(plant)}</div>
                                                  <div className="plantlist-cell">{this.setPositionImage(plant)}</div>
                                              </div>
                                          </>
                                      )}
                                  </Link>
                              );
                          })
                        : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentPlant: state.currentPlant,
    isMobile: state.utility.isMobile,
});

const mapDispatchToProps = {
    setCurrentPlant,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantTable);

//export default PlantTable;
