import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';

class AdminPlantItemsFromTopic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topicString: '',
            itemTypes: [],
            isSubmitting: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.topicString && this.props.topicString !== '') {
            this.setState({
                topicString: this.props.topicString,
            });

            api.get(process.env.REACT_APP_API + '/api/itemTypes/getByDataType/' + this.props.topicString.split('/')[2])
                .then((res) => {
                    console.log('RES ITEMTYPES', res);
                    this.setState({
                        itemTypes: res.data,
                        itemTypeId: res.data && res.data[0].id,
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    handleChange(event) {
        const target = event.target;
        // const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ topicString: value });
    }

    handleItemTypeChange(event) {
        event.preventDefault();
        const itemTypeId = event.target.value;
        console.log('CHANGE!', itemTypeId);
        this.setState({
            itemTypeId,
        });
    }

    async createSubmit(event) {
        event.preventDefault();
        if (this.props.user != null && this.props.plant != null) {
            try {
                this.setState({ isSubmitting: true });
                const response = await api.post(
                    process.env.REACT_APP_API + '/api/plants/' + this.props.plant.id + '/items/generateFromTopic/',
                    { topic: this.state.topicString, itemTypeId: this.state.itemTypeId }
                );
                //.then((response) => {
                if (response.status === 200 || response.status === 204) {
                    if (response.status === 204) {
                        NotificationManager.info('No items created', 'Structure already exists', 5000);
                    } else {
                        NotificationManager.success('Structure successfully created', 'Structure created', 5000);
                        this.props.addedCallback();
                    }
                }
            } catch (err) {
                console.error(err);
                NotificationManager.error('Structure not created', 'Error creating structure', 5000);
            }
            this.setState({ isSubmitting: false });
        }
    }

    renderButton() {
        const button = [
            <button
                key={'cancel-btn'}
                className="btn btn-yellow btn-grow"
                onClick={(e) => {
                    e.preventDefault();
                    this.props.closeModal();
                }}
            >
                Cancel
            </button>,
        ];

        button.push(
            <button
                key={'creating-btn'}
                disabled={this.state.isSubmitting}
                className="btn btn-blue btn-grow"
                value="Submit"
            >
                {this.state.isSubmitting ? 'Creating...' : 'Create'}
            </button>
        );

        return button;
    }

    render() {
        if (!this.props) return;
        //console.log("props", this.props);

        return (
            <div>
                <div className="admin-plant-items-add-edit-header">
                    <span>Generate structure from topic string</span>
                </div>
                <h3>Topic must contain full path starting with OC1</h3>
                <form onSubmit={this.createSubmit} className="admin-plant-items-add-edit-form">
                    <div>
                        <label htmlFor="itemName">
                            Topic string <span className="small-text">&nbsp;Required</span>
                        </label>
                        <input
                            required
                            autoComplete="off"
                            type="text"
                            className={'admin-plant-items-input-control'}
                            name="topicString"
                            value={this.state.topicString || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>
                    <br />
                    {this.state.itemTypes.length > 0 ? (
                        <select className="admin-form-select" name="data-type" onChange={this.handleItemTypeChange}>
                            {this.state.itemTypes.map((dt, i) => {
                                return (
                                    <option key={'it' + i} id={i} value={dt.id}>
                                        {dt.name}
                                    </option>
                                );
                            })}
                        </select>
                    ) : null}

                    <div className="btn-row btn-row-grow">{this.renderButton()}</div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentAdminPlant,
});

export default connect(mapStateToProps)(AdminPlantItemsFromTopic);
