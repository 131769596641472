import React from 'react';
import './AdminArticleAddEdit.css';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import api from '../../../utility/api';
import FontAwesome from 'react-fontawesome';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import { confirmAlert } from 'react-confirm-alert';
import { noop } from 'lodash';

const overlayStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '2.5em 0',
    background: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    color: '#fff',
};

const dropzoneStyle = {
    border: '1px dashed #919191',
    marginTop: '20px',
    position: 'relative',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    position: 'relative',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    margin: '0 auto',
};

const accept = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'image/gif',
    'application/pdf',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
];

class AdminArticleAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            plantTypes: [],
            nameValid: false,
            formValid: false,
            isDirty: false,
            checkedItems: [],
            article: [],
            validName: true,
            files: [],
            existingFiles: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.fetchCategories = this.fetchCategories.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            isDirty: true,
            userAdded: false,
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        if (name === 'name') {
            //check if name exists
            if (this.props.allArticles.filter((a) => a.name?.toLowerCase() === value?.toLowerCase()).length > 0) {
                this.setState({
                    validName: false,
                });
            }
        }

        if (target.type === 'checkbox') {
            if (value) {
                this.addArticle(name);
            } else {
                this.removeArticle(name);
            }
        } else {
            const tmp = Object.assign({}, this.state.article);
            tmp[name] = value;

            this.setState({ article: tmp });
        }
    }

    componentDidMount() {
        if (this.props.editArticle) {
            api.get(process.env.REACT_APP_API + '/api/documents/article/' + this.props.editArticle.id).then((res) => {
                this.setState({
                    article: this.props.editArticle,
                    existingFiles: res.data,
                    mode: 'edit',
                });
            });
        }
        this.fetchCategories();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) this.fetchCategories();
    }

    fetchCategories() {
        if (!this.props.user) return;

        api.get(process.env.REACT_APP_API + '/api/article/category')
            .then((res) => this.setState({ categories: res.data }))
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    handleSubmit(event) {
        if (this.state.mode === 'edit') {
            this.updateSubmit(event);
        } else {
            this.createSubmit(event);
        }
    }
    createSubmit(event) {
        event.preventDefault();

        const article = {
            id: this.state.article.id,
            name: this.state.article.name,
            categoryId: this.state.article.categoryId,
            brand: this.state.article.brand,
            description: this.state.article.description,
        };

        api.post('/api/article', article)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Article created', 'Article successfully created', 5000);
                    const tmp = Object.assign({}, this.state.article);
                    tmp.name = '';
                    tmp.categoryId = '';
                    tmp.description = '';
                    tmp.brand = '';

                    this.setState(
                        {
                            isDirty: false,
                            formValid: false,
                            article: tmp,
                            articleId: response.data.id,
                            uploadFiles: true,
                        },
                        () => {
                            this.uploadFiles();
                            this.props.editedCallback(response);
                        }
                    );
                } else {
                    NotificationManager.error('Error', 'Failed to create article', 5000);
                    throw new Error(response.status);
                }
            })
            .then((res) => {
                this.props.addedCallback(res);
            })
            .catch(noop);
    }

    confirmDeleteFile(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete article document</h1>
                        <p>
                            You are about to delete the article document
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.filename}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteFile(item.id);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    deleteFile(id) {
        api.delete(process.env.REACT_APP_API + '/api/documents/' + id + '?articleDoc=true').then((res) => {
            if (res.status === 200) {
                const tmp = Object.assign([], this.state.existingFiles);
                tmp.splice(
                    tmp.indexOf((f) => f.id === id),
                    1
                );
                this.setState({
                    existingFiles: tmp,
                });
            }
        });
    }

    updateSubmit(event) {
        event.preventDefault();

        const article = {
            id: this.state.article.id,
            name: this.state.article.name,
            categoryId: this.state.article.categoryId,
            brand: this.state.article.brand,
            description: this.state.article.description,
        };

        api.put(process.env.REACT_APP_API + '/api/article/' + article.id, article)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Article updated', 'Article successfully updated', 5000);
                    this.uploadFiles();
                    this.props.editedCallback(response.data);
                } else {
                    NotificationManager.error('Error', 'Failed to update article', 5000);
                    // throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    renderButton() {
        let button;

        if (this.state.mode === 'edit') {
            button = (
                <button
                    className="btn btn-blue btn-grow"
                    onClick={this.handleSubmit}
                    value="Submit"
                    disabled={!this.state.validName}
                >
                    Save changes
                </button>
            );
        } else {
            button = (
                <button
                    className="btn btn-blue btn-grow"
                    onClick={this.handleSubmit}
                    value="Submit"
                    disabled={!this.state.validName}
                >
                    Create
                </button>
            );
        }

        return button;
    }

    handleCategoryChange(event) {
        event.preventDefault();
        const tmp = Object.assign({}, this.state.article);
        tmp.categoryId = event.target.value;

        this.setState({
            article: tmp,
        });
    }

    removeFile(index) {
        const files = this.state.files;
        files.splice(index, 1);
        this.setState({ files });
    }

    uploadFiles() {
        this.state.files.forEach((file) => {
            this.fileUpload(file);
        });
    }

    onDrop(files) {
        if (files.length < 1) {
            NotificationManager.error('File type not supported', 'File error', 5000);
            return;
        }
        const prevFiles = this.state.files;
        prevFiles.push(...files);
        this.setState({
            files: prevFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
        });
    }

    fileUpload(file) {
        if (this.uploading) {
            return;
        }

        const data = new FormData();
        data.append('File', file);
        data.append('Filename', file.name);
        data.append('ArticleId', this.state.articleId || this.state.article.id);
        data.append('CreatedAt', moment.utc().format());
        data.append('Date', moment(this.state.selectedDate).utc().format());
        api.post(process.env.REACT_APP_API + '/api/documents', data)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    if (this.state.selectedOption) {
                        this.setCategories(response.data.id);
                    }
                    this.setState({
                        files: [],
                        link: '',
                        linkName: '',
                    });
                    this.props.fetchDocs();
                    NotificationManager.success('File uploaded', 'File uploaded successfully', 5000);
                    this.props.closeModal();
                } else {
                    NotificationManager.error('Error', 'Failed to upload file', 5000);
                    throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    render() {
        const categories = this.state.categories;
        const { files } = this.state;

        const thumbs = files.map((file, i) => (
            <div className="file-preview" key={'preview-' + i}>
                <div style={thumb} key={file.name + '-' + i}>
                    <FontAwesome name="remove" className="file-remove" onClick={() => this.removeFile(i)} />

                    <div style={thumbInner}>
                        <a href={file.preview} rel="noopener noreferrer" target="_blank">
                            {file.type.includes('image') ? (
                                <img src={file.preview} style={img} alt="preview" />
                            ) : (
                                <FontAwesome name="file" size="3x" style={{ margin: '0 auto', color: '#919191' }} />
                            )}
                        </a>
                    </div>
                </div>
                <span>{file.name}</span>
            </div>
        ));

        return (
            <div className="admin-plant-add-edit-form">
                {this.state.mode === 'edit' ? <h1>Edit Article</h1> : <h1>New Article</h1>}
                <form style={{ width: '100%' }} onSubmit={this.handleSubmit}>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            required
                            name="name"
                            value={this.state.article.name || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>

                    <div>
                        <label htmlFor="name">Brand:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            name="brand"
                            value={this.state.article.brand || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>

                    <div>
                        <label htmlFor="name">Description:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            name="description"
                            value={this.state.article.description || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>

                    <div>
                        <label htmlFor="plant_type">Choose Category:</label>
                        <select
                            className="admin-form-select"
                            value={categories.id}
                            name="categoryId"
                            onChange={this.handleCategoryChange}
                        >
                            {this.props.editArticle ? (
                                <option value={this.props.editArticle.categoryId}>
                                    {this.props.editArticle.category}
                                </option>
                            ) : (
                                <option value="0">Select Category</option>
                            )}
                            {categories.map((category, i) => {
                                return (
                                    <option key={'category' + i} id={i} value={category.id}>
                                        {category.category}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </form>
                <div className={'form-container ' + this.props.className ? this.props.className : null}>
                    <form style={{ width: 'unset' }} onSubmit={this.handleSubmit}>
                        <Dropzone onDrop={this.onDrop.bind(this)} accept={accept}>
                            {({ getRootProps, getInputProps, isDragActive }) => (
                                <div {...getRootProps()} style={dropzoneStyle}>
                                    Drop files here or click to upload
                                    <input {...getInputProps()} />
                                    {isDragActive && <div style={overlayStyle}></div>}
                                </div>
                            )}
                        </Dropzone>

                        <aside style={thumbsContainer}>{thumbs}</aside>
                    </form>
                </div>
                <div className="existing-files">
                    {this.state.existingFiles.map((file) => {
                        return (
                            <div className="file" key={file.filename}>
                                {file.filename}
                                <DeleteIcon
                                    onClick={() => this.confirmDeleteFile(file)}
                                    className="action-icon red"
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="btn-row btn-row-grow">
                    <button className="btn btn-yellow btn-grow" onClick={this.props.cancelCallback}>
                        Cancel
                    </button>
                    {this.renderButton()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminArticleAddEdit);
