import React from 'react';
import 'gantt-task-react/dist/index.css';
import { Gantt } from 'gantt-task-react';
import { getChangedTasks, getStartEndDateForProject, convertTasks, getPercentage } from './helper';
import './gantttable.scss';
import { GanttChartConfiguration } from '../../../components/common/GanttChart/GanttChartConfiguration';

let handleTaskChangedTaskId = '';
let handleTaskChangedDateTime = new Date();

function Ganttchart(props) {
    const [tasks, setTasks] = React.useState();

    React.useEffect(() => {
        if (props.data) {
            let result = convertTasks(props.data, props.globalFilterValue, props.plantSelectedOptions);
            props.onNumberOfPlantsChange(result.numberOfPlants);
            setTasks(result.tasks);
            props.onResetOperationChanges();
        }
    }, [props.data, props.globalFilterValue, props.plantSelectedOptions]);

    const ganttChartConfiguration = GanttChartConfiguration({ view: props.timeviewMode });
    const columnWidth = ganttChartConfiguration.table.columnWidth;
    const tooltip = ganttChartConfiguration.tooltip;
    const customTaskListTable = ganttChartConfiguration.customTaskListTable;
    const customTaskListHeader = ganttChartConfiguration.customTaskListHeader;

    const handleTaskChange = (task) => {
        const currentDate = new Date();

        if (handleTaskChangedTaskId === task.id && currentDate.getTime() - handleTaskChangedDateTime.getTime() < 100) {
            return;
        }

        handleTaskChangedTaskId = task.id;
        handleTaskChangedDateTime = new Date();

        task.progress = getPercentage(task.start, task.end);        

        let newTasks = tasks.map((t) => (t.id === task.id ? {...task, previousStart : t.start, previousEnd : t.end} : t));

        let tasksModified = [];

        //update children timeframe
        newTasks = getChangedTasks(newTasks, task, tasksModified);

        props.onTasksChange([task, ...tasksModified]);

        //update project timeframe and progress
        if (task.project) {
            const [start, end, progress] = getStartEndDateForProject(newTasks, task.project);
            const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];

            if (
                project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime() ||
                project.progress !== progress
            ) {
                const changedProject = { ...project, start, end, progress };
                newTasks = newTasks.map((t) => (t.id === task.project ? changedProject : t));
            }
        }

        setTasks(newTasks);
    };

    const handleClick = (task) => {
        if (task.type === 'project') {
            return;
        }

        const currentDate = new Date();

        if (handleTaskChangedTaskId === task.id && currentDate.getTime() - handleTaskChangedDateTime.getTime() < 100) {
            return;
        }

        props.onTaskClick(task);
    };

    const handleExpanderClick = (task) => {
        setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    };

    return (
      <div className="gant-view">
          {tasks && tasks.length > 0 ? (
              <Gantt
                  tasks={tasks}
                  viewMode={props.timeviewMode}
                  onDateChange={handleTaskChange}
                  onClick={handleClick}
                  onExpanderClick={handleExpanderClick}
                  columnWidth={columnWidth}
                  listCellWidth={'250px'}
                  barBackgroundColor="#234552"
                  barBackgroundSelectedColor="#346a7f"
                  barProgressColor="#41c9f0"
                  barProgressSelectedColor="#82d9f2"
                  barCornerRadius="16"
                  todayColor="rgba(106, 141, 151, 0.4)"
                  TooltipContent={tooltip}
                  TaskListTable={customTaskListTable}
                  TaskListHeader={customTaskListHeader}
              />
          ) : (
              <div style={{ textAlign: 'center' }}>No operations are available</div>
          )}
      </div>
    );
}

export default Ganttchart;
