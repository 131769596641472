import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as SubscribeIcon } from '../../../images/icons/optimar_bell_blue.svg';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import './SubscribeButton.scss';

class SubscribeButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscribed: this.props.isSubbed || false,
            overrideExternal: false,
        };

        this.subscribe = this.subscribe.bind(this);
        this.fetching = false;
        this.mounted = true;
    }

    componentDidMount() {
        if (!this.props.external) {
            this.checkIfSubbed();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.plant === '35dcd2e9-be27-4d8b-a647-81196f708e9b') {
            console.log(this.props.isSubbed);
        }
        if (this.props.external && !this.state.overrideExternal && this.props.isSubbed !== prevProps.isSubbed) {
            this.setState({ subscribed: this.props.isSubbed });
        }
    }

    checkIfSubbed() {
        api.get(
            process.env.REACT_APP_API +
                '/api/notificationSubscriptions/issubbed?plant=' +
                this.props.plant +
                '&user=' +
                this.props.user.profile.sub +
                '&notificationType=' +
                this.props.notificationType
        )
            .then((res) => {
                if (res.status === 200 && this.mounted) {
                    if (res.data) {
                        this.setState({
                            subscribed: true,
                        });
                    } else {
                        this.setState({
                            subscribed: false,
                        });
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    subscribe(e) {
        e.stopPropagation();
        e.preventDefault();
        if (this.fetching === true) return;
        if (this.props.external && !this.state.overrideExternal) {
            this.setState({ overrideExternal: true });
        }

        this.fetching = true;
        if (!this.state.subscribed) {
            api.post(process.env.REACT_APP_API + '/api/notificationSubscriptions', {
                user: this.props.user.profile.sub,
                plant: this.props.plant,
                notificationType: this.props.notificationType,
            })
                .then((res) => {
                    if (res.status === 200 && this.mounted) {
                        this.setState({
                            subscribed: true,
                        });
                        NotificationManager.success('You have successfully subscribed', 'Subscribed', 5000);
                    } else {
                        NotificationManager.error('Could not subscribe, try again later', 'Subscribe error', 5000);
                    }
                    this.fetching = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.fetching = false;
                });
        } else {
            api.delete(
                process.env.REACT_APP_API +
                    '/api/notificationSubscriptions?plant=' +
                    this.props.plant +
                    '&user=' +
                    this.props.user.profile.sub +
                    '&notificationType=' +
                    this.props.notificationType
            )
                .then((res) => {
                    if (res.status === 200 && this.mounted) {
                        this.setState({
                            subscribed: false,
                        });
                        NotificationManager.success('You have successfully unsubscribed', 'Unsubscribed', 5000);
                    } else {
                        NotificationManager.error('Could not unsubscribe, try again later', 'Unsubscribe error', 5000);
                    }
                    this.fetching = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.fetching = false;
                });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (
            <span title="Subscribe" className="subscribe-button" onClick={this.subscribe}>
                <SubscribeIcon
                    className={
                        (this.props.external && !this.state.overrideExternal && !this.props.isSubbed) ||
                        !this.state.subscribed
                            ? 'unsubscribed'
                            : ''
                    }
                    title="Subscribe"
                />
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default connect(mapStateToProps)(SubscribeButton);
