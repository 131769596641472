import React from 'react';
import './VerticalBar.scss';
import api from '../../../utility/api';
import { getTimeSince } from '../../../utility/timesince';
import { getValueWithPrecisionOrDefault } from '../../../utility/numberFunctions';
import { forEach } from 'lodash';

class VerticalBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
        this.getChartInfo = this.getChartInfo.bind(this);
    }

    componentDidUpdate(prevProps) {
        //console.log("update2");

        if (this.props.shouldFetch && this.props.user && JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });
        }
    }

    componentDidMount() {
        if (this.props.shouldFetch && this.props.user && !this.props.data) {
            this.getChartInfo((res) => {
                if (this.mounted) {
                    this.setState({
                        res: res,
                    });
                }
            });

            this.interval = setInterval(() => {
                this.getChartInfo((res) => {
                    if (this.mounted) {
                        this.setState({
                            res: res,
                        });
                    }
                });
            }, this.props.refreshInterval || 10000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.mounted = false;
    }

    getChartInfo = (callback) => {
        let apiUrl =
            process.env.REACT_APP_API +
            '/api/kpi?machinenumber=' +
            this.props.machineNumber +
            '&valueFormat=single&dataType=' +
            this.props.dataType +
            '&dataValue=' +
            this.props.dataValue;
        forEach(this.props.components, (component) => {
            apiUrl += '&componentId=' + component;
        });

        api.get(apiUrl)
            .then((res) => {
                return callback(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    setBarColor() {
        const threshold = this.props.configuration.threshold;
        const value = this.state.res ? this.state.res.data.value : this.props.data ? this.props.data.data.value : 0;
        if (threshold) {
            if (value > threshold.alertHigh || value < threshold.alertLow) {
                return 'linear-gradient(rgba(255,0,85,0.6), rgba(255,0,85,0))';
            } else if (value > threshold.warningHigh || value < threshold.warningLow) {
                return 'linear-gradient(rgba(201,181,78,0.6), rgba(201,181,78,0))';
            }
        }
        return 'linear-gradient(rgba(65,200,240,0.6), rgba(65,200,240,0))';
    }

    render() {
        const res = this.state.res || this.props.data || { data: {} };
        //console.log("RES", res);

        const minValue = this.props.configuration.minValue;
        const maxValue = this.props.configuration.maxValue;

        const decimals = this.props.configuration.decimalPlaces || 1;
        const current = parseFloat(getValueWithPrecisionOrDefault(res.data.value, decimals, 0)); //res.data.value ? parseFloat(res.data.value.toFixed(decimals)) : parseFloat(res.data.value);

        const totalSpan = maxValue - minValue;
        let currentCalc = current;
        if (current < 0 || minValue < 0) {
            currentCalc = (minValue - current) * -1;
        }

        let currentPercent = current != null ? (currentCalc / totalSpan) * 100 : 0;
        if (currentPercent > 100) {
            currentPercent = 100;
        } else if (currentPercent < 0) {
            currentPercent = 0;
        }

        let currentDowntimePercent = 0;
        const currentDowntime = parseFloat(res.data.downTime) || 0;
        let total = 0;
        if (this.props.type === 'opti-control-live') {
            currentDowntimePercent = res.data.downTime != null ? (currentDowntime / totalSpan) * 100 : 0;
            total = current + currentDowntime;
            total = total % 1 !== 0 ? total.toFixed(1) : total;
            if (currentDowntimePercent > 100) {
                currentDowntimePercent = 100;
            } else if (currentDowntimePercent < 0) {
                currentDowntimePercent = 0;
            }
        }

        return (
            <div>
                <span className="time-since">{getTimeSince(res)}</span>

                <div className="vertical-bar">
                    <div className="vertical-wrapper">
                        <div className="bar-gauge-bar">
                            {this.props.type === 'opti-control-live' ? (
                                <>
                                    <div
                                        className="bar-gauge-bar-progress-ocl"
                                        style={{
                                            height: currentPercent + '%',
                                            bottom: currentDowntimePercent + '%',
                                            backgroundImage: this.setBarColor(),
                                            borderTop: '2px solid white',
                                        }}
                                        title={current}
                                    ></div>
                                    <div
                                        className="bar-gauge-bar-progress-ocl downtime"
                                        style={{
                                            height: currentDowntimePercent + '%',
                                            background: 'rgba(255,0,85,0.6)',
                                        }}
                                        title={currentDowntime}
                                    ></div>
                                </>
                            ) : (
                                <div
                                    className="bar-gauge-bar-progress"
                                    style={{
                                        height: currentPercent + '%',
                                        backgroundImage: this.setBarColor(),
                                        borderTop: '2px solid white',
                                    }}
                                ></div>
                            )}
                        </div>

                        <div className="data-wrapper">
                            <div className="data-title">
                                <span className="data-title__label">{this.props.title}</span>
                            </div>
                            <div className="data">
                                <div className="data-value">
                                    <span className="data-value__number">
                                        {this.props.type === 'opti-control-live' ? total : current}
                                    </span>
                                </div>
                                <div className="data-units">
                                    <span className="data-units__total">/{maxValue || 100}</span>
                                    <span className="data-units__unit">{this.props.configuration.unit}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

VerticalBar.propTypes = {};

export default VerticalBar;
