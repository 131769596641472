import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { clearAccountDocList, clearDocList, setAccountDocList, setCurrentPlant, setDocList } from '../../actions';
import FileUpload from '../../components/common/FileUpload/FileUpload';
import GenericTable from '../../components/common/GenericTable';
import Spinner from '../../components/common/Spinner/Spinner';
import { withRouteMatch } from '../../HOC';
import '../../styles/styles.scss';
import api from '../../utility/api';
import { guidIsNullOrEmpty } from '../../utility/guidFunctions';
import '../list/generic-list.scss';
import './documentlist.scss';
import {
    confirmDelete,
    createAndDownloadFile,
    fetchSingleDocAsync,
    fetchSingleExternalDocAsync,
} from './documentoperations';
import { getColumns, getHeader } from './listdefinition';

library.add(fas, far);

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
    },
};

class DocumentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: undefined,
            modalIsOpen: false,
            files: [],
            fetching: false,
            fetchingSingleDoc: false,
            sortColumn: undefined,
            sortDirection: undefined,
            currentPage: 1,
            pageSize: 10,
            showItemDocs: true,
            editItem: null,
            filterColumns: [],
        };
        this.filterString = '';

        //this.fetching = false;
        this.mounted = true;
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.fetchDocumentation = this.fetchDocumentation.bind(this);
        this.fetchSingleDoc = this.fetchSingleDoc.bind(this);
        this.filter = this.filter.bind(this);
        //this.search = this.search.bind(this);
        this.onDrop = this.onDrop.bind(this);
        //this.onChange = this.onChange.bind(this);
        //this.onShowSizeChange = this.onShowSizeChange.bind(this);
        // this.confirmDelete = confirmDelete.bind(this);
        this.editFile = this.editFile.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.visibleColumns = getColumns(
            this.props,
            this.fetchSingleDoc,
            this.handleConfirmDelete,
            this.editFile,
            this.openFile,
            this.hideDocModifyContextMenuFunctions
        );
    }

    handleConfirmDelete(item) {
        confirmDelete(item);
        this.fetchDocumentation();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.user &&
            this.props.plant &&
            JSON.stringify(prevProps) !== JSON.stringify(this.props)
        ) {
            this.fetchDocumentation();
            this.fetchBreadcrumb();
        }
        //console.log(prevProps, this.props);
    }

    componentDidMount() {
        if (this.props.user && this.props.plant) {
            this.fetchDocumentation();
            this.fetchBreadcrumb();
        } else if (this.props.user && !this.props.plant) {
            this.fetchAccountDocumentation();
        }
    }

    componentWillUnmount() {
        this.props.clearDocList();
        this.props.clearAccountDocList();
        this.mounted = false;
        clearInterval(this.interval);
    }

    fetchBreadcrumb() {
        const id = this.props.itemId ? this.props.itemId : this.props.plantId;
        const url = `${process.env.REACT_APP_API}/api/breadcrumb?itemId=${id}`;

        api.get(url)
            .then((res) => {
                if (this.mounted) {
                    this.setState({
                        breadcrumb: res.data,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    fetchAccountDocumentation = async () => {
        this.setState({ fetching: true });
        const url = `${process.env.REACT_APP_API}/api/documents/account`;
        try {
            const res = await api.get(url);
            this.fetching = false;
            const data = res.data;
            data.map((d) => (d.itemName === null ? (d.itemName = '') : d.itemName));
            //console.log(data);
            this.props.setAccountDocList(data);
            this.props.setDocList(data);
            this.setFilteredDocuments();
        } catch (err) {
            console.error(err);
            NotificationManager.error('Failed to get documents', 'Error getting documents', 5000);
        }
        this.setState({ fetching: false });
    };
    fetchDocumentation = async () => {
        if (this.props.user && !this.props.plant) {
            await this.fetchAccountDocumentation();
        } else {
            await this.fetchPlantDocumentation();
        }
    };

    fetchPlantDocumentation = async () => {
        this.setState({ fetching: true });
        let url = `${process.env.REACT_APP_API}/api/documents/list/${this.props.plantId}/${this.props.itemId}`;
        if (guidIsNullOrEmpty(this.props.itemId)) {
            url = `${process.env.REACT_APP_API}/api/documents/list/${this.props.plantId}`;
        }
        try {
            const res = await api.get(url);
            if (res.status === 200) {
                const data = res.data;
                data.map((d) => (d.itemName === null ? (d.itemName = '') : d.itemName));
                this.props.setDocList(data);
                this.setFilteredDocuments();
            } else if (res.status === 204) {
                this.props.setDocList([]);
            }
        } catch (err) {
            console.error(err);
            NotificationManager.error('Failed to get documents', 'Error getting documents', 5000);
        }
        this.setState({ fetching: false });
    };

    fetchSingleDoc = async (doc) => {
        this.setState({
            fetchingSingleDoc: true,
        });
        try {
            if (doc.external === 'SharePoint') {
                await fetchSingleExternalDocAsync(doc, this.props);
            } else {
                const response = await fetchSingleDocAsync(doc, this.props);
                if (response.status === 200) {
                    createAndDownloadFile(response.data, doc.filename);
                    NotificationManager.success('Document downloaded successfully', 'Download success');
                } else {
                    NotificationManager.error('Failed to download document', 'Download failed', 5000);
                }
            }
        } catch (err) {
            console.error(err);
            NotificationManager.error('Failed to download document', 'Download failed', 5000);
        }
        this.setState({
            fetchingSingleDoc: false,
        });
    };

    hideDocModifyContextMenuFunctions = (row) => {
        console.log('hideDocModifyContextMenuFunctions', row);
        if (row.original.external?.length > 0) {
            return true;
        }
        return false;
    };
    openFile = (doc) => {
        console.log('openFile', doc);
        //let doc = row.original;
        if (!doc) {
            NotificationManager.warning('Please try again later', 'Unable to open document', 5000);
            return;
        }
        doc.link && !doc.external ? document.open(doc.link) : this.fetchSingleDoc(doc);
    };
    editFile = (doc) => {
        this.setState({ editItem: doc }, this.openModal(doc.link ? 'link' : 'file'));
    };

    openModal(type) {
        this.setState({
            modalIsOpen: true,
            linkOrFile: type,
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            editItem: null,
        });
    }

    filter() {
        if (!this.filterString || this.filterString.length < 1) {
            return this.props.documents.filter((doc) => {
                return !doc.noteId;
            });
        }
        const fString = this.filterString.toLowerCase();

        return this.props.documents.filter((o) => {
            return Object.keys(o).some((k) => {
                if (k === 'createdAt') {
                    return new Date(o[k]).toString().toLowerCase().includes(fString.toLowerCase());
                }
                if (typeof o[k] === 'string' || typeof o[k] === 'number') {
                    return String(o[k]).toLowerCase().includes(fString.toLowerCase());
                } else {
                    let res = false;
                    o.categories &&
                        o.categories.forEach((cat) => {
                            res = cat.name.toLowerCase().includes(fString);
                        });
                    return res;
                }
            });
        });
    }

    search(e) {
        const filter = e.target.value.trim();
        this.filterString = filter;
        this.setFilteredDocuments();
    }

    onDrop(files) {
        this.setState({ files });
    }

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    canUpload() {
        if (!sessionStorage.getItem('userImpersonate') && this.props.plant) {
            return true;
        }
        return false;
    }

    setFilteredDocuments(sortByColumn) {
        let docs = this.filter();
        let newDirection,
            newSortColumn = undefined;

        if (docs.length < this.state.currentPage * this.state.pageSize) {
            this.setState({ currentPage: 1 });
        }

        if (this.state.sortColumn && sortByColumn && this.state.filterColumns.length === 0) {
            if (this.state.sortColumn === sortByColumn) {
                if (this.state.sortDirection === 'asc') {
                    newDirection = 'desc';
                    newSortColumn = sortByColumn;
                    docs = docs.sort(this.sortFunc(false, sortByColumn));
                } else if (this.state.sortDirection === 'desc') {
                    newDirection = undefined;
                    newSortColumn = undefined;
                }
            } else {
                newDirection = 'asc';
                newSortColumn = sortByColumn;
                docs = docs.sort(this.sortFunc(true, sortByColumn));
            }
        } else if (sortByColumn && docs && !this.state.filterColumns.length === 0) {
            newDirection = 'asc';
            newSortColumn = sortByColumn;
            docs = docs.sort(this.sortFunc(true, sortByColumn));
        }

        if (docs && this.state.filterColumns.length > 0) {
            console.log('Filtercols', this.state.filterColumns);
            this.state.filterColumns.forEach((c) => {
                if (c === 'itemId') {
                    docs = docs.filter((d) => {
                        return (
                            (d[c] === null && d.hasArticleDocs) ||
                            d[c]?.toLowerCase() === this.props.match.params.itemId?.toLowerCase()
                        );
                    });
                } else {
                    docs = docs.filter((d) => {
                        return d[c] === null;
                    });
                }
            });
        }

        this.setState({ documents: docs, sortDirection: newDirection, sortColumn: newSortColumn });
    }

    sortFunc(ascending, colName) {
        return (a, b) => {
            if (a === b) {
                return 0;
            } else if (a[colName] === null) {
                return 1;
            } else if (b[colName] === null) {
                return -1;
            } else if (ascending) {
                return a[colName].toString().toLowerCase() < b[colName].toString().toLowerCase() ? -1 : 1;
            } else {
                return a[colName].toString().toLowerCase() < b[colName].toString().toLowerCase() ? 1 : -1;
            }
        };
    }
    handleShowDocs(type) {
        const currentFilter = [].concat(this.state.filterColumns);
        currentFilter.indexOf(type) === -1
            ? currentFilter.push(type)
            : currentFilter.splice(currentFilter.indexOf(type), 1);

        this.setState(
            {
                filterColumns: currentFilter,
            },
            () => this.setFilteredDocuments()
        );
    }

    showSubDocs = (_event) => {
        return this.state.fetching || !this.props.documents ? null : this.handleShowDocs('itemId');
    };
    showProjectDocs = (_event) => {
        return this.state.fetching || !this.props.documents ? null : this.handleShowDocs('external');
    };
    showArticleDocs = (_event) => {
        return this.state.fetching || !this.props.documents ? null : this.handleShowDocs('article');
    };
    enableFiltering = () => {
        return this.state.fetching || !this.props.documents;
    };
    hasItemIdFilter = () => {
        return this.state.filterColumns.indexOf('itemId') === -1;
    };
    hasProjectDocFilter = () => {
        return this.state.filterColumns.indexOf('external') === -1;
    };
    hasArticleDocFilter = () => {
        return this.state.filterColumns.indexOf('article') === -1;
    };
    render() {
        //let docs = this.state.documents ? this.state.documents.slice((this.state.currentPage - 1) * this.state.pageSize, ((this.state.currentPage - 1) * this.state.pageSize) + this.state.pageSize) : [];
        const docs = this.state.documents || [];
        if (this.state.fetching) {
            return <Spinner text="documents"></Spinner>;
        }

        return (
            <div className="documentlist-grid">
                <div className="main-header">
                    <span>
                        {!(this.props.isMobile || this.props.isTablet) ? (
                            <h2>{this.state.documents ? this.state.documents.length : 0} Documents</h2>
                        ) : null}
                        {this.canUpload() ? (
                            <>
                                <span
                                    className="main-header-action"
                                    style={{ paddingRight: 0 }}
                                    onClick={() => this.openModal('file')}
                                >
                                    {' '}
                                    <span className="list-expand-toggle">+ Upload file</span>{' '}
                                </span>
                                <span
                                    className="main-header-action"
                                    style={{ padding: 0 }}
                                    onClick={() => this.openModal('link')}
                                >
                                    {' '}
                                    <span className="list-expand-toggle">+ New link</span>{' '}
                                </span>
                            </>
                        ) : null}
                    </span>
                </div>

                <GenericTable
                    header={getHeader(docs, this.props.itemId, {
                        showSubDocs: this.showSubDocs,
                        showProjectDocs: this.showProjectDocs,
                        showArticleDocs: this.showArticleDocs,
                        enableFiltering: this.enableFiltering,
                        hasItemIdFilter: this.hasItemIdFilter,
                        hasProjectDocFilter: this.hasProjectDocFilter,
                        hasArticleDocFilter: this.hasArticleDocFilter,
                    })}
                    columns={this.visibleColumns}
                    data={docs}
                    localPaging={true}
                    enableGlobalFilter={true}
                    defaultPageSize={20}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <div>
                        <FileUpload
                            editItem={this.state.editItem}
                            type={this.state.linkOrFile}
                            closeModal={this.closeModal}
                            itemId={this.props.itemId}
                            fetchDocs={this.fetchDocumentation}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plants.currentPlant,
    user: state.user.currentUser,
    documents: state.documents.docList || [],
    isMobile: state.utility.isMobile,
    isTablet: state.utility.isTablet,
});

const mapDispatchToProps = {
    setDocList,
    setCurrentPlant,
    clearDocList,
    setAccountDocList,
    clearAccountDocList,
};

export default withRouteMatch(connect(mapStateToProps, mapDispatchToProps)(DocumentList));
