import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import CustomDatePicker from '../../components/common/CustomDatePicker/CustomDatePicker';

export function getHeader(
    props,
    state,
    data,
    itemId,
    {
        enableFiltering,
        showSubComponentAlarms,
        hasSubComponentAlarmsFilter,
        openPeriodDropdown,
        openDropdown,
        daterange,
    }
) {
    //console.log("my state..",state)
    const header = {
        left: [
            // ,
            // <div onClick={() => showInactiveAlarms()} className={"list-filter-check faux-checkbox " + (enableFiltering() ? "disabled" : "")}>
            //             <div className={hasInactiveAlarmsFilter()? 'checkbox checked' : 'checkbox'}></div>
            //             <span>Show inactive alarms</span>
            // </div>
        ],
        right: [
            <div key={'date-wrapper'} className={'date-wrapper parent ' + (props.isMobile ? '' : ' mobile')}>
                <div className="date-picker-button" onClick={() => openPeriodDropdown(true)}>
                    <div className="summary-value">Period:</div>
                    <div className="dates-value">{state.period ? state.period.interval : ''}</div>
                </div>
                {state.showPeriodDropdownOnParent ? (
                    <CustomDatePicker period={true} daterange={(val) => daterange(val, true, true)} />
                ) : (
                    <div></div>
                )}
                <div className="date-picker-button" onClick={() => openDropdown(true)}>
                    <div className="summary-value">End time:</div>
                    <div className="dates-value">
                        {state.endDate ? moment(state.endDate).format('DD.MM.YYYY HH:mm') : 'NOW'}
                    </div>
                </div>

                {state.showDropdownOnParent ? (
                    <CustomDatePicker
                        mobile={props.isMobile}
                        selDate={state.endDate}
                        daterange={(val, a, b, c, cancel) => daterange(val, false, true, null, cancel)}
                    />
                ) : (
                    <div></div>
                )}
            </div>,
        ],
    };
    if (itemId) {
        //only show these filters if we are on an item page
        header.left.push.apply(header.left, [
            <div
                key={'list-filter-check'}
                onClick={() => showSubComponentAlarms()}
                className={'list-filter-check faux-checkbox ' + (enableFiltering() ? 'disabled' : '')}
            >
                <div className={hasSubComponentAlarmsFilter() ? 'checkbox checked' : 'checkbox'}></div>
                <span>Show subcomponent alarms</span>
            </div>,
        ]);
    }
    return header;
}

export function getColumns(props) {
    const createLink = (itemId, name, row) => {
        //console.log(props);
        const plantId = row?.original?.plantId || props.plant?.id;
        if (!itemId || !plantId) return <div className="list-item-title">{name}</div>;
        //console.log(row);
        return (
            <div className="list-item-title">
                <Link to={`/plant/${plantId}/items/${itemId}/alarms`}>{name}</Link>
            </div>
        );
    };

    const columns = [
        {
            Header: 'Last triggered',
            accessor: 'latestTime',
            Cell: (row) => <div>{row.value ? moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss') : null}</div>,
        },
        {
            Header: 'Name',
            accessor: 'alarmName',
        },
        {
            Header: 'Component',
            accessor: 'itemName',
            Cell: (cellInfo) => {
                //cellinfo.row.original
                //console.log("cellinfo",cellInfo);
                const plantId = cellInfo.row.original.plantId;
                const itemId = cellInfo.row.original.itemId;
                const itemName = cellInfo.row.original.itemName;

                if (plantId && itemName && !itemId) {
                    return <div>{itemName}</div>;
                } else if (itemId && itemName) {
                    return createLink(itemId, itemName, cellInfo.row);
                } else {
                    const fullTopic = cellInfo.row.original.topic;
                    try {
                        const toPlantkey = fullTopic.split('/', 5).join('/');
                        const componentPath = fullTopic.split(`${toPlantkey}/`)[1]; //.replace(/\s*$/, "");
                        return <div>{componentPath}</div>;
                    } catch (err) {
                        console.log('Error creating componentPath', fullTopic, err);
                        return <div>{fullTopic}</div>;
                    }
                }
            },
        },
        {
            Header: 'Severity',
            accessor: 'alarmLevelText',
        },
        {
            Header: 'Count',
            accessor: 'alarmCount',
        },
        // ,
        // {
        //     Header: () => null,
        //     id: 'rowcontext',
        //     Cell: ({ row }) => (<RowContextMenu options={rowContext} row={row} />)
        // }
    ];
    return columns;
}
