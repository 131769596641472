import React from 'react';
import PropTypes from 'prop-types';
import '../widget.css';
import * as moment from 'moment';
import api from '../../../utility/api';
import Modal from 'react-modal';
import { largeModalStyle } from '../../../utility/modalstyles';
import CustomDatePicker from '../../common/CustomDatePicker/CustomDatePicker';
import CommentList from '../../common/CommentList/CommentList';
import FileList from '../../common/FileList/FileList';
import { forEach } from 'lodash';
class NoteLogList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notes: undefined,
        };

        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
        this.getLogs();
    }

    openDropdown = () => {
        this.setState({ showDropdownOnParent: !this.state.showDropdownOnParent });
    };

    daterange(val, period, parent, row, cancel) {
        if (!cancel) {
            this.setState({ endDate: val });
        }

        this.setState(
            { loadingData: true, showDropdownOnParent: false, showPeriodDropdownOnParent: false },
            function () {
                this.getLogs();
            }
        );
    }

    openModal(mode, note) {
        this.setState({
            modalIsOpen: true,
            editItem: note,
        });
    }

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    getLogs = (_callback) => {
        const to = moment(this.state.endDate);

        const daterange =
            (this.props.job ? '&from=' + this.props.job.startDate.toISOString() : '') +
            '&to=' +
            (this.props.job ? this.props.job.endDate.toISOString() : to.toISOString());

        let apiUrl =
            '/api/notes/loglist?limit=' +
            this.props.widget?.configuration?.limit +
            '&showSub=' +
            this.props.widget?.configuration?.showSub +
            '&showClosed=' +
            this.props.widget?.configuration?.showClosed +
            daterange;
        forEach(this.props.widget.components, (component) => {
            apiUrl += '&component=' + component;
        });

        api.get(apiUrl)
            .then((res) => {
                this.setState({
                    notes: res.data,
                });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    render() {
        const res = this.state.notes;

        return (
            <div className="widget">
                <div className={'widget-header '}>
                    {this.props.title ? this.props.title : ''}{' '}
                    {this.props.note ? (
                        <span onClick={() => this.addNewNote()} className={'add-note'}>
                            Add new
                        </span>
                    ) : null}
                </div>
                {!this.props.job ? (
                    <div className={'date-wrapper parent ' + (this.check() ? '' : ' mobile')}>
                        <div className="date-picker-button" onClick={() => this.openDropdown(true)}>
                            <div className="summary-value">End time:</div>
                            <div className="dates-value">
                                {this.state.endDate ? moment(this.state.endDate).format('DD.MM.YYYY HH:mm') : 'NOW'}
                            </div>
                        </div>

                        {this.state.showDropdownOnParent ? (
                            <CustomDatePicker
                                mobile={!this.check()}
                                selDate={this.state.endDate}
                                daterange={(val, a, b, c, cancel) => this.daterange(val, false, true, null, cancel)}
                            />
                        ) : (
                            <div></div>
                        )}
                    </div>
                ) : null}
                {!res ? (
                    <div>No notes found</div>
                ) : (
                    <>
                        <div className="widget-content-full">
                            <table className="widget-table log-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Title</th>
                                        <th>Component</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {res.map((e, i) => {
                                        return (
                                            <tr key={i} onClick={() => this.openModal('edit', e)}>
                                                <td>{moment(e.date).format('DD.MM.YYYY HH:mm')}</td>
                                                <td>{e.title}</td>
                                                <td>{e.itemName}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={() => this.setState({ modalIsOpen: false })}
                            style={largeModalStyle}
                            ariaHideApp={false}
                        >
                            <div key="veryunique12341" className="note-text">
                                <div className="note-left">
                                    <h2 className="comment-title">{this.state.editItem?.title}</h2>
                                    <p className="note-content" style={{ fontSize: '15px' }}>
                                        {this.state.editItem?.content}
                                    </p>
                                    <FileList
                                        readOnly={this.props.plant ? false : true}
                                        noteId={this.state.editItem?.id}
                                        files={this.state.editItem?.documents}
                                    />

                                    <div className="btn-row-grow">
                                        <button
                                            style={{ marginLeft: '10px' }}
                                            onClick={() => {
                                                this.setState({ modalIsOpen: false });
                                            }}
                                            className="btn btn-yellow"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                                <div className="note-right">
                                    <CommentList
                                        noteId={this.state.editItem?.id}
                                        comments={this.state.editItem?.comments}
                                    />
                                </div>
                            </div>
                        </Modal>
                    </>
                )}
            </div>
        );
    }
}

NoteLogList.propTypes = {
    itemId: PropTypes.string,
};

export default NoteLogList;
