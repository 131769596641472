import React from 'react';
import * as moment from 'moment';

import PositionUploading from '../../../images/list/Location_Connected-Uploading.svg';
import PositionError from '../../../images/list/Location_Error.svg';
import PositionIdle from '../../../images/list/Location_Idle.svg';
import PositionNA from '../../../images/list/Location_Not-Available.svg';
import Position from '../../../images/list/Location.svg';

import PLANT_TYPE from '../../../constants';
import MapsLogo from '../../../images/list/GoogleMaps_logo.svg';
import MarineLogo from '../../../images/list/MarineTraffic_logo.svg';

const PositionStatus = (plant) => {
    let image = PositionNA;
    let text = '';

    if (plant.position) {
        const timeDiff = moment().valueOf() - moment.utc(plant.position.loggedDate).local().valueOf();
        if (plant.address) {
            text = plant.address;

            return (
                <span className="cell-image-text">
                    <img style={{ marginRight: '0.5em' }} alt="Position Icon" src={Location} />
                    <span className={plant.position.latitude ? 'cell-text' : 'cell-text-grey'}> {text}</span>
                </span>
            );
        } else if (plant.position.latitude) {
            text = plant.position.latitude.toFixed(2) + '°N ' + plant.position.longitude.toFixed(2) + '°W';
        } else {
            text = 'Not Available';
        }

        if (plant.plantTypeName !== 'Factory' && plant.plantTypeName !== 'Fishfarm') {
            if (timeDiff <= 60 * 60 * 1000) {
                // 1 hour
                image = PositionUploading;
            } else if (plant.position.loggedDate) {
                image = PositionIdle;
            } else {
                image = PositionError;
            }
        } else {
            image = Position;
        }
    } else {
        image = PositionNA;
        text = 'Not Available';
    }

    return (
        <>
            <span className="cell-image-text">
                <img style={{ marginRight: '0.5em' }} alt="Position Icon" src={image} />
                <span className={plant.position && plant.position.latitude ? 'cell-text' : 'cell-text-grey'}>
                    {' '}
                    {text}
                    <br />
                    {plant.type !== 'Factory' && plant.type !== 'Fishfarm' ? (
                        <span className="cell-metadata">
                            {plant.position ? moment.utc(plant.position.loggedDate).local().fromNow() : null}
                        </span>
                    ) : null}
                </span>
            </span>
            {plant.plantTypeName === PLANT_TYPE.sea ? (
                <a
                    href={'https://www.marinetraffic.com/en/ais/details/ships/imo:' + plant.imo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="goto-plant-location"
                >
                    <img alt="Marine Traffic logo" src={MarineLogo} />
                </a>
            ) : (
                <a
                    href={
                        'http://www.google.com/maps/place/' + plant.position.latitude + ',' + plant.position.longitude
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="goto-plant-location"
                >
                    <img alt="Google Maps logo" src={MapsLogo} />
                </a>
            )}
        </>
    );
};

export default PositionStatus;
