import GenericTable from '../GenericTable';
import React from 'react';
import api from '../../../utility/api';
import './AdminRole.scss';
import Modal from 'react-modal';
import RowContextMenu from '../GenericTable/TableWidgets/RowContextMenu';
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminRole extends React.Component {
    constructor(props) {
        super(props);
        this.fetching = false;
        this.state = {
            roleTitle: '',
            editItem: {
                permissions: [],
                users: [],
            },
            addedPermissions: [],
            removedPermissions: [],
            allPermissions: [],
            filteredPermissions: [],
            addedUsers: [],
            removedUsers: [],
            allUsers: [],
            filteredUsers: [],
            isModalOpen: false,
            modalTabs: [
                {
                    name: 'General',
                    value: 'general',
                },
                {
                    name: 'Users',
                    value: 'users',
                },
            ],
            currentTab: 'general',
            visibleColumns: [
                {
                    Header: 'Role',
                    accessor: 'title',
                    filterable: true,
                    sortable: false,
                },
                {
                    Header: 'Permissions',
                    accessor: 'permissions',
                    filterable: true,
                    sortable: false,
                    type: 'array',
                    width: 1200,
                },
                {
                    Header: '',
                    id: 'rowcontext',
                    Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
                },
            ],
        };

        this.fetchAllPermissionData();
        this.fetchUserData();

        const rowContext = [
            {
                content: <span>Edit role</span>,
                action: (row) => this.openModal(row.original, 'edit'),
            },
            {
                content: <span>Delete role</span>,
                action: (row) => this.confirmDelete(row.original),
            },
        ];
        this.editRole = this.editRole.bind(this);
        this.saveRole = this.saveRole.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.createRole = this.createRole.bind(this);
        this.fetchUserData = this.fetchUserData.bind(this);
        this.fetchRoleData = this.fetchRoleData.bind(this);
        this.renderUserButton = this.renderUserButton.bind(this);
        this.renderModalContent = this.renderModalContent.bind(this);
        this.addPermissionToRole = this.addPermissionToRole.bind(this);
        this.fetchAllPermissionData = this.fetchAllPermissionData.bind(this);
    }

    fetchRoleData() {
        this.setState({ loading: true });
        api.get(`api/roles`)
            .then((res) => {
                this.setState({
                    data: res.data,
                    pages: res.data.length,
                    count: res.data.length,
                    loading: false,
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({ loading: false });
            });
    }

    fetchAllPermissionData() {
        try {
            api.get('api/roles/permissions').then((res) => {
                const permissions = res.data;
                permissions.sort((a, b) => {
                    let fa = a.name,
                        fb = b.name;
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({
                    allPermissions: res.data,
                });
            });
        } catch (error) {
            console.error(error);
        }
    }

    fetchUserData() {
        try {
            api.get('/api/users').then((res) => {
                const users = res.data;

                users.sort((a, b) => +a.isDisabled - +b.isDisabled || a.username.localeCompare(b.username));

                this.setState({
                    allUsers: users,
                });
            });
        } catch (err) {
            console.error('ERROR: ', err);
        }
    }

    openModal(item, mode) {
        this.setState({
            isModalOpen: true,
            roleTitle: item.title,
            editItem: item,
            mode: mode,
            filteredPermissions: this.state.allPermissions.filter((p) => {
                return !item.permissions.some((existingPermission) => existingPermission?.name === p?.name);
            }),
            filteredUsers: this.state.allUsers.filter((u) => {
                return !item.applicationUsers.some((existingUser) => existingUser?.id === u.Id);
            }),
        });
    }

    closeModal() {
        this.setState({
            isModalOpen: false,
            addedPermissions: [],
            removedPermissions: [],
            addedUsers: [],
            removedUsers: [],
            currentTab: 'general',
        });
    }

    confirmDelete(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete role</h1>
                        <p>
                            You are about to delete this role
                            <br />
                        </p>
                        <span>
                            <b>{item.title}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteRole(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    addPermissionToRole(permission) {
        this.setState({
            addedPermissions: [...this.state.addedPermissions, permission],
        });
    }

    revertAddPermissionToRole(permission) {
        this.setState({
            addedPermissions: [...this.state.addedPermissions].filter((p) => p.id !== permission.id),
        });
    }

    removePermissionFromRole(permission) {
        this.setState({
            removedPermissions: [...this.state.removedPermissions, permission],
        });
    }

    revertRemovePermissionFromRole(permission) {
        this.setState({
            removedPermissions: [...this.state.removedPermissions].filter((p) => p.id !== permission.id),
        });
    }

    addUserToRole(user) {
        this.setState({
            addedUsers: [...this.state.addedUsers, user],
        });
    }

    revertAddUserToRole(user) {
        this.setState({
            addedUsers: [...this.state.addedUsers].filter((u) => u.id !== user.id),
        });
    }

    removeUserFromRole(user) {
        this.setState({
            removedUsers: [...this.state.removedUsers, user],
        });
    }

    revertRemoveUserFromRole(user) {
        this.setState({
            removedUsers: [...this.state.removedUsers].filter((u) => u.id !== user.id),
        });
    }

    async createRole() {
        this.setState({ loading: true });
        return await api
            .post(`api/roles`, { title: this.state.roleTitle, permissions: this.state.addedPermissions })
            .then((result) => {
                this.setState({ loading: false });
                return result;
            });
    }

    async editRole() {
        await api.put(`api/roles`, {
            role: this.state.editItem,
            permissionsToAdd: this.state.addedPermissions,
            permissionsToRemove: this.state.removedPermissions,
            newTitle: this.state.roleTitle,
        });
    }

    deleteRole(item) {
        api.delete('api/roles/' + item.id)
            .then((res) => {
                if (res.status === 200) {
                    NotificationManager.success('Role deleted', 'Role successfully deleted ' + item.title, 5000);
                    return res.data;
                } else {
                    NotificationManager.error('Role not deleted', 'Error deleting Role ' + item.title, 5000);
                    throw new Error(res.status);
                }
            })
            .finally(() => {
                this.fetchRoleData();
            });
    }

    async attachUsersToRole(roleId) {
        if (this.state.addedUsers.length === 0) return;
        const userRoles = this.state.addedUsers.map((user) => {
            return {
                UserId: user.id,
                RoleId: roleId ? roleId : this.state.editItem.id,
            };
        });
        try {
            await api.post(`api/roles/attach`, userRoles);
        } catch (error) {
            console.debug(error);
        }
    }

    async detachUsersFromRole(roleId) {
        if (this.state.removedUsers.length === 0) return;

        const userRoles = this.state.removedUsers.map((user) => {
            return {
                UserId: user.id,
                RoleId: roleId ? roleId : this.state.editItem.id,
            };
        });
        try {
            await api.post(`api/roles/detach`, userRoles);
        } catch (error) {
            console.debug(error);
        }
    }

    async saveRole() {
        if (this.state.mode === 'edit') {
            await this.editRole();
            await this.attachUsersToRole();
            await this.detachUsersFromRole();
            this.fetchRoleData();
            this.closeModal();
        } else {
            this.createRole().then(async (result) => {
                await this.attachUsersToRole(result.data);
                await this.detachUsersFromRole(result.data);
                this.fetchRoleData();
                this.closeModal();
            });
        }
    }

    renderModalContent() {
        if (this.state.currentTab === 'general') {
            return (
                <div className="admin-role-modal-content">
                    <label htmlFor="roleTitle">Role name*:&nbsp;</label>
                    <input
                        placeholder="Title of role"
                        name="roleTitle"
                        value={this.state?.roleTitle}
                        minLength={1}
                        onChange={(e) => this.setState({ roleTitle: e.target.value })}
                        required
                        className="input-control"
                        type="text"
                    />
                    <div className="admin-role-modal-permissions">
                        {this.state.editItem.permissions.map((permission) => {
                            return (
                                <div key={permission?.name} className="admin-role-modal-items">
                                    <div className="admin-role-modal-item">
                                        <div>{permission?.name}</div>
                                        {this.renderPermissionButton(true, permission)}
                                    </div>
                                </div>
                            );
                        })}
                        {this.state?.filteredPermissions.map((permission) => {
                            return (
                                <div key={permission?.name} className="admin-role-modal-items">
                                    <div className="admin-role-modal-item">
                                        <div>{permission?.name}</div>
                                        {this.renderPermissionButton(false, permission)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        } else if (this.state.currentTab === 'users') {
            return (
                <div className="admin-role-modal-content">
                    <div className="admin-role-modal-users">
                        {this.state.editItem.applicationUsers.map((user) => {
                            return (
                                <div key={user?.Id} className="admin-role-modal-items">
                                    <div className="admin-role-modal-item">
                                        <div>{user?.displayname ? user?.displayname : user?.username}</div>
                                        {this.renderUserButton(true, user)}
                                    </div>
                                </div>
                            );
                        })}
                        {this.state?.filteredUsers.map((user) => {
                            return (
                                <div key={user.Id} className="admin-role-modal-items">
                                    <div className="admin-role-modal-item">
                                        <div>{user?.displayname ? user?.displayname : user?.username}</div>
                                        {this.renderUserButton(false, user)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    }

    renderPermissionButton(existingPermission, permission) {
        if (existingPermission && !this.state.removedPermissions.some((p) => p.id === permission.id)) {
            return (
                <button
                    className="btn btn-yellow admin-role-modal-permission-item-button"
                    onClick={() => this.removePermissionFromRole(permission)}
                >
                    Detach
                </button>
            );
        } else if (existingPermission) {
            return (
                <button
                    className="btn btn-blue admin-role-modal-permission-item-button"
                    onClick={() => this.revertRemovePermissionFromRole(permission)}
                >
                    Attach
                </button>
            );
        } else if (this.state.addedPermissions.some((p) => p.id === permission.id)) {
            return (
                <button
                    className="btn btn-yellow admin-role-modal-permission-item-button"
                    onClick={() => this.revertAddPermissionToRole(permission)}
                >
                    Detach
                </button>
            );
        } else {
            return (
                <button
                    className="btn btn-blue admin-role-modal-permission-item-button"
                    onClick={() => this.addPermissionToRole(permission)}
                >
                    Attach
                </button>
            );
        }
    }

    renderUserButton(existingUser, user) {
        if (existingUser && !this.state.removedUsers.some((p) => p.id === user.id)) {
            return (
                <button
                    className="btn btn-yellow admin-role-modal-item-button"
                    onClick={() => this.removeUserFromRole(user)}
                >
                    Detach
                </button>
            );
        } else if (existingUser) {
            return (
                <button
                    className="btn btn-blue admin-role-modal-item-button"
                    onClick={() => this.revertRemoveUserFromRole(user)}
                >
                    Attach
                </button>
            );
        } else if (this.state.addedUsers.some((u) => u.id === user.id)) {
            return (
                <button
                    className="btn btn-yellow admin-role-modal-item-button"
                    onClick={() => this.revertAddUserToRole(user)}
                >
                    Detach
                </button>
            );
        } else {
            return (
                <button className="btn btn-blue admin-role-modal-item-button" onClick={() => this.addUserToRole(user)}>
                    Attach
                </button>
            );
        }
    }

    render() {
        const roles = this.state.data || [];
        const visibleColumns = this.state.visibleColumns;
        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} style={modalStyle} ariaHideApp={false}>
                    <div className="admin-role-modal-tabs">
                        {this.state.modalTabs.map((tab) => {
                            return (
                                <div
                                    key={tab.name + tab.value}
                                    onClick={() => this.setState({ currentTab: tab.value })}
                                    className={
                                        'admin-role-modal-tab ' +
                                        (this.state.currentTab === tab.value ? 'admin-role-modal-tab--active' : '')
                                    }
                                >
                                    <p>{tab.name}</p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="admin-role-modal">{this.renderModalContent()}</div>
                    <div className="admin-role-modal-bottom">
                        <div className="admin-role-modal-bottom-buttons">
                            <button
                                style={{ marginRight: '30px' }}
                                className="btn btn-yellow btn-grow"
                                onClick={this.closeModal}
                            >
                                Cancel
                            </button>
                            <button className="btn btn-blue btn-grow" onClick={this.saveRole}>
                                {this.state.mode === 'edit' ? 'Save' : 'Create'}
                            </button>
                        </div>
                    </div>
                </Modal>

                <div className="main-header">
                    <span>
                        <h2>{roles ? roles.length : 0} Roles</h2>
                        <span
                            className="main-header-action"
                            onClick={() =>
                                this.openModal({ title: '', permissions: [], applicationUsers: [] }, 'create')
                            }
                        >
                            <span className="list-expand-toggle">+ New role</span>
                        </span>
                    </span>
                </div>
                <GenericTable
                    loading={this.state.loading}
                    triggerFetch={this.state.fetchData}
                    localPaging={true}
                    enableGlobalFilter={true}
                    columns={visibleColumns}
                    data={roles}
                    pageCount={this.state.pages}
                    fetchData={this.fetchRoleData}
                />
            </div>
        );
    }
}

export default AdminRole;
