import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import '../widget.css';
import './HeatMap.scss';
import moment from 'moment';

import ReactApexChart from 'react-apexcharts';
import { NotificationManager } from 'react-notifications';

import CustomDatePicker from '../../common/CustomDatePicker/CustomDatePicker';
import Spinner from '../../common/Spinner/Spinner';
import api from '../../../utility/api';

import { getDefaultTicksAndPeriod, getFromTimeBasedOnPeriodAndToTime } from '../widgetHelper';

class HeatMap extends React.Component {
    constructor(props) {
        super(props);

        const defaultPeriod = this.props.widget.defaultPeriod || 'day';
        let { ticks, period } = getDefaultTicksAndPeriod(defaultPeriod);

        this.state = {
            loadingData: true,
            endDate: null,
            period: {
                period: period,
                interval: defaultPeriod,
                ticks: ticks,
            },
            series: [],
            options: {
                chart: {
                    animations: {
                        enabled: false,
                        animateGradually: { enabled: false },
                        dynamicAnimation: { enabled: false },
                    },
                    type: 'heatmap',
                    toolbar: { show: false },
                    selection: { enabled: false },
                    background: 'transparent',
                },
                dataLabels: { enabled: false },
                grid: { enabled: false },
                theme: {
                    mode: 'dark',
                    monochrome: {
                        enabled: true,
                        color: '#41CDF5',
                        shadeTo: 'dark',
                        shadeIntensity: 0.65,
                    },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    reversed: true,
                },
                tooltip: {
                    // eslint-disable-next-line no-unused-vars
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        let raw_index = dataPointIndex;
                        if (w.config.custom.raw_series[seriesIndex].data.length > w.config.custom.maxChartColumns)
                            raw_index =
                                dataPointIndex *
                                Math.ceil(
                                    w.config.custom.raw_series[seriesIndex].data.length /
                                        w.config.custom.maxChartColumns
                                );

                        return (
                            '<div class="tooltip-box">' +
                            '<div class="tooltip-date">' +
                            moment(w.globals.initialSeries[seriesIndex].data[dataPointIndex].x).format(
                                'DD.MM.YYYY HH:mm'
                            ) +
                            '</div>' +
                            '<div class="tooltip-label">' +
                            w.config.custom.name +
                            ' - ' +
                            w.globals.initialSeries[seriesIndex].name +
                            '</div>' +
                            '<div class="tooltip-value">' +
                            '<div class="tooltipvalue">' +
                            (w.config.custom.raw_series[seriesIndex].data[raw_index].y == null
                                ? 'No data'
                                : w.config.custom.raw_series[seriesIndex].data[raw_index].y) +
                            '</div>' +
                            '<div class="tooltipunit">' +
                            (w.config.custom.raw_series[seriesIndex].data[raw_index].y == null
                                ? ''
                                : w.config.custom.unit) +
                            '</div>' +
                            '</div>' +
                            '</div>'
                        );
                    },
                },
                custom: {
                    name: this.props.widget.componentSettings[0].name,
                    unit: this.props.widget.componentSettings[0].unit,
                    raw_series: [],
                    maxChartColumns: Infinity,
                },
            },
            sizing: {
                chartWidth: 0,
                minPixelPerTile: 4,
                maxChartColumns: Infinity,
            },
        };

        this.mounted = true;
    }

    getChartInfo = (callback) => {
        let to =
            this.state.period.interval === 'day'
                ? moment(this.state.endDate)
                : moment(this.state.endDate).minute(0).second(0).millisecond(0).subtract(1, 'second');
        if (!this.state.endDate) {
            to =
                this.state.period.interval === 'day' || this.state.period.interval === 'hour'
                    ? moment().utc()
                    : moment().hour(0).minute(0).second(0).millisecond(0).utc().subtract(1, 'second');
        }
        to = this.props.job ? moment(this.props.job.endDate).local() : to;

        let from = getFromTimeBasedOnPeriodAndToTime(to, this.state.period);
        from = this.props.job ? moment(this.props.job.startDate).local() : from;

        const daterange = '&from=' + from.toISOString() + '&to=' + to.toISOString();

        const apiUrl =
            process.env.REACT_APP_API +
            '/api/kpi/heatmap?calculation=' +
            (this.props.calculation || '') +
            daterange +
            '&rangeformat=' +
            this.state.period.ticks +
            '&valueFormat=' +
            this.props.widget.valueFormat;

        const data = new FormData();
        data.append('componentSettings', encodeURIComponent(JSON.stringify(this.props.widget.componentSettings)));

        api.post(apiUrl, data)
            .then((res) => {
                return callback(res.data);
            })
            .catch((err) => {
                console.error(err);
                this.setState({ loading: false });
            });
    };

    setChartData(res) {
        const total = res.series.length;
        const factor = Math.round(total / 10);

        const filtered_series = JSON.parse(JSON.stringify(res.series));

        filtered_series.forEach((serie) => {
            serie.data.forEach((point) => {
                if (point.y === null) point.y = this.props.widget.configuration.minValue;
                if (point.y < this.props.widget.configuration.minValue)
                    point.y = this.props.widget.configuration.minValue;
                if (point.y > this.props.widget.configuration.maxValue)
                    point.y = this.props.widget.configuration.maxValue;

                point.y = point.y - this.props.widget.configuration.minValue;
            });
        });

        this.setState((prevState) => ({
            series: filtered_series,
            loadingData: false,
            options: {
                ...prevState.options,
                yaxis: {
                    ...prevState.options.yaxis,
                    labels: {
                        formatter: function (val, index) {
                            if (Number.isInteger(index)) return (total - index - 1) % factor === 0 ? val : '';
                            return val;
                        },
                    },
                },
                custom: {
                    ...prevState.options.custom,
                    raw_series: res.series,
                },
            },
        }));
    }

    calculateSizing() {
        const chartWidth =
            ReactDOM.findDOMNode(this).getElementsByClassName('widget-content')[0].getBoundingClientRect().width - 90;

        if (chartWidth > 0 && chartWidth !== this.state.sizing.chartWidth) {
            this.setState((prevState) => ({
                sizing: {
                    ...prevState.sizing,
                    chartWidth: chartWidth,
                    maxChartColumns: Math.round(chartWidth / this.state.sizing.minPixelPerTile),
                },
                options: {
                    ...prevState.options,
                    custom: {
                        ...prevState.options.custom,
                        maxChartColumns: Math.round(chartWidth / this.state.sizing.minPixelPerTile),
                    },
                },
            }));

            this.notificationFlag = true;
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.getChartInfo((res) => {
                if (this.mounted) this.setChartData(res);
            });
        }
    }

    componentDidMount() {
        this.updateSizingInterval = setInterval(() => {
            this.calculateSizing();
        }, 1000);

        if (!this.props.data) {
            this.getChartInfo((res) => {
                if (this.mounted) this.setChartData(res);
            });

            this.interval = setInterval(() => {
                this.getChartInfo((res) => {
                    if (this.mounted) this.setChartData(res);
                });
            }, 120000);
        }
    }

    componentWillUnmount() {
        this.mounted = false;

        if (this.updateSizingInterval) clearInterval(this.updateSizingInterval);

        if (this.interval) clearInterval(this.interval);
    }

    /// Dropdowns
    openDropdown = () => {
        this.setState({ showDropdownOnParent: !this.state.showDropdownOnParent });
    };

    openPeriodDropdown = () => {
        this.setState({ showPeriodDropdownOnParent: !this.state.showPeriodDropdownOnParent });
    };

    daterange(val, period, parent, row, cancel) {
        if (!cancel) {
            if (period) {
                this.setState({ period: val });
            } else {
                this.setState({ endDate: val });
            }
        }

        this.setState(
            { loadingData: true, showDropdownOnParent: false, showPeriodDropdownOnParent: false },
            function () {
                this.getChartInfo((res) => {
                    if (this.mounted) this.setChartData(res);
                });
            }
        );
    }

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    trim_state_series() {
        if (this.state.series.length === 0) return [];

        const series = JSON.parse(JSON.stringify(this.state.series));

        const currentPoints = series[0].data.length;

        if (currentPoints > this.state.sizing.maxChartColumns) {
            const factor = Math.ceil(currentPoints / this.state.sizing.maxChartColumns);

            for (let i = 0; i < series.length; ++i) {
                series[i].data = series[i].data.filter((_, j) => j % factor === 0);
            }

            if (this.notificationFlag) {
                NotificationManager.warning(this.props.widget.title + ' data trimmed', +'Heatmap data trimmed');
                this.notificationFlag = false;
            }
        }

        return series;
    }

    render() {
        return (
            <div className={'widget'}>
                <div className="data-title">
                    <span className="data-title__label">{this.props.widget.title}</span>
                </div>
                {!this.props.job ? (
                    <div className={'date-wrapper parent ' + (this.check() ? '' : ' mobile')}>
                        <div className="date-picker-button" onClick={() => this.openPeriodDropdown(true)}>
                            <div className="summary-value">Period:</div>
                            <div className="dates-value">{this.state.period ? this.state.period.interval : ''}</div>
                        </div>
                        {this.state.showPeriodDropdownOnParent ? (
                            <CustomDatePicker period={true} daterange={(val) => this.daterange(val, true, true)} />
                        ) : (
                            <div></div>
                        )}
                        <div className="date-picker-button" onClick={() => this.openDropdown(true)}>
                            <div className="summary-value">End time:</div>
                            <div className="dates-value">
                                {this.state.endDate ? moment(this.state.endDate).format('DD.MM.YYYY HH:mm') : 'NOW'}
                            </div>
                        </div>
                        {this.state.showDropdownOnParent ? (
                            <CustomDatePicker
                                mobile={!this.check()}
                                selDate={this.state.endDate}
                                daterange={(val, a, b, c, cancel) => this.daterange(val, false, true, null, cancel)}
                            />
                        ) : (
                            <div></div>
                        )}
                    </div>
                ) : null}
                {this.state.loadingData ? (
                    <div className="loading-data">
                        <Spinner text="new data.." />
                    </div>
                ) : null}
                <div className="widget-content" height="250px">
                    {!this.state.loadingData ? (
                        this.state.series.length === 0 ? (
                            <div className="no-data-message">No data available</div>
                        ) : (
                            <ReactApexChart
                                options={this.state.options}
                                series={this.trim_state_series()}
                                type="heatmap"
                                width="100%"
                                height="250"
                            />
                        )
                    ) : null}
                </div>
            </div>
        );
    }
}

HeatMap.propTypes = {
    itemId: PropTypes.string,
};

export default HeatMap;
