import {
    SET_NOTIFICATIONLIST,
    CLEAR_NOTIFICATIONLIST,
    SET_USER_NOTIFICATION_SETTINGS,
    SET_NOTIFICATION_TYPES,
} from '../actions';

const notifications = (
    state = {
        notificationList: undefined,
        notificationTypes: undefined,
        userNotificationSettings: {
            notificationTypeSettings: [],
        },
    },
    action
) => {
    switch (action.type) {
        case SET_NOTIFICATIONLIST:
            return {
                ...state,
                notificationList: action.data,
            };
        case CLEAR_NOTIFICATIONLIST:
            return {
                ...state,
                notificationList: undefined,
            };
        case SET_NOTIFICATION_TYPES:
            return {
                ...state,
                notificationTypes: action.data,
            };
        case SET_USER_NOTIFICATION_SETTINGS:
            return {
                ...state,
                userNotificationSettings: action.data ?? {
                    notificationTypeSettings: [],
                },
            };
        default:
            return state;
    }
};

export default notifications;
