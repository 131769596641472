import React from 'react';
import PropTypes from 'prop-types';

const AccModuleChecker = (component, user, moduleName) => {
    const impersonateModules = sessionStorage.getItem('userImpersonateModules');
    const modules = user.profile.AccountModules;
    if (
        !modules ||
        modules.indexOf(moduleName) === -1 ||
        (impersonateModules && impersonateModules.indexOf(moduleName) === -1)
    ) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h2>No access</h2>
                <p>Your account does not have access to this. Contact Optimar for further information.</p>
            </div>
        );
    }
    return component;
};

export default AccModuleChecker;

export const AccessModuleChecker = ({ children, user, moduleName }) => {
    return AccModuleChecker(children, user, moduleName);
};

AccessModuleChecker.propTypes = {
    children: PropTypes.node.isRequired,
    moduleName: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
};
