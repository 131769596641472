import React from 'react';
import './AdminPlantDevice.scss';
import api from '../../../utility/api';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import Modal from 'react-modal';
import SimpleSpinner from '../Spinner/SimpleSpinner';
import { DeviceVersioning } from '../../../utility/IoT/deviceVersioning';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminPlantDevice extends React.Component {
    constructor(props) {
        super(props);
        // always use state when creating or modifying variables in react
        this.state = {
            devices: [],
            selectedDevice: null,
            syncingDeviceTwin: false,
            fetchingPlantVersions: true,
            dockerImageVersion: 'N/A',
            edgeServiceVersion: 'N/A',
            isLatestCommanderEdgeDockerVersion: false,
            isLatestCommanderEdgeVersion: false,
            latestUpdateEvent: '',
            deviceVersioningUtility: new DeviceVersioning(),
            updateIntervalMessages: null,
            updateIntervalVersions: null,
        };

        this.createPlantDevice = this.createPlantDevice.bind(this);
        this.handleDeviceChange = this.handleDeviceChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (this.props.plant) {
            this.fetchDeviceInfo();
            this.fetchVersioningInfo();

            this.setState({
                updateIntervalMessages: setInterval(() => {
                    this.updateLatestMessage();
                }, 10000),
            });

            this.setState({
                updateIntervalVersions: setInterval(() => {
                    this.updateVersions();
                }, 30000),
            });
        }
    }

    componentWillUnmount() {
        if (this.state.updateIntervalMessages) clearInterval(this.state.updateIntervalMessages);

        if (this.state.updateIntervalVersions) clearInterval(this.state.updateIntervalVersions);
    }

    async fetchVersioningInfo() {
        // Everything marked as async will always return a Promise and can be awaited
        // since upgrade to net6.0, plantversions return plantkeys as lowercase.
        const plantKey = this.props.plant?.topicKey?.toLowerCase();

        if (!plantKey) return;

        const plantVersionsPromise = this.updateVersions(); //this.state.deviceVersioningUtility.fetchPlantVersion(plantKey);
        const latestUpdateEventPromise = this.updateLatestMessage(); //this.state.deviceVersioningUtility.fetchLatestUpdateEvent(plantKey);

        try {
            //plantversions updates state by itself.
            await plantVersionsPromise;
            //latestupdateevntresult also updates state..
            await latestUpdateEventPromise;

            // var dockerImageVersion = plantVersionsResult ? plantVersionsResult["version Commander Edge"] : "N/A";
            // var edgeServiceVersion = plantVersionsResult ? plantVersionsResult["version Service"] : "N/A";

            // var isLatestCommanderEdgeDockerVersion = await this.state.deviceVersioningUtility.isLatestCommanderEdgeDockerVersion(dockerImageVersion);
            // var isLatestCommanderEdgeVersion = await this.state.deviceVersioningUtility.isLatestCommanderEdgeVersion(edgeServiceVersion);

            this.setState({
                fetchingPlantVersions: false,
                //latestUpdateEvent: latestUpdateEventResult,
                // dockerImageVersion: dockerImageVersion,
                // edgeServiceVersion: edgeServiceVersion,
                // isLatestCommanderEdgeDockerVersion:isLatestCommanderEdgeDockerVersion,
                // isLatestCommanderEdgeVersion:isLatestCommanderEdgeVersion
            });
        } catch (err) {
            console.log(err);
        }
    }

    async updateVersions() {
        // since upgrade to net6.0, plantversions return plantkeys as lowercase.
        const plantKey = this.props.plant?.topicKey?.toLowerCase();

        if (!plantKey) return;

        try {
            const plantVersionsResult = await this.state.deviceVersioningUtility.fetchPlantVersion(plantKey, true);
            const dockerImageVersion = plantVersionsResult ? plantVersionsResult['version Commander Edge'] : 'N/A';
            const edgeServiceVersion = plantVersionsResult ? plantVersionsResult['version Service'] : 'N/A';

            const isLatestCommanderEdgeDockerVersion =
                await this.state.deviceVersioningUtility.isLatestCommanderEdgeDockerVersion(dockerImageVersion);
            const isLatestCommanderEdgeVersion = await this.state.deviceVersioningUtility.isLatestCommanderEdgeVersion(
                edgeServiceVersion
            );

            this.setState({
                dockerImageVersion: dockerImageVersion,
                edgeServiceVersion: edgeServiceVersion,
                isLatestCommanderEdgeDockerVersion: isLatestCommanderEdgeDockerVersion,
                isLatestCommanderEdgeVersion: isLatestCommanderEdgeVersion,
            });
        } catch (err) {
            console.log(err);
        }
    }

    async updateLatestMessage() {
        const plantKey = this.props.plant?.topicKey;

        if (!plantKey) return;

        try {
            const latestUpdateEvent = await this.state.deviceVersioningUtility.fetchLatestUpdateEvent(plantKey);

            if (latestUpdateEvent !== this.state.latestUpdateEvent) {
                this.setState({
                    latestUpdateEvent: latestUpdateEvent,
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    async fetchDeviceInfo() {
        try {
            const res = await api.get(
                process.env.REACT_APP_API + '/api/admin/plants/' + this.props.plant.id + '/device'
            );
            this.setState({ device: res.data });
            // .then(res => this.setState({ device: res.data }))
            // .catch(err => {
            //     console.error("ERROR: ", err);
            // });
        } catch (err) {
            console.error('ERROR: ', err);
        }
    }

    fetchDevicesWithoutPlant() {
        api.get(process.env.REACT_APP_API + '/api/admin/iotdevice')
            .then((res) => {
                //console.log("RES", res);
                this.setState({
                    devices: res.data?.filter((x) => x.connectionState === 'Connected'),
                });
            })
            .catch(() => {
                NotificationManager.error('Failed to fetch', 'Could not fetch items');
            });
    }

    createPlantDevice() {
        alert('Use the Gateways tab to connect existing gateways');
        //todo: implement search existing IoT devices the same way as "change device"

        //if (this.props.plant) {
        // api.post(process.env.REACT_APP_API + "/api/admin/plants/device/" + this.props.plant.id + "/" + this.props.plant.topicKey)
        //     .then(res => this.setState({ device: res.data }))
        //     .catch(err => {
        //         console.error("ERROR: ", err);
        //     });
        //}
    }

    handleDeviceChange(event) {
        const value = event.target.value;
        this.setState({
            selectedDevice: value,
        });
    }

    confirmConnectDevice(event, item) {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Connect gateway</h1>
                        <p>
                            You are about to connect {this.props.plant.name} with
                            <br />{' '}
                        </p>
                        <span>
                            <b>{this.state.selectedDevice}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-blue"
                                onClick={() => {
                                    this.connectDevice(item);
                                    onClose();
                                }}
                            >
                                Yes, connect
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    async connectDevice(_item) {
        if (this.state.selectedDevice != null) {
            try {
                const response = await api.post(
                    process.env.REACT_APP_API +
                        '/api/admin/iotdevice/connect/' +
                        this.state.selectedDevice +
                        '/' +
                        this.props.plant.topicKey,
                    {}
                );
                // .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    NotificationManager.success('Gateway successfully connected', 'Gateway connected', 5000);
                    this.closeModal();
                    // if(this.props.closeModal)
                    //     this.props.closeModal();
                } else {
                    console.error(response);
                    NotificationManager.error('Gateway not connected', 'Error connecting gateway', 5000);
                }
                //}).catch(err => {
                //});
            } catch (err) {
                console.error(err);
                NotificationManager.error('Gateway not connected', 'Error connecting gateway', 5000);
            }
        }
    }

    async syncDeviceTwin(_item) {
        this.setState({
            syncingDeviceTwin: true,
        });
        const errMsg = 'Configuration sync failed';
        try {
            const response = await api.post(
                process.env.REACT_APP_API + '/api/admin/iotdevice/syncdevicetwin?plantId=' + this.props.plant.id,
                {}
            );
            if (response.status === 200 || response.status === 204) {
                NotificationManager.success('Configuration synced successfully', 'Configuration', 5000);
            } else {
                console.error(response);
                NotificationManager.error(errMsg, 'Configuration', 5000);
            }
        } catch (err) {
            console.error(err);
            NotificationManager.error(errMsg, 'Configuration', 5000);
        }
        this.setState({
            syncingDeviceTwin: false,
        });
    }

    openModal(item, _mode) {
        this.fetchDevicesWithoutPlant();

        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
            },
            function () {
                this.fetchDeviceInfo();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
            },
            function () {
                this.fetchDeviceInfo();
            }
        );
    }

    openAzure(res) {
        window.open(
            `https://portal.azure.com/#blade/Microsoft_Azure_IotHub/StandaloneFrameBlade/path/%2Fdevices%2Fdevice%3FdeviceId%3D${res.deviceId}/title/${res.deviceId}/resourceId/%2Fsubscriptions%2Fdf67ca81-e44c-46b2-a669-648d7108f44b%2FresourceGroups%2F${process.env.REACT_APP_IOT_HUB_RG}%2Fproviders%2FMicrosoft.Devices%2FIotHubs%2F${process.env.REACT_APP_IOT_HUB}`,
            '_blank'
        );
    }

    render() {
        const res = this.state.device;

        const syncDeviceTwinLabel = this.state.syncingDeviceTwin ? 'Syncing configuration...' : 'Sync configuration';

        const dockerImageVersion = this.state.fetchingPlantVersions ? (
            <div>
                <SimpleSpinner size={'1x'}></SimpleSpinner>
            </div>
        ) : (
            this.state.dockerImageVersion
        );
        const edgeServiceVersion = this.state.fetchingPlantVersions ? (
            <div>
                <SimpleSpinner size={'1x'}></SimpleSpinner>
            </div>
        ) : (
            this.state.edgeServiceVersion
        );

        return (
            <div className="device-wrapper">
                <div className="plant-table">
                    {res ? (
                        <>
                            <div className="table-row">
                                <span className="label">ID</span>
                                <span className="value">{res.deviceId}</span>
                            </div>
                            <div className="table-row">
                                <span className="label">Status</span>
                                <span className={'value ' + (res.connectionState === 'Connected' ? 'green' : 'red')}>
                                    {res.connectionState}
                                </span>
                            </div>
                            <div className="table-row">
                                <span className="label">Last activity</span>
                                <span className="value">
                                    {res.lastActivityTime ? format(new Date(res.lastActivityTime), 'dd.MM.yyyy') : null}
                                </span>
                            </div>
                            <div className="table-row">
                                <span className="label">Docker image version</span>
                                <span
                                    className={
                                        'value' +
                                        (this.state.isLatestCommanderEdgeDockerVersion ||
                                        this.state.fetchingPlantVersions
                                            ? ''
                                            : ' red')
                                    }
                                >
                                    {dockerImageVersion}
                                </span>
                            </div>
                            <div className="table-row">
                                <span className="label">Edge service version</span>
                                <span
                                    className={
                                        'value' +
                                        (this.state.isLatestCommanderEdgeVersion || this.state.fetchingPlantVersions
                                            ? ''
                                            : ' red')
                                    }
                                >
                                    {edgeServiceVersion}
                                </span>
                            </div>
                            <div className="table-row">
                                <span className="label">Latest update message</span>
                                <span className="value">{this.state.latestUpdateEvent}</span>
                            </div>
                            <div className="buttons">
                                <div className="btn btn-blue" onClick={() => this.openModal(res)}>
                                    Change gateway
                                </div>
                                <div className="btn btn-blue" onClick={() => this.syncDeviceTwin(res)}>
                                    {syncDeviceTwinLabel}
                                </div>
                                <div className="btn btn-blue" onClick={() => this.openAzure(res)}>
                                    Open IoT Device page
                                </div>
                                <div
                                    className="btn btn-blue"
                                    onClick={() => this.state.deviceVersioningUtility.confirmUpdateDevice(res)}
                                >
                                    Update Device
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2>No device configured</h2>
                            <div className="btn-row">
                                <div className="btn btn-blue" onClick={() => this.createPlantDevice()}>
                                    Create gateway
                                </div>
                            </div>
                        </>
                    )}
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div>
                            <div className="admin-plant-items-add-edit-header">
                                <span>Select gateway</span>
                            </div>
                            <form className="admin-plant-items-add-edit-form">
                                {this.state.devices ? (
                                    <select
                                        className="admin-form-select"
                                        name="data-type"
                                        onChange={this.handleDeviceChange}
                                    >
                                        {this.state.devices.map((dt, i) => {
                                            return (
                                                <option key={'it' + i} id={i} value={dt.deviceId}>
                                                    {dt.deviceId}
                                                </option>
                                            );
                                        })}
                                    </select>
                                ) : null}
                                <div className="btn-row btn-row-grow">
                                    <button
                                        className="btn btn-yellow btn-grow"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.closeModal();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-grow btn-blue"
                                        onClick={(event) => this.confirmConnectDevice(event, this.state.editItem)}
                                    >
                                        Connect
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        plant: state.plants.currentAdminPlant,
    };
};

export default connect(mapStateToProps)(AdminPlantDevice);
