import { setNotificationList } from './../actions';
import { JsonHubProtocol, HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { NotificationManager } from 'react-notifications';
import api from '../utility/api';
import { SET_CURRENT_USER } from '../actions/';
import { getNotificationMessage } from '../utility/notificationHelper';
import { toCamel } from '../utility/objectHelper';

export const signalRMiddleware = (store) => (next) => async (action) => {
    if (action.type === SET_CURRENT_USER) {
        const protocol = new JsonHubProtocol();

        const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

        const options = {
            transport,
            logMessageContent: false,
            accessTokenFactory: () => action.data.access_token,
        };

        const connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API + '/notify', options)
            .withHubProtocol(protocol)
            .build();

        connection.on('info', (res) => onNotify(res, store));

        connection.onclose(() => setTimeout(startSignalRConnection(connection), 5000));

        startSignalRConnection(connection, store);
    }
    return next(action);
};

const startSignalRConnection = (connection, store) => {
    console.log('connection', connection);
    connection
        .start()
        .then(() => console.info('SignalR Connected'))
        .catch((err) => console.error('SignalR Connection Error: ', err))
        .finally(() => fetchNotifications(store));
};

const onNotify = (res, store) => {
    res = toCamel(JSON.parse(res));

    const msg = getNotificationMessage(res);

    NotificationManager.info(msg, 'Notification', 5000);
    fetchNotifications(store);
};

function fetchNotifications(store) {
    api.get(process.env.REACT_APP_API + '/api/userNotifications')
        .then((res) => {
            if (store) store.dispatch(setNotificationList(res.data));
        })
        .catch((err) => {
            console.error('ERROR: ', err);
        });
}
