export const SET_CURRENT_PLANT = 'SET_CURRENT_PLANT';
export const CLEAR_CURRENT_PLANT = 'CLEAR_CURRENT_PLANT';

export const SET_CURRENT_ADMIN_PLANT = 'SET_CURRENT_ADMIN_PLANT';
export const CLEAR_CURRENT_ADMIN_PLANT = 'CLEAR_CURRENT_ADMIN_PLANT';

export const SET_CURRENT_ADMIN_PLANT_ITEMS = 'SET_CURRENT_ADMIN_PLANT_ITEMS';
export const CLEAR_CURRENT_ADMIN_PLANT_ITEMS = 'CLEAR_CURRENT_ADMIN_PLANT_ITEMS';

export const SET_PLANTLIST = 'SET_PLANTLIST';

export const setCurrentPlant = (plant) => {
    return {
        type: SET_CURRENT_PLANT,
        data: plant,
    };
};

export const clearCurrentPlant = () => {
    return {
        type: CLEAR_CURRENT_PLANT,
        data: null,
    };
};

export const setCurrentAdminPlant = (plant) => {
    return {
        type: SET_CURRENT_ADMIN_PLANT,
        data: plant,
    };
};

export const clearCurrentAdminPlant = () => {
    return {
        type: CLEAR_CURRENT_ADMIN_PLANT,
        data: null,
    };
};

export const setCurrentAdminPlantItems = (items) => {
    return {
        type: SET_CURRENT_ADMIN_PLANT_ITEMS,
        data: items,
    };
};

export const clearCurrentAdminPlantItems = () => {
    return {
        type: CLEAR_CURRENT_ADMIN_PLANT_ITEMS,
        data: null,
    };
};

export const setPlantList = (plants) => {
    return {
        type: SET_PLANTLIST,
        data: plants,
    };
};
