import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import { ReactComponent as DuplicateIcon } from '../../../images/list/duplicate.svg';
import Spinner from '../Spinner/Spinner';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../../utility/api';
import { noop } from 'lodash';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        paddingTop: '40px',
        paddingRight: '18px',
        paddingBottom: '40px',
        paddingLeft: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
    },
};

class AdminAccPlantAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.fetching = false;

        this.closeModal = this.closeModal.bind(this);

        this.visibleColumns = [
            { Header: 'Name', accessor: 'plantName', filterable: true, width: 300 },
            {
                Header: '',
                Cell: (item) => {
                    return (
                        <div className="action-container">
                            {item.original.accountPlantState === 1 ? (
                                <div
                                    className="action-element"
                                    alt="Detach plant"
                                    title="Detach plant"
                                    onClick={() => this.confirmDetach(item.original)}
                                >
                                    <DeleteIcon className="action-icon red" />
                                    <span className="list-edit-btn">Detach</span>
                                </div>
                            ) : null}
                            {!item.original.accountPlantState ? (
                                <div
                                    className="action-element"
                                    alt="Attach plant"
                                    title="Attach plant"
                                    onClick={() => this.confirmAttachPlant(item.original)}
                                >
                                    <DuplicateIcon className="action-icon red" />
                                    <span className="list-edit-btn">Attach</span>
                                </div>
                            ) : null}
                        </div>
                    );
                },
                width: 500,
            },
        ];
    }

    componentDidMount() {
        this.setState(
            {
                account: this.props.accountToEdit,
            },
            () => {
                this.fetchAccountPlants();
            }
        );
    }

    confirmDetach(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Detach plant</h1>
                        <p>
                            You are about to detach plant
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.plantName}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.detachPlant(item);
                                    onClose();
                                }}
                            >
                                Yes, detach
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    detachPlant = (itemFromChild) => {
        if (this.props.user != null) {
            api.delete('/api/accountPlants/' + itemFromChild.id)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Plant detached', 'Plant successfully detached', 5000);
                        this.fetchAccountPlants();
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to detach plant', 5000);
                        throw new Error(response.status);
                    }
                })
                .catch(noop);
        }
    };

    confirmAttachPlant(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Attach plant</h1>
                        <p>
                            You are about to attach plant
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.plantName}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.attachPlant(item);
                                    onClose();
                                }}
                            >
                                Yes, attach
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    attachPlant = (itemFromChild) => {
        if (this.props.user != null) {
            const accountPlant = {
                AccountId: this.state.account.id,
                PlantId: itemFromChild.plantId,
                AccountPlantState: 1,
            };
            api.post(process.env.REACT_APP_API + '/api/accountPlants/', accountPlant)
                .then((response) => {
                    if (response.status === 200) {
                        NotificationManager.success('Plant attached', 'Plant successfully attached', 5000);
                        return response.data;
                    } else {
                        NotificationManager.error('Error', 'Failed to attach plant', 5000);
                        throw new Error(response.status);
                    }
                })
                .then((_account) => {
                    this.fetchAccountPlants();
                })
                .catch(noop);
        }
    };

    getDetachLink(plant) {
        return (
            <img
                key={'detachButton' + plant.id}
                className={'list-btn'}
                alt="Detach"
                title="Detach"
                src={DeleteIcon}
                onClick={() => this.confirmDetach(plant)}
            />
        );
    }

    getAttachLink(plant) {
        return (
            <img
                key={'attachButton' + plant.id}
                className={'list-btn'}
                alt="Attach"
                title="Attach"
                src={DuplicateIcon}
                onClick={() => this.confirmAttachPlant(plant)}
            />
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
    }

    fetchAccountPlants() {
        if (this.props.user != null && this.state.account != null) {
            api.get(process.env.REACT_APP_API + '/api/accountplants/account/' + this.state.account.id)
                .then((res) => {
                    this.setState({
                        accountPlants: res.data,
                    });
                    this.fetching = false;
                })
                .catch((err) => {
                    console.error('ERROR: ', err);
                });
        }
    }

    openModal() {
        this.setState({
            modalIsOpen: true,
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
        });
    }

    render() {
        if (!this.state.accountPlants) {
            return <Spinner text="plants" />;
        }

        const plants = this.state.accountPlants.filter((plant) => {
            return plant.accountPlantState === 1;
        });

        const detachedPlants = this.state.accountPlants.filter((plant) => {
            return !plant.accountPlantState;
        });

        return (
            <div>
                <div className="main-header">
                    <span>
                        <h2>{plants ? plants.length : 0} Plants</h2>
                        <span className="main-header-action" onClick={() => this.openModal()}>
                            {' '}
                            <span className="list-expand-toggle">+ Attach plant</span>{' '}
                        </span>
                    </span>
                </div>

                <ReactTable
                    defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                    data={plants}
                    columns={this.visibleColumns}
                    showPagination={false}
                    pageSize={plants.length}
                    minRows={0}
                />

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <ReactTable
                        defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                        data={detachedPlants}
                        columns={this.visibleColumns}
                        showPagination={false}
                        pageSize={detachedPlants.length}
                        minRows={0}
                    />
                    <button style={{ marginBottom: '10px' }} onClick={this.closeModal} className="btn btn-yellow">
                        Close
                    </button>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default connect(mapStateToProps)(AdminAccPlantAddEdit);
