import React from 'react';
import { ConfirmationAlert } from './ConfirmationAlert';

/**
 * @type {React.FC<{
 *     onCancel: React.MouseEventHandler<HTMLButtonElement>;
 *     onConfirm: React.MouseEventHandler<HTMLButtonElement>;
 * }>}
 */
export const ConfirmPublishDashboardAlert = (props) => {
    return (
        <ConfirmationAlert
            title="Publish dashboard"
            description="This will publish the dashboard and make it available for all users"
            {...props}
        />
    );
};
