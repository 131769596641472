import { filter } from 'lodash';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { InitProcessGanttData } from '../GanttView/Helper';

export default function GanttFilter({ setGanttFilter, data, isGanttFilterActive, setIsGanttFilterActive }) {
    const [value, setValue] = React.useState();
    const onChange = (value) => {
        setIsGanttFilterActive(true);
        let dataInternal = InitProcessGanttData(data, isGanttFilterActive);
        let topLevelParent = filterData(dataInternal, value);
        if (topLevelParent) {
            let filteredData = data.filter((d) => topLevelParent.some((topLevelItem) => topLevelItem.id === d.id));
            setGanttFilter(filteredData);
        } else {
            setGanttFilter(null);
        }

        if (value === '') setIsGanttFilterActive(false);
    };
    function filterData(data, name) {
        const matches = data.filter((item) => item.name.toLowerCase().includes(name.toLowerCase()));
        const topLevelParents = matches.map((match) => {
            let current = match;
            while (current.project !== null) {
                current = data.find((item) => item.id === current.project);
            }
            return current;
        });
        return [...new Set(topLevelParents)];
    }
    return (
        <div className="list-search">
            <input
                value={value || ''}
                name="global-search"
                className="global-search"
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Search`}
                style={{
                    fontSize: '1.1rem',
                    border: '0',
                }}
                autoComplete={'off'}
            />
            <FontAwesome className="search-icon" name="search" />
        </div>
    );
}
