import React from 'react';
import './Gauge.scss';
import '../widget.css';
import { getTimeSince } from '../../../utility/timesince';
import { getValueWithPrecisionOrDefault } from '../../../utility/numberFunctions';
class Gauge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
        this.gauge = React.createRef();
    }

    componentDidUpdate(_nextProps) {
        this.drawGauge();
    }

    componentDidMount() {
        this.drawGauge();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    drawGauge() {
        const gauge = this.refs.gauge;

        if (gauge) {
            const ctx = gauge.getContext('2d');

            const max = this.props.configuration.maxValue;
            const min = this.props.configuration.minValue;
            //const current = this.props.data ? this.props.data.data.value : 0;
            const current = this.props.data?.data?.value || 0;
            const threshold = this.props.configuration.threshold || {};
            const total = max - min;

            let currentCalc = current;
            if (current < 0 || min < 0) {
                currentCalc = (min - current) * -1;
            }

            let currentPercent = current != null ? (currentCalc / total) * 75 : 0;
            if (currentPercent > 75) {
                currentPercent = 75;
            } else if (currentPercent < 0) {
                currentPercent = 0;
            }

            // let currentPercent = (current / total) * 75; // * 75 to prevent full circle
            // if (currentPercent > 75) {
            //     currentPercent = 75;
            // }

            //console.log(total, currentPercent);

            let progressFillStart = 'rgba(65, 205, 245, 0)';
            let progressFillStop = 'rgba(65, 205, 245, 1)';

            if (current > threshold.alertHigh || current < threshold.alertLow) {
                progressFillStart = 'rgba(255, 0, 85, 0)';
                progressFillStop = 'rgba(255, 0, 85, 1)';
            } else if (current > threshold.warningHigh || current < threshold.warningLow) {
                progressFillStart = 'rgba(201, 181, 78, 0)';
                progressFillStop = 'rgba(201, 181, 78, 1)';
            }

            const calcEnd = ((2 / 100) * currentPercent + 0.5) * Math.PI;

            const x = 62,
                y = 62,
                radius = 57,
                angleStart = 0.5 * Math.PI,
                angleEnd = calcEnd;

            ctx.clearRect(0, 0, 1000, 1000);

            // Create gradient
            const gradient = ctx.createLinearGradient(0, 200, 0, 0);
            gradient.addColorStop(0, progressFillStart);
            gradient.addColorStop(1, progressFillStop);

            // Draw circle
            ctx.beginPath();
            ctx.arc(x, x, radius, 0.5 * Math.PI, 2 * Math.PI, false);
            ctx.lineWidth = 10;
            ctx.strokeStyle = '#1C2B32';
            ctx.stroke();

            // Draw arc
            ctx.beginPath();
            ctx.arc(x, y, radius, angleStart, angleEnd);
            ctx.strokeStyle = gradient;
            ctx.lineWidth = 10;
            ctx.stroke();

            // Draw end
            ctx.beginPath();
            ctx.arc(x, x, radius, angleEnd, angleEnd + 0.05);
            ctx.lineWidth = 10;
            ctx.strokeStyle = '#ECECEC';
            ctx.stroke();
        }
    }

    render() {
        //console.log(this.props.data)
        const res = this.props.data || {
            data: {
                value: 0,
                timestamp: new Date().toString(),
                fakeRes: true,
            },
        };
        res.configuration = this.props.configuration;

        const current = res.data?.value;

        const decimalCount = res.configuration.numericPrecision ?? 1; // todo: Make parameterizable in widget config -> numericPrecision already used in ValueCell

        return (
            <div className="gauge">
                <span className="time-since">{getTimeSince(res)}</span>
                <div className="component">
                    <div className="gauge-wrapper">
                        <canvas ref="gauge" width="124" height="124"></canvas>
                    </div>
                    <div className="data-wrapper">
                        <div className="data-title">
                            <span className="data-title__label">{this.props.title}</span>
                        </div>
                        <div className="data">
                            <div className="data-value">
                                <span className="data-value__number">
                                    {getValueWithPrecisionOrDefault(current, decimalCount, 0)}
                                </span>
                            </div>
                            <div className="data-units">
                                <span className="data-units__total">/{res.configuration.maxValue}</span>
                                <span className="data-units__unit">{res.configuration.unit}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gauge;
