import React from 'react';

import { ComponentCollectionEditor, GetDecimalPrecisionField, GetFormulaField } from './utils/DatapointsUtils';
import { ReactComponent as DeleteIcon } from '../../../images/new/optimar_cross.svg';
import { defaultFormula } from '../../../utility/widgetformulamanager';

const datapoint_type_name = 'Bar Chart';
const datapoint_sources = undefined;
//  {
//     data_aggregates: "Data Aggregates"
// }
const new_datapoint = {
    datapointId: 'barChartDatapoint',
    type: 'barchart',
    groups: [],
    // "componentId": "",
    // "datavalue": "",
    componentSettings: [],
    formula: defaultFormula,
};

export default () => {
    return {
        name: datapoint_type_name,
        new_datapoint: new_datapoint,
        get_editor: get_editor,
        get_details: get_details,
    };
};

function get_editor(data, onInputChange, props) {
    if (!data.groups) onInputChange('groups', []);
    if (!data.componentSettings) onInputChange('componentSettings', []);

    // callback for deleting a group
    const delete_group = (index) => {
        data.groups.splice(index, 1);
        onInputChange('groups', data.groups);
    };

    // callback for adding a group
    const add_new_group = () => {
        data.groups.push({
            label: '0-100',
            min: 0,
            max: 100,
        });
        onInputChange('groups', data.groups);
    };

    // callback for when something change in a group
    const on_group_change = (index, name, value) => {
        data.groups[index][name] = value;
        onInputChange('groups', data.groups);
    };

    return (
        <>
            <br />
            {data.groups.map((group, index) => {
                return (
                    <fieldset className="group-fieldset" key={'gfs' + index}>
                        <legend>Group {index + 1}</legend>
                        <DeleteIcon
                            onClick={() => {
                                delete_group(index);
                            }}
                            className="delete-icon"
                        />
                        <div className="form-group">
                            <label htmlFor="label">Label</label>
                            <input
                                type="text"
                                name="label"
                                value={group.label}
                                className="input-control"
                                onChange={(event) =>
                                    on_group_change(index, event.currentTarget.name, event.currentTarget.value)
                                }
                            />
                        </div>
                        <div className="fields">
                            <div className="fields-column">
                                <div className="form-group">
                                    <label htmlFor="min">Min</label>
                                    <input
                                        type="number"
                                        name="min"
                                        value={group.min}
                                        className="input-control"
                                        onChange={(event) =>
                                            on_group_change(index, event.currentTarget.name, event.currentTarget.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="fields-column">
                                <div className="form-group">
                                    <label htmlFor="max">Max</label>
                                    <input
                                        type="number"
                                        name="max"
                                        value={group.max}
                                        className="input-control"
                                        onChange={(event) =>
                                            on_group_change(index, event.currentTarget.name, event.currentTarget.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                );
            })}
            <button className="btn btn-blue" onClick={add_new_group}>
                Add group
            </button>
            <ComponentCollectionEditor
                onInputChange={onInputChange}
                datapoint_sources={datapoint_sources}
                data={data}
                items={props.items}
                getDatavaluesFunc={props.getDatavaluesFunc}
                child_jobs={props.child_jobs}
            />

            {GetFormulaField(data, onInputChange)}
            {GetDecimalPrecisionField(data, onInputChange)}
        </>
    );
}

function get_details(data) {
    return (
        <ul className="datapoint_details">
            <li>Groups: {data.groups.length}</li>
            {data.componentSettings?.length > 0 ? (
                data.componentSettings.map((itm, idx) => {
                    return (
                        <li key={`cs_${idx}`}>
                            {itm.componentId} - <i>{itm.datavalue}</i>
                            {itm.__custom__ ? ' (custom)' : ''}
                        </li>
                    );
                })
            ) : (
                <li>
                    Data: {data.componentId} - <i>{data.datavalue}</i>
                    {data.__custom__ ? ' (custom)' : ''}
                </li>
            )}
        </ul>
    );
}
