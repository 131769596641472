import moment from 'moment';

export const getDefaultTicksAndPeriod = (defaultPeriod) => {
    const rangeinfo = getRangeInfoFromPeriod(defaultPeriod);

    return rangeinfo;
};
export const getRangeInfoFromPeriod = (period) => {
    const range = {
        period: 24, //288 5 minute periods in a day //24,
        interval: 'day',
        //ticks: "hour"
        ticks: '5minutes',
        datefriendlyticks: 'minutes',
    };
    switch (period) {
        case 'hour':
            range.period = 60;
            range.interval = 'hour';
            range.ticks = 'minutes';
            break;
        case 'day':
            //this is default..
            // range.period = 24;
            // range.interval = "day";
            //range.ticks = "hour";
            // range.ticks = "5minutes";
            break;
        case 'week':
            range.period = 7;
            range.interval = 'week';
            //range.ticks = "day";
            range.ticks = 'hour';
            break;
        case 'month':
            range.period = 30;
            range.interval = 'month';
            range.ticks = 'hour';
            break;
        default:
            break;
    }
    return range;
};
export const roundDate = (date, duration, method) => {
    return moment(Math[method](+date / +duration) * +duration);
};
//this function gets all date/time intervals that should be present in an interval.
// useful when we want to illustrate data gaps
export const getDateRangesFromPeriod = (period, from, to) => {
    let range;
    switch (period.interval) {
        // case "minutes":
        //     range = this.enumerateMinutesBetweenDates(from, to, 1);
        //     break;
        case 'hour':
            //         range = this.enumerateHoursBetweenDates(from, to, 1);
            range = enumerateMinutesBetweenDates(from, to, 1);
            break;
        case 'day':
            // eslint-disable-next-line no-case-declarations
            const roundedFrom = roundDate(from, moment.duration(5, 'minutes'), 'ceil');
            range = enumerateMinutesBetweenDates(roundedFrom, to, 5);
            break;
        case 'week':
            //range = this.enumerateBetweenDates(from, to, 1, "weeks");
            range = enumerateHoursBetweenDates(from, to, 1);
            break;
        case 'month':
            //range = this.enumerateBetweenDates(from, to, 1, "weeks");
            range = enumerateHoursBetweenDates(from, to, 1);
            break;
        default:
            break;
    }
    // switch (period.ticks) {
    //     case "minutes":
    //         range = this.enumerateMinutesBetweenDates(from, to, 1);
    //         break;
    //     case "hour":
    //         range = this.enumerateHoursBetweenDates(from, to, 1);
    //         break;
    //     case "day":
    //         range = this.enumerateBetweenDates(from, to, 1, "days");
    //         break;
    //     case "week":
    //         range = this.enumerateBetweenDates(from, to, 1, "weeks");
    //         break;
    //     default:
    //         break;
    // }
    return range;
};

export const getToAndFromBasedOnPeriodOrJob = (job, period, stateEndDate) => {
    let to, from;
    // if(stateEndDate){
    //     to = period.interval === "day" ? moment(stateEndDate) : moment(stateEndDate).minute(0).second(0).millisecond(0);
    // }else {
    //     to = (period.interval === "day" || period.interval === "hour") ? moment().utc() : moment().hour(0).minute(0).second(0).millisecond(0).utc();
    // }

    to = getToTimeFromEndTimeOrPeriod(stateEndDate, period);
    from = getFromTimeBasedOnPeriodAndToTime(to, period);

    if (job) {
        to = moment(job.endDate).local();
        from = moment(job.startDate).local();
    }
    // to = (this.props.job ? moment(job.endDate).local() : to);

    //from = (this.props.job ? moment(this.props.job.startDate).local() : from);

    return { from, to };
};

export const getFromTimeBasedOnPeriodAndToTime = (toTime, period) => {
    let from = toTime.clone().minute(0).second(0).millisecond(0); //.add(-period.period, period.ticks);

    switch (period.interval) {
        case 'hour':
            from = from.add(-60, 'minutes');
            break;
        case 'day':
            from = from.add(-24, 'hours');
            break;
        case 'week':
            from = from.add(-7, 'days');
            break;
        case 'month':
            from = from.add(-1, 'month');
            break;
        default:
            break;
    }
    return from;
};

export const getToTimeFromEndTimeOrPeriod = (endDate, period) => {
    // let to;
    //     if (!this.state.endDate) {
    //         to = this.state.period.interval === "day" ? moment().utc(): moment().utc(); //.hour(0).minute(0).second(0).millisecond(0).utc() : moment().minute(0).second(0).millisecond(0).utc();
    //     }else
    //         to = this.state.period.interval === "day" ? moment(this.state.endDate).utc() : moment(this.state.endDate).minute(0).second(0).millisecond(0).utc();
    let to;
    if (!endDate) {
        to =
            period.interval === 'day' || period.interval === 'hour'
                ? moment().utc()
                : moment().hour(0).minute(0).second(0).millisecond(0).utc();
    } else
        to =
            period.interval === 'day'
                ? moment(endDate).utc()
                : moment(endDate).minute(0).second(0).millisecond(0).utc();
    return to;
};

export const enumerateBetweenDates = (startDate, endDate, tick, type) => {
    let now = startDate.clone(),
        dates = [];

    while (now.isBefore(endDate)) {
        if (type === 'weeks') {
            dates.push(now.weekday(1).format('YYYY-MM-DDT00:00:00.000') + 'Z');
        } else if (type === 'minutes') {
            dates.push(now.seconds(0).milliseconds(0).toISOString());
        } else {
            dates.push(now.format('YYYY-MM-DDT00:00:00.000') + 'Z');
        }

        now.add(tick, type);
    }
    return dates;
};

export const enumerateHoursBetweenDates = (startDate, endDate, tick) => {
    let now = startDate.clone(),
        dates = [];

    while (now.isBefore(endDate)) {
        dates.push(now.minute(0).seconds(0).milliseconds(0).toISOString());
        now.add(tick, 'hours');
    }
    return dates;
};

export const enumerateMinutesBetweenDates = (startDate, endDate, tick) => {
    let now = startDate.clone(),
        dates = [];

    while (now.isBefore(endDate)) {
        dates.push(now.seconds(0).milliseconds(0).toISOString());
        now.add(tick, 'minutes');
    }
    return dates;
};

export const IsRangeValid = (from, to) => {
    const diffTime = Math.abs(from - to);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 32 && diffDays >= 0;
};
