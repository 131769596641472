import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import api from '../../../utility/api';
import { ReactComponent as DownArrow } from '../../../images/new/optimar_angle_down.svg';
import { ReactComponent as RightArrow } from '../../../images/new/optimar_angle_right.svg';
import './FlatTreeNav.scss';
import { ReactComponent as DeleteIcon } from '../../../images/new/optimar_cross.svg';

class FlatTreeNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            list: [],
            generated: false,
            data: [],
            filterString: '',
            expanded: [],
            state: props.whatever,
            expandAll: false,
            flatData: [],
        };

        this.mouseMove = this.mouseMove.bind(this);
        this.generateList = this.generateList.bind(this);
    }

    componentDidMount() {
        if (this.props.user && this.props.plant) {
            this.getStructure(this.props.user, this.props.plant);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.plant &&
            this.props.user &&
            !this.state.generated &&
            JSON.stringify(this.props) !== JSON.stringify(prevProps)
        ) {
            this.getStructure(this.props.user, this.props.plant);
        } else if (this.props.plant && this.props.currentItemId !== prevProps.currentItemId) {
            console.log('reflattening');
            this.unflatten(this.state.flatData);
        }

        if (prevProps.currentLocation !== this.props.currentLocation) this.generateList();

        if (prevProps.plant && prevProps.plant.id !== this.props.plant.id)
            this.getStructure(this.props.user, this.props.plant);
    }

    getStructure(user, plant) {
        const apiUrl = process.env.REACT_APP_API + '/api/plants/' + plant.id + '/items/structure';

        api.get(apiUrl)
            .then((res) => {
                if (res.status !== 204) {
                    const filteredData = res.data.filter((x) => x.showInTableView);
                    this.unflatten(filteredData);
                    this.setState({
                        flatData: filteredData,
                    });
                } else if (res.status === 204) {
                    this.unflatten();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    unflatten(data) {
        if (data) {
            const ID_KEY = 'id';
            const PARENT_KEY = 'parentId';
            const CHILDREN_KEY = 'children';

            const tree = [],
                childrenOf = {};
            let item, id, parentId;

            for (let i = 0, length = data.length; i < length; i++) {
                item = data[i];
                id = item[ID_KEY];
                parentId = item[PARENT_KEY] || '00000000-0000-0000-0000-000000000000';
                // every item may have children
                childrenOf[id] = childrenOf[id] || [];
                // init its children
                item[CHILDREN_KEY] = childrenOf[id];
                item.toggled = true;
                if (parentId !== '00000000-0000-0000-0000-000000000000') {
                    // init its parent's children object
                    childrenOf[parentId] = childrenOf[parentId] || [];
                    // push it into its parent's children object
                    childrenOf[parentId].push(item);
                } else {
                    tree.push(item);
                }
            }
            this.setState(
                {
                    data: tree,
                },
                () => this.generateList()
            );
        }
    }

    toggleExpand(e, id) {
        e.stopPropagation();
        if (this.state.expanded.includes(id)) {
            const tmp = this.state.expanded;
            tmp.splice(tmp.indexOf(id), 1);
            this.setState(
                {
                    expanded: tmp,
                },
                this.generateList()
            );
        } else {
            const tmp = this.state.expanded;
            tmp.push(id);
            this.setState(
                {
                    expanded: tmp,
                },
                this.generateList()
            );
        }
    }

    findNode(node) {
        const fString = this.state.filterString.toLowerCase();
        if (
            node.name.toLowerCase().includes(fString) ||
            (node.componentId && node.componentId.toLowerCase().includes(fString)) ||
            (node.orderPos && node.orderPos.toLowerCase().includes(fString)) ||
            (node.productSheet && node.productSheet.toLowerCase().includes(fString)) ||
            node.children.find((child) => this.findNode(child))
        ) {
            if (!this.state.expanded.includes(node.parentId)) {
                const tmp = this.state.expanded;
                tmp.push(node.parentId);
                this.setState({
                    expanded: tmp,
                });
            }
            return true;
        }
    }
    setShowOrderPos(orderPos) {
        //this.setState({ showOrderPos: node.id })
        if (this.props.isMobileOrTablet) {
            //Don't set if mobile or tablet, because it depends on mouse movement..
            //This should be solved in a better way
            return;
        }
        this.setState({ showOrderPos: orderPos });
    }

    check() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    generateListSection(node, list, level) {
        const expanded = this.state.expanded;
        const currentLocation = this.props.currentLocation;
        if (node.parentId === '00000000-0000-0000-0000-000000000000' && this.findNode(node)) {
            list.push(
                <span
                    onMouseMove={this.mouseMove}
                    onMouseLeave={() => {
                        this.setShowOrderPos(null);
                    }}
                    onMouseEnter={() => this.setShowOrderPos(node.id)}
                    key={node.id}
                    className="menu-line"
                >
                    <span className="menu-line-content">
                        {node.children.length > 0 && this.state.filterString === '' ? (
                            expanded.includes(node.id) ? (
                                <span
                                    onClick={(e) => this.toggleExpand(e, node.id)}
                                    className="nav-collapser"
                                    title="Collapse"
                                >
                                    <DownArrow title="Toggle expand" />
                                </span>
                            ) : (
                                <span
                                    onClick={(e) => this.toggleExpand(e, node.id)}
                                    className="nav-expander"
                                    title="Expand"
                                >
                                    <RightArrow title="Toggle expand" style={{ stroke: '#41cdf5' }} />
                                </span>
                            )
                        ) : null}

                        <Link
                            key={'nav' + node.id}
                            className={
                                'nav-node l-' +
                                level +
                                (window.location.pathname.toLowerCase().includes(node.id) ? ' nav-node-active' : '')
                            }
                            to={`/plant/${this.props.plant.id}/items/${node.id + currentLocation}`}
                        >
                            {node.name}
                        </Link>
                    </span>
                </span>
            );
        } else {
            const styles = {};
            if (level >= 1) {
                if (this.check()) {
                    styles.paddingLeft = level * 17 + 'px';
                } else {
                    styles.paddingLeft = level * 5 + 'px';
                }
            }

            if (this.state.filterString === '' || this.findNode(node)) {
                list.push(
                    <span
                        onMouseMove={this.mouseMove}
                        onMouseLeave={() => {
                            this.setShowOrderPos(null);
                        }}
                        onMouseEnter={() => this.setShowOrderPos(node.id)}
                        style={styles}
                        key={node.id}
                        className="menu-line"
                    >
                        <span className="menu-line-content">
                            {node.children.length > 0 && this.state.filterString === '' ? (
                                expanded.includes(node.id) ? (
                                    <span
                                        onClick={(e) => this.toggleExpand(e, node.id)}
                                        className="nav-collapser"
                                        title="Collapse"
                                    >
                                        <DownArrow title="Toggle expand" />
                                    </span>
                                ) : (
                                    <span
                                        onClick={(e) => this.toggleExpand(e, node.id)}
                                        className="nav-expander"
                                        title="Expand"
                                    >
                                        <RightArrow title="Toggle expand" style={{ stroke: '#41cdf5' }} />
                                    </span>
                                )
                            ) : null}
                            <Link
                                key={'nav' + node.id}
                                className={
                                    'nav-node l-' +
                                    level +
                                    (window.location.pathname.toLowerCase().includes(node.id) ? ' nav-node-active' : '')
                                }
                                to={`/plant/${this.props.plant.id}/items/${node.id + currentLocation}`}
                            >
                                {node.name}
                            </Link>
                        </span>
                    </span>
                );
            }
        }

        if (node.children.length > 0 && expanded.includes(node.id)) {
            ++level;

            const sortedChildren = node.children.sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
            );

            sortedChildren.map((n) => {
                return this.generateListSection(n, list, level);
            });
        }

        if (node.parentId === '00000000-0000-0000-0000-000000000000' && this.findNode(node))
            list.push(<div className="divider" key={'div' + node.id}></div>);

        return;
    }

    expandAll(e, expand) {
        e.stopPropagation();
        if (expand) {
            this.setState(
                {
                    expanded: this.state.flatData.map((node) => {
                        return node.id;
                    }),
                    filterString: '',
                },
                () => this.generateList()
            );
        } else {
            this.setState(
                {
                    expanded: [],
                    filterString: '',
                },
                () => this.generateList()
            );
        }
    }

    mouseMove(e) {
        this.setState({ mouseX: e.clientX, mouseY: e.clientY });
    }

    generateList() {
        const list = [];
        const currentLocation = this.props.currentLocation;
        list.push(
            <span key="veryunique" className="nav-root">
                <Link key="node-root" className="nav-node l-1" to={`/plant/${this.props.plant.id + currentLocation}`}>
                    {this.props.plant.name}
                </Link>
                <span className="nav-expand">
                    <FontAwesome
                        className="nav-caret"
                        title="Expand all"
                        onClick={(e) => this.expandAll(e, true)}
                        name="angle-double-down"
                    />
                    <FontAwesome
                        className="nav-caret"
                        title="Collapse all"
                        onClick={(e) => this.expandAll(e, false)}
                        name="angle-double-up"
                    />
                </span>
                {this.props.sidePanelMode ? (
                    <DeleteIcon onClick={() => this.props.toggle(false)} className="tree-close" />
                ) : null}
            </span>
        );
        list.push(
            <input
                autoComplete="off"
                key="veryunique2"
                type="text"
                placeholder="Search"
                className="search-input search-nav"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                    this.setState({ filterString: e.target.value }, this.generateList);
                }}
            />
        );
        list.push(<div key="veryunique3" className="divider"></div>);

        const sortedNodes = this.state.data.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
        );

        sortedNodes.map((node) => {
            return this.generateListSection(node, list, 0);
        });

        list.pop();
        this.setState({
            list: list,
            generated: true,
        });
    }

    render() {
        if (!this.state.list) {
            return null;
        }

        let orderPosDisplay = null;
        if (this.state.showOrderPos) {
            const node = this.state.flatData.filter((x) => x.id === this.state.showOrderPos)[0];
            orderPosDisplay = (
                <div
                    style={{ position: 'fixed', top: this.state.mouseY + 20, left: this.state.mouseX + 20 }}
                    className="orderpos-display"
                >
                    OrderPos: {node.orderPos ? node.orderPos : 'N/A'}
                </div>
            );
        }

        return (
            <div
                className={'treenav-dropdown ' + (this.props.className ? this.props.className : '')}
                style={{ display: this.props.show ? 'block' : 'none', ...this.props.style }}
            >
                <div className={'inner-treenav-container'}>
                    {this.state.generated ? this.state.list : null}
                    {this.check() ? orderPosDisplay : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        plant: state.plants.currentPlant,
        user: state.user.currentUser,
        currentItemId: state.items.currentItem,
    };
};

export default connect(mapStateToProps)(FlatTreeNav);
