import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import NavContainer from '../Nav/NavContainer';
import NavConfig from '../Nav/NavConfig';
import { Switch, Route } from 'react-router-dom';
import AdminProjectAddEdit from '../AdminProjectAddEdit/AdminProjectAddEdit';
import AdminMachineNo from '../../../views/AdminMachineNo/AdminMachineNo';
import api from '../../../utility/api';
import { withRouteMatch } from '../../../HOC';

class AdminAccountOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: {},
        };
        this.NavConfig = JSON.parse(JSON.stringify(NavConfig));
        this.projectEdited = this.projectEdited.bind(this);
    }

    componentDidMount() {
        if (this.props.user) this.fetchAccount(this.props.match.params.projectId, this.props.user);
    }

    componentDidUpdate() {
        if (this.props.user) {
            this.fetchAccount(this.props.match.params.projectId, this.props.user);
        }
    }

    fetchAccount(projectId) {
        api.get(process.env.REACT_APP_API + `/api/projects/${projectId}`).then((res) => {
            this.setState({ project: res.data });
        });
    }

    projectEdited() {
        this.fetchProject(this.props.match.params.projectId, this.props.user);
    }

    render() {
        if (!this.state.project) {
            return <Spinner text="account" />;
        }
        return (
            <div className="items-add-edit-container">
                <div className="items-add-edit-header">
                    <span>Project</span>
                </div>
                <div className="items-add-edit-content">
                    <div className="items-add-edit-tree-account">
                        <label className="ao-title">
                            <h4>{this.state.project.projectName} </h4>
                        </label>
                    </div>
                    <div className="items-add-edit" style={{ width: '100%' }}>
                        <NavContainer
                            path={this.props.match}
                            config={null}
                            defaultConfig={this.NavConfig.projOverview}
                            plant={null}
                        />
                        <div>
                            <Switch>
                                <Route exact path="/admin/project/:projectId" name="General project admin">
                                    <AdminProjectAddEdit
                                        editProject={this.state.project}
                                        projectEdited={this.projectEdited}
                                    />
                                </Route>
                                <Route path="/admin/project/:projectId/machines" name="Project machines">
                                    <AdminMachineNo accountToEdit={this.state.account} />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
    };
};

export default withRouteMatch(connect(mapStateToProps)(AdminAccountOverview));
