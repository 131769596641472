import React from 'react';
import './Signal.scss';
import '../widget.css';
import { getTimeSince } from '../../../utility/timesince';

class Signal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    checkTruthValue(value) {
        if (typeof value === 'string' && value.toLowerCase() === 'true') return true;
        if (typeof value === 'boolean' && value) return true;

        return false;
    }

    render() {
        const res = this.props.data;
        const colors = this.props.configuration.color || ['', '', ''];

        if (!res) {
            return (
                <div className="signal">
                    <div className="data-wrapper">
                        <div className="data-title">
                            <span className="data-title__label">{this.props.title}</span>
                        </div>
                        <div className="data">
                            <div className="data-value">
                                <div className="signal-wrapper">
                                    <span className={'signal-lamp no-data ' + colors[0]}></span>
                                    <span className="signal-label">No data</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        res.configuration = this.props.configuration;
        console.log('lampRES', res);

        return (
            <div className="signal">
                <span className="time-since">{getTimeSince(res)}</span>
                <div className="data-wrapper">
                    <div className="data-title">
                        <span className="data-title__label">{this.props.title}</span>
                        {this.props.manualInput ? (
                            <span className="manual-add" onClick={(e) => this.openInputModal(e)}>
                                ...
                            </span>
                        ) : null}
                    </div>
                    <div className="data">
                        <div className="data-value">
                            <div className="signal-wrapper">
                                <span
                                    className={
                                        'signal-lamp ' +
                                        (this.checkTruthValue(res.data.value) ? colors[1] : 'error ' + colors[2])
                                    }
                                ></span>
                                <span className="signal-label">
                                    {this.props.configuration.usePayloadUnit
                                        ? this.props.widget.dataValue
                                        : this.props.configuration.name}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Signal;
