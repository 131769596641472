import React from 'react';
import Dashboard from '../dashboard';
import './accountdashboard.scss';

class AccountDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="main-content">
                <Dashboard {...this.props} type="account" />
            </div>
        );
    }
}

export default AccountDashboard;
