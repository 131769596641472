import React from 'react';
import { Link } from 'react-router-dom';
import './LinkButton.scss';
import { ReactComponent as BuoyOk } from '../../../images/biometrics/buoy_ok.svg';
import { ReactComponent as BuoyWarn } from '../../../images/biometrics/buoy_warn.svg';
import { ReactComponent as BuoyErr } from '../../../images/biometrics/buoy_err.svg';

import { getValueWithPrecisionOrDefault } from '../../../utility/numberFunctions';
import FontAwesome from 'react-fontawesome';
class LinkButton extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            linkbutton: null,
            selectedBuoy: undefined,
            id: undefined,
            status: undefined,
            loaded: false,
        };
        this.mounted = true;
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.setState({
                linkbutton: this.props.config,
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    showSymbol = () => {
        switch (this.state.status) {
            case 0:
                return <BuoyOk />;
            case 1:
                return <BuoyWarn />;
            case 2:
                return <BuoyErr />;
            default:
                return <BuoyOk />;
        }
    };

    showBackLink = () => {
        return (
            <Link replace key={'nav' + this.state.linkbutton.id} to={`/plant/${this.props.plantId}/kpi`}>
                <FontAwesome name="arrow-left" title="Go to plant dashboard" className={'buoy-dashboard-link'} />
            </Link>
        );
    };

    render() {
        if (!this.state.linkbutton) return <div></div>;

        if (this.props.clickable)
            return (
                <Link
                    replace
                    key={'nav' + this.state.linkbutton.id}
                    to={
                        this.props.link
                            ? this.props.link
                            : `/plant/${this.props.plantId}/items/${this.props.itemId}/kpi`
                    }
                >
                    <div ref={this.myRef} className="buoy">
                        {this.showSymbol()}
                        <span className="weight">{this.state.linkbutton.name}</span>
                        <span className="weight">
                            {getValueWithPrecisionOrDefault(this.state.linkbutton.data?.data?.value, 2, 0)}{' '}
                            {this.state.linkbutton.unit}
                        </span>
                        {/* <span className="increase">{this.dataValues.growth}%</span> */}
                    </div>
                </Link>
            );
        else
            return (
                <>
                    {this.showBackLink()}
                    <div ref={this.myRef} className="buoy no-click">
                        {this.showSymbol()}
                        <span className="weight">{this.state.linkbutton.name}</span>
                        <span className="weight">
                            {getValueWithPrecisionOrDefault(this.state.linkbutton.data?.data?.value, 2, 0)}{' '}
                            {this.state.linkbutton.unit}
                        </span>
                        {/* <span className="increase">{this.dataValues.growth}%</span> */}
                    </div>
                </>
            );
    }
}

export default LinkButton;
