import React from 'react';
import './Login.css';
import Logo from '../../images/Optimar_Commander_Negativ.svg';

const Login = () => {
    return (
        <div className="login-content">
            <img className="optimar-logo-login" src={Logo} alt="Optimar logo" />
            <div className="login-panel">
                <span className="login">AUTHORIZING...</span>
            </div>
        </div>
    );
};

export default Login;
