import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './Nav.scss';
import NavItem from './NavItem';

const NavContainer = ({ item, config, defaultConfig, path, plant, user }) => {
    const location = useLocation();
    let navItems = [];
    if (!plant || !config) {
        navItems = defaultConfig;
    } else if (plant) {
        navItems = config;
    } else {
        navItems = config.filter((c) => c.itemTypeId === item.itemTypeId);
        navItems = navItems[0] ? navItems[0].navigation : defaultConfig;
    }
    //console.log(item, config, defaultConfig, path, plant, user);
    navItems.forEach((item) => {
        if (item.external) {
            return;
        }
        if (item.link === '') {
            return;
        }

        if (item.link.includes('/overview')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/overview';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/overview';
            }
        } else if (item.link.includes('/system')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/system';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/system';
            }
        } else if (item.link.includes('/report')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/report';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/report';
            }
        } else if (item.link.includes('/note/add')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/note/add';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/note/add';
            }
        } else if (item.link.includes('/note')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/note';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/note';
            }
        } else if (item.link.includes('/documentation')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/documentation';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/documentation';
            }
        } else if (item.link.includes('/settings')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/settings';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/settings';
            }
        } else if (item.link.includes('/alarms')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/alarms';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/alarms';
            }
        } else if (
            item.link.includes('/admin/plant/eventsubscriptions') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/eventsubscriptions')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/eventsubscriptions';
        } else if (item.link.includes('/events')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/events';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/events';
            }
        } else if (item.link.includes('/parameters')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/parameters';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/parameters';
            }
        } else if (item.link.includes('/kpi')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/kpi';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/kpi';
            }
        } else if (item.link.includes('/trace')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/trace';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/trace';
            }
        } else if (item.link.includes('/support')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/support';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/support';
            }
        } else if (item.link.includes('/operations')) {
            if (path.params.itemId) {
                item.link = '/plant/' + path.params.plantId + '/items/' + path.params.itemId + '/operations';
            } else if (path.params.plantId) {
                item.link = '/plant/' + path.params.plantId + '/operations';
            }
        } else if (
            item.link.includes('/admin/account/users') ||
            item.link.includes('/admin/account/' + path.params.accountId + '/users')
        ) {
            item.link = '/admin/account/' + path.params.accountId + '/users';
        } else if (
            item.link.includes('/admin/account/plants') ||
            item.link.includes('/admin/account/' + path.params.accountId + '/plants')
        ) {
            item.link = '/admin/account/' + path.params.accountId + '/plants';
        } else if (
            item.link.includes('/admin/account/general') ||
            item.link.includes('/admin/account/' + path.params.accountId + '/general')
        ) {
            item.link = '/admin/account/' + path.params.accountId + '/general';
        } else if (
            item.link.includes('/admin/account/modules') ||
            item.link.includes('/admin/account/' + path.params.accountId + '/modules')
        ) {
            item.link = '/admin/account/' + path.params.accountId + '/modules';
        } else if (
            item.link.includes('/admin/account/dashboard') ||
            item.link.includes('/admin/account/' + path.params.accountId + '/dashboard')
        ) {
            item.link = '/admin/account/' + path.params.accountId + '/dashboard';
        } else if (item.link.includes('/admin/account')) {
            item.link = '/admin/account';
        } else if (item.link.includes('/admin/iotdevices')) {
            item.link = '/admin/iotdevices';
        } else if (
            item.link.includes('/admin/plant/newitems') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/newitems')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/newitems';
        } else if (
            item.link.includes('/admin/plant/subdevices') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/subdevices')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/subdevices';
        } else if (
            item.link.includes('/admin/plant/components') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/components')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/components';
        } else if (
            item.link.includes('/admin/plant/accounts') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/accounts')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/accounts';
        } else if (
            item.link.includes('/admin/plant/sla') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/sla')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/sla';
        } else if (
            item.link.includes('/admin/plant/projects') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/projects')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/projects';
        } else if (
            item.link.includes('/admin/plant/config') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/config')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/config';
        } else if (
            item.link.includes('/admin/plant/log') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/log')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/log';
        } else if (
            item.link.includes('/admin/plant/device') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/device')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/device';
        } else if (
            item.link.includes('/admin/plant/items') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/items')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/items';
        } else if (
            item.link.includes('/admin/plant/operationtypes') ||
            item.link.includes('/admin/plant/' + path.params.plantId + '/operationtypes')
        ) {
            item.link = '/admin/plant/' + path.params.plantId + '/operationtypes';
        } else if (item.link.includes('/admin/project/general')) {
            item.link = '/admin/project/' + path.params.projectId;
        } else if (item.link.includes('/admin/project/machines')) {
            item.link = '/admin/project/' + path.params.projectId + '/machines';
        } else if (item.link.includes('/account/projects')) {
            item.link = '/account/projects';
        } else if (item.link.includes('/account/notes')) {
            item.link = '/account/notes';
            // eslint-disable-next-line no-dupe-else-if
        } else if (item.link.includes('/admin/plant/accounts')) {
            item.link = '/admin/plant/accounts';
        } else if (item.link.includes('/admin/itemtypes')) {
            item.link = '/admin/itemtypes';
        }
    });

    return (
        <nav className="navlist">
            {navItems.map((item) => {
                // eslint-disable-next-line
                if (item.link.includes('itemtypes') && !user.isOwner) return;
                const classname = location.pathname && location.pathname === item.link ? 'currentitem' : '';
                return (
                    <NavItem
                        activeClassName={'currentitem'}
                        className={classname}
                        key={item.name}
                        name={item.name}
                        link={item.link}
                        icon={item.icon}
                        external={item.external || false}
                    />
                );
            })}
        </nav>
    );
};

NavContainer.propTypes = {
    navItems: PropTypes.array,
};

export default NavContainer;
