import React from 'react';

/*  Description: Get forms editor for job metadata
    Parameters:
        - onInputChange: callback when something changes
        - data: raw data from the metadata component
        - available_metas: available metas
        - parent_metas: available parent metas
        - available_datapoints: available datapoints to use
        - config flags: flags to hide some of the specials
    Returns: job metadata forms editor
*/
export function meta_editor(
    onInputChange,
    data,
    available_metas,
    parent_metas,
    available_datapoints,
    { show_math = true, show_eff = true, show_parent = true } = {}
) {
    // get meta info struct from raw data
    const data_struct = compose_meta(data, available_metas, available_datapoints, onInputChange);

    // callback to call when a value of the meta info struct changes
    const on_meta_change = (event) => {
        // update struct
        data_struct[event.currentTarget.name] = event.currentTarget.value;

        // decompose struct to raw data
        const new_data = decompose_meta(data_struct);

        // call the upper callback
        onInputChange('meta', new_data);
    };

    // callback to call when a value of extra field of the meta info struct changes
    const on_extras_change = (name, value, index = undefined) => {
        // update struct
        if (index === undefined) data_struct.extra[name] = value;
        else data_struct.extra[name][index] = value;

        // decompose struct to raw data
        const new_data = decompose_meta(data_struct);

        // call the upper callback
        onInputChange('meta', new_data);
    };

    // form for editing metadata type
    const data_type_form = (
        <>
            <label htmlFor="type">Data Type</label>
            <select required name="type" className={'input-control'} onChange={on_meta_change} value={data_struct.type}>
                <option key="dt1" value="meta">
                    Metadata
                </option>
                <option key="dt2" value="special">
                    Special
                </option>
            </select>
        </>
    );

    // from for editing metadata field
    const metadata_field_form = (
        <>
            <label htmlFor="name">Metadata Field</label>
            <select required name="name" className={'input-control'} onChange={on_meta_change} value={data_struct.name}>
                {available_metas.map((element, index) => {
                    return (
                        <option key={'mt' + (index + 1)} value={element.name}>
                            {element.label}
                        </option>
                    );
                })}
            </select>
        </>
    );

    // from for editing special field
    const special_field_form = (
        <>
            <label htmlFor="name">Special Field</label>
            <select required name="name" className={'input-control'} onChange={on_meta_change} value={data_struct.name}>
                <option key="mt1" value="startTime">
                    Start Time
                </option>
                <option key="mt2" value="endTime">
                    End Time
                </option>
                <option key="mt3" value="totalTime">
                    Total Time
                </option>
                {parent_metas && show_parent ? (
                    <option key="mt4" value="parent">
                        Parent Metadata
                    </option>
                ) : null}
                {show_eff ? (
                    <option key="mt5" value="efficiency">
                        Efficiency
                    </option>
                ) : null}
                {show_math ? (
                    <option key="mt6" value="math">
                        Math
                    </option>
                ) : null}
            </select>
        </>
    );

    // from for editing operand field
    const metadata_field_operand = (index, show_label) => (
        <>
            <label htmlFor={'operand' + index}>Operand {show_label ? index + 1 : ''}</label>
            <select
                required
                name={'operand' + index}
                className={'input-control'}
                onChange={(event) => on_extras_change('ops', event.currentTarget.value, index)}
                value={data_struct.extra?.ops ? data_struct.extra.ops[index] : ''}
            >
                {available_metas.map((element, index) => {
                    return (
                        <option key={'otm' + (index + 1)} value={element.name}>
                            {element.label}
                        </option>
                    );
                })}
                {available_datapoints.map((element, index) => {
                    return (
                        <option key={'otd' + (index + 1)} value={'&' + element}>
                            {element}
                        </option>
                    );
                })}
            </select>
        </>
    );

    // from for editing efficiency special meta
    const efficiency_edit_forms = (
        <>
            <div className="form-group">
                <label htmlFor="efficiency">Period</label>
                <select
                    required
                    name="efficiency"
                    className={'input-control'}
                    onChange={(event) => on_extras_change(event.currentTarget.name, event.currentTarget.value)}
                    value={data_struct.extra.efficiency}
                >
                    <option key="ep1" value="day">
                        Day
                    </option>
                    <option key="ep2" value="hour">
                        Hour
                    </option>
                    <option key="ep3" value="min">
                        Minute
                    </option>
                </select>
            </div>
            <div className="form-group">{metadata_field_operand(0, false)}</div>
        </>
    );

    // from for editing math special meta
    const math_edit_forms = (
        <>
            <div className="form-group">{metadata_field_operand(0, true)}</div>
            <div className="form-group">
                <label htmlFor="math">Period</label>
                <select
                    required
                    name="math"
                    className={'input-control'}
                    onChange={(event) => on_extras_change(event.currentTarget.name, event.currentTarget.value)}
                    value={data_struct.extra.math}
                >
                    <option key="mp1" value="+">
                        +
                    </option>
                    <option key="mp2" value="-">
                        -
                    </option>
                    <option key="mp3" value="*">
                        *
                    </option>
                    <option key="mp4" value="/">
                        /
                    </option>
                </select>
            </div>
            <div className="form-group">{metadata_field_operand(1, true)}</div>
        </>
    );

    // return the form
    return (
        <>
            <div className="form-group">{data_type_form}</div>
            <div className="form-group">{data_struct.type === 'meta' ? metadata_field_form : special_field_form}</div>
            {
                // if we have a parent special metadata type and the spacial field is parent, then show the form for the parent
                data_struct.type === 'special' && data_struct.name === 'parent'
                    ? meta_editor(
                          (name, value) => {
                              if (name === 'meta') on_extras_change(data_struct.name, value);
                          },
                          data_struct.extra[data_struct.name],
                          parent_metas,
                          null,
                          [],
                          { show_eff: false, show_math: false, show_parent: false }
                      )
                    : null
            }
            {
                // if we have a efficiency special metadata type, then show the form for the efficiency
                data_struct.type === 'special' && data_struct.name === 'efficiency' ? efficiency_edit_forms : null
            }
            {
                // if we have a math special metadata type, then show the form for the efficiency
                data_struct.type === 'special' && data_struct.name === 'math' ? math_edit_forms : null
            }
        </>
    );
}

/*  Description: Function to validate child Operation type and get available metas
    Parameters:
        - jobtype: the name of the job to validate
        - child_jobs: array of children of the root job
        - onChange: callback if something has to be changed durring validation
        - key: prop name for the onChage callcback
    Returns: available_metas: metadata array or empty array if not available
*/
export function validate_jobtype(jobtype, child_jobs, onChange, key = 'jobtype') {
    // invalid Operation type name ?
    if (jobtype && !child_jobs.find((elem) => elem.name === jobtype)) {
        // set dafault to first child job
        jobtype = child_jobs[0].name;
        onChange(key, jobtype);
    }

    // get available metas
    let available_metas = JSON.parse(child_jobs.find((elem) => elem.name === jobtype).configuration);

    // are metas available and valid ?
    if (available_metas && available_metas.fields) available_metas = available_metas.fields;
    else available_metas = [];

    // do we have an array of metas ?
    if (!Array.isArray(available_metas)) available_metas = [];

    return available_metas;
}

/*  Description: Function to get parent metadata configuration
    Parameters:
        - job_type: the name of the job to get the parent metadata
        - root_job: the root job
        - child_jobs: array of children of the root job
    Returns: parent_metadata: metadata array or empty array if not available
*/
export function get_parent_metadata(job_type, root_job, child_jobs) {
    let parent_metadata = [];

    // job type is defined ?
    if (job_type) {
        // get this job
        const this_job = child_jobs.find((elem) => elem.name === job_type);

        // this job exists ?
        if (this_job) {
            // is this job a child of the root job ?
            if (this_job.parentId === root_job.id) parent_metadata = JSON.parse(root_job.configuration);
            else {
                // get parent from all root child jobs
                const parent_job = child_jobs.find((elem) => elem.id === this_job.parentId);

                // parent exists ?
                if (parent_job) parent_metadata = JSON.parse(parent_job.configuration);
            }
        }
    }

    // get only the fields
    if (parent_metadata && parent_metadata.fields) parent_metadata = parent_metadata.fields;

    // do we have an array of metas ?
    if (!Array.isArray(parent_metadata)) parent_metadata = [];

    return parent_metadata;
}

/*  Description: Function to get meta info struct from raw data
    Parameters:
        - data: raw data
        - available_metas: available meta fields
        - available_datapoints: available datapoints
        - onChange: callback to call when somethig must be changed
    Returns: meta info struct
*/
function compose_meta(data, available_metas, available_datapoints, onChange) {
    // default fields for the struct
    let type = 'meta';
    let name = '';
    const extra = {};

    // is raw data starting with @ then we have a special metadata
    if (data.startsWith('@')) type = 'special';

    // is it a meta type ?
    if (type === 'meta') {
        // do we have available metas ?
        if (available_metas.length) {
            // do available metas contain the selected one in raw data ?
            if (!available_metas.find((elem) => elem.name === data)) {
                // set raw data to first available meta
                data = get_default_meta(available_metas);
                onChange('meta', data);
            }
        } else {
            // raw data defined but no meta available ?
            if (data !== '') {
                // set raw data to empty string
                data = '';
                onChange('meta', data);
            }
        }

        // set name field of the struct to raw data
        name = data;
    }

    // is it a special type ?
    if (type === 'special') {
        // set the name field by removing the @ from the raw data
        name = data.substring(1);

        // is it a complex special ?
        if (!['startTime', 'endTime', 'totalTime'].includes(name)) {
            // split the name by space
            const words = name.split(' ');

            // set a corrected flag
            let corrected = false;

            // extract name field
            name = words[0];

            // validate and extract other fields
            switch (name) {
                case 'parent':
                    // set-up extras filed
                    extra[name] = words[1];
                    break;

                case 'efficiency':
                    // validate operand
                    if (words.length >= 2 && is_operand_valid(words[1], available_metas, available_datapoints))
                        extra.ops = [words[1]];
                    else {
                        extra.ops = get_default_meta(available_metas);
                        corrected = true;
                    }

                    // validate efficiency timebase
                    if (words.length >= 3 && ['day', 'hour', 'min'].includes(words[2])) extra[name] = words[2];
                    else {
                        extra[name] = 'day';
                        corrected = true;
                    }
                    break;

                case 'math':
                    // validate operands
                    extra.ops = ['', ''];
                    if (words.length >= 4 && is_operand_valid(words[1], available_metas, available_datapoints))
                        extra.ops[0] = words[1];
                    else {
                        extra.ops[0] = get_default_meta(available_metas);
                        corrected = true;
                    }

                    if (words.length >= 4 && is_operand_valid(words[3], available_metas, available_datapoints))
                        extra.ops[1] = words[3];
                    else {
                        extra.ops[1] = get_default_meta(available_metas);
                        corrected = true;
                    }

                    // validate operator
                    if (words.length >= 3 && ['+', '-', '*', '/'].includes(words[2])) extra[name] = words[2];
                    else {
                        extra[name] = '+';
                        corrected = true;
                    }
                    break;

                default:
                    name = 'startTime';
                    corrected = true;
                    break;
            }

            // did we got corrected ?
            if (corrected) {
                onChange(
                    'meta',
                    decompose_meta({
                        type: type,
                        name: name,
                        extra: extra,
                    })
                );
            }
        }
    }

    // build and return the struct
    return {
        type: type,
        name: name,
        extra: extra,
    };
}

/*  Description: Function to get raw data from meta info struct
    Parameters:
        - data_struct: meta info struct
    Returns: raw meta data
*/
function decompose_meta(data_struct) {
    // prepare raw data variable
    let data = '';

    // is it a special type ?
    if (data_struct.type === 'special') {
        // raw data will be @ + the name field of the struct
        data = '@' + data_struct.name;

        // treat the complex specials
        switch (data_struct.name) {
            case 'parent':
                // if it is a parent append the parents raw meta data to current raw meta data
                data = data + ' ' + data_struct.extra[data_struct.name];
                break;

            case 'efficiency':
                // if it is an effieciency special append operand and time base
                if (data_struct.name in data_struct.extra && 'ops' in data_struct.extra)
                    data += ' ' + data_struct.extra.ops + ' ' + data_struct.extra[data_struct.name];
                break;

            case 'math':
                // if it is a math special append operand and time base
                if (
                    data_struct.name in data_struct.extra &&
                    'ops' in data_struct.extra &&
                    data_struct.extra.ops.length >= 2
                )
                    data +=
                        ' ' +
                        data_struct.extra.ops[0] +
                        ' ' +
                        data_struct.extra[data_struct.name] +
                        ' ' +
                        data_struct.extra.ops[1];
                break;

            default:
                break;
        }
    }
    // for meta type the raw data is the name field of the meta info struct
    else data = data_struct.name;

    return data;
}

/*  Description: Function that checks that a complex special meta operand is valid
    Parameters:
        - operand: the operand
        - available_metas: available meta fields
        - available_datapoints: available datapoints
    Returns: true if operand is valid, else false
*/
function is_operand_valid(operand, available_metas, available_datapoints) {
    // is a datapoint operand ?
    if (operand.startsWith('&')) {
        return available_datapoints.includes(operand.substring(1));
    } // it is a meta operand
    else {
        return available_metas.filter((elem) => elem.name === operand).length > 0;
    }
}

/*  Description: Get default meta (first one)
    Parameters:
        - available_metas: available meta fields
    Returns: the name of the first meta
*/
function get_default_meta(available_metas) {
    return available_metas[0].name;
}
