import {
    CLEAR_CURRENT_ADMIN_ACCOUNT,
    CLEAR_CURRENT_ADMIN_PLANT,
    CLEAR_CURRENT_ADMIN_PLANT_ITEMS,
    CLEAR_CURRENT_PLANT,
    SET_CURRENT_ADMIN_ACCOUNT,
    SET_CURRENT_ADMIN_PLANT,
    SET_CURRENT_ADMIN_PLANT_ITEMS,
    SET_CURRENT_PLANT,
    SET_PLANTLIST,
} from '../actions';

const plants = (
    state = {
        currentPlant: {},
        currentAdminPlant: {},
        currentAdminAccount: {},
        currentAdminPlantItems: [],
    },
    action
) => {
    switch (action.type) {
        case SET_CURRENT_ADMIN_PLANT_ITEMS:
            return {
                ...state,
                currentAdminPlantItems: action.data,
            };

        case CLEAR_CURRENT_ADMIN_PLANT_ITEMS:
            return {
                ...state,
                currentAdminPlantItems: null,
            };

        case CLEAR_CURRENT_PLANT:
            return {
                ...state,
                currentPlant: null,
            };

        case CLEAR_CURRENT_ADMIN_PLANT:
            return {
                ...state,
                currentAdminPlant: null,
            };

        case CLEAR_CURRENT_ADMIN_ACCOUNT:
            return {
                ...state,
                currentAdminAccount: null,
            };

        case SET_CURRENT_PLANT:
            return {
                ...state,
                currentPlant: action.data,
            };

        case SET_CURRENT_ADMIN_PLANT:
            return {
                ...state,
                currentAdminPlant: action.data,
            };

        case SET_CURRENT_ADMIN_ACCOUNT:
            return {
                ...state,
                currentAdminAccount: action.data,
            };

        case SET_PLANTLIST:
            return {
                ...state,
                plantList: action.data,
            };

        default:
            return state;
    }
};

export default plants;
