import React, { useContext } from 'react';
import { EditPaneContext } from './DashboardEditPane';
import { ReactComponent as DeleteIcon } from '../../../images/new/optimar_cross.svg';

export default function DistributionBuckets(props) {
    const [state, setSettings] = useContext(EditPaneContext);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const tmp = Object.assign([], state);
        tmp[props.index][name] = value;

        setSettings(tmp);
    };

    return (
        <div className="component-setting">
            <fieldset className="component-fieldset">
                <legend>Range {props.index + 1}</legend>
                <div className="component-fields" style={{ position: 'relative' }}>
                    <DeleteIcon
                        onClick={() => props.delete(props.index)}
                        className="delete-icon"
                        style={{ position: 'absolute', right: '0' }}
                    />
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={state[props.index].name}
                            onChange={(e) => handleChange(e)}
                            className="input-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="dataValue">Range start</label>
                        <input
                            type="number"
                            required
                            name="rangeMin"
                            className={'input-control'}
                            onChange={(e) => handleChange(e)}
                            value={state[props.index].rangeMin}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="dataValue">Range end</label>
                        <input
                            type="number"
                            required
                            name="rangeMax"
                            className={'input-control'}
                            onChange={(e) => handleChange(e)}
                            value={state[props.index].rangeMax}
                        />
                    </div>
                </div>
            </fieldset>
        </div>
    );
}
