import React from 'react';
import Modal from 'react-modal';
import AccountOperationViews from './../accountoperationviews';
import { MultiSelect } from "react-multi-select-component";
import { GanttTimeSwitcher } from "../ganttview/gantttimeswitcher"; 
import { ViewSwitcher } from "../viewswitcher";  
import { modalStyle } from '../../../utility/modalstyles';
import JobAccountAddEdit from '../../../components/common/JobAccountAddEdit/JobAccountAddEdit';
import GeneralButton, { ADD_UPDATE_BUTTON, CANCEL_BUTTON } from '../../../components/common/Buttons/generalButton/GeneralButton';
import DateRangePicker from '../../../components/common/DateRangePicker/DateRangePicker';
import './accountjobheader.scss';

function AccountJobHeader(props) {
    const [modalOpen, setModalState] = React.useState(false);
    const [currentItem, setCurrentItem] = React.useState(null);
    const [editType, setEditType] = React.useState('edit');

    const modalRef = React.useRef();

    const plantOptionHandler = (options) => {
        props.onPlantSelectedOptionsChange(options);
    };

    const customValueRenderer = (selected, _options) => {
        return selected.length === 1
          ? '1 plant' 
          : selected.length > 0 && selected.length === _options.length
          ? "All plants"
          : selected.length > 1 
          ? selected.length + ' plants'
          : "No plants selected";
    };

    const toggleModal = (item, type) => {
        setEditType(type);
        setCurrentItem(item);
        setModalState(!modalOpen);
    };

    return (
        <>
            <div className='gt-header'>
                <div className="gt-header-left">
                    <h3 className="gt-header-count">{props.numberOfPlants} {(props.numberOfPlants === 0 || props.numberOfPlants === 1) ? 'Plant' : 'Plants'}</h3>
                    <h3 className="gt-header-button" onClick={() => toggleModal(null, 'new')}>+ Add Operation</h3>                     
                </div>                            
                <div className="gt-header-center">
                    { props.accountOperationView === AccountOperationViews.GanttChart && 
                        <div className='item'>
                            <GanttTimeSwitcher
                                view={props.ganttView}
                                onViewModeChange={props.onGanttViewModeChange}
                            />
                        </div>
                    } 
                    <div className='item'> 
                        <div className='item-multi-select'>
                            <MultiSelect
                                valueRenderer={customValueRenderer}
                                options={props.plantOptions}
                                value={props.plantSelectedOptions}
                                onChange={plantOptionHandler}
                                overrideStrings={{
                                    "allItemsAreSelected": "All plants",
                                    "clearSearch": "Clear Search",
                                    "clearSelected": "Clear Selected",
                                    "noOptions": "No plants",
                                    "search": "Search plants",
                                    "selectAll": "Select all",
                                    "selectAllFiltered": "Select All (Filtered)",
                                    "selectSomeItems": "Select...",
                                    "create": "Create",
                                }}
                            />
                            </div>                          
                    </div> 
                    <div className='item'>
                        <DateRangePicker 
                            value={ props.dateRange }
                            onChange={props.onDateRangeChange}/>
                    </div>
                    { props.accountOperationView === AccountOperationViews.GanttChart && 
                        <>
                            <GeneralButton
                                className={CANCEL_BUTTON}
                                hidden={props.disabledSaveChanges}
                                onClick={props.onClearOperationChanges}
                                style={{ marginLeft: '30px' }}
                            >
                                Cancel
                            </GeneralButton>
                            <GeneralButton
                                className={ADD_UPDATE_BUTTON}
                                disabled={props.disabledSaveChanges}
                                onClick={props.onSaveChanges}
                                style={{ marginLeft: '30px' }}
                            >
                                {props.savingState ? 'Saving...' : 'Save Changes'}
                            </GeneralButton>
                        </>
                    }
                </div>             
                <div className="gt-header-right">
                    <ViewSwitcher onClick={props.onViewSwitcherClick} View={props.accountOperationView}/> 
                </div>
            </div>
            <Modal
                ref={modalRef}
                isOpen={modalOpen}
                style={modalStyle}
                onRequestClose={() => {
                    // Fetch jobs when modal is closed to keep in sync
                    props.fetchJobs();
                    toggleModal();
                }}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <JobAccountAddEdit
                    modalRef={modalRef}
                    editItem={currentItem}
                    editType={editType}
                    toggleModal={(toggletype) => {
                        if (toggletype !== "Cancel") {
                            setTimeout(() => {
                                props.fetchJobs();
                              }, "500");
                        }                         
                        toggleModal(null);
                    }}
                />
            </Modal>  
        </>
    );
}

export default AccountJobHeader;
