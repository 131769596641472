import React from 'react';

import { confirmAlert } from 'react-confirm-alert';

import AggregationDatapoint from './AggregationDatapoint';
import BarchartDatapoint from './BarchartDatapoint';
import ChartDatapoint from './ChartDatapoint';
import ChildrentableDatapoint from './ChildrentableDatapoint';
import ProcessnotesDatapoint from './ProcessnotesDatapoint';
import { emptyGuid } from '../../../utility/guidFunctions';

import './Datapoints.scss';

export default {
    get_name: get_name,
    get_details: get_details,
};

const datapoints = {
    aggregation: AggregationDatapoint(),
    barchart: BarchartDatapoint(),
    chart: ChartDatapoint(),
    childrentable: ChildrentableDatapoint(),
    processnotes: ProcessnotesDatapoint(),
};

function get_name(type) {
    if (type in datapoints) return datapoints[type].name;

    return 'N/A';
}

function get_details(data) {
    if (data.type in datapoints) return datapoints[data.type].get_details(data);

    return 'N/A';
}

function get_editor(config, onInputChange, props) {
    if (config.type in datapoints) return datapoints[config.type].get_editor(config, onInputChange, props);

    return null;
}

export function DatapointEdit(props) {
    const [data, setData] = React.useState(props.config);

    const onInputChange = (name, value) => {
        if (name === 'na') {
            setData(value);
        } else {
            const updatedData = { ...data };
            updatedData[name] = value;
            //console.log("setdata",name,value);
            setData(updatedData);
        }
    };

    return (
        <>
            <h2 className="form-title">Edit {get_name(data.type)} Datapoint</h2>

            <div className="form-group">
                <label htmlFor="datapointId">Datapoint ID</label>
                <input
                    autoComplete="off"
                    type="text"
                    className={'input-control'}
                    name="datapointId"
                    value={data.datapointId || ''}
                    required={true}
                    onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="jobtyperef">Operation type ref</label>
                <select
                    required
                    name="jobtyperef"
                    className={'input-control'}
                    onChange={(event) => onInputChange(event.currentTarget.name, event.currentTarget.value)}
                    value={data.jobtyperef}
                >
                    <option key={'default'} value={emptyGuid()}>
                        Default
                    </option>
                    {props.child_jobs
                        ? props.child_jobs.map((element, index) => {
                              return (
                                  <option key={'child_type_' + index} value={element.id}>
                                      {element.name}
                                  </option>
                              );
                          })
                        : null}
                </select>
            </div>
            {get_editor(data, onInputChange, {
                items: props.items,
                //datavalues: props.datavalues,
                getDatavaluesFunc: props.getDatavaluesFunc,
                child_jobs: props.child_jobs,
                job_type: props.job_type,
            })}

            <div className="buttons">
                <button onClick={props.closeCallback} className="btn btn-yellow">
                    Cancel
                </button>
                <button
                    onClick={() => {
                        props.saveCallback(data);
                        props.closeCallback(false);
                    }}
                    className="btn btn-blue"
                >
                    Save
                </button>
            </div>
        </>
    );
}

export function DatapointNew(props) {
    const [type, setType] = React.useState(Object.keys(datapoints)[0]);
    const [edit_mode, setEditMode] = React.useState(false);
    const [data, setData] = React.useState({});

    const editNewDatapoint = () => {
        setEditMode(true);
        setData(datapoints[type].new_datapoint);
    };

    const onCancelEdit = (ask = true) => {
        if (ask)
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body">
                            <h1>Cancel adding a new datapoint</h1>
                            <p>
                                You are about to cancel adding a new datapoint
                                <br />{' '}
                            </p>
                            <p>Are you sure?</p>
                            <div className="buttons">
                                <button className="btn btn-yellow" onClick={onClose}>
                                    No, cancel
                                </button>
                                <button
                                    className="btn btn-red"
                                    onClick={async () => {
                                        props.closeCallback();
                                        onClose();
                                    }}
                                >
                                    Yes, discard
                                </button>
                            </div>
                        </div>
                    );
                },
            });
        else props.closeCallback();
    };

    const type_selector = (
        <>
            <h2 className="form-title">New Datapoint</h2>
            <div className="form-group">
                <label htmlFor="method">Datapoint Type</label>
                <select
                    required
                    name="type"
                    className={'input-control'}
                    onChange={(event) => setType(event.currentTarget.value)}
                    value={type}
                >
                    {Object.keys(datapoints).map((key, index) => (
                        <option key={'new' + index} value={key}>
                            {get_name(key)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="buttons">
                <button onClick={props.closeCallback} className="btn btn-yellow">
                    Cancel
                </button>
                <button onClick={editNewDatapoint} className="btn btn-blue">
                    Add
                </button>
            </div>
        </>
    );

    return (
        <>
            {edit_mode ? (
                <DatapointEdit
                    config={data}
                    closeCallback={onCancelEdit}
                    saveCallback={props.saveCallback}
                    items={props.items}
                    //datavalues={props.datavalues}
                    getDatavaluesFunc={props.getDatavaluesFunc}
                    child_jobs={props.child_jobs}
                    job_type={props.job_type}
                />
            ) : (
                type_selector
            )}
        </>
    );
}
