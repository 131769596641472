export function get_flat_child_data(childs) {
    const flat_child_data = [];

    Object.entries(childs).forEach(([, child]) => {
        flat_child_data.push({
            id: child.node.id,
            jobType: child.node.jobTypeId,
            startDate: child.node.startDate,
            endDate: child.node.endDate,
            ...JSON.parse(child.node.data),
        });
        flat_child_data.push(...get_flat_child_data(child.children));
    });

    return flat_child_data;
}
export const getConfigurationFromOperationContext = (data, operationContext) => {
    //Get configuration node from operation -> need to work recursively here.
    if (!data?.currentTrip) {
        return null;
    }
    const opId = operationContext.id;

    // let configNode = data.currentTrip.jobs[opId]?.node.configuration
    // if(typeof(configNode) !== "undefined"){
    //     return configNode;
    // }
    const configNode = GetConfigurationRecursively(opId, data.currentTrip?.jobs);

    //return configNode? JSON.parse(configNode):undefined;
    //console.log("getConfigurationFromOperationContext",operationContext,configNode);
    return configNode;
};
function GetConfigurationRecursively(opId, childrenDict) {
    const configNode = childrenDict ? childrenDict[opId]?.node?.configuration : undefined;
    if (typeof configNode !== 'undefined') {
        return configNode;
    }
    const childJobIds = childrenDict ? Object.keys(childrenDict) : [];
    for (let i = 0; i < childJobIds.length; i++) {
        const childJob = childrenDict[childJobIds[i]];
        const childConfigNode = GetConfigurationRecursively(opId, childJob.children);

        if (typeof childConfigNode !== 'undefined') {
            return childConfigNode;
        }
    }
    return null;
}

export function timediff_format(duration) {
    const zeroPad = (number) => (number > 9 ? number : `0${number}`);
    return `${zeroPad(Math.floor(duration.asHours()))}:${zeroPad(Math.floor(duration.asMinutes() % 60))}`;
}

export async function get_image_from_url(url) {
    try {
        const response = await fetch(url);

        const convertBlobToBase64 = (blob) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onerror = reject;
                reader.onload = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });

        return await convertBlobToBase64(await response.blob());
    } catch (err) {
        console.log('Error fetching image from url', url, err);
        return undefined;
    }
}
