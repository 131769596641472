import React from 'react';
import { ConfirmationAlert } from './ConfirmationAlert';

/**
 * @type {React.FC<{
 *     onCancel: React.MouseEventHandler<HTMLButtonElement>;
 *     onConfirm: React.MouseEventHandler<HTMLButtonElement>;
 * }>}
 */
export const ConfirmDeleteRowAlert = (props) => {
    return <ConfirmationAlert title="Delete row" description="This will delete the selected row" {...props} />;
};
