import { canvasPreview } from './canvasPreview';

let previewUrl = '';

/**
 * Return a canvas as a blob
 * @param {HTMLCanvasElement} canvas
 * @returns {Promise<Blob>}
 */
function toBlob(canvas) {
    return new Promise((resolve) => {
        canvas.toBlob(resolve);
    });
}

/**
 * Returns an image source you should set to state and pass
 * `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
 * @param {HTMLImageElement} image
 * @param {PixelCrop} crop
 * @param {number} scale
 * @param {number} rotate
 * @returns
 */
export async function imgPreview(image, crop, scale = 1, rotate = 0) {
    const canvas = document.createElement('canvas');
    canvasPreview(image, canvas, crop, scale, rotate);

    const blob = await toBlob(canvas);
    if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
    }

    if (blob) {
        previewUrl = URL.createObjectURL(blob);
    }
    return previewUrl;
}
