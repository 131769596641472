import React from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import './AdminItemTypeAddEdit.scss';

class AdminItemTypeAddEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formErrors: { name: '', typeKey: '' },
            nameValid: false,
            typeKeyValid: false,
            isComponentOrIsStructuralValid: false,
            dataTypeValid: false,
            isDirty: false,
            editItemType: { isComponent: true },
            enableSubmit: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
        this.validateField = this.validateField.bind(this);
    }

    componentDidMount() {
        if (this.props.editItemType) {
            const tmp = Object.assign({}, this.props.editItemType);
            if (!tmp.dataTypeId) {
                tmp.dataTypeId = '0';
            }
            this.setState({
                editItemType: tmp,
                mode: 'edit',
            });
        }
    }

    handleSubmit(event) {
        if (this.state.mode === 'edit') {
            this.updateSubmit(event);
        } else {
            this.createSubmit(event);
        }
    }

    updateSubmit(event) {
        event.preventDefault();

        const tmpItemType = Object.assign({}, this.state.editItemType);
        console.log(tmpItemType);

        tmpItemType.isComponent = tmpItemType.isComponent ? true : false;
        tmpItemType.isStructural = tmpItemType.isStructural ? true : false;
        tmpItemType.isTitle = false;
        tmpItemType.isFolder = false;

        api.put(process.env.REACT_APP_API + '/api/itemTypes/' + tmpItemType.id, tmpItemType)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Item type updated', 'Item type successfully updated', 5000);
                    this.props.editedCallback(response.data);
                } else {
                    throw new Error(response.status);
                }
            })
            .catch(() => {
                NotificationManager.error('Error', 'Failed to update item type', 5000);
            });
    }

    createSubmit(event) {
        event.preventDefault();
        const tmpItemType = Object.assign({}, this.state.editItemType);

        tmpItemType.isComponent = tmpItemType.isComponent ? true : false;
        tmpItemType.isStructural = tmpItemType.isStructural ? true : false;
        tmpItemType.isTitle = false;
        tmpItemType.isFolder = false;

        api.post(process.env.REACT_APP_API + '/api/itemTypes', tmpItemType)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Item type created', 'Item type successfully created', 5000);
                    this.props.cancelCallback();
                } else {
                    throw new Error(response.status);
                }
            })
            .catch(() => {
                NotificationManager.error('Error', 'Failed to item type', 5000);
            });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const tmp = this.state.editItemType;
        tmp[name] = value;

        this.setState({ editItemType: tmp, isDirty: true });
    }

    validateField(fieldName, value) {
        const fieldValidationErrors = this.state.formErrors;

        let nameValid = this.state.nameValid;
        let typeKeyValid = this.state.typeKeyValid;

        switch (fieldName) {
            case 'name':
                nameValid = value.length >= 2;
                fieldValidationErrors.name = nameValid ? '' : ' is invalid';
                break;
            case 'typeKey':
                typeKeyValid = value.length >= 2;
                fieldValidationErrors.typeKey = typeKeyValid ? '' : ' is invalid';
                break;

            default:
                break;
        }
        this.setState({ formErrors: fieldValidationErrors });
    }

    handleCheckboxClick(target) {
        const editItemType = { ...this.state.editItemType };
        const currentValue = editItemType[target];

        if (currentValue) {
            return;
        } else {
            if (target === 'isStructural') {
                editItemType.isComponent = false;
            } else {
                editItemType.isStructural = false;
            }
            editItemType[target] = !editItemType[target];
        }
        this.setState({ editItemType });
    }

    render() {
        return (
            <div className="admin-itemtype-add-edit-form">
                {this.state.mode === 'edit' ? <h1>Edit item type</h1> : <h1>New item type</h1>}
                <form className="add-edit-form" onSubmit={this.handleSubmit}>
                    <div>
                        <label htmlFor="name">Item type name:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            required
                            name="name"
                            value={this.state.editItemType.name || ''}
                            onChange={this.handleChange}
                        />
                    </div>

                    <div>
                        <label htmlFor="configuration">Configuration:</label>
                        <textarea
                            className={'admin-input-control'}
                            name="configuration"
                            value={this.state.editItemType.configuration || ''}
                            onChange={this.handleChange}
                        />
                    </div>

                    <div className="btn-row btn-row-grow">
                        <button className="btn btn-yellow btn-grow" onClick={this.props.cancelCallback}>
                            Cancel
                        </button>
                        <button className="btn btn-blue btn-grow" disabled={!this.state.enableSubmit} value="Submit">
                            {this.state.mode === 'edit' ? 'Save changes' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

AdminItemTypeAddEdit.propTypes = {
    editItemType: PropTypes.object,
};

export default AdminItemTypeAddEdit;
