import PropTypes from 'prop-types';
import React from 'react';

/**
 * @type {React.FC<{
 *     title: String;
 *     description: String;
 *     onCancel: React.MouseEventHandler<HTMLButtonElement>;
 *     onConfirm: React.MouseEventHandler<HTMLButtonElement>;
 * }>}
 */
export const ConfirmationAlert = ({ title, description, onCancel, onConfirm }) => {
    return (
        <div className="react-confirm-alert-body">
            <>
                <h1>{title}</h1>
                <p>
                    {description}
                    <br />
                </p>
            </>
            <p>Are you sure?</p>
            <div className="buttons">
                <button className="btn btn-yellow" onClick={onCancel}>
                    No, cancel
                </button>
                <button className="btn btn-blue" onClick={onConfirm}>
                    Yes
                </button>
            </div>
        </div>
    );
};

ConfirmationAlert.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
