import React from 'react';
import './Spinner.scss';
import loading from '../../../images/fish-loader.svg';

const Spinner = ({ text, className }) => {
    return (
        <div className={'loading-area ' + className}>
            {/* <FontAwesome name="spinner" size="2x" spin/> */}
            <img src={loading} alt="Loading..." className="fa-spin" />
            <span className="loading-text">{`Loading ${text || 'items'}...`}</span>
        </div>
    );
};

export default Spinner;
