import React from 'react';
import { connect } from 'react-redux';
import {
    clearCurrentPlant,
    clearDateRange,
    getDashboardConfig,
    setCurrentPlant,
    setDashboardConfig,
    setEndDate,
    setPeriod,
    setWidget,
} from '../../actions';
import ProgressWheel from '../../components/Widgets/ProgressWheel/ProgressWheel';
import UserInput from '../../components/Widgets/UserInput/UserInput';
import ValueCell from '../../components/Widgets/ValueCell/ValueCell';
import './dashboard.scss';

import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import Modal from 'react-modal';
import Spinner from '../../components/common/Spinner/Spinner';

import { findIndex, forEach } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';
import OptiControlLive from '../../components/Solutions/OptiControlLive/OptiControlLive';
import BarGauge from '../../components/Widgets/BarGauge/BarGauge';
import DepthDistributionChart from '../../components/Widgets/Charts/DepthDistributionChart/DepthDistributionChart';
import SizeDistributionChart from '../../components/Widgets/Charts/SizeDistributionChart/SizeDistributionChart';
import DatavalueLogList from '../../components/Widgets/DatavalueLogList/DatavalueLogList';
import Gauge from '../../components/Widgets/Gauge/Gauge';
import HeatMap from '../../components/Widgets/HeatMap/HeatMap';
import Histogram from '../../components/Widgets/Histogram/Histogram';
import LineChartDotted from '../../components/Widgets/LineChartDotted/LineChartDotted';
import LineChartRegular from '../../components/Widgets/LineChartRegular/LineChartRegular';
import Buoy from '../../components/Widgets/LinkButton/LinkButton';
import LinkButtonGroup from '../../components/Widgets/LinkButtonGroup/LinkButtonGroup';
import NoteLogList from '../../components/Widgets/NoteLogList/NoteLogList';
import OptiControl from '../../components/Widgets/OptiControl/OptiControl';
import Signal from '../../components/Widgets/Signal/Signal';
import Statistics from '../../components/Widgets/Statistics/Statistics';
import StatusSlider from '../../components/Widgets/StatusSlider/StatusSlider';
import TankGauge from '../../components/Widgets/TankGauge/TankGauge';
import Text from '../../components/Widgets/Text/Text';
import ValueCellGroup from '../../components/Widgets/ValueCellGroup/ValueCellGroup';
import VerticalBar from '../../components/Widgets/VerticalBar/VerticalBar';
import { getToAndFromBasedOnPeriodOrJob, IsRangeValid } from '../../components/Widgets/widgetHelper';
import { withRouteMatch } from '../../HOC';
import api from '../../utility/api';
import { emptyGuid, guidIsNullOrEmpty, newGuid } from '../../utility/guidFunctions';
import { newLocalizedId } from '../../utility/numberFunctions';
import { WidgetFormulaManager } from '../../utility/widgetformulamanager';
import DashboardEditPane from '../dashboardadmin/DashboardEditPane/DashboardEditPane';
import {
    ConfirmChangeDashboardAlert,
    ConfirmDeleteRowAlert,
    ConfirmDiscardDashboardAlert,
    ConfirmPublishDashboardAlert,
    ConfirmSaveItemTypeDashboardAlert,
    ConfirmSaveJobTypeDashboardAlert,
} from './confirmationAlerts';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
    },
};

const pathUrl = '/admin/account/';
const shouldFetch = false;
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openRow: [],
            openRows: [],
            modalIsOpen: false,
            widgetModalIsOpen: false,
            editMode: false,
            moveActive: null,
            dashboardType: props.dashboardType,
            forceDashboardUpdate: false,
            fetchOnInterval: false,
            fetchInterval: undefined,
            itemId: props.row?.id,
            formulaManager: new WidgetFormulaManager(),
        };

        this.row = null;
        this.column = null;

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openWidgetModal = this.openWidgetModal.bind(this);
        this.closeWidgetModal = this.closeWidgetModal.bind(this);
        this.fetchingConfig = false;
        this.cancelMove = this.cancelMove.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        //this.forceUpdate();

        this.setState(
            {
                forceDashboardUpdate: true,
                config: null,
                editMode: false,
                itemId: this.props.match?.params?.itemId,
                plantId: this.props.match?.params?.plantId,
            },
            () => this.setDashboard(true)
        );
        // console.log((this.props.match.params.itemId))
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        //console.log(6,snapshot,prevState);
        if (prevProps.match && prevProps.match.params.itemId !== this.props.match.params.itemId) {
            // clearInterval(this.state.fetchInterval);
            // this.hasInterval = false;
            console.log('new component', this.props.match);
            this.setState(
                {
                    forceDashboardUpdate: true,
                    dashboardId: null,
                    plantId: this.props.match.params?.plantId,
                    itemId: this.props.match.params?.itemId,
                    dashboardType: this.props.match.params?.itemId ? 'item_default' : 'plant',
                    //config:null
                },
                () => {
                    this.setDashboard(false);
                }
            );

            //this.forceUpdate();
        } else {
            //todo: ensure we are showing the correct dashboard id connected to the given user.. There is some timing issue existing
            //when navigating in the tree view. -> Not sure if it is inside the dashboard.js any more. problem still exists in production as well.
            // console.log("Update triggered but found to be not important",this.props.match.params.itemId)
            // console.log("prevprops",prevProps.match);
            // console.log("currentProps",this.props.match);
        }
        //
    }
    // eslint-disable-next-line lodash/prefer-constant
    getSnapshotBeforeUpdate(_prevProps, _prevState) {
        return 999;
    }

    componentWillUnmount() {
        this.mounted = false;
        //this.hasInterval = false;
        clearInterval(this.state.fetchInterval);
    }

    //clears the current interval function that is in the state object and then returns a new interval object
    //implementing methods should call  setState with the new interval object to prevent memory leaks.
    clearAndSetIntervalUpdateFunc = (interval) => {
        interval = interval || 20000;
        clearInterval(this.state.fetchOnInterval);

        return setInterval(this.intervalUpdateFunc, interval);
    };

    intervalUpdateFunc = () => {
        if (this.state.fetchOnInterval === false || !this.state.config) {
            return;
        }
        const initiator = this.getInitiator(false);
        this.getItemData(initiator, this.state.config, 'regular');
    };

    getInitiator(onMount) {
        return {
            //     'itemId':this.props.match?.params?.itemId,
            //     'plantId':this.props.match?.params?.plantId,
            //     'source': onMount? "mount":"update"
            itemId: this.state.itemId,
            plantId: this.state.plantId,
            source: onMount ? 'mount' : 'update',
        };
    }

    initiatorIsValid(initiator) {
        const currentItemId = this.props.match?.params?.itemId;
        const currentPlant = this.props.match?.params?.plantId;
        return !(
            (initiator.source !== 'mount' && currentItemId !== initiator.itemId) ||
            currentPlant !== initiator.plantId
        );
    }

    setDashboard = async (onMount) => {
        const initiator = this.getInitiator(onMount);
        //console.log("setDashboard",initiator,this.currentDashboardOp);
        // if(this.currentDashboardOp){
        //   //  console.log("awaiting previous async action");
        //     await this.currentDashboardOp;
        // }
        if (this.state.forceDashboardUpdate) {
            this.setState({
                forceDashboardUpdate: false,
            });
            // Non plant dashboard mode
            //if ((this.props.match && this.props.match.params.itemId) || this.props.dashboardType === "job") {
            if (this.state.dashboardType === 'job') {
                this.currentDashboardOp = this.setJobTypeDashboard(initiator);
            } else if (this.state.itemId) {
                // Item dashboard
                //console.log(this.props.match.params.itemId,this.state.dashboardType,this.forceDashboardUpdate);
                this.currentDashboardOp = this.setItemTypeDashboard(initiator);
            } else {
                this.currentDashboardOp = this.setAccountOrPlantDashboard(initiator);
            }
        }
    };

    setAccountOrPlantDashboard = async (initiator) => {
        const dashboardType = this.props.accountMode ? 'account' : this.state.dashboardType; // "plant";
        console.log(dashboardType);
        // No item id - fetching config for plant
        const res = await this.getDashboardConfig(
            this.props.type === 'account'
                ? this.props.match?.params?.dashboardId || null
                : this.props.type === 'edit'
                ? this.props.match.params.dashboardId
                : initiator.plantId
        );
        if (!res?.data) {
            console.log('No data returned on get config', res);
        }
        if (this.mounted && !this.state.editMode) {
            const parsedConfig = res?.data?.configuration ? JSON.parse(res.data.configuration) : {};

            this.setStateDashboardConfig(
                initiator,
                dashboardType,
                res,
                parsedConfig,
                res?.data?.status,
                res?.data?.interval
            );
        } else {
            console.log(
                'Not setting state dashboard conf because of mounted or edit mode',
                this.mounted,
                this.state.editMode
            );
        }
    };

    setItemTypeDashboard = async (initiator) => {
        let dashboardType = this.state.dashboardType;
        try {
            const data = await this.getDashboardConfigForItem(initiator);
            if (data) {
                //got custom item dashboard
                dashboardType = this.determineDashboardType(data); //"item_custom";
                let config = JSON.parse(data.configuration);
                if (this.isItemTypeDashboard(dashboardType)) {
                    config = this.setupItemTypeDashboard(config);
                }

                this.setStateDashboardConfig(initiator, dashboardType, data, config, data.status, data.interval);
            }
        } catch (err) {
            console.error('ERROR: ', err);
            this.setState({
                success: false,
            });
        }
    };
    setJobTypeDashboard = async (initiator) => {
        const dashboardType = this.state.dashboardType;
        try {
            const data = await this.getDashboardConfigForJob(initiator);
            if (data) {
                let config = JSON.parse(data.configuration);
                const isGlobal = this.isGlobalJobTypeDashboard(dashboardType, data);
                if (isGlobal) {
                    config = this.setupGlobalJobTypeDashboard(config);
                }

                this.setStateDashboardConfig(initiator, dashboardType, data, config, data.status, data.interval);
            }
        } catch (err) {
            console.error('ERROR: ', err);
            this.setState({
                success: false,
            });
        }
    };

    setStateDashboardConfig(initiator, dashboardType, data, config, status, interval) {
        //Verifies that the new config still belongs to the current dashboard.. as can happen with async functions.
        //console.log(config);
        //console.log(initiator,this.state.itemId,this.props.match.params.itemId,this.state.plantId);

        //if((this.state.itemId !== initiator.itemId) || (this.state.plantId !== initiator.plantId)){
        if (!this.initiatorIsValid(initiator)) {
            console.log(
                `Item has changed since starting to get dashboard config. Cancelling at this point`,
                initiator,
                this.state.itemId,
                this.state.plantId
            );
            return;
        }
        //Pretty sure this is not the correct place to fix such issues, but.. ensure component settings
        //Has ids in order to match retrieved values later.
        this.ensureComponentSettingsIds(config);
        //Migrate any old config to newer setups if applicable
        config = this.migrateConfigFromSingleTopicToMultiIfApplicable(config);

        this.setState(
            {
                interval,
                success: true,
                dashboardMetadata: data,
                dashboardId: data?.id,
                dashboardStatus: status,
                config,
                dashboardType,
                fetchOnInterval: true,
                fetchInterval: this.clearAndSetIntervalUpdateFunc(interval),
                fetching: false,
            },
            () => {
                this.getItemData(initiator, config, 'regular');
                //this.props.setDashboardConfig(config);;
            }
        );
    }

    determineDashboardType(dashboard) {
        if (!dashboard) {
            return;
        }

        if (dashboard.itemId && dashboard.itemId !== emptyGuid()) {
            return 'item_custom';
        }
        if (dashboard.itemTypeId && dashboard.itemTypeId !== emptyGuid()) {
            return 'item_default';
        }
        // if(dashboard.jobTypeId && dashboard.jobTypeId != emptyGuid()){
        //     return "job";
        // }
    }

    setupItemTypeDashboard(dashboard) {
        if (!this.props.currentItem) {
            console.log('No item retrieved yet.');
            return {};
        }
        const plant = this.props.plant ? this.props.plant : this.props.currentPlant;

        dashboard.configuration.forEach((zone) => {
            zone.widgets.forEach((widget) => {
                widget.components = widget.components?.map((comp) => {
                    const childStructure = this.getChildStructure(comp, this.props.currentItem.topic);
                    return `${plant.topicKey}/${this.props.currentItem.topic}${childStructure}`;
                });
                widget.componentSettings = widget.componentSettings?.map((comp) => {
                    const childStructure = this.getChildStructure(comp.topic, this.props.currentItem.topic);
                    comp.topic = `${plant.topicKey}/${this.props.currentItem.topic}${childStructure}`;
                    return comp;
                });
            });
        });
        
        return dashboard;
    }
    setupGlobalJobTypeDashboard(dashboard) {
        if (!this.props.currentItem) {
            console.log('No item retrieved yet.');
            return {};
        }
        console.log('setupGlobalJobTypeDashboard');

        const getNewTopic = (newTopicKey, comp) => {
            //For job type dashboards we cannot easily replace more than the plant key from the topic..
            return `${newTopicKey}/${this.removeTopicKey(comp)}`;
        };

        const plant = this.props.plant ? this.props.plant : this.props.currentPlant;

        dashboard.configuration.forEach((zone) => {
            zone.widgets.forEach((widget) => {
                widget.components = widget.components?.map((comp) => {
                    const newTopic = getNewTopic(plant.topicKey, comp);
                    console.log(`newTopic: ${newTopic}`);
                    return newTopic;
                });
                widget.componentSettings = widget.componentSettings?.map((comp) => {
                    const newTopic = getNewTopic(plant.topicKey, comp.topic);
                    console.log(`newTopic: ${newTopic}`);
                    comp.topic = newTopic;
                    return comp;
                });
            });
        });
        
        return dashboard;
    }
    hasConfigWithErroneousKeypointers(dashboard) {
        const plant = this.props.plant ? this.props.plant : this.props.currentPlant;

        const zoneWithErr = dashboard.configuration.find((zone) => {
            const firstWidgetErr = zone.widgets.find((widget) => {
                const firstErr = widget.components?.find((comp) => {
                    //Return true if current comp does not contain the current plant key
                    return comp.indexOf(plant.topicKey) < 0;
                });
                return typeof firstErr !== 'undefined';
            });
            return typeof firstWidgetErr !== 'undefined';
        });

        return typeof zoneWithErr !== 'undefined';
    }

    removeTopicKey(comp) {
        if (!comp) {
            return '';
        }
        return comp.slice(comp.indexOf('/') + 1);
    }

    getChildStructure(comp, currentTopic) {
        if (!comp || !currentTopic) {
            return '';
        }
        const currentItemKey = currentTopic.slice(currentTopic.lastIndexOf('/') + 1);
        if (comp.lastIndexOf(currentItemKey) > 0) {
            return comp.slice(comp.lastIndexOf(currentItemKey) + currentItemKey.length);
        }
        return '';
    }

    getConfigAfterEdit = async () => {
        try {
            const initiator = this.getInitiator(false);
            const res = await api.get(`${process.env.REACT_APP_API}/api/dashboard/${this.state.dashboardId}`);
            //.then(res => {
            let config = JSON.parse(res.data.configuration);
            //console.log("getConfigAfterEdit",res.data)
            // if (res.data.itemTypeId !==  emptyGuid() ||
            //     this.isGlobalJobTypeDashboard(this.state.dashboardType,res.data))
            //     config = this.setupItemTypeOrGlobalJobTypeDashboard(config);
            if (res.data.itemTypeId !== emptyGuid()) {
                config = this.setupItemTypeDashboard(config);
            } else if (this.isGlobalJobTypeDashboard(this.state.dashboardType, res.data)) {
                config = this.setupGlobalJobTypeDashboard(config);
            }

            this.setState(
                {
                    success: true,
                    dashboardMetadata: res.data,
                    config,
                },
                () => this.getItemData(initiator, config, 'edit')
            );
        } catch (err) {
            console.error('ERROR: ', err);
            this.setState({
                success: false,
            });
        }
    };

    getDashboardConfigForJob = async (initiator) => {
        let apiUrl = '';
        //apiUrl = process.env.REACT_APP_API + "/api/dashboard/job/" + this.props.match.params.plantId + "/" + this.props.jobTypeId
        apiUrl = `${process.env.REACT_APP_API}/api/dashboard/job/${initiator.plantId}/${this.props.jobTypeId}`;
        //this.dashboardType = "job";

        const res = await api.get(apiUrl);
        //console.log(`Current dashboardId: ${this.state.dashboardId} Asked for ${apiUrl}`,res);
        return res?.data;
    };
    getDashboardConfigForItem = async (initiator) => {
        const apiUrl = `${process.env.REACT_APP_API}/api/dashboard/item/${initiator.itemId}`;

        const res = await api.get(apiUrl);
        //console.log(`Current dashboardId: ${this.state.dashboardId} Asked for ${apiUrl}`,res);
        return res?.data;
    };

    getDashboardConfig = async (id) => {
        //console.log("getDashboardConfig",id)
        if (!this.fetchingConfig) {
            this.fetchingConfig = true;
            const accId = this.getAccountIdFromUrlOrUserProfile();

            const url =
                process.env.REACT_APP_API +
                (this.props.accountMode
                    ? `/api/dashboard/account/${accId}`
                    : `/api/plants/${id}/config?name=dashboard`);
            //console.log("URI", url,this.props.user);
            try {
                const res = await api.get(url);
                //  .then((res) => {
                //this.fetchingConfig = false;
                const data = res.data;
                const returnable = { id: data };
                //this.setState({ dashboardId: dashboardId })

                const dashConfUrl = this.props.accountMode
                    ? `${process.env.REACT_APP_API}/api/dashboard/account/${accId}/${data}`
                    : `${process.env.REACT_APP_API}/api/dashboard/${
                          this.props.type === 'edit' ? id : data
                      }?accountMode=${this.props.accountMode}`;

                const res2 = await api.get(dashConfUrl); //process.env.REACT_APP_API + "/api/dashboard/" + (this.props.accountMode ? null : (this.props.type === "edit" ? id : dashboardId)) + "?accountMode=" + this.props.accountMode)
                //  .then((res) => {
                if (res.status === 204) {
                    this.setState({
                        noConfig: true,
                    });
                }
                this.fetchingConfig = false;
                returnable.data = res2.data;
                return returnable;
            } catch (err) {
                this.fetchingConfig = false;
                console.error(err);
            }
            return;
        }
        console.log('Already fetching config. Aborting');
    };

    getItemData = async (initiator, config, type) => {
        if ((this.state.editMode && type === 'regular') || config === {}) {
            return;
        }

        //Aborting fetching here will fail on subsequent navigations in tree and lead to never getting correct data in some cases.
        //if (!this.fetching) {
        //console.log(this.props.periodParent,this.props.endDateParent,this.props.job);

        this.fetching = true;
        //var to, from;

        let { to, from } = getToAndFromBasedOnPeriodOrJob(this.props.job, this.props.periodParent);
        // if(this.props.job){
        //     from = this.props.job.startDate;
        //     to = this.props.job.endDate || moment();
        // }else{

        //     to = this.props.periodParent.ticks === "day" ? moment(this.props.endDateParent) : moment(this.props.endDateParent).second(0).millisecond(0);
        //     if (!this.props.endDateParent) {
        //         to = this.props.periodParent.ticks === "day" ? moment().minute(0).second(0).millisecond(0).utc() : moment().second(0).millisecond(0).utc();
        //     }
        //     from = this.props.periodParent.ticks === "day" ? to.clone().add(-this.props.periodParent.period, this.props.periodParent.interval).add(moment().utcOffset(), "minutes") : to.clone().minute(0).second(0).millisecond(0).add(-this.props.periodParent.period, this.props.periodParent.interval);
        // }
        config.rangeFormat = this.props.periodParent.ticks || 'hour';
        config.endDate = to;
        config.fromDate = from;

        try {
            const res = await api.post(`${process.env.REACT_APP_API}/api/kpi/batch`, config);

            if (!this.initiatorIsValid(initiator)) {
                console.log('Initiator is no longer valid. Not setting data', initiator);
                this.fetching = false;
                return;
            }

            //.then((res) => {
            if (this.mounted) {
                if (this.state.editMode && type === 'regular') {
                    return;
                }
                const appliedFormula = this.state.formulaManager.applyWidgetFormula(res.data);
                this.setState({
                    config: appliedFormula,
                });
            }
            this.fetching = false;
        } catch (error) {
            console.log('Error fetching dashboard data', error);
            this.fetching = false;
        }
        //}
    };

    check() {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 600;
    }

    toggleRow(item) {
        // eslint-disable-next-line lodash/matches-prop-shorthand
        const index = findIndex(this.state.openRows, (r) => r.row.title === item.row.title);

        let openRows = [];
        if (index === -1) {
            //new row and widget
            this.setState({
                openRows: [...this.state.openRows, item], //show row with widget
            });
        } else {
            const a = this.state.openRows[index].widgetIndex === item.widgetIndex;

            if (a) {
                // same widget on same row
                this.setState({
                    openRows: [...this.state.openRows.slice(0, index), ...this.state.openRows.slice(index + 1)], //remove widget -- clicked the same
                });
            } else {
                // new widget on same row
                openRows = Object.assign([], this.state.openRows);

                openRows = [...openRows.slice(0, index), ...openRows.slice(index + 1)]; //remove widget on same row
                openRows.push(item); //add new

                this.setState({
                    openRows,
                });
            }
        }

        if (!this.check()) {
            this.openModal();
        }
    }

    editWidget(widget, row, column) {
        console.log('WID', widget);
        this.migrateWidgetConfigFromSingleTopicToMultiIfApplicable(widget);
        this.props.setWidget(widget);

        this.openWidgetModal(false, row, column);
    }

    closeHistogram = (activeRow) => {
        // eslint-disable-next-line lodash/matches-prop-shorthand
        const index = findIndex(this.state.openRows, (r) => r.row.title === activeRow.row.title);

        this.setState({
            openRows: [...this.state.openRows.slice(0, index), ...this.state.openRows.slice(index + 1)], //remove widget -- clicked the same
        });
    };

    openModal() {
        this.setState({
            modalIsOpen: true,
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
        });
    }

    openWidgetModal(newMode, row, column) {
        this.row = row;
        this.column = column;

        this.setState({
            widgetModalIsOpen: true,
            newMode,
        });
    }

    closeWidgetModal() {
        this.props.setWidget();
        this.setState({
            widgetModalIsOpen: false,
        });

        this.getConfigAfterEdit();
    }

    handleRowCheck(val) {
        const tmp1 = val.widgets.map((x) => {
            const ret = Object.assign({}, x);
            ret.data = null;
            return ret;
        });
        return this.state.openRows.find(
            (item) =>
                JSON.stringify(
                    item.row.widgets.map((x) => {
                        const ret = Object.assign({}, x);
                        ret.data = null;
                        return ret;
                    })
                ) === JSON.stringify(tmp1)
        );
    }

    addWidgetIds(config) {
        try {
            for (const row of config.configuration) {
                // eslint-disable-next-line
                for (const widget of row.widgets) {
                    if (!widget.wId) {
                        widget.wId = newGuid(); // _this.uuidv4();
                    }
                }
            }
        } catch (err) {
            console.log('Error setting widget ids. possible empty row.', err);
        }

        return config;
    }

    getAccountIdFromUrlOrUserProfile() {
        if (this.props.accountMode && window.location.pathname.startsWith(pathUrl)) {
            return window.location.pathname.split('/')[3];
        }
        return this.props.user.profile.AccountId;
    }

    ensureComponentSettingsIds(dashboard) {
        if (!dashboard?.configuration) {
            return dashboard;
        }
        dashboard.configuration.forEach((zone) => {
            zone.widgets.forEach((widget) => {
                if (!widget.componentSettings) {
                    return;
                }
                widget.componentSettings.forEach((comp) => {
                    if (typeof comp.id === 'undefined' || comp.id === 0) {
                        comp.id = newLocalizedId();
                    }
                });
            });
        });
        return dashboard;
    }
    migrateConfigFromSingleTopicToMultiIfApplicable(dashboard) {
        if (!dashboard?.configuration) {
            return dashboard;
        }
        dashboard.configuration.forEach((zone) => {
            zone.widgets.forEach((widget) => {
                this.migrateWidgetConfigFromSingleTopicToMultiIfApplicable(widget);
            });
        });
        return dashboard;
    }

    migrateWidgetConfigFromSingleTopicToMultiIfApplicable(widget) {
        const migratableTypes = ['single-cell', 'linechart'];

        if (!widget || !migratableTypes.includes(widget.type) || widget.componentSettings?.length > 0) {
            return;
        }
        console.log('migrating..', widget);
        if (!widget.componentSettings) {
            widget.componentSettings = [];
        }

        widget.components.forEach((compString) => {
            widget.componentSettings.push({
                id: newLocalizedId(),
                dataType: widget.dataType,
                dataValue: widget.dataValue,
                name: '',
                plantId: widget.plant,
                topic: compString,
                unit: widget.configuration?.unit,
            });
        });
        widget.components = [];
        widget.dataType = null;
        widget.dataValue = null;
    }

    editDashboard = async (editMode, _refreshConfig) => {
        if (!editMode) {
            const accId = this.getAccountIdFromUrlOrUserProfile();
            //clearInterval(this.state.fetchInterval);
            this.setState({ fetchOnInterval: false });

            const initiator = this.getInitiator(false);
            //this.hasInterval = false;

            let url = `${process.env.REACT_APP_API}/api/dashboard/edit`;

            if (this.state.dashboardType === 'plant') {
                url += `/plant/${this.props.match.params.plantId}/${this.state.dashboardId}`;
            } else if (this.state.dashboardType === 'job') {
                url +=
                    '/job/' +
                    this.props.jobTypeId +
                    '/' +
                    this.state.dashboardId +
                    '?plantId=' +
                    this.props.match.params.plantId;
            } else if (this.state.dashboardType === 'account') {
                url += `/account/${accId}/${this.state.dashboardId}?accountMode=${this.props.accountMode}`;
            } else {
                url += `/item/${this.props.match.params.itemId}/${this.state.dashboardId}`;
            }

            try {
                const res = await api.post(url);
                // console.log("switching to edit mode",res.data,url);
                //.then(res => {
                //console.log("editdash",res.data,this.isGlobalJobTypeDashboard(this.state.dashboardType,res.data))
                //When modifying job type dashboards, they will never be "global" at this point because it has already been stored in db with plantid
                //-> Instead of checking if it is global, we check if it is a custom job type dashboard, but also has pointers to other plants..
                let config = this.addWidgetIds(JSON.parse(res.data.configuration));
                if (res.data.itemTypeId !== emptyGuid()) {
                    config = this.setupItemTypeDashboard(config);
                } else if (
                    this.isCustomJobTypeDashboard(this.state.dashboardType, res.data) &&
                    this.hasConfigWithErroneousKeypointers(config)
                ) {
                    config = this.setupGlobalJobTypeDashboard(config);
                }

                this.props.setDashboardConfig(config);
                console.log(config);
                this.setState(
                    {
                        success: true,
                        forceDashboardUpdate: false,
                        editMode: !this.state.editMode,
                        dashboardId: res.data.id,
                        dashboardMetadata: res.data,
                        config,
                    },
                    () => this.getItemData(initiator, config, 'edit')
                );
            } catch (err) {
                console.error('ERROR: ', err);
                this.setState({
                    success: false,
                });
            }
            return;
        }
        //this.forceDashboardUpdate = true;
        this.setState({
            fetchOnInterval: true,
            forceDashboardUpdate: true,
            editMode: !this.state.editMode,
        });
    };

    discardDashboard = async () => {
        try {
            await api.post(`${process.env.REACT_APP_API}/api/dashboard/discard/${this.state.dashboardId}`);

            //this.forceDashboardUpdate = true;
            //clearInterval(this.state.fetchInterval);
            this.setState(
                {
                    success: true,
                    forceDashboardUpdate: true,
                    dashboardId: null,
                    editMode: !this.state.editMode,
                    //fetchInterval: null
                    fetchOnInterval: false,
                },
                () => {
                    this.setDashboard();
                }
            );
            NotificationManager.success(
                'Dashboard draft discarded. Returning to the latest published version',
                'Dashboard draft discarded',
                5000
            );
        } catch (err) {
            console.error('ERROR: ', err);
            this.setState({
                success: false,
            });
            NotificationManager.error('Unable to discard dashboard', 'Error discarding dashboard', 5000);
        }
    };

    publishDashboard = async (itemTypeDash = false, jobTypeDash = false) => {
        let url = `/api/dashboard/publish/${this.state.dashboardId}`;
        if (this.props.match && this.props.match.params.itemId) {
            url += `?itemTypeDash=${itemTypeDash}&itemId=${this.props.match.params.itemId}`;
        } else if (jobTypeDash && this.props.dashboardType === 'job') {
            url += `?jobTypeDash=${jobTypeDash}&jobTypeId=${this.props.jobTypeId}`;
        }
        // else if(!jobTypeDash && this.isCustomJobTypeDashboard(this.props.dashboardType,this.state.dashboardMetadata))
        // {
        //     url +=
        // }
        //console.log("URL", url);
        try {
            this.setState({ fetchOnInterval: false });
            await api.post(url);
            //.then(res => {
            //this.forceDashboardUpdate = true;
            //clearInterval(this.state.fetchInterval);
            this.setState(
                {
                    forceDashboardUpdate: true,
                    success: true,
                    editMode: !this.state.editMode,
                    fetchOnInterval: true,
                },
                () => {
                    this.setDashboard();
                }
            );
            if (itemTypeDash) {
                NotificationManager.success('Item type dashboard published successfully', 'Dashboard published', 5000);
            } else if (jobTypeDash) {
                NotificationManager.success(
                    'Operation type dashboard published successfully',
                    'Dashboard published',
                    5000
                );
            } else {
                NotificationManager.success('Dashboard published successfully', 'Dashboard published', 5000);
            }
        } catch (err) {
            console.error('ERROR: ', err);
            this.setState({
                success: false,
            });
            NotificationManager.error('Unable to publish dashboard', 'Error publishing dashboard', 5000);
        }
    };

    findWidget(configuration, wId) {
        // eslint-disable-next-line
        for (const row of configuration) {
            // eslint-disable-next-line
            for (const widget of row.widgets) {
                if (widget.wId === wId) {
                    return widget;
                }
            }
        }
    }

    addDeleteNewRow = async (e, action, row) => {
        e.preventDefault();

        const dashboardConfig = Object.assign({}, this.props.editConfig);

        if (action === 'delete') {
            dashboardConfig.configuration.splice(row, 1);
        } else {
            dashboardConfig.configuration.splice(row, 0, { title: '', size: '', layout: '', widgets: [] });
        }
        try {
            await api.put(`${process.env.REACT_APP_API}/api/dashboard/${this.state.dashboardId}`, dashboardConfig);
            //    .then(res => {
            this.setState({
                success: true,
            });
            this.props.setDashboardConfig(dashboardConfig);
            this.getConfigAfterEdit();

            NotificationManager.success(
                `Row ${action === 'delete' ? 'removed' : 'added'} successfully`,
                `Row ${action === 'delete' ? 'removed' : 'added'}`,
                5000
            );
        } catch (err) {
            console.error('ERROR: ', err);
            this.setState({
                success: false,
            });

            NotificationManager.error('Row not changed successfully', 'Error updating rows', 5000);
        }
    };

    confirmDeleteRow(row) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmDeleteRowAlert
                    onCancel={onClose}
                    onConfirm={(e) => {
                        this.addDeleteNewRow(e, 'delete', row);
                        onClose();
                    }}
                />
            ),
        });
    }

    moveWidget = async (e, widget, row, column) => {
        e.stopPropagation();

        if (this.state.moveActive) {
            const dashboardConfig = Object.assign({}, this.props.editConfig);
            const widgetToDelete = this.findWidget(dashboardConfig.configuration, this.state.moveActive);

            dashboardConfig.configuration[this.state.moveFromRow].widgets.splice(this.state.moveFromColumn, 1);

            const widgetCopyToMove = Object.assign({}, widgetToDelete);
            dashboardConfig.configuration[row].widgets.splice(column, 0, widgetCopyToMove);

            let success = true;
            try {
                await api.put(`${process.env.REACT_APP_API}/api/dashboard/${this.state.dashboardId}`, dashboardConfig);
                //.then(res => {
                // this.setState({
                //     success: true
                // })
                NotificationManager.success('Widget moved successfully', 'Widget moved', 5000);
                this.props.setDashboardConfig(dashboardConfig);
                this.getConfigAfterEdit();
            } catch (err) {
                console.error('ERROR: ', err);
                // this.setState({
                //     success: false
                // })
                success = false;
                NotificationManager.error('Widget not updated successfully', 'Error updating widget', 5000);
            }

            this.setState({
                sucess: success,
                moveActive: null,
                moveFromRow: null,
                moveFromColumn: null,
            });
            return;
        }
        this.setState({
            moveActive: widget.wId,
            moveFromRow: row,
            moveFromColumn: column,
        });
    };

    cancelMove() {
        this.setState({
            moveActive: null,
            moveFromRow: null,
            moveFromColumn: null,
        });
    }

    confirmPublishDashboard() {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmPublishDashboardAlert
                    onCancel={onClose}
                    onConfirm={() => {
                        this.publishDashboard();
                        onClose();
                    }}
                />
            ),
        });
    }
    confirmSaveItemTypeDashboard() {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmSaveItemTypeDashboardAlert
                    onCancel={onClose}
                    onConfirm={() => {
                        this.publishDashboard(true);
                        onClose();
                    }}
                />
            ),
        });
    }
    confirmSaveJobTypeDashboard() {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmSaveJobTypeDashboardAlert
                    onClose={onClose}
                    onConfirm={() => {
                        this.publishDashboard(false, true);
                        onClose();
                    }}
                    onCancel={onClose}
                />
            ),
        });
    }

    confirmDiscardDashboard() {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmDiscardDashboardAlert
                    onConfirm={() => {
                        this.discardDashboard();
                        onClose();
                    }}
                    onClose={onClose}
                    onCancel={onClose}
                />
            ),
        });
    }

    confirmChangeDashboard(revert) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmChangeDashboardAlert
                    revert={revert}
                    onConfirm={() => {
                        this.changeDashboard(revert);
                        onClose();
                    }}
                    onClose={onClose}
                    onCancel={onClose}
                />
            ),
        });
    }

    changeDashboard = async (revert) => {
        const configToCopy = Object.assign({}, this.state.config);
        if (!revert) {
            forEach(configToCopy.configuration, (row) => {
                forEach(row.widgets, (w) => {
                    //Set missing id on widgets
                    if (!w.wId) {
                        w.wId = newGuid(); // _this.uuidv4();
                    }
                    //remove any cached data
                    delete w.data;
                });
            });
        }
        const revertId = this.props.job
            ? `${this.props.jobTypeId}?job=true&plantId=${this.props.match.params.plantId}`
            : this.props.match.params.itemId;
        try {
            this.setState({ fetchOnInterval: false });
            const res = await api.post(
                process.env.REACT_APP_API +
                    '/api/dashboard/' +
                    (revert
                        ? `revert/${revertId}`
                        : `new/${this.props.match.params.itemId}/${this.props.match.params.plantId}`),
                configToCopy
            );
            // .then(res => {
            if (revert) {
                //this.forceDashboardUpdate = true;
                //clearInterval(this.state.fetchInterval);
            }
            //clearInterval(this.state.fetchInterval);
            this.setState(
                {
                    success: true,
                    forceDashboardUpdate: revert,
                    dashboardId: res.data.id,
                    dashboardType: this.props.job ? 'job' : 'item_default',
                    //fetchInterval: null
                    fetchOnInterval: true,
                },
                () => {
                    if (revert) {
                        this.setDashboard();
                    } else {
                        this.editDashboard(this.state.editMode);
                    }
                }
            );
            NotificationManager.success(
                `Dashboard ${revert ? 'reverted' : 'created'} successfully`,
                revert ? 'Reverting dashboard' : 'Creating dashboard',
                5000
            );
        } catch (err) {
            console.error('ERROR: ', err);
            this.setState({
                success: false,
            });

            NotificationManager.error(
                `Dashboard not ${revert ? 'reverted' : 'created'} successfully`,
                revert ? 'Error reverting dashboard' : 'Error creating dashboard',
                5000
            );
        }
    };
    isItemTypeDashboard(dashboardtype) {
        return dashboardtype === 'item_default';
    }
    isGlobalJobTypeDashboard(dashboardType, dashboardMetadata) {
        return (
            this.isJobTypeDashboard(dashboardType) &&
            !guidIsNullOrEmpty(dashboardMetadata?.jobTypeId) &&
            guidIsNullOrEmpty(dashboardMetadata?.plantId)
        );
    }
    isCustomJobTypeDashboard(dashboardType, dashboardMetadata) {
        //console.log("custom job type?", dashboardType,dashboardMetadata);
        return (
            this.isJobTypeDashboard(dashboardType) &&
            !guidIsNullOrEmpty(dashboardMetadata?.jobTypeId) &&
            !guidIsNullOrEmpty(dashboardMetadata?.plantId)
        );
    }
    isJobTypeDashboard(dashboardType) {
        return dashboardType === 'job';
    }
    render() {
        const isJobDashboard = this.isJobTypeDashboard(this.state.dashboardType);
        if (!this.fetchingConfig && !this.state.config && isJobDashboard) {
            return (
                <div style={{ padding: '20px' }}>
                    <h2>No data available</h2>
                </div>
            );
        }
        if (!this.state.config || this.fetchingConfig) {
            return <Spinner text="KPI" />;
        }
        const res = this.state.config;
        if (!res) {
            return <div />;
        }
        let startDate;
        let endDate;
        if (isJobDashboard) {
            startDate = this.props.job?.startDate;
            endDate = this.props.job?.endDate;
        } else {
            endDate = this.props.endDateParent;
        }
        const hasRange = typeof startDate !== 'undefined' && typeof endDate !== 'undefined';
        const isRangeValid = hasRange && IsRangeValid(startDate, endDate);

        return (
            <div className="content-grid-dash">
                <div className={`dashboard-wrapper ${this.props.user.isAdmin ? 'admin-dashboard' : ''}`}>
                    <div className="dashboard-widget-wrapper">
                        <div style={{ width: '100%' }}>
                            {this.props.user.isAdmin ? (
                                <div
                                    className={
                                        this.state.editMode
                                            ? 'dashboard-edit-wrapper edit-dashboard'
                                            : 'dashboard-edit-wrapper'
                                    }
                                >
                                    <div style={{ display: 'flex' }}>
                                        {hasRange && !isRangeValid ? (
                                            <label className="dashwarnlabel">
                                                Warning! Large date ranges may impact functionality on some widgets.
                                            </label>
                                        ) : null}
                                        {this.state.editMode ? (
                                            <>
                                                {this.state.dashboardMetadata.newerExists === emptyGuid() ? null : (
                                                    <span className="new-dashboard-exists">
                                                        There is a newer published dashboard available
                                                    </span>
                                                )}
                                                <div
                                                    className="btn btn-blue"
                                                    onClick={() => this.editDashboard(this.state.editMode)}
                                                >
                                                    View existing dashboard
                                                </div>
                                            </>
                                        ) : (
                                            <div
                                                className="btn btn-blue"
                                                onClick={
                                                    this.state.dashboardType === 'item_default'
                                                        ? () => this.confirmChangeDashboard()
                                                        : () => this.editDashboard(this.state.editMode)
                                                }
                                            >
                                                Edit dashboard
                                            </div>
                                        )}

                                        {this.state.editMode ? (
                                            <>
                                                <div
                                                    className="btn btn-yellow"
                                                    onClick={() => this.confirmPublishDashboard()}
                                                >
                                                    Publish dashboard
                                                </div>
                                                {this.props.match && this.props.match.params.itemId ? (
                                                    <div
                                                        className="btn btn-yellow"
                                                        onClick={() => this.confirmSaveItemTypeDashboard()}
                                                    >
                                                        Save as Itemtype dashboard
                                                        {this.props.currentItem?.itemTypeName
                                                            ? ` (${this.props.currentItem.itemTypeName})`
                                                            : ''}
                                                    </div>
                                                ) : null}
                                                {this.isCustomJobTypeDashboard(
                                                    this.state.dashboardType,
                                                    this.state.dashboardMetadata
                                                ) ? (
                                                    <div
                                                        className="btn btn-yellow"
                                                        onClick={() => this.confirmSaveJobTypeDashboard()}
                                                    >
                                                        Save as global operation type dashboard
                                                    </div>
                                                ) : null}
                                                <div
                                                    className="btn btn-red"
                                                    onClick={() => this.confirmDiscardDashboard()}
                                                >
                                                    Discard dashboard
                                                </div>
                                                {this.state.moveActive ? (
                                                    <div className="btn btn-yellow" onClick={() => this.cancelMove()}>
                                                        Cancel move
                                                    </div>
                                                ) : null}
                                            </>
                                        ) : null}
                                        {this.state.dashboardType === 'item_custom' ? (
                                            <div
                                                className="btn btn-blue"
                                                onClick={() => this.confirmChangeDashboard(true)}
                                            >
                                                Revert to default
                                            </div>
                                        ) : null}
                                        {this.isCustomJobTypeDashboard(
                                            this.state.dashboardType,
                                            this.state.dashboardMetadata
                                        ) ? (
                                            <div
                                                className="btn btn-blue"
                                                onClick={() => this.confirmChangeDashboard(true)}
                                            >
                                                Revert to global
                                            </div>
                                        ) : null}
                                    </div>

                                    {this.state.editMode &&
                                    this.state.dashboardMetadata &&
                                    this.state.dashboardMetadata.status === 'DRAFT' ? (
                                        <h2 className="dashboard-draft">
                                            Edit Mode - Draft{' '}
                                            {this.state.dashboardMetadata
                                                ? ' - ' +
                                                  moment(this.state.dashboardMetadata.modified).format(
                                                      'DD.MM.YYYY HH:mm'
                                                  )
                                                : null}{' '}
                                        </h2>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                        {res.configuration?.map(
                            /**
                             * @param {Configuration} d
                             * @param {number} x
                             */
                            (d, x) => {
                                const plant = this.props.plant ? this.props.plant : this.props.currentPlant;
                                let topicString = this.props.currentItem
                                    ? `${plant.topicKey}/${this.props.currentItem.topic}`
                                    : '';
                                topicString = topicString.replace(/ /g, '');
                                const activeRow = this.handleRowCheck(d);

                                return (
                                    <div className={`rich-list ${d.size ? d.size : ''}`} key={`r${d.title}`}>
                                        {this.state.editMode && x === 0 ? (
                                            <div
                                                className="add-row-inline"
                                                onClick={(e) => this.addDeleteNewRow(e, 'add', x)}
                                            >
                                                <FontAwesome name={'plus'} />
                                            </div>
                                        ) : null}
                                        <div className="rich-list-item">
                                            {d.title ? (
                                                <div className="rich-list-title">
                                                    <h3>{d.title}</h3>
                                                </div>
                                            ) : null}
                                            <div className="widget-wrapper">
                                                {d.widgets.map((w, i) => {
                                                    let titleOverride;
                                                    const { wId } = w;
                                                    if (
                                                        this.state.dashboardType === 'item_default' &&
                                                        this.props.currentItem &&
                                                        (this.props.currentItem.itemTypeName?.toLowerCase() ===
                                                            'number' ||
                                                            this.props.currentItem.itemTypeName?.toLowerCase() ===
                                                                'bool')
                                                    ) {
                                                        titleOverride = this.props.currentItem.name;
                                                    }

                                                    let widget = null;
                                                    if (w.type === 'histogram') {
                                                        widget = (
                                                            <Histogram
                                                                widget={w}
                                                                shouldFetch={shouldFetch}
                                                                data={w.data}
                                                                job={this.props.job}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                key={w.wId}
                                                                defaultPeriod={w.defaultPeriod}
                                                                period={this.props.periodParent}
                                                                endDate={this.props.endDateParent}
                                                                user={this.props.user}
                                                                refreshInterval={this.state.interval}
                                                                configuration={w.configuration}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'deviation') {
                                                        widget = (
                                                            <BarGauge
                                                                widget={w}
                                                                shouldFetch={shouldFetch}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                key={wId}
                                                                user={this.props.user}
                                                                endDate={this.props.endDateParent}
                                                                refreshInterval={this.state.interval}
                                                                configuration={w.configuration}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={titleOverride || w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'horizontal-bar') {
                                                        widget = (
                                                            <BarGauge
                                                                widget={w}
                                                                shouldFetch={shouldFetch}
                                                                data={w.data}
                                                                job={this.props.job}
                                                                type={w.type}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                key={wId}
                                                                user={this.props.user}
                                                                endDate={this.props.endDateParent}
                                                                refreshInterval={this.state.interval}
                                                                configuration={w.configuration}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={titleOverride || w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'progress') {
                                                        widget = (
                                                            <ProgressWheel
                                                                widget={w}
                                                                shouldFetch={shouldFetch}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                key={wId}
                                                                user={this.props.user}
                                                                endDate={this.props.endDateParent}
                                                                refreshInterval={this.state.interval}
                                                                configuration={w.configuration}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={titleOverride || w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'gauge') {
                                                        widget = (
                                                            <Gauge
                                                                widget={w}
                                                                shouldFetch={shouldFetch}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                key={wId}
                                                                user={this.props.user}
                                                                endDate={this.props.endDateParent}
                                                                refreshInterval={this.state.interval}
                                                                configuration={w.configuration}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={titleOverride || w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'single-cell') {
                                                        widget = (
                                                            <ValueCell
                                                                widget={w}
                                                                manualInput={w.manualInput}
                                                                shouldFetch={shouldFetch}
                                                                job={this.props.job}
                                                                data={w.data}
                                                                calculation={w.calculation}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                dataValueFilter={w.dataValueFilter}
                                                                key={wId}
                                                                user={this.props.user}
                                                                period={this.props.periodParent}
                                                                endDate={this.props.endDateParent}
                                                                refreshInterval={this.state.interval}
                                                                configuration={w.configuration}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={titleOverride || w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'statistics') {
                                                        widget = (
                                                            <Statistics
                                                                widget={w}
                                                                shouldFetch={shouldFetch}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                key={wId}
                                                                user={this.props.user}
                                                                period={this.props.periodParent}
                                                                endDate={this.props.endDateParent}
                                                                refreshInterval={this.state.interval}
                                                                configuration={w.configuration}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'link-button-group') {
                                                        widget = (
                                                            <LinkButtonGroup
                                                                widget={w}
                                                                key={wId}
                                                                config={w}
                                                                plantId={this.props.match.params.plantId}
                                                                user={this.props.user}
                                                            />
                                                        );
                                                    } else if (w.type === 'buoy') {
                                                        widget = (
                                                            <Buoy
                                                                data={w.data}
                                                                key={wId}
                                                                plantId={this.props.match.params.plantId}
                                                                topicString={w.components || [topicString]}
                                                                config={w}
                                                                user={this.props.user}
                                                            />
                                                        );
                                                    } else if (w.type === 'line' || w.type === 'spread-line') {
                                                        widget = (
                                                            <LineChartDotted
                                                                widget={w}
                                                                spreadChart={w.type === 'spread-line'}
                                                                job={this.props.job}
                                                                shouldFetch={shouldFetch}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                key={wId}
                                                                period={this.props.periodParent}
                                                                endDate={this.props.endDateParent}
                                                                user={this.props.user}
                                                                refreshInterval={this.state.interval}
                                                                plantId={this.props.match.params.plantId}
                                                                configuration={w}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'linechart') {
                                                        widget = (
                                                            <LineChartRegular
                                                                widget={w}
                                                                shouldFetch={shouldFetch}
                                                                job={this.props.job}
                                                                defaultPeriod={w.defaultPeriod}
                                                                data={w.data}
                                                                componentSettings={w.componentSettings}
                                                                calculation={w.calculation}
                                                                valueFormat={w.valueFormat}
                                                                events={w.events}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.histDataValue || w.dataValue}
                                                                key={wId}
                                                                period={this.props.periodParent}
                                                                endDate={this.props.endDateParent}
                                                                user={this.props.user}
                                                                refreshInterval={this.state.interval}
                                                                plantId={
                                                                    this.props.match
                                                                        ? this.props.match.params.plantId
                                                                        : ''
                                                                }
                                                                configuration={w}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'depthDistribution') {
                                                        widget = (
                                                            <DepthDistributionChart
                                                                widget={w}
                                                                title={w.title}
                                                                job={this.props.job}
                                                                data={w.data}
                                                                key={wId}
                                                                configuration={w.configuration}
                                                            />
                                                        );
                                                    } else if (w.type === 'sizeDistribution') {
                                                        widget = (
                                                            <SizeDistributionChart
                                                                widget={w}
                                                                data={w.data}
                                                                key={wId}
                                                                job={this.props.job}
                                                            />
                                                        );
                                                    } else if (w.type === 'status-slider') {
                                                        widget = <StatusSlider widget={w} data={w.data} key={wId} />;
                                                    } else if (w.type === 'text') {
                                                        widget = (
                                                            <Text
                                                                widget={w}
                                                                title={w.title}
                                                                textSize={w.textSize}
                                                                horAlign={w.horAlign}
                                                                verAlign={w.verAlign}
                                                                textGradient={w.textGradient}
                                                                key={wId}
                                                            />
                                                        );
                                                    } else if (w.type === 'lamp') {
                                                        widget = (
                                                            <Signal
                                                                widget={w}
                                                                data={w.data}
                                                                title={w.title}
                                                                configuration={w.configuration}
                                                                key={wId}
                                                            />
                                                        );
                                                    } else if (w.type === 'tank-gauge') {
                                                        widget = (
                                                            <TankGauge
                                                                widget={w}
                                                                data={w.data}
                                                                title={w.title}
                                                                configuration={w.configuration}
                                                                key={wId}
                                                            />
                                                        );
                                                    } else if (w.type === 'value-cell-group') {
                                                        widget = (
                                                            <ValueCellGroup
                                                                widget={w}
                                                                data={w.data}
                                                                title={w.title}
                                                                key={wId}
                                                                config={{ rounded: true }}
                                                            />
                                                        );
                                                    } else if (w.type === 'vertical-bar') {
                                                        widget = (
                                                            <VerticalBar
                                                                widget={w}
                                                                shouldFetch={shouldFetch}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                key={wId}
                                                                user={this.props.user}
                                                                endDate={this.props.endDateParent}
                                                                refreshInterval={this.state.interval}
                                                                configuration={w.configuration}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'opticontrol') {
                                                        widget = (
                                                            <OptiControl
                                                                widget={w}
                                                                shouldFetch={shouldFetch}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                key={wId}
                                                                user={this.props.user}
                                                                endDate={this.props.endDateParent}
                                                                refreshInterval={this.state.interval}
                                                                configuration={w.configuration}
                                                                machineNumber={w.machineNumber}
                                                                components={w.components || [topicString]}
                                                                title={titleOverride || w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                            />
                                                        );
                                                    } else if (w.type === 'distribution') {
                                                        widget = (
                                                            <SizeDistributionChart
                                                                widget={w}
                                                                job={this.props.job}
                                                                defaultPeriod={w.defaultPeriod}
                                                                dataType={w.dataType}
                                                                buckets={w.distributionBuckets}
                                                                dataValue={w.dataValue}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                key={wId}
                                                                distMode={true}
                                                                period={this.props.periodParent}
                                                                endDate={this.props.endDateParent}
                                                                refreshInterval={this.state.interval}
                                                                plantId={
                                                                    this.props.match
                                                                        ? this.props.match.params.plantId
                                                                        : ''
                                                                }
                                                                configuration={w}
                                                                components={w.components || [topicString]}
                                                                title={w.title}
                                                                subtitle={w.subtitle}
                                                            />
                                                        );
                                                    } else if (w.type === 'user-input') {
                                                        widget = (
                                                            <UserInput
                                                                widget={w}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                dataValueFilter={w.dataValueFilter}
                                                                key={wId}
                                                                user={this.props.user}
                                                                configuration={w.configuration}
                                                                payload={w.payload}
                                                                components={w.components || [topicString]}
                                                                title={titleOverride || w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                                plantId={
                                                                    this.props.match
                                                                        ? this.props.match.params.plantId
                                                                        : null
                                                                }
                                                            />
                                                        );
                                                    } else if (w.type === 'opti-control-live') {
                                                        widget = (
                                                            <OptiControlLive
                                                                widget={w}
                                                                data={w.data}
                                                                itemKey={w.itemKey}
                                                                dataValue={w.dataValue}
                                                                dataValueFilter={w.dataValueFilter}
                                                                key={wId}
                                                                user={this.props.user}
                                                                configuration={w.configuration}
                                                                payload={w.payload}
                                                                components={w.components || [topicString]}
                                                                title={titleOverride || w.title}
                                                                subtitle={w.subtitle}
                                                                measurement={w.measurement}
                                                                dataType={w.dataType}
                                                                plantId={
                                                                    this.props.match
                                                                        ? this.props.match.params.plantId
                                                                        : null
                                                                }
                                                            />
                                                        );
                                                    } else if (w.type === 'log-list') {
                                                        widget = (
                                                            <NoteLogList
                                                                widget={w}
                                                                data={w.data}
                                                                job={this.props.job}
                                                                title={w.title}
                                                                configuration={w.configuration}
                                                                defaultPeriod={w.defaultPeriod}
                                                                key={wId}
                                                            />
                                                        );
                                                    } else if (w.type === 'datavalues-log-list') {
                                                        widget = (
                                                            <DatavalueLogList
                                                                widget={w}
                                                                data={w.data}
                                                                job={this.props.job}
                                                                title={w.title}
                                                                configuration={w.configuration}
                                                                defaultPeriod={w.defaultPeriod}
                                                                key={wId}
                                                            />
                                                        );
                                                    } else if (w.type === 'heatmap') {
                                                        widget = <HeatMap widget={w} job={this.props.job} />;
                                                    }
                                                    return widget ? (
                                                        this.state.editMode ? (
                                                            <>
                                                                {this.state.moveActive &&
                                                                this.state.moveFromColumn > wId ? (
                                                                    <div
                                                                        key={`inline-add-first-${wId}`}
                                                                        className={'place-widget-inline'}
                                                                        onClick={(e) => this.moveWidget(e, null, x, i)}
                                                                    >
                                                                        <FontAwesome name={'arrow-circle-down'} />
                                                                    </div>
                                                                ) : i === 0 ? (
                                                                    <div
                                                                        key={`inline-add-first-${wId}`}
                                                                        className={'add-widget-inline'}
                                                                        onClick={(_e) =>
                                                                            this.openWidgetModal(true, x, i)
                                                                        }
                                                                    >
                                                                        <FontAwesome name={'plus'} />
                                                                    </div>
                                                                ) : null}
                                                                <div
                                                                    key={`widgetwrap${wId}`}
                                                                    className={
                                                                        'rich-list-clickable ' +
                                                                        (w.zones ? `${w.zones}-zones` : '') +
                                                                        (activeRow && activeRow.widgetIndex === i
                                                                            ? ' active'
                                                                            : '') +
                                                                        (this.state.moveFromColumn === i &&
                                                                        this.state.moveFromRow === x
                                                                            ? ' active-move'
                                                                            : '') +
                                                                        ` ${w.type}`
                                                                    }
                                                                    onClick={() => this.editWidget(w, x, i)}
                                                                >
                                                                    {widget}
                                                                    <div
                                                                        className={'move-widget'}
                                                                        onClick={(e) => this.moveWidget(e, w, x, i)}
                                                                    >
                                                                        <FontAwesome
                                                                            name={
                                                                                this.state.moveActive
                                                                                    ? ''
                                                                                    : 'arrows-alt'
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {this.state.moveActive &&
                                                                (this.state.moveFromColumn < i ||
                                                                    this.state.moveFromRow !== x) ? (
                                                                    <div
                                                                        key={`inline-add-${wId}`}
                                                                        className={'place-widget-inline'}
                                                                        onClick={(e) =>
                                                                            this.moveWidget(e, null, x, i + 1)
                                                                        }
                                                                    >
                                                                        <FontAwesome name={'arrow-circle-down'} />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        key={`inline-add-${wId}`}
                                                                        className={'add-widget-inline'}
                                                                        onClick={(_e) =>
                                                                            this.openWidgetModal(true, x, i + 1)
                                                                        }
                                                                    >
                                                                        <FontAwesome name={'plus'} />
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <div
                                                                key={`widgetwrap${wId}`}
                                                                className={
                                                                    ((w.type === 'deviation' ||
                                                                        w.type === 'horizontal-bar' ||
                                                                        w.type === 'single-cell' ||
                                                                        w.type === 'gauge') &&
                                                                    !w.historyDisabled
                                                                        ? 'rich-list-clickable '
                                                                        : 'rich-list-non-clickable ') +
                                                                    (w.zones ? `${w.zones}-zones` : '') +
                                                                    (activeRow && activeRow.widgetIndex === i
                                                                        ? ' active'
                                                                        : '') +
                                                                    ` ${w.type === 'buoy' ? '' : w.type}`
                                                                }
                                                                onClick={
                                                                    (w.type === 'deviation' ||
                                                                        w.type === 'horizontal-bar' ||
                                                                        w.type === 'single-cell' ||
                                                                        w.type === 'gauge') &&
                                                                    !w.historyDisabled
                                                                        ? () =>
                                                                              this.toggleRow({
                                                                                  row: d,
                                                                                  widget: w,
                                                                                  widgetIndex: i,
                                                                              })
                                                                        : null
                                                                }
                                                            >
                                                                {widget}
                                                            </div>
                                                        )
                                                    ) : null;
                                                })}

                                                {this.state.editMode && d.widgets.length === 0 ? (
                                                    <div key={`inline-add-${x}`} className={'inline-first-add'}>
                                                        <div
                                                            className="btn btn-blue"
                                                            onClick={(_e) => this.openWidgetModal(true, x, 0)}
                                                        >
                                                            Add first widget to row
                                                        </div>
                                                        <div
                                                            className="btn btn-red"
                                                            onClick={(_e) => this.confirmDeleteRow(x)}
                                                        >
                                                            Delete row
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {activeRow ? (
                                            this.check() ? (
                                                <div className="rich-list-expand-area">
                                                    <div className="histogram-header">
                                                        <h3>Showing history for {activeRow.widget.title}</h3>
                                                    </div>
                                                    <div
                                                        className="rich-list-non-clickable"
                                                        style={{ flex: '0 1 100%' }}
                                                    >
                                                        <LineChartRegular
                                                            expanded={true}
                                                            itemKey={activeRow.widget.itemKey}
                                                            dataValue={
                                                                'historic/' +
                                                                activeRow.widget.dataValue?.split('/').pop()
                                                            }
                                                            key={`expand-area-${x}`}
                                                            period={this.props.periodParent}
                                                            endDate={endDate}
                                                            job={this.props.job}
                                                            user={this.props.user}
                                                            refreshInterval={this.state.interval}
                                                            plantId={
                                                                this.props.match
                                                                    ? this.props.match.params.plantId
                                                                    : null
                                                            }
                                                            configuration={activeRow.widget}
                                                            machineNumber={activeRow.widget.machineNumber}
                                                            components={
                                                                activeRow.widget.components || [
                                                                    plant.topicKey +
                                                                        '/' +
                                                                        this.props.currentItem.topic,
                                                                ]
                                                            }
                                                            componentSettings={activeRow.widget.componentSettings}
                                                            title={activeRow.widget.title}
                                                            subtitle={activeRow.widget.subtitle}
                                                            measurement={activeRow.widget.measurement}
                                                            dataType={activeRow.widget.dataType}
                                                        />
                                                    </div>
                                                    <span
                                                        className="close-histogram"
                                                        onClick={() => this.closeHistogram(activeRow)}
                                                    >
                                                        X
                                                    </span>
                                                </div>
                                            ) : (
                                                <Modal
                                                    isOpen={this.state.modalIsOpen}
                                                    onRequestClose={this.closeModal}
                                                    style={customStyles}
                                                    ariaHideApp={false}
                                                    shouldCloseOnOverlayClick={false}
                                                >
                                                    <>
                                                        <h3>Showing history for {activeRow.widget.title}</h3>
                                                        <span
                                                            className="close-histogram"
                                                            onClick={() => this.closeHistogram(activeRow)}
                                                        >
                                                            X
                                                        </span>
                                                        <LineChartRegular
                                                            shouldFetch={true}
                                                            itemKey={activeRow.widget.itemKey}
                                                            dataValue={
                                                                activeRow.widget.histDataValue
                                                                    ? activeRow.widget.histDataValue
                                                                    : activeRow.widget.dataValue
                                                            }
                                                            key={`expand-area-${x}`}
                                                            period={this.props.periodParent}
                                                            user={this.props.user}
                                                            refreshInterval={this.state.interval}
                                                            endDate={endDate}
                                                            job={this.props.job}
                                                            plantId={this.props.match.params.plantId}
                                                            configuration={activeRow.widget}
                                                            machineNumber={activeRow.widget.machineNumber}
                                                            components={
                                                                activeRow.widget.components || [
                                                                    plant.topicKey +
                                                                        '/' +
                                                                        this.props.currentItem.topic,
                                                                ]
                                                            }
                                                            componentSettings={activeRow.widget.componentSettings}
                                                            title={activeRow.widget.title}
                                                            subtitle={activeRow.widget.subtitle}
                                                            measurement={activeRow.widget.measurement}
                                                            dataType={activeRow.widget.dataType}
                                                        />
                                                    </>
                                                </Modal>
                                            )
                                        ) : null}

                                        {this.state.editMode ? (
                                            <div
                                                className="add-row-inline"
                                                onClick={(e) => this.addDeleteNewRow(e, 'add', x + 1)}
                                            >
                                                <FontAwesome name={'plus'} />
                                            </div>
                                        ) : null}
                                    </div>
                                );
                            }
                        )}

                        {res.configuration?.length === 0 && this.state.editMode ? (
                            <div className={'rich-list'} key={`r${0}`}>
                                <div className="add-row-inline" onClick={(e) => this.addDeleteNewRow(e, 'add', 0)}>
                                    <FontAwesome name={'plus'} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {this.state.editMode ? (
                    <Modal
                        isOpen={this.state.widgetModalIsOpen}
                        onRequestClose={this.closeWidgetModal}
                        style={customStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <DashboardEditPane
                            {...this.props}
                            dashboardType={this.state.dashboardType}
                            row={this.row}
                            column={this.column}
                            mode={this.state.newMode ? 'NEW' : ''}
                            dashboardId={this.state.dashboardId}
                            plantId={this.props.match ? this.props.match.params.plantId : null}
                            accountId={this.props.user.profile.accountId}
                            itemId={this.props.match ? this.props.match.params.itemId : null}
                            closeWidgetModal={() => this.closeWidgetModal()}
                        />
                    </Modal>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    plant: state.plants.currentPlant,
    user: state.user.currentUser,
    endDateParent: state.itemrow.endDateParent,
    periodParent: state.itemrow.periodParent || { period: 12, interval: 'hours' },
    editConfig: state.itemrow.dashboardConfig,
});

const mapDispatchToProps = {
    setCurrentPlant,
    setEndDate,
    setPeriod,
    setWidget,
    clearDateRange,
    clearCurrentPlant,
    setDashboardConfig,
    getDashboardConfig,
};

export default withRouteMatch(connect(mapStateToProps, mapDispatchToProps)(Dashboard));

/**
 * @typedef {{
 *     components: string[];
 *     dataType: string;
 *     dataValue: string;
 *     title: string;
 *     type: string;
 *     historyDisabled: boolean;
 *     manualInput: boolean;
 *     biomassTypes: any[];
 *     biomassValues: any[];
 *     calculation: string;
 *     configuration: Configuration2;
 *     wId: string;
 *     componentSettings: ComponentSetting[];
 *     dataValueFilter?: any;
 *     histDataValue: string;
 *     valueFormat: string;
 *     zones: string;
 *     itemKey?: any;
 *     measurement?: any;
 *     machineNumber: number;
 *     plant: string;
 *     solutionConfig?: any;
 *     distributionBuckets: DistributionBucket[];
 *     tabs?: any;
 *     traceEnabled: boolean;
 *     events: boolean;
 *     defaultPeriod: string;
 *     textSize?: any;
 *     horAlign?: any;
 *     verAlign?: any;
 *     textGradient: boolean;
 *     payload?: any;
 * }} Widget
 *
 * @typedef {{ title: string; size: string; layout: string; widgets: Widget[] }} Configuration
 */
