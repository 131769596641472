import differenceInSeconds from 'date-fns/differenceInSeconds';
import { constant } from 'lodash';
import moment from 'moment';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import { tryParseJson } from '../../utility/jsonFunctions';
import Dashboard from '../dashboard';
import JobDocuments from './jobdocuments';
import { ReactComponent as AngleRight } from '../../images/icons/optimar_angle_right_white.svg';
import { ReactComponent as AngleDown } from '../../images/icons/optimar_angle_down.svg';
import { ReactComponent as Warning } from '../../images/table/warning.svg';
import { ReactComponent as TableIcon } from '../../images/icons/optimar_list_blue.svg';
import { ReactComponent as TableIconInactive } from '../../images/icons/optimar_list_inactive.svg';
import { ReactComponent as GanttIcon } from '../../images/icons/optimar_gantt_blue.svg';
import { ReactComponent as GanttIconInactive } from '../../images/icons/optimar_gantt_inactive.svg';
import { GANTT_VIEW, TABLE_VIEW } from './joblist';
import { getDataCol } from '../../components/common/GanttChart/GanttChartConfiguration';
// import { ReactComponent as GreenIcon } from '../../../images/icons/green_circle.svg';

const findParent = (arr, item) => {
    const currentItem = { ...item };
    currentItem.parent = arr
        .filter((x) => item.parentJobId === x.id)
        .sort((a, b) => a.id - b.id)
        .map((y) => findParent(arr, y));

    return currentItem;
};

const getDisplayName = (node) => {
    const parsedFormData = tryParseJson(node.data, {}); // node.data ? JSON.parse(node.data) : {};
    const parsedTypeConfig = tryParseJson(node.configuration, {}); // node.configuration ? JSON.parse(node.configuration) : {};
    const displayName = parsedFormData[parsedTypeConfig?.relationDisplayConfig?.displayValue];
    const displayHeader = parsedTypeConfig?.relationDisplayConfig?.header;

    return displayName ? (
        <>
            <span className={'relation-header'}>{displayHeader}</span>
            <span className={'relation-name'}>{displayName}</span>
        </>
    ) : (
        <>
            <span className={'relation-header'}>Type</span>
            <span className={'relation-name'}>{node.type}</span>
        </>
    );
};

const generateBranch = (node, total, current, data, toggleParentView, header) => {
    let jsxArray = total;

    let rel = '';
    switch (node.relationship) {
        case 'Part of':
            rel = 'part-of';
            break;
        case 'Predecessor':
            rel = 'predecessor';
            break;
        default:
            break;
    }
    if (node.children?.length > 0) {
        jsxArray = generateBranch(node.children[0], jsxArray, false, data, toggleParentView, header);
    }

    const currentNodeWithParentBranch = findParent(data, { ...node });
    currentNodeWithParentBranch.children = [];
    jsxArray.push(
        <div
            key={`n${node.id}`}
            onClick={current ? null : () => toggleParentView(currentNodeWithParentBranch, header)}
            className={rel + (current ? ' current' : '')}
        >
            {getDisplayName(node)}
        </div>
    );

    if (node.parent?.length > 0) {
        return generateBranch(node.parent[0], jsxArray, false, data, toggleParentView, header);
    }

    return jsxArray;
};

export function getHeader(
    data,
    topLevelOnly,
    parent,
    view,
    { toggleModal, toggleTopLevel, clearFilter, toggleParentView, toggleGanttView, toggleTableView }
) {
    return {
        left: [
            <h3 className="gt-header-count" key="gth-0">
                {data.length} Operations
            </h3>,
            <span onClick={() => toggleModal(null, 'new')} key="gth-1" className="gt-header-button">
                + Add operation
            </span>,
            // <div onClick={() => toggleTopLevel()} key="gth-2" className={'list-filter-check faux-checkbox '}>
            //     <div className={topLevelOnly ? 'checkbox checked' : 'checkbox'} />
            //     <span>Only top level</span>
            // </div>,
        ],
        right: [
            parent ? (
                <span key="gth-3" className={'header-filter-info'}>
                    <span className="current-filter-label">Current filter:</span>
                    <div className="relation-flow">
                        {generateBranch(parent, [], true, data, toggleParentView, true)}
                    </div>
                    <span className={'header-clear-filter'} onClick={() => clearFilter()}>
                        X
                    </span>
                </span>
            ) : (
                <span key="gth-3" />
            ),
            <>
                {view === TABLE_VIEW ? (
                    <TableIcon
                        key="gth-4"
                        onClick={() => {
                            toggleTableView();
                        }}
                    />
                ) : (
                    <TableIconInactive
                        key="gth-4"
                        onClick={() => {
                            toggleTableView();
                        }}
                    />
                )}
            </>,
            <>
                {view === GANTT_VIEW ? (
                    <GanttIcon
                        key="gth-5"
                        onClick={() => {
                            toggleGanttView();
                        }}
                    />
                ) : (
                    <GanttIconInactive
                        key="gth-5"
                        onClick={() => {
                            toggleGanttView();
                        }}
                    />
                )}
            </>,
        ],
    };
}

const viewTypeDash = 0;
const viewTypeDocs = 1;

export function getDashboard(row, setViewTypeFunc, getViewTypeFunc) {
    return (
        <div className="rowDetailsContainer">
            <nav className="viewslist">
                {
                    <div className="viewlist-content" onClick={() => setViewTypeFunc(viewTypeDash)}>
                        <span className={getViewTypeFunc() === viewTypeDash ? 'view-active' : 'view-inactive'}>
                            Dashboard
                        </span>
                    </div>
                }
                <div className="viewlist-content" onClick={() => setViewTypeFunc(viewTypeDocs)}>
                    <span className={getViewTypeFunc() === viewTypeDocs ? 'view-active' : 'view-inactive'}>
                        Documents
                    </span>
                </div>
            </nav>
            {getViewTypeFunc() === viewTypeDash ? (
                <div style={{ paddingTop: '20px' }}>
                    <Dashboard
                        dashboardType="job"
                        jobTypeId={row.original.jobTypeId}
                        job={{
                            startDate: row.original.startDate ? moment.utc(row.original.startDate) : new Date(),
                            endDate: row.original.endDate ? moment.utc(row.original.endDate) : new Date(),
                        }}
                        match={{ params: { plantId: row.original.plantId } }}
                        currentItem={{ topic: row.original.topic }}
                    />
                </div>
            ) : null}
            {getViewTypeFunc() === viewTypeDocs ? (
                <div style={{ paddingTop: '20px' }}>
                    <JobDocuments plantId={row.original.plantId} jobId={row.original.id} />
                </div>
            ) : null}
        </div>
    );
}

export function confirmDelete(item, deleteJob) {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>Delete operation</h1>
                    <p>
                        You are about to delete the operation
                        <br />{' '}
                    </p>
                    <p>Are you sure?</p>
                    <div className="buttons">
                        <button className="btn btn-yellow" onClick={onClose}>
                            No, cancel
                        </button>
                        <button
                            className="btn btn-red"
                            onClick={() => {
                                deleteJob(item);
                                onClose();
                            }}
                        >
                            Yes, delete
                        </button>
                    </div>
                </div>
            );
        },
    });
}

/**
 * @param {any} props
 * @param {any} data
 * @param {any} param2
 *
 * @returns {import('react-table').Column[]}
 */
export function getColumns(
    props,
    data,
    { toggleDashboard, toggleModal, deleteJob, generateJobReport, toggleParentView, transferOperation, jobTypes }
) {
    const isDate = (date, fieldConfig) => {
        if (fieldConfig?.type.indexOf('date') < 0) {
            return false;
        }
        const isnan = isNaN(date);
        //Seems like numbers like 1, 2, 3 etc can get parsed to a date in 2001.. :D
        if (isnan === false) {
            //if it is a number.. then it is hopefully not a date..
            return false;
        }

        const d = moment(date);
        const earliestDate = moment('1990-01-01');

        // console.log(d,earliestDate,d.isValid());
        return d.isValid() && d.isAfter(earliestDate);
    };

    const rowContext = [
        {
            content: <span>Dashboard & details</span>,
            action: (row) => toggleDashboard(row.original, 'dashboard'),
        },
        {
            content: <span>Edit operation</span>,
            action: (row) => toggleModal(row.original, 'edit'),
        },
        {
            content: <span>New child operation</span>,
            action: (row) => toggleModal(row.original, 'child'),
        },
        {
            content: <span>Delete operation</span>,
            action: (row) => confirmDelete(row.original, deleteJob),
        },
        {
            content: <span>Download report</span>,
            action: (row) => generateJobReport(row.original),
            hideContext: ({ original }) => !jobTypes.find((x) => original.jobTypeId === x.value)?.reportConfigId,
        },
        {
            content: <span>Transfer</span>,
            action: (row) => transferOperation(row.original)
        }
    ];

    return [
        {
            Header: constant(null),
            id: 'expander',
            Cell: ({ row, _rows, _toggleRowExpanded }) =>
                row.original.jobTypeId === '00000000-0000-0000-0000-000000000000' ? null : (
                    <span
                        className="row-expander-icon"
                        {...row.getToggleRowExpandedProps({
                            onClick: () => {
                                // console.log('Custom rowexpand function');
                                //Can use the below code to unexpand other dashboards..
                                //toggleRowExpanded(expandedRow.id, false);
                                row.toggleRowExpanded();
                            },
                        })}
                    >
                        {row.subRows.length > 0 ? (
                            row.isExpanded ? (
                                <AngleDown fill="red" stroke="red" />
                            ) : (
                                <AngleRight fill="red" stroke="red" />
                            )
                        ) : null}
                        <span style={{ maxHeight: '200px', display: 'flex', alignItems: 'center' }}>
                            {row.original.isOrphan ? <Warning /> : null}
                        </span>
                    </span>
                ),
        },
        {
            Header: 'Component',
            accessor: 'topic',
            id: 'component',
            show: false,
            Cell: (cellInfo) => (
                <Link to={`/plant/${props.plantId}/items/${cellInfo.data[cellInfo.row.index].itemId}/overview`}>
                    {cellInfo.value}
                </Link>
            ),
        },
        {
            Header: 'Type',
            accessor: 'type',
            Cell: (row) => {
                // const currentNodeWithParentBranch = findParent(data, row.row.original);
                // console.log(row.row.original.isOrphan);
                // console.log(row.values)
                return (
                    <div className="clickable" onClick={() => toggleDashboard(row.row.original, 'dahboard')}>
                        {row.value}
                    </div>
                );
            },
        },
        {
            Header: 'Data',
            accessor: 'data',
            Cell: ({ row }) => {
                return getDataCol(row.original, jobTypes);
            },
        },
        {
            Header: 'Start Date',
            accessor: 'startDate',
            Cell: (row) => <div>{row.value ? moment.utc(row.value).local().format('DD.MM.YYYY HH:mm') : null}</div>,
        },
        {
            Header: 'End Date',
            accessor: 'endDate',
            Cell: (row) => <div>{row.value ? moment.utc(row.value).local().format('DD.MM.YYYY HH:mm') : null}</div>,
        },
        {
            Header: 'Time',
            Cell: ({ row }) => {
                if (row.original?.startDate && row.original?.endDate) {
                    const fullDiff = differenceInSeconds(
                        new Date(row.original?.endDate),
                        new Date(row.original?.startDate)
                    );
                    const nowDiff = differenceInSeconds(new Date(), new Date(row.original?.startDate));
                    let currentPercent = (nowDiff / fullDiff) * 100;
                    currentPercent = currentPercent > 100 ? 100 : currentPercent;
                    currentPercent = currentPercent < 0 ? 0 : currentPercent;
                    //console.log("HMM", currentPercent)

                    return (
                        <div>
                            <div className="bar-gauge-bar" style={{ width: '100px' }}>
                                <div
                                    className="bar-gauge-bar-progress"
                                    style={{
                                        width: `${currentPercent}%`,
                                        backgroundImage:
                                            'linear-gradient(90deg, rgba(65,200,240,0), rgba(65,200,240,0.6))',
                                    }}
                                />
                            </div>
                        </div>
                    );
                }
                return <div />;
            },
        },
        {
            Header: 'Modified',
            accessor: 'modifiedOn',
            Cell: (row) => <div>{moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss')}</div>,
        },
        {
            Header: constant(null),
            id: 'rowcontext',
            Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
        },
    ];
}
