import * as moment from 'moment';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import ItemDropdown from '../../components/common/ItemDropdown/ItemDropdown';
import api from '../../utility/api';
import { uploadFileUsingFormData } from '../../utility/formFileUploadFunctions';
import { guidIsNullOrEmpty } from '../../utility/guidFunctions';
import './SupportAddEdit.scss';

class SupportAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supportCase: {
                customerRef: props.user.Username,
            },
            caseTypes: [],
            //itemName: null,
            itemId: this.props.item ? this.props.item.id : null,
            items: [],
            list: [],
            expanded: [],
            claimConfirmed: false,
            filterString: '',
            isSubmitting: false,
            activeProjects: [],
            fetchingActiveProjects: false,
        };

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
        //this.fetchitem = this.fetchItem.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        this.getSupportTypes();
        //this.getStructure(this.props.user, this.props.plant);
        this.getActiveProjects(this.props.plant);
        // if (this.state.itemId) {
        //     this.fetchItem(this.state.itemId);
        // }
    }

    getActiveProjects = async (plant) => {
        try {
            this.setState({ fetchingActiveProjects: true });

            const plantId = plant ? plant.id : this.props.case.plant;
            const res = await api.get(`api/projects/${plantId}/active`);

            this.setState(
                {
                    activeProjects: res.data,
                    fetchingActiveProjects: false,
                },
                () => this.setOrClearDefaultSelectedProjectIfClaim()
            );
            //this.setDefaultSelectedProjectIfClaim();
        } catch (err) {
            console.log('Unable to fetch active projects.', err);
            NotificationManager.error('Unable to fetch active projects', 'Error', 5000);
        }
    };

    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        const tmp = Object.assign({}, this.state.supportCase);
        tmp[name] = value;

        if (name === 'caseTypeId') {
            tmp.projectNo = this.getDefaultProjectNoValueIfClaim(value);
            //this.setDefaultSelectedProjectIfClaim()
        }

        this.setState({ supportCase: tmp });
    }
    onFileChange = (e) => {
        const file = e.target.files[0];
        console.log('file..', file);
        //let fileProp = `${e.target.name}`;
        this.setState({ supportCaseFile: file });
    };
    uploadFile = async (caseId, file) => {
        if (!file || guidIsNullOrEmpty(caseId)) return;
        try {
            const props = {
                Internal: false, //never internal files when creating cases
                SupportId: caseId,
                CreatedAt: moment.utc().format(),
                Date: moment.utc().format(),
            };
            await uploadFileUsingFormData(file, props);
            NotificationManager.success('File uploaded successfully', 'Upload succeeded', 5000);
            //this.getDocuments(internal);
            this.setState({
                supportCaseFile: null,
            });
        } catch (err) {
            NotificationManager.error('Could not upload file, please try again', 'Upload failed', 5000);
        }
    };

    async getSupportTypes() {
        const res = await api.get('api/support/types');
        this.setState({ caseTypes: res.data });
    }

    validateForm(supportCase) {
        let isValid = true;
        let validMsg = '';

        console.log('Validating info', supportCase, supportCase.title?.length);

        if (!supportCase.title || supportCase.title.length <= 3) {
            validMsg += ' - Title is too short.\n\r';
            isValid = false;
        }
        if (!supportCase.description || supportCase.description.length <= 0) {
            validMsg += ' - Description is missing.\n\r';
            isValid = false;
        }

        //console.log(seriesNames,widget.componentSettings);
        return { isValid, validMsg };
    }

    async submit(e) {
        e.preventDefault();
        const supportCase = Object.assign({}, this.state.supportCase);

        let { isValid, validMsg } = this.validateForm(supportCase);
        if (!isValid) {
            NotificationManager.warning(
                'Please input data in all mandatory fields.\n\r' + validMsg,
                'Form Validation Failed',
                5000
            );
            return;
        }

        this.setState({ isSubmitting: true });
        const defaultCaseType = this.state.caseTypes.filter((x) => x.name.includes('General'))[0];

        supportCase.reporter = this.props.user.sub;
        supportCase.linkedNote = this.props.linkedNote?.id;
        if (!supportCase.caseTypeId) {
            supportCase.caseTypeId = defaultCaseType.id;
            //supportCase.caseType = defaultCaseType.name; <- not important atm..
        }
        supportCase.plant = this.props.plant.id;
        if (this.props.item) supportCase.item = this.props.item.id;

        if (this.state.itemId) supportCase.item = this.state.itemId;

        try {
            console.log('case', supportCase);

            const res = await api.post('api/support', supportCase);
            if (res.status < 200 || res.status > 300) throw new Error('Failure creating case');

            const caseId = res.data;
            if (guidIsNullOrEmpty(caseId))
                throw new Error('Could not get case id from case. It may not have been created properly.');
            await this.uploadFile(caseId, this.state.supportCaseFile);

            NotificationManager.success('Case added successfully', 'Support case added', 5000);

            this.setState({ isSubmitting: false, supportCase: {} });
            this.props.close();
        } catch (ex) {
            console.log('Submit support case error', ex);
            NotificationManager.error('Could not add support case, try again later', 'Failure', 5000);
            this.setState({ isSubmitting: false });
        }
    }

    setItem(item) {
        if (item) {
            this.setState({
                itemId: item.id,
                //itemName: item.name,
                // filterString: "",
                // showItemList: false
            });
        }
    }

    renderClaimText(isClaim) {
        if (!isClaim) return;

        return (
            <>
                <div className="claim-disclaimer">
                    Customer shall report any Warranty Claim to Optimar immediately upon discovery. In the event the
                    Customer does not report the Claim as soon as possible, Optimar reserves the right to reject the
                    warranty claim.
                    <br />
                    Each Claim shall be reported by Separate Warranty Claim Registry Form.
                    <br />
                    <br />
                    Replacement parts for a warranty claim, supplied by Optimar to Customer, will be invoiced and due
                    for payment within 90 days. If not otherwise specifically agreed, defect parts shall be returned to
                    Optimar at Customers expense. If claim is accepted, invoice will be credited by Optimar.
                    <br />
                    <br />
                    Customer Claim Number shall be entered into Customer Claim Ref field in below form.
                    <br />
                    <br />
                    Give full description, photos and other relevant information by attachment to identify the
                    deficiency.
                    <br />
                    <br />
                    If not otherwise agreed, defective parts shall be clearly identified with Customers name, address
                    and Claim number.
                    <br />
                    Proper pro forma invoice and packing list should follow when applicable. Shipping documents shall be
                    scanned and sent by email to <b>warrantyclaim@optimar.no.</b> or uploaded to the specific claim in
                    Optimar Commander web portal
                    <br />
                    Send defective parts to (after agreement):
                    <br />
                    <br />
                    OPTIMAR AS,
                    <br />
                    Dept. Stette
                    <br />
                    Stette Industriomrade 14
                    <br />
                    6260 Skodje
                    <br />
                    Norway
                    <br />
                    <br />
                    If not otherwise specifically agreed, Warranty Period is 12 months from signature of Protocol of
                    Delivery and acceptance, but not more than 18 months from date of shipment from OPTIMAR to Customer.
                    <br />
                    <br />
                    Warranty Terms and Conditions described in Agreement for Purchase shall apply, if not otherwise
                    agreed reference is made to Orgalime SI 14, Clause 55 – 71.
                    <br />
                    Definitions: Contractor = OPTIMAR, Purchaser = Customer.
                    <br />
                    <br />
                    OPTIMAR reserves the right to consider the relevance of any Claim and may refuse Claims which do not
                    qualify as warranty with reference to Agreement for Purchase.
                    <br />
                    <br />
                </div>
                <label htmlFor="claimConfirmed">I accept the terms listed above</label>
                <input
                    type="checkbox"
                    name="claimConfirmed"
                    checked={this.state.claimConfirmed}
                    onChange={(e) => this.setState({ claimConfirmed: e.target.checked })}
                />
                <br />
            </>
        );
    }

    hasProjectBeforeHandover() {
        if (this.state.fetchingActiveProjects || this.state.activeProjects.length === 0) return false;

        const nowDate = new Date();
        for (let i = 0; i < this.state.activeProjects.length; i++) {
            const project = this.state.activeProjects[i];
            if (new Date(project.customerHandover) < nowDate) {
                return true;
            }
        }
    }
    hasProjectInClaimPeriod() {
        if (this.state.fetchingActiveProjects || this.state.activeProjects.length === 0) return false;

        for (let i = 0; i < this.state.activeProjects.length; i++) {
            const project = this.state.activeProjects[i];
            if (this.isWithinClaimPeriod(project)) return true;
        }
        return false;
    }
    isWithinClaimPeriod(project) {
        const nowDate = new Date();

        if (nowDate > new Date(project.customerHandover) && new Date(project.endOfWarrantyPeriod) > nowDate) {
            //noClaim = false;
            return true;
        }
    }
    isClaimType(caseTypeId) {
        return (this.state.caseTypes.find((x) => x.id === caseTypeId) || {}).name === 'Claim';
    }

    getDefaultProjectNoValueIfClaim(caseTypeId) {
        if (!this.isClaimType(caseTypeId)) {
            return null;
        }
        return this.state.activeProjects.find((ap) => this.isWithinClaimPeriod(ap))?.projectNo;
    }
    setOrClearDefaultSelectedProjectIfClaim() {
        const theCase = this.state.supportCase;
        const projectNo = this.getDefaultProjectNoValueIfClaim(theCase.caseTypeId);
        theCase.projectNo = projectNo;
        this.setState({ supportCase: theCase });
    }

    render() {
        //Punch should get routed as Service Request.
        const isClaim = this.isClaimType(this.state.supportCase.caseTypeId);
        //Punch only before handover
        const noPunch = !this.hasProjectBeforeHandover();

        //Claim only between handover and warranty end
        const noClaim = !this.hasProjectInClaimPeriod();
        return (
            <div className="support addedit">
                <h3>New case</h3>
                <form>
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input
                            onChange={this.onChange}
                            required={true}
                            minLength={4}
                            value={this.state.supportCase.title || ''}
                            className="input-control"
                            name="title"
                        />
                    </div>
                    {
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea
                                onChange={this.onChange}
                                value={this.state.supportCase.description || ''}
                                className="input-control"
                                name="description"
                                required={true}
                            />
                        </div>
                    }
                    <div className="form-group">
                        <label htmlFor="customerRef">Customer ref.</label>
                        <input
                            onChange={this.onChange}
                            value={this.state.supportCase.customerRef || ''}
                            className="input-control"
                            name="customerRef"
                        />
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="optimarRef">Optimar ref.</label>
                        <input onChange={this.onChange} value={this.state.supportCase.optimarRef} className="input-control" name="optimarRef" readOnly={this.props.user.role !== 'admin' && this.props.user.role !== 'owner'} />
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="caseType">Case type</label>
                        <select
                            onChange={this.onChange}
                            value={this.state.supportCase.caseTypeId}
                            className="input-control"
                            name="caseTypeId"
                        >
                            {!this.state.caseTypes.length && this.state.fetchingActiveProjects ? (
                                <option>Loading...</option>
                            ) : (
                                this.state.caseTypes.map((t, i) => {
                                    if (noPunch && t.name === 'Punch') return null;
                                    if (noClaim && t.name === 'Claim') return null;

                                    return (
                                        <option key={`ct${i}`} value={t.id}>
                                            {t.name}
                                        </option>
                                    );
                                })
                            )}
                        </select>
                    </div>
                    <div className="form-group" style={{ position: 'relative' }}>
                        <label htmlFor="content">Component</label>

                        <ItemDropdown
                            modalRef={this.props.modalRef}
                            showLabel={false}
                            showHiddenItems={false}
                            plantId={this.props.plant.id}
                            selectItem={(item) => this.setItem(item)}
                            selectedItem={{ id: this.state.itemId }}
                            required={false}
                        />
                    </div>
                    {this.renderClaimText(isClaim)}
                    {isClaim ? (
                        <>
                            <div className="form-group">
                                <label htmlFor="activeProject">Project</label>
                                <select
                                    onChange={this.onChange}
                                    value={this.state.supportCase.projectNo}
                                    className="input-control"
                                    name="projectNo"
                                >
                                    {this.state.fetchingActiveProjects ? (
                                        <option>Loading...</option>
                                    ) : (
                                        this.state.activeProjects.map((t, i) => {
                                            if (!this.isWithinClaimPeriod(t)) return null;
                                            return (
                                                <option key={`ap${i}`} value={t.projectNo}>
                                                    {t.projectNo} - {t.projectName}
                                                </option>
                                            );
                                        })
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="customerClaimRef">Customer Claim ref.</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.supportCase.customerClaimRef || ''}
                                    className="input-control"
                                    name="customerClaimRef"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="drawingNo">Drawing No</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.supportCase.drawingNo || ''}
                                    className="input-control"
                                    name="drawingNo"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="requiredParts">Required Parts</label>
                                <textarea
                                    onChange={this.onChange}
                                    value={this.state.supportCase.requiredParts || ''}
                                    className="input-control"
                                    name="requiredParts"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="customerNeeds">Detailed Customer Need</label>
                                <textarea
                                    onChange={this.onChange}
                                    value={this.state.supportCase.customerNeeds || ''}
                                    className="input-control"
                                    name="customerNeeds"
                                />
                            </div>
                        </>
                    ) : null}
                    {/* <div className="form-group">
                        <label htmlFor="contact">Contact</label>
                        <input onChange={this.onChange} value={this.state.supportCase.contact} className="input-control" name="contact" />
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="supportCaseFile">Attachment</label>
                        <input
                            onChange={this.onFileChange}
                            type="file"
                            className="input-control"
                            name="supportCaseFile"
                        />
                    </div>

                    {this.props.linkedNote ? (
                        <div className="form-group">
                            <label htmlFor="attachment">Linked note</label>
                            <input disabled type="text" value={this.props.linkedNote.title} className="input-control" />
                        </div>
                    ) : null}
                </form>
                <button
                    disabled={(!this.state.claimConfirmed && isClaim) || this.state.isSubmitting}
                    onClick={this.submit}
                    className="btn btn-blue"
                >
                    {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
                <button
                    onClick={() => {
                        this.props.close();
                    }}
                    style={{ marginLeft: '20px' }}
                    className="btn btn-yellow"
                >
                    Cancel
                </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser.profile,
        plant: state.plants.currentPlant,
        isMobile: state.utility.isMobile,
    };
};

export default connect(mapStateToProps)(SupportAddEdit);
