import React, { useMemo, useCallback } from 'react';

import { datapoints_source_validate } from './DatapointsUtils';
import { meta_get_available_metas } from './MetaUtils';

import { ReactComponent as DeleteIcon } from '../../../../images/new/optimar_cross.svg';

const DEFAULT_TIME_FORMAT = 'DD/MM/YY HH:mm';
const AVAILABLE_VALUE_TYPES = {
    root: [
        { type: 'meta', label: 'Job Metadata Field' },
        { type: 'time', label: 'Job Time' },
        { type: 'child', label: 'Child Job Data' },
        { type: 'previous', label: 'Previous Job Data' },
        { type: 'childtimeagg', label: 'Child Jobs Time Aggregation' },
        { type: 'datapoint', label: 'Datapoint' },
        { type: 'math', label: 'Mathematical Operation' },
    ],
    child: [
        { type: 'meta', label: 'Job Metadata Field' },
        { type: 'time', label: 'Job Time' },
    ],
    previous: [
        { type: 'meta', label: 'Job Metadata Field' },
        { type: 'time', label: 'Job Time' },
        { type: 'child', label: 'Child Job Data' },
        { type: 'childtimeagg', label: 'Child Jobs Time Aggregation' },
    ],
    math: [
        { type: 'meta', label: 'Job Metadata Field' },
        { type: 'time', label: 'Job Time' },
        { type: 'child', label: 'Child Job Data' },
        { type: 'previous', label: 'Previous Job Data' },
        { type: 'childtimeagg', label: 'Child Jobs Time Aggregation' },
        { type: 'datapoint', label: 'Datapoint' },
        { type: 'math', label: 'Mathematical Operation' },
    ],
    commentlist_root: [
        { type: 'meta', label: 'Job Metadata Field' },
        { type: 'child', label: 'Child Job Data' },
        { type: 'previous', label: 'Previous Job Data' },
    ],
    commentlist_child: [{ type: 'meta', label: 'Job Metadata Field' }],
    commentlist_previous: [
        { type: 'meta', label: 'Job Metadata Field' },
        { type: 'child', label: 'Child Job Data' },
    ],
};

export function ContentEditor(props) {
    const [data, setData] = React.useState(props.data);
    const level = (props.usage === 'commentlist' ? 'commentlist_' : '') + (props.data.type ? props.data.type : 'root');

    // commentlist not working properly without this
    React.useEffect(() => setData(props.data), [props.data]);

    const onChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
        props.onInputChange(name, value);
    };

    const onValueChange = (value_id, new_value) => {
        data.values[value_id] = new_value;
        setData({ ...data });
        props.onInputChange('values', data.values);
    };

    const addNewValue = () => {
        const new_id = Math.max(...Object.keys(data.values).map((elem) => parseInt(elem))) + 1;
        onValueChange(new_id.toString(), { type: 'meta', values: '' });
        onChange('content', data.content + '{' + new_id + '}');
    };

    const removeValue = (value_id) => {
        delete data.values[value_id];
        props.onInputChange('values', data.values);

        onChange('content', data.content.replace('{' + value_id + '}', ''));
    };

    const content_editor =
        level === 'root' || level === 'commentlist_root' ? (
            <>
                <div className="form-group">
                    <label htmlFor="display">Display</label>
                    <select
                        className={'input-control'}
                        name="display"
                        value={data.display}
                        required={false}
                        onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                    >
                        <option value="">Default</option>
                        <option value={'emptycell'}>Empty cell</option>
                        <option value={'checkbox'}>Checkbox</option>
                        <option value={'checkboxValueOnly'}>Checkbox, value only</option>
                        <option value={'titleOnly'}>Title only</option>
                        <option value={'valueOnly'}>Value only</option>
                    </select>
                </div>
                {data.display === 'emptycell' ? (
                    <div key={'emptycellHeightKey'} className="form-group">
                        <label htmlFor="emptycellHeight">Height</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="emptycellHeight"
                            value={data.emptycellHeight}
                            required={true}
                            onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                        />
                    </div>
                ) : (
                    <div className="form-group">
                        <label htmlFor="content">Content</label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={'input-control'}
                            name="content"
                            value={data.content}
                            required={true}
                            onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                        />
                    </div>
                )}

                {data.display === 'titleOnly' || data.display === 'emptycell' ? null : (
                    <div className="form-group">
                        <button onClick={addNewValue} className="btn btn-blue">
                            Add new value
                        </button>
                    </div>
                )}
            </>
        ) : null;

    const values_editor = (
        <div className="form-group">
            <label htmlFor="content">Values</label>
            {Object.keys(data.values).map((v_id) => {
                return (
                    <fieldset key={'vf' + v_id} className="values-fieldset">
                        <legend>Value {v_id}</legend>
                        {(level === 'root' || level === 'commentlist_root') && Object.keys(data.values).length > 1 ? (
                            <DeleteIcon
                                style={{ float: 'right' }}
                                onClick={() => {
                                    removeValue(v_id);
                                }}
                                className="delete-icon"
                            />
                        ) : null}
                        <ValueEditor
                            id={v_id}
                            data={data.values[v_id]}
                            onInputChange={onValueChange}
                            job_type={props.job_type}
                            child_jobs={props.child_jobs}
                            datapoints={props.datapoints}
                            level={level}
                            usage={props.usage}
                        />
                    </fieldset>
                );
            })}
        </div>
    );

    return (
        <>
            {content_editor}
            {data.display === 'titleOnly' || data.display === 'emptycell' ? null : values_editor}
        </>
    );
}

export function ValueEditor(props) {
    const [data, setData] = React.useState(props.data);
    const level = useMemo(() => {
        return props.level ? props.level : 'root';
    }, [props.level]);

    const onChange = useCallback(
        (name, value) => {
            if (value === undefined) delete data[name];
            else data[name] = value;

            setData({ ...data });
            props.onInputChange(props.id, data);
        },
        [data, props]
    );

    // check type
    React.useEffect(() => {
        if (!data.hasOwnProperty('type')) {
            onChange('type', 'meta');
        }
    }, [data, level, onChange]);

    // commentlist not working properly without this
    React.useEffect(() => setData(props.data), [props.data]);

    const type_editor = (
        <div className="form-group">
            <label htmlFor="type">Content Type</label>
            <select
                required
                name="type"
                className={'input-control'}
                onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                value={data.type}
            >
                {AVAILABLE_VALUE_TYPES[level].map((element, index) => {
                    return (
                        <option key={'content_type_' + index} value={element.type}>
                            {element.label}
                        </option>
                    );
                })}
            </select>
        </div>
    );

    return (
        <>
            {type_editor}

            {data.type === 'meta' ? (
                <MetaValueEditor onChange={onChange} data={data} job_type={props.job_type} />
            ) : null}

            {data.type === 'child' ? (
                <ChildValueEditor onChange={onChange} data={data} child_jobs={props.child_jobs} usage={props.usage} />
            ) : null}

            {data.type === 'time' ? <TimeValueEditor onChange={onChange} data={data} /> : null}

            {data.type === 'previous' ? (
                <PreviousValueEditor
                    onChange={onChange}
                    data={data}
                    job_type={props.job_type}
                    child_jobs={props.child_jobs}
                    usage={props.usage}
                />
            ) : null}

            {data.type === 'childtimeagg' ? (
                <ChildTimeAggValueEditor onChange={onChange} data={data} child_jobs={props.child_jobs} />
            ) : null}

            {data.type === 'datapoint' ? (
                <DatapointValueEditor
                    onChange={onChange}
                    data={data}
                    datapoints={props.datapoints}
                    child_jobs={props.child_jobs}
                />
            ) : null}

            {data.type === 'math' ? (
                <MathValueEditor
                    onChange={onChange}
                    data={data}
                    datapoints={props.datapoints}
                    job_type={props.job_type}
                    child_jobs={props.child_jobs}
                />
            ) : null}
            {data.type === 'emptycell' ? <div>empty cell</div> : null}
        </>
    );
}

function MetaValueEditor(props) {
    const [data, setData] = React.useState(props.data);
    const [available_metas, setAvailableMetas] = React.useState([]);
    const [selected_meta, setSelectedMeta] = React.useState(null);

    // callback when a input changes
    const onChange = useCallback(
        (name, value) => {
            if (value === undefined) delete data[name];
            else data[name] = value;

            setData({ ...data });
            props.onChange(name, value);
        },
        [data, props]
    );

    // get available metas
    React.useEffect(() => {
        setAvailableMetas(meta_get_available_metas(props.job_type));
    }, [props.job_type]);

    // set selected meta from available metas
    React.useEffect(() => {
        if (available_metas && available_metas.length)
            setSelectedMeta(available_metas.find((element) => element.name === data.values));
    }, [available_metas, data]);

    // validate selected meta
    React.useEffect(() => {
        if (selected_meta === undefined && available_metas.length) onChange('values', available_metas[0].name);

        if (selected_meta) {
            if (selected_meta.type === 'date' && !data.hasOwnProperty('format'))
                onChange('format', DEFAULT_TIME_FORMAT);
            if (selected_meta.type !== 'date' && data.hasOwnProperty('format')) onChange('format', undefined);
        }
    }, [available_metas, data, onChange, selected_meta]);

    const format_editor = (
        <div className="form-group">
            <label htmlFor="format">Date Format</label>
            <input
                autoComplete="off"
                type="text"
                className={'input-control'}
                name="format"
                value={data.format ? data.format : ''}
                required={true}
                onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
            />
        </div>
    );

    return (
        <>
            <div className="form-group">
                <label htmlFor="values">Metadata Field</label>
                <select
                    required
                    name="values"
                    className={'input-control'}
                    onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                    value={data.values}
                >
                    {available_metas.map((meta, index) => {
                        return (
                            <option key={'meta_value_' + index} value={meta.name}>
                                {meta.label}
                            </option>
                        );
                    })}
                </select>
            </div>
            {selected_meta?.type === 'date' ? format_editor : null}
        </>
    );
}

function getChildJobTypeSelector(propname, value, childJobsCollection, onChangeEvent) {
    return (
        <div className="form-group">
            <label htmlFor="id">Child Job</label>
            <select
                required
                name={propname}
                className={'input-control'}
                onChange={(event) => onChangeEvent(event.currentTarget.name, event.currentTarget.value)}
                value={value?.toLowerCase()}
            >
                {childJobsCollection
                    ? childJobsCollection.map((element, index) => {
                          return (
                              <option key={'child_type_' + index} value={element.id}>
                                  {element.name}
                              </option>
                          );
                      })
                    : null}
            </select>
        </div>
    );
}

function ChildValueEditor(props) {
    const [data, setData] = React.useState(props.data);
    const [selected_child, setSelectedChild] = React.useState(null);

    // callback when a input changes
    const onChange = useCallback(
        (name, value) => {
            data[name] = value;
            setData({ ...data });
            props.onChange(name, value);
        },
        [data, props]
    );

    // check fields existance
    React.useEffect(() => {
        if (!data.hasOwnProperty('id')) onChange('id', '');
        if (!data.hasOwnProperty('content')) onChange('content', '{1}');
        if (
            !data.hasOwnProperty('values') ||
            typeof data.values === 'string' ||
            !data.values.hasOwnProperty('1') ||
            Object.keys(data.values).length !== 1
        )
            onChange('values', { 1: { type: 'meta', values: '' } });
    }, [data, onChange]);

    // set selected child
    React.useEffect(() => {
        setSelectedChild(props.child_jobs.find((element) => element.id.toLowerCase() === data.id.toLowerCase()));
    }, [data, props.child_jobs]);

    // validate selected child
    React.useEffect(() => {
        if (selected_child === undefined && props.child_jobs.length) onChange('id', props.child_jobs[0].id);
    }, [onChange, props.child_jobs, selected_child]);

    const child_selector = getChildJobTypeSelector('id', data?.id, props.child_jobs, onChange);

    const sub_value_editor = selected_child ? (
        <ContentEditor
            onInputChange={onChange}
            data={data}
            job_type={selected_child}
            child_jobs={[]}
            usage={props.usage}
        />
    ) : null;

    return (
        <>
            {child_selector}
            {sub_value_editor}
        </>
    );
}

function TimeValueEditor(props) {
    const [data, setData] = React.useState(props.data);

    // callback when a input changes
    const onChange = useCallback((name, value) => {
        data[name] = value;
        setData({ ...data });
        props.onChange(name, value);
    }, []);

    // data validation
    React.useEffect(() => {
        if (!data.hasOwnProperty('values') || !['start', 'end', 'duration'].includes(data.values))
            onChange('values', 'start');
        if (!data.hasOwnProperty('format')) onChange('format', DEFAULT_TIME_FORMAT);
    }, [data, onChange]);

    const value_editor = (
        <div className="form-group">
            <label htmlFor="values">Time Value</label>
            <select
                required
                name="values"
                className={'input-control'}
                onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                value={data.values}
            >
                <option key="time_value_1" value="start">
                    Start Time
                </option>
                <option key="time_value_2" value="end">
                    End Time
                </option>
                <option key="time_value_3" value="duration">
                    Duration
                </option>
            </select>
        </div>
    );

    const format_editor = (
        <div className="form-group">
            <label htmlFor="format">Date Format</label>
            <input
                autoComplete="off"
                type="text"
                className={'input-control'}
                name="format"
                value={data.format ? data.format : ''}
                required={true}
                onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
            />
        </div>
    );

    return (
        <>
            {value_editor}
            {format_editor}
        </>
    );
}

function PreviousValueEditor(props) {
    const [data, setData] = React.useState(props.data);

    // callback when a input changes
    const onChange = useCallback(
        (name, value) => {
            data[name] = value;
            setData({ ...data });
            props.onChange(name, value);
        },
        [data, props]
    );

    // check fields existance
    React.useEffect(() => {
        if (!data.hasOwnProperty('content') || data.content !== '{1}') onChange('content', '{1}');
        if (
            !data.hasOwnProperty('values') ||
            typeof data.values === 'string' ||
            !data.values.hasOwnProperty('1') ||
            Object.keys(data.values).length !== 1
        )
            onChange('values', { 1: { type: 'meta', values: '' } });
    }, [data, onChange]);

    return typeof data.values !== 'string' ? (
        <ContentEditor
            onInputChange={onChange}
            data={data}
            job_type={props.job_type}
            child_jobs={props.child_jobs}
            usage={props.usage}
        />
    ) : null;
}

const available_aggregations = [
    { type: 'firststart', label: 'First Start', time_format: true },
    { type: 'lastend', label: 'Last End', time_format: true },
    { type: 'total', label: 'Total Time', time_format: false },
    { type: 'totalhours', label: 'Total Hours', time_format: false },
    { type: 'processing', label: 'Total Processing Time', time_format: false },
    { type: 'waiting', label: 'Total Waiting Time', time_format: false },
    { type: 'avgwaiting', label: 'Average Waiting Time', time_format: false },
];

function ChildTimeAggValueEditor(props) {
    const [data, setData] = React.useState(props.data);
    const [selected_child, setSelectedChild] = React.useState(null);
    const [selected_agg, setSelectedAgg] = React.useState(null);

    // callback when a input changes
    const onChange = useCallback(
        (name, value) => {
            if (value === undefined) delete data[name];
            else data[name] = value;

            setData({ ...data });
            props.onChange(name, value);
        },
        [data, props]
    );

    // set selected child / agg
    React.useEffect(() => {
        if (
            !data.hasOwnProperty('values') ||
            !available_aggregations.map((element) => element.type).includes(data.values)
        )
            onChange('values', available_aggregations[0].type);

        setSelectedChild(props.child_jobs.find((element) => element.id.toLowerCase() === data.id.toLowerCase()));
        setSelectedAgg(available_aggregations.find((element) => element.type === data.values));
    }, [data, onChange, props.child_jobs]);

    // validate selected child
    React.useEffect(() => {
        if (selected_child === undefined && props.child_jobs.length) onChange('id', props.child_jobs[0].id);
    }, [onChange, props.child_jobs, selected_child]);

    // set format
    React.useEffect(() => {
        if (selected_agg && selected_agg.time_format)
            if (!data.hasOwnProperty('format')) onChange('format', DEFAULT_TIME_FORMAT);
        if (selected_agg && !selected_agg.time_format) if (data.hasOwnProperty('format')) onChange('format', undefined);
    }, [data, onChange, selected_agg]);

    const child_selector = (
        <div className="form-group">
            <label htmlFor="id">Child Job</label>
            <select
                required
                name="id"
                className={'input-control'}
                onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                value={data.id.toLowerCase()}
            >
                {props.child_jobs.map((element, index) => {
                    return (
                        <option key={'child_type_' + index} value={element.id}>
                            {element.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );

    const agg_type_selector = (
        <div className="form-group">
            <label htmlFor="values">Time Aggregation Type</label>
            <select
                required
                name="values"
                className={'input-control'}
                onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                value={data.values}
            >
                {available_aggregations.map((element, index) => {
                    return (
                        <option key={'time_agg_' + index} value={element.type}>
                            {element.label}
                        </option>
                    );
                })}
            </select>
        </div>
    );

    const format_editor = (
        <div className="form-group">
            <label htmlFor="format">Date Format</label>
            <input
                autoComplete="off"
                type="text"
                className={'input-control'}
                name="format"
                value={data.format ? data.format : ''}
                required={true}
                onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
            />
        </div>
    );

    return (
        <>
            {child_selector}
            {agg_type_selector}
            {selected_agg?.time_format ? format_editor : null}
        </>
    );
}

function DatapointValueEditor(props) {
    const [data, setData] = React.useState(props.data);
    const [available_datapoints, setAvailableDatapoints] = React.useState([]);

    // callback when a input changes
    const onChange = useCallback(
        (name, value) => {
            data[name] = value;
            setData({ ...data });
            props.onChange(name, value);
        },
        [data, props]
    );

    // check values field existance
    React.useEffect(() => {
        if (!data.hasOwnProperty('values') || typeof data.values !== 'string') {
            onChange('values', '');
        }
    }, [data, onChange]);

    // validate values field and set avaialble datapoints
    React.useEffect(() => {
        setAvailableDatapoints(
            datapoints_source_validate(props.datapoints, ['aggregation'], data.values, (value) =>
                onChange('values', value)
            )
        );
    }, [data, onChange, props.datapoints]);
    console.log(data);
    return (
        <>
            <div className="form-group">
                <label htmlFor="values">Datapoint</label>
                <select
                    required
                    name="values"
                    className={'input-control'}
                    onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                    value={data?.values}
                >
                    {available_datapoints
                        ? available_datapoints.map((element, index) => {
                              return (
                                  <option key={'datapoint_' + index} value={element.datapointId}>
                                      {element.datapointId}
                                  </option>
                              );
                          })
                        : null}
                </select>
            </div>
            {/* <> --> This part is defined by datapoints.. 
        <div className="form-group">
            <label htmlFor="jobtyperef">Job type ref</label>
            <select required name="jobtyperef" className={"input-control"} onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)} value={data?.jobtyperef}>
                <option key={"default"} value={emptyGuid()} >Default</option>
                {props.child_jobs ? props.child_jobs.map((element, index) => {
                    return <option key={"child_type_" + index} value={element.id}>{element.name}</option>
                }):null}
            </select>
        </div>
    </> */}
            {/* {getChildJobTypeSelector("jobtyperef",data?.jobtyperef, props.child_jobs,onChange) } */}
        </>
    );
}

const available_operators = [
    { type: '+', label: 'Sum' },
    { type: '-', label: 'Difference' },
    { type: '*', label: 'Multiply' },
    { type: '/', label: 'Divide' },
];
function MathValueEditor(props) {
    const [data, setData] = React.useState(props.data);

    // callback when a input changes
    const onChange = useCallback(
        (name, value) => {
            data[name] = value;
            setData({ ...data });
            props.onChange(name, value);
        },
        [data, props]
    );

    // check fields existance
    React.useEffect(() => {
        if (!data.hasOwnProperty('operator') || !available_operators.map((elem) => elem.type).includes(data.operator))
            onChange('operator', available_operators[0].type);
        if (
            !data.hasOwnProperty('values') ||
            typeof data.values === 'string' ||
            !Object.keys(data.values).every((elem) => ['1', '2'].includes(elem)) ||
            !['1', '2'].every((elem) => Object.keys(data.values).includes(elem))
        )
            onChange('values', { 1: { type: 'meta', values: '' }, 2: { type: 'meta', values: '' } });
    }, [data, onChange]);

    const operator_selector = (
        <div className="form-group">
            <label htmlFor="operator">Operator</label>
            <select
                required
                name="operator"
                className={'input-control'}
                onChange={(event) => onChange(event.currentTarget.name, event.currentTarget.value)}
                value={data.operator ?? ''}
            >
                {available_operators.map((element, index) => {
                    return (
                        <option key={'math_op_' + index} value={element.type}>
                            {element.label}
                        </option>
                    );
                })}
            </select>
        </div>
    );

    return (
        <>
            {operator_selector}
            {typeof data.values !== 'string' ? (
                <ContentEditor
                    onInputChange={onChange}
                    data={data}
                    job_type={props.job_type}
                    child_jobs={props.child_jobs}
                    datapoints={props.datapoints}
                />
            ) : null}
        </>
    );
}
