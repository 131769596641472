export const guidIsNullOrEmpty = (value) => {
    return !value || value === emptyGuid();
};

// eslint-disable-next-line lodash/prefer-constant
export const emptyGuid = () => {
    return '00000000-0000-0000-0000-000000000000';
};

export const newGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
export const newId = () => {
    return newGuid().substr(25);
};
