import React from 'react';
import CreatableSelect from 'react-select/creatable';
import SimpleSpinner from '../components/common/Spinner/SimpleSpinner';

const customStyles = {
    option: (_base) => ({
        color: 'black',
    }),
};
const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#000000',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};
const loadingAnimStyles = {
    position: 'relative',
    float: 'right',
    marginRight: '50px',
    marginTop: '-35px',
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span style={{ color: data.color, fontWeight: 700, fontSize: 16 }}>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const createReactSelectOptions = (options, type, supportedTypes) => {
    if (!options) return [];
    const uniqueOptions = options.filter((a, index) => {
        const _a = JSON.stringify(a);
        return (
            index ===
            options.findIndex((obj) => {
                return JSON.stringify(obj) === _a && (!a.dtype || supportedTypes.indexOf(a.dtype));
            })
        );
    });

    return uniqueOptions.map((o) => {
        return {
            label: o.dataValue,
            value: type + '/' + o.dataValue,
            type: type,
        };
    });
};

//Usage is not here, but the same kinds of checks are here, so trying to have them at the same location
export const fromDatavalueObjToDatasourceValueAndSource = (dvObj, valid_sources) => {
    // datavalues: "Data Values",
    // data_aggregates: "Data Aggregates",
    // trace: "Trace",
    // events:"Event"
    if (!dvObj || typeof dvObj !== 'object') throw new Error('No item, or wrong type', dvObj);
    const dv = dvObj.dataValue;
    let sourceProp;
    if (dvObj.historic) {
        sourceProp = 'data_aggregates';
    } else if (dvObj.isEvent) {
        sourceProp = 'events';
    } else if (dvObj.isTrace) {
        sourceProp = 'trace';
    } else {
        sourceProp = 'datavalues';
    }
    if (valid_sources && !Object.prototype.hasOwnProperty.call(valid_sources, sourceProp)) {
        //throw new Error("Datavalue is not part of the valid sources",sourceProp,dvObj,valid_sources)
        console.log('Datavalue is not part of the valid sources', sourceProp, dvObj, valid_sources);
    }

    return { datavalue: dv, source: sourceProp };
};

export const fromStringRepToDatavalueObject = (stringRep, options) => {
    if (!stringRep || typeof stringRep != 'string' || stringRep.indexOf('/') === -1) {
        console.log('Stringrep empty or wrong format', stringRep, options);
        return;
    }
    const routing = stringRep.substring(0, stringRep.indexOf('/'));
    const datavalueName = stringRep.substring(stringRep.indexOf('/') + 1);

    const dvobj = options.find((o) => {
        //console.log(o);
        const odatavalue = o.dataValue;
        if (routing === 'rt' && !o.isEvent && !o.historic && !o.isTrace && datavalueName === odatavalue) {
            return true;
        }
        if (routing === 'event' && o.isEvent && datavalueName === odatavalue) {
            return true;
        }
        if (routing === 'historic' && o.historic && datavalueName === odatavalue) {
            return true;
        }
        if (routing === 'trace' && o.isTrace && datavalueName === odatavalue) {
            return true;
        }
        return false;
    });
    //console.log("returning fromStrinRep",dvobj)
    return dvobj;
};
export const fromSourceAndDataValueToStringRep = (source, datavalue) => {
    if (!source) return datavalue;
    //this is a mess.. why can't we reuse properties and values from the start..?
    switch (source) {
        case 'trace':
            return `trace/${datavalue}`;

        case 'data_aggregates':
            return `historic/${datavalue}`;

        case 'datavalues':
            return `rt/${datavalue}`;

        case 'events':
            return `event/${datavalue}`;
        default:
            throw new Error('Unknown source!');
    }
};
const fromDataValueObjectToStringRep = (o) => {
    //datavalue object.
    if (!o || typeof o !== 'object') return o;

    if (!o.isEvent && !o.historic && !o.isTrace) return `rt/${o.dataValue}`;
    if (o.historic) return `historic/${o.dataValue}`;
    if (o.isTrace) return `trace/${o.dataValue}`;
    if (o.isEvent) return `event/${o.dataValue}`;
};

//export default DataValueSelect (props) => {
export function DataValueSelect(props) {
    let groupedOptions = [];
    //console.log(props.options);
    if (typeof props.options?.filter === 'function') {
        groupedOptions = [
            {
                label: 'Real time',
                color: '#89B374',
                type: 'rt',
                options: createReactSelectOptions(
                    props.options.filter((o) => !o.isEvent && !o.historic && !o.isTrace),
                    'rt',
                    props.widget?.supportedTypes
                ),
            },
            {
                label: 'Event',
                color: '#01ACB6',
                type: 'event',
                options: createReactSelectOptions(
                    props.options.filter((o) => o.isEvent),
                    'event',
                    props.widget?.supportedTypes
                ),
            },
            {
                label: 'Trace',
                color: '#FEBE28',
                type: 'trace',
                options: createReactSelectOptions(
                    props.options.filter((o) => o.isTrace),
                    'trace',
                    props.widget?.supportedTypes
                ),
            },
            {
                label: 'Historic',
                color: '#2B2B2D',
                type: 'historic',
                options: createReactSelectOptions(
                    props.options.filter((o) => o.historic),
                    'historic',
                    props.widget?.supportedTypes
                ),
            },
        ];
    }
    let selectedValue = fromDataValueObjectToStringRep(props.widget?.dataValue || props.widget?.histDataValue);

    //console.log("sel", selectedValue, props.widget)

    if (selectedValue?.indexOf('/') === -1) {
        selectedValue = 'rt/' + selectedValue;
        if (props.widget?.histDataValue) {
            selectedValue = 'historic/' + selectedValue;
        } else if (props.widget?.traceEnabled) {
            selectedValue = 'trace/' + selectedValue;
        } else if (props.widget?.valueFormat === 'eventmode') {
            selectedValue = 'event/' + selectedValue;
        }
    }

    let selectedObject;
    if (selectedValue) {
        const type = selectedValue.substring(0, selectedValue.indexOf('/'));
        //console.log(type, groupedOptions.filter(g => g.type === type)[0]);
        selectedObject = groupedOptions
            .filter((g) => g.type === type)[0]
            ?.options.find((o) => o.value === selectedValue);
        if (!selectedObject) {
            selectedObject = {
                label: selectedValue,
                value: selectedValue,
                type: 'user',
            };
            groupedOptions.push({
                label: 'User defined',
                color: '#c9b54f',
                type: 'user',
                options: [selectedObject],
            });
        }
        //console.log(selectedObject);
    }

    //console.log("PRP", props);

    return (
        <div>
            <CreatableSelect
                style={customStyles}
                styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (styles) => ({
                        ...styles,
                    }),
                }}
                menuPortalTarget={document.body}
                options={groupedOptions}
                formatGroupLabel={formatGroupLabel}
                value={selectedObject}
                onChange={(e) => props.onChange(e)}
                classNamePrefix={'optimar'}
                required={props.required}
            />
            {props.fetchingDataValues ? (
                <div className="datavalue-loadinganim" style={loadingAnimStyles}>
                    <SimpleSpinner size={'2x'}></SimpleSpinner>
                </div>
            ) : null}
        </div>
    );
}
