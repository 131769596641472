import { noop } from 'lodash';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setNotificationList } from '../../actions';
import GenericTable from '../../components/common/GenericTable';
import RowContextMenu from '../../components/common/GenericTable/TableWidgets/RowContextMenu';
import Spinner from '../../components/common/Spinner/Spinner';
import api from '../../utility/api';
import { modalStyle } from '../../utility/modalstyles';
import {
    getNotificationItem,
    getNotificationLink,
    getNotificationMessageShort,
    getNotificationPath,
    getSearchParams,
} from '../../utility/notificationHelper';
import '../list/generic-list.scss';
import './AlertList.scss';
import NotificationSettingEdit, {
    fetchNotificationTypes,
    fetchUserNotificationSettings,
    toggleEmailNotification,
} from './NotificationSettingEdit';

const baseUrl = process.env.REACT_APP_API + '/api/userNotifications';

const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] != null ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase()) : true;
};

const confirmHideAllNotifications = (onConfirm) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>Hide all notifications</h1>
                    <p>
                        You are about to hide all notifications
                        <br />
                    </p>
                    <p>Are you sure?</p>
                    <div className="buttons">
                        <button className="btn btn-yellow" onClick={() => onClose()}>
                            No, cancel
                        </button>
                        <button
                            className="btn btn-red"
                            onClick={() => {
                                onConfirm();
                                onClose();
                            }}
                        >
                            Yes, hide
                        </button>
                    </div>
                </div>
            );
        },
    });
};

const confirmMarkAllRead = (onConfirm) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>Mark all as read</h1>
                    <p>
                        You are about to mark all notifications as read
                        <br />
                    </p>
                    <p>Are you sure?</p>
                    <div className="buttons">
                        <button className="btn btn-yellow" onClick={() => onClose()}>
                            No, cancel
                        </button>
                        <button
                            className="btn btn-red"
                            onClick={() => {
                                onConfirm();
                                onClose();
                            }}
                        >
                            Yes, mark read
                        </button>
                    </div>
                </div>
            );
        },
    });
};

const confirmSilenceNotification = (type, onConfirm) => {
    const title = 'test';
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>Silence notification type</h1>
                    <p>
                        You are about to silence notifications for
                        <br />
                    </p>
                    <span>
                        <b>{title}</b>
                    </span>
                    <p>Are you sure?</p>
                    <div className="buttons">
                        <button className="btn btn-yellow" onClick={() => onClose()}>
                            No, cancel
                        </button>
                        <button
                            className="btn btn-red"
                            onClick={() => {
                                onConfirm(type);
                                onClose();
                            }}
                        >
                            Yes, silence
                        </button>
                    </div>
                </div>
            );
        },
    });
};

const rowContext = (
    { onSilenceNotificationType, onMarkRead, onHide },
    row,
    userNotificationSettings,
    notificationTypes
) => {
    let disabled = false;
    if (userNotificationSettings) {
        const notificationType = notificationTypes.find((x) => x.name === row.original.type);
        if (
            notificationType &&
            userNotificationSettings.notificationTypeSettings.find(
                (x) => x.id === notificationType.id && x.muteEmailNotification === true
            )
        ) {
            disabled = true;
        }
    }

    const contextMenu = [
        {
            content: <span>Mark as read</span>,
            action: (row) => onMarkRead(row.original),
        },
        {
            content: <span>Hide notification</span>,
            action: (row) => onHide(row.original),
        },
    ];

    if (!disabled) {
        contextMenu.push({
            content: <span>Silence this notification type</span>,
            action: (row) => onSilenceNotificationType(row.original),
        });
    }

    return contextMenu;
};

const getColumns = (contextActions, userNotificationSettings, notificationTypes) => {
    const { onMarkRead } = contextActions;
    return [
        {
            Header: 'Type',
            Cell: (cell) => (
                <span style={{ fontWeight: cell.row.original.read ? 'normal' : 'bold' }}>
                    {getNotificationMessageShort(cell.row.original.type)}
                </span>
            ),
            filterable: true,
        },
        {
            Header: 'Item',
            accessor: 'objectName',
            Cell: (item) => {
                return (
                    <Link
                        onClick={() => onMarkRead(item.row.original)}
                        to={{
                            pathname: getNotificationLink(item.row.original),
                            search: getSearchParams(item.row.original),
                        }}
                    >
                        {getNotificationItem(item.row.original)}
                    </Link>
                );
            },
            filterable: true,
        },
        {
            Header: 'Date',
            Cell: (cell) => {
                return moment.utc(cell.row.original.createdOn).local().format('DD.MM.YYYY HH:mm');
            },
            filterable: true,
        },
        {
            Header: 'Path',
            Cell: (item) => {
                return (
                    <Link onClick={() => onMarkRead(item.row.original)} to={getNotificationLink(item.row.original)}>
                        {getNotificationPath(item.row.original)}
                    </Link>
                );
            },
            filterable: true,
        },
        {
            Header: () => null,
            id: 'rowContext',
            Cell: ({ row }) => (
                <RowContextMenu
                    options={rowContext(contextActions, row, userNotificationSettings, notificationTypes)}
                    row={row}
                />
            ),
        },
    ];
};

const getHeader = (notifications, { onManageNotifications, onMarkAllRead, onHideAllNotifications }) => {
    return {
        left: [
            <h3 key="gth-0" className={'gt-header-count'}>
                {notifications ? notifications.filter((notification) => notification.read !== true).length : 0} unread
                notification(s)
            </h3>,
            <span key="gth-1" className="gt-header-button" onClick={() => onMarkAllRead()}>
                Mark all as read
            </span>,
            <span key="gth-2" className="gt-header-button alert-header-button" onClick={() => onHideAllNotifications()}>
                Hide all notifications
            </span>,
        ],
        right: [
            <span key="gth-3" className="gt-header-button " onClick={() => onManageNotifications()}>
                Manage notifications
            </span>,
        ],
    };
};

const loadNotifications = async (dispatch) => {
    try {
        const response = await api.get(`${baseUrl}`);
        dispatch(setNotificationList(response.data));
    } catch (e) {
        console.error(e);
    }
};

const markAllNotificationsRead = async (dispatch) => {
    try {
        await api.get(`${baseUrl}/readall`);
        await loadNotifications(dispatch);
    } catch (e) {
        console.error(e);
    }
};

const hideAllNotifications = async (dispatch) => {
    try {
        await api.post(`${baseUrl}/ackall`);
        await loadNotifications(dispatch);
    } catch (e) {
        console.error(e);
    }
};

export const setNotificationRead = async (userNotificationId, dispatch) => {
    try {
        await api.put(`${baseUrl}/read/${userNotificationId}`);
        await loadNotifications(dispatch);
    } catch (e) {
        console.error(e);
    }
};

const hideNotification = async (userNotificationId, dispatch) => {
    try {
        await api.put(`${baseUrl}/ack/${userNotificationId}`);
        await loadNotifications(dispatch);
    } catch (e) {
        console.error(e);
    }
};

const silenceNotificationType = async (type, dispatch) => {
    try {
        await toggleEmailNotification(type, dispatch);
    } catch (e) {
        console.error(e);
    }
};

const AlertList = (_props) => {
    const notifications = useSelector((state) => state.notifications.notificationList);
    const userNotificationSettings = useSelector((state) => state.notifications.userNotificationSettings);
    const notificationTypes = useSelector((state) => state.notifications.notificationTypes);

    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchNotificationTypes(dispatch).then(() =>
            fetchUserNotificationSettings(dispatch).then(() => setIsLoading(false))
        );
    }, [dispatch]);

    if (!notifications || isLoading) {
        return <Spinner text="notifications" />;
    }

    return (
        <div>
            <div className={'header-container'}>
                <div className={'currentHeading'}>Notifications</div>
            </div>
            <GenericTable
                defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                header={getHeader(notifications, {
                    onManageNotifications: () => setIsEditModalVisible(true),
                    onHideAllNotifications: () => confirmHideAllNotifications(() => hideAllNotifications(dispatch)),
                    onMarkAllRead: () => confirmMarkAllRead(() => markAllNotificationsRead(dispatch)),
                })}
                data={notifications}
                localPaging={true}
                columns={getColumns(
                    {
                        onMarkRead: (row) => setNotificationRead(row.id, dispatch),
                        onHide: (row) => hideNotification(row.id, dispatch),
                        onSilenceNotificationType: (row) =>
                            confirmSilenceNotification(row.type, () =>
                                silenceNotificationType(row.notificationTypeId, dispatch)
                            ),
                    },
                    userNotificationSettings,
                    notificationTypes
                )}
                showPagination={true}
                defaultPageSize={20}
                minRows={0}
                fetchData={noop}
                triggerFetch={false}
            />
            <Modal
                isOpen={isEditModalVisible}
                onRequestClose={() => setIsEditModalVisible(false)}
                style={modalStyle}
                ariaHideApp={false}
            >
                <NotificationSettingEdit CloseModalCallback={() => setIsEditModalVisible(false)} />
            </Modal>
        </div>
    );
};

export default AlertList;
