import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import api from '../../../utility/api';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import { ReactComponent as EditIcon } from '../../../images/list/optimar_edit.svg';
import { confirmAlert } from 'react-confirm-alert';
import './AdminitemDataTypeAddEdit.scss';
import { noop } from 'lodash';

class AdminItemDataTypeAddEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            dataValues: [],
            isDirty: false,
            dataType: [],
            editValues: [],
            dataValue: '',
            histDataValue: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubmit = this.createSubmit.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);
        this.fetchDataValues = this.fetchDataValues.bind(this);
        this.dataValueChange = this.dataValueChange.bind(this);
        this.histDataValueChange = this.histDataValueChange.bind(this);
        this.submitDataValue = this.submitDataValue.bind(this);
        this.deleteDataValue = this.deleteDataValue.bind(this);
        this.confirmDeleteDV = this.confirmDeleteDV.bind(this);
    }

    handleChange(event) {
        this.setState({
            isDirty: true,
            userAdded: false,
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;
        const tmp = Object.assign({}, this.state.dataType);
        tmp[name] = value;

        this.setState({ dataType: tmp });
    }

    componentDidMount() {
        if (this.props.editDataType) {
            this.fetchDataValues();
            api.get(process.env.REACT_APP_API + '/api/itemDataType/' + this.props.editDataType.id).then((res) => {
                this.setState({
                    dataType: res.data,
                    mode: 'edit',
                });
            });
        }
    }

    fetchDataValues() {
        api.get(process.env.REACT_APP_API + '/api/itemdatavalue/datatype/' + this.props.editDataType.id)
            .then((res) => this.setState({ dataValues: res.data }))
            .catch((err) => {
                console.error('ERROR: ', err);
            });
    }

    handleSubmit(event) {
        if (this.state.mode === 'edit') {
            this.updateSubmit(event);
        } else {
            this.createSubmit(event);
        }
    }
    createSubmit(event) {
        event.preventDefault();

        const dataType = {
            id: this.state.dataType.id,
            name: this.state.dataType.name,
        };

        api.post('/api/itemDataType', dataType)
            .then((response) => {
                if (response.status === 200) {
                    const dvs = Object.assign([], this.state.dataValues);

                    dvs.forEach((dv) => {
                        if (!dv.itemDataTypeId) {
                            dv.itemDataTypeId = response.data.id;
                        }
                    });

                    api.put('/api/itemdatavalue', dvs).then((res) => {
                        if (res.status === 200) {
                            NotificationManager.success('Datatype created', 'Datatype successfully created', 5000);
                            const tmp = Object.assign({}, this.state.dataType);
                            tmp.name = '';
                            tmp.categoryId = '';
                            tmp.description = '';
                            tmp.brand = '';

                            this.setState({
                                isDirty: false,
                                formValid: false,
                                dataType: tmp,
                                dataValues: [],
                            });
                        } else {
                            NotificationManager.error('Datatype created, but datavalues failed', 'Error', 5000);
                        }
                    });
                } else {
                    NotificationManager.error('Error', 'Failed to create datatype', 5000);
                    throw new Error(response.status);
                }
            })
            .then((res) => {
                this.props.addedCallback(res);
            })
            .catch(noop);
    }

    updateSubmit(event) {
        event.preventDefault();

        const dataType = {
            id: this.state.dataType.id,
            name: this.state.dataType.name,
        };
        console.log(this.state, dataType);

        api.put(process.env.REACT_APP_API + '/api/itemDataType/' + dataType.id, dataType)
            .then((response) => {
                if (response.status === 200) {
                    const dataValues = Object.assign([], this.state.dataValues);
                    dataValues.forEach((dv) => {
                        if (!dv.itemDataTypeId) {
                            dv.itemDataTypeId = this.props.editDataType.id;
                        }
                    });

                    api.put('/api/itemDataValue', dataValues).then((res) => {
                        if (res.status === 200) {
                            NotificationManager.success('Datatype updated', 'Datatype successfully updated', 5000);
                            this.props.editedCallback(response.data);
                        } else {
                            NotificationManager.error('Datatype updated, but datavalues failed', 'Error', 5000);
                        }
                    });
                } else {
                    NotificationManager.error('Error', 'Failed to update datatype', 5000);
                    // throw new Error(response.status);
                }
            })
            .catch(noop);
    }

    renderButton() {
        let button;

        if (this.state.mode === 'edit') {
            button = (
                <button className="btn btn-blue btn-grow" onClick={this.handleSubmit} value="Submit">
                    Save changes
                </button>
            );
        } else {
            button = (
                <button className="btn btn-blue btn-grow" onClick={this.handleSubmit} value="Submit">
                    Create
                </button>
            );
        }

        return button;
    }

    handleCategoryChange(event) {
        event.preventDefault();
        const tmp = Object.assign({}, this.state.dataType);
        tmp.categoryId = event.target.value;

        this.setState({
            dataType: tmp,
        });
    }

    removeFile(index) {
        const files = this.state.files;
        files.splice(index, 1);
        this.setState({ files });
    }

    uploadFiles() {
        this.state.files.forEach((file) => {
            this.fileUpload(file);
        });
    }

    dataValueChange(e) {
        this.setState({ dataValue: e.target.value });
    }

    histDataValueChange(e) {
        this.setState({ histDataValue: e.target.value });
    }

    submitDataValue() {
        const tmp = Object.assign([], this.state.dataValues);
        const tmpEditVals = Object.assign([], this.state.editValues);
        tmp.push({
            dataValue: this.state.dataValue,
            histDataValue: this.state.histDataValue !== '' ? this.state.histDataValue : null,
        });
        tmpEditVals.push(this.state.dataValues.length);

        this.setState({
            dataValues: tmp,
            dataValue: '',
            histDataValue: '',
            editValues: tmpEditVals,
        });
    }

    deleteDataValue(i) {
        const tmp = Object.assign([], this.state.dataValues);
        if (tmp[i].id) {
            api.delete('/api/itemdatavalue/' + tmp[i].id);
        }
        tmp.splice(i, 1);
        this.setState({
            dataValues: tmp,
        });
    }

    confirmDeleteDV(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Delete Datavalue</h1>
                        <p>Are you sure you want to delete this datavalue?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.deleteDataValue(item);
                                    onClose();
                                }}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    toggleEdit(index) {
        const idx = this.state.editValues.indexOf(index);
        const tmpVals = Object.assign([], this.state.editValues);

        // If index isn't in edit array, add it. Else remove it
        if (idx < 0) {
            tmpVals.push(index);
        } else {
            tmpVals.splice(idx, 1);
        }

        this.setState({
            editValues: tmpVals,
        });
    }

    valueChange(event, index, isHistVal) {
        const tmpVals = Object.assign([], this.state.dataValues);
        if (event.target.name === 'isevent') {
            tmpVals[index].isEvent = !tmpVals[index].isEvent || false;
        } else if (isHistVal) {
            tmpVals[index].histDataValue = event.target.value;
        } else {
            tmpVals[index].dataValue = event.target.value;
        }

        this.setState({
            dataValues: tmpVals,
        });
    }

    render() {
        return (
            <div className="admin-plant-add-edit-form">
                {this.state.mode === 'edit' ? <h1>Edit datatype</h1> : <h1>New datatype</h1>}
                <form style={{ width: '100%' }} onSubmit={this.handleSubmit}>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            className={'admin-input-control'}
                            autoComplete="off"
                            type="text"
                            required
                            name="name"
                            value={this.state.dataType.name || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleOnFocusOut}
                        />
                    </div>
                </form>
                <table className="dv-table">
                    <thead>
                        <tr>
                            <th>Value</th>
                            {<th>Historic Value</th>}
                            {<th>Event</th>}
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataValues.map((dv, i) => {
                            return (
                                <tr className="dv-row" key={'dvrow-' + i}>
                                    {this.state.editValues.indexOf(i) >= 0 ? (
                                        <td>
                                            <input
                                                onChange={(e) => this.valueChange(e, i, false)}
                                                style={{ backgroundColor: 'white' }}
                                                type="text"
                                                value={dv.dataValue}
                                            />
                                        </td>
                                    ) : (
                                        <td className="dv-value">{dv.dataValue}</td>
                                    )}
                                    {this.state.editValues.indexOf(i) >= 0 ? (
                                        <td>
                                            <input
                                                onChange={(e) => this.valueChange(e, i, true)}
                                                style={{ backgroundColor: 'white' }}
                                                type="text"
                                                value={dv.histDataValue}
                                            />
                                        </td>
                                    ) : (
                                        <td className="dv-value">{dv.histDataValue}</td>
                                    )}
                                    <td className="dv-value">
                                        <input
                                            type="checkbox"
                                            name="isevent"
                                            checked={dv.isEvent || false}
                                            value={dv.isEvent || false}
                                            onChange={(e) => this.valueChange(e, i, false)}
                                            disabled={this.state.editValues.indexOf(i) < 0}
                                        />
                                    </td>
                                    <td onClick={() => this.toggleEdit(i)} className="dv-edit">
                                        <EditIcon />
                                    </td>
                                    <td onClick={() => this.confirmDeleteDV(i)} className="dv-delete">
                                        <DeleteIcon />
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td>
                                <input
                                    value={this.state.dataValue}
                                    style={{ backgroundColor: 'white' }}
                                    onChange={this.dataValueChange}
                                />
                            </td>
                            <td>
                                <input
                                    value={this.state.histDataValue}
                                    style={{ backgroundColor: 'white' }}
                                    onChange={this.histDataValueChange}
                                />
                            </td>
                            <td>
                                <button
                                    onClick={this.submitDataValue}
                                    style={{ minWidth: 'unset' }}
                                    className="btn btn-blue"
                                >
                                    Add
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="btn-row btn-row-grow">
                    <button className="btn btn-yellow btn-grow" onClick={this.props.cancelCallback}>
                        Cancel
                    </button>
                    {this.renderButton()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(AdminItemDataTypeAddEdit);
