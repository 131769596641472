import React, { useCallback, useEffect, useState } from 'react';
import SimpleSpinner from '../Spinner/SimpleSpinner';
import api from '../../../utility/api';
import './UserInfoTooltip.scss';
import { guidIsNullOrEmpty } from '../../../utility/guidFunctions';
import { ReactComponent as InfoIcon } from '../../../images/user.svg'; //'../../../images/new/optimar_chat.svg';
import ReactTooltip from 'react-tooltip';

function UserInfoTooltip({
    tooltipId,
    userId,
    placement: place = 'right',
    tooltipEffect: effect = 'solid',
    tooltipDelayHide: delayHide = 500,
}) {
    const [currentItem, setCurrentItem] = useState(null);
    const [fetchingItem, setFetching] = useState(false);
    const [error, setError] = useState();

    const fetchUserInfo = useCallback(async (userId) => {
        try {
            setFetching(true);
            const url = `${process.env.REACT_APP_API}/api/plantcontacts/userinfo/${userId}`;
            const res = await api.get(url);
            setCurrentItem(res.data);
        } catch (err) {
            setError(err);
            console.log('Unable to get user info', err);
        } finally {
            setFetching(false);
        }
    }, []);

    useEffect(() => {
        if (error !== undefined || currentItem != null || fetchingItem || guidIsNullOrEmpty(userId)) {
            return;
        }

        fetchUserInfo(userId);
    }, [currentItem, error, fetchUserInfo, fetchingItem, userId]);

    if (fetchingItem) {
        return (
            <ReactTooltip
                className="userInfoTooltip"
                id={tooltipId}
                title="Fetching User Info"
                place={place}
                effect={effect}
                delayHide={delayHide}
            >
                <SimpleSpinner />
            </ReactTooltip>
        );
    }
    if (!fetchingItem && !currentItem) {
        return (
            <ReactTooltip
                className="userInfoTooltip"
                id={tooltipId}
                title="Could not get user info"
                place={place}
                effect={effect}
                delayHide={delayHide}
            >
                No Info
            </ReactTooltip>
        );
    }
    return (
        <ReactTooltip
            className="userInfoTooltip"
            id={tooltipId}
            title="User Info"
            place={place}
            effect={effect}
            delayHide={delayHide}
        >
            <div className="userInfoOuter">
                <div className="userInfoLeft">
                    <InfoIcon className="infoicon" />
                </div>
                <div className="userInfoRight">
                    <div className="userInfoValueContainer">
                        <div className="userInfoValueLabel">Email</div>
                        <div className="userInfoValue value">{currentItem.email}</div>
                    </div>
                    <div className="userInfoValueContainer">
                        <div className="userInfoValueLabel">Phone</div>
                        <div className="userInfoValue value">{currentItem.phone}</div>
                    </div>
                </div>
            </div>
        </ReactTooltip>
    );
}
export default UserInfoTooltip;
