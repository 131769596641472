import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import AdminPlantItems from '../AdminPlantItems';
import AdminPlantConfig from '../../components/common/AdminPlantConfig';
import AdminPlantLog from '../../components/common/AdminPlantLog';
import AdminPlantNewItems from '../../components/common/AdminPlantNewItems';
import AdminPlantSubdevices from '../../components/common/AdminPlantSubdevices';
import AdminPlantDevice from '../../components/common/AdminPlantDevice';
import AdminPlantAccounts from '../../components/common/AdminPlantAccounts';
//import AdminPlantSLA from '../AdminPlantSLA';
import AdminProjects from '../AdminProjects';
import AdminPlantComponents from '../AdminPlantComponents';
import NavContainer from '../../components/common/Nav/NavContainer';
import NavConfig from '../../components/common/Nav/NavConfig';
import api from '../../utility/api';
import { setCurrentAdminPlant } from '../../actions';
import AdminPlantEventSubs from '../AdminPlantEventSubs/AdminPlantEventSubs';
import AdminJobTypes from '../AdminJobTypes/AdminJobTypes';
import { withRouteMatch } from '../../HOC';

class AdminPlantTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.fetchPlant(this.props.match.params.plantId);
    }

    componentDidUpdate() {
        //console.log("props.plant", this.props.plant);
        if (this.props.plant && this.props.plant.id !== this.props.match.params.plantId) {
            this.fetchPlant(this.props.match.params.plantId);
        }
    }

    fetchPlant(plantId) {
        api.get(process.env.REACT_APP_API + `/api/admin/plants/${plantId}`).then((res) => {
            this.props.setCurrentAdminPlant(res.data);
        });
    }

    render() {
        let header = this.props.plant?.name;
        if (this.props.plant?.topicKey) header = `${header} - ${this.props.plant.topicKey}`;
        return this.props.plant ? (
            <>
                <h2>{header}</h2>
                <NavContainer
                    path={this.props.match}
                    defaultConfig={JSON.parse(JSON.stringify(NavConfig.plantoverview))}
                    plant={this.props.plant}
                />
                <Switch>
                    <Route exact path="/admin/plant/:plantId/config" name="PlantConfig">
                        <AdminPlantConfig />
                    </Route>
                    <Route exact path="/admin/plant/:plantId/subdevices" name="PlantSubdevices">
                        <AdminPlantSubdevices />
                    </Route>
                    <Route exact path="/admin/plant/:plantId/newitems" name="PlantNewItems">
                        <AdminPlantNewItems />
                    </Route>
                    <Route exact path="/admin/plant/:plantId/log" name="PlantLog">
                        <AdminPlantLog />
                    </Route>
                    <Route exact path="/admin/plant/:plantId/items" name="PlantItems">
                        <AdminPlantItems />
                    </Route>
                    <Route exact path="/admin/plant/:plantId/device" name="PlantDevice">
                        <AdminPlantDevice />
                    </Route>
                    <Route exact path="/admin/plant/:plantId/accounts" name="PlantAccounts">
                        <AdminPlantAccounts />
                    </Route>
                    <Route exact path="/admin/plant/:plantId/projects" name="PlantProjects">
                        <AdminProjects />
                    </Route>
                    {/* <Route exact path="/admin/plant/:plantId/sla" name="PlantSLA" ><AdminPlantSLA  /></Route> */}
                    <Route exact path="/admin/plant/:plantId/eventsubscriptions" name="PlantEventSubscriptions">
                        <AdminPlantEventSubs />
                    </Route>
                    <Route exact path="/admin/plant/:plantId/components" name="PlantComponents">
                        <AdminPlantComponents plantId={this.props.plant.id} />
                    </Route>
                    <Route exact path="/admin/plant/:plantId/operationtypes" name="Plant Operation types">
                        <AdminJobTypes user={this.props.user} plantId={this.props.plant.id} />
                    </Route>
                </Switch>
            </>
        ) : null;
    }
}

const mapStateToProps = (state) => {
    return {
        plant: state.plants.currentAdminPlant,
    };
};

const mapDispatchToProps = {
    setCurrentAdminPlant,
};

export default withRouteMatch(connect(mapStateToProps, mapDispatchToProps)(AdminPlantTabs));
