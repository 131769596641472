import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import GenericTable from '../GenericTable';
import RowContextMenu from '../GenericTable/TableWidgets/RowContextMenu';
import * as moment from 'moment';
import './AdminIotDevices.scss';
import api from '../../../utility/api';
import { confirmAlert } from 'react-confirm-alert';
import Spinner from '../Spinner/Spinner';
import SimpleSpinner from '../Spinner/SimpleSpinner';
import { DeviceVersioning } from '../../../utility/IoT/deviceVersioning';
import Select from 'react-select';

import Modal from 'react-modal';
//import { ro } from 'date-fns/locale';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminIotDevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            plants: [],
            plantsWithDevices: [],
            fetching: true,
            fetchingPlantVersions: true,
            fetchingLatestversion: true,
            latestversion: {},
            isLatestCommanderEdgeDockerVersion: {},
            isLatestCommanderEdgeVersion: {},
            latestUpdateMessage: {},
            connecting: false,
            deviceVersioningUtility: new DeviceVersioning(),
            updateIntervalMessages: null,
            updateIntervalVersions: null,
            visibleColumns: null,
        };

        const hideConnectToPlantContextCondition = (row) => {
            if (row.original.connectionState !== 'Connected' || row.original.plant) return true;
            return false;
        };
        const hideUpdateContextCondition = (row) => {
            if (row.original.connectionState !== 'Connected') return true;
            return false;
        };
        const rowContext = [
            {
                content: <span>Connect to plant</span>,
                action: (row) => this.openModal(row.original, 'edit'),
                hideContext: hideConnectToPlantContextCondition,
            },
            {
                content: <span>Perform update</span>,
                action: (row) => this.state.deviceVersioningUtility.confirmUpdateDevice(row.original),
                hideContext: hideUpdateContextCondition,
            },
            {
                content: <span>Disable IoT device</span>,
                action: (row) => this.confirmDisable(row.original),
            },
        ];

        this.state.visibleColumns = [
            {
                Header: 'Latest activity',
                accessor: 'lastActivityTime',
                Cell: (row) => <div>{moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss')}</div>,
                filterable: false,
            },
            { Header: 'ID', accessor: 'deviceId', Cell: (row) => <div>{row.value}</div> },
            {
                Header: 'Connection',
                accessor: 'connectionState',
                Cell: (row) => (
                    <div className="connection-state">
                        {row.value === 'Connected' ? (
                            <div className="connected"></div>
                        ) : (
                            <div className="disconnected"></div>
                        )}
                    </div>
                ),
            },
            { Header: 'Plant', accessor: 'plant', Cell: (row) => <div>{row.value}</div> },
            {
                Header: 'Version',
                accessor: 'version',
                Cell: ({ row }) => {
                    const self = this;

                    if (self.state.fetchingPlantVersions)
                        return (
                            <div>
                                <SimpleSpinner size={'1x'}></SimpleSpinner>
                            </div>
                        );

                    if (!row.original.plantVersion) return <div></div>;

                    return (
                        <div>
                            <div className={'versioninfocontainer'}>
                                {Object.keys(row.original.plantVersion)
                                    .sort()
                                    .map(function (keyName, keyIndex) {
                                        if (keyName.indexOf('_tstamp') >= 0) return null;
                                        const keyValue = row.original.plantVersion[keyName] || 'N/A';
                                        const keyName2 = keyName.trim();
                                        let keyNameFin;
                                        let versionInfoItemClassNames = 'versioninfoitem';
                                        //do some silly replacements
                                        switch (keyName2) {
                                            case 'version Commander Edge':
                                                keyNameFin = 'Docker Image';
                                                versionInfoItemClassNames += row.original
                                                    .isLatestCommanderEdgeDockerVersion
                                                    ? ''
                                                    : ' outdated';
                                                break;
                                            case 'version Service':
                                                keyNameFin = 'Edge Service';
                                                versionInfoItemClassNames += row.original.isLatestCommanderEdgeVersion
                                                    ? ''
                                                    : ' outdated';
                                                break;
                                            default:
                                                //For now.. only display the 2 above.. the others will only cause panic
                                                return null;
                                        }

                                        return (
                                            <div className={versionInfoItemClassNames} key={keyIndex}>
                                                <div className={'versioninfoitemlabel'}>{keyNameFin}:</div>
                                                <div className={'versioninfoitemvalue'}>{keyValue}</div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    );
                },
            },
            { Header: 'Latest update message', accessor: 'latestUpdateMessage', Cell: (row) => <div>{row.value}</div> },
            {
                Header: () => null,
                id: 'rowcontext',
                Cell: ({ row }) => <RowContextMenu options={rowContext} row={row} />,
            },
        ];

        this.fetchNewItems = this.fetchNewItems.bind(this);
        this.handlePlantChange = this.handlePlantChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = true;
    }

    componentDidMount() {
        this.fetchNewItems();
        this.fetchPlantsWithDevices();

        this.fetchVersioningInfo();

        this.setState({
            updateIntervalMessages: setInterval(() => {
                this.updateLatestMessage();
            }, 30000),
        });

        this.setState({
            updateIntervalVersions: setInterval(() => {
                this.updateVersions();
            }, 30000),
        });
    }

    componentWillUnmount() {
        this.mounted = false;

        if (this.state.updateIntervalMessages) clearInterval(this.state.updateIntervalMessages);

        if (this.state.updateIntervalVersions) clearInterval(this.state.updateIntervalVersions);
    }

    confirmDisable(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Disable device</h1>
                        <p>
                            You are about to disable this device
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.disableDevice(item);
                                    onClose();
                                }}
                            >
                                Yes, disable
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    async disableDevice(item) {
        try {
            const res = await api.post(`api/admin/iotdevice/disable/${item.deviceId}`); //.then((res) => {
            if (res.status === 200) {
                NotificationManager.success('Device disabled', 'Success');
                this.fetchNewItems();
                this.fetchPlantsWithDevices();
            }
        } catch (err) {
            console.log(err);
            NotificationManager.error('Failed to disable', 'Could not disable device');
        }
    }

    async fetchNewItems() {
        this.setState({
            fetching: true,
        });
        try {
            const res = await api.get(process.env.REACT_APP_API + '/api/admin/iotdevice');
            //.then(res => {
            //    console.log("RES", res);
            if (this.mounted) {
                this.setState({
                    data: res.data,
                    fetching: false,
                });
            }
        } catch (err) {
            NotificationManager.error('Failed to fetch', 'Could not fetch items');
            if (this.mounted) {
                this.setState({
                    fetching: false,
                });
            }
        }
    }

    fetchPlantsWithoutDevices() {
        api.get(process.env.REACT_APP_API + '/api/admin/plants/nodevice')
            .then((res) => {
                console.log('RES', res);
                if (this.mounted) {
                    this.setState({
                        plants: res.data,
                    });
                }
            })
            .catch(() => {
                NotificationManager.error('Failed to fetch', 'Could not fetch items');
            });
    }

    async fetchPlantsWithDevices() {
        try {
            const res = await api.get(process.env.REACT_APP_API + '/api/admin/plants/hasdevice');
            if (this.mounted) {
                this.setState({
                    plantsWithDevices: res.data,
                });
            }
        } catch (err) {
            NotificationManager.error('Failed to fetch', 'Could not fetch items');
        }
    }

    async fetchVersioningInfo() {
        const latestVersionPromise = this.state.deviceVersioningUtility.fetchLatestVersions();
        const plantVersionsPromise = this.state.deviceVersioningUtility.fetchPlantVersions();
        const latestUpdateMessagePromise = this.state.deviceVersioningUtility.fetchLatestUpdateEvents();

        try {
            const latestVersionResult = await latestVersionPromise;
            const plantVersionsResult = await plantVersionsPromise;
            const latestUpdateMessage = await latestUpdateMessagePromise;

            const isLatestCommanderEdgeDockerVersion = {};
            const isLatestCommanderEdgeVersion = {};
            //var latestUpdateMessage = {};

            for (const key in plantVersionsResult) {
                isLatestCommanderEdgeDockerVersion[key] =
                    await this.state.deviceVersioningUtility.isLatestCommanderEdgeDockerVersion(
                        plantVersionsResult[key]['version Commander Edge']
                    );
                isLatestCommanderEdgeVersion[key] =
                    await this.state.deviceVersioningUtility.isLatestCommanderEdgeVersion(
                        plantVersionsResult[key]['version Service']
                    );

                //latestUpdateMessage[key] = await this.state.deviceVersioningUtility.fetchLatestUpdateEvent(key);
            }

            this.setState({
                latestversion: latestVersionResult,
                fetchingLatestversion: false,
                plantVersions: plantVersionsResult,
                fetchingPlantVersions: false,
                isLatestCommanderEdgeDockerVersion: isLatestCommanderEdgeDockerVersion,
                isLatestCommanderEdgeVersion: isLatestCommanderEdgeVersion,
                latestUpdateMessage: latestUpdateMessage,
            });
        } catch (err) {
            console.log(err);
        }
    }

    async updateLatestMessage() {
        if (this.state.fetching) return;

        // eslint-disable-next-line no-var
        var latestUpdateMessage = this.state.latestUpdateMessage; // fixme remove var
        //var messagesUpdated = false;

        // eslint-disable-next-line no-var
        var latestUpdateMessage = await this.state.deviceVersioningUtility.fetchLatestUpdateEvents(); // fixme remove var
        this.setState({
            latestUpdateMessage: latestUpdateMessage,
        });
    }

    async updateVersions() {
        try {
            const plantVersions = await this.state.deviceVersioningUtility.fetchPlantVersions(true);
            //console.log(plantVersions)
            const isLatestCommanderEdgeDockerVersion = {};
            const isLatestCommanderEdgeVersion = {};

            for (const key in plantVersions) {
                //camel cased due to serialization in net6.0
                isLatestCommanderEdgeDockerVersion[key] =
                    await this.state.deviceVersioningUtility.isLatestCommanderEdgeDockerVersion(
                        plantVersions[key]['version Commander Edge']
                    );
                isLatestCommanderEdgeVersion[key] =
                    await this.state.deviceVersioningUtility.isLatestCommanderEdgeVersion(
                        plantVersions[key]['version Service']
                    );
            }

            this.setState({
                plantVersions: plantVersions,
                isLatestCommanderEdgeDockerVersion: isLatestCommanderEdgeDockerVersion,
                isLatestCommanderEdgeVersion: isLatestCommanderEdgeVersion,
            });
        } catch (err) {
            console.log(err);
        }
    }

    handlePlantChange(selectedPlant) {
        console.log('changing plant', selectedPlant);
        this.setState({
            selectedPlant: selectedPlant,
        });
    }
    // handlePlantChange(event) {
    //     let value = event.target.value;

    //     let plant = this.state.plants.find(p => p.topicKey === value);

    //     this.setState({
    //         selectedPlant: plant
    //     });
    // }

    confirmConnectDevice(event, item) {
        event.preventDefault();
        if (!this.state.selectedPlant) {
            NotificationManager.warning('Please select plant first', 'No device selected', 5000);
            return;
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Connect device</h1>
                        <p>
                            You are about to connect device {item.deviceId} with
                            <br />{' '}
                        </p>
                        <span>
                            <b>{this.state.selectedPlant.name}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-blue"
                                onClick={() => {
                                    this.connectDevice(item);
                                    onClose();
                                }}
                            >
                                Yes, connect
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    connectDevice(item) {
        if (this.state.selectedPlant != null) {
            this.setState({
                connecting: true,
            });
            api.post(
                process.env.REACT_APP_API +
                    '/api/admin/iotdevice/connect/' +
                    item.deviceId +
                    '/' +
                    this.state.selectedPlant.topicKey,
                {}
            )
                .then((response) => {
                    if (response.status === 200 || response.status === 204) {
                        NotificationManager.success('Device successfully connected', 'Device connected', 5000);
                        this.closeModal();
                        this.setState({
                            connecting: false,
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    NotificationManager.error('Device not connected', 'Error connecting device', 5000);
                    this.setState({
                        connecting: false,
                    });
                    this.closeModal();
                });
        }
    }

    openModal(item, _mode) {
        this.fetchPlantsWithoutDevices();

        this.setState({
            modalIsOpen: true,
            editItem: item,
        });
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
            },
            function () {
                this.fetchNewItems();
                this.fetchPlantsWithDevices();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase()) : false;
    }

    getLatestVersionsInfo() {
        const self = this;
        if (!self.state.latestversion) return null;

        return (
            <div>
                <h3>Latest Version Info</h3>
                <div className={'versioninfocontainer'}>
                    <div className={'versioninfoitem'}>
                        <div className={'versioninfoitemlabel'}>Docker Image: </div>
                        <div className={'versioninfoitemvalue'}>
                            {self.state.latestversion.edgeServiceDockerImageVersion}
                        </div>
                    </div>
                    <div className={'versioninfoitem'}>
                        <div className={'versioninfoitemlabel'}>Edge Service: </div>
                        <div className={'versioninfoitemvalue'}>{self.state.latestversion.edgeServiceVersion}</div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const newItems = Object.assign([], this.state.data);
        if (this.state.fetching) return <Spinner text="gateways"></Spinner>;
        if (!newItems || (newItems && newItems.length < 1)) return <div>No gateways retrieved</div>;

        //var connectedCount = newItems.filter(function(x){return x==2}).length
        let connectedCount = 0;
        newItems.forEach((i) => {
            if (i.connectionState === 'Connected') connectedCount++;

            const plant = this.state.plantsWithDevices.find((p) => p.deviceId === i.deviceId);

            if (!plant) return;

            i.plant = plant.name;
            //console.lolg(plant.topicKey)
            const lowerPlantKey = plant.topicKey?.toLowerCase();
            if (!lowerPlantKey) {
                console.warn(`Plant is missing plantkey`, plant);
                return;
            }
            if (!this.state.fetchingPlantVersions && this.state.plantVersions) {
                const versions = this.state.plantVersions[lowerPlantKey];
                //console.log(`Plant ${lowerPlantKey} || Versions ${versions}`,this.state.plantVersions);
                i.plantVersion = versions;
                i.isLatestCommanderEdgeDockerVersion = this.state.isLatestCommanderEdgeDockerVersion[lowerPlantKey];
                i.isLatestCommanderEdgeVersion = this.state.isLatestCommanderEdgeVersion[lowerPlantKey];
            }
            const myMsg = this.state.latestUpdateMessage[lowerPlantKey];
            if (myMsg) i.latestUpdateMessage = myMsg['auto Update Status']; //camel cased due to serialization in net6.0
        });

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>
                            {newItems.length} gateways, {connectedCount} online
                        </h2>
                    </span>
                    <span>
                        {this.state.fetchingLatestversion ? (
                            <div>Fetching latest version info</div>
                        ) : (
                            this.getLatestVersionsInfo()
                        )}
                    </span>
                </div>
                <GenericTable
                    data={newItems}
                    columns={this.state.visibleColumns}
                    localPaging={true}
                    enableGlobalFilter={true}
                    defaultPageSize={20}
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <div>
                        <div className="admin-plant-items-add-edit-header">
                            <span>Select plant</span>
                        </div>
                        <form className="admin-plant-items-add-edit-form">
                            <Select
                                name={'plant'}
                                value={this.state.selectedPlant}
                                onChange={(item) => this.handlePlantChange(item)}
                                options={this.state.plants || []}
                                isMulti={false}
                                classNamePrefix={'optimar'}
                                getOptionLabel={(x) => `${x.name}`}
                                getOptionValue={(x) => `${x.topicKey}`}
                                menuPortalTarget={document.body}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        background: '#1E2E34',
                                        borderRadius: '0.1em',
                                        borderStyle: 'none',
                                    }),
                                    singleValue: (base) => ({
                                        ...base,
                                        color: 'rgb(230, 230, 240)',
                                    }),
                                    input: (base) => ({
                                        ...base,
                                        color: 'rgb(230, 230, 240)',
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (styles) => ({
                                        ...styles,
                                        width: '100%',
                                    }),
                                }}
                            />
                            {/* {this.state.plants ? <select className="admin-form-select" name="data-type" onChange={this.handlePlantChange}>
                                {
                                    this.state.plants.map((dt, i) => {
                                        return <option key={"it" + i} id={i} value={dt.topicKey}>{dt.name}</option>
                                    })
                                }
                            </select> : null} */}
                            <div className="btn-row btn-row-grow">
                                <button
                                    className="btn btn-yellow btn-grow"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.closeModal();
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    disabled={this.state.connecting}
                                    className="btn btn-grow btn-blue"
                                    onClick={(event) => this.confirmConnectDevice(event, this.state.editItem)}
                                >
                                    {this.state.connecting ? 'Connecting..' : 'Connect'}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        plant: state.plants.currentAdminPlant,
    };
};

export default connect(mapStateToProps)(AdminIotDevices);
