import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ReactTable from 'react-table-6';
import Spinner from '../../components/common/Spinner/Spinner';
import * as moment from 'moment';
import api from '../../utility/api';
import 'react-table-6/react-table.css';

class ParameterList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSub: false,
        };

        this.visibleColumns = [
            { Header: 'Name', accessor: 'name', filterable: true, maxWidth: 250 },
            {
                Header: 'Topic',
                accessor: 'topic',
                filterable: true,
                Cell: (row) => <div>{row.value.split('/').slice(5).join('/')}</div>,
            },
            { Header: 'Value', accessor: 'value', filterable: true, maxWidth: 100 },
            { Header: 'Prev. Value', accessor: 'previousValue', filterable: true, maxWidth: 100 },
            {
                Header: 'Updated',
                accessor: 'timestamp',
                filterable: true,
                maxWidth: 160,
                Cell: (row) => <div>{moment(row.value).format('DD.MM.YYYY HH:mm:ss')}</div>,
            },
        ];

        this.fetchParameters = this.fetchParameters.bind(this);
        this.showSubComponentParams = this.showSubComponentParams.bind(this);
    }

    componentDidMount() {
        if (!this.props.currentItem) {
            this.setState({ showSub: true }, this.fetchParameters);
        } else {
            this.fetchParameters();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && prevProps.itemId !== this.props.itemId) {
            this.fetchParameters();
        }
    }

    fetchParameters() {
        let url;
        if (!this.props.itemId) {
            url = `${process.env.REACT_APP_API}/api/parameters/${this.props.plant.topicKey}/plant?showSub=${this.state.showSub}`;
        } else {
            url = `${process.env.REACT_APP_API}/api/parameters/${this.props.itemId}?plantKey=${this.props.plant.topicKey}&showSub=${this.state.showSub}`;
        }

        api.get(url)
            .then((res) => {
                this.setState({
                    data: res.data || [],
                });
            })
            .catch(() => {
                NotificationManager.error('Failed to fetch', 'Could not fetch parameters');
                this.setState({ data: [] });
            });
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null
            ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
            : false;
    }

    showSubComponentParams() {
        console.log('!');
        this.setState(
            {
                showSub: !this.state.showSub,
            },
            this.fetchParameters
        );
    }

    render() {
        const parameters = this.state.data;

        if (!parameters) {
            return <Spinner text="parameters" />;
        }

        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{parameters ? parameters.length : 0} Parameters</h2>
                        {/* <span className="main-header-action" onClick={() => this.openModal(null, "new")}> <span className="list-expand-toggle">+ New machineNo</span> </span> */}
                    </span>
                    {
                        <div onClick={this.showSubComponentParams} className="faux-checkbox">
                            <div className={this.state.showSub ? 'checkbox checked' : 'checkbox'}></div>
                            <span>Show subcomponent parameters</span>
                        </div>
                    }
                </div>
                <ReactTable
                    data={parameters}
                    columns={this.visibleColumns}
                    showPagination={true}
                    defaultPageSize={20}
                    showPageSizeOptions={false}
                    minRows={0}
                    defaultFilterMethod={this.filterCaseInsensitive}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    plant: state.plants.currentPlant,
});

export default connect(mapStateToProps)(ParameterList);
