import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ReactTable from 'react-table-6';
import * as moment from 'moment';
import 'react-table-6/react-table.css';
import api from '../../../utility/api';
import { ReactComponent as EditIcon } from '../../../images/list/optimar_edit.svg';
import { ReactComponent as DeleteIcon } from '../../../images/list/optimar_cross.svg';
import AdminPlantSubdevicesItemSelect from '../AdminPlantSubdevicesItemSelect/AdminPlantSubdevicesItemSelect';
import { confirmAlert } from 'react-confirm-alert';

import Modal from 'react-modal';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 22, 28, 0.8)',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#0D171E',
        boxShadow: '0 0 30px 2px rgb(10, 22, 28)',
        border: '1px solid rgb(45, 65, 80)',
        maxHeight: '80vh',
        minHeight: '20vw',
        width: '600px',
    },
};

class AdminPlantSubdevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newItems: [],
            existingItems: [],
        };

        this.visibleColumns = [
            {
                Header: 'Time',
                accessor: 't_stamp',
                Cell: (row) => <div>{moment.utc(row.value).local().format('DD.MM.YYYY HH:mm:ss')}</div>,
                filterable: false,
            },
            { Header: 'Subdevice ID', accessor: 'deviceId', Cell: (row) => <div>{row.value}</div>, filterable: false },
            { Header: 'Topic', accessor: 'value', filterable: false },
            { Header: 'Description', accessor: 'desc', filterable: false },
            {
                Header: '',
                Cell: (item) => {
                    return (
                        <div className="action-container">
                            <div
                                className="action-element padding"
                                alt="Select item"
                                title="Add item"
                                onClick={() => this.openModal(item.original, false)}
                            >
                                <EditIcon className="action-icon" />
                                <span className="list-edit-btn">Select item</span>
                            </div>
                        </div>
                    );
                },
            },
        ];

        this.visibleColumns2 = [
            { Header: 'Subdevice ID', accessor: 'deviceId', Cell: (row) => <div>{row.value}</div>, filterable: false },
            {
                Header: 'Child Component',
                accessor: 'itemKey',
                Cell: (row) => <div>{row.original.thisComponentOnly ? row.value : '*'}</div>,
                filterable: false,
            },
            { Header: 'Parent Structure', accessor: 'topic', filterable: false, Cell: (row) => <div>{row.value}</div> },
            {
                Header: '',
                Cell: (item) => {
                    return (
                        <div className="action-container">
                            <div
                                className="action-element padding"
                                alt="Remove mapping"
                                title="Remove mapping"
                                onClick={() => this.confirmRemoveMapping(item.original)}
                            >
                                <DeleteIcon className="delete-icon" />
                                <span className="list-edit-btn">Remove mapping</span>
                            </div>
                            <div
                                className="action-element padding"
                                alt="Edit mapping"
                                title="Edit mapping"
                                onClick={() => this.openModal(item.original, true)}
                            >
                                <EditIcon className="action-icon" />
                                <span className="list-edit-btn">Edit mapping</span>
                            </div>
                        </div>
                    );
                },
            },
        ];

        this.fetchNewItems = this.fetchNewItems.bind(this);
        this.fetchExistingItems = this.fetchExistingItems.bind(this);
        this.fetchExistingItems = this.fetchExistingItems.bind(this);
        this.removeMapping = this.removeMapping.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.modalRef = React.createRef();
        this.mounted = true;
    }

    async componentDidMount() {
        await this.fetchNewItems();
        await this.fetchExistingItems();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    async componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            await this.fetchNewItems();
            await this.fetchExistingItems();
        }
    }

    async fetchNewItems() {
        if (this.props.plant) {
            //This could be handled better.. could end up running this query multiple times if trying to fetch new items before the first query returns.
            try {
                const res = await api.get(
                    process.env.REACT_APP_API +
                        '/api/admin/edgedevice/' +
                        this.props.plant.id +
                        '?plantKey=' +
                        this.props.plant.topicKey
                );
                if (this.mounted) {
                    this.setState({
                        newItems: res.data,
                    });
                }
            } catch (err) {
                NotificationManager.error('Failed to fetch', 'Could not fetch items');
            }
        }
    }

    confirmRemoveMapping(item) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>Remove item mapping</h1>
                        <p>
                            You are about to remove the mapping from item
                            <br />{' '}
                        </p>
                        <span>
                            <b>{item.topic}</b>
                        </span>
                        <p>Are you sure?</p>
                        <div className="buttons">
                            <button className="btn btn-yellow" onClick={onClose}>
                                No, cancel
                            </button>
                            <button
                                className="btn btn-red"
                                onClick={() => {
                                    this.removeMapping(item);
                                    onClose();
                                }}
                            >
                                Yes, remove
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    async removeMapping(item) {
        try {
            if (item != null) {
                const response = await api.delete(
                    process.env.REACT_APP_API + '/api/admin/edgedevice/' + item.itemId,
                    {}
                );
                if (response.status === 200 || response.status === 204) {
                    NotificationManager.success('Mapping successfully removed', 'Mapping removed', 5000);
                    await this.fetchNewItems();
                    await this.fetchExistingItems();
                } else {
                    throw new Error('Mapping was not successfully removed: ' + response.status);
                }
            }
        } catch (err) {
            console.error(err);
            NotificationManager.error('Error removing mapping', 'Error removing mapping', 5000);
        }
    }

    async fetchExistingItems() {
        if (this.props.plant) {
            //This could be handled better.. could end up running this query multiple times if trying to fetch new items before the first query returns.
            try {
                const res = await api.get(
                    process.env.REACT_APP_API + '/api/admin/edgedevice/' + this.props.plant.id + '/existing'
                );
                if (this.mounted) {
                    this.setState({
                        existingItems: res.data,
                    });
                }
            } catch (err) {
                NotificationManager.error('Failed to fetch', 'Could not fetch items');
            }
        }
    }
    openModal(item, existing) {
        this.setState(
            {
                modalIsOpen: true,
                editItem: item,
                existing: existing,
            },
            async function () {
                await this.fetchNewItems();
                await this.fetchExistingItems();
            }
        );
    }

    closeModal() {
        this.setState(
            {
                modalIsOpen: false,
                editItem: null,
            },
            async function () {
                await this.fetchNewItems();
                await this.fetchExistingItems();
            }
        );
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return row[id] != null
            ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
            : false;
    }

    render() {
        const newItems = this.state.newItems;
        const existingItems = this.state.existingItems;

        if (newItems.length < 1 && existingItems.length < 1) return <div>No new items</div>;
        // console.log("1");
        // console.log(this.modalRef);
        return (
            <>
                <div className="main-header">
                    <span>
                        <h2>{newItems ? newItems.length : 0} new subdevices</h2>
                    </span>
                </div>
                {newItems.length > 0 ? (
                    <ReactTable
                        defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                        data={newItems}
                        columns={this.visibleColumns}
                        showPagination={false}
                        defaultPageSize={50}
                        minRows={0}
                    />
                ) : null}

                <div className="main-header">
                    <span>
                        <h2>{existingItems ? existingItems.length : 0} existing subdevices</h2>
                    </span>
                </div>
                <ReactTable
                    defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                    data={existingItems}
                    columns={this.visibleColumns2}
                    showPagination={false}
                    defaultPageSize={50}
                    minRows={0}
                />

                <Modal
                    ref={this.modalRef}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <AdminPlantSubdevicesItemSelect
                        modalRef={this.modalRef}
                        existing={this.state.existing}
                        edgeDevice={this.state.editItem}
                        addedCallback={() => this.closeModal()}
                        closeModal={() => this.closeModal()}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        plant: state.plants.currentAdminPlant,
    };
};

export default connect(mapStateToProps)(AdminPlantSubdevices);
